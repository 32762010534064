import React from "react";
import { Navigate as Redirect } from "react-router-dom";
import appState from "../../state/AppStateContainer";

export default function({ requireAuth, roles, children }) {
  //if don't require auth return true
  if (!requireAuth) return children;

  //if does require auth but no role requirement
  //and user is authenticated (token is set)
  //return true
  if (!roles && appState.state.token) return children;

  //if specific roles are allowed, return true if user
  //has any of the required roles
  if (
    roles &&
    appState.state.token &&
    roles.indexOf(appState.state.token?.role) >= 0
  )
    return children;

  //return false
  if (!appState.state.token) return <Redirect to="/" />;
  else return <Redirect to="/denied" />;
}
