import React, { Component, createRef } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Modal, Button } from "react-bootstrap";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

class CropModal extends Component {
  constructor(props) {
    super(props);
    this.imageRef = createRef();
    this.state = {
      crop: {
        aspect: props.aspectRatio,
        width: 200,
        height: 30,
        x: 0,
        y: 0,
        minHeight: 30,
        minWidht: 100,
      },
      completedCrop: ""
    };
  }

  // componentDidUpdate(prevProps) {
  //   if (prevProps !== this.props && !this.props.add) {
  //     this.formik.resetForm();
  //   }
  // }

  // onImageLoaded = (image, crop) => {
  //   this.imageRef = image;
  // };
  
  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const blob = await this.getCroppedImg(
        this.imageRef.current,
        crop,
        this.props.filename
      );
      return blob;
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = Math.ceil(crop.width * scaleX);
    canvas.height = Math.ceil(crop.height * scaleY);
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          console.error("Canvas is empty");
          return;
        }
        blob.name = Date.now() + fileName;

        resolve(blob);
      }, this.props.fileType || "image/webp");
    });
  }

  render() {
    const { intl, imageSrc } = this.props;
    return (
      <Modal
        show={true}
        onHide={() => {
          this.props.onCancel && this.props.onCancel();
        }}
        backdrop="static"
      >
        <Modal.Header closeButton={true}>
          <h4>
            <FormattedMessage
              id="global.cropImage"
              defaultMessage="Crop Image"
            />
          </h4>
        </Modal.Header>
        <Modal.Body>
          <style>
            {`
                .ReactCrop {
                  
                  background-color: #fff;
                  background-image: -webkit-linear-gradient(45deg,#efefef 25%,transparent 25%,transparent 75%,#efefef 75%,#efefef),-webkit-linear-gradient(45deg,#efefef 25%,transparent 25%,transparent 75%,#efefef 75%,#efefef);
                  background-position: 0 0,10px 10px;
                  -webkit-background-size: 21px 21px;
                }
              `}
          </style>
          <ReactCrop
            crop={this.state.crop}
            onChange={(crop) => this.setState({ crop })}
          >
            <img src={imageSrc} ref={this.imageRef} />
          </ReactCrop>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={async () => {
              const blob = await this.makeClientCrop(this.state.crop);
              this.props.onSubmit && this.props.onSubmit(blob);
            }}
          >
            <FormattedMessage id="global.save" defaultMessage="Save" />
          </Button>
          <Button
            variant="link"
            onClick={() => {
              this.props.onCancel && this.props.onCancel();
            }}
          >
            <FormattedMessage id="global.cancel" defaultMessage="Cancel" />
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default injectIntl(CropModal);
