import React, { Component } from "react";
import { FormattedMessage, injectIntl, FormattedNumber } from "react-intl";
import classNames from "classnames";
import Layout from "../shared/Layout";
import services from "../../services";
import _ from "lodash";
import Auth from "../shared/Auth";
import { Link } from "react-router-dom";
import withRouter from "../../util/withRouter";
import appState from "../../state/AppStateContainer";
import moment from "moment";
import ImageProvider from "../shared/ImageProvider";
import HeaderTitle from "../shared/HeaderTitle";
import CheckAccess from "../shared/CheckAccess";
import { ActivitiesEnum } from "../../staticData/Activities";
import { SingleDatePicker } from "react-dates";
import { getLocalDateTime } from "../../util";
import Table from "../shared/Table";

export class ServiceCategoryList extends Component {
  state = {
    data: [],
    hasMore: true,
    pageIndex: -1,
    pageSize: 10,
    searchFilter: "",
    statusFilter: "all",
    aplhabeticalOrder: true,
    count: 0,
    updatingRowId: "",
    loading: false,
  };

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      if (!this.props.match.params.isFormOpen) {
        if (_.get(this, "props.location.state.isSubmitted") === true) {
          this.setState({
            pageIndex: -1,
            data: [],
            hasMore: true,
            searchFilter: this.searchInput.value,
            statusFilter: this.statusSelect.value,
            disableFlag: true,
            updatingRowId: "",
          });
        } else {
          this.setState({ updatingRowId: "" });
        }
      }
    }
  }

  async loadMore() {
    this.setState(
      (currentState) => {
        return {
          pageIndex: currentState.pageIndex + 1,
          hasMore: false,
          loading: true,
        };
      },
      async () => {
        const pageIndex = 0;
        const options = {
          progressBar: true,
          fromDate: this.state.from
            ? moment(this.state.from).startOf("day").toString()
            : null,
          toDate: this.state.to
            ? moment(this.state.to).endOf("day").toString()
            : null,
        };
        const pageData = await services.getCategories(
          this.state.searchFilter,
          this.state.statusFilter,
          this.state.pageIndex,
          this.state.pageSize,
          this.state.aplhabeticalOrder,
          options,
          true // service category
        );

        const summary =
          pageIndex === 0
            ? await services.getCategories(
                this.state.searchFilter,
                this.state.statusFilter,
                undefined,
                undefined,
                this.state.aplhabeticalOrder,
                { ...options, summary: 1 },
                true // service category
              )
            : { count: this.state.count };

        this.setState((currentState) => {
          return {
            data: currentState.data.concat(pageData),
            hasMore: pageData.length > 0,
            disableFlag: false,
            count: summary.count,
            loading: false,
          };
        });
      }
    );
  }

  resetData(e) {
    this.setState({
      pageIndex: -1,
      data: [],
      hasMore: true,
      searchFilter: this.searchInput.value,
      statusFilter: this.statusSelect.value,
      disableFlag: true,
    });
    if (e) e.preventDefault();
  }

  async componentDidMount() {
    this.subscription = appState.emitter.addListener("onLanguageChange", () => {
      this.setState({
        from: this.state.from
          ? moment(this.state.from).locale(appState.state.language)
          : undefined,
        to: this.state.to
          ? moment(this.state.to).locale(appState.state.language)
          : undefined,
      });
    });
  }

  componentWillUnmount() {
    this.subscription.remove();
  }

  resetPage(e) {
    this.setState({
      pageIndex: -1,
      data: [],
      hasMore: true,
      searchFilter: "",
      statusFilter: "all",
      disableFlag: true,
      to: undefined,
      from: undefined,
    });
    this.searchInput.value = "";
    this.statusSelect.value = "all";
    e.preventDefault();
  }

  render() {
    const { intl } = this.props;
    const { loading } = this.state;

    return (
      <Auth requireAuth={true} roles={["sadmin"]}>
        <Layout hidden={this.props.match.params.isFormOpen} loading={loading}>
          <div className="table-header clearfix">
            <HeaderTitle
              id="category.serviceCategories"
              defaultMessage="Service Categories"
            />
            <form className="row" onSubmit={this.resetData.bind(this)}>
              <div className="col-xs-3 pull-right">
                <div className="panel-group-buttons pull-right">
                  <CheckAccess
                    activityId={ActivitiesEnum.service_categories__new}
                  >
                    <Link className="btn btn-special " to="/service-category">
                      <span className="fa fa-plus" />{" "}
                      <FormattedMessage
                        id="category.newCategory"
                        defaultMessage="Create Category"
                      />
                    </Link>
                  </CheckAccess>
                </div>
              </div>
              <CheckAccess
                activityId={ActivitiesEnum.categories__filter_and_search}
              >
                <div className="col-lg-2 col-md-4 col-sm-12 col-xs-6">
                  <div className="has-feedback">
                    <input
                      type="text"
                      ref={(c) => {
                        this.searchInput = c;
                      }}
                      className="form-control"
                      placeholder={intl.formatMessage({
                        id: "global.search",
                        defaultMessage: "Search",
                      })}
                    />
                    <i className="glyphicon glyphicon-search form-control-feedback" />
                  </div>
                </div>

                <div className="col-lg-2 col-md-4 col-sm-12 col-xs-6">
                  <select
                    ref={(c) => {
                      this.statusSelect = c;
                    }}
                    onChange={this.resetData.bind(this)}
                    className="form-control"
                  >
                    <option value="all">
                      {intl.formatMessage({
                        id: "category.status",
                        defaultMessage: "Status",
                      })}
                    </option>
                    <option value="active">
                      {intl.formatMessage({
                        id: "category.active",
                        defaultMessage: "Active",
                      })}
                    </option>
                    <option value="suspended">
                      {intl.formatMessage({
                        id: "category.inactive",
                        defaultMessage: "Inactive",
                      })}
                    </option>
                  </select>
                </div>

                <div className="clearfix" />
                <div className="col-lg-2 col-md-4 col-sm-12 col-xs-6">
                  <SingleDatePicker
                    id="fromDate"
                    placeholder={intl.formatMessage({
                      id: "global.from",
                      defaultMessage: "From",
                    })}
                    block={true}
                    small={true}
                    isOutsideRange={() => false}
                    numberOfMonths={1}
                    initialDate={this.state.from}
                    isRTL={appState.state.language === "ar" ? true : false}
                    date={this.state.from}
                    onDateChange={(date) => {
                      this.setState({ from: date }, this.resetData.bind(this));
                    }}
                    focused={this.state.focusedFrom}
                    onFocusChange={({ focused }) =>
                      this.setState({ focusedFrom: focused })
                    }
                  />
                </div>

                <div className="col-lg-2 col-md-4 col-sm-12 col-xs-6">
                  <SingleDatePicker
                    id="toDate"
                    block={true}
                    placeholder={intl.formatMessage({
                      id: "global.to",
                      defaultMessage: "To",
                    })}
                    small={true}
                    isOutsideRange={() => false}
                    numberOfMonths={1}
                    initialDate={this.state.to}
                    isRTL={appState.state.language === "ar" ? true : false}
                    date={this.state.to}
                    onDateChange={(date) => {
                      this.setState({ to: date }, this.resetData.bind(this));
                    }}
                    focused={this.state.focusedTo}
                    onFocusChange={({ focused }) =>
                      this.setState({ focusedTo: focused })
                    }
                  />
                </div>
                <div className="col force--inline">
                  <div className="tooltip-master">
                    <button
                      href="javascript:;"
                      className="btn btn-primary"
                      onClick={this.resetData.bind(this)}
                      disabled={this.state.disableFlag}
                    >
                      <i className="material-icons">search</i>
                    </button>
                    <span className="tooltiptext">
                      <FormattedMessage
                        id="global.filter"
                        defaultMessage="Filter"
                      />
                    </span>
                  </div>
                </div>
                <div className="col force--inline">
                  <div className="tooltip-master">
                    <button
                      href="javascript:;"
                      className="btn btn-primary"
                      onClick={this.resetPage.bind(this)}
                      disabled={this.state.disableFlag}
                    >
                      <i className="material-icons">settings_backup_restore</i>
                    </button>
                    <span className="tooltiptext">
                      <FormattedMessage
                        id="global.resetFilter"
                        defaultMessage="Reset Filter"
                      />
                    </span>
                  </div>
                </div>
              </CheckAccess>
            </form>
            <div className="row">
              <div className="col-md-12">
                <FormattedMessage
                  id="global.totalCount"
                  defaultMessage="Total Count"
                />

                <strong>
                  {" "}
                  <FormattedNumber
                    style="decimal"
                    maximumFractionDigits={0}
                    minimumFractionDigits={0}
                    value={this.state.count}
                  />{" "}
                </strong>
              </div>
            </div>
          </div>
          <div>
            <div className="table-responsive">
              <Table
                loadMore={this.loadMore.bind(this)}
                hasMore={this.state.hasMore}
                caption={
                  (!this.state.data || this.state.data.length == 0) && (
                    <caption
                      style={{
                        captionSide: "bottom",
                        textAlign: "center",
                        margin: "20px",
                        color: "darkgray",
                      }}
                    >
                      <FormattedMessage
                        id="global.noData"
                        defaultMessage="No data available"
                      />
                    </caption>
                  )
                }
                headerRow={
                  <tr>
                    <th>
                      <FormattedMessage
                        id="category.logo"
                        defaultMessage="Logo"
                      />
                    </th>
                    <th>
                      <FormattedMessage
                        id="category.englishName"
                        defaultMessage="English Name"
                      />
                    </th>
                    <th>
                      <FormattedMessage
                        id="category.arabicName"
                        defaultMessage="Arabic Name"
                      />
                    </th>
                    <th>
                      <FormattedMessage
                        id="global.status"
                        defaultMessage="Status"
                      />
                    </th>
                    <th>
                      <FormattedMessage
                        id="global.createdAt"
                        defaultMessage="Created At"
                      />
                    </th>
                    <th style={{ width: "20px" }} />
                  </tr>
                }
              >
                {this.state.data.map((c) => {
                  var showEdit = false;

                  if (
                    (appState.state.token?.role === "sadmin" &&
                      c.approvalState === "approved") ||
                    (appState.state.token?.id === c.approvalRequestedBy &&
                      c.approvalState !== "approved")
                  )
                    showEdit = true;

                  return (
                    <tr
                      key={c.id}
                      onClick={() => {
                        this.setState(
                          {
                            updatingRowId: c.id,
                          },
                          () => {
                            // appState?.isPetrominWholesaler() ? this.props.navigate(`/category/${c.id}`) :
                            this.props.navigate(`/service-category/${c.id}`);
                          }
                        );
                      }}
                      className="cases-trow link-row"
                    >
                      <ImageProvider imageSrc={c.logo} />
                      {/* <td>
                          {c.logo && (
                            <img
                              src={`/api/util/image?key=${c.logo}`}
                              alt=""
                              style={{ width: "50px" }}
                            />
                          )}
                        </td> */}
                      <td>{c.englishName}</td>
                      <td>{c.arabicName}</td>
                      <td
                        className={classNames({
                          "col-status-green": c.isActive,
                          "col-status-red": !c.isActive,
                        })}
                      >
                        <a href="javascript:;">
                          {c.isActive && (
                            <FormattedMessage
                              id="global.active"
                              defaultMessage="Active"
                            />
                          )}
                          {!c.isActive && (
                            <FormattedMessage
                              id="global.suspended"
                              defaultMessage="Suspended"
                            />
                          )}
                        </a>
                      </td>
                      <td>
                        {c.createdAt
                          ? getLocalDateTime(c.createdAt)
                          : undefined}
                      </td>
                      <td style={{ position: "relative" }}>
                        {showEdit && (
                          <CheckAccess
                            activityId={
                              ActivitiesEnum.categories__update_category_edit_reject
                            }
                          >
                            <Link
                              style={{ margin: "10px", cursor: "pointer" }}
                              to={`/category/${c.id}`}
                            >
                              <span className="fa fa-pencil" />{" "}
                              <FormattedMessage
                                id="global.edit"
                                defaultMessage="Edit"
                              />
                            </Link>
                          </CheckAccess>
                        )}
                        {appState.state.token?.role === "sadmin" &&
                          (c.approvalState === "pending" ||
                            c.approvalState === "approved") && (
                            <CheckAccess
                              activityId={
                                ActivitiesEnum.categories__update_category_edit_reject
                              }
                            >
                              <a
                                style={{
                                  margin: "10px",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  this.handleReject(c.id);
                                }}
                              >
                                <span className="fa fa-thumbs-down" />{" "}
                                {this.props.intl.formatMessage({
                                  id: "global.reject",
                                  defaultMessage: "Reject",
                                })}
                              </a>
                            </CheckAccess>
                          )}

                        {appState.state.token?.role === "sadmin" && (
                          <CheckAccess
                            activityId={ActivitiesEnum.user_activity__all}
                          >
                            <Link
                              style={{
                                margin: "10px",
                                cursor: "pointer",
                              }}
                              to={`/userActivities/${c.id}`}
                            >
                              <span className="fa fa-history" />{" "}
                              <FormattedMessage
                                id="global.viewHistory"
                                defaultMessage="View History"
                              />
                            </Link>
                          </CheckAccess>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </Table>
            </div>
          </div>
        </Layout>
      </Auth>
    );
  }
}

export default withRouter(injectIntl(ServiceCategoryList));
