import React from "react";
import { FormattedMessage } from "react-intl";
import { Modal, Button } from "react-bootstrap";
import _ from "lodash";
import moment from "moment-timezone";

const QapterHistoryModal = ({ show, onCancel, estimationDetails }) => {
  return (
    <Modal
      show={show}
      onHide={() => onCancel()}
      dialogClassName="history-modal"
    >
      <Modal.Header closeButton={true}>
        <h4>
          <FormattedMessage id="qapter.history" defaultMessage="History" />
        </h4>
      </Modal.Header>
      <Modal.Body className="qapter-modal-body">
        <div>
          {estimationDetails?.qapterDetails?.length > 0 ? (
            estimationDetails?.qapterDetails?.map((obj) => {
              return (
                <div className="qapter-claim-number-wrapper">
                  <p>
                    <b>Claim Number:</b> {obj?.claimNumber}
                  </p>
                  <p>
                    <b>Sent at:</b>{" "}
                    {moment(obj?.sentAt)?.format("YYYY-MM-DD, HH:mm:ss")}
                  </p>
                </div>
              );
            })
          ) : (
            <p className="text-center">
              <FormattedMessage
                id="global.noHistory"
                defaultMessage="No history found"
              />
            </p>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button bsStyle="link" onClick={onCancel}>
          <FormattedMessage id="global.cancel" defaultMessage="Cancel" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default React.memo(QapterHistoryModal);
