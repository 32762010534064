import React, { Component, createRef } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Form, Formik } from "formik";
import { NotificationManager } from "react-notifications";
import _ from "lodash";
import { Yup } from "../../util";
import services from "../../services";
import appState from "../../state/AppStateContainer";
import withRouter from "../../util/withRouter";
import Layout from "../shared/Layout";
import Auth from "../shared/Auth";
import FieldRow from "../shared/FieldRow";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import Select from "react-select";
import { notifications } from "../../util/utils";
import Notifications from "./adminNotifications/notifications";
import { Button } from "react-bootstrap";
export class AdminForm extends Component {
  constructor() {
    super();
    this.formik = createRef();
  }

  state = {
    data: {},
    roles: [],
    expanded: [],
    branches: [],
    loading: true,
    showPassword: false,
    showConfirmPassword: false,
    smsContent: [],
    smsError: "",
  };

  async componentDidMount() {
    try {
      this.subscription = appState.emitter.addListener(
        "onLanguageChange",
        () => {
          this.formik.validateForm();
        }
      );

      const options = { progressBar: true };
      let roles = await services.getRoles("", null, null, options);
      roles = roles.map((r) => ({ label: r.name, value: r.id }));
      let data;
      if (this.props.match.params.id) {
        data = await services.getAdmin(this.props.match.params.id, options);
        this.deCompileSmsContent(data);
      }

      // const branches = await branchService.getBranches();
      this.setState({
        data: this.props.match.params.id ? data : {},
        // branches: branches.data,
        roles,
      });
    } catch (e) {
      this.props.navigate("/error", {
        error: _.get(e, "response.data.error", "error.unexpectedError"),
      });
    } finally {
      this.setState({ loading: false });
    }
  }

  componentWillUnmount() {
    this.subscription.remove();
  }

  async deCompileSmsContent(values) {
    let smsData = [];
    notifications.forEach((notify) => {
      const name = notify.charAt(0).toUpperCase() + notify.substring(1);
      if (values["allow" + name]) {
        smsData.push({ [notify + "Content"]: values[notify + "Content"] });
      }
    });

    this.setState({ smsContent: smsData });
  }

  async compileSmsContent(values) {
    let validateSms = { isError: false, name: "" };

    notifications.forEach((notify) => {
      const name = notify.charAt(0).toUpperCase() + notify.substring(1);
      if (values["allow" + name]) {
        let smsContent = this.state.smsContent.find(
          (content) => content[notify + "Content"]
        );
        let valuesSms = smsContent && smsContent[notify + "Content"];

        if (smsContent && Object.keys(valuesSms).length) {
          values[notify + "Content"] = valuesSms;
        } else {
          validateSms = { isError: true, name: notify };
        }
      } else {
        values[notify + "Content"] = {};
      }
    });

    if (validateSms.isError) {
      this.setState({ smsError: validateSms.name });
    } else {
      this.setState({ smsError: "" });
    }

    return values;
  }

  async handleSubmit(values, { setSubmitting }) {
    let updatedValues = await this.compileSmsContent(values);
    if (!this.state.smsError) {
      this.setState({ loading: true });
      setSubmitting(true);
      try {
        let response;
        if (!this.props.match.params.id) {
          response = await services.addAdmin(updatedValues);
        } else {
          const request = { id: this.props.match.params.id, ...updatedValues };

          response = await services.updateAdmin({
            ...this.state.data,
            ...request,
          });
        }

        NotificationManager.success(
          this.props.intl.formatMessage({
            id: _.get(response, "message", "global.operationSuccessful"),
            defaultMessage: "Operation Successful",
          }),
          this.props.intl.formatMessage({
            id: "global.success",
            defaultMessage: "Success",
          })
        );

        this.props.navigate("/admins");
      } catch (e) {
        console.error("error", e);
        NotificationManager.error(
          this.props.intl.formatMessage({
            id: _.get(e, "response.data.response", "error.unexpectedError"),
            defaultMessage: "An unexpected error has occurred",
          }),
          this.props.intl.formatMessage({
            id: "global.error",
            defaultMessage: "Error",
          })
        );
      } finally {
        this.setState({ loading: false });
        setSubmitting(false);
      }
    } else {
      setSubmitting(false);
    }
  }

  render() {
    const { intl } = this.props;
    let {
      adminName,
      mobilePhone,
      loginId,
      isActive,
      isServiceAdvisor,
      adminEmail,
      roles,
      language,
      allowScheduleNotification,
      manangeScheduleNotification,
      allowRescheduleNotification,
      manangeRescheduleNotification,
      allowCancelNotification,
      manangeCancelNotification,
      allowCreateEstimationNotification,
      manageCreateEstimationNotification,
      // allowUpdateEstimationNotification,
      // manageUpdateEstimationNotification,
    } = this.state.data;

    return (
      <Auth requireAuth={true} roles={["sadmin"]}>
        <Formik
          innerRef={(f) => {
            this.formik = f;
          }}
          enableReinitialize
          initialValues={{
            adminName: adminName || "",
            mobilePhone: mobilePhone || "",
            loginId: loginId || "",
            isActive: isActive || false,
            isServiceAdvisor: this.props.match.params.id
              ? isServiceAdvisor
              : false,
            adminEmail: adminEmail || "",
            roles: roles || [],
            language: language || "ar",
            // managersBranch: managersBranch || "",

            // schedule notification
            allowScheduleNotification: allowScheduleNotification || false,
            manangeScheduleNotification:
              manangeScheduleNotification || "immediately",

            // reschedule notification
            allowRescheduleNotification: allowRescheduleNotification || false,
            manangeRescheduleNotification:
              manangeRescheduleNotification || "immediately",

            // cancel notification
            allowCancelNotification: allowCancelNotification || false,
            manangeCancelNotification:
              manangeCancelNotification || "immediately",

            // create estimation notification
            allowCreateEstimationNotification:
              allowCreateEstimationNotification || false,
            manageCreateEstimationNotification:
              manageCreateEstimationNotification || "immediately",

            // update estimation notification
            // allowUpdateEstimationNotification:
            //   allowUpdateEstimationNotification || false,
            // manageUpdateEstimationNotification:
            //   manageUpdateEstimationNotification || "immediately",
          }}
          validationSchema={Yup.object().shape({
            adminName: Yup.string()
              .label(
                intl.formatMessage({
                  id: "admin.name",
                  defaultMessage: "Name",
                })
              )
              .trim()
              .required(),

            loginId: Yup.string()
              .label(
                intl.formatMessage({
                  id: "admin.loginId",
                  defaultMessage: "Login ID",
                })
              )
              .trim()
              .required(),

            adminEmail: Yup.string()
              .label(
                intl.formatMessage({
                  id: "admin.adminEmail",
                  defaultMessage: "Admin email",
                })
              )
              .email()
              .required(),

            mobilePhone: Yup.string()
              .label(
                intl.formatMessage({
                  id: "admin.mobilePhone",
                  defaultMessage: "Mobile phone",
                })
              )
              .min(
                13,
                intl.formatMessage({
                  id: "admin.tooShort",
                  defaultMessage: "Too Short!",
                })
              )
              .max(
                14,
                intl.formatMessage({
                  id: "admin.tooLong",
                  defaultMessage: "Too Long!",
                })
              )
              .matches(
                /^\+(?:[0-9] ?){6,14}[0-9]$/,
                intl.formatMessage({
                  id: "admin.onlyDigits",
                  defaultMessage: "Must be only digits",
                })
              )
              .required(),
            roles: Yup.array().required(
              intl.formatMessage({
                id: "admin.requiredRole",
                defaultMessage: "Role is required",
              })
            ),
          })}
          onSubmit={this.handleSubmit.bind(this)}
        >
          {(formikProps) => {
            const {
              isSubmitting,
              values,
              setFieldValue,
              setTouched,
              touched,
              errors,
            } = formikProps;

            return (
              <Layout loading={this.state.loading}>
                <style>
                  {`
            .rct-collapse {
              padding-left:0 !important;
              padding-right:0 !important;
            }
          `}
                </style>
                <div className="dashboard-table-container card-layout">
                  <div className="panel-primary">
                    <header className="panel-title">
                      {this.props.match.params.id && (
                        <FormattedMessage
                          id="admin.update"
                          defaultMessage="Update Admin"
                        />
                      )}
                      {!this.props.match.params.id && (
                        <FormattedMessage
                          id="operator.add"
                          defaultMessage="Add Admin"
                        />
                      )}
                    </header>
                    <Form>
                      <div className="dashboard-row">
                        <FieldRow
                          type="text"
                          name="adminName"
                          labelId="admin.name"
                          defaultLabel="Name"
                          placeholderId="admin.enterName"
                          defaultPlaceholder="Enter name"
                          showRequiredFlag={true}
                          formikProps={formikProps}
                        />

                        <FieldRow
                          name="mobilePhone"
                          labelId="operator.mobilePhone"
                          defaultLabel="Mobile Phone"
                          placeholderId="operator.enterMobilePhone"
                          defaultPlaceholder="Enter mobile phone"
                          showRequiredFlag={true}
                          formikProps={formikProps}
                        />

                        <FieldRow
                          name="loginId"
                          labelId="global.adminLoginId"
                          defaultLabel="Admin Login Id"
                          placeholderId="global.enterAdminLoginId"
                          defaultPlaceholder="Enter admin login id"
                          showRequiredFlag={true}
                          formikProps={formikProps}
                          disabled={appState.state.token?.role === "wsadmin"}
                        />

                        <FieldRow
                          name="adminEmail"
                          type="email"
                          labelId="admin.adminEmail"
                          defaultLabel="Admin Email"
                          placeholderId="admin.enterAdminEmail"
                          defaultPlaceholder="Enter admin email"
                          showRequiredFlag={true}
                          formikProps={formikProps}
                          disabled={appState.state.token?.role === "spadmin"}
                        />

                        <FieldRow
                          name="isActive"
                          type="checkbox"
                          labelId="operator.isActive"
                          defaultLabel="Active"
                          disabled={!this.props.match.params.id}
                          formikProps={formikProps}
                          onChange={(e) => {
                            if (!this.props.match.params.id) return;
                            setFieldValue("isActive", e?.target?.checked);
                          }}
                        />
                        <div className="row">
                          <div className="col-md-2 col-xs-12">
                            <label className="form-label caps block">
                              <FormattedMessage
                                id="admin.roles"
                                defaultMessage="Roles"
                              />
                            </label>
                          </div>
                          <div className="input-group col-md-8 col-xs-12">
                            {/* <CheckboxTree
                      nodes={this.state.roles}
                      checked={values.roles}
                      expanded={this.state.expanded}
                      onCheck={checked => {
                        setFieldValue("roles", checked);
                        setTouched({ roles: true });
                      }}
                      onExpand={expanded => this.setState({ expanded })}
                    /> */}

                            <Select
                              isMulti
                              options={this.state.roles}
                              value={this.state.roles.filter(
                                (r) => values.roles.indexOf(r.value) >= 0
                              )}
                              onChange={(c) => {
                                //const newValues = [...values.roles, c.value];
                                setFieldValue(
                                  "roles",
                                  c.map((c) => c.value)
                                );
                                setTouched({ roles: true });
                              }}
                            />
                            {touched["roles"] && errors["roles"] && (
                              <p className="text-warning">{errors["roles"]}</p>
                            )}
                          </div>
                        </div>

                        {/* <div>
                    <FieldRow
                      colSize={2}
                      name="managersBranch"
                      type="select"
                      labelId="global.managerBranch"
                      defaultLabel="Manager's Branch"
                      formikProps={formikProps}
                    >
                      <option value="">Select manager's branch</option>
                      {
                        this.state.branches?.map(({_id, name}) => {
                          return (
                            <option selected={this.state.data.managersBranch || ""} value={_id}>{name}</option>
                          )
                        })
                      }
                    </FieldRow>
                  </div> */}

                        <div>
                          <FieldRow
                            colSize={2}
                            name="language"
                            type="select"
                            labelId="global.language"
                            defaultLabel="Language"
                            formikProps={formikProps}
                          >
                            <option value="ar">
                              {this.props.intl.formatMessage({
                                id: "language.ar",
                                defaultMessage: "Arabic",
                              })}
                            </option>
                            <option value="en">
                              {this.props.intl.formatMessage({
                                id: "language.en",
                                defaultMessage: "English",
                              })}
                            </option>
                          </FieldRow>
                        </div>

                        <Notifications
                          values={values}
                          formikProps={formikProps}
                          formatMessage={this.props.intl.formatMessage}
                          smsData={
                            this.props.match.params.id && this.state.smsContent
                          }
                          smsContent={(content) =>
                            this.setState({ smsContent: content })
                          }
                        />
                        <FieldRow
                          name="isServiceAdvisor"
                          type="checkbox"
                          labelId="operator.serviceAdvisor"
                          defaultLabel="Service Advisor"
                          formikProps={formikProps}
                        />

                        {this.state.smsError && (
                          <p className="text-warning">
                            <FormattedMessage
                              id={this.state.smsError}
                              defaultMessage="Notification is allowed but its content is not set."
                            />
                          </p>
                        )}
                      </div>
                      <div className="dashboard-row -nobottom">
                        <input
                          type="submit"
                          className="btn btn-primary"
                          value={this.props.intl.formatMessage({
                            id: "global.submit",
                            defaultMessage: "Submit",
                          })}
                          onClick={() => this.compileSmsContent(values)}
                          disabled={isSubmitting}
                        />
                        <Button
                          variant="link"
                          onClick={() => this.props.navigate("/admins")}
                          disabled={isSubmitting}
                        >
                          {this.props.intl.formatMessage({
                            id: "global.cancel",
                            defaultMessage: "Cancel",
                          })}
                        </Button>
                      </div>
                    </Form>
                  </div>
                </div>
              </Layout>
            );
          }}
        </Formik>
      </Auth>
    );
  }
}

export default withRouter(injectIntl(AdminForm));
