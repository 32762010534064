import React from "react";
import { Formik, Form } from "formik";
import { Yup } from "../../util";
import FieldRow from "../shared/FieldRow";

const ServiceAdvisorListing = ({
  intl,
  handleAssignServiceAdvisor,
  serviceAdvisor,
  selectedSA,
  disabled,
}) => {
  return (
    <React.Fragment>
      <Formik
        enableReinitialize
        initialValues={{ serviceAdvisor: selectedSA || "" }}
        validationSchema={Yup.object().shape({
          serviceAdvisor: Yup.string().required(
            intl.formatMessage({
              id: "error.required",
              defaultMessage: "Required",
            })
          ),
        })}
        onSubmit={handleAssignServiceAdvisor}
      >
        {(formikProps) => {
          return (
            <Form>
              <FieldRow
                name="serviceAdvisor"
                type="select"
                defaultLabel="Service Advisor"
                labelId="order.serviceAdvisor"
                showRequiredFlag={true}
                formikProps={formikProps}
                disabled={disabled}
                authorizedFrom={disabled ? undefined : "serviceAdvisor"}
              >
                <option value="">
                  {intl.formatMessage({
                    id: "order.select",
                    defaultMessage: "Select",
                  })}
                </option>
                {serviceAdvisor &&
                  serviceAdvisor.map((sa) => (
                    <option key={sa._id} value={sa._id}>
                      {sa.fullName}
                    </option>
                  ))}
              </FieldRow>
              <input
                type="submit"
                className="btn btn-primary saveAssignServiceAdvisor"
                value={intl.formatMessage({
                  id: "global.save",
                  defaultMessage: "Save",
                })}
                disabled={disabled}
              />
            </Form>
          );
        }}
      </Formik>
    </React.Fragment>
  );
};
export default React.memo(ServiceAdvisorListing);
