import React, { Component, createRef } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Form, Formik } from "formik";
import { Yup } from "../../util";
import Layout from "../shared/Layout";
import SubscriptionService from "../../services/subscriptionService";
import services from "../../services";
import { NotificationManager } from "react-notifications";
import _ from "lodash";
import Auth from "../shared/Auth";
import FieldRow from "../shared/FieldRow";
import withRouter from "../../util/withRouter";
import appState from "../../state/AppStateContainer";
import HeaderTitle from "../shared/HeaderTitle";
import { withAuthContext } from "../../state/AuthContext";
import { ActivitiesEnum } from "../../staticData/Activities";
import CheckAccess from "../shared/CheckAccess";
import Select from "react-select";
import { carModelYears } from "../../util/utils";
import { Accordion, Button } from "react-bootstrap";
import CheckboxTree from "react-checkbox-tree";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import AutohubServices from "../../services/autohubServices";
import classNames from "classnames";

export class SubscriptionForm extends Component {
  constructor() {
    super();
    this.formik = createRef();
  }

  state = {
    carsPanel: false,
    allCarsTree: [],
    carsTree: [],
    copyCarModels: [],
    copyCarModelYears: [],
    expandedTree: [],
    suggestion: [],
    data: {},
    statusFilter: "all",
    approvalFilter: "all",
    loading: false,
    translatedData: [],
    pageData: {},
    value: {},
    servicesType: [],
    branches: [],
    subscriptionType: [
      {
        label_en: "Special Subscription",
        label_ar: "عرض خاص",
        value: "specialSubscription",
      },
      {
        label_en: "Everyday Deal",
        label_ar: "الصفقة اليومية",
        value: "everydayDeal",
      },
    ],
  };

  async fetchServices() {
    const servicesType = await services.getServicesName({
      fromCategoryScreen: false,
    });
    this.setState({ servicesType });
  }

  async fetchServicesTypesCommonBranches(selectedBranchIds) {
    try {
      const servicesType = await services.getServicesNameCommonBranches({
        selectedBranchIds,
      });
      this.setState({ servicesType });
    } catch (err) {
      console.log("err", err);
      this.setState({ loading: false });
    }
  }

  async fetchServicesTypesCommonCarsBrandModels(selectedServiceTypeIds) {
    try {
      const servicesTypeCarModels =
        await services.getCommonServiceTypeCarBrandModels({
          selectedServiceTypeIds,
        });

      if (servicesTypeCarModels.length >= 0) {
        const lang = appState.state.language;
        const allCarModels = [];
        let commonCarYears = [];
        const carsTree = [];
        for (const service of servicesTypeCarModels) {
          carsTree.push({
            value: service?.brand.en,
            label: service?.brand[lang],
            children: service?.models.map((m) => {
              const model = {
                value: m.en,
                label: m[lang],
              };

              allCarModels.push(model);
              return model;
            }),
          });
          commonCarYears = [
            ...new Set([...commonCarYears, ...service?.modelYear]),
          ];
        }

        this.setState({ carsTree, commonCarYears });
      }
    } catch (err) {
      console.log("err", err);
    } finally {
      this.setState({ loading: false });
    }
  }

  async componentDidMount() {
    this.setState({ loading: true });

    try {
      this.subscription = appState.emitter.addListener(
        "onLanguageChange",
        () => {
          this.formik.validateForm();
        }
      );

      const lang = appState.state.language;
      await this.getAllCarsTree(lang);
      const branchesData = await services.getBranchesForPromo();

      await this.fetchServices();
      if (this.props.match.params.id) {
        let data = this.props.match.params.id
          ? await SubscriptionService.getSubscriptionById(
              this.props.match.params.id,
              { progressBar: true }
            )
          : {};

        data.branches = data?.branches?.map((x) => {
          return {
            value: x._id,
            label:
              appState.state.language === "en"
                ? x.name?.value_en
                : x.name?.value_ar || x.name?.value_en,
            type: "branches",
          };
        });
        await this.handleServicesAgainstSelectedBranches(
          data.branches,
          this.formik.setFieldValue,
          this.formik.setTouched
        );
        this.assignCommonServicesCars(data?.cars);
        await this.fetchServicesTypesCommonCarsBrandModels(data?.serviceType);

        this.setState({ data });
      }

      this.setState({
        branches: [
          {
            label: "branches",
            options: branchesData?.map((x) => {
              return {
                value: x._id,
                label:
                  appState.state.language === "en"
                    ? x.name?.value_en
                    : x.name?.value_ar || x.name?.value_en,
                type: "branches",
              };
            }),
          },
        ],
      });
    } catch (e) {
      this.props.navigate("/error", {
        error: _.get(e, "response.data.error", "error.unexpectedError"),
      });
    } finally {
      this.setState({ loading: false });
    }
  }

  componentWillUnmount() {
    this.subscription.remove();
  }

  async getAllCarsTree(lang) {
    const cars = await AutohubServices.getCarsListing();
    const allCarModels = [];
    const carsTree = cars.map(({ brand, models }) => ({
      value: brand.en,
      label: brand[lang],
      children: models.map((m) => {
        const model = {
          value: m.en,
          label: m[lang],
        };

        allCarModels.push(model);
        return model;
      }),
    }));
    const allCarsTree = [
      {
        value: "all",
        label: "ALL BRANDS AND MODELS",
        children: allCarModels,
      },
    ];

    this.setState({ carsTree, allCarsTree });
  }

  async handleSubmit(values, { setSubmitting, setErrors }) {
    setSubmitting(true);
    this.setState({ loading: true });

    try {
      const { carModels, carModelYears, ...rest } = values;
      const cars = { models: carModels, years: carModelYears };
      const price = rest?.price;
      const total =
        Number(rest?.labour || 0) +
        Number(rest?.parts || 0) +
        Number(rest?.paint || 0) +
        Number(rest?.oil || 0) +
        Number(rest?.boughtOut || 0) +
        // Number(rest?.coolite || 0) +
        Number(rest?.sublet || 0);

      if (Number(total) !== price) {
        return setErrors({
          ...this.formik.errors,
          amountExceeded: this.props.intl.formatMessage({
            id: "subscription.amountExceededMessage",
            defaultMessage:
              "Sum of Labour, Parts, Bought Out, Paint, Oil and Sublet cannot greater and less than the Price",
          }),
        });
      }

      rest.serviceType = rest?.serviceType?.map((service) =>
        service?.value ? service?.value : service
      );

      let branches = rest?.branches
        ?.filter((x) => x.type === "branches")
        ?.map((x) => x.value);

      if (branches[0] === "All Branches") {
        branches = await services.getAllBranches();
      }

      const payload = {
        ...rest,
        branches: branches,
        cars,
      };

      let response;
      if (this.props.match.params.id) {
        payload.startDate = this.state.data.startDate;
        payload.endDate = this.state.data.endDate;
        response = await SubscriptionService.updateSubscription({
          ...payload,
          id: this.props.match.params.id,
        });
      } else {
        response = await SubscriptionService.addSubscription({
          ...payload,
        });
      }
      this.props.navigate("/subscriptions");
      NotificationManager.success(
        this.props.intl.formatMessage({
          id: "global.operationSuccessful",
          defaultMessage: "Operation Successful",
        }),
        this.props.intl.formatMessage({
          id: "global.success",
          defaultMessage: "Success",
        })
      );
    } catch (e) {
      if (e.response.data.key === "error.duplicateSubscription") {
        return NotificationManager.error(
          this.props.intl.formatMessage({
            id: "error.duplicateSubscription",
            defaultMessage:
              appState.state.language === "en"
                ? "Subscription has already been created with this name"
                : "تم إنشاء العرض بهذا الاسم بالفعل",
          }),
          this.props.intl.formatMessage({
            id: "global.error",
            defaultMessage: "Error",
          })
        );
      }
      NotificationManager.error(
        this.props.intl.formatMessage({
          id: _.get(e, "response.data.error", "error.unexpectedError"),
          defaultMessage: "An unexpected error has occurred",
        }),
        this.props.intl.formatMessage({
          id: "global.error",
          defaultMessage: "Error",
        })
      );
    } finally {
      setSubmitting(false);
      this.setState({ loading: false });
    }
  }

  handleChange = async (
    selectedServiceType,
    formikProps,
    filterSelectedIds
  ) => {
    formikProps.setFieldValue("serviceType", selectedServiceType);
    formikProps.setTouched({ serviceType: true });
    if (filterSelectedIds.length > 0) {
      await this.fetchServicesTypesCommonCarsBrandModels(filterSelectedIds);
    } else {
      this.setState({ commonCarYears: carModelYears });
      formikProps.setFieldValue("carModels", []);
      formikProps.setFieldValue("carModelYears", []);
      await this.getAllCarsTree(appState.state.language);
    }
  };

  handleCarModelYearCheck({ target }, { values, setFieldValue }) {
    const { value, checked } = target;
    const modelYearsValue = values.carModelYears || [];
    checked
      ? modelYearsValue.push(value)
      : modelYearsValue.splice(modelYearsValue.indexOf(value), 1);

    setFieldValue("carModelYears", modelYearsValue);
  }

  async handleServicesAgainstSelectedBranches(v, setFieldValue, setTouched) {
    if (!v?.length) {
      this.setState({ loading: true });
      await this.fetchServices();
      setFieldValue("branches", "");
      return;
    }
    const filterSelectedIds = v.map((branch) => {
      return branch.value;
    });
    if (v.some((item) => item.value === "All Branches")) {
      // If "All Branches" is selected, set branches to only "All Branches"
      setFieldValue("branches", [
        {
          value: "All Branches",
          label: "All Branches",
          type: "branches",
        },
      ]);
    } else {
      // If other options are selected, remove "all" option and update branches
      setFieldValue(
        "branches",
        v.filter((item) => item.value !== "All Branches")
      );
    }
    setTouched({ branches: true });
    //  call api
    if (
      filterSelectedIds.length >= 0 &&
      !v.some((item) => item.value === "All Branches")
    ) {
      await this.fetchServicesTypesCommonBranches(filterSelectedIds);
    }
    this.setState({ loading: false });
  }

  handleServiceChange(selectedServiceType, formikProps) {
    const filterSelectedIds = selectedServiceType?.map((car) => {
      return car.value;
    });
    if (selectedServiceType?.length > 1) {
      formikProps.setFieldValue("quota", 1);
    }
    if (formikProps)
      this.handleChange(selectedServiceType, formikProps, filterSelectedIds);
  }

  assignCommonServicesCars(cars) {
    const carModels = [];
    const carModelYears = [];
    if (cars?.length)
      cars.forEach(({ product, modelYear }) => {
        carModels.push(product.value_en);
        carModelYears.push(modelYear);
      });
    this.setState({
      copyCarModels: [...new Set(carModels)],
      copyCarModelYears: [...new Set(carModelYears)],
    });
  }

  sanitizeHtml(html) {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    tempDiv.querySelectorAll("br").forEach((br) => br.remove());
    const isEmpty = tempDiv.textContent.trim() === "";
    return isEmpty ? "" : tempDiv.innerHTML;
  }

  _renderBody(formikProps) {
    const { carsPanel, allCarsTree, carsTree, expandedTree, commonCarYears } =
      this.state;
    const { isSubmitting, values, setFieldValue, setTouched, errors, touched } =
      formikProps;

    return (
      <Layout noLayout={this.props.noLayout} loading={this.state.loading}>
        <HeaderTitle
          id="subscription.subscription"
          defaultMessage="Subscription Packages"
        />
        <div className="dashboard-table-container card-layout">
          <div className="panel-primary">
            <header className="panel-title">
              {!this.props.match.params.id ? (
                <FormattedMessage
                  id="subscription.addSubscription"
                  defaultMessage="Add Subscription"
                />
              ) : (
                <FormattedMessage
                  id="subscription.updateSubscription"
                  defaultMessage="Update Subscription"
                />
              )}
            </header>
            <Form>
              <div>
                <div className="dashboard-row">
                  <FieldRow
                    type="text"
                    name="name_en"
                    labelId="subscription.subscriptionTitleEnglish"
                    defaultLabel="Subscription Title English"
                    placeholderId="subscription.subscriptionTitleEnglish"
                    defaultPlaceholder="Subscription Title English"
                    showRequiredFlag={true}
                    formikProps={formikProps}
                    disabled={this.state.data.createdBy === "inspirage"}
                  />
                  <FieldRow
                    type="text"
                    name="name_ar"
                    labelId="subscription.subscriptionTitleArabic"
                    defaultLabel="Subscription Title Arabic"
                    placeholderId="subscription.subscriptionTitleArabic"
                    defaultPlaceholder="Subscription Title Arabic"
                    showRequiredFlag={true}
                    formikProps={formikProps}
                    disabled={this.state.data.createdBy === "inspirage"}
                  />
                  <div className="row" style={{ marginTop: "20px" }}>
                    <div className="col-md-2 col-xs-12">
                      <label className="form-label caps block">
                        <FormattedMessage
                          id="promo.branches"
                          defaultMessage={"Branches Selection"}
                        />
                      </label>
                    </div>

                    <div className="col-md-8 col-xs-12 no-bottom ">
                      <Select
                        name="branches"
                        options={[
                          { value: "All Branches", label: "All Branches" }, // "All Branches" option at the top
                          ...this.state.branches, // Rest of the options
                        ]}
                        placeholder={this.props.intl.formatMessage({
                          id: "promo.allBranches",
                          defaultMessage: "Select Branch(es)",
                        })}
                        value={values.branches}
                        onChange={(e) =>
                          this.handleServicesAgainstSelectedBranches(
                            e,
                            setFieldValue,
                            setTouched
                          )
                        }
                        isMulti={true}
                        closeMenuOnSelect={false}
                        isDisabled={false}
                      />
                    </div>
                  </div>

                  <div className="row" style={{ marginTop: "20px" }}>
                    <div className="col-md-2 col-xs-12">
                      <label className="form-label caps block">
                        <FormattedMessage
                          id="subscription.serviceType"
                          defaultMessage="Service Type"
                        />
                      </label>
                    </div>

                    <div className="col-md-8 col-xs-12 no-bottom">
                      <Select
                        isDisabled={false}
                        isClearable={true}
                        isMulti={true}
                        options={this.state?.servicesType?.map((s) => ({
                          value: s.id.toString(),
                          label:
                            appState.state.language === "en"
                              ? s.englishName
                              : s.arabicName,
                        }))}
                        placeholder={this.props.intl.formatMessage({
                          id: "category.selectServiceType",
                          defaultMessage: "Select Service Type",
                        })}
                        onChange={(e) =>
                          this.handleServiceChange(e, formikProps)
                        }
                        // value={values?.serviceType?.map((item) => {
                        //   const service = this.state?.servicesType?.find(
                        //     (serviceType) =>
                        //       serviceType?.id ===
                        //       (item?.value ? item?.value : item)
                        //   );
                        //   return {
                        //     value: item?.value ? item?.value : item,
                        //     label:
                        //       appState.state.language === "en"
                        //         ? service?.englishName
                        //         : service?.arabicName,
                        //   };
                        // })}
                        value={values?.serviceType
                          ?.map((item) => {
                            const service = this.state?.servicesType?.find(
                              (serviceType) =>
                                serviceType?.id ===
                                (item?.value ? item?.value : item)
                            );

                            // Check if corresponding text exists before including in the value array
                            if (
                              appState.state.language === "en" &&
                              service?.englishName
                            ) {
                              return {
                                value: item?.value ? item?.value : item,
                                label: service?.englishName,
                              };
                            } else if (
                              appState.state.language === "ar" &&
                              service?.arabicName
                            ) {
                              return {
                                value: item?.value ? item?.value : item,
                                label: service?.arabicName,
                              };
                            }

                            return null; // Exclude if text does not exist
                          })
                          .filter(Boolean)}
                      />
                    </div>
                  </div>

                  {/* <FieldRow
                      formikProps={formikProps}
                      type="select"
                      name="serviceType"
                      labelId="subscription.serviceType"
                      defaultLabel="Service Type"
                      placeholderId="subscription.serviceType"
                      defaultPlaceholder="Service type"
                      showRequiredFlag={true}
                      disabled={this.state.data.createdBy === "inspirage"}
                    >
                      <option value="">
                        {this.props.intl.formatMessage({
                          id: "subscription.selectServiceType",
                          defaultMessage: "Select Service Type",
                        })}
                      </option>
                      {this.state.servicesType?.map((service, i) => {
                        return (
                          <option value={service.id} key={i}>
                            {appState.state.language === "en"
                              ? service.englishName
                              : service.arabicName}
                          </option>
                        );
                      })}
                    </FieldRow> */}

                  <FieldRow
                    type="html"
                    name="description_en"
                    labelId="subscription.descriptionEnglish"
                    defaultLabel="Subscription Description English"
                    showRequiredFlag={true}
                    formikProps={formikProps}
                    textEditor={true}
                  />
                  <FieldRow
                    type="html"
                    name="description_ar"
                    labelId="subscription.descriptionArabic"
                    defaultLabel="Subscription Description Arabic"
                    showRequiredFlag={true}
                    formikProps={formikProps}
                    textEditor={true}
                  />
                  <FieldRow
                    type="html"
                    name="terms_en"
                    labelId="subscription.terms_en"
                    defaultLabel="Terms and Conditions English"
                    showRequiredFlag={true}
                    formikProps={formikProps}
                    textEditor={true}
                  />
                  <FieldRow
                    type="html"
                    name="terms_ar"
                    labelId="subscription.terms_ar"
                    defaultLabel="Terms and Conditions Arabic"
                    showRequiredFlag={true}
                    formikProps={formikProps}
                    textEditor={true}
                  />

                  <FieldRow
                    type="number"
                    name="quota"
                    labelId="subscription.quota"
                    defaultLabel="Quota"
                    placeholderId="subscription.quota"
                    defaultPlaceholder="Quota"
                    showRequiredFlag={true}
                    onChange={(e) => {
                      if (values?.serviceType?.length > 1) {
                        return;
                      } else {
                        setFieldValue("quota", e?.target?.value);
                      }
                    }}
                    formikProps={formikProps}
                    disabled={values?.serviceType?.length > 1}
                  />
                  <hr />
                  <div>
                    {errors && errors["amountExceeded"] && (
                      <p style={{ color: "red", marginLeft: "15%" }}>
                        {errors["amountExceeded"]}
                      </p>
                    )}
                  </div>
                  <FieldRow
                    type="number"
                    name="price"
                    labelId="subscription.price"
                    defaultLabel="Price"
                    placeholderId="subscription.price"
                    defaultPlaceholder="Price"
                    showRequiredFlag={true}
                    formikProps={formikProps}
                    disabled={this.state.data.createdBy === "inspirage"}
                  />
                  <hr />
                  <div>
                    {errors && errors["amountExceeded"] && (
                      <p style={{ color: "red", marginLeft: "15%" }}>
                        {errors["amountExceeded"]}
                      </p>
                    )}
                  </div>
                  <FieldRow
                    formikProps={formikProps}
                    type="number"
                    name="labour"
                    labelId="subscription.labour"
                    defaultLabel="Labour"
                    placeholderId="subscription.labour"
                    defaultPlaceholder="Labour"
                  />
                  <FieldRow
                    formikProps={formikProps}
                    type="number"
                    name="parts"
                    labelId="subscription.parts"
                    defaultLabel="Parts"
                    placeholderId="subscription.parts"
                    defaultPlaceholder="Parts"
                  />
                  <FieldRow
                    formikProps={formikProps}
                    type="number"
                    name="boughtOut"
                    labelId="subscription.boughtOut"
                    defaultLabel="Bought Out"
                    placeholderId="subscription.boughtOut"
                    defaultPlaceholder="Bought Out"
                  />
                  <FieldRow
                    formikProps={formikProps}
                    type="number"
                    name="paint"
                    labelId="subscription.paint"
                    defaultLabel="Paint"
                    placeholderId="subscription.paint"
                    defaultPlaceholder="Paint"
                  />
                  <FieldRow
                    formikProps={formikProps}
                    type="number"
                    name="oil"
                    labelId="subscription.oil"
                    defaultLabel="Oil"
                    placeholderId="subscription.oil"
                    defaultPlaceholder="Oil"
                  />
                  <FieldRow
                    formikProps={formikProps}
                    type="number"
                    name="sublet"
                    labelId="subscription.sublet"
                    defaultLabel="Sublet"
                    placeholderId="subscription.sublet"
                    defaultPlaceholder="Sublet"
                  />

                  <FieldRow
                    name="thumbnail_en"
                    disabled={false}
                    cameraIcon={true}
                    type="file"
                    image={true}
                    showThumbnail={true}
                    labelId="subscription.thumbnail_en"
                    defaultLabel="Thumbnail En"
                    placeholderId="subscription.thumbnail_en"
                    defaultPlaceholder="Subscription Image"
                    showRequiredFlag={true}
                    formikProps={formikProps}
                    aspectRatio={320 / 320}
                    imageSize="W:320 x H:320"
                  />
                  <FieldRow
                    name="thumbnail_ar"
                    disabled={false}
                    cameraIcon={true}
                    type="file"
                    image={true}
                    showThumbnail={true}
                    labelId="subscription.thumbnail_ar"
                    defaultLabel="Thumbnail Ar"
                    placeholderId="subscription.thumbnail_ar"
                    defaultPlaceholder="Subscription Image"
                    showRequiredFlag={true}
                    formikProps={formikProps}
                    aspectRatio={320 / 320}
                    imageSize="W:320 x H:320"
                  />
                  <FieldRow
                    name="banner_en"
                    disabled={false}
                    cameraIcon={true}
                    type="file"
                    image={true}
                    showThumbnail={true}
                    labelId="subscription.banner_en"
                    defaultLabel="Banner En"
                    placeholderId="subscription.banner_en"
                    defaultPlaceholder="Subscription Banner"
                    showRequiredFlag={true}
                    formikProps={formikProps}
                    aspectRatio={1130 / 450}
                    imageSize="W:1130 x H:450"
                    forServices={true}
                  />
                  <FieldRow
                    name="banner_ar"
                    disabled={false}
                    cameraIcon={true}
                    type="file"
                    image={true}
                    showThumbnail={true}
                    labelId="subscription.banner_ar"
                    defaultLabel="Banner Ar"
                    placeholderId="subscription.banner_ar"
                    defaultPlaceholder="Subscription Banner"
                    showRequiredFlag={true}
                    formikProps={formikProps}
                    aspectRatio={1130 / 450}
                    imageSize="W:1130 x H:450"
                    forServices={true}
                  />
                  <FieldRow
                    type="checkbox"
                    name="isActive"
                    labelId="subscription.isActive"
                    defaultLabel="Active"
                    placeholderId="subscription.isActive"
                    formikProps={formikProps}
                  />

                  <br />

                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header
                        style={{
                          backgroundColor:
                            errors.carModelYears && touched.carModelYears
                              ? "red"
                              : "#555",
                        }}
                        onClick={() => this.setState({ carsPanel: !carsPanel })}
                      >
                        <FormattedMessage
                          id="service.assignCars"
                          defaultMessage="Assign Cars"
                        />
                        <span
                          className={classNames("glyphicon", "pull-right", {
                            "glyphicon-plus": !carsPanel,
                            "glyphicon-minus": carsPanel,
                          })}
                          aria-hidden="true"
                        />
                      </Accordion.Header>
                      {carsTree.length > 0 && (
                        <Accordion.Body className="cars-for-service">
                          <CheckboxTree
                            nodes={allCarsTree}
                            checked={values.carModels}
                            onCheck={(selectedModels) => {
                              setFieldValue("carModels", selectedModels);
                            }}
                            nativeCheckboxes={true}
                            showNodeIcon={false}
                            icons={{ expandClose: null }}
                            iconsClass="fa5"
                          />
                          <CheckboxTree
                            nodes={carsTree}
                            checked={values.carModels}
                            onCheck={(selectedModels) => {
                              setFieldValue("carModels", selectedModels);
                            }}
                            expanded={expandedTree}
                            onExpand={(expandedTree) =>
                              this.setState({ expandedTree })
                            }
                            iconsClass="fa5"
                            nativeCheckboxes={true}
                            showExpandAll={true}
                            showNodeIcon={false}
                          />

                          {values.carModels?.length ? (
                            <div className="car-model-years">
                              {commonCarYears?.map((year) => {
                                return (
                                  <label htmlFor={year}>
                                    <input
                                      type="checkbox"
                                      name="carModelYears"
                                      id={year}
                                      value={year}
                                      onChange={(e) =>
                                        this.handleCarModelYearCheck(
                                          e,
                                          formikProps
                                        )
                                      }
                                      defaultChecked={values.carModelYears.includes(
                                        year
                                      )}
                                    />
                                    <span>{year}</span>
                                  </label>
                                );
                                <p></p>;
                              })}
                            </div>
                          ) : null}
                          {errors.carModelYears && (
                            <span className="car-model-year-error">
                              <FormattedMessage
                                id="service.carModelYearError"
                                defaultMessage="Please select model years also for selected cars"
                              />
                            </span>
                          )}
                        </Accordion.Body>
                      )}
                    </Accordion.Item>
                  </Accordion>
                </div>
                <div className="dashboard-row -nobottom">
                  <CheckAccess
                    activityId={
                      this?.props?.match?.params?.id?.length > 0
                        ? ActivitiesEnum.subscription_update_edit
                        : ActivitiesEnum.subscription_new_package
                    }
                  >
                    <input
                      type="submit"
                      className="btn btn-primary"
                      value={this.props.intl.formatMessage({
                        id: "global.submit",
                        defaultMessage: "Submit",
                      })}
                      disabled={isSubmitting}
                    />
                  </CheckAccess>
                  <Button
                    variant="link"
                    onClick={() => this.props.navigate("/subscriptions")}
                    disabled={isSubmitting}
                  >
                    {this.props.intl.formatMessage({
                      id: "global.cancel",
                      defaultMessage: "Cancel",
                    })}
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </Layout>
    );
  }

  render() {
    const { intl } = this.props;
    const { data, copyCarModelYears, copyCarModels } = this.state;

    return (
      <Auth requireAuth={true} roles={["sadmin", "wsadmin", "spadmin"]}>
        <Formik
          enableReinitialize
          innerRef={(formik) => (this.formik = formik)}
          initialValues={{
            name_en: data?.name?.value_en || "",
            name_ar: data?.name?.value_ar || "",
            description_en: data?.description?.value_en,
            description_ar: data?.description?.value_ar,
            terms_en: data?.termsAndConditions?.value_en,
            terms_ar: data?.termsAndConditions?.value_ar,
            quota: data.quota,
            price: data.price,
            labour: data.labour,
            parts: data.parts,
            boughtOut: data.boughtOut,
            paint: data.paint,
            oil: data.oil,
            sublet: data.sublet,
            branches: this.state.data.branches || [],
            serviceType: this.state?.data?.serviceType?.map((item) => {
              const service = this.state?.servicesType?.find(
                (serviceType) =>
                  serviceType?.id === (item?.value ? item?.value : item)
              );
              return {
                value: item?.value ? item?.value : item,
                label:
                  appState.state.language === "en"
                    ? service?.englishName
                    : service?.arabicName,
              };
            }),
            // serviceType: data.serviceType || "",
            // subscriptionCreatedAt:
            //   moment(data.subscriptionCreatedAt).format("MMMM Do YYYY") || "",
            isActive: data.isActive || "",
            thumbnail_en: data.thumbnail_en || "",
            thumbnail_ar: data.thumbnail_ar || "",
            banner_en: data.banner_en || "",
            banner_ar: data.banner_ar || "",
            carModels: copyCarModels || [],
            carModelYears: copyCarModelYears || [],
          }}
          validationSchema={Yup.object().shape({
            name_en: Yup.string()
              .hasNoArabic(
                intl.formatMessage({
                  id: "error.arabicCharactersAreNotAllowedInThisField",
                  defaultMessage:
                    "Arabic characters are not allowed in this field",
                })
              )
              .label(
                intl.formatMessage({
                  id: "location.subscriptionCode",
                  defaultMessage: "Subscription title en",
                })
              )
              .trim()
              .required(),
            name_ar: Yup.string()
              .label(
                intl.formatMessage({
                  id: "location.subscriptionCode",
                  defaultMessage: "Subscription title ar",
                })
              )
              .trim()
              .required(),
            description_en: Yup.string()

              .label(
                intl.formatMessage({
                  id: "location.descriptionEnglish",
                  defaultMessage: "Description English",
                })
              )
              .test(
                "is-empty",
                "Description is required",
                (value) => this.sanitizeHtml(value).trim() !== ""
              )
              .required(),
            description_ar: Yup.string()

              .label(
                intl.formatMessage({
                  id: "location.descriptionArabic",
                  defaultMessage: "Description Arabic",
                })
              )
              .test(
                "is-empty",
                "Description is required",
                (value) => this.sanitizeHtml(value).trim() !== ""
              )
              .required(),
            terms_en: Yup.string()

              .label(
                intl.formatMessage({
                  id: "location.termsEnglish",
                  defaultMessage: "Terms English",
                })
              )
              .test(
                "is-empty",
                "Terms is required",
                (value) => this.sanitizeHtml(value).trim() !== ""
              )
              .required(),
            terms_ar: Yup.string()

              .label(
                intl.formatMessage({
                  id: "location.termsArabic",
                  defaultMessage: "Terms Arabic",
                })
              )
              .test(
                "is-empty",
                "Terms is required",
                (value) => this.sanitizeHtml(value).trim() !== ""
              )
              .required(),
            quota: Yup.number()
              .label(
                intl.formatMessage({
                  id: "location.quota",
                  defaultMessage: "Quota",
                })
              )
              .min(
                1,
                intl.formatMessage({
                  id: "location.minQuota",
                  defaultMessage: "Invalid quota (< 1)",
                })
              )
              .required(),
            serviceType: Yup.array()
              .label(
                intl.formatMessage({
                  id: "location.serviceType",
                  defaultMessage: "Service Type",
                })
              )
              .required(),
            branches: Yup.array()
              .label(
                intl.formatMessage({
                  id: "location.branches",
                  defaultMessage: "Branches",
                })
              )
              .required(),
            thumbnail_en: Yup.string()
              .label(
                intl.formatMessage({
                  id: "location.thumbnail_en",
                  defaultMessage: "Thumbnail en",
                })
              )
              .required(),
            thumbnail_ar: Yup.string()
              .label(
                intl.formatMessage({
                  id: "location.thumbnail_ar",
                  defaultMessage: "Thumbnail ar",
                })
              )
              .required(),
            banner_en: Yup.string()
              .label(
                intl.formatMessage({
                  id: "location.banner_en",
                  defaultMessage: "Banner en",
                })
              )
              .required(),
            banner_ar: Yup.string()
              .label(
                intl.formatMessage({
                  id: "location.banner_ar",
                  defaultMessage: "Banner ar",
                })
              )
              .required(),
            price: Yup.number()
              .label(
                intl.formatMessage({
                  id: "location.price",
                  defaultMessage: "Price",
                })
              )
              .min(
                1,
                intl.formatMessage({
                  id: "location.minPrice",
                  defaultMessage: "Invalid price (< 1)",
                })
              )
              .required(),
            labour: Yup.number().required(),
            parts: Yup.number().required(),
            boughtOut: Yup.number()
              .label(
                intl.formatMessage({
                  id: "subscription.boughtOut",
                  defaultMessage: "Bought Out",
                })
              )
              .required(),
            paint: Yup.number().required(),
            oil: Yup.number().required(),
            sublet: Yup.number().required(),
            carModels: Yup.array().of(Yup.string()),
            carModelYears: Yup.array().when("carModels", {
              is: (carModels) => carModels?.length,
              then: (schema) => schema.min(1).of(Yup.string().required()),
            }),
          })}
          onSubmit={this.handleSubmit.bind(this)}
        >
          {this._renderBody.bind(this)}
        </Formik>
      </Auth>
    );
  }
}

export default withAuthContext(withRouter(injectIntl(SubscriptionForm)));
