import React, { Component, createRef } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Formik, Form } from "formik";
import { Yup } from "../../util";
import { Modal, Button } from "react-bootstrap";
import { Tabs, Tab } from "react-bootstrap-tabs";
import appState from "../../state/AppStateContainer";
import _ from "lodash";
class SmsEmailContentModal extends Component {
  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (this.formik) {
        this.formik.resetForm();
      }
    }
  }

  renderSmsSelectField = (type, otherCase, onChange) => {
    const { intl, notificationName } = this.props;
    return (
      <select
        ref={(c) => {
          if (type == "en") {
            this.smsVariableEn = c;
          } else {
            this.smsVariableAr = c;
          }
          //   if (otherCase) {
          //     this.otherSmsVarEn = c;
          //   } else {
          //     this.smsVariableEn = c;
          //   }
          // } else {
          //   if (otherCase) {
          //     this.otherSmsVarAr = c;
          //   } else {
          //     this.smsVariableAr = c;
          //   }
        }}
        onChange={onChange}
        className="form-control"
      >
        <option value="select">
          {intl.formatMessage({
            id: "admin.select",
            defaultMessage: "Select...",
          })}
        </option>
        {notificationName == "createEstimationNotification" ? (
          <>
            <option value="{firstName}">
              {intl.formatMessage({
                id: "admin.firstName",
                defaultMessage: "Customer First Name",
              })}
            </option>
            <option value="{lastName}">
              {intl.formatMessage({
                id: "admin.lastName",
                defaultMessage: "Customer Last Name",
              })}
            </option>
            <option value="{phoneNumber}">
              {intl.formatMessage({
                id: "admin.phoneNumber",
                defaultMessage: "Phone Number",
              })}
            </option>
            {!otherCase ? (
              <>
                <option value="{brand}">
                  {intl.formatMessage({
                    id: "admin.brand",
                    defaultMessage: "Car Brand",
                  })}
                </option>
                <option value="{model}">
                  {intl.formatMessage({
                    id: "admin.model",
                    defaultMessage: "Car Model",
                  })}
                </option>
              </>
            ) : (
              <>
                <option value="{otherBrand}">
                  {intl.formatMessage({
                    id: "admin.brand",
                    defaultMessage: "Car Brand",
                  })}
                </option>
                <option value="{otherModel}">
                  {intl.formatMessage({
                    id: "admin.model",
                    defaultMessage: "Car Model",
                  })}
                </option>
              </>
            )}
            <option value="{year}">
              {intl.formatMessage({
                id: "admin.year",
                defaultMessage: "Year",
              })}
            </option>
            <option value="{licencePlate}">
              {intl.formatMessage({
                id: "admin.licencePlate",
                defaultMessage: "Car Plate ID",
              })}
            </option>
            <option value="{createdDate}">
              {intl.formatMessage({
                id: "admin.createdDate",
                defaultMessage: "Estimation Created Date",
              })}
            </option>
            <option value="{createdTime}">
              {intl.formatMessage({
                id: "admin.createdTime",
                defaultMessage: "Estimation Created Time",
              })}
            </option>
          </>
        ) : (
          <>
            <option value="{date}">
              {intl.formatMessage({
                id: "admin.date",
                defaultMessage: "Date",
              })}
            </option>
            <option value="{time}">
              {intl.formatMessage({
                id: "admin.time",
                defaultMessage: "Time",
              })}
            </option>
            <option value="{branchName}">
              {intl.formatMessage({
                id: "admin.amount",
                defaultMessage: "Branch Name",
              })}
            </option>
          </>
        )}
      </select>
    );
  };

  renderVariableDropDown = (type, otherCase, onChange) => {
    const { mode } = this.props;

    if (mode == "sms") {
      return (
        <div>
          <FormattedMessage
            id="admin.selectVariable"
            defaultMessage="Select Variable"
          />
          <div>{this.renderSmsSelectField(type, otherCase, onChange)}</div>
        </div>
      );
    }
  };

  switchTabs = (errors) => {
    // Bad Code: Need to replace this with states later on.
    let tabs = document.querySelectorAll(".message-content ul li a");
    if (errors["englishValueSMS"] || errors["arabicValueSMS"]) {
      tabs[0].click();
    } else {
      tabs[1].click();
    }
  };

  render() {
    const { intl, show, data, notificationName } = this.props;

    let modalData = {};
    let index = notificationName + "Content";
    let smsContent = data.find((content) => content[index]);
    if (smsContent) {
      modalData = smsContent[index];
    }

    return (
      <Modal
        show={show}
        onHide={() => {
          this.props.onCancel && this.props.onCancel();
        }}
        className="sms-email-modal"
      >
        <Modal.Header closeButton={true}>
          <h4>
            <FormattedMessage
              id={"translation." + notificationName + "Content"}
              defaultMessage={"Content"}
            />
          </h4>
        </Modal.Header>
        <Modal.Body>
          <Formik
            ref={(f) => (this.formik = f)}
            validationSchema={Yup.object().shape(
              _({
                englishValueSMS: Yup.string()
                  .label(
                    intl.formatMessage({
                      id: "translation.englishSMS",
                      defaultMessage: "English",
                    })
                  )
                  .trim()
                  .required(),
                arabicValueSMS: Yup.string()
                  .label(
                    intl.formatMessage({
                      id: "translation.arabicSMS",
                      defaultMessage: "Arabic",
                    })
                  )
                  .trim()
                  .required(),

                englishValueEmail: Yup.string()
                  .label(
                    intl.formatMessage({
                      id: "admin.englishEmail",
                      defaultMessage: "English",
                    })
                  )
                  .trim()
                  .required(),
                arabicValueEmail: Yup.string()
                  .label(
                    intl.formatMessage({
                      id: "translation.arabicEmail",
                      defaultMessage: "Arabic",
                    })
                  )
                  .trim()
                  .required(),
                // englishOtherValueSMS: Yup.string()
                //   .label(
                //     intl.formatMessage({
                //       id: "translation.otherEnglishSMS",
                //       defaultMessage: "English",
                //     })
                //   )
                //   .trim()
                //   .required(),
                // arabicOtherValueSMS: Yup.string()
                //   .label(
                //     intl.formatMessage({
                //       id: "translation.otherArabicSMS",
                //       defaultMessage: "Arabic",
                //     })
                //   )
                //   .trim()
                //   .required(),

                // englishOtherValueEmail: Yup.string()
                //   .label(
                //     intl.formatMessage({
                //       id: "admin.otherEnglishEmail",
                //       defaultMessage: "English",
                //     })
                //   )
                //   .trim()
                //   .required(),
                // arabicOtherValueEmail: Yup.string()
                //   .label(
                //     intl.formatMessage({
                //       id: "translation.otherArabicEmail",
                //       defaultMessage: "Arabic",
                //     })
                //   )
                //   .trim()
                //   .required(),
              })
                .omitBy(_.isUndefined)
                .value()
            )}
            onSubmit={(values) => {
              this.props.onSubmit && this.props.onSubmit(values);
            }}
            initialValues={{
              englishValueSMS: modalData.englishValueSMS || "",
              arabicValueSMS: modalData.arabicValueSMS || "",
              englishValueEmail: modalData.englishValueEmail || "",
              arabicValueEmail: modalData.arabicValueEmail || "",
              // englishOtherValueSMS: modalData.englishOtherValueSMS || "",
              // arabicOtherValueSMS: modalData.arabicOtherValueSMS || "",
              // englishOtherValueEmail: modalData.englishOtherValueEmail || "",
              // arabicOtherValueEmail: modalData.arabicOtherValueEmail || "",
            }}
            render={({
              values,
              handleChange,
              touched,
              errors,
              setFieldValue,
            }) => {
              return (
                <Form className="message-content">
                  <Tabs>
                    <Tab
                      label={
                        appState.state.language === "en"
                          ? "For SMS"
                          : "للرسائل القصيرة"
                      }
                    >
                      <div className="content">
                        {this.renderVariableDropDown("en", undefined, () => {
                          if (
                            this.smsVariableEn &&
                            this.smsVariableEn.value !== "select"
                          ) {
                            setFieldValue(
                              "englishValueSMS",
                              values.englishValueSMS +
                                `%${this.smsVariableEn.value}`
                            );
                            this.smsVariableEn.value = "select";
                          }
                        })}
                        <div>
                          <FormattedMessage
                            id="translation.englishSMS"
                            defaultMessage="SMS in English"
                          />
                          <textarea
                            name="englishValueSMS"
                            value={values.englishValueSMS}
                            onChange={handleChange}
                            className="form-control"
                          />
                          {touched["englishValueSMS"] &&
                            errors["englishValueSMS"] && (
                              <p className="text-warning">
                                {errors["englishValueSMS"]}
                              </p>
                            )}
                        </div>
                      </div>

                      <hr />

                      <div className="content">
                        {this.renderVariableDropDown("ar", undefined, () => {
                          if (
                            this.smsVariableAr &&
                            this.smsVariableAr.value !== "select"
                          ) {
                            setFieldValue(
                              "arabicValueSMS",
                              values.arabicValueSMS +
                                `%${this.smsVariableAr.value}`
                            );
                            this.smsVariableAr.value = "select";
                          }
                        })}
                        <div>
                          <FormattedMessage
                            id="translation.arabicSMS"
                            defaultMessage="SMS in Arabic"
                          />
                          <textarea
                            name="arabicValueSMS"
                            value={values.arabicValueSMS}
                            onChange={handleChange}
                            className="form-control"
                          />
                          {touched["arabicValueSMS"] &&
                            errors["arabicValueSMS"] && (
                              <p className="text-warning">
                                {errors["arabicValueSMS"]}
                              </p>
                            )}
                        </div>
                      </div>
                    </Tab>

                    <Tab
                      label={
                        appState.state.language === "en"
                          ? "For Email"
                          : "للبريد الإلكتروني"
                      }
                    >
                      <div className="content">
                        {this.renderVariableDropDown("en", undefined, () => {
                          if (
                            this.smsVariableEn &&
                            this.smsVariableEn.value !== "select"
                          ) {
                            setFieldValue(
                              "englishValueEmail",
                              values.englishValueEmail +
                                `%${this.smsVariableEn.value}`
                            );
                            this.smsVariableEn.value = "select";
                          }
                        })}
                        <div>
                          <FormattedMessage
                            id="translation.englishEmail"
                            defaultMessage="Email in English"
                          />
                          <textarea
                            name="englishValueEmail"
                            value={values.englishValueEmail}
                            onChange={handleChange}
                            className="form-control"
                          />
                          {touched["englishValueEmail"] &&
                            errors["englishValueEmail"] && (
                              <p className="text-warning">
                                {errors["englishValueEmail"]}
                              </p>
                            )}
                        </div>
                      </div>

                      <hr />

                      <div className="content">
                        {this.renderVariableDropDown("ar", undefined, () => {
                          if (
                            this.smsVariableAr &&
                            this.smsVariableAr.value !== "select"
                          ) {
                            setFieldValue(
                              "arabicValueEmail",
                              values.arabicValueEmail +
                                `%${this.smsVariableAr.value}`
                            );
                            this.smsVariableAr.value = "select";
                          }
                        })}
                        <div>
                          <FormattedMessage
                            id="translation.arabicEmail"
                            defaultMessage="Email in Arabic"
                          />
                          <textarea
                            name="arabicValueEmail"
                            value={values.arabicValueEmail}
                            onChange={handleChange}
                            className="form-control"
                          />
                          {touched["arabicValueEmail"] &&
                            errors["arabicValueEmail"] && (
                              <p className="text-warning">
                                {errors["arabicValueEmail"]}
                              </p>
                            )}
                        </div>
                      </div>
                    </Tab>
                  </Tabs>
                  {/* <Tabs>
                    <Tab
                      label={
                        appState.state.language === "en"
                          ? "For Other SMS"
                          : "للرسائل القصيرة"
                      }
                    >
                      <div className="content">
                        {this.renderVariableDropDown("en", "other", () => {
                          if (
                            this.otherSmsVarEn &&
                            this.otherSmsVarEn.value !== "select"
                          ) {
                            setFieldValue(
                              "englishOtherValueSMS",
                              values.englishOtherValueSMS +
                                `%${this.otherSmsVarEn.value}`
                            );
                            this.otherSmsVarEn.value = "select";
                          }
                        })}
                        <div>
                          <FormattedMessage
                            id="translation.englishSMS"
                            defaultMessage="SMS in English"
                          />
                          <textarea
                            name="englishOtherValueSMS"
                            value={values.englishOtherValueSMS}
                            onChange={handleChange}
                            className="form-control"
                          />
                          {touched["englishOtherValueSMS"] &&
                            errors["englishOtherValueSMS"] && (
                              <p className="text-warning">
                                {errors["englishOtherValueSMS"]}
                              </p>
                            )}
                        </div>
                      </div>

                      <hr />

                      <div className="content">
                        {this.renderVariableDropDown("ar", "other", () => {
                          if (
                            this.otherSmsVarAr &&
                            this.otherSmsVarAr.value !== "select"
                          ) {
                            setFieldValue(
                              "arabicOtherValueSMS",
                              values.arabicOtherValueSMS +
                                `%${this.otherSmsVarAr.value}`
                            );
                            this.otherSmsVarAr.value = "select";
                          }
                        })}
                        <div>
                          <FormattedMessage
                            id="translation.arabicSMS"
                            defaultMessage="SMS in Arabic"
                          />
                          <textarea
                            name="arabicOtherValueSMS"
                            value={values.arabicOtherValueSMS}
                            onChange={handleChange}
                            className="form-control"
                          />
                          {touched["arabicOtherValueSMS"] &&
                            errors["arabicOtherValueSMS"] && (
                              <p className="text-warning">
                                {errors["arabicOtherValueSMS"]}
                              </p>
                            )}
                        </div>
                      </div>
                    </Tab>

                    <Tab
                      label={
                        appState.state.language === "en"
                          ? "For Other Email"
                          : "للبريد الإلكتروني"
                      }
                    >
                      <div className="content">
                        {this.renderVariableDropDown("en", "other", () => {
                          if (
                            this.otherSmsVarEn &&
                            this.otherSmsVarEn.value !== "select"
                          ) {
                            setFieldValue(
                              "englishOtherValueEmail",
                              values.englishOtherValueEmail +
                                `%${this.otherSmsVarEn.value}`
                            );
                            this.otherSmsVarEn.value = "select";
                          }
                        })}
                        <div>
                          <FormattedMessage
                            id="translation.englishEmail"
                            defaultMessage="Email in English"
                          />
                          <textarea
                            name="englishOtherValueEmail"
                            value={values.englishOtherValueEmail}
                            onChange={handleChange}
                            className="form-control"
                          />
                          {touched["englishOtherValueEmail"] &&
                            errors["englishOtherValueEmail"] && (
                              <p className="text-warning">
                                {errors["englishOtherValueEmail"]}
                              </p>
                            )}
                        </div>
                      </div>

                      <hr />

                      <div className="content">
                        {this.renderVariableDropDown("ar", "other", () => {
                          if (
                            this.otherSmsVarAr &&
                            this.otherSmsVarAr.value !== "select"
                          ) {
                            setFieldValue(
                              "arabicOtherValueEmail",
                              values.arabicOtherValueEmail +
                                `%${this.otherSmsVarAr.value}`
                            );
                            this.otherSmsVarAr.value = "select";
                          }
                        })}
                        <div>
                          <FormattedMessage
                            id="translation.arabicEmail"
                            defaultMessage="Email in Arabic"
                          />
                          <textarea
                            name="arabicOtherValueEmail"
                            value={values.arabicOtherValueEmail}
                            onChange={handleChange}
                            className="form-control"
                          />
                          {touched["arabicOtherValueEmail"] &&
                            errors["arabicOtherValueEmail"] && (
                              <p className="text-warning">
                                {errors["arabicOtherValueEmail"]}
                              </p>
                            )}
                        </div>
                      </div>
                    </Tab>
                  </Tabs> */}
                </Form>
              );
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            bsStyle="btn btn-primary"
            style={{ marginBottom: 0 }}
            onClick={async () => {
              this.formik.state.values.englishValueEmail =
                this.formik.state.values.englishValueEmail.replace(
                  /\n/g,
                  "<br />"
                );
              // this.formik.state.values.englishOtherValueEmail =
              //   this.formik.state.values.englishOtherValueEmail.replace(
              //     /\n/g,
              //     "<br />"
              //   );
              this.formik.state.values.arabicValueEmail =
                this.formik.state.values.arabicValueEmail.replace(
                  /\n/g,
                  "<br />"
                );
              // this.formik.state.values.arabicOtherValueEmail =
              //   this.formik.state.values.arabicOtherValueEmail.replace(
              //     /\n/g,
              //     "<br />"
              //   );

              await this.formik.submitForm();
              this.switchTabs(this.formik.state.errors);
            }}
          >
            <FormattedMessage id="global.save" defaultMessage="Save" />
          </Button>
          <Button
            bsStyle="link"
            onClick={() => {
              this.props.onCancel && this.props.onCancel();
            }}
          >
            <FormattedMessage id="global.cancel" defaultMessage="Cancel" />
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default injectIntl(SmsEmailContentModal);
