import React from "react";
import { FormattedMessage } from "react-intl";
import CustomModal from "../shared/CustomModal";
import QrReader from "../shared/QrReader";

const CompleteOrder = ({
  doShowOrderCompleteModal,
  doShowOtpModal,
  showOdoMeterModal,
  closeModal,
  submitOrderId,
  onChangeTextValue,
  orderIdText,
  odometer,
  verifyOrderOtpText,
  completeOrder,
  verifyOrderOtp,
  intl,
}) => {
  const [showScanner, setShowScanner] = React.useState(false);

  const onScanQrCode = (text) => {
    setShowScanner(false);
    onChangeTextValue("orderIdText", text);
    submitOrderId();
  };
  const hideOrderIdModal = () => {
    setShowScanner(false);
    closeModal("doShowOrderCompleteModal");
  };
  return (
    <>
      <CustomModal
        show={doShowOrderCompleteModal}
        containerStyle={
          showScanner ? { height: 350 } : { height: 100, width: 350 }
        }
        footer={
          !showScanner ? (
            <div>
              <input
                type="submit"
                className="btn btn-primary"
                value={intl.formatMessage({
                  id: "global.submit",
                  defaultMessage: "Submit",
                })}
                onClick={submitOrderId}
              />
              <button
                className="btn btn-transparent"
                type="button"
                onClick={() => setShowScanner(true)}
              >
                {intl.formatMessage({
                  id: "order.scanOrder",
                  defaultMessage: "Scan Order",
                })}
              </button>
            </div>
          ) : (
            <button
              className="btn btn-transparent"
              type="button"
              onClick={() => setShowScanner(false)}
            >
              {intl.formatMessage({
                id: "order.cancelQr",
                defaultMessage: "Cancel",
              })}
            </button>
          )
        }
        handleClose={hideOrderIdModal}
        dialogClassName="complete-order-modal"
        closeButton={true}
        onBackdropClick={()=>{}}
        backdrop="static"
      >
        {showScanner ? (
          <QrReader onScanQrCode={(text) => onScanQrCode(text)} />
        ) : (
          <div className="mx-auto">
            <div>
              <label>
                <FormattedMessage
                  id="order.orderIdInputLabel"
                  defaultMessage="Please enter correct Order ID here"
                />
              </label>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div>
                  <input
                    type="text"
                    class="form-control"
                    placeholder={intl.formatMessage({
                      id: "order.orderIdPlaceHolder",
                      defaultMessage: "Enter Order ID",
                    })}
                    value={orderIdText}
                    onChange={(e) =>
                      onChangeTextValue("orderIdText", e.target.value)
                    }
                    maxLength={6}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </CustomModal>
      <CustomModal
        show={doShowOtpModal}
        containerStyle={{ height: 100, width: 350 }}
        footer={
          <>
            <div>
              <input
                type="submit"
                className="btn btn-primary"
                value={intl.formatMessage({
                  id: "global.submit",
                  defaultMessage: "Submit",
                })}
                onClick={verifyOrderOtp}
              />
              <button
                className="btn btn-transparent"
                type="button"
                onClick={submitOrderId}
              >
                {intl.formatMessage({
                  id: "order.resentOtp",
                  defaultMessage: "Resend Otp",
                })}
              </button>
            </div>
          </>
        }
        handleClose={() => closeModal("doShowOtpModal")}
        dialogClassName="complete-order-modal"
        closeButton={true}
        onBackdropClick={()=>{}}
        backdrop="static"
      >
        <div className="mx-auto">
          <div>
            <label>
              <FormattedMessage
                id="order.otp"
                defaultMessage="Enter Correct OTP"
              />
            </label>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div>
                <input
                  type="text"
                  class="form-control"
                  placeholder={intl.formatMessage({
                    id: "order.customerOtpPlaceholder",
                    defaultMessage: "Enter OTP",
                  })}
                  value={verifyOrderOtpText}
                  onChange={(e) =>
                    onChangeTextValue("verifyOrderOtpText", e.target.value)
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
      <CustomModal
        show={showOdoMeterModal}
        containerStyle={{ height: 100, width: 350 }}
        footer={
          <div>
            <input
              type="submit"
              className="btn btn-primary"
              value={intl.formatMessage({
                id: "global.submit",
                defaultMessage: "Submit",
              })}
              onClick={completeOrder}
            />
            <button
              className="btn btn-transparent"
              type="button"
              onClick={() => closeModal("showOdoMeterModal")}
            >
              {intl.formatMessage({
                id: "global.cancel",
                defaultMessage: "Cancel",
              })}
            </button>
          </div>
        }
        handleClose={() => closeModal("showOdoMeterModal")}
        dialogClassName="complete-order-modal"
        closeButton={false}
        onBackdropClick={()=>{}}
        backdrop="static"
      >
        <div className="mx-auto">
          <div className="row">
            <div className="col-md-8">
              <div>
                <label>
                  <FormattedMessage
                    id="order.odometer"
                    defaultMessage="Odometer"
                  />
                </label>
              </div>
              <div>
                <input
                  type="number"
                  class="form-control"
                  placeholder={intl.formatMessage({
                    id: "order.odoMeterValue",
                    defaultMessage: "Enter Odometer (KM)",
                  })}
                  value={odometer}
                  onChange={(e) => {
                    const value = e.target.value
                    onChangeTextValue("odometer", value);
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export default CompleteOrder;
