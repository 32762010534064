import React, { Component } from "react";
import { FormattedMessage, injectIntl, FormattedNumber } from "react-intl";
import Layout from "../shared/Layout";
import services from "../../services";
import _, { isEmpty } from "lodash";
import Auth from "../shared/Auth";
import withRouter from "../../util/withRouter";
import { getLocalDateTime } from "../../util";
import Table from "../shared/Table";
import appState from "../../state/AppStateContainer";
import HeaderTitle from "../shared/HeaderTitle";
import CheckAccess from "../shared/CheckAccess";
import { ActivitiesEnum } from "../../staticData/Activities";
import { SingleDatePicker } from "react-dates";
import moment from "moment";
import ExportToExcel from "../shared/ExportToExcel";
import ActionMenu from "../shared/ActionMenu";
import { Link } from "react-router-dom";
import { NotificationManager } from "react-notifications";

export class UserListing extends Component {
  state = {
    data: [],
    hasMore: true,
    pageIndex: -1,
    pageSize: 10,
    searchFilter: "",
    statusFilter: "all",
    serviceProviderFilter: "all",
    categoryFilter: "all",
    updatingRowId: "",
    searchValueInput: "",
    count: 0,
  };

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      if (!this.props.match.params.isFormOpen) {
        if (_.get(this, "props.location.state.isSubmitted") === true) {
          this.setState({
            pageIndex: -1,
            data: [],
            hasMore: true,
            disableFlag: true,
            updatingRowId: "",
          });
        } else {
          this.setState({ updatingRowId: "" });
        }
      }
    }
  }

  async loadMore() {
    this.setState(
      (currentState) => {
        return {
          pageIndex: currentState.pageIndex + 1,
          hasMore: false,
          loading: true,
        };
      },
      async () => {
        const options = {
          progressBar: true,
          alphabeticalOrder: true,
          fromDate: this.state.from
            ? moment(this.state.from).startOf("day").toString()
            : null,
          toDate: this.state.to
            ? moment(this.state.to).endOf("day").toString()
            : null,
        };
        const pageIndex = 0;
        const pageData = await services.getUserList(
          this.state.searchFilter,
          this.state.statusFilter,
          this.state.categoryFilter,
          this.state.pageIndex,
          this.state.pageSize,
          options
        );

        const summary =
          pageIndex === 0
            ? await services.getUserList(
                this.state.searchFilter,
                this.state.approvalFilter,
                this.state.statusFilter,
                undefined,
                undefined,
                { ...options, summary: 1 }
              )
            : { count: this.state.count };
        this.setState((currentState) => {
          return {
            data: currentState.data.concat(pageData),
            hasMore: pageData.length > 0,
            disableFlag: false,
            count: summary.count,
            loading: false,
          };
        });
      }
    );
  }

  resetData(e) {
    this.setState({
      pageIndex: -1,
      data: [],
      hasMore: true,
      searchFilter: this.searchInput?.value,
      statusFilter: this.statusSelect?.value,
      categoryFilter: this.categorySelect?.value,
      disableFlag: true,
    });
    if (e) e.preventDefault();
  }

  resetPage(e) {
    this.setState({
      pageIndex: -1,
      data: [],
      hasMore: true,
      searchFilter: "",
      statusFilter: "all",
      categoryFilter: "all",
      disableFlag: true,
      searchValueInput: null,
      to: undefined,
      from: undefined,
    });
    this.searchInput.value = "";
    if (e) e.preventDefault();
  }

  onExport = async (startDate, endDate) => {
    if (!startDate) return;
    if (!endDate) {
      endDate = startDate;
    }
    this.setState({ loading: true });
    try {
      this.setState({ exportExcelLoading: true });
      await services.downloadCustomerList(
        this.state.searchFilter,
        this.state.statusFilter,
        moment(startDate)
          .utcOffset(60 * 3)
          .startOf("day")
          .toString(),
        moment(endDate)
          .utcOffset(60 * 3)
          .endOf("day")
          .toString()
      );
    } catch (err) {}
    this.setState({ loading: false });
  };

  async handleRestrictAccount({ id, canDeleteAccount = false }) {
    try {
      this.setState({
        loading: true,
      });
      await services.restrictUserAccount({ id, canDeleteAccount });
      NotificationManager.success(
        this.props.intl.formatMessage({
          id: "global.operationSuccessful",
          defaultMessage: "Operation Successful",
        }),
        this.props.intl.formatMessage({
          id: "global.success",
          defaultMessage: "Success",
        })
      );
      this.resetPage();
    } catch (err) {
      console.log(err);
      this.setState({
        loading: false,
      });
      NotificationManager.error(
        this.props.intl.formatMessage({
          id: "error.unexpectedError",
          defaultMessage: "An unexpected error has occurred",
        }),
        this.props.intl.formatMessage({
          id: "global.error",
          defaultMessage: "Error",
        })
      );
    }
  }

  render() {
    const { intl } = this.props;
    return (
      <Auth requireAuth={true} roles={["sadmin"]}>
        <Layout
          hidden={this.props.match.params.isFormOpen}
          loading={this.state.loading}
        >
          <HeaderTitle id="user.user" defaultMessage="Customers" />
          <div className="main-container" style={{ minHeight: "100vh" }}>
            <div className="table-header clearfix">
              <form className="row" onSubmit={this.resetData.bind(this)}>
                <div className="col-xs-3 pull-right"></div>
                <div className="col-md-2 col-sm-4 col-xs-6">
                  <div className="has-feedback">
                    <input
                      type="text"
                      ref={(c) => {
                        this.searchInput = c;
                      }}
                      className="form-control"
                      value={this.searchValueInput}
                      placeholder={intl.formatMessage({
                        id: "global.search",
                        defaultMessage: "Search",
                      })}
                    />
                    <i className="glyphicon glyphicon-search form-control-feedback" />
                  </div>
                </div>
                <div className="col-lg-2 col-md-4 col-sm-12 col-xs-6">
                  <SingleDatePicker
                    id="fromDate"
                    placeholder={intl.formatMessage({
                      id: "global.from",
                      defaultMessage: "From",
                    })}
                    block={true}
                    small={true}
                    isOutsideRange={() => false}
                    numberOfMonths={1}
                    initialDate={this.state.from}
                    isRTL={appState.state.language === "ar" ? true : false}
                    date={this.state.from}
                    onDateChange={(date) => {
                      this.setState({ from: date }, this.resetData.bind(this));
                    }}
                    focused={this.state.focusedFrom}
                    onFocusChange={({ focused }) =>
                      this.setState({ focusedFrom: focused })
                    }
                  />
                </div>
                <div className="col-lg-2 col-md-4 col-sm-12 col-xs-6">
                  <SingleDatePicker
                    id="toDate"
                    block={true}
                    placeholder={intl.formatMessage({
                      id: "global.to",
                      defaultMessage: "To",
                    })}
                    small={true}
                    isOutsideRange={() => false}
                    numberOfMonths={1}
                    initialDate={this.state.to}
                    isRTL={appState.state.language === "ar" ? true : false}
                    date={this.state.to}
                    onDateChange={(date) => {
                      this.setState({ to: date }, this.resetData.bind(this));
                    }}
                    focused={this.state.focusedTo}
                    onFocusChange={({ focused }) =>
                      this.setState({ focusedTo: focused })
                    }
                  />
                </div>
                <div className="col force--inline">
                  <div className="tooltip-master">
                    <button
                      // href="javascript:;"
                      className="btn btn-primary"
                      onClick={this.resetData.bind(this)}
                      disabled={this.state.disableFlag}
                    >
                      <i className="material-icons">search</i>
                    </button>
                    <span className="tooltiptext">
                      <FormattedMessage
                        id="global.filter"
                        defaultMessage="Filter"
                      />
                    </span>
                  </div>
                </div>
                <div className="col force--inline">
                  <div className="tooltip-master">
                    <button
                      // href="javascript:;"
                      className="btn btn-primary"
                      onClick={this.resetPage.bind(this)}
                      disabled={this.state.disableFlag}
                    >
                      <i className="material-icons">settings_backup_restore</i>
                    </button>
                    <span className="tooltiptext">
                      <FormattedMessage
                        id="global.resetFilter"
                        defaultMessage="Reset Filter"
                      />
                    </span>
                  </div>
                </div>
                <div className="col-xs-3 pull-right">
                  <div className="panel-group-buttons pull-right">
                    <CheckAccess
                      activityId={ActivitiesEnum.customers_export_to_excel}
                    >
                      <ExportToExcel onExport={this.onExport} />
                    </CheckAccess>
                  </div>
                </div>
              </form>
            </div>
            <div className="row">
              <div className="col-md-12">
                <FormattedMessage
                  id="global.totalCount"
                  defaultMessage="Total Count"
                />

                <strong>
                  {" "}
                  <FormattedNumber
                    style="decimal"
                    maximumFractionDigits={0}
                    minimumFractionDigits={0}
                    value={this.state.count}
                  />{" "}
                </strong>
              </div>
            </div>
            <div className="dashboard-table-container card-layout">
              <div className="table-responsive">
                <Table
                  loadMore={this.loadMore.bind(this)}
                  hasMore={this.state.hasMore}
                  caption={
                    (!this.state.data || this.state.data.length == 0) && (
                      <caption
                        style={{
                          captionSide: "bottom",
                          textAlign: "center",
                          margin: "20px",
                          color: "darkgray",
                        }}
                      >
                        <FormattedMessage
                          id="global.noData"
                          defaultMessage="No data available"
                        />
                      </caption>
                    )
                  }
                  headerRow={
                    <tr onClick={() => {}}>
                      <th>
                        <FormattedMessage
                          id="user.phoneNumner"
                          defaultMessage="Phone Number"
                        />
                      </th>
                      {/* <th>
                      <FormattedMessage
                       id="user.Name"
                        defaultMessage="Name"
                      />
                    </th> */}
                      <th>
                        <FormattedMessage
                          id="global.createdAt"
                          defaultMessage="Created At"
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          id="global.lastUserActivity"
                          defaultMessage="Last User Activity"
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          id="user.source"
                          defaultMessage="Source"
                        />
                      </th>
                      <th style={{ width: "20px" }} />
                    </tr>
                  }
                >
                  {this.state.data.map((user) => (
                    <tr key={user._id} className="cases-trow link-row">
                      <td>{user.mobilePhone}</td>
                      {/* <td>{user.fullName}</td> */}
                      <td>
                        {user.createdAt
                          ? getLocalDateTime(user.createdAt)
                          : undefined}
                      </td>
                      <td>
                        {user.lastUserActivity
                          ? getLocalDateTime(user.lastUserActivity)
                          : undefined}
                      </td>
                      <td>{!isEmpty(user.source) ? user.source : "autohub"}</td>
                      <td style={{ position: "relative" }}>
                        <ActionMenu
                          // visible={visible}
                          menuStyle={{
                            backgroundColor: "#E9E9E9",
                          }}
                        >
                          <Link
                            style={{ margin: "10px", cursor: "pointer" }}
                            onClick={() =>
                              this.handleRestrictAccount({
                                id: user?._id,
                                canDeleteAccount: !user?.canDeleteAccount,
                              })
                            }
                          >
                            <span className="fa fa-pencil" />{" "}
                            <FormattedMessage
                              id={
                                user?.canDeleteAccount === false
                                  ? "user.allowDeleteAccount"
                                  : "user.restrictDeleteAccount"
                              }
                              defaultMessage={
                                user?.canDeleteAccount === false
                                  ? "Allow Delete Account"
                                  : "Restrict Delete Account"
                              }
                            />
                          </Link>

                          <a
                            style={{ margin: "10px", cursor: "pointer" }}
                            onClick={() => {}}
                          />
                        </ActionMenu>
                      </td>
                    </tr>
                  ))}
                </Table>
              </div>
            </div>
          </div>
        </Layout>
      </Auth>
    );
  }
}

export default withRouter(injectIntl(UserListing));
