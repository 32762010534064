import React, { Component } from "react";
import { Button } from "react-bootstrap";
import _ from "lodash";
import { NotificationManager } from "react-notifications";
import { FormattedMessage } from "react-intl";
import CropModal from "./CropModal";
import imageCompression from "browser-image-compression";

export default class VideoInput extends Component {
  state = {
    isCropping: false,
    isImage: true,
  };
  render() {
    const {
      placeholder,
      name,
      videoicon = false,
      formikprops,
      disabled,
      inputClassName,
      spRegistrationLogo,
      aspectRatio = 1,
      allowFiles,
    } = this.props;

    return (
      <React.Fragment>
        <input
          accept={allowFiles}
          type="file"
          ref={(f) => (this.innerInput = f)}
          style={{
            width: "0.1px",
            height: "0.1px",
            opacity: 0,
            overflow: "hidden",
            position: "absolute",
            zIndex: -1,
          }}
          id={name}
          disabled={disabled}
          className={`${inputClassName} form-control`}
          placeholder={placeholder}
          name={name}
          onClick={(event) => {
            event.target.value = null;
          }}
          onChange={async (e) => {
            let fileData = e.currentTarget.files[0];

            if (fileData && fileData.type !== "video/mp4") {
              NotificationManager.error(
                <FormattedMessage
                  id="auth.thisFileIsNotAllowed"
                  defaultMessage="This File is not allowed"
                />
              );
              return;
            }
            const maxSize = fileData && fileData.size / 1048576.0 > 100;
            if (maxSize) {
              NotificationManager.error(
                <FormattedMessage
                  id="auth.thisFileIsNotAllowed"
                  defaultMessage="File size is too large"
                />
              );
              return;
            }
            const touchedConfig = {};
            touchedConfig[name] = true;
            formikprops.setTouched(touchedConfig);
            formikprops.setFieldValue(name, e.currentTarget.files[0]);
          }}
        />
        <span className="img-btn-wrapper">
          <label
            ref={(r) => (this._lblCamera = r)}
            style={{ margin: "0 10px 0 0" }}
            htmlFor={name}
            disabled={disabled}
            className="btn btn-success fileChooseBtn"
          >
            <span className="fa fa-video-camera" />
          </label>
          {formikprops.values[name] &&
            !disabled &&
            formikprops.values[name] !==
              "/design/images/carhub_placeholder.png" && (
              <label
                onClick={() => {
                  this.innerInput.value = "";
                  const touchedConfig = {};
                  touchedConfig[name] = true;
                  formikprops.setTouched(touchedConfig);
                  formikprops.setFieldValue(name, undefined);
                }}
                ref={(r) => (this._lblCamera = r)}
                style={{ margin: "0 10px 0 0" }}
                htmlFor={name}
                disabled={disabled}
                className="btn btn-danger fileRemoveBtn"
              >
                <span class="fa fa-trash" />
              </label>
            )}
        </span>
      </React.Fragment>
    );
  }
}
