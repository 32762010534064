import React, { Component } from "react";
import _ from "lodash";

export default class VideoThumb extends Component {
  state = {
    loading: false,
    thumb: undefined,
  };

  componentWillReceiveProps(nextProps) {
    if (
      !nextProps.file ||
      (typeof nextProps.file !== "string" &&
        nextProps.file.name === _.get(this, "props.file.name"))
    ) {
      return;
    }

    this.setState({ loading: true }, () => {
      if (typeof nextProps.file === "string") {
        this.setState({
          loading: false,
          thumb: `/api/util/image?key=${nextProps.file}`,
        });
      } else if (nextProps.file instanceof Blob) {
        const blob = nextProps.file;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        this.setState({ loading: false, thumb: this.fileUrl });
      } else {
        let reader = new FileReader();

        reader.onloadend = () => {
          this.setState({ loading: false, thumb: reader.result });
        };

        reader.readAsDataURL(nextProps.file);
      }
    });
  }

  render() {
    const { file, showfocusborder } = this.props;
    const { loading, thumb } = this.state;

    if (!file) {
      return null;
    }

    if (loading) {
      return <p>loading...</p>;
    }

    return (
      <video className="video-player-style" width="400" controls>
        <source src={thumb} type="video/mp4" />
      </video>
    );
  }
}
