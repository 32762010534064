import React from "react";
import { FormattedMessage } from "react-intl";
import { Modal } from "react-bootstrap";
import _ from "lodash";
import constants from "../../staticData/constants";
import moment from "moment";
import { isLtr } from "../../util/utils";
import appState from "../../state/AppStateContainer";

const ViewRecommendations = ({
  showRecommendationModal,
  setShowRecommendationModal,
  data,
  handleRecommendedOffers,
  setRecommendedOfferAction,
}) => {
  const lang = appState.state.language;

  return (
    <Modal
      show={showRecommendationModal?.view}
      onHide={() => setShowRecommendationModal({ view: false })}
    >
      <Modal.Header closeButton={true}>
        <h4>
          <FormattedMessage
            id="fastCollect.viewRecommendation"
            defaultMessage="View Recommendation History"
          />
        </h4>
      </Modal.Header>
      <Modal.Body>
        {data?.length ? (
          <div className="fast-collect">
            {data.map(
              (
                {
                  name,
                  detail,
                  images,
                  video,
                  createdAt,
                  price,
                  seen,
                  canDoActiveAndInActive,
                  _id,
                  isActive,
                },
                i
              ) => {
                return (
                  <div
                    className="content-holder recommendationOfferCard"
                    key={i}
                  >
                    <div className="recommendation-offer-meta-data meta-data">
                      <div>
                        <h3 className="fw-bold" style={{ marginBottom: 8 }}>
                          {isLtr() ? name?.value_en : name.value_ar}
                        </h3>
                        <span>
                          {moment(createdAt).format("MMM DD YYYY, h:mm:ss a")}
                        </span>
                      </div>
                      <i className={seen ? "fa fa-eye" : "fa fa-eye-slash"} />
                    </div>

                    <p>
                      <b>Description:</b> {detail?.["value_" + lang]}
                    </p>

                    <span>
                      <b>Price:</b> {price} SAR
                    </span>

                    {video ? (
                      <div className="media-wrapper">
                        <h4>Video</h4>
                        <div className="media">
                          <video controls>
                            <source
                              src={`/api/util/image?key=${video}&accesskey=${constants.MEDIA_KEY}`}
                              type="video/mp4"
                            />
                          </video>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}

                    <div className="media-wrapper">
                      <h4>Images</h4>
                      <div className="media recommendationMedia">
                        {images &&
                          images?.map(({ value }) => {
                            return (
                              <img
                                src={`/api/util/image?key=${value}&accesskey=${constants.MEDIA_KEY}`}
                              />
                            );
                          })}
                      </div>
                    </div>

                    {canDoActiveAndInActive ? (
                      <div className="active-wrapper">
                        <label>
                          Active
                          <input
                            type="checkbox"
                            defaultChecked={isActive}
                            onChange={(e) =>
                              setRecommendedOfferAction({
                                id: _id,
                                isActive: e?.target?.checked,
                              })
                            }
                          />
                        </label>
                        <br />
                        <div className="save-btn-wrapper">
                          <button
                            className="btn btn-primary"
                            onClick={() => handleRecommendedOffers()}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                );
              }
            )}
          </div>
        ) : null}
      </Modal.Body>
    </Modal>
  );
};

export default ViewRecommendations;
