import React, { useEffect } from "react";
import { injectIntl } from "react-intl";
import withRouter from "../../util/withRouter";
import appState from "../../state/AppStateContainer";
import { Button } from "react-bootstrap";

const EmailSent = (props) => {
  const { intl, navigate, location } = props;
  const lang = appState.state.language;

  useEffect(() => {
    if (!location.state?.message) navigate("/");
  }, []);

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center"
      }}
    >
      <div>
        <img
          src={`/design/images/${
            lang === "en" ? "ah-logo.webp" : "ah-logo-ar.webp"
          }`}
          alt="The Auto Hub CMS"
        />
        <h2
          className="card-title"
          style={{
            fontSize: "28px",
            fontWeight: 600,
            margin: "25px 0",
          }}
        >
          {intl.formatMessage({
            id: location.state?.message || "email.resetPassword",
            defaultMessage:
              "Reset password link has been sent to your email address",
          })}
        </h2>
        
        <Button onClick={() => navigate("/forgot-password")}>
          Back
        </Button>
      </div>
    </div>
  );
};
export default withRouter(injectIntl(EmailSent));
