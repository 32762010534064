import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import Layout from "../shared/Layout";
import withRouter from "../../util/withRouter";

export class GenericError extends Component {
  render() {
    return (
      <Layout>
        <div className="dashboard-table-container card-layout">
          <h2 className="card-title">
            <FormattedMessage id="global.error" defaultMessage="Error" />
          </h2>
          <p>
            {this.props.intl.formatMessage({
              id: this.props.location.state?.error || "global.error",
              defaultMessage: "An unexpected error occurred",
            })}
          </p>
        </div>
      </Layout>
    );
  }
}

export default withRouter(injectIntl(GenericError));
