import React, { Component } from "react";
import { FormattedMessage, injectIntl, FormattedNumber } from "react-intl";
import Layout from "../shared/Layout";
import _ from "lodash";
import Auth from "../shared/Auth";
import { Link } from "react-router-dom";
import withRouter from "../../util/withRouter";
import HeaderTitle from "../shared/HeaderTitle";
import { getLocalDateTime } from "../../util";
import Table from "../shared/Table";
import CheckAccess from "../shared/CheckAccess";
import { ActivitiesEnum } from "../../staticData/Activities";
import Blog from "../../services/blogService";
import ActionMenu from "../shared/ActionMenu";

export class BlogTagsList extends Component {
  state = {
    data: [],
    loading: false,
  };

  async componentDidMount() {
    this.loadData();
  }

  async loadData() {
    this.setState({ loading: true });
    try {
      const blog = await Blog.getBlogTags({});
      this.setState({ data: blog.response, loading: false });
    } catch (err) {
      console.log(err);
    }
  }

  async deleteBlogTag(id) {
    try {
      this.setState({ loading: true });
      await Blog.deleteBlogTag(id);
      await this.loadData();
    } catch (e) {
      console.error(e);
    }
  }

  render() {
    return (
      <Auth requireAuth={true} roles={["sadmin", "wsadmin", "spadmin"]}>
        <Layout loading={this.state.loading}>
          <HeaderTitle id="blogs.blogTags" defaultMessage="Blog Tags" />
          <div className="main-container">
            <div className="table-header clearfix">
              <div className="row">
                <div className="col-xs-3 pull-right">
                  <div className="panel-group-buttons pull-right">
                    <CheckAccess activityId={ActivitiesEnum.knowledge_hub_tag}>
                      <Link className="btn btn-special " to="/blog-tag">
                        <span className="fa fa-plus" />{" "}
                        <FormattedMessage
                          id="blogs.newBlog"
                          defaultMessage="Create Blog Tag"
                        />
                      </Link>
                    </CheckAccess>
                  </div>
                </div>
                <div className="col-md-12">
                  <FormattedMessage
                    id="global.totalCount"
                    defaultMessage="Total Count"
                  />
                  <strong>
                    {" "}
                    <FormattedNumber
                      style="decimal"
                      maximumFractionDigits={0}
                      minimumFractionDigits={0}
                      value={this.state.data.length}
                    />
                  </strong>
                </div>
              </div>
            </div>
            <div>
              <div className="table-responsive">
                <Table
                  caption={
                    !this.state.data.length ? (
                      <caption
                        style={{
                          captionSide: "bottom",
                          textAlign: "center",
                          margin: "20px",
                          color: "darkgray",
                        }}
                      >
                        <FormattedMessage
                          id="global.noData"
                          defaultMessage="No data available"
                        />
                      </caption>
                    ) : null
                  }
                  headerRow={
                    <tr>
                      <th>
                        <FormattedMessage
                          id="blogs.userName"
                          defaultMessage="Tag (English)"
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          id="blogs.email"
                          defaultMessage="Tag (Arabic)"
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          id="global.createdAt"
                          defaultMessage="Created At"
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          id="blogs.isActive"
                          defaultMessage="Active"
                        />
                      </th>
                      <th></th>
                    </tr>
                  }
                >
                  {this.state.data.map(
                    ({ _id, name, isActive, createdAt }, i) => {
                      return (
                        <tr
                          key={i}
                          onClick={() =>
                            this.props.navigate(`/blog-tag/${_id}`)
                          }
                          className="cases-trow link-row"
                        >
                          <td>{name.en}</td>
                          <td>{name.ar}</td>
                          <td>{createdAt && getLocalDateTime(createdAt)}</td>
                          <td>{isActive ? "Yes" : "No"}</td>
                          <td style={{ position: "relative" }}>
                            <ActionMenu>
                              <span
                                className="fa fa-trash"
                                onClick={() => this.deleteBlogTag(_id)}
                                style={{ color: "#fff", fontSize: "18px" }}
                              />
                            </ActionMenu>
                          </td>
                        </tr>
                      );
                    }
                  )}
                </Table>
              </div>
            </div>
          </div>
        </Layout>
      </Auth>
    );
  }
}

export default withRouter(injectIntl(BlogTagsList));
