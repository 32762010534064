import React from "react";
import services from "../../services";
import $ from "jquery";
import InfiniteScrollOverride from "../shared/InfiniteScrollOverride";

export default class Table extends React.Component {
  componentWillUnmount() {
    $("#rightSection").off("scroll.serviceList");
    $(window).off("scroll.serviceList");
    $(window).off("resize.serviceList");
  }

  updateTHead() {
    const tableRect = $(".dataGrid")[0]?.getBoundingClientRect();

    if (tableRect.y <= 0) {
      const value = Math.abs(tableRect.y) + 80 + "px";

      $(".dataGrid thead").css("transform", `translateY(${value})`);
    } else {
      $(".dataGrid thead").css("transform", `translateY(0px)`);
    }
  }

  componentDidMount() {
    if (window.innerWidth >= 768)
      $("#rightSection").on("scroll.serviceList", (e) => {
        this.updateTHead();
      });
    else
      $(window).on("scroll.serviceList", (e) => {
        this.updateTHead();
      });

    $(window).on("resize.serviceList", (e) => {
      setTimeout(() => this.updateTHead(), 1000);
    });
  }

  render() {
    return (
      <table className="dataGrid card-layout table dashboard-table table-hover full-w">
        {this.props.caption}

        <InfiniteScrollOverride
          useWindow={false}
          element="tbody"
          pageStart={0}
          loadMore={this.props.loadMore}
          // parentSelector="#pageContainer"
          hasMore={this.props.hasMore}
          // useWindow={$("body").height() > $(window).height()}
          initialLoad={true}
          // loader={<caption key="loader">Loading...</caption>}
        >
          {this.props.children}
        </InfiniteScrollOverride>
        <tfoot>{this.props.footerRow}</tfoot>
        <thead style={{ backgroundColor: "white" }}>
          {this.props.headerRow}
        </thead>
      </table>
    );
  }
}
