import React, { Component } from "react";
import classNames from "classnames";
import appState from "../../state/AppStateContainer";
import { FormattedMessage } from "react-intl";
import $ from "jquery";
import _ from "lodash";

export default class ActionMenu extends Component {
  state = {
    display: "none",
    visible: false,
    isEmpty: false
  };

  handleAnimationEnd() {
    this.setState({ display: this.state.visible ? "flex" : "none" });

    if (this.state.visible && this.state.openByRightClick) {
      const windowWidth = $(window).width();
      const rect = this._mainContainer.getBoundingClientRect();
      const xEnd = rect.x + rect.width;
      if (xEnd > windowWidth) {
        const diff = xEnd - windowWidth;
        const openAtX = this.state.openAtX - diff;

        this.setState({ openAtX });
      }
    }
  }

  handleAnimationStart() {
    this.setState({ display: "flex" });
  }

  componentDidMount() {
    appState.emitter.addListener("closeActionMenu", () => {
      $(".animated.zoomInRight").addClass("zoomOutLeft");
      $(".animated.zoomInRight").removeClass("zoomInRight");
      this.setState({ visible: false });
    });
    if (this._mainContainer.childElementCount === 0) {
      this.setState({ isEmpty: true });
    }

    if (!this.props.RightClickDisabled) {
      $(this.linkEl)
        .parents("tr")
        .mousedown(e => {
          if (e.button === 2) {
            appState.emitter.emit("closeActionMenu");
            const rect = this.linkEl.getBoundingClientRect();

            const x = e.pageX - rect.left; //x position within the element.

            //var y = e.pageY - rect.top; //y position within the element.
            let scrollingEl = $(".backdrop-container");
            if (scrollingEl.length === 0) {
              scrollingEl = $(".table-responsive");
            }
            if (scrollingEl.length === 0) {
              scrollingEl = $("#rightSection");
            }
            const scrollWidth = scrollingEl[0].scrollWidth;
            const width = scrollingEl.width();
            this.setState({
              visible: !this.state.visible,
              openAtX: x,
              openByRightClick: true,
              hasScroll: scrollWidth > width
            });
          }
        })
        .contextmenu(e => {
          return false;
        });
    }
  }

  render() {
    const menuStyle = {
      position: "absolute",
      top: "0px",
      height: "100%",
      justifyContent: "center",
      alignItems: "center",
      padding: "0px 20px 0px 20px",
      overflowY: "hidden",
      whiteSpace: "nowrap",
      display: this.state.visible ? "flex" : this.state.display,
      animationDuration: "500ms",
      background: "linear-gradient(to right, #29c0dc, #29c0dc 1%)",
      zIndex: 99999
    };
    if (!this.props.RightClickDisabled) {
      if (appState.state.language === "ar") {
        if (!this.state.openByRightClick || !this.state.hasScroll)
          menuStyle.left = "40px";
        else menuStyle.left = this.state.openAtX + "px";
      } else {
        if (!this.state.openByRightClick || !this.state.hasScroll)
          menuStyle.right = "40px";
        else menuStyle.left = this.state.openAtX + "px"; //"40px";
      }
    } else {
      if (appState.state.language === "ar") {
        menuStyle.left = "40px";
      } else {
        menuStyle.right = "40px";
      }
    }
    if (this.props.menuStyle) Object.assign(menuStyle, this.props.menuStyle);

    return (
      <React.Fragment>
        {this.state.visible && (
          <div
            style={{
              position: "fixed",
              backgroundColor: "#000",
              opacity: 0,
              zIndex: 9999,
              height: "100%",
              width: "100%",
              top: "0px",
              left: "0px"
            }}
            onClick={e => {
              e.stopPropagation();
              appState.emitter.emit("closeActionMenu");
            }}
          />
        )}
        {_.get(appState, "state.titleId") !== "order.orderReport" &&
          _.get(appState, "state.titleId") !== "order.orderDetails" && (
            <a
              ref={a => (this.linkEl = a)}
              style={{
                height: "100%",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
                left: "0px",
                top: "0px",
                fontSize: "2rem",
                textDecoration: "none"
              }}
              onClick={e => {
                e.stopPropagation();
                appState.emitter.emit("closeActionMenu");

                this.setState({
                  visible: !this.state.visible,
                  openByRightClick: false
                });
              }}
            >
              <span className="glyphicon glyphicon-option-vertical options-icon" />
            </a>
          )}
        <div
          ref={r => (this._mainContainer = r)}
          onAnimationStart={this.handleAnimationStart.bind(this)}
          onAnimationEnd={this.handleAnimationEnd.bind(this)}
          style={menuStyle}
          onClick={e => {
            e.stopPropagation();
            if (e.target !== e.currentTarget)
              appState.emitter.emit("closeActionMenu");
          }}
          className={classNames("animated", {
            zoomInRight: this.state.visible,
            zoomOutLeft: !this.state.visible
          })}
        >
          {(!this.props.children ||
            this.props.children.length === 0 ||
            (_.isArray(this.props.children) &&
              this.props.children.filter(c => c !== false).length === 0) ||
            this.state.isEmpty) && (
            <FormattedMessage
              id="actionMenu.noAvailableActions"
              defaultMessage="No available actions"
            />
          )}
          {this.props.children}
        </div>
      </React.Fragment>
    );
  }
}
