import React, { Component } from "react";
import { FormattedMessage, injectIntl, FormattedNumber } from "react-intl";
import Layout from "../shared/Layout";
import services from "../../services/scheduleEmailService";
import _ from "lodash";
import Auth from "../shared/Auth";
import { Link } from "react-router-dom";
import withRouter from "../../util/withRouter";
import ActionMenu from "../shared/ActionMenu";
import appState from "../../state/AppStateContainer";
import moment from "moment";
import HeaderTitle from "../shared/HeaderTitle";
import CheckAccess from "../shared/CheckAccess";
import { ActivitiesEnum } from "../../staticData/Activities";
import { SingleDatePicker } from "react-dates";
import { getLocalDateTime } from "../../util";
import Table from "../shared/Table";
import { NotificationManager } from "react-notifications";
export class ScheduleEmailList extends Component {
  state = {
    data: [],
    hasMore: true,
    pageIndex: -1,
    pageSize: 10,
    searchFilter: "",
    reportTypeFilter: "all",
    tenureFilter: "all",
    aplhabeticalOrder: true,
    count: 0,
    updatingRowId: "",
  };

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      if (!this.props.match.params.isFormOpen) {
        if (_.get(this, "props.location.state.isSubmitted") === true) {
          this.setState({
            pageIndex: -1,
            data: [],
            hasMore: true,
            // searchFilter: this.searchInput.value,
            reportTypeFilter: this.reportTypeSelect.value,
            tenureFilter: this.tenureSelect.value,
            disableFlag: true,
            updatingRowId: "",
          });
        } else {
          this.setState({ updatingRowId: "" });
        }
      }
    }
  }

  async loadMore() {
    this.setState(
      (currentState) => {
        return {
          pageIndex: currentState.pageIndex + 1,
          hasMore: false,
          loading: true,
        };
      },
      async () => {
        const pageIndex = 0;
        const options = {
          progressBar: true,
          fromDate: this.state.from
            ? moment(this.state.from).startOf("day").toString()
            : null,
          toDate: this.state.to
            ? moment(this.state.to).endOf("day").toString()
            : null,
        };
        const pageData = await services.getScheduleEmail(
          this.state.searchFilter,
          this.state.reportTypeFilter,
          this.state.tenureFilter,
          this.state.pageIndex,
          this.state.pageSize,
          this.state.aplhabeticalOrder,
          options
        );

        const summary =
          pageIndex === 0
            ? await services.getScheduleEmail(
                this.state.searchFilter,
                this.state.reportTypeFilter,
                this.state.tenureFilter,
                undefined,
                undefined,
                this.state.aplhabeticalOrder,
                { ...options, summary: 1 }
              )
            : { count: this.state.count };

        this.setState((currentState) => {
          return {
            data: currentState.data.concat(pageData),
            hasMore: pageData.length > 0,
            disableFlag: false,
            count: summary.count,
            loading: false,
          };
        });
      }
    );
  }

  resetData(e) {
    this.setState({
      pageIndex: -1,
      data: [],
      hasMore: true,
      // searchFilter: this.searchInput.value,
      reportTypeFilter: this.reportTypeSelect.value,
      tenureFilter: this.tenureSelect.value,
      disableFlag: true,
    });
    if (e) e.preventDefault();
  }

  async componentDidMount() {
    this.subscription = appState.emitter.addListener("onLanguageChange", () => {
      this.setState({
        from: this.state.from
          ? moment(this.state.from).locale(appState.state.language)
          : undefined,
        to: this.state.to
          ? moment(this.state.to).locale(appState.state.language)
          : undefined,
      });
    });
  }

  componentWillUnmount() {
    this.subscription.remove();
  }

  resetPage(e) {
    this.setState({
      pageIndex: -1,
      data: [],
      hasMore: true,
      searchFilter: "",
      reportTypeFilter: "all",
      tenureFilter: "all",
      disableFlag: true,
      to: undefined,
      from: undefined,
    });
    this.reportTypeSelect.value = "all";
    // this.searchInput.value = "";
    this.tenureSelect.value = "all";
    e.preventDefault();
  }

  async handleDelete(item) {
    try {
      await services.deleteScheduleEmail(item.id);
      this.setState({
        data: [...this.state.data.filter((p) => p.id !== item.id)],
      });
    } catch (e) {
      NotificationManager.error(
        this.props.intl.formatMessage({
          id: _.get(e, "response.data.error", "error.unexpectedError"),
          defaultMessage: "An unexpected error has occurred",
        }),
        this.props.intl.formatMessage({
          id: "global.error",
          defaultMessage: "Error",
        })
      );
    }
  }

  render() {
    const { intl } = this.props;
    return (
      <Auth requireAuth={true} roles={["sadmin", "wsadmin", "spadmin"]}>
        <Layout
          hidden={this.props.match.params.isFormOpen}
          loading={this.state.loading}
        >
          <div className="table-header clearfix">
            <HeaderTitle
              id="scheduleEmail.scheduleEmail"
              defaultMessage="Schedule Emails"
            />
            <form className="row" onSubmit={this.resetData.bind(this)}>
              <div className="col-xs-3 pull-right">
                <div className="panel-group-buttons pull-right">
                  <CheckAccess
                    activityId={ActivitiesEnum.schedule_email_add_update}
                  >
                    <Link className="btn btn-special " to="/schedule-email">
                      <span className="fa fa-plus" />{" "}
                      <FormattedMessage
                        id="scheduleEmail.newScheduleEmail"
                        defaultMessage="Create Schedule Email"
                      />
                    </Link>
                  </CheckAccess>
                </div>
              </div>
              {/* search bar div */}
              {/* <div className="col-lg-2 col-md-4 col-sm-12 col-xs-6">
                <div className="has-feedback">
                  <input
                    type="text"
                    ref={(c) => {
                      this.searchInput = c;
                    }}
                    className="form-control"
                    placeholder={intl.formatMessage({
                      id: "global.search",
                      defaultMessage: "Search",
                    })}
                  />
                  <i className="glyphicon glyphicon-search form-control-feedback" />
                </div>
              </div> */}

              <div className="col-lg-2 col-md-4 col-sm-12 col-xs-6">
                <select
                  ref={(c) => {
                    this.reportTypeSelect = c;
                  }}
                  onChange={this.resetData.bind(this)}
                  className="form-control"
                >
                  <option value="all">
                    {intl.formatMessage({
                      id: "scheduleEmail.reportType",
                      defaultMessage: "Report Type",
                    })}
                  </option>
                  <option value="appointment">
                    {intl.formatMessage({
                      id: "scheduleEmail.appointment",
                      defaultMessage: "Appointment",
                    })}
                  </option>
                  {/* <option value="customer">
                    {intl.formatMessage({
                      id: "scheduleEmail.customer",
                      defaultMessage: "Customer",
                    })}
                  </option>
                  <option value="sales">
                    {intl.formatMessage({
                      id: "scheduleEmail.sales",
                      defaultMessage: "Sales",
                    })}
                  </option> */}
                </select>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-12 col-xs-6">
                <select
                  ref={(c) => {
                    this.tenureSelect = c;
                  }}
                  onChange={this.resetData.bind(this)}
                  className="form-control"
                >
                  <option value="all">
                    {intl.formatMessage({
                      id: "scheduleEmail.tenure",
                      defaultMessage: "Tenure",
                    })}
                  </option>
                  <option value="daily">
                    {intl.formatMessage({
                      id: "scheduleEmail.daily",
                      defaultMessage: "Daily",
                    })}
                  </option>
                  <option value="weekly">
                    {intl.formatMessage({
                      id: "scheduleEmail.weekly",
                      defaultMessage: "Weekly",
                    })}
                  </option>
                  <option value="monthly">
                    {intl.formatMessage({
                      id: "scheduleEmail.monthly",
                      defaultMessage: "Monthly",
                    })}
                  </option>
                </select>
              </div>
              <div className="clearfix" />
              <div className="col-lg-2 col-md-4 col-sm-12 col-xs-6">
                <SingleDatePicker
                  id="fromDate"
                  placeholder={intl.formatMessage({
                    id: "global.from",
                    defaultMessage: "From",
                  })}
                  block={true}
                  small={true}
                  isOutsideRange={() => false}
                  numberOfMonths={1}
                  initialDate={this.state.from}
                  isRTL={appState.state.language === "ar" ? true : false}
                  date={this.state.from}
                  onDateChange={(date) => {
                    this.setState({ from: date }, this.resetData.bind(this));
                  }}
                  focused={this.state.focusedFrom}
                  onFocusChange={({ focused }) =>
                    this.setState({ focusedFrom: focused })
                  }
                />
              </div>

              <div className="col-lg-2 col-md-4 col-sm-12 col-xs-6">
                <SingleDatePicker
                  id="toDate"
                  block={true}
                  placeholder={intl.formatMessage({
                    id: "global.to",
                    defaultMessage: "To",
                  })}
                  small={true}
                  isOutsideRange={() => false}
                  numberOfMonths={1}
                  initialDate={this.state.to}
                  isRTL={appState.state.language === "ar" ? true : false}
                  date={this.state.to}
                  onDateChange={(date) => {
                    this.setState({ to: date }, this.resetData.bind(this));
                  }}
                  focused={this.state.focusedTo}
                  onFocusChange={({ focused }) =>
                    this.setState({ focusedTo: focused })
                  }
                />
              </div>
              <div className="col force--inline">
                <div className="tooltip-master">
                  <button
                    href="javascript:;"
                    className="btn btn-primary"
                    onClick={this.resetData.bind(this)}
                    disabled={this.state.disableFlag}
                  >
                    <i className="material-icons">search</i>
                  </button>
                  <span className="tooltiptext">
                    <FormattedMessage
                      id="global.filter"
                      defaultMessage="Filter"
                    />
                  </span>
                </div>
              </div>
              <div className="col force--inline">
                <div className="tooltip-master">
                  <button
                    href="javascript:;"
                    className="btn btn-primary"
                    onClick={this.resetPage.bind(this)}
                    disabled={this.state.disableFlag}
                  >
                    <i className="material-icons">settings_backup_restore</i>
                  </button>
                  <span className="tooltiptext">
                    <FormattedMessage
                      id="global.resetFilter"
                      defaultMessage="Reset Filter"
                    />
                  </span>
                </div>
              </div>
            </form>
            <div className="row">
              <div className="col-md-12">
                <FormattedMessage
                  id="global.totalCount"
                  defaultMessage="Total Count"
                />

                <strong>
                  {" "}
                  <FormattedNumber
                    style="decimal"
                    maximumFractionDigits={0}
                    minimumFractionDigits={0}
                    value={this.state.count}
                  />{" "}
                </strong>
              </div>
            </div>
          </div>
          <div>
            <CheckAccess activityId={ActivitiesEnum.schedule_email_table}>
              <div className="table-responsive">
                <Table
                  loadMore={this.loadMore.bind(this)}
                  hasMore={this.state.hasMore}
                  caption={
                    (!this.state.data || this.state.data.length == 0) && (
                      <caption
                        style={{
                          captionSide: "bottom",
                          textAlign: "center",
                          margin: "20px",
                          color: "darkgray",
                        }}
                      >
                        <FormattedMessage
                          id="global.noData"
                          defaultMessage="No data available"
                        />
                      </caption>
                    )
                  }
                  headerRow={
                    <tr onClick={() => {}}>
                      <th>
                        <FormattedMessage
                          id="scheduleEmail.reportType"
                          defaultMessage="Report Type"
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          id="scheduleEmail.tenure"
                          defaultMessage="Tenure"
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          id="scheduleEmail.startFrom"
                          defaultMessage="Start From"
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          id="global.createdAt"
                          defaultMessage="Created At"
                        />
                      </th>
                      <th style={{ width: "20px" }} />
                    </tr>
                  }
                >
                  {this.state.data.map((c) => {
                    var showEdit = false;

                    if (appState.state.token?.role === "sadmin")
                      showEdit = true;

                    return (
                      <tr
                        key={c.id}
                        onClick={() => {
                          this.setState(
                            {
                              updatingRowId: c.id,
                            },
                            () => {
                              this.props.navigate(`/schedule-email/${c.id}`);
                            }
                          );
                        }}
                        className="cases-trow link-row"
                      >
                        <td>{c.reportType}</td>
                        <td>{c.tenure}</td>
                        <td>
                          {c.startFrom
                            ? moment(c.startFrom).format("MMM DD, YYYY")
                            : undefined}
                        </td>
                        <td>
                          {c.createdAt
                            ? getLocalDateTime(c.createdAt)
                            : undefined}
                        </td>
                        <td style={{ position: "relative" }}>
                          <ActionMenu
                            visible={this.state.visible}
                            menuStyle={{
                              backgroundColor: "#E9E9E9",
                            }}
                          >
                            {showEdit && (
                              <CheckAccess
                                activityId={
                                  ActivitiesEnum.schedule_email_add_update
                                }
                              >
                                <Link
                                  style={{ margin: "10px", cursor: "pointer" }}
                                  to={`/schedule-email/${c.id}`}
                                >
                                  <span className="fa fa-pencil" />{" "}
                                  <FormattedMessage
                                    id="global.edit"
                                    defaultMessage="Edit"
                                  />
                                </Link>

                                <a
                                  style={{ margin: "10px", cursor: "pointer" }}
                                  onClick={() => {
                                    this.handleDelete(c);
                                  }}
                                >
                                  <span className="fa fa-trash" />{" "}
                                  {this.props.intl.formatMessage({
                                    id: "global.delete",
                                    defaultMessage: "Delete",
                                  })}
                                </a>
                              </CheckAccess>
                            )}
                          </ActionMenu>
                        </td>
                      </tr>
                    );
                  })}
                </Table>
              </div>
            </CheckAccess>
          </div>
        </Layout>
      </Auth>
    );
  }
}

export default withRouter(injectIntl(ScheduleEmailList));
