import React, { useState, useEffect } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Form, Formik } from "formik";
import Layout from "../shared/Layout";
import _ from "lodash";
import Loading from "../shared/Loading";
import Auth from "../shared/Auth";
import FieldRow from "../shared/FieldRow";
import withRouter from "../../util/withRouter";
import appState from "../../state/AppStateContainer";
import HeaderTitle from "../shared/HeaderTitle";
import { withAuthContext } from "../../state/AuthContext";
import SubscriptionService from "../../services/subscriptionService";
import { Yup } from "../../util";
import moment from "moment";
import { Button } from "react-bootstrap";

const SubscribedOfferForm = (props) => {
  const formId = props.match.params.id;
  const lang = appState.state.language;

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLoading(true);
    try {
      const data = await SubscriptionService.getSubscribedOfferById(formId);
      setData(data || {});
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  const appointmentHash = [];
  const quotaInfo = data.services?.reduce(
    (store, item) => {
      appointmentHash.push(
        ...item.quota.appointmentIds.map(
          ({ appointmentHash }) => appointmentHash
        )
      );

      const { total, consume, left } = item.quota;
      return {
        total: store.total + total,
        consume: store.consume + consume,
        left: store.left + left,
      };
    },
    { total: 0, consume: 0, left: 0 }
  );

  return (
    <Layout noLayout={props.noLayout} loading={loading}>
      <HeaderTitle
        id="subscription.subscribedOffer"
        defaultMessage="Subscribed Packages"
      />

      <div className="dashboard-table-container card-layout">
        <Auth requireAuth={true} roles={["sadmin", "wsadmin", "spadmin"]}>
          <Formik
            enableReinitialize
            initialValues={{
              title: data.title,
              firstName: data.firstName,
              lastName: data.lastName,
              email: data.email,
              phoneNumber: data.phoneNumber,
              make: data.carId?.brand?.["value_" + lang],
              model: data.carId?.product?.["value_" + lang],
              year: data.carId?.modelYear,
              otherBrand: data.otherBrand,
              otherModel: data.otherModel,
              carNickName: data.carNickName,
              plateNumber: data.licencePlate?.substring(0, 4),
              plateChar: data.licencePlate?.substring(4),
              subscribedOfferIdHash: data.hashId,
              offerName: data.subscriptionId?.name?.["value_" + lang],
              subscriptionServices: data.services
                ?.map((s) => s.serviceId?.name?.["value_" + lang])
                .join(" | "),
              city: data.branchId?.city,
              branch: data.branchId?.name["value_" + lang],
              totalQuota: quotaInfo?.total,
              consumeQuota: quotaInfo?.consume,
              leftQuota: quotaInfo?.left,
              subscriptionPrice:
                data.orderId?.subscriptionItems?.[0]?.priceAfterPromoDiscount,
              subscriptionExpiry: moment(data?.endDate).utc().format("LL"),
              appointments: appointmentHash.join(" | ") || "N/A",
              orderIdHash: data.orderId?.orderIdHash,
            }}
            validationSchema={Yup.object().shape({
              cost: Yup.string().required("Cost is required"),
              costPdf: Yup.string().required("PDF is required"),
            })}
          >
            {(formikProps) => (
              <div className="panel-primary">
                <div>
                  <Form>
                    <div className="dashboard-row">
                      <FieldRow
                        type="text"
                        name="title"
                        labelId="subscribed.title"
                        defaultLabel="Title"
                        formikProps={formikProps}
                        showRequiredFlag={true}
                        disabled={true}
                      />
                      <FieldRow
                        type="text"
                        name="firstName"
                        disabled={true}
                        labelId="subscribed.firstName"
                        defaultLabel="First Name"
                        formikProps={formikProps}
                        showRequiredFlag={true}
                      />
                      <FieldRow
                        name="lastName"
                        type="text"
                        labelId="subscribed.lastName"
                        defaultLabel="Last Name"
                        showRequiredFlag={true}
                        formikProps={formikProps}
                        disabled={true}
                      />
                      <FieldRow
                        name="email"
                        type="text"
                        labelId="subscribed.email"
                        defaultLabel="Email"
                        showRequiredFlag={true}
                        formikProps={formikProps}
                        disabled={true}
                      />
                      <FieldRow
                        type="text"
                        name="phoneNumber"
                        labelId="subscribed.phoneNumber"
                        defaultLabel="Phone Number"
                        showRequiredFlag={true}
                        formikProps={formikProps}
                        disabled={true}
                      />
                      <FieldRow
                        type="text"
                        name="make"
                        labelId="subscribed.make"
                        defaultLabel="Make"
                        showRequiredFlag={true}
                        formikProps={formikProps}
                        disabled={true}
                      />
                      <FieldRow
                        name="model"
                        type="text"
                        labelId="subscribed.model"
                        defaultLabel="Model"
                        showRequiredFlag={true}
                        formikProps={formikProps}
                        disabled={true}
                      />
                      {formikProps.values?.otherBrand &&
                      formikProps.values?.otherModel ? (
                        <>
                          <FieldRow
                            type="text"
                            name="otherBrand"
                            labelId="subscribed.otherBrand"
                            defaultLabel="Other Brand"
                            showRequiredFlag={true}
                            formikProps={formikProps}
                            disabled={true}
                          />
                          <FieldRow
                            type="text"
                            name="otherModel"
                            labelId="subscribed.otherModel"
                            defaultLabel="Other Model"
                            showRequiredFlag={true}
                            formikProps={formikProps}
                            disabled={true}
                          />
                        </>
                      ) : null}
                      {formikProps.values?.otherModel &&
                      !formikProps.values?.otherBrand ? (
                        <>
                          <FieldRow
                            type="text"
                            name="otherModel"
                            labelId="subscribed.otherModel"
                            defaultLabel="Other Model"
                            showRequiredFlag={true}
                            formikProps={formikProps}
                            disabled={true}
                          />
                        </>
                      ) : null}
                      <FieldRow
                        type="text"
                        name="carNickName"
                        defaultLabel="Car Nick Name"
                        labelId="subscribed.carNickName"
                        formikProps={formikProps}
                        disabled={true}
                      />
                      <FieldRow
                        name="year"
                        type="text"
                        labelId="subscribed.year"
                        defaultLabel="Year"
                        showRequiredFlag={true}
                        formikProps={formikProps}
                        disabled={true}
                      />
                      <div className="two-fields">
                        <FieldRow
                          type="number"
                          name="plateNumber"
                          defaultLabel="Car Plate Number"
                          labelId="subscribed.licencePlate"
                          pattern="[0-9]*"
                          showRequiredFlag={true}
                          formikProps={formikProps}
                          colSize={4}
                          disabled={true}
                        />
                        <FieldRow
                          type="text"
                          name="plateChar"
                          formikProps={formikProps}
                          disabled={true}
                        />
                      </div>

                      <hr />

                      <FieldRow
                        type="text"
                        name="subscribedOfferIdHash"
                        labelId="subscribed.packageId"
                        defaultLabel="Subscription Id"
                        formikProps={formikProps}
                        disabled={true}
                      />
                      <FieldRow
                        type="text"
                        name="offerName"
                        labelId="subscribed.offerName"
                        defaultLabel="Subscription Name"
                        formikProps={formikProps}
                        disabled={true}
                      />
                      <FieldRow
                        type="text"
                        name="subscriptionServices"
                        labelId="subscribed.subscriptionServices"
                        defaultLabel="Subscription Services"
                        formikProps={formikProps}
                        disabled={true}
                      />
                      <FieldRow
                        type="text"
                        name="city"
                        labelId="subscribed.cityName"
                        defaultLabel="City"
                        formikProps={formikProps}
                        disabled={true}
                      />
                      <FieldRow
                        type="text"
                        name="branch"
                        labelId="subscribed.branchName"
                        defaultLabel="Branch"
                        formikProps={formikProps}
                        disabled={true}
                      />
                      <FieldRow
                        type="number"
                        name="totalQuota"
                        labelId="subscribed.totalQuota"
                        defaultLabel="Total Quota"
                        formikProps={formikProps}
                        disabled={true}
                      />
                      <FieldRow
                        type="number"
                        name="consumeQuota"
                        labelId="subscribed.consumedQuota"
                        defaultLabel="Consumed From Quota"
                        formikProps={formikProps}
                        disabled={true}
                      />
                      <FieldRow
                        type="number"
                        name="leftQuota"
                        labelId="subscribed.leftQuota"
                        defaultLabel="Left From Quota"
                        formikProps={formikProps}
                        disabled={true}
                      />
                      <FieldRow
                        type="number"
                        name="subscriptionPrice"
                        labelId="subscribed.price"
                        defaultLabel="Subscription Price"
                        formikProps={formikProps}
                        disabled={true}
                      />
                      <FieldRow
                        type="text"
                        name="subscriptionExpiry"
                        labelId="subscribed.expiry"
                        defaultLabel="Subscription Expiry"
                        formikProps={formikProps}
                        disabled={true}
                      />
                      <FieldRow
                        type="text"
                        name="appointments"
                        labelId="subscribed.appointments"
                        defaultLabel="Appointment Ids"
                        formikProps={formikProps}
                        disabled={true}
                      />
                      <FieldRow
                        type="text"
                        name="orderIdHash"
                        labelId="subscribed.orderIdHash"
                        defaultLabel="Order Id"
                        formikProps={formikProps}
                        disabled={true}
                      />
                    </div>
                    <div className="dashboard-row -nobottom">
                      <Button
                        variant="link"
                        onClick={() => props.navigate("/subscribed-offers")}
                      >
                        <FormattedMessage
                          id="global.cancel"
                          defaultMessage="Cancel"
                        />
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
            )}
          </Formik>
        </Auth>
      </div>
    </Layout>
  );
};

export default withAuthContext(withRouter(injectIntl(SubscribedOfferForm)));
