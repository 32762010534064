import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import FieldRow from "../../shared/FieldRow";
import _ from "lodash";

export class NotificationType extends Component {
  constructor() {
    super();
    this.notifications = {
      scheduleNotification: {
        name: "Schedule Appointment Notification",
        allow: "allowScheduleNotification",
        manage: "manangeScheduleNotification",
      },
      rescheduleNotification: {
        name: "Reschedule Appointment Notification",
        allow: "allowRescheduleNotification",
        manage: "manangeRescheduleNotification",
      },
      cancelNotification: {
        name: "Cancel Appointment Notification",
        allow: "allowCancelNotification",
        manage: "manangeCancelNotification",
      },
      createEstimationNotification: {
        name: "Create Estimation Notification",
        allow: "allowCreateEstimationNotification",
        manage: "manageCreateEstimationNotification",
      },
      // updateEstimationNotification: {
      //   name: "Update Estimation Notification",
      //   allow: "allowUpdateEstimationNotification",
      //   manage: "manageUpdateEstimationNotification",
      // },
    };
  }

  render() {
    const { formikProps, formatMessage, type, handleModal, values } =
      this.props;
    const isDisabled = values && !values[this.notifications[type].allow];

    return (
      <tbody>
        <tr>
          <td style={{ maxWidth: "80px" }}>
            <FormattedMessage
              id={"notify." + type}
              defaultMessage={this.notifications[type].name}
            />
          </td>
          <td style={{ maxWidth: "50px" }}>
            <FieldRow
              type="checkbox"
              name={this.notifications[type].allow}
              formikProps={formikProps}
              fieldStyle={{ width: "60px", height: "28px" }}
            />
          </td>
          <td style={{ maxWidth: "80px" }}>
            <FieldRow
              colSize={2}
              type="select"
              name={this.notifications[type].manage}
              formikProps={formikProps}
              disabled={isDisabled}
            >
              <option value="immediately">
                {formatMessage({
                  id: "global.immediately",
                  defaultMessage: "Immediately",
                })}
              </option>
              <option value="daily">
                {formatMessage({
                  id: "global.daily",
                  defaultMessage: "Daily",
                })}
              </option>
              <option value="weekly">
                {formatMessage({
                  id: "global.weekly",
                  defaultMessage: "Weekly",
                })}
              </option>
              <option value="monthly">
                {formatMessage({
                  id: "global.monthly",
                  defaultMessage: "Monthly",
                })}
              </option>
            </FieldRow>
          </td>
          <td style={{ maxWidth: "50px" }}>
            <a
              onClick={(e) => {
                if (!isDisabled) {
                  handleModal && handleModal("open", "sms", type);
                  e.stopPropagation();
                }
              }}
              style={{
                margin: "10px",
                cursor: "pointer",
                opacity: isDisabled && 0.6,
              }}
            >
              <span
                style={{ fontSize: "3rem" }}
                className="fa fa-commenting fa-2"
              />
            </a>
          </td>
        </tr>
      </tbody>
    );
  }
}

export default NotificationType;
