import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Modal } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import _ from "lodash";
import estimationService from "../../services/estimationService";
import Loading from "./Loading";

const Qapter = ({ show, onCancel, intl, estimationDetails, props }) => {
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    vinNumber: "",
  });
  const [errors, setErrors] = useState({
    vinNumber: "",
  });

  const saveData = async (values) => {
    try {
      if (state?.vinNumber?.length === 0) {
        return setErrors({
          vinNumber: "Vin number is required",
        });
      }

      setLoading(true);

      let payload = {
        vinNumber: state?.vinNumber,
        licencePlate: estimationDetails?.licencePlate,
        frontImage: estimationDetails?.frontImage,
        rearImage: estimationDetails?.rearImage,
        rightImage: estimationDetails?.rightImage,
        leftImage: estimationDetails?.leftImage,
        id: estimationDetails?._id,
      };

      if (estimationDetails?.qapterDetails?.length > 0) {
        payload.caseId = estimationDetails.qapterDetails[0]?.caseId;
        payload.itemId = estimationDetails.qapterDetails[0]?.itemId;
      }
      const { result } = await estimationService.sendDataToQapter(payload);

      if (result?.caseId && result?.itemId) {
        props.navigate("/estimation-requests");
      }

      setLoading(false);

      onCancel();

      NotificationManager.success(
        intl.formatMessage({
          id: "global.operationSuccessful",
          defaultMessage: "Operation Successful",
        }),
        intl.formatMessage({
          id: "global.success",
          defaultMessage: "Success",
        })
      );
    } catch (e) {
      setLoading(false);

      NotificationManager.error(
        intl.formatMessage({
          id: _.get(e, "response.data.message", "error.unexpectedError"),
          defaultMessage: e?.response?.data?.message,
        }),
        intl.formatMessage({
          id: "global.error",
          defaultMessage: "Error",
        })
      );
    }
  };

  return (
    <Modal show={show} onHide={() => onCancel()} backdrop="static">
      {loading && <Loading />}
      <Modal.Header closeButton={true}>
        <h4>
          <FormattedMessage id="estimation.qapter" defaultMessage="Qapter" />
        </h4>
      </Modal.Header>

      <div>
        <Modal.Body>
          <div className="row">
            <div className="col-md-2 col-sm-2 col-xs-12">
              <label className="form-label caps block require-flag">
                <FormattedMessage
                  id="estimation.vinNumber"
                  defaultMessage="VIN Number"
                />
              </label>
            </div>
            <div className="input-group col-md-8 col-sm-8 col-xs-12">
              <input
                type="text"
                name="vinNumber"
                placeholder={intl.formatMessage({
                  id: "estimation.enterVinNumber",
                  defaultMessage: "Enter Vin number",
                })}
                className="form-control"
                onChange={(e) => {
                  if (e?.target?.value?.length) {
                    setErrors({
                      vinNumber: "",
                    });
                    setState({
                      vinNumber: e.target.value,
                    });
                  } else {
                    setErrors({
                      vinNumber: "Vin number is required",
                    });
                  }
                }}
              />
              {errors && errors["vinNumber"] && (
                <span style={{ color: "red" }}>*{errors["vinNumber"]}</span>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="add-car-modal-footer">
            <span className="btn btn-primary" onClick={saveData}>
              <FormattedMessage id="global.submit" defaultMessage="Submit" />
            </span>
          </div>
        </Modal.Footer>
      </div>
    </Modal>
  );
};

export default React.memo(Qapter);
