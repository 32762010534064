import React, { useEffect } from "react";
import Layout from "../shared/Layout";
import { FormattedMessage } from "react-intl";
import appState from "../../state/AppStateContainer";

const SessionExpired = () => {
  useEffect(() => {
    const handlePermissionsChange = async () =>
      await appState.logout("error.sessionExpired");
    handlePermissionsChange();
  }, []);

  return (
    <Layout>
      <div className="dashboard-table-container card-layout">
        <h2 className="card-title">
          <FormattedMessage
            id="error.sessionExpired"
            defaultMessage="Session Expired"
          />
        </h2>
        <p>
          <FormattedMessage
            id="error.sessionExpiredMsg"
            defaultMessage="Your last authenticated session was timed out. Please login again to continue."
          />
        </p>
      </div>
    </Layout>
  );
};

export default SessionExpired;
