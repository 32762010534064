import React from "react";
import ReactLoading from "react-loading";
import appState from "../../state/AppStateContainer";

const Loading = ({ message, state = false }) => {
  const defaultLoadingMsg = {
    en: "Loading...",
    ar: "تحميل...",
  };

  return (
    <>
      {state && (
        <div
          style={{
            top: 0,
            left: 0,
            zIndex: 9999,
            width: "100%",
            height: "100%",
            display: "flex",
            position: "fixed",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(0, 0, 0, 0.3)",
          }}
        >
          <div
            style={{
              top: "20%",
              left: "50%",
              color: "white",
              width: "200px",
              height: "100px",
              display: "flex",
              zIndex: "99999999",
              textAlign: "center",
              borderRadius: "5px",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "center",
              backgroundColor: "#333",
            }}
          >
            <p>
              <strong>
                {message || defaultLoadingMsg[appState.state.language]}
              </strong>
            </p>
            <ReactLoading
              color="silver"
              type="spin"
              height="20px"
              width="20px"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default React.memo(Loading);
