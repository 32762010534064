import React, { Component, createRef } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Form, Formik } from "formik";
import { Yup } from "../../util";
import Layout from "../shared/Layout";
import services from "../../services";
import { NotificationManager } from "react-notifications";
import _ from "lodash";
import Auth from "../shared/Auth";
import FieldRow from "../shared/FieldRow";
import withRouter from "../../util/withRouter";
import appState from "../../state/AppStateContainer";
import { Activities, Modules } from "../../staticData/Activities";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import CheckboxTree from "react-checkbox-tree";
import { isLtr } from "../../util/utils";
import { Button } from "react-bootstrap";

const getLocalizedName = (shop) => {
  return appState.state.language === "ar"
    ? shop.name.value_ar || shop.name.value_en
    : shop.name.value_en;
};

const acitivityNodes = Modules.map((m) => ({
  value: m.id,
  label: m.name,
  icon: <i className="fa fa-cubes" />,
  children: Activities.filter((a) => a.moduleId === m.id).map((a) => ({
    icon: <i className="fa fa-cube" />,
    value: a.id,
    label: a.name,
  })),
}));

export class RoleForm extends Component {
  constructor() {
    super();
    this.formik = createRef();
  }

  state = {
    data: {},
    orgNodes: [],
    expandedNodes: [],
    expanded: [],
    checked: [],
    loading: true,
  };

  async componentDidMount() {
    try {
      this.subscription = appState.emitter.addListener(
        "onLanguageChange",
        () => {
          this.formik.validateForm();
        }
      );

      const orgChart = await services.getOrgChart();
      const orgNodes = _.sortBy(orgChart.children, (branch) => branch.name).map(
        (branch) => ({
          value: "branch_" + branch.id,
          label: isLtr()
            ? branch.name?.value_en || branch?.name
            : branch.name?.value_ar || branch?.name,
          icon: <i className="fa fa-briefcase" />,
          // children: _.sortBy(_.get(ws, "children", []), (sp) =>
          //   getLocalizedName(sp)
          // ).map((sp) => ({
          //   icon: <i className="fa fa-user" />,
          //   value: "sp_" + sp.id,
          //   label: getLocalizedName(sp),
          // })),
        })
      );
      this.setState({ orgNodes });
      if (this.props.match.params.id) {
        const data = await services.getRole(this.props.match.params.id, {
          progressBar: true,
        });
        this.setState({ data });
      }
    } catch (e) {
      this.props.navigate("/error", {
        error: _.get(e, "response.data.error", "error.unexpectedError"),
      });
    } finally {
      this.setState({ loading: false });
    }
  }

  componentWillUnmount() {
    this.subscription.remove();
  }

  async handleSubmit(values, { setSubmitting }) {
    this.setState({ loading: true });
    setSubmitting(true);
    try {
      let response;
      const name = values.name;
      const activities = values.activities;
      const modules = _.uniq(
        Activities.filter((a) => activities.indexOf(a.id) >= 0).map(
          (a) => a.moduleId
        )
      );
      const branches = values.orgNodes.filter((n) => n.startsWith("branch_"));

      const roleData = {
        name,
        activities,
        modules,
        branches: branches.map((branch) => branch.substr(7)),
      };

      if (!this.props.match.params.id) {
        response = await services.addRole(roleData);
      } else {
        const request = { id: this.props.match.params.id, ...roleData };
        response = await services.updateRole(request);
      }

      NotificationManager.success(
        this.props.intl.formatMessage({
          id: "global.operationSuccessful",
          defaultMessage: "Operation Successful",
        }),
        this.props.intl.formatMessage({
          id: "global.success",
          defaultMessage: "Success",
        })
      );

      this.props.navigate("/roles", {
        isSubmitted: true,
      });
    } catch (e) {
      console.log("error while submitting role", e);
      NotificationManager.error(
        this.props.intl.formatMessage({
          id: _.get(e, "response.data.error", "error.unexpectedError"),
          defaultMessage: "An unexpected error has occurred",
        }),
        this.props.intl.formatMessage({
          id: "global.error",
          defaultMessage: "Error",
        })
      );
    } finally {
      setSubmitting(false);
      this.setState({ loading: false });
    }
  }

  render() {
    const { intl } = this.props;
    return (
      <Auth requireAuth={true} roles={["sadmin"]}>
        <Formik
          innerRef={(f) => {
            this.formik = f;
          }}
          enableReinitialize
          initialValues={{
            name: this.state.data.name || "",
            activities: this.state.data.activities || [],
            orgNodes: this.state.data.orgNodes || [],
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string()
              .label(
                intl.formatMessage({
                  id: "roles.name",
                  defaultMessage: "Name",
                })
              )
              .trim()
              .required(),
            activities: Yup.array()
              .of(Yup.string())
              .min(
                1,
                intl.formatMessage({
                  id: "roles.activities",
                  defaultMessage: "Please select at least one Activity",
                })
              ),
            orgNodes: Yup.array()
              .of(Yup.string())
              .min(
                1,
                intl.formatMessage({
                  id: "roles.branch",
                  defaultMessage: "Please select at least one branch",
                })
              ),
          })}
          onSubmit={this.handleSubmit.bind(this)}
        >
          {(formikProps) => {
            const { isSubmitting, values, setFieldValue, touched, errors } =
              formikProps;

            return (
              <Layout
                noLayout={this.props.noLayout}
                loading={this.state.loading}
              >
                <div className="dashboard-table-container card-layout">
                  <div className="panel-primary">
                    <header className="panel-title">
                      {this.props.match.params.id && (
                        <FormattedMessage
                          id="roles.update"
                          defaultMessage="Update Role"
                        />
                      )}
                      {!this.props.match.params.id && (
                        <FormattedMessage
                          id="roles.add"
                          defaultMessage="Add Role"
                        />
                      )}
                    </header>

                    <div className="">
                      <Form>
                        <div className="dashboard-row">
                          <FieldRow
                            formikProps={formikProps}
                            type="text"
                            name="name"
                            labelId="roles.name"
                            defaultLabel="Name"
                            placeholderId="roles.enterName"
                            defaultPlaceholder="Enter name"
                            showRequiredFlag={true}
                          />

                          <div className="row">
                            <div className="col-md-2 col-xs-12">
                              <label className="form-label caps block">
                                <FormattedMessage
                                  id="roles.activities"
                                  defaultMessage="Activities"
                                />
                              </label>
                            </div>
                            <div className="col-md-8 col-xs-12">
                              <CheckboxTree
                                nodes={acitivityNodes}
                                checked={values.activities}
                                expanded={this.state.expanded}
                                onCheck={(checked) => {
                                  setFieldValue("activities", checked);
                                }}
                                onExpand={(expanded) =>
                                  this.setState({ expanded })
                                }
                              />
                              {errors.activities && touched.activities && (
                                <p className="text-warning">
                                  {errors.activities}
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-2 col-xs-12">
                              <label className="form-label caps block">
                                <FormattedMessage
                                  id="roles.nodes"
                                  defaultMessage="Nodes"
                                />
                              </label>
                            </div>
                            <div className="col-md-8 col-xs-12">
                              <CheckboxTree
                                nodes={this.state.orgNodes}
                                checked={values.orgNodes}
                                expanded={this.state.expandedNodes}
                                onCheck={(checked) => {
                                  setFieldValue("orgNodes", checked);
                                }}
                                onExpand={(expanded) =>
                                  this.setState({ expandedNodes: expanded })
                                }
                              />
                              {touched["orgNodes"] && errors["orgNodes"] && (
                                <p className="text-warning">
                                  {errors["orgNodes"]}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="dashboard-row -nobottom">
                          <input
                            type="submit"
                            className="btn btn-primary"
                            value={this.props.intl.formatMessage({
                              id: "global.submit",
                              defaultMessage: "Submit",
                            })}
                            disabled={isSubmitting}
                          />
                          <Button
                            variant="link"
                            onClick={() => this.props.navigate("/roles")}
                            disabled={isSubmitting}
                          >
                            {this.props.intl.formatMessage({
                              id: "global.cancel",
                              defaultMessage: "Cancel",
                            })}
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              </Layout>
            );
          }}
        </Formik>
      </Auth>
    );
  }
}

export default withRouter(injectIntl(RoleForm));
