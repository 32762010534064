import React, { Component, createRef } from "react";
import withRouter from "../../../util/withRouter";
import { FormattedMessage, injectIntl } from "react-intl";
import { withAuthContext } from "../../../state/AuthContext";
import { Form, Formik } from "formik";
import { Yup } from "../../../util";
import _ from "lodash";
import FieldRow from "../../shared/FieldRow";
import Auth from "../../shared/Auth";
import Layout from "../../shared/Layout";
import HeaderTitle from "../../shared/HeaderTitle";
import appState from "../../../state/AppStateContainer";
import { NotificationManager } from "react-notifications";
import HeadlineService from "../../../services/headlineService";
import { Button } from "react-bootstrap";

export class Headlines extends Component {
  constructor() {
    super();
    this.formik = createRef();
  }

  state = {
    data: [],
    statusFilter: "all",
    approvalFilter: "all",
    loading: true,
  };

  async componentDidMount() {
    try {
      this.subscription = appState.emitter.addListener(
        "onLanguageChange",
        () => {
          this.formik.validateForm();
        }
      );

      if (this.props.match.params.id) {
        const data = await HeadlineService.getHeadline(
          this.props.match.params.id
        );
        this.setState({ data });
      }
    } catch (e) {
      this.props.navigate("/error", {
        error: _.get(e, "response.data.error", "error.unexpectedError"),
      });
    } finally {
      this.setState({ loading: false });
    }
  }

  componentWillUnmount() {
    this.subscription.remove();
  }

  async handleSubmit(
    {
      englishTitle,
      arabicTitle,
      englishDescription,
      arabicDescription,
      moreDetailsLink,
      rating,
      englishImage,
      arabicImage,
      isActive,
    },
    { setSubmitting }
  ) {
    this.setState({ loading: true });
    try {
      if (englishImage["name"]) {
        englishImage["name"] = englishImage.name.replace(/\s/g, "");
      }
      if (arabicImage["name"]) {
        arabicImage["name"] = arabicImage.name.replace(/\s/g, "");
      }
      const headlineData = {
        title: {
          en: englishTitle,
          ar: arabicTitle,
        },
        description: {
          en: englishDescription,
          ar: arabicDescription,
        },
        link: moreDetailsLink,
        rating: rating,
        isActive: isActive,
        imageEn: englishImage,
        imageAr: arabicImage,
      };
      let response;
      if (!this.props.match.params.id) {
        response = await HeadlineService.addHeadline(headlineData);
      } else {
        response = await HeadlineService.updateHeadline(
          headlineData,
          this.props.match.params.id
        );
      }

      NotificationManager.success(
        this.props.intl.formatMessage({
          id: "global.operationSuccessful",
          defaultMessage: "Operation Successful",
        }),
        this.props.intl.formatMessage({
          id: "global.success",
          defaultMessage: "Success",
        })
      );

      this.props.navigate("/headlines");
    } catch (e) {
      console.log(e?.response?.data);
      NotificationManager.error(
        this.props.intl.formatMessage({
          id: _.get(e, "response.data.message", "error.unexpectedError"),
          defaultMessage: e?.response?.data?.message,
        }),
        this.props.intl.formatMessage({
          id: "global.error",
          defaultMessage: "Error",
        })
      );
    } finally {
      setSubmitting(false);
      this.setState({ loading: false });
    }
  }

  render() {
    const { intl } = this.props;
    const { data } = this.state;
    return (
      <>
        <Auth requireAuth={true} roles={["sadmin", "wsadmin", "spadmin"]}>
          <Formik
            innerRef={(f) => {
              this.formik = f;
            }}
            enableReinitialize
            initialValues={{
              englishTitle: data[0]?.title.en || "",
              arabicTitle: data[0]?.title.ar || "",
              englishDescription: data[0]?.description.en || "",
              arabicDescription: data[0]?.description.ar || "",
              englishImage: data[0]?.image.en || "",
              arabicImage: data[0]?.image.ar || "",
              moreDetailsLink: data[0]?.link || "",
              rating: data[0]?.rating || "",
              isActive: data[0]?.isActive || false,
            }}
            validationSchema={Yup.object().shape({
              englishTitle: Yup.string()
                .hasNoArabic(
                  intl.formatMessage({
                    id: "error.arabicCharactersAreNotAllowedInThisField",
                    defaultMessage:
                      "Arabic characters are not allowed in this field",
                  })
                )
                .label(
                  intl.formatMessage({
                    id: "headline.englishTitle",
                    defaultMessage: "English title",
                  })
                )
                .trim()
                .required(),
              arabicTitle: Yup.string()
                .label(
                  intl.formatMessage({
                    id: "headline.arabicTitle",
                    defaultMessage: "Arabic title",
                  })
                )
                .trim()
                .required()
                .hasNoEnglish(
                  intl.formatMessage({
                    id: "error.englishCharactersAreNotAllowedInThisField",
                    defaultMessage:
                      "English characters are not allowed in this field",
                  })
                ),
              englishDescription: Yup.string()
                .hasNoArabic(
                  intl.formatMessage({
                    id: "error.arabicCharactersAreNotAllowedInThisField",
                    defaultMessage:
                      "Arabic characters are not allowed in this field",
                  })
                )
                .label(
                  intl.formatMessage({
                    id: "headline.englishDescription",
                    defaultMessage: "English description",
                  })
                )
                .trim()
                .required(),
              arabicDescription: Yup.string()
                .label(
                  intl.formatMessage({
                    id: "headline.arabicDescription",
                    defaultMessage: "Arabic Description",
                  })
                )
                .trim()
                .required()
                .hasNoEnglish(
                  intl.formatMessage({
                    id: "error.englishCharactersAreNotAllowedInThisField",
                    defaultMessage:
                      "English characters are not allowed in this field",
                  })
                ),
              rating: Yup.string()
                .label(
                  intl.formatMessage({
                    id: "headline.rating",
                    defaultMessage: "Rating",
                  })
                )
                .required(),
              moreDetailsLink: Yup.string()
                .label(
                  intl.formatMessage({
                    id: "headline.moreDetailsLink",
                    defaultMessage: "Enter button link",
                  })
                )
                .trim()
                .required(),
              englishImage: Yup.mixed()
                .label(
                  intl.formatMessage({
                    id: "headline.englishImage",
                    defaultMessage: "Image (English)",
                  })
                )
                .required(),
              arabicImage: Yup.mixed()
                .label(
                  intl.formatMessage({
                    id: "headline.arabicImage",
                    defaultMessage: "Image (Arabic)",
                  })
                )
                .required(),
            })}
            onSubmit={this.handleSubmit.bind(this)}
          >
            {(formikProps) => {
              const { isSubmitting, dirty } = formikProps;
              return (
                <Layout
                  noLayout={this.props.noLayout}
                  loading={this.state.loading}
                >
                  <HeaderTitle
                    id="headline.headlinesTitle"
                    defaultMessage="Headlines"
                  />

                  <div className="dashboard-table-container card-layout">
                    <div className="panel-primary">
                      <header className="panel-title">
                        {!this.props.match.params.id ? (
                          <FormattedMessage
                            id="headline.addHeadline"
                            defaultMessage="Add Headline"
                          />
                        ) : (
                          <FormattedMessage
                            id="headline.updateHeadline"
                            defaultMessage="Update Headline"
                          />
                        )}
                      </header>

                      <Form>
                        <div className="dashboard-row">
                          <FieldRow
                            name="englishTitle"
                            labelId="headline.englishTitle"
                            defaultLabel="Title (English)"
                            placeholderId="headline.enterEnglishTitle"
                            defaultPlaceholder="Enter English Title"
                            showRequiredFlag={true}
                            formikProps={formikProps}
                          />

                          <FieldRow
                            name="arabicTitle"
                            labelId="headline.arabicTitle"
                            defaultLabel="Title (Arabic)"
                            placeholderId="headline.enterArabicTitle"
                            defaultPlaceholder="Enter Arabic Title"
                            showRequiredFlag={true}
                            formikProps={formikProps}
                          />

                          <FieldRow
                            name="englishDescription"
                            type="textarea"
                            labelId="headline.englishDescription"
                            defaultLabel="Description (English)"
                            placeholderId="headline.enterEnglishDescription"
                            defaultPlaceholder="Enter English Description"
                            showRequiredFlag={true}
                            formikProps={formikProps}
                          />

                          <FieldRow
                            name="arabicDescription"
                            type="textarea"
                            labelId="headline.arabicDescription"
                            defaultLabel="Description (Arabic)"
                            placeholderId="headline.enterArabicDescription"
                            defaultPlaceholder="Enter Arabic Description"
                            showRequiredFlag={true}
                            formikProps={formikProps}
                          />

                          <FieldRow
                            name="moreDetailsLink"
                            labelId="headline.titleLink"
                            defaultLabel="More Details Link"
                            placeholderId="headline.enterTitleLink"
                            defaultPlaceholder="Enter More Details Link"
                            showRequiredFlag={true}
                            formikProps={formikProps}
                          />

                          <FieldRow
                            name="rating"
                            type="select"
                            labelId="headline.rating"
                            defaultLabel="Rating"
                            placeholderId="headline.selectRating"
                            defaultPlaceholder="Select Rating"
                            showRequiredFlag={true}
                            formikProps={formikProps}
                          >
                            <option value="">
                              {this.props.intl.formatMessage({
                                id: "headline.selectRating",
                                defaultMessage: "Select Rating",
                              })}
                            </option>
                            <option value="1">
                              {this.props.intl.formatMessage({
                                id: "headline.oneStar",
                                defaultMessage: "1 ",
                              })}
                            </option>
                            <option value="2">
                              {this.props.intl.formatMessage({
                                id: "headline.twoStar",
                                defaultMessage: "2 ",
                              })}
                            </option>
                            <option value="3">
                              {this.props.intl.formatMessage({
                                id: "headline.threeStar",
                                defaultMessage: "3 ",
                              })}
                            </option>
                            <option value="4">
                              {this.props.intl.formatMessage({
                                id: "headline.fourStar",
                                defaultMessage: "4 ",
                              })}
                            </option>
                          </FieldRow>

                          <br />

                          <FieldRow
                            name="englishImage"
                            type="file"
                            image={true}
                            cameraIcon={true}
                            showThumbnail={true}
                            labelId="headline.englishImage"
                            defaultLabel="Image (English)"
                            placeholderId="headline.selectImage"
                            defaultPlaceholder="Select English Image"
                            showRequiredFlag={true}
                            aspectRatio={150 / 30}
                            imageSize="W:150 x H:30"
                            formikProps={formikProps}
                          />
                          <FieldRow
                            name="arabicImage"
                            type="file"
                            image={true}
                            cameraIcon={true}
                            showThumbnail={true}
                            labelId="headline.arabicImage"
                            defaultLabel="Image (Arabic)"
                            placeholderId="headline.selectImage"
                            defaultPlaceholder="Select Arabic Image"
                            showRequiredFlag={true}
                            aspectRatio={1920 / 1080}
                            imageSize="W:1920 x H:500"
                            formikProps={formikProps}
                          />

                          <FieldRow
                            name="isActive"
                            type="checkbox"
                            labelId="headline.isActive"
                            defaultLabel="Active"
                            formikProps={formikProps}
                          />
                        </div>

                        <div className="dashboard-row -nobottom">
                          {this.props.authContext.authorized && (
                            <input
                              type="submit"
                              className="btn btn-primary"
                              style={{ marginBottom: "0" }}
                              value={this.props.intl.formatMessage({
                                id: "global.submit",
                                defaultMessage: "Submit",
                              })}
                              disabled={isSubmitting || !dirty}
                            />
                          )}
                          <Button
                            variant="link"
                            onClick={() => this.props.navigate("/headlines")}
                            disabled={isSubmitting}
                          >
                            {this.props.intl.formatMessage({
                              id: "global.cancel",
                              defaultMessage: "Cancel",
                            })}
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </div>
                </Layout>
              );
            }}
          </Formik>
        </Auth>
      </>
    );
  }
}

export default withAuthContext(withRouter(injectIntl(Headlines)));
