import React, { Component } from "react";
import { FormattedMessage, injectIntl, FormattedNumber } from "react-intl";
import Layout from "../shared/Layout";
import _ from "lodash";
import Auth from "../shared/Auth";
import { Link } from "react-router-dom";
import withRouter from "../../util/withRouter";
import appState from "../../state/AppStateContainer";
import HeaderTitle from "../shared/HeaderTitle";
import CheckAccess from "../shared/CheckAccess";
import { ActivitiesEnum } from "../../staticData/Activities";
import Table from "../shared/Table";
import BranchService from "../../services/branchesService";

export class BranchesList extends Component {
  state = {
    data: [],
    hasMore: true,
    pageIndex: -1,
    pageSize: 10,
    approvalFilter: "all",
    statusFilter: "all",
    count: 0,
    loading: false,
  };

  async loadMore() {
    this.setState(
      (currentState) => {
        return {
          pageIndex: currentState.pageIndex + 1,
          hasMore: false,
          loading: true,
        };
      },
      async () => {
        const pageIndex = 0;
        const options = { progressBar: true, alphabeticalOrder: true };
        const pageData = await BranchService.getBranches(
          this.state.searchFilter,
          this.state.statusFilter,
          this.state.categoryFilter,
          this.state.pageIndex,
          this.state.pageSize,
          options
        );

        const summary =
          pageIndex === 0
            ? await BranchService.getBranches(
                this.state.searchFilter,
                this.state.statusFilter,
                this.state.categoryFilter,
                this.state.pageIndex,
                this.state.pageSize,
                { summary: true }
              )
            : { count: this.state.count };

        this.setState((currentState) => {
          return {
            data: currentState.data.concat(pageData),
            hasMore: pageData.length > 0,
            disableFlag: false,
            count: summary.count,
            loading: false,
          };
        });
      }
    );
  }

  resetData(e) {
    this.setState({
      pageIndex: -1,
      data: [],
      hasMore: true,
      disableFlag: false,
      searchFilter: this.searchInput?.value,
      statusFilter: this.statusSelect?.value,
    });
    if (e) e.preventDefault();
  }

  resetPage(e) {
    this.setState({
      pageIndex: -1,
      data: [],
      hasMore: true,
      searchFilter: "",
      approvalFilter: "all",
      statusFilter: "all",
      disableFlag: false,
    });
    this.searchInput.value = "";
    this.statusSelect.value = "all";
    e.preventDefault();
  }

  render() {
    const { intl } = this.props;
    const { loading } = this.state;

    return (
      <Auth requireAuth={true} roles={["sadmin", "wsadmin", "spadmin"]}>
        <Layout hidden={this.props.match.params.isFormOpen} loading={loading}>
          <div className="table-header clearfix">
            <HeaderTitle id="branches.branches" defaultMessage="Branches" />
            <div className="main-container">
              <div className="table-header clearfix">
                <form className="row" onSubmit={this.resetData.bind(this)}>
                  <div className="col-md-2 col-sm-4 col-xs-6">
                    <div className="has-feedback">
                      <input
                        type="text"
                        ref={(c) => {
                          this.searchInput = c;
                        }}
                        className="form-control"
                        value={this.searchValueInput}
                        placeholder={intl.formatMessage({
                          id: "global.search",
                          defaultMessage: "Search",
                        })}
                      />
                      <i className="glyphicon glyphicon-search form-control-feedback" />
                    </div>
                  </div>
                  <div className="col-md-2 col-sm-4 col-xs-6">
                    <select
                      ref={(c) => {
                        this.statusSelect = c;
                      }}
                      onChange={this.resetData.bind(this)}
                      className="form-control"
                    >
                      <option value="all">
                        {intl.formatMessage({
                          id: "shops.status",
                          defaultMessage: "Status",
                        })}
                      </option>
                      <option value="Yes">
                        {intl.formatMessage({
                          id: "shops.active",
                          defaultMessage: "Active",
                        })}
                      </option>
                      <option value="No">
                        {intl.formatMessage({
                          id: "shops.suspended",
                          defaultMessage: "Suspended",
                        })}
                      </option>
                    </select>
                  </div>
                  <div className="col force--inline">
                    <div className="tooltip-master">
                      <button
                        // href="javascript:;"
                        className="btn btn-primary"
                        onClick={this.resetData.bind(this)}
                        disabled={this.state.disableFlag}
                      >
                        <i className="material-icons">search</i>
                      </button>
                      <span className="tooltiptext">
                        <FormattedMessage
                          id="global.filter"
                          defaultMessage="Filter"
                        />
                      </span>
                    </div>
                  </div>
                  <div className="col force--inline">
                    <div className="tooltip-master">
                      <button
                        // href="javascript:;"
                        className="btn btn-primary"
                        onClick={this.resetPage.bind(this)}
                        disabled={this.state.disableFlag}
                      >
                        <i className="material-icons">
                          settings_backup_restore
                        </i>
                      </button>
                      <span className="tooltiptext">
                        <FormattedMessage
                          id="global.resetFilter"
                          defaultMessage="Reset Filter"
                        />
                      </span>
                    </div>
                  </div>
                </form>
              </div>
              <div className="row">
                <div className="col-xs-3 pull-right">
                  <div className="panel-group-buttons pull-right">
                    <CheckAccess activityId={ActivitiesEnum.branch__add_branch}>
                      <Link className="btn btn-special" to="/branch">
                        <span className="fa fa-plus" />{" "}
                        <FormattedMessage
                          id="branches.new_branch"
                          defaultMessage="Create Branch"
                        />
                      </Link>
                    </CheckAccess>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <FormattedMessage
                    id="global.totalCount"
                    defaultMessage="Total Count"
                  />
                  <strong>
                    {" "}
                    <FormattedNumber
                      style="decimal"
                      maximumFractionDigits={0}
                      minimumFractionDigits={0}
                      value={this.state.data.length}
                    />{" "}
                  </strong>
                </div>
              </div>
            </div>
            <CheckAccess activityId={ActivitiesEnum.branch__view_branch}>
              <div className="table-responsive">
                <Table
                  loadMore={this.loadMore.bind(this)}
                  hasMore={this.state.hasMore}
                  caption={
                    (!this.state.data || this.state.data.length == 0) && (
                      <caption
                        style={{
                          captionSide: "bottom",
                          textAlign: "center",
                          margin: "20px",
                          color: "darkgray",
                        }}
                      >
                        <FormattedMessage
                          id="global.noData"
                          defaultMessage="No data available"
                        />
                      </caption>
                    )
                  }
                  headerRow={
                    <tr onClick={() => {}}>
                      <th>
                        <FormattedMessage
                          id="branches.name"
                          defaultMessage="Name"
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          id="branches.phoneNumber"
                          defaultMessage="Phone Number"
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          id="branches.inspirageId"
                          defaultMessage="Inspirage ID"
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          id="branches.isActive"
                          defaultMessage="Active"
                        />
                      </th>
                      <th style={{ width: "20px" }} />
                    </tr>
                  }
                >
                  {this.state.data?.map((c) => {
                    var showEdit = false;

                    if (
                      (appState.state.token?.role === "sadmin" &&
                        c.approvalState === "approved") ||
                      (appState.state.token?.id === c.approvalRequestedBy &&
                        c.approvalState !== "approved")
                    )
                      showEdit = true;

                    return (
                      <tr
                        key={c._id}
                        onClick={() => this.props.navigate("/branch/" + c._id)}
                        className="cases-trow link-row"
                      >
                        <td>
                          {appState.state.language === "en"
                            ? c.name?.value_en || c.name
                            : c.name?.value_ar || c.name}
                        </td>
                        <td className="direction">{c.phoneNumber}</td>
                        <td>{c.inspirageId}</td>
                        <td>{c.active}</td>
                      </tr>
                    );
                  })}
                </Table>
              </div>
            </CheckAccess>
          </div>
        </Layout>
      </Auth>
    );
  }
}

export default withRouter(injectIntl(BranchesList));
