import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import _ from "lodash";
import Autosuggest from "react-autosuggest";

export default class AutoSuggestInput extends Component {
  state = {
    suggestions: []
  };
  render() {
    const {
      placeholder,
      name,
      formikprops,
      disabled,
      getsuggestions
    } = this.props;

    const inputProps = {
      id: name,
      disabled,
      className: "form-control",
      placeholder,
      name,
      value: formikprops.values[name],
      onChange: (e, { newValue }) => {
        console.log(
          "onchange fired",
          e.currentTarget,
          e.currentTarget.value,
          name
        );
        // const touchedConfig = {};
        // touchedConfig[name] = true;
        formikprops.touched[name] = true;
        formikprops.setTouched(formikprops.touched);
        formikprops.setFieldValue(name, newValue);
      }
    };
    return (
      <React.Fragment>
        <Autosuggest
          type="file"
          ref={f => (this.innerInput = f)}
          inputProps={inputProps}
          suggestions={this.state.suggestions}
          onSuggestionsFetchRequested={async value => {
            const newSuggestions = await getsuggestions(value.value);
            this.setState({ suggestions: newSuggestions });
          }}
          onSuggestionsClearRequested={() => this.setState({ suggestions: [] })}
          getSuggestionValue={suggestion => {
            return suggestion.options;
          }}
          renderSuggestion={suggestion => <div>{suggestion.options}</div>}
        />
      </React.Fragment>
    );
  }
}
