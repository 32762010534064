import BaseService from "./baseService";
import axios from "axios";

class AutohubServices extends BaseService {
  constructor() {
    super();
  }

  async getServices(
    searchFilter,
    approvalFilter,
    statusFilter,
    // _pageIndex,
    // pageSize,
    alphabeticalOrder,
    options = {},
    summary
  ) {
    const response = await axios.get("/api/services", {
      params: {
        searchFilter,
        approvalFilter,
        statusFilter,
        // _pageIndex,
        // pageSize,
        alphabeticalOrder,
        summary: summary,
        fromDate: options.fromDate,
        toDate: options.toDate,
      },
      progressBar: options.progressBar,
    });
    return response.data?.response;
  }

  async updateServicesSorting(payload) {
    const response = await axios.put("/api/services-sorting", {
      data: payload,
    });
    return response.data;
  }

  async getServiceById(id, options = {}) {
    const result = await axios.get("/api/service", {
      params: { id },
      progressBar: options.progressBar,
    });
    return result.data.response;
  }

  async addService(data) {
    const formData = new FormData();
    for (let key in data) {
      formData.append(
        key,
        key === "cars" ? JSON.stringify(data[key]) : data[key]
      );
    }
    const response = await axios.post("/api/service", formData);
    return response.data;
  }

  async updateService(data) {
    const formData = new FormData();
    for (let key in data) {
      formData.append(
        key,
        key === "cars" ? JSON.stringify(data[key]) : data[key]
      );
    }
    const response = await axios.put("/api/service", formData);
    return response.data;
  }

  async getCarsListing() {
    const response = await axios.get("/api/car-brands-models");
    return response.data;
  }

  async cloneService(data) {
    const response = await axios.post("/api/clone-service", data);
    return response.data.response;
  }

  async getServiceCategories() {
    const result = await axios.get("/api/service-categories", {
      params: {
        forCMS: true,
      },
    });
    return result.data?.response;
  }
}
export default new AutohubServices();
