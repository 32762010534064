import React from "react";
import services from "../../services";

export default function CheckAccess({
  moduleId,
  activityId,
  children,
  accessOnChange,
}) {
  if (
    services.hasAccessToModule(moduleId) ||
    services.canPerformActivity(activityId) ||
    accessOnChange
  ) {
    return <React.Fragment>{children}</React.Fragment>;
  } else {
    return null;
  }
}
