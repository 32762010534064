import React, { useState, useRef, useEffect } from "react";
import { Form, Field, Formik } from "formik";
import appState from "../../state/AppStateContainer";
import * as Yup from "yup";
import { NotificationManager } from "react-notifications";
import { FormattedMessage, injectIntl } from "react-intl";
import { get } from "lodash";
import withRouter from "../../util/withRouter";
import services from "../../services";
import { automateOTPFields } from "../../util/utils";
import "./style.css";
import Error from "../../util/errorMessage";

const ServiceAdvisorLogin = (props) => {
  const { intl } = props;
  const lang = appState.state.language;

  const formikRef = useRef();
  const otpFirstDigit = useRef();
  const otpSecondDigit = useRef();
  const otpThirdDigit = useRef();
  const otpFourthDigit = useRef();

  const [show, setShow] = useState(false);
  const [showError, setShowError] = useState(false);
  const [lastOtp, setLastOtp] = useState("");

  useEffect(() => {
    formikRef.current.validateForm();
  }, [lang]);

  const sendOtpSms = async () => {
    try {
      const values = formikRef.current.values;

      const response = await services.serviceAdvisorLogin(values);

      if (response?.statusCode === 200) setShow(true);
    } catch (e) {
      NotificationManager.error(
        props.intl.formatMessage({
          id:
            e?.response?.data?.response ||
            Error[e?.response?.data?.key]?.key ||
            "error.unexpectedError",
          defaultMessage:
            Error[e?.response?.data?.key]?.value ||
            "An unexpected error has occurred",
        }),
        props.intl.formatMessage({
          id: "global.error",
          defaultMessage: "Error",
        })
      );
    }
  };

  const getVerifyUserValidationSchema = () => {
    return Yup.object().shape({
      code1: Yup.string().required(
        intl.formatMessage({
          id: "login.required",
          defaultMessage: "Required",
        })
      ),
      code2: Yup.string().required(
        intl.formatMessage({
          id: "login.required",
          defaultMessage: "Required",
        })
      ),
      code3: Yup.string().required(
        intl.formatMessage({
          id: "login.required",
          defaultMessage: "Required",
        })
      ),
      code4: Yup.string().required(
        intl.formatMessage({
          id: "login.required",
          defaultMessage: "Required",
        })
      ),
    });
  };

  const handleVerifyUserSubmit = async (values, { setSubmitting }) => {
    const code =
      values.code1.toString() +
      values.code2.toString() +
      values.code3.toString() +
      values.code4.toString();

    if (code === lastOtp) {
      setShowError(true);
      return;
    }

    setLastOtp(code);

    try {
      const token = await services.serviceAdvisorVerification({ code });

      NotificationManager.success(
        props.intl.formatMessage({
          id: "global.loginSuccessful",
          defaultMessage: "Login Successful",
        }),
        props.intl.formatMessage({
          id: "global.success",
          defaultMessage: "Success",
        })
      );
      if (get(props, "location.state.from")) {
        token = {
          ...token,
          route: get(props, "location.state.from"),
        };
        props.location.state.from = undefined;
      }
      appState.setToken(token);
      props.navigate("/");
    } catch (e) {
      NotificationManager.error(
        props.intl.formatMessage({
          id:
            e?.response?.data?.response ||
            Error[e?.response?.data?.key]?.key ||
            "error.unexpectedError",
          defaultMessage:
            Error[e?.response?.data?.key]?.value ||
            "An unexpected error has occurred",
        }),
        props.intl.formatMessage({
          id: "global.error",
          defaultMessage: "Error",
        })
      );
      setShowError(true);
    }
  };

  return (
    <>
      <div
        className={`${
          appState.state.language === "en"
            ? "en-bg-sky-white"
            : "ar-bg-sky-white"
        }`}
      >
        <div className="lang-wrapper">
          <a
            className=""
            style={{ cursor: "pointer" }}
            onClick={() => {
              appState.changeLanguage();
            }}
          >
            <span className="fa fa-language fa-2x" />
          </a>
        </div>

        <div className="login-row-wrapper">
          <div className="service-advisor-login-wrapper">
            <div className="login-left-box">
              <h3>
                {" "}
                <FormattedMessage
                  id="login.serviceAdvisor"
                  defaultMessage="Service Advisor"
                />
              </h3>
            </div>
            <div className="right-form-box">
              {!show ? (
                <Formik
                  initialValues={{
                    phoneNumber: "",
                  }}
                  validationSchema={Yup.object().shape({
                    phoneNumber: Yup.string().required(
                      intl.formatMessage({
                        id: "login.phoneNumberRequired",
                        defaultMessage: "Phone number is required",
                      })
                    ),
                  })}
                  innerRef={formikRef}
                  onSubmit={sendOtpSms}
                >
                  {({ touched, errors, isSubmitting }) => (
                    <Form>
                      <div className="full-row">
                        <div className="autohub-logo-wrapper">
                          <img
                            src={`/design/images/${
                              lang === "en" ? "ah-logo.webp" : "ah-logo-ar.webp"
                            }`}
                            alt="The Auto Hub CMS"
                          />
                        </div>
                        <Field
                          type="text"
                          className={`service-advisor-login-field ${
                            appState.state.language === "ar"
                              ? "arabic-field"
                              : ""
                          }`}
                          name="phoneNumber"
                          placeholder={intl.formatMessage({
                            id: "login.phoneNumber",
                            defaultMessage: "Phone number",
                          })}
                          autoFocus=""
                        />
                        {touched.phoneNumber && errors.phoneNumber && (
                          <p className="text-warning">{errors.phoneNumber}</p>
                        )}
                      </div>

                      <div className="full-row login-btn-wrapper">
                        <input
                          type="submit"
                          value={intl.formatMessage({
                            id: "global.login",
                            defaultMessage: "Login",
                          })}
                          // onClick={() => sendOtpSms(values)}
                          className="btn btn-primary btn-login"
                          disabled={isSubmitting}
                        />
                      </div>
                    </Form>
                  )}
                </Formik>
              ) : (
                <Formik
                  enableReinitialize
                  initialValues={{
                    code1: "",
                    code2: "",
                    code3: "",
                    code4: "",
                  }}
                  validationSchema={getVerifyUserValidationSchema}
                  onSubmit={handleVerifyUserSubmit}
                >
                  {({ values, isSubmitting }) => {
                    return (
                      <Form id="verifyUser">
                        <div className="otp-input-box">
                          <div className="otp-title-wrapper">
                            <h6 className="text-center">
                              <FormattedMessage
                                id="login.otpVerificationTitle"
                                defaultMessage="OTP Verification"
                              />
                            </h6>
                            <p>
                              <FormattedMessage
                                id="login.otpReceived"
                                defaultMessage="Enter the OTP you received
                                
                                "
                              />
                            </p>
                          </div>
                          <div className="OTP">
                            <div className="field-box-wrapper">
                              <div className="box">
                                <Field
                                  type="number"
                                  id="code1"
                                  name="code1"
                                  placeholder="X"
                                  className="inptFields"
                                  maxLength={1}
                                  innerRef={otpFirstDigit}
                                  pattern="[0-9]*"
                                  onInput={(e) =>
                                    automateOTPFields(
                                      e,
                                      otpSecondDigit,
                                      setShowError
                                    )
                                  }
                                />
                              </div>
                            </div>
                            <div className="field-box-wrapper">
                              <div className="box">
                                <Field
                                  type="number"
                                  id="code2"
                                  name="code2"
                                  placeholder="X"
                                  className="inptFields"
                                  maxLength={1}
                                  innerRef={otpSecondDigit}
                                  pattern="[0-9]*"
                                  onInput={(e) =>
                                    automateOTPFields(
                                      e,
                                      otpThirdDigit,
                                      setShowError
                                    )
                                  }
                                  onKeyDown={(e) =>
                                    automateOTPFields(e, otpFirstDigit)
                                  }
                                />
                              </div>
                            </div>
                            <div className="field-box-wrapper">
                              <div className="box">
                                <Field
                                  type="number"
                                  id="code3"
                                  name="code3"
                                  placeholder="X"
                                  className="inptFields"
                                  maxLength={1}
                                  innerRef={otpThirdDigit}
                                  pattern="[0-9]*"
                                  onInput={(e) =>
                                    automateOTPFields(
                                      e,
                                      otpFourthDigit,
                                      setShowError
                                    )
                                  }
                                  onKeyDown={(e) =>
                                    automateOTPFields(e, otpSecondDigit)
                                  }
                                />
                              </div>
                            </div>
                            <div className="field-box-wrapper">
                              <div className="box">
                                <Field
                                  type="number"
                                  id="code4"
                                  name="code4"
                                  placeholder="X"
                                  maxLength={1}
                                  innerRef={otpFourthDigit}
                                  className="inptFields"
                                  pattern="[0-9]*"
                                  onInput={(e) =>
                                    automateOTPFields(e, null, setShowError)
                                  }
                                  onKeyDown={(e) =>
                                    automateOTPFields(e, otpThirdDigit)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          {showError && (
                            <span
                              className="red-color mt-3"
                              style={{ marginBottom: "-5px" }}
                            >
                              <FormattedMessage
                                id="login.invalidOtp"
                                defaultMessage="Invalid OTP"
                              />
                            </span>
                          )}

                          <div className="mt-4 otp-event-btns">
                            <input
                              type="button"
                              onClick={() => setShow(false)}
                              className="back-btn"
                              value={intl.formatMessage({
                                id: "login.backBtn",
                                defaultMessage: "Back",
                              })}
                            />

                            <input
                              type="submit"
                              className={`verify-btn ${
                                values.code1 === "" ||
                                values.code2 === "" ||
                                values.code3 === "" ||
                                values.code4 === ""
                                  ? ""
                                  : "dark-blue"
                              }`}
                              value={intl.formatMessage({
                                id: "login.verifyBtn",
                                defaultMessage: "Verify",
                              })}
                              disabled={
                                values.code1 === "" ||
                                values.code2 === "" ||
                                values.code3 === "" ||
                                values.code4 === "" ||
                                isSubmitting
                              }
                            />
                          </div>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(injectIntl(ServiceAdvisorLogin));
