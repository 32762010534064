import React, { Component, createRef } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Form, Formik } from "formik";
import { Yup } from "../../util";
import Layout from "../shared/Layout";
import services from "../../services";
import { NotificationManager } from "react-notifications";
import _ from "lodash";
import Auth from "../shared/Auth";
import FieldRow from "../shared/FieldRow";
import withRouter from "../../util/withRouter";
import HeaderTitle from "../shared/HeaderTitle";
import { withAuthContext } from "../../state/AuthContext";
import { isLtr } from "../../util/utils";
import CheckAccess from "../shared/CheckAccess";
import { ActivitiesEnum } from "../../staticData/Activities";
import { Button } from "react-bootstrap";

export class CarForm extends Component {
  constructor() {
    super();

    this.formik = createRef();
    this.state = {
      loading: false,
      data: {},
    };
  }

  componentDidMount() {
    this.fetchCars();
  }

  async fetchCars() {
    this.setState({ loading: true });
    try {
      const options = { progressBar: true };
      const carDetails = await services.getCar(
        this.props.match.params.id,
        options
      );
      this.setState({ data: carDetails?.response });
    } catch (e) {
      this.props.navigate("/error", {
        error: _.get(e, "response.data.error", "error.unexpectedError"),
      });
    } finally {
      this.setState({ loading: false });
    }
  }

  async handleSubmit(values, { setSubmitting }) {
    this.setState({ loading: true });
    try {
      let response;
      const payload = {
        ...values,
        product: {
          value_en: values?.product_en,
          value_ar: values?.product_ar,
        },
      };
      if (this.props.match.params.id) {
        response = await services.updateCar({
          ...payload,
          id: this.props.match.params.id,
        });
      }
      this.props.navigate("/cars", {
        isSubmitted: true,
      });
      NotificationManager.success(
        this.props.intl.formatMessage({
          id: "global.operationSuccessful",
          defaultMessage: "Operation Successful",
        }),
        this.props.intl.formatMessage({
          id: "global.success",
          defaultMessage: "Success",
        })
      );
    } catch (e) {
      NotificationManager.error(
        this.props.intl.formatMessage({
          id: _.get(e, "response.data.error", "error.unexpectedError"),
          defaultMessage: "An unexpected error has occurred",
        }),
        this.props.intl.formatMessage({
          id: "global.error",
          defaultMessage: "Error",
        })
      );
    } finally {
      setSubmitting(false);
      this.setState({ loading: false });
    }
  }

  _renderBody(formikProps) {
    const { isSubmitting, dirty } = formikProps;
    return (
      <>
        <Layout noLayout={this.props.noLayout} loading={this.state.loading}>
          <HeaderTitle id="car.cars" defaultMessage="Cars" />
          <div className="dashboard-table-container card-layout">
            <div className="panel-primary">
              <header className="panel-title">
                {!this.props.match.params.id ? (
                  <FormattedMessage id="car.addCar" defaultMessage="Add Car" />
                ) : (
                  <FormattedMessage
                    id="car.updateCar"
                    defaultMessage="Update Car"
                  />
                )}
              </header>
              <Form>
                <div>
                  <div className="dashboard-row">
                    <FieldRow
                      formikProps={formikProps}
                      type="text"
                      name="brand"
                      labelId="car.brand"
                      defaultLabel="Brand"
                      placeholderId="car.brand"
                      defaultPlaceholder="Brand"
                      showRequiredFlag={true}
                      disabled={true}
                    />
                    <FieldRow
                      formikProps={formikProps}
                      type="text"
                      name="product_en"
                      labelId="car.productEn"
                      defaultLabel="Product EN"
                      placeholderId="car.productEn"
                      defaultPlaceholder="Product EN"
                      showRequiredFlag={true}
                    />
                    <FieldRow
                      formikProps={formikProps}
                      type="text"
                      name="product_ar"
                      labelId="car.productAr"
                      defaultLabel="Product AR"
                      placeholderId="car.productAr"
                      defaultPlaceholder="Product AR"
                      showRequiredFlag={true}
                    />
                    <FieldRow
                      formikProps={formikProps}
                      type="text"
                      name="modelYear"
                      labelId="car.modelYear"
                      defaultLabel="Model Year"
                      placeholderId="car.modelYear"
                      defaultPlaceholder="Model Year"
                      showRequiredFlag={true}
                      disabled={true}
                    />
                    <FieldRow
                      formikProps={formikProps}
                      type="text"
                      name="grade"
                      labelId="car.grade"
                      defaultLabel="Grade"
                      placeholderId="car.grade"
                      defaultPlaceholder="Grade"
                      showRequiredFlag={true}
                      disabled={true}
                    />
                    <FieldRow
                      formikProps={formikProps}
                      type="text"
                      name="type"
                      labelId="car.type"
                      defaultLabel="Type"
                      placeholderId="car.type"
                      defaultPlaceholder="Type"
                      showRequiredFlag={true}
                      disabled={true}
                    />
                    <FieldRow
                      formikProps={formikProps}
                      type="text"
                      name="category"
                      labelId="car.category"
                      defaultLabel="Category"
                      placeholderId="car.category"
                      defaultPlaceholder="Category"
                      showRequiredFlag={true}
                      disabled={true}
                    />
                    <FieldRow
                      formikProps={formikProps}
                      type="checkbox"
                      name="isActive"
                      labelId="car.isActive"
                      defaultLabel="Is Active"
                      placeholderId="car.isActive"
                      defaultPlaceholder="Is Active"
                      showRequiredFlag={true}
                    />
                  </div>
                  <div className="dashboard-row -nobottom">
                    <CheckAccess
                      activityId={ActivitiesEnum.cars__update_car_edit}
                    >
                      {this.props.authContext.authorized && (
                        <input
                          type="submit"
                          className="btn btn-primary"
                          value={this.props.intl.formatMessage({
                            id: "global.submit",
                            defaultMessage: "Submit",
                          })}
                          disabled={isSubmitting || !dirty}
                        />
                      )}
                    </CheckAccess>
                    <Button
                      variant="link"
                      onClick={() => this.props.navigate("/cars")}
                      disabled={isSubmitting}
                    >
                      {this.props.intl.formatMessage({
                        id: "global.cancel",
                        defaultMessage: "Cancel",
                      })}
                    </Button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </Layout>
      </>
    );
  }

  render() {
    const { intl } = this.props;
    return (
      <Auth requireAuth={true} roles={["sadmin", "wsadmin", "spadmin"]}>
        <Formik
          innerRef={(f) => {
            this.formik = f;
          }}
          enableReinitialize
          initialValues={{
            brand: isLtr()
              ? this.state.data?.brand?.value_en
              : this.state.data?.brand?.value_ar || "",
            product_en: this.state.data?.product?.value_en || "",
            product_ar: this.state.data?.product?.value_ar || "",
            modelYear: this.state.data.modelYear || "",
            grade: this.state.data.grade || "",
            type: this.state.data.type || "",
            category: this.state.data.category || "",
            isActive: this.props.match.params.id
              ? this.state.data.isActive
              : true,
          }}
          validationSchema={Yup.object().shape({
            brand: Yup.string().label(
              intl.formatMessage({
                id: "car.brand",
                defaultMessage: "Brand",
              })
            ),
            product_en: Yup.string()
              .hasNoArabic(
                intl.formatMessage({
                  id: "error.arabicCharactersAreNotAllowedInThisField",
                  defaultMessage:
                    "Arabic characters are not allowed in this field",
                })
              )
              .label(
                intl.formatMessage({
                  id: "car.product",
                  defaultMessage: "Product",
                })
              )
              .trim()
              .required(),
            product_ar: Yup.string()
              .hasNoEnglish(
                intl.formatMessage({
                  id: "error.englishCharactersAreNotAllowedInThisField",
                  defaultMessage:
                    "English characters are not allowed in this field",
                })
              )
              .label(
                intl.formatMessage({
                  id: "car.product",
                  defaultMessage: "Product",
                })
              )
              .trim(),
            modelYear: Yup.string().label(
              intl.formatMessage({
                id: "car.year",
                defaultMessage: "Model year",
              })
            ),
            grade: Yup.string().label(
              intl.formatMessage({
                id: "car.grade",
                defaultMessage: "Grade",
              })
            ),
            type: Yup.string().label(
              intl.formatMessage({
                id: "car.type",
                defaultMessage: "Type",
              })
            ),
            category: Yup.string().label(
              intl.formatMessage({
                id: "car.category",
                defaultMessage: "Category",
              })
            ),
          })}
          onSubmit={this.handleSubmit.bind(this)}
        >
          {this._renderBody.bind(this)}
        </Formik>
      </Auth>
    );
  }
}

export default withAuthContext(withRouter(injectIntl(CarForm)));
