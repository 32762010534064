import React from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import TableBody from "./TableBody";

const SortableItem = SortableElement(
  ({ c, updateStatus, deleteHandler, props, from }) => (
    <TableBody
      c={c}
      updateStatus={updateStatus}
      deleteHandler={deleteHandler}
      navigate={props.navigate}
      from={from}
    />
  )
);

const SortableList = SortableContainer(
  ({ items, deleteHandler, updateStatus, props, from }) => {
    return (
      <ul style={{ padding: 0 }}>
        {items?.map((value, index) => (
          <SortableItem
            key={`item-${index}`}
            index={index}
            c={value}
            deleteHandler={deleteHandler}
            updateStatus={updateStatus}
            props={props}
            from={from}
          />
        ))}
      </ul>
    );
  }
);

const SortableComponent = ({
  index,
  data,
  deleteHandler,
  updateStatus,
  props,
  updateSorting,
  from,
}) => {
  const onSortEnd = ({ oldIndex, newIndex }) => {
    updateSorting(oldIndex, newIndex);
  };

  return (
    <SortableList
      distance={1}
      items={data}
      index={index}
      deleteHandler={deleteHandler}
      updateStatus={updateStatus}
      onSortEnd={onSortEnd}
      props={props}
      from={from}
    />
  );
};
export default SortableComponent;
