import React, { useState, useEffect } from "react";
import { withAuthContext } from "../../../state/AuthContext";
import { FormattedMessage, injectIntl } from "react-intl";
import withRouter from "../../../util/withRouter";
import Layout from "../../shared/Layout";
import HeaderTitle from "../../shared/HeaderTitle";
import Auth from "../../shared/Auth";
import { Formik, Form } from "formik";
import FieldRow from "../../shared/FieldRow";
import services from "../../../services";
import appState from "../../../state/AppStateContainer";
import constants from "../../../staticData/constants";
import { Button } from "react-bootstrap";

const UpsellRecommendationForm = (props) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const id = props.match.params.id;

  useEffect(() => {
    getUpsellRecommendation();
  }, [id]);

  const getUpsellRecommendation = async () => {
    setLoading(true);
    try {
      const response = await services.getUpsellRecommendation(id);
      setData(response);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log("err", err);
    }
  };

  const handleSubmit = async ({ comment }) => {
    setLoading(true);
    try {
      const payload = { id };
      if (!comment) payload.isCustomerContacted = true;
      else payload.recommendedOfferComment = comment;
      const result = await services.updateRecommendedOffer(payload);
      if (result?.statusCode === 200) await getUpsellRecommendation();
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout noLayout={props.noLayout} loading={loading}>
      <HeaderTitle
        id="track.upsellRecommendations"
        defaultMessage="Upsell Recommendations"
      />

      <div className="dashboard-table-container card-layout">
        <Auth requireAuth={true} roles={["sadmin", "wsadmin", "spadmin"]}>
          <Formik
            enableReinitialize
            initialValues={{
              phoneNumber: data?.phoneNumber || "",
              offerName: data?.name?.["value_" + appState.state.language] || "",
              description:
                data?.detail?.["value_" + appState.state.language] || "",
              images: [],
              video: "",
              createdBy: data?.createdById?.firstName
                ? `${data?.createdById?.firstName || ""} ${
                    data?.createdById?.lastName || ""
                  }`
                : data?.createdById?.username || "",

              price: data?.price,
              labour: data?.labour,
              parts: data?.parts,
              boughtOut: data?.boughtOut,
              paint: data?.paint,
              oil: data?.oil,
              sublet: data?.sublet,
              isCustomerContacted: data.isCustomerContacted,
              comment: data?.recommendedOfferComment || "",
            }}
            onSubmit={handleSubmit}
          >
            {(formikProps) => (
              <div className="panel-primary">
                <header className="panel-title">
                  <FormattedMessage
                    id="track.upsellRecommendationDetails"
                    defaultMessage="Upsell Recommendation Details"
                  />
                </header>

                <Form>
                  <div className="dashboard-row">
                    <div className="eye-icon-wrapper">
                      <i
                        className={data?.seen ? "fa fa-eye" : "fa fa-eye-slash"}
                      />
                    </div>
                    <FieldRow
                      type="text"
                      name="phoneNumber"
                      labelId="track.phoneNumber"
                      defaultLabel="Phone Number"
                      placeholderId="track.phoneNumber"
                      defaultPlaceholder="Phone Number"
                      formikProps={formikProps}
                      disabled={true}
                    />
                    <FieldRow
                      type="text"
                      name="offerName"
                      labelId="track.offerName"
                      defaultLabel="Offer Name"
                      placeholderId="track.offerName"
                      defaultPlaceholder="Offer Name"
                      formikProps={formikProps}
                      disabled={true}
                    />
                    <FieldRow
                      type="textarea"
                      name="description"
                      labelId="track.description"
                      defaultLabel="Description"
                      placeholderId="track.description"
                      defaultPlaceholder="Description"
                      formikProps={formikProps}
                      disabled={true}
                    />
                    <FieldRow
                      type="text"
                      name="createdBy"
                      labelId="track.createdBy"
                      defaultLabel="Created By"
                      placeholderId="track.createdBy"
                      defaultPlaceholder="Created By"
                      formikProps={formikProps}
                      disabled={true}
                    />
                    <FieldRow
                      type="number"
                      name="price"
                      labelId="fastCollect.price"
                      defaultLabel="Price"
                      placeholderId="fastCollect.price"
                      defaultPlaceholder="Price"
                      formikProps={formikProps}
                      disabled={true}
                    />
                    <FieldRow
                      type="number"
                      name="labour"
                      labelId="fastCollect.labour"
                      defaultLabel="Labour"
                      placeholderId="fastCollect.labour"
                      defaultPlaceholder="Labour"
                      formikProps={formikProps}
                      disabled={true}
                    />
                    <FieldRow
                      type="number"
                      name="parts"
                      labelId="fastCollect.parts"
                      defaultLabel="Parts"
                      placeholderId="fastCollect.parts"
                      defaultPlaceholder="Parts"
                      formikProps={formikProps}
                      disabled={true}
                    />
                    <FieldRow
                      type="number"
                      name="boughtOut"
                      labelId="fastCollect.boughtOut"
                      defaultLabel="Bought Out"
                      placeholderId="fastCollect.boughtOut"
                      defaultPlaceholder="Bought Out"
                      formikProps={formikProps}
                      disabled={true}
                    />
                    <FieldRow
                      type="number"
                      name="paint"
                      labelId="fastCollect.paint"
                      defaultLabel="Paint"
                      placeholderId="fastCollect.paint"
                      defaultPlaceholder="Paint"
                      formikProps={formikProps}
                      disabled={true}
                    />
                    <FieldRow
                      type="number"
                      name="oil"
                      labelId="fastCollect.oil"
                      defaultLabel="Oil"
                      placeholderId="fastCollect.oil"
                      defaultPlaceholder="Oil"
                      formikProps={formikProps}
                      disabled={true}
                    />
                    <FieldRow
                      type="number"
                      name="sublet"
                      labelId="fastCollect.sublet"
                      defaultLabel="Sublet"
                      placeholderId="fastCollect.sublet"
                      defaultPlaceholder="Sublet"
                      formikProps={formikProps}
                      disabled={true}
                    />
                    {data?.images?.length > 1 ? (
                      <div className="row">
                        <div className="col-md-2 col-sm-2 col-xs-12">
                          <label className="form-label caps block">
                            <FormattedMessage
                              id="track.images"
                              defaultMessage="Images"
                            />
                          </label>
                        </div>
                        <div className="input-group col-md-8 col-sm-8 col-xs-12">
                          <div className="media-wrapper upsell-recommmendation-form">
                            <div className="media recommendationMedia">
                              {data?.images?.map(({ value }) => {
                                return (
                                  <img
                                    src={`/api/util/image?key=${value}&accesskey=${constants.MEDIA_KEY}`}
                                  />
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                    {data?.video ? (
                      <div className="row">
                        <div className="col-md-2 col-sm-2 col-xs-12">
                          <label className="form-label caps block">
                            <FormattedMessage
                              id="track.video"
                              defaultMessage="Video"
                            />
                          </label>
                        </div>
                        <div className="input-group col-md-8 col-sm-8 col-xs-12">
                          <div className="media-wrapper upsell-recommmendation-form">
                            <div className="media">
                              <video controls>
                                <source
                                  src={`/api/util/image?key=${data?.video}&accesskey=${constants.MEDIA_KEY}`}
                                  type="video/mp4"
                                />
                              </video>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}

                    <div className="customer-contacted">
                      {!data.isCustomerContacted ? (
                        <h5>
                          <FormattedMessage
                            id="fastCollect.isCustomerContacted"
                            defaultMessage="Have you contacted the customer?"
                          />
                        </h5>
                      ) : (
                        <>
                          <h4 className="text-success">
                            <i className="fa fa-check" />{" "}
                            <FormattedMessage
                              id="fastCollect.customerContacted"
                              defaultMessage="Customer contacted by"
                            />{" "}
                            <b>
                              {data.isCustomerContacted?.firstName +
                                " " +
                                data.isCustomerContacted?.lastName}{" "}
                              ({data.isCustomerContacted?.role})
                            </b>
                          </h4>
                          <br />
                          <FieldRow
                            type="textarea"
                            name="comment"
                            labelId="fastCollect.comment"
                            defaultLabel="Comment"
                            placeholderId="fastCollect.addComment"
                            defaultPlaceholder="Add comment here if any"
                            formikProps={formikProps}
                            disabled={data.recommendedOfferComment}
                          />
                        </>
                      )}

                      {!data.recommendedOfferComment && (
                        <Button type="submit" disabled={data.isCustomerContacted && !formikProps.values.comment}>
                          {!data.isCustomerContacted ? (
                            <FormattedMessage
                              id="fastCollect.contactedYes"
                              defaultMessage="Yes"
                            />
                          ) : (
                            <FormattedMessage
                              id="fastCollect.saveComment"
                              defaultMessage="Save comment"
                            />
                          )}
                        </Button>
                      )}
                    </div>
                  </div>

                  <div className="dashboard-row -nobottom">
                    <Button
                      variant="link"
                      onClick={() => props.navigate("/upsell-recommendations")}
                    >
                      {props.intl.formatMessage({
                        id: "global.cancel",
                        defaultMessage: "Cancel",
                      })}
                    </Button>
                  </div>
                </Form>
              </div>
            )}
          </Formik>
        </Auth>
      </div>
    </Layout>
  );
};

export default withAuthContext(
  withRouter(injectIntl(UpsellRecommendationForm))
);
