import React from "react";
import { Modal } from "react-bootstrap";
import { getFormattedCarNumberPlate } from "../../../util/utils";
import appState from "../../../state/AppStateContainer";
import Notification from "./Notification";
import { FormattedMessage } from "react-intl";

const EstimationNotificationModal = ({ show, close, selectedEstimation }) => {
  const language = appState.state.language;

  const {
    _id,
    comments,
    status,
    carData,
    otherBrand,
    otherModel,
    licencePlate,
  } = selectedEstimation;
  return (
    <Modal
      show={show}
      onHide={close}
      centered
      size="xl"
      className="estimation-comment-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <FormattedMessage
            id="track.estimationHeading"
            defaultMessage="Estimation Request"
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="image-guidance-modal-body comments-modal notification-modal">
        <div className="image-guide-modal-header">
          <h4>
            <FormattedMessage
              id="track.notificationHeading"
              defaultMessage="Notifications"
            />
          </h4>
          {comments?.length ? (
            <h6>
              <span>
                {otherBrand || carData[0].brand["value_" + language]}{" "}
                {otherModel || carData[0].product["value_" + language]}{" "}
              </span>
              <span>{getFormattedCarNumberPlate(licencePlate)}</span>
            </h6>
          ) : null}
        </div>

        <div
          className="notifications-container"
          aria-live="polite"
          aria-atomic="true"
        >
          <Notification messages={comments} />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default EstimationNotificationModal;
