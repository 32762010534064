import React, { useEffect, useState } from "react";
import { Link, Route } from "react-router-dom";
import withRouter from "../../../util/withRouter";
import { FormattedMessage, injectIntl, FormattedNumber } from "react-intl";
import { withAuthContext } from "../../../state/AuthContext";
import _ from "lodash";
import Auth from "../../shared/Auth";
import Layout from "../../shared/Layout";
import HeaderTitle from "../../shared/HeaderTitle";
import appState from "../../../state/AppStateContainer";
import CheckAccess from "../../shared/CheckAccess";
import { ActivitiesEnum } from "../../../staticData/Activities";
import homeBannerService from "../../../services/homeBannerService";
import { NotificationManager } from "react-notifications";
import SortableComponent from "../../../shared/DragAndDropTable";
import { arrayMove } from "react-sortable-hoc";

const HomeBannersListCopy = (props) => {
  const [data, setData] = useState([]);
  const [activeData, setActiveData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [disableFlag, setDisableFlag] = useState(false);

  useEffect(() => {
    if (props.location.pathname) {
      if (!props.match.params.isFormOpen) {
        if (_.get(props, "location.state.isSubmitted") === true) {
          setData([]);
          setDisableFlag(true);
          getBannersData();
        }
      }
    }
  }, [props.location.pathname]);

  const resetData = (e) => {
    setData([]);
    setDisableFlag(true);

    if (e) e.preventDefault();
  };
  useEffect(() => {
    getBannersData();
  }, []);

  const getBannersData = async () => {
    setLoading(true);
    const getBanners = await homeBannerService.getHomeBanners();
    setData(getBanners);
    setActiveData(getBanners.filter(({ isActive }) => isActive));
    setLoading(false);
    setDisableFlag(false);
  };

  const updateHomebannerStatus = async (id, status) => {
    try {
      const message = {
        en: "Do you want to update status?",
        ar: "هل تريد تحديث الحالة؟",
      };
      if (!window.confirm(message[appState.state.language])) return;
      const response = await homeBannerService.updateHomeBannerStatus(
        id,
        status
      );
      if (response.status) await getBannersData();
    } catch (e) {
      NotificationManager.error(
        props.intl.formatMessage({
          id: _.get(e, "response.data.message", "error.unexpectedError"),
          defaultMessage: e?.response?.data?.message,
        }),
        props.intl.formatMessage({
          id: "global.error",
          defaultMessage: "Error",
        })
      );
    }
  };

  const deleteHandler = async (id) => {
    const message = {
      en: "Do you want to delete banner?",
      ar: "هل تريد حذف البانر؟",
    };
    if (!window.confirm(message[appState.state.language])) return;
    const response = await homeBannerService.deleteHomeBanner(id);
    if (response.status === "Deleted") await getBannersData();
  };

  const resetPage = (e) => {
    setData([]);
    setDisableFlag(true);

    getBannersData();
    e.preventDefault();
  };

  const updateBannerSorting = async (oldIndex, newIndex) => {
    try {
      const updatedData = arrayMove(data, oldIndex, newIndex);
      updatedData.map((item, i) => {
        item.order = i;
      });
      setData(updatedData);
      setLoading(true);
      await homeBannerService.updateHomeBannerSorting(updatedData);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Auth requireAuth={true} roles={["sadmin", "wsadmin", "spadmin"]}>
      <Layout hidden={props.match.params.isFormOpen} loading={loading}>
        <div className="table-header clearfix">
          <HeaderTitle id="menu.bannersTitle" defaultMessage="Banners" />
          <div className="table-header clearfix">
            <button
              className="btn btn-primary"
              onClick={resetPage}
              disabled={disableFlag}
            >
              <i className="material-icons">settings_backup_restore</i>
            </button>
          </div>
          {activeData.length < 6 ? (
            <div className="row">
              <div className="col-xs-3 pull-right">
                <div className="panel-group-buttons pull-right">
                  <CheckAccess activityId={ActivitiesEnum.content_home}>
                    <Link className="btn btn-special " to="/banner">
                      <span className="fa fa-plus" />{" "}
                      <FormattedMessage
                        id="home.newBanner"
                        defaultMessage="Create Banner"
                      />
                    </Link>
                  </CheckAccess>
                </div>
              </div>
            </div>
          ) : null}

          <div className="row">
            <div className="col-md-12">
              <FormattedMessage
                id="global.totalCount"
                defaultMessage="Total Count"
              />

              <strong>
                {" "}
                <FormattedNumber
                  style="decimal"
                  maximumFractionDigits={0}
                  minimumFractionDigits={0}
                  value={data && data.length}
                />{" "}
              </strong>
            </div>
          </div>
        </div>
        <div>
          <div className="custom-table">
            <div className="custom-table-header">
              <div>
                <FormattedMessage id="banners.image" defaultMessage="Image" />
              </div>

              <div>
                <FormattedMessage
                  id="banners.englishTitle"
                  defaultMessage="English Title"
                />
              </div>
              <div>
                <FormattedMessage
                  id="banners.arabicTitle"
                  defaultMessage="Arabic Title"
                />
              </div>

              <div>
                <FormattedMessage
                  id="global.expiryAt"
                  defaultMessage="Expiry after"
                />
              </div>

              <div>
                <FormattedMessage
                  id="banner.isActive"
                  defaultMessage="IsActive"
                />
              </div>
            </div>
            {!data && data?.length <= 0 ? (
              <div
                style={{
                  captionSide: "bottom",
                  textAlign: "center",
                  margin: "20px",
                  color: "darkgray",
                }}
              >
                <FormattedMessage
                  id="global.noData"
                  defaultMessage="No data available"
                />
              </div>
            ) : (
              <SortableComponent
                data={data && data}
                _setData={setData}
                deleteHandler={deleteHandler}
                updateStatus={updateHomebannerStatus}
                props={props}
                updateSorting={updateBannerSorting}
                from="banners"
              />
            )}
          </div>
        </div>
      </Layout>
    </Auth>
  );
};

export default withAuthContext(withRouter(injectIntl(HomeBannersListCopy)));
