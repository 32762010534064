import React, { useState, useEffect } from "react";
import { FormattedMessage, FormattedNumber, injectIntl } from "react-intl";
import Table from "../shared/Table";
import { isNull } from "lodash";
import Auth from "../shared/Auth";
import Layout from "../shared/Layout";
import HeaderTitle from "../shared/HeaderTitle";
import withRouter from "../../util/withRouter";
import services from "../../services";
import appState from "../../state/AppStateContainer";
import { Link } from "react-router-dom";

const RequiredDocumentsListing = ({ noLayout, navigate }) => {
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);

  const [data, setData] = useState([]);
  const [quoteService, setQuoteService] = useState([]);

  useEffect(() => {
    getServices();
    getQuoteServices();
  }, []);

  const getServices = async () => {
    try {
      setLoading(true);
      const {
        response: { data },
      } = await services.getServicesAgainstCarOwnBy();
      setData(data || []);
      const { count } = await services.getServicesAgainstCarOwnBy(true);
      setCount(count);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const getQuoteServices = async (servicesID) => {
    try {
      setLoading(true);
      const { data: servicesData } =
        await services.getQuoteServicesAgainstCarOwnBy(servicesID);

      setQuoteService(servicesData || []);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  return (
    <div>
      <Auth requireAuth={true} roles={["sadmin"]}>
        <Layout noLayout={noLayout} loading={loading}>
          <style>
            {`
              tbody td {
                min-width:80px;
              }
            `}
          </style>
          <div
            id="pageContainer"
            style={{
              height: "100%",
            }}
          >
            <HeaderTitle
              id="requiredDoc.listing"
              defaultMessage="Required Documents For Appointment Services"
            />
            <div className="main-container">
              <div className="row">
                <div className="col-xs-3 pull-right">
                  <div className="panel-group-buttons pull-right">
                    <Link className="btn btn-special" to="/required-document">
                      <span className="fa fa-plus" />{" "}
                      <FormattedMessage
                        id="requiredDoc.addNew"
                        defaultMessage="Assign Documents"
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <FormattedMessage
                  id="global.totalCount"
                  defaultMessage="Total count"
                />{" "}
                <strong>
                  <FormattedNumber
                    style="decimal"
                    maximumFractionDigits={0}
                    minimumFractionDigits={0}
                    value={count || 0}
                  />{" "}
                </strong>
              </div>
            </div>
            <Table
              loadMore={() => {}}
              caption={
                !data ||
                (data.length == 0 && (
                  <caption
                    className="noDataCaption"
                    style={{
                      captionSide: "bottom",
                      textAlign: "center",
                      margin: "20px",
                      color: "darkgray",
                    }}
                  >
                    <FormattedMessage
                      id="global.noData"
                      defaultMessage="No data available"
                    />
                  </caption>
                ))
              }
              headerRow={
                <tr>
                  <th>
                    <FormattedMessage
                      id="requiredDoc.Service"
                      defaultMessage="Service"
                    />
                  </th>
                  {/* <th>
                    <FormattedMessage
                      id="requiredDoc.createdAt"
                      defaultMessage="Created at"
                    />
                  </th> */}

                  <th style={{ width: "20px" }} />
                </tr>
              }
            >
              {data.map((c) => {
                if (isNull(c)) return;
                return (
                  <tr
                    key={c._id}
                    onClick={() => {
                      navigate(`/required-document/${c._id}`);
                    }}
                    className="cases-trow link-row"
                  >
                    <td>
                      {appState.state.language === "en"
                        ? c.name.value_en
                        : c.name.value_ar}
                    </td>

                    {/* <td>{c.createdAt ? getLocalDateTime(c.createdAt) : undefined}</td> */}
                  </tr>
                );
              })}
            </Table>
          </div>
        </Layout>
      </Auth>
    </div>
  );
};
export default React.memo(withRouter(injectIntl(RequiredDocumentsListing)));
