import BaseService from "./baseService";
import axios from "axios";

class SettingsService extends BaseService {
  constructor() {
    super();
  }

  async updateSettings(values) {
    const formData = new FormData();
    for (const key in values) {
      formData.append(key, values[key]);
    }

    const response = await axios.put("/api/settings", formData);
    return response.data;
  }

  async getSettings() {
    const response = await axios.get("/api/settings");
    return response.data;
  }
}

export default new SettingsService();
