import React, { Component, createRef } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Form, Formik } from "formik";
import { Yup } from "../../util";
import Layout from "../shared/Layout";
import Services from "../../services/scheduleEmailService";
import { NotificationManager } from "react-notifications";
import _ from "lodash";
import Auth from "../shared/Auth";
import FieldRow from "../shared/FieldRow";
import withRouter from "../../util/withRouter";
import appState from "../../state/AppStateContainer";
import HeaderTitle from "../shared/HeaderTitle";
import { withAuthContext } from "../../state/AuthContext";
import moment from "moment";
import { ActivitiesEnum } from "../../staticData/Activities";
import CheckAccess from "../shared/CheckAccess";
import { Button } from "react-bootstrap";

export class ScheduleForm extends Component {
  constructor() {
    super();
    this.formik = createRef();
  }

  state = {
    suggestion: [],
    data: {},
    value: {},
    statusFilter: "all",
    approvalFilter: "all",
    loading: true,
  };

  async componentDidMount() {
    try {
      this.subscription = appState.emitter.addListener(
        "onLanguageChange",
        () => {
          this.formik.validateForm();
          this.setState({
            value: {
              startFrom: moment(this.state.value.startFrom).locale(
                appState.state.language
              ),
            },
          });
        }
      );

      if (this.props.match.params.id) {
        const data = await Services.getScheduleEmailById(
          this.props.match.params.id,
          { progressBar: true }
        );
        this.setState({
          data,
          value: {
            startFrom: moment(data.startFrom),
          },
        });
      }
    } catch (e) {
      this.props.navigate("/error", {
        error: _.get(e, "response.data.error", "error.unexpectedError"),
      });
    } finally {
      this.setState({ loading: false });
    }
  }

  componentWillUnmount() {
    this.subscription.remove();
  }

  async handleSubmit(values, { setSubmitting }) {
    this.setState({ loading: true });
    try {
      let response;
      if (!this.props.match.params.id)
        response = await Services.addScheduleEmail(values);
      else {
        const request = { id: this.props.match.params.id, ...values };
        response = await Services.updateScheduleEmail(request);
      }

      NotificationManager.success(
        this.props.intl.formatMessage({
          id: "global.operationSuccessful",
          defaultMessage: "Operation Successful",
        }),
        this.props.intl.formatMessage({
          id: "global.success",
          defaultMessage: "Success",
        })
      );

      this.props.navigate("/schedule-emails");
    } catch (e) {
      NotificationManager.error(
        this.props.intl.formatMessage({
          id: _.get(e, "response.data.error", "error.unexpectedError"),
          defaultMessage: "An unexpected error has occurred",
        }),
        this.props.intl.formatMessage({
          id: "global.error",
          defaultMessage: "Error",
        })
      );
    } finally {
      setSubmitting(false);
      this.setState({ loading: false });
    }
  }

  _renderBody(formikProps) {
    const { isSubmitting } = formikProps;

    return (
      <Layout noLayout={this.props.noLayout} loading={this.state.loading}>
        <HeaderTitle
          id="scheduleEmail.scheduleEmail"
          defaultMessage="Schedule Emails"
        />

        <div className="dashboard-table-container card-layout">
          <div className="panel-primary">
            <header className="panel-title">
              <CheckAccess
                activityId={ActivitiesEnum.schedule_email_add_update}
              >
                {this.props.match.params.id && (
                  <FormattedMessage
                    id="scheduleEmail.update"
                    defaultMessage="Update Schedule Email"
                  />
                )}
                {!this.props.match.params.id && (
                  <FormattedMessage
                    id="scheduleEmail.add"
                    defaultMessage="Add Schedule Email"
                  />
                )}
              </CheckAccess>
            </header>

            <div className="">
              <Form>
                <div className="dashboard-row">
                  <FieldRow
                    // colSize={3}
                    name="reportType"
                    type="select"
                    labelId="scheduleEmail.reportTypeLabel"
                    defaultLabel="Report Type"
                    placeholderId="scheduleEmail.reportType"
                    defaultPlaceholder="Select Report Type"
                    showRequiredFlag={true}
                    formikProps={formikProps}
                  >
                    <option value="appointment">
                      {this.props.intl.formatMessage({
                        id: "scheduleEmail.appointment",
                        defaultMessage: "Appointment",
                      })}
                    </option>
                  </FieldRow>

                  <FieldRow
                    // colSize={3}
                    name="tenure"
                    type="select"
                    labelId="scheduleEmail.tenureLabel"
                    defaultLabel="Tenure"
                    placeholderId="scheduleEmail.tenure"
                    defaultPlaceholder="Select Tenure"
                    showRequiredFlag={true}
                    formikProps={formikProps}
                  >
                    <option value="daily">
                      {this.props.intl.formatMessage({
                        id: "scheduleEmail.daily",
                        defaultMessage: "Daily",
                      })}
                    </option>
                    <option value="weekly">
                      {this.props.intl.formatMessage({
                        id: "scheduleEmail.weekly",
                        defaultMessage: "Weekly",
                      })}
                    </option>
                    <option value="monthly">
                      {this.props.intl.formatMessage({
                        id: "scheduleEmail.monthly",
                        defaultMessage: "Monthly",
                      })}
                    </option>
                  </FieldRow>

                  <FieldRow
                    startFromPlaceholderText={this.props.intl.formatMessage({
                      id: "scheduleEmail.startFrom",
                      defaultMessage: "Start From",
                    })}
                    type="singleDate"
                    name="startFrom"
                    // startFrom={this.state?.data?.startFrom}
                    initialStartFrom={this.state?.value?.startFrom}
                    labelId="scheduleEmail.dateLabel"
                    defaultLabel="Date"
                    placeholderId="scheduleEmail.date"
                    formikProps={formikProps}
                    showRequiredFlag={true}
                  />

                  <FieldRow
                    type="tag"
                    name="emails"
                    data={this.state.data.emails}
                    // initialEmails={this.state.value.emails}
                    labelId="scheduleEmail.emails"
                    defaultLabel="Emails"
                    emailsPlaceholderText={this.props.intl.formatMessage({
                      id: "scheduleEmail.addEmails",
                      defaultMessage: "Add Email",
                    })}
                    formikProps={formikProps}
                    showRequiredFlag={true}
                  />
                </div>
                <div className="dashboard-row -nobottom">
                  <CheckAccess
                    activityId={ActivitiesEnum.schedule_email_add_update}
                  >
                    {this.props.authContext.authorized && (
                      <input
                        type="submit"
                        className="btn btn-primary"
                        value={this.props.intl.formatMessage({
                          id: "global.submit",
                          defaultMessage: "Submit",
                        })}
                        disabled={isSubmitting}
                      />
                    )}
                  </CheckAccess>
                  <Button
                    variant="link"
                    onClick={() => this.props.navigate("/schedule-emails")}
                    disabled={isSubmitting}
                  >
                    {this.props.intl.formatMessage({
                      id: "global.cancel",
                      defaultMessage: "Cancel",
                    })}
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </Layout>
    );
  }

  render() {
    const { intl } = this.props;

    return (
      <Auth requireAuth={true} roles={["sadmin", "wsadmin", "spadmin"]}>
        <Formik
          innerRef={(f) => {
            this.formik = f;
          }}
          enableReinitialize
          initialValues={{
            reportType: this.state.data.reportType || "appointment",
            tenure: this.state.data.tenure || "daily",
            startFrom: this.state.data.startFrom || "",
            emails: this.state.data.emails || [],
          }}
          validationSchema={Yup.object().shape({
            startFrom: Yup.mixed()
              .label(
                intl.formatMessage({
                  id: "scheduleEmail.startFrom",
                  defaultMessage: "Date",
                })
              )
              .required(),
            emails: Yup.array()
              .min(1)
              .label(
                intl.formatMessage({
                  id: "scheduleEmail.emails",
                  defaultMessage: "Emails",
                })
              )
              .required(),
          })}
          onSubmit={this.handleSubmit.bind(this)}
        >
          {this._renderBody.bind(this)}
        </Formik>
      </Auth>
    );
  }
}

export default withAuthContext(withRouter(injectIntl(ScheduleForm)));
