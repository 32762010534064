import React, { useEffect, useState, useRef } from "react";
import { FormattedMessage, injectIntl, FormattedNumber } from "react-intl";
import Layout from "../shared/Layout";
import AutohubServices from "../../services/autohubServices";
import _ from "lodash";
import Auth from "../shared/Auth";
import { Link } from "react-router-dom";
import withRouter from "../../util/withRouter";
import moment from "moment";
import HeaderTitle from "../shared/HeaderTitle";
import CheckAccess from "../shared/CheckAccess";
import { ActivitiesEnum } from "../../staticData/Activities";
import SortableComponent from "../../shared/DragAndDropTable";
import { arrayMove } from "react-sortable-hoc";
import CloneServiceModal from "./ServiceCloneModal";
import { NotificationManager } from "react-notifications";

const ServiceList = (props) => {
  const { intl } = props;

  const [data, setData] = useState([]);
  const [approvalFilter, setApprovalFilter] = useState("all");
  const [statusFilter, setStatusFilter] = useState("all");
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [disableFlag, setDisableFlag] = useState(false);
  const [cloneModal, setCloneModal] = useState(false);
  const [targetService, setTargetService] = useState("");

  const aplhabeticalOrder = true;
  const searchInputRef = useRef();

  useEffect(() => {
    loadMore();
  }, []);

  const loadMore = async (searchFilter = "") => {
    try {
      setLoading(true);
      const options = {
        progressBar: true,
        fromDate: from ? moment(from).startOf("day").toString() : null,
        toDate: to ? moment(to).endOf("day").toString() : null,
      };

      const pageData = await AutohubServices.getServices(
        searchFilter,
        approvalFilter,
        statusFilter,
        aplhabeticalOrder,
        options
      );

      setCount(pageData?.length || 0);
      setData(pageData);
      setDisableFlag(false);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const resetData = (e) => {
    setData([]);
    setDisableFlag(true);
    if (e) e.preventDefault();

    loadMore(searchInputRef.current.value);
  };

  const resetPage = (e) => {
    setData([]);
    setApprovalFilter("all");
    setStatusFilter("all");
    setDisableFlag(true);
    setTo(undefined);
    setFrom(undefined);

    searchInputRef.current.value = "";
    e.preventDefault();

    loadMore();
  };

  const updateServicesSorting = async (oldIndex, newIndex) => {
    setLoading(true);
    try {
      const updatedData = arrayMove(data, oldIndex, newIndex);
      updatedData.map((item, i) => {
        item.order = i;
      });
      setData(updatedData);
      await AutohubServices.updateServicesSorting(updatedData);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const updateStatus = (id) => {
    if (loading) return;

    setCloneModal(!cloneModal);
    setTargetService(!cloneModal ? id : "");
  };

  const cloneService = async (values) => {
    setLoading(true);
    const payload = {
      name: {
        value_en: values.name_en,
        value_ar: values.name_ar,
      },
      targetService,
    };
    try {
      await AutohubServices.cloneService(payload);
      await loadMore();
      updateStatus();
    } catch (e) {
      console.error(e);
      if (e.response.data.response.error === "error.duplicateService") {
        return NotificationManager.error(
          intl.formatMessage({
            id: e.response.data.response.error,
            defaultMessage: "Service already exist with the same name",
          }),
          intl.formatMessage({
            id: "global.error",
            defaultMessage: "Error",
          })
        );
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Auth requireAuth={true} roles={["sadmin", "wsadmin", "spadmin"]}>
      <Layout hidden={props.match.params.isFormOpen} loading={loading}>
        <div className="table-header clearfix">
          <HeaderTitle id="service.service" defaultMessage="Services" />
          <div className="main-container">
            <CheckAccess
              activityId={ActivitiesEnum.services__filter_and_search}
            >
              <div className="table-header clearfix">
                <form className="row" onSubmit={resetData}>
                  <div className="col-xs-3 pull-right"></div>
                  <div className="col-md-2 col-sm-4 col-xs-6">
                    <div className="has-feedback">
                      <input
                        type="text"
                        ref={searchInputRef}
                        className="form-control"
                        //   value={searchValueInput}
                        placeholder={intl.formatMessage({
                          id: "global.search",
                          defaultMessage: "Search",
                        })}
                      />
                      <i className="glyphicon glyphicon-search form-control-feedback" />
                    </div>
                  </div>
                  <div className="col force--inline">
                    <div className="tooltip-master">
                      <button
                        href="javascript:;"
                        className="btn btn-primary"
                        onClick={resetData}
                        disabled={disableFlag}
                      >
                        <i className="material-icons">search</i>
                      </button>
                      <span className="tooltiptext">
                        <FormattedMessage
                          id="global.filter"
                          defaultMessage="Filter"
                        />
                      </span>
                    </div>
                  </div>
                  <div className="col force--inline">
                    <div className="tooltip-master">
                      <button
                        href="javascript:;"
                        className="btn btn-primary"
                        onClick={resetPage}
                        disabled={disableFlag}
                      >
                        <i className="material-icons">
                          settings_backup_restore
                        </i>
                      </button>
                      <span className="tooltiptext">
                        <FormattedMessage
                          id="global.resetFilter"
                          defaultMessage="Reset Filter"
                        />
                      </span>
                    </div>
                  </div>
                </form>
              </div>
            </CheckAccess>

            <div className="row">
              <div className="col-xs-3 pull-right">
                <div className="panel-group-buttons pull-right">
                  <CheckAccess
                    activityId={ActivitiesEnum.services__new_service}
                  >
                    <Link className="btn btn-special " to="/service">
                      <span className="fa fa-plus" />{" "}
                      <FormattedMessage
                        id="service.new_services"
                        defaultMessage="Create Service"
                      />
                    </Link>
                  </CheckAccess>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <FormattedMessage
                  id="global.totalCount"
                  defaultMessage="Total Count"
                />

                <strong>
                  {" "}
                  <FormattedNumber
                    style="decimal"
                    maximumFractionDigits={0}
                    minimumFractionDigits={0}
                    value={count || 0}
                  />{" "}
                </strong>
              </div>
            </div>
          </div>
          <div>
            <div className="dashboard-table-container card-layout">
              <div className="table-responsive">
                <div className="custom-table">
                  <div className="custom-table-header">
                    <div>
                      <FormattedMessage
                        id="service.englishName"
                        defaultMessage="English Name"
                      />
                    </div>
                    <div>
                      <FormattedMessage
                        id="service.arabicName"
                        defaultMessage="Arabic Name"
                      />
                    </div>
                    <div>
                      <FormattedMessage
                        id="service.serviceType"
                        defaultMessage="Service Type"
                      />
                    </div>
                    <div>
                      <FormattedMessage
                        id="service.isComingSoon"
                        defaultMessage="Coming Soon"
                      />
                    </div>
                    <div>
                      <FormattedMessage
                        id="global.createdAt"
                        defaultMessage="Created At"
                      />
                    </div>
                    <div>
                      <FormattedMessage
                        id="service.isActive"
                        defaultMessage="Is Active"
                      />
                    </div>
                  </div>
                  {!data && data?.length <= 0 ? (
                    <div
                      style={{
                        captionSide: "bottom",
                        textAlign: "center",
                        margin: "20px",
                        color: "darkgray",
                      }}
                    >
                      <FormattedMessage
                        id="global.noData"
                        defaultMessage="No data available"
                      />
                    </div>
                  ) : (
                    <div className="main-container">
                      <SortableComponent
                        data={data}
                        _setData={setData}
                        props={props}
                        updateSorting={updateServicesSorting}
                        updateStatus={updateStatus}
                        from="service"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>

      <CloneServiceModal
        show={cloneModal}
        onSubmit={cloneService}
        onCancel={updateStatus}
        loading={loading}
      />
    </Auth>
  );
};

export default withRouter(injectIntl(ServiceList));
