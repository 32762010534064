import React, { useState, useEffect, useRef } from "react";
import { FormattedMessage, FormattedNumber, injectIntl } from "react-intl";
import classNames from "classnames";
import Layout from "../shared/Layout";
import services from "../../services";
import _ from "lodash";
import Auth from "../shared/Auth";
import { Link } from "react-router-dom";
import withRouter from "../../util/withRouter";
import ActionMenu from "../shared/ActionMenu";
import Table from "../shared/Table";
import appState from "../../state/AppStateContainer";
import HeaderTitle from "../shared/HeaderTitle";
import CheckAccess from "../shared/CheckAccess";
import { ActivitiesEnum } from "../../staticData/Activities";

const CarList = (props) => {
  const { intl } = props;

  const [count, setCount] = useState(0);
  const [data, setData] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [pageIndex, setPageIndex] = useState(-1);
  const [pageSize, setPageSize] = useState(10);
  const [searchFilter, setSearchFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("all");
  const [categoryFilter, setCategoryFilter] = useState("all");
  const [disableFlag, setDisableFlag] = useState(false);
  const [loading, setLoading] = useState(false);

  const searchInputRef = useRef(null);
  const statusSelectRef = useRef(null);
  const categorySelectRef = useRef(null);
  const prevPropsRef = useRef();

  useEffect(() => {
    prevPropsRef.current = props;
  });

  const loadMore = async () => {
    setLoading(true);
    setPageIndex((prevState) => prevState + 1);

    setHasMore(false);
    const _pageIndex = pageIndex + 1;

    const options = {
      progressBar: true,
      alphabeticalOrder: true,
    };

    const pageData = await services.getCars(
      searchFilter,
      statusFilter,
      categoryFilter,
      _pageIndex,
      pageSize,
      options
    );
    let summary = { count };

    if (_pageIndex === 0) {
      summary = await services.getCars(
        searchFilter,
        statusFilter,
        categoryFilter,
        undefined,
        undefined,
        options,
        summary
      );
    }

    setData((prevData) => [...prevData, ...pageData]);
    setHasMore(pageData.length > 0);
    setDisableFlag(false);
    setLoading(false);
    if (_pageIndex === 0) {
      setCount(summary.count);
    }
  };

  const resetData = (e) => {
    setPageIndex(-1);
    setData([]);
    setHasMore(true);
    setDisableFlag(true);
    setStatusFilter(statusSelectRef.current.value);
    setCategoryFilter(categorySelectRef.current.value);
    setSearchFilter(searchInputRef.current.value);
    if (e) e.preventDefault();
  };

  const resetPage = (e) => {
    setPageIndex(-1);
    setData([]);
    setHasMore(true);
    setSearchFilter("");
    setStatusFilter("all");
    setCategoryFilter("all");
    setDisableFlag(true);
    searchInputRef.current.value = "";
    statusSelectRef.current.value = "all";
    categorySelectRef.current.value = "all";
    e.preventDefault();
  };

  const categories = [
    {
      value: "smallSedan",
      label: intl.formatMessage({
        id: "global.smallSedan",
        defaultMessage: "Small Sedan",
      }),
    },
    {
      value: "bigSedan",
      label: intl.formatMessage({
        id: "global.bigSedan",
        defaultMessage: "Big Sedan",
      }),
    },
    {
      value: "smallSUV",
      label: intl.formatMessage({
        id: "global.smallSUV",
        defaultMessage: "Small SUV",
      }),
    },
    {
      value: "bigSUV",
      label: intl.formatMessage({
        id: "global.bigSUV",
        defaultMessage: "Big SUV",
      }),
    },
  ];

  return (
    <Auth requireAuth={true} roles={["sadmin"]}>
      <Layout loading={loading}>
        <HeaderTitle id="car.cars" defaultMessage="Cars" />
        <div className="main-container">
          <CheckAccess activityId={ActivitiesEnum.cars__filter_and_search}>
            <div className="table-header clearfix">
              <form className="row" onSubmit={resetData}>
                <div className="col-xs-3 pull-right">
                  <div className="panel-group-buttons pull-right">
                    {/* <CheckAccess activityId={ActivitiesEnum.cars__add_new_car}>
                    <Link className="btn btn-special " to="/cars/form">
                      <span className="fa fa-plus" />{" "}
                      <FormattedMessage
                        id="car.addNew"
                        defaultMessage="Add New Car"
                      />
                    </Link>
                  </CheckAccess> */}
                  </div>
                </div>
                <div className="col-md-2 col-sm-4 col-xs-6">
                  <div className="has-feedback">
                    <input
                      type="text"
                      ref={searchInputRef}
                      className="form-control"
                      placeholder={intl.formatMessage({
                        id: "global.search",
                        defaultMessage: "Search",
                      })}
                    />
                    <i className="glyphicon glyphicon-search form-control-feedback" />
                  </div>
                </div>
                <div className="col-md-3 col-sm-4 col-xs-6">
                  <select
                    ref={categorySelectRef}
                    className="form-control"
                  >
                    <option value="all">
                      {intl.formatMessage({
                        id: "car.category",
                        defaultMessage: "Category",
                      })}
                    </option>
                    {categories.map((c, i) => (
                      <option value={c.value} key={i}>
                        {c.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-2 col-sm-4 col-xs-6">
                  <select
                    ref={statusSelectRef}
                    className="form-control"
                  >
                    <option value="all">
                      {intl.formatMessage({
                        id: "operator.status",
                        defaultMessage: "Status",
                      })}
                    </option>
                    <option value="active">
                      {intl.formatMessage({
                        id: "operator.active",
                        defaultMessage: "Active",
                      })}
                    </option>
                    <option value="suspended">
                      {intl.formatMessage({
                        id: "operator.suspended",
                        defaultMessage: "Suspended",
                      })}
                    </option>
                  </select>
                </div>
                <div className="col force--inline">
                  <div className="tooltip-master">
                    <button
                      // href="javascript:;"
                      className="btn btn-primary"
                      onClick={resetData}
                      disabled={disableFlag}
                    >
                      <i className="material-icons">search</i>
                    </button>
                    <span className="tooltiptext">
                      <FormattedMessage
                        id="global.filter"
                        defaultMessage="Filter"
                      />
                    </span>
                  </div>
                </div>
                <div className="col force--inline">
                  <div className="tooltip-master">
                    <button
                      // href="javascript:;"
                      className="btn btn-primary"
                      onClick={resetPage}
                      disabled={disableFlag}
                    >
                      <i className="material-icons">settings_backup_restore</i>
                    </button>
                    <span className="tooltiptext">
                      <FormattedMessage
                        id="global.resetFilter"
                        defaultMessage="Reset Filter"
                      />
                    </span>
                  </div>
                </div>
              </form>
            </div>
          </CheckAccess>
          <div className="row">
            <div className="col-md-12">
              <FormattedMessage
                id="global.totalCount"
                defaultMessage="Total Count"
              />

              <strong>
                {" "}
                <FormattedNumber
                  style="decimal"
                  maximumFractionDigits={0}
                  minimumFractionDigits={0}
                  value={count || 0}
                />{" "}
              </strong>
            </div>
          </div>

          <CheckAccess activityId={ActivitiesEnum.cars__table}>
            <div className="dashboard-table-container card-layout">
              <div className="table-responsive">
                <Table
                  loadMore={loadMore}
                  hasMore={hasMore}
                  caption={
                    (!data || data.length == 0) && (
                      <caption
                        style={{
                          captionSide: "bottom",
                          textAlign: "center",
                          margin: "20px",
                          color: "darkgray",
                        }}
                      >
                        <FormattedMessage
                          id="global.noData"
                          defaultMessage="No data available"
                        />
                      </caption>
                    )
                  }
                  headerRow={
                    <tr onClick={() => {}}>
                      <th>
                        <FormattedMessage
                          id="car.brand"
                          defaultMessage="Brand"
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          id="car.product"
                          defaultMessage="Product"
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          id="car.grade"
                          defaultMessage="Grade"
                        />
                      </th>
                      <th>
                        <FormattedMessage id="car.year" defaultMessage="Year" />
                      </th>
                      <th>
                        <FormattedMessage id="car.type" defaultMessage="Type" />
                      </th>
                      <th>
                        <FormattedMessage
                          id="car.category"
                          defaultMessage="Category"
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          id="operator.status"
                          defaultMessage="Status"
                        />
                      </th>
                      <th style={{ width: "20px" }} />
                    </tr>
                  }
                >
                  {data &&
                    data.map((car, i) => (
                      <tr
                        key={i}
                        onClick={() => {
                          props.navigate("/car/" + car._id);
                        }}
                        className="cases-trow link-row"
                      >
                        <td>{car.brand?.value_en}</td>
                        <td>{car.product?.value_en}</td>
                        <td>{car.grade}</td>
                        <td>{car.modelYear}</td>
                        <td>{car.type}</td>
                        <td>
                          {intl.formatMessage({
                            id: "global." + car.category,
                            defaultMessage: car.category,
                          })}
                        </td>
                        <td
                          className={classNames({
                            "col-status-green": car.isActive,
                            "col-status-red": !car.isActive,
                          })}
                        >
                          <a>
                            {car.isActive && (
                              <FormattedMessage
                                id="operator.active"
                                defaultMessage="Active"
                              />
                            )}
                            {!car.isActive && (
                              <FormattedMessage
                                id="operator.suspended"
                                defaultMessage="Suspended"
                              />
                            )}
                          </a>
                        </td>
                        <td style={{ position: "relative" }}>
                          <ActionMenu
                            // visible={visible}
                            menuStyle={{
                              backgroundColor: "#E9E9E9",
                            }}
                          >
                            <CheckAccess
                              activityId={ActivitiesEnum.cars__update_car_edit}
                            >
                              <Link
                                style={{ margin: "10px", cursor: "pointer" }}
                                to={`/car/${car._id}`}
                              >
                                <span className="fa fa-pencil" />{" "}
                                <FormattedMessage
                                  id="global.edit"
                                  defaultMessage="Edit"
                                />
                              </Link>
                            </CheckAccess>
                            {appState.state.token?.role === "sadmin" && (
                              <CheckAccess
                                activityId={ActivitiesEnum.user_activity__all}
                              >
                                <Link
                                  style={{
                                    margin: "10px",
                                    cursor: "pointer",
                                  }}
                                  to={`/userActivities/${car._id}`}
                                >
                                  <span className="fa fa-history" />{" "}
                                  <FormattedMessage
                                    id="global.viewHistory"
                                    defaultMessage="View History"
                                  />
                                </Link>
                              </CheckAccess>
                            )}
                            <a
                              style={{ margin: "10px", cursor: "pointer" }}
                              onClick={() => {}}
                            />
                          </ActionMenu>
                        </td>
                      </tr>
                    ))}
                </Table>
              </div>
            </div>
          </CheckAccess>
        </div>
      </Layout>
    </Auth>
  );
};

export default withRouter(injectIntl(CarList));
