import React, { Component } from "react";
import appState from "../../state/AppStateContainer";
import withRouter from "../../util/withRouter";
import _ from "lodash";
import { FormattedMessage } from "react-intl";
import services from "../../services";
export class ImageProvider extends Component {
  state = {
    image: ""
  };

  async componentDidMount() {}

  render() {
    let logoSrc = this.props.imageSrc;
    const key = "xzic2b7Fei1GJlKuQsJcBtIo12qfnJ5W"
    return (
      <td
        onClick={() => {
          this.props.onClick && this.props.onClick();
        }}
      >
        {_.isEmpty(logoSrc) && (
          <div className="profile-thumbnail">
            <img
              src="/design/images/carhub_placeholder.png"
              alt=""
              style={{ width: "50px", ...this.props.style }}
            />
          </div>
        )}
        {logoSrc && !_.isUndefined(logoSrc) && (
          <div className="profile-thumbnail">
            <img
              src={`/api/util/image?key=${logoSrc}&accesskey=${key}`}
              alt=""
              style={{ width: "50px", ...this.props.style }}
            />
          </div>
        )}
      </td>
    );
  }
}

export default withRouter(ImageProvider);
