import React, { Component, createRef } from "react";
import withRouter from "../../../util/withRouter";
import { FormattedMessage, injectIntl } from "react-intl";
import { withAuthContext } from "../../../state/AuthContext";
import { Form, Formik } from "formik";
import { Yup } from "../../../util";
import _ from "lodash";
import FieldRow from "../../shared/FieldRow";
import Auth from "../../shared/Auth";
import Layout from "../../shared/Layout";
import HeaderTitle from "../../shared/HeaderTitle";
import appState from "../../../state/AppStateContainer";
import classNames from "classnames";
import { NotificationManager } from "react-notifications";
import HomeBannerService from "../../../services/homeBannerService";
import moment from "moment";
import { Button } from "react-bootstrap";

export class HomeBannerForm extends Component {
  constructor() {
    super();
    this.formik = createRef();
  }

  state = {
    data: [],
    value: {},
    statusFilter: "all",
    approvalFilter: "all",
    loading: true,
  };

  async componentDidMount() {
    try {
      this.setState({ loading: true });

      this.subscription = appState.emitter.addListener(
        "onLanguageChange",
        () => {
          this.formik.validateForm();
        }
      );

      if (this.props.match.params.id) {
        const data = await HomeBannerService.getHomeBanners(
          this.props.match.params.id
        );
        this.setState({ data });
      }
    } catch (e) {
      this.props.navigate("/error", {
        error: _.get(e, "response.data.error", "error.unexpectedError"),
      });
    } finally {
      this.setState({ loading: false });
    }
  }

  componentWillUnmount() {
    this.subscription.remove();
  }

  async handleSubmit(
    {
      englishTitle,
      arabicTitle,
      englishDescription,
      arabicDescription,
      englishBtnText,
      arabicBtnText,
      btnLink,
      expiryDate,
      homeBanner_en,
      homeBanner_ar,
      homeBanner_mobile_en,
      homeBanner_mobile_ar,
      isActive,
    },
    { setSubmitting }
  ) {
    this.setState({ loading: true });
    try {
      if (homeBanner_en["name"]) {
        homeBanner_en["name"] = homeBanner_en.name.replace(/\s/g, "");
      }
      if (homeBanner_ar["name"]) {
        homeBanner_ar["name"] = homeBanner_ar.name.replace(/\s/g, "");
      }
      if (homeBanner_mobile_en["name"]) {
        homeBanner_mobile_en["name"] = homeBanner_mobile_en.name.replace(/\s/g, "");
      }
      if (homeBanner_mobile_ar["name"]) {
        homeBanner_mobile_ar["name"] = homeBanner_mobile_ar.name.replace(/\s/g, "");
      }

      const bannerData = {
        title: {
          en: englishTitle,
          ar: arabicTitle,
        },
        description: {
          en: englishDescription,
          ar: arabicDescription,
        },
        buttonText: {
          en: englishBtnText,
          ar: arabicBtnText,
        },
        link: btnLink,
        expiryDate:
          typeof expiryDate === "string"
            ? expiryDate
            : expiryDate?._d?.toString(),
        homeBanner_en,
        homeBanner_ar,
        homeBanner_mobile_en,
        homeBanner_mobile_ar,
        isActive: isActive,
      };
      let response;
      if (!this.props.match.params.id) {
        response = await HomeBannerService.addHomeBanner(bannerData);
      } else {
        response = await HomeBannerService.updateHomeBanner(
          bannerData,
          this.props.match.params.id
        );
      }

      NotificationManager.success(
        this.props.intl.formatMessage({
          id: "global.operationSuccessful",
          defaultMessage: "Operation Successful",
        }),
        this.props.intl.formatMessage({
          id: "global.success",
          defaultMessage: "Success",
        })
      );

      this.props.navigate("/banners");
    } catch (e) {
      console.log(e?.response?.data);
      NotificationManager.error(
        this.props.intl.formatMessage({
          id: _.get(e, "response.data.message", "error.unexpectedError"),
          defaultMessage: e?.response?.data?.message,
        }),
        this.props.intl.formatMessage({
          id: "global.error",
          defaultMessage: "Error",
        })
      );
    } finally {
      setSubmitting(false);
      this.setState({ loading: false });
    }
  }

  render() {
    const { intl } = this.props;
    const { data } = this.state;
    return (
      <>
        <Auth requireAuth={true} roles={["sadmin", "wsadmin", "spadmin"]}>
          <Formik
            innerRef={(f) => {
              this.formik = f;
            }}
            enableReinitialize
            initialValues={{
              englishTitle: data[0]?.title.en || "",
              arabicTitle: data[0]?.title.ar || "",
              englishDescription: data[0]?.description.en || "",
              arabicDescription: data[0]?.description.ar || "",
              englishBtnText: data[0]?.buttonText.en || "",
              arabicBtnText: data[0]?.buttonText.ar || "",
              btnLink: data[0]?.link || "",
              expiryDate: data[0]?.expiryDate ? moment(data[0]?.expiryDate) : "",
              homeBanner_en: data[0]?.homeBanner_en || "",
              homeBanner_ar: data[0]?.homeBanner_ar || "",
              homeBanner_mobile_en: data[0]?.homeBanner_mobile_en || "",
              homeBanner_mobile_ar: data[0]?.homeBanner_mobile_ar || "",
              isActive: data[0]?.isActive || false,
            }}
            validationSchema={Yup.object().shape(
              {
                englishTitle: Yup.string()
                  .hasNoArabic(
                    intl.formatMessage({
                      id: "error.arabicCharactersAreNotAllowedInThisField",
                      defaultMessage:
                        "Arabic characters are not allowed in this field",
                    })
                  )
                  .label(
                    intl.formatMessage({
                      id: "banner.englishTitle",
                      defaultMessage: "English title",
                    })
                  )
                  .trim(),
                englishDescription: Yup.string()
                  .hasNoArabic(
                    intl.formatMessage({
                      id: "error.arabicCharactersAreNotAllowedInThisField",
                      defaultMessage:
                        "Arabic characters are not allowed in this field",
                    })
                  )
                  .label(
                    intl.formatMessage({
                      id: "banner.englishDescription",
                      defaultMessage: "English description",
                    })
                  )
                  .trim(),
                arabicTitle: Yup.string()
                  .label(
                    intl.formatMessage({
                      id: "banner.arabicTitle",
                      defaultMessage: "Arabic title",
                    })
                  )
                  .trim()
                  .hasNoEnglish(
                    intl.formatMessage({
                      id: "error.englishCharactersAreNotAllowedInThisField",
                      defaultMessage:
                        "English characters are not allowed in this field",
                    })
                  ),
                arabicDescription: Yup.string()
                  .label(
                    intl.formatMessage({
                      id: "banner.arabicDescription",
                      defaultMessage: "Arabic Description",
                    })
                  )
                  .trim()
                  .hasNoEnglish(
                    intl.formatMessage({
                      id: "error.englishCharactersAreNotAllowedInThisField",
                      defaultMessage:
                        "English characters are not allowed in this field",
                    })
                  ),
                englishBtnText: Yup.string()
                  .label(
                    intl.formatMessage({
                      id: "banner.englishBtnText",
                      defaultMessage: "English button text",
                    })
                  )
                  .when(["arabicBtnText", "btnLink"], {
                    is: (arabicBtnText, btnLink) => arabicBtnText || btnLink,
                    then: (schema) => schema.required(),
                  })
                  .trim()
                  .hasNoArabic(
                    intl.formatMessage({
                      id: "error.arabicCharactersAreNotAllowedInThisField",
                      defaultMessage:
                        "Arabic characters are not allowed in this field",
                    })
                  ),
                arabicBtnText: Yup.string()
                  .label(
                    intl.formatMessage({
                      id: "banner.arabicBtnText",
                      defaultMessage: "Arabic button text",
                    })
                  )
                  .when(["englishBtnText", "btnLink"], {
                    is: (englishBtnText, btnLink) => englishBtnText || btnLink,
                    then: (schema) => schema.required(),
                  })
                  .trim()
                  .hasNoEnglish(
                    intl.formatMessage({
                      id: "error.englishCharactersAreNotAllowedInThisField",
                      defaultMessage:
                        "English characters are not allowed in this field",
                    })
                  ),
                btnLink: Yup.string()
                  .label(
                    intl.formatMessage({
                      id: "banner.btnLink",
                      defaultMessage: "Button link",
                    })
                  )
                  .when(["englishBtnText", "arabicBtnText"], {
                    is: (englishBtnText, arabicBtnText) =>
                      englishBtnText || arabicBtnText,
                    then: (schema) => schema.required(),
                  })
                  .trim(),
                homeBanner_en: Yup.mixed()
                  .label(
                    intl.formatMessage({
                      id: "banner.imageEn",
                      defaultMessage: "Image (English)",
                    })
                  )
                  .required(),
                homeBanner_ar: Yup.mixed()
                  .label(
                    intl.formatMessage({
                      id: "banner.imageAr",
                      defaultMessage: "Image (Arabic)",
                    })
                  )
                  .required(),
                  homeBanner_mobile_en: Yup.mixed()
                  .label(
                    intl.formatMessage({
                      id: "banner.mobileImageEn",
                      defaultMessage: "Image (English)",
                    })
                  )
                  .required(),
                homeBanner_mobile_ar: Yup.mixed()
                  .label(
                    intl.formatMessage({
                      id: "banner.mobileImageAr",
                      defaultMessage: "Image (Arabic)",
                    })
                  )
                  .required(),
              },
              [
                ["englishBtnText", "arabicBtnText"],
                ["englishBtnText", "btnLink"],
                ["arabicBtnText", "btnLink"],
              ]
            )}
            onSubmit={this.handleSubmit.bind(this)}
          >
            {(formikProps) => {
              const { isSubmitting, dirty, values } = formikProps;
              return (
                <Layout
                  noLayout={this.props.noLayout}
                  loading={this.state.loading}
                >
                  <HeaderTitle
                    id="menu.bannersTitle"
                    defaultMessage="Banners"
                  />
                  <div className="dashboard-table-container card-layout">
                    <div className="panel-primary">
                      <header className="panel-title">
                        {this.props.match.params.id && (
                          <FormattedMessage
                            id="banner.updateBanner"
                            defaultMessage="Update Banner"
                          />
                        )}
                        {!this.props.match.params.id && (
                          <FormattedMessage
                            id="banner.addBanner"
                            defaultMessage="Add Banner"
                          />
                        )}
                      </header>

                      <div className="home-banners">
                        <Form>
                          <div className="dashboard-row">
                            <FieldRow
                              name="englishTitle"
                              labelId="banner.englishTitle"
                              defaultLabel="Banner Title (English)"
                              placeholderId="banner.enterEnglishTitle"
                              defaultPlaceholder="Enter english title"
                              formikProps={formikProps}
                            />

                            <FieldRow
                              name="arabicTitle"
                              labelId="banner.arabicTitle"
                              defaultLabel="Banner Title (Arabic)"
                              placeholderId="banner.enterArabicTitle"
                              defaultPlaceholder="Enter arabic title"
                              formikProps={formikProps}
                            />

                            <FieldRow
                              name="englishDescription"
                              type="textarea"
                              labelId="banner.englishDescription"
                              defaultLabel="Description (English)"
                              placeholderId="banner.enterEnglishDescription"
                              defaultPlaceholder="Enter english description"
                              formikProps={formikProps}
                            />

                            <FieldRow
                              name="arabicDescription"
                              type="textarea"
                              labelId="banner.arabicDescription"
                              defaultLabel="Description (Arabic)"
                              placeholderId="banner.enterArabicDescription"
                              defaultPlaceholder="Enter arabic description"
                              formikProps={formikProps}
                            />

                            <FieldRow
                              name="englishBtnText"
                              labelId="banner.englishBtnText"
                              defaultLabel="Button Text (English)"
                              placeholderId="banner.enterenglishBtnText"
                              defaultPlaceholder="Enter english button text"
                              formikProps={formikProps}
                            />

                            <FieldRow
                              name="arabicBtnText"
                              labelId="banner.arabicBtnText"
                              defaultLabel="Button Text (Arabic)"
                              placeholderId="banner.enterArabicBtnText"
                              defaultPlaceholder="Enter arabic button text"
                              formikProps={formikProps}
                            />

                            <FieldRow
                              name="btnLink"
                              labelId="banner.BtnLink"
                              defaultLabel="Button Link"
                              placeholderId="banner.enterBtnLink"
                              defaultPlaceholder="Enter button link"
                              formikProps={formikProps}
                            />

                            <FieldRow
                              type="singleDate"
                              name="expiryDate"
                              labelId="banner.expiryDate"
                              initialExpirydate={values.expiryDate || ""}
                              defaultLabel="Expiry date"
                              placeholderId="banner.enterExpiryDate"
                              defaultPlaceholder="Enter Expiry Date"
                              formikProps={formikProps}
                            />

                            <FieldRow
                              name="homeBanner_en"
                              type="file"
                              image={true}
                              cameraIcon={true}
                              showThumbnail={true}
                              labelId="banner.imageEN"
                              defaultLabel="Image (English)"
                              placeholderId="banner.selectImage"
                              defaultPlaceholder="Select Image"
                              showRequiredFlag={true}
                              aspectRatio={1920 / 1080}
                              imageSize="W:1920 x H:1080"
                              formikProps={formikProps}
                              forCarousel={true}
                              // allowFiles="image/png, image/jpg, image/jpeg, image/bmp, image/webp"
                            />
                            <FieldRow
                              name="homeBanner_ar"
                              type="file"
                              image={true}
                              cameraIcon={true}
                              showThumbnail={true}
                              labelId="banner.imageAR"
                              defaultLabel="Image (Arabic)"
                              placeholderId="banner.selectImage"
                              defaultPlaceholder="Select Image"
                              showRequiredFlag={true}
                              aspectRatio={1920 / 1080}
                              imageSize="W:1920 x H:1080"
                              formikProps={formikProps}
                              forCarousel={true}
                            />
                            <FieldRow
                              name="homeBanner_mobile_en"
                              type="file"
                              image={true}
                              cameraIcon={true}
                              showThumbnail={true}
                              labelId="banner.mobileImageEN"
                              defaultLabel="Mobile Image (English)"
                              placeholderId="banner.selectImage"
                              defaultPlaceholder="Select Image"
                              showRequiredFlag={true}
                              aspectRatio={500 / 471}
                              imageSize="W:500 x H:471"
                              formikProps={formikProps}
                              forCarousel={true}
                            />
                            <FieldRow
                              name="homeBanner_mobile_ar"
                              type="file"
                              image={true}
                              cameraIcon={true}
                              showThumbnail={true}
                              labelId="banner.mobileImageAR"
                              defaultLabel="Mobile Image (Arabic)"
                              placeholderId="banner.selectImage"
                              defaultPlaceholder="Select Image"
                              showRequiredFlag={true}
                              aspectRatio={500 / 471}
                              imageSize="W:500 x H:471"
                              formikProps={formikProps}
                              forCarousel={true}
                            />

                            <div
                              className="col-md-2 col-xs-12"
                              style={{ paddingLeft: "0" }}
                            >
                              <label
                                className={classNames("form-label caps block", {
                                  "require-flag": false,
                                })}
                              >
                                <FormattedMessage
                                  id="banner.isActive"
                                  defaultMessage="Is Active"
                                />
                              </label>
                            </div>
                            <FieldRow
                              name="isActive"
                              type="checkbox"
                              formikProps={formikProps}
                            />
                          </div>
                          <div className="dashboard-row -nobottom">
                            {this.props.authContext.authorized && (
                              <input
                                type="submit"
                                className="btn btn-primary"
                                style={{ marginBottom: "0" }}
                                value={this.props.intl.formatMessage({
                                  id: "global.submit",
                                  defaultMessage: "Submit",
                                })}
                                disabled={isSubmitting || !dirty}
                              />
                            )}
                            <Button
                              variant="link"
                              onClick={() => this.props.navigate("/banners")}
                              disabled={isSubmitting}
                            >
                              {this.props.intl.formatMessage({
                                id: "global.cancel",
                                defaultMessage: "Cancel",
                              })}
                            </Button>
                          </div>
                        </Form>
                      </div>
                    </div>
                  </div>
                </Layout>
              );
            }}
          </Formik>
        </Auth>
      </>
    );
  }
}

export default withAuthContext(withRouter(injectIntl(HomeBannerForm)));
