import React, { useEffect, useState } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import Layout from "../shared/Layout";
import services from "../../services";
import { NotificationManager } from "react-notifications";
import _ from "lodash";
import withRouter from "../../util/withRouter";
import appState from "../../state/AppStateContainer";
import moment from "moment";
import { Button, Card as Well } from "react-bootstrap";
import Items from "./ItemDetails";
import AdjustmentDetails from "./AdjustmentDetails";
import ReasonModal from "../shared/ReasonModal";
import { FormattedNumber } from "react-intl";
import CheckAccess from "../shared/CheckAccess";
import { ActivitiesEnum } from "../../staticData/Activities";
import HeaderTitle from "../shared/HeaderTitle";
import PasswordVerificationModal from "../shared/passwordVerificationModal";
import CompleteOrder from "../../components/completeOrder";
import { handleErrors } from "../../services/services";
import OtpModal from "../shared/OtpModal";
import ConfirmationModal from "../shared/ConfirmationModal";
import ServiceAdvisorListing from "./ServiceAdvisorListing";
import {
  generateInvoice,
  getOrderCompletedStatus,
  getOrderRefundItemKey,
  toShowResendInvoiceButton,
} from "../../util/orderUtil";
import errorMessage from "../../util/errorMessage";
import { cnd_order_status } from "../../util/utils";

function CarCard({ car, onClick, cursorFlag, style }) {
  return (
    <div
      key={car._id}
      style={{
        ...style,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        flexDirection: "row",
        padding: "10px",
        marginBottom: "5px",
        cursor: !cursorFlag ? "pointer" : undefined,
      }}
      className="card"
    >
      <div
        className="row condensed flexible"
        style={{
          fontSize: ".8em",
        }}
      >
        <div className="col-md-4">
          <img
            src="/design/images/car.jpg"
            width="90"
            className="img-thumbnail img-responsive"
          />
        </div>
        <div className="col-md-8">
          <div>
            <strong>
              <FormattedMessage id="car.brand" defaultMessage="Brand" />
            </strong>{" "}
            {car?.brand?.["value_" + appState.state.language]}
          </div>
          <div>
            <strong>
              <FormattedMessage id="car.product" defaultMessage="Product" />
            </strong>{" "}
            {car?.product?.["value_" + appState.state.language]}
          </div>
          <div>
            <strong>
              <FormattedMessage id="car.year" defaultMessage="Model year" />
            </strong>{" "}
            {car.modelYear}
          </div>
          <div>
            <strong>
              <FormattedMessage id="car.grade" defaultMessage="Grade" />
            </strong>{" "}
            {car.grade}
          </div>
        </div>
      </div>
    </div>
  );
}

const OrderDetails = ({ intl, match, navigate }) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [editingBooking, setEditingBooking] = useState(false);
  const [verifyPassword, setVerifyPassword] = useState(null);
  const [userVerified, setUserVerified] = useState(false);
  const [doShowOrderCompleteModal, setDoShowOrderCompleteModal] =
    useState(false);
  const [orderIdText, setOrderIdText] = useState("");
  const [selectedOrderId, setSelectedOrderId] = useState("");
  const [doShowOtpModal, setDoShowOtpModal] = useState(false);
  const [showOdoMeterModal, setShowOdoMeterModal] = useState(false);
  const [verifyOrderOtpText, setVerifyOrderOtpText] = useState("");
  const [odometer, setOdometer] = useState("");
  const [fetchData, setFetchData] = useState(false);
  const [show, setShow] = useState(false);
  const [previousOtp, setPreviousOtp] = useState("");
  const [showError, setShowError] = useState(false);
  const [isCndOrder, setIsCndOrder] = useState(false);
  const [completionKey, setCompletionKey] = useState("");
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [serviceAdvisor, setServiceAdvisor] = useState([]);
  const [isShowServiceAdvisor, setIsShowServiceAdvisor] = useState(false);
  const [upsellServiceAction, setUpsellServiceAction] = useState("");
  const [showNextStep, setShowNextStep] = useState(false);

  const lang = appState.state.language;

  useEffect(() => {
    getOrderDetail();
  }, [verifyPassword, fetchData]);

  useEffect(() => {
    getAllServiceAdvisorAgainstBranch();
  }, [data?._id]);

  async function getOrderDetail() {
    try {
      const options = { progressBar: true };
      setLoading(true);
      const orderDetails = await services.getOrder(match.params.id, options);
      setData(orderDetails);
      setLoading(false);
      setFetchData(false);
    } catch (e) {
      navigate("/error", {
        error: _.get(e, "response.data.error", "error.unexpectedError"),
      });
    } finally {
      setLoading(false);
    }
  }
  function showCompleteOrderBtn() {
    return (
      data?.paid &&
      !data?.isDeleted &&
      data?.orderStatus === "pending" &&
      appState?.state?.token &&
      appState.isPetrominShop() &&
      !data?.customerDetail?.isDeleted
    );
  }

  async function handleSubmitRefundReason(payload) {
    try {
      setLoading(true);
      await services.refundOrderWithReason({
        id: match.params.id,
        reason: payload?.reason,
      });
      getOrderDetail();
      setUserVerified(false);

      setLoading(true);

      NotificationManager.success(
        intl.formatMessage({
          id: "global.operationSuccessful",
          defaultMessage: "Operation Successful",
        }),
        intl.formatMessage({
          id: "global.success",
          defaultMessage: "Success",
        })
      );
    } catch (e) {
      setShowNextStep(false);
      NotificationManager.error(
        intl.formatMessage({
          id: _.get(e, "response.data.error", "error.unexpectedError"),
          defaultMessage: "An unexpected error has occurred",
        }),
        intl.formatMessage({
          id: "global.error",
          defaultMessage: "Error",
        })
      );
    } finally {
      setLoading(false);
    }
  }

  async function verificationHandler(values) {
    try {
      await services.verifyPassword(values);
      NotificationManager.success(
        intl.formatMessage({
          id: "global.verificationsuccessful",
          defaultMessage: "Verification Successful",
        }),
        intl.formatMessage({
          id: "global.success",
          defaultMessage: "Success",
        })
      );

      await services.refundOrder(data?._id);
      setVerifyPassword(null);

      NotificationManager.success(
        intl.formatMessage({
          id: "order.refundSuccess",
          defaultMessage: "Order Refunded Successfully",
        }),
        intl.formatMessage({
          id: "global.success",
          defaultMessage: "Success",
        })
      );
      // setUserVerified(true);
    } catch (e) {
      NotificationManager.error(
        intl.formatMessage({
          id: _.get(e, "response.data.error", "error.unexpectedError"),
          defaultMessage: "An unexpected error has occurred",
        }),
        intl.formatMessage({
          id: "global.error",
          defaultMessage: "Error",
        })
      );
      setVerifyPassword(null);
      setUserVerified(false);
    }
  }
  function showCompleteOrderModal(id) {
    setSelectedOrderId(id);
    setDoShowOrderCompleteModal(true);
  }

  async function submitOrderId(id) {
    if (!orderIdText) return;
    setLoading(true);
    try {
      const response = await services.scanOrder({
        orderHash: orderIdText.toLowerCase(),
        showError: "false",
      });

      if (response._id) {
        setDoShowOrderCompleteModal(false);
        setDoShowOtpModal(true);
        successMessage();
      }
    } catch (err) {
      showErr(err);
    } finally {
      setLoading(false);
    }
  }

  const successMessage = () =>
    NotificationManager.success(
      intl.formatMessage({
        id: "global.operationSuccessful",
        defaultMessage: "Operation Successful",
      }),
      intl.formatMessage({
        id: "global.success",
        defaultMessage: "Success",
      })
    );

  const showErr = (err) => {
    let error = false;
    if (err && !_.isEmpty(err?.response?.data.error)) {
      error = err?.response?.data.error;
    } else {
      error = _.get(
        err?.response?.data,
        "response.data.error",
        "error.unexpectedError"
      );
    }

    const errorMessage = handleErrors(error);

    NotificationManager.error(
      intl.formatMessage({
        id: errorMessage.id,
        defaultMessage:
          errorMessage.defaultMessage?.["value_" + lang] ||
          errorMessage.defaultMessage,
      }),
      intl.formatMessage({
        id: "global.error",
        defaultMessage: "Error",
      })
    );
  };

  async function verifyOrderOtp() {
    if (!orderIdText || !verifyOrderOtpText) return;
    setLoading(true);
    try {
      await services.verifyOrderOtp({
        orderHash: orderIdText.toLowerCase(),
        otp: verifyOrderOtpText,
      });

      setDoShowOtpModal(false);
      setShowOdoMeterModal(true);
      successMessage();
    } catch (err) {
      showErr(err);
    } finally {
      setLoading(false);
    }
  }

  async function completeOrder() {
    if (!orderIdText) return;
    setLoading(true);
    try {
      await services.completeOrder({ orderIdText, odometer });
      setDoShowOtpModal(false);
      setShowOdoMeterModal(false);
      successMessage();
      setTimeout(() => window.location.reload(), 500);
    } catch (err) {
      showErr(err);
    } finally {
      setLoading(false);
    }
  }

  function closeModal(key) {
    // setState({
    //   [key]: false,
    //   orderIdText: "",
    //   verifyOrderOtpText: "",
    //   odometer: "",
    // });
    setOrderIdText("");
    setVerifyOrderOtpText("");
    setOdometer("");
  }

  const handleVerifyUserSubmit = async (values, from = undefined) => {
    if (loading) return;
    try {
      setLoading(true);
      if (from !== "serviceAction") {
        if (
          data?.variantCompletedStatus !== "pending" &&
          data?.isDeleted === false
        )
          return;
      }
      let code;
      if (completionKey === "variant") {
        setShow(true);
        code =
          values.code1.toString() +
          values.code2.toString() +
          values.code3.toString() +
          values.code4.toString();

        if (code === previousOtp) return setShowError(true);

        setPreviousOtp(code);
      }

      const order = await services.verifyOtpForOrderCompletion({
        id: data?._id,
        phoneNumber: data?.phoneNumber,
        code,
        completionKey,
        upsellServiceAction,
        isCndOrder,
      });

      if (order && order?.message === "order.orderCompletedSuccessfully") {
        setShow(false);
        setShowConfirmationModal(false);
        NotificationManager.success(
          intl.formatMessage({
            id: "global.orderCompletedSuccessfully",
            defaultMessage: "Order Completed Successfully",
          }),
          intl.formatMessage({
            id: "global.success",
            defaultMessage: "Success",
          })
        );
        setFetchData(true);
      }
    } catch (err) {
      setShowError(true);
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const completeService = () =>
    handleVerifyUserSubmit(undefined, "serviceAction");

  const sendOtpForOrderCompletion = async (data, type) => {
    if (loading) return;
    try {
      setLoading(true);
      const response = await services.sendSmsToUser(
        data?._id,
        data?.phoneNumber
      );

      if (response?.message === "auth.otpSentSuccessfully") {
        setShow(true);
        setCompletionKey(type);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const closeConfirmationModal = () => setShowConfirmationModal(false);

  const getAllServiceAdvisorAgainstBranch = async () => {
    if (!data?.branchId?._id) return;
    try {
      const response = await services.getAllServiceAdvisor(data?.branchId?._id);
      setServiceAdvisor(response);
    } catch (e) {
      NotificationManager.error(
        intl.formatMessage({
          id: _.get(e, "response.data.error", "error.unexpectedError"),
          defaultMessage: "An unexpected error has occurred",
        }),
        intl.formatMessage({
          id: "global.error",
          defaultMessage: "Error",
        })
      );
    }
  };

  const handleAssignServiceAdvisor = async (values) => {
    try {
      const response = await services.assignServiceAdvisor({
        id: data?._id,
        serviceAdvisor: values?.serviceAdvisor,
      });

      if (response.message === "success.orderUpdatedSuccessfully") {
        NotificationManager.success(
          intl.formatMessage({
            id: "global.operationSuccessful",
            defaultMessage: "Operation Successful",
          }),
          intl.formatMessage({
            id: "global.success",
            defaultMessage: "Success",
          })
        );
        setFetchData(true);
      }
    } catch (e) {
      NotificationManager.error(
        intl.formatMessage({
          id: _.get(e, "response.data.error", "error.unexpectedError"),
          defaultMessage: "An unexpected error has occurred",
        }),
        intl.formatMessage({
          id: "global.error",
          defaultMessage: "Error",
        })
      );
    }
  };

  const handleInvoiceDownload = async (id, deposit, type, refund) => {
    setLoading(true);
    await generateInvoice({ id, deposit, type, refund });
    setLoading(false);
  };

  const handleResendInvoiceToZatca = async ({
    orderId,
    orderType,
    isDeposit = false,
  }) => {
    try {
      setLoading(true);
      const response = await services.resendInvoiceToZatca({
        orderId,
        orderType,
        isDeposit,
        isRefund: true,
      });

      setLoading(false);
      NotificationManager.success(
        intl.formatMessage({
          id: "global.operationSuccessful",
          defaultMessage: response,
        }),
        intl.formatMessage({
          id: "global.success",
          defaultMessage: "Success",
        })
      );
      getOrderDetail();
    } catch (err) {
      setLoading(false);
      console.log(err);
      NotificationManager.error(
        intl.formatMessage({
          id: err?.response?.data?.response,
          defaultMessage: "An unexpected error has occurred",
        }),
        intl.formatMessage({
          id: "global.error",
          defaultMessage: "Error",
        })
      );
    }
  };

  const handleResendInvoice = () => {
    handleResendInvoiceToZatca({
      orderId: data?._id,
      orderType: getOrderRefundItemKey(data),
      isDeposit: false,
    });
  };

  const handleSendNotificationToUser = async (isCadDelivery) => {
    setLoading(true);
    try {
      if (!isCadDelivery && data?.isReceivedCarSmsSent) return;

      await services.sendCadNotificationToCustomer({
        id: data?._id,
        isCadDelivery,
      });
      NotificationManager.success(
        intl.formatMessage({
          id: "global.operationSuccessful",
          defaultMessage: "Operation Successful",
        }),
        intl.formatMessage({
          id: "global.success",
          defaultMessage: "Success",
        })
      );
      await getOrderDetail();
    } catch (e) {
      NotificationManager.error(
        intl.formatMessage({
          id:
            errorMessage?.Error[e?.response?.data?.key]?.key ||
            "error.unexpectedError",
          defaultMessage:
            errorMessage?.Error[e?.response?.data?.key]?.value ||
            "An unexpected error has occurred",
        }),
        intl.formatMessage({
          id: "global.error",
          defaultMessage: "Error",
        })
      );
    } finally {
      setLoading(false);
    }
  };

  const handleChangeCndOrderStatus = async (status) => {
    setLoading(true);
    try {
      await services.changeCndOrderStatus({
        id: data?._id,
        status,
      });
      NotificationManager.success(
        intl.formatMessage({
          id: "global.operationSuccessful",
          defaultMessage: "Operation Successful",
        }),
        intl.formatMessage({
          id: "global.success",
          defaultMessage: "Success",
        })
      );
      await getOrderDetail();
    } catch (e) {
      NotificationManager.error(
        intl.formatMessage({
          id: e?.response?.data?.response,
          defaultMessage: "An unexpected error has occurred",
        }),
        intl.formatMessage({
          id: "global.error",
          defaultMessage: "Error",
        })
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout loading={loading}>
      <ReasonModal
        {...{
          showNextStep,
          setShowNextStep,
          show: userVerified !== false,
          onSubmit: (values) => handleSubmitRefundReason(values),
          onCancel: () => setUserVerified(false),
          loading,
        }}
      />

      <PasswordVerificationModal
        onCancel={() => {
          setVerifyPassword(null);
        }}
        onSubmit={(values) => {
          verificationHandler(values);
        }}
        show={verifyPassword !== null}
        add={true}
      />

      <OtpModal
        {...{
          show,
          setShow,
          intl,
          handleVerifyUserSubmit,
          showError,
          setShowError,
          loading,
        }}
      />

      <ConfirmationModal
        {...{
          show: showConfirmationModal,
          id: "gloabl.confirmationText",
          defaultMessage: "Are you sure you want to continue",
          onCancel: closeConfirmationModal,
          onSubmit: completeService,
          loading,
        }}
      />

      <div className="dashboard-table-container card-layout">
        <div className="panel-primary">
          <HeaderTitle id="order.orderDetails" defaultMessage="Order Details" />
          <div>
            {/* {data?.shop && ( */}
            {data.isPaid === false && (
              <h5 className="unpaid-order-tag">
                <FormattedMessage
                  id="order.unsuccessfulPaid"
                  defaultMessage="This order was not paid successfully"
                />
              </h5>
            )}

            <div className="dashboard-row label-row">
              <div>
                <strong>
                  <FormattedMessage
                    id="service.orderId"
                    defaultMessage="Order Id"
                  />
                  :{" "}
                </strong>{" "}
                {data?.orderIdHash}
              </div>
              <div>
                <strong>
                  <FormattedMessage
                    id="order.appointmentReferenceId"
                    defaultMessage="Appointment Reference Id"
                  />
                  :{" "}
                </strong>{" "}
                {data?.appointmentHash || "N/A"}
              </div>
              <div>
                <strong>
                  <FormattedMessage
                    id="service.voucherId"
                    defaultMessage="Voucher Id"
                  />
                  :{" "}
                </strong>{" "}
                {data?.orderId}
              </div>
              <div>
                <strong>
                  <FormattedMessage
                    id="service.plateId"
                    defaultMessage="Plate Id"
                  />
                  :{" "}
                </strong>{" "}
                {data?.carNumberPlate}
              </div>

              <div>
                <strong>
                  <FormattedMessage
                    id="service.orderStatus"
                    defaultMessage="Order Status"
                  />
                  :{" "}
                </strong>{" "}
                {data?.isRefunded
                  ? "Refunded"
                  : data?.orderStatus == "pending" && data?.isDeleted
                  ? "Cancelled"
                  : data?.orderStatus ||
                    cnd_order_status[data.cndStatus] ||
                    "N/A"}
              </div>
              <div>
                <strong>
                  <FormattedMessage
                    id="order.branch"
                    defaultMessage="Branch Name"
                  />
                  :{" "}
                </strong>{" "}
                {data?.branchId?.name?.["value_" + lang] || ""}
              </div>

              <div>
                <strong>
                  <FormattedMessage
                    id="service.paymentMethod"
                    defaultMessage="Payment Method"
                  />
                  :{" "}
                </strong>{" "}
                {(data?.paymentMethod === "creditCard" && data?.applePay && (
                  <FormattedMessage
                    id="order.onlineApplePay"
                    defaultMessage="Online (Apple Pay)"
                  />
                )) ||
                  (data?.paymentMethod === "creditCard" && !data?.applePay && (
                    <FormattedMessage
                      id="order.onlineHyperPay"
                      defaultMessage="Online (Hyper Pay)"
                    />
                  )) ||
                  (data?.paymentMethod === "span" && (
                    <FormattedMessage id="order.span" defaultMessage="SPAN" />
                  )) ||
                  data?.paymentMethod}
              </div>

              <div>
                <strong>
                  <FormattedMessage
                    id="order.paymentBrand"
                    defaultMessage="Payment Brand"
                  />
                  :{" "}
                </strong>{" "}
                <span>{data?.paymentBrand}</span>
              </div>

              <div>
                <strong>
                  <FormattedMessage
                    id="order.customerName"
                    defaultMessage="Customer Name"
                  />
                  :{" "}
                </strong>{" "}
                <span>
                  {data?.username?.toLowerCase() === "null null"
                    ? "N/A"
                    : data?.username}
                </span>
              </div>
              <div>
                <strong>
                  <FormattedMessage
                    id="order.customerNumber"
                    defaultMessage="Custom Number"
                  />
                  :{" "}
                </strong>{" "}
                <span>{data?.phoneNumber}</span>
              </div>
              <div>
                <strong>
                  <FormattedMessage
                    id="order.variantItem"
                    defaultMessage="No Of Variants"
                  />
                  :{" "}
                </strong>{" "}
                <span>{data?.variantItems?.length}</span>
              </div>
              <div>
                <strong>
                  <FormattedMessage
                    id="order.offerItems"
                    defaultMessage="No Of Offer Items"
                  />
                  :{" "}
                </strong>{" "}
                <span>{data?.offerItems?.length}</span>
              </div>

              {/* promo fields */}
              <div>
                <strong>
                  <FormattedMessage
                    id="order.priceBeforeDiscount"
                    defaultMessage="Price Before Discount"
                  />
                  :{" "}
                </strong>{" "}
                <FormattedNumber
                  style="decimal"
                  maximumFractionDigits={2}
                  minimumFractionDigits={2}
                  value={
                    data?.orderDetermination?.priceBeforePromoDiscount || 0
                  }
                />
              </div>

              {data?.promoCode ? (
                <div>
                  <strong>
                    <FormattedMessage
                      id="order.promoName"
                      defaultMessage="Promo Name"
                    />
                    :{" "}
                  </strong>{" "}
                  <span>{data?.promoCode}</span>
                </div>
              ) : null}

              {data?.orderDetermination?.promoDiscount ? (
                <div>
                  <strong>
                    <FormattedMessage
                      id="order.promoDiscount"
                      defaultMessage="Promo Discount"
                    />
                    :{" "}
                  </strong>{" "}
                  <span>{data?.orderDetermination?.promoDiscount}</span>
                </div>
              ) : null}

              <div>
                <strong>
                  <FormattedMessage
                    id="order.totalAmount"
                    defaultMessage="Total Amount"
                  />
                  :{" "}
                </strong>{" "}
                <FormattedNumber
                  style="decimal"
                  maximumFractionDigits={2}
                  minimumFractionDigits={2}
                  // value={data?.totalPrice || 0}
                  value={data?.orderDetermination?.priceAfterPromoDiscount || 0}
                />
              </div>

              <div>
                <strong>
                  <FormattedMessage
                    id="order.orderPlacementTime"
                    defaultMessage="Order placement time"
                  />
                  :
                </strong>
                <span>{moment(data?.createdAt).format("lll")}</span>
              </div>

              {data?.fastCollect?.carCollectionAt?.date ? (
                <div>
                  <strong>
                    <FormattedMessage
                      id="order.cadCarDeliverySchedule"
                      defaultMessage="CAD car delivery scheduled by customer"
                    />
                    :
                  </strong>
                  <span>
                    {moment(data?.fastCollect?.carCollectionAt?.date).format(
                      "ll"
                    ) +
                      " " +
                      data?.fastCollect?.carCollectionAt?.slot}
                  </span>
                </div>
              ) : null}

              {data?.cadCarReceivedAt ? (
                <div>
                  <strong>
                    <FormattedMessage
                      id="order.cadCarReceivedAt"
                      defaultMessage="CAD car received at"
                    />
                    :
                  </strong>
                  <span>{moment(data?.cadCarReceivedAt).format("lll")}</span>
                </div>
              ) : null}

              {data?.cadCarDeliveredAt ? (
                <div>
                  <strong>
                    <FormattedMessage
                      id="order.cadCarDeliveredAt"
                      defaultMessage="CAD car delivered at"
                    />
                    :{" "}
                  </strong>{" "}
                  <span>{moment(data?.cadCarDeliveredAt).format("lll")}</span>
                </div>
              ) : null}

              {data?.offerItems?.length ? (
                <div>
                  <strong>
                    <FormattedMessage
                      id="order.offerDepositReceipt"
                      defaultMessage="Offer Deposit Receipt"
                    />
                    :{" "}
                  </strong>{" "}
                  <span className="download-icon">
                    <i
                      className="fa fa-download"
                      onClick={() =>
                        handleInvoiceDownload(data?._id, true, "offer")
                      }
                    ></i>
                  </span>
                </div>
              ) : null}

              {data?.subscriptionItems?.length ? (
                <div>
                  <strong>
                    <FormattedMessage
                      id="order.subscriptionDepositReceipt"
                      defaultMessage="Subscription Deposit Receipt"
                    />
                    :{" "}
                  </strong>{" "}
                  <span className="download-icon">
                    <i
                      className="fa fa-download"
                      onClick={() =>
                        handleInvoiceDownload(data?._id, true, "subscription")
                      }
                    ></i>
                  </span>
                </div>
              ) : null}

              {data?.variantItems?.length > 0 ? (
                <div>
                  <strong>
                    <FormattedMessage
                      id="order.productDepositReceipt"
                      defaultMessage="Product Deposit Receipt"
                    />
                    :{" "}
                  </strong>{" "}
                  <span className="download-icon">
                    <i
                      className="fa fa-download"
                      onClick={() =>
                        handleInvoiceDownload(data?._id, true, "variant")
                      }
                    ></i>
                  </span>
                </div>
              ) : null}

              {data?.offerCompletedBy ? (
                <div>
                  <strong>
                    <FormattedMessage
                      id="order.offerFinalInvoice"
                      defaultMessage="Final Invoice (Offer)"
                    />
                    :{" "}
                  </strong>{" "}
                  <span className="download-icon">
                    <i
                      className="fa fa-download"
                      onClick={() =>
                        handleInvoiceDownload(data?._id, false, "offer")
                      }
                    ></i>
                  </span>
                </div>
              ) : null}

              {data?.subscriptionCompletedBy ? (
                <div>
                  <strong>
                    <FormattedMessage
                      id="order.subscriptionFinalInvoice"
                      defaultMessage="Final Invoice (Subscription)"
                    />
                    :{" "}
                  </strong>{" "}
                  <span className="download-icon">
                    <i
                      className="fa fa-download"
                      onClick={() =>
                        handleInvoiceDownload(data?._id, false, "subscription")
                      }
                    ></i>
                  </span>
                </div>
              ) : null}

              {data?.variantItems?.length && data?.variantDeliveredBy ? (
                <div>
                  <strong>
                    <FormattedMessage
                      id="order.productFinalInvoice"
                      defaultMessage="Final Invoice (Product)"
                    />
                    :{" "}
                  </strong>{" "}
                  <span className="download-icon">
                    <i
                      className="fa fa-download"
                      onClick={() =>
                        handleInvoiceDownload(data?._id, false, "variant")
                      }
                    ></i>
                  </span>
                </div>
              ) : null}
              {data?.variantItems?.length && data?.isRefunded ? (
                <div>
                  <strong>
                    <FormattedMessage
                      id="order.productRefundInvoice"
                      defaultMessage="Refund Invoice (Product)"
                    />
                    :{" "}
                  </strong>{" "}
                  <span className="download-icon">
                    <i
                      className="fa fa-download"
                      onClick={() =>
                        handleInvoiceDownload(data?._id, true, "variant", true)
                      }
                    ></i>
                  </span>
                </div>
              ) : null}
              {data?.offerItems?.length && data?.isRefunded ? (
                <div>
                  <strong>
                    <FormattedMessage
                      id="order.offerRefundInvoice"
                      defaultMessage="Refund Invoice (Offer)"
                    />
                    :{" "}
                  </strong>{" "}
                  <span className="download-icon">
                    <i
                      className="fa fa-download"
                      onClick={() =>
                        handleInvoiceDownload(data?._id, true, "offer", true)
                      }
                    ></i>
                  </span>
                </div>
              ) : null}

              {/* For subscription order */}
              {data.subscriptionId && (
                <>
                  <div>
                    <strong>
                      <FormattedMessage
                        id="order.packageId"
                        defaultMessage="Subscription Id"
                      />
                    </strong>
                    <span>{data.subscribedSubscriptionId?.hashId}</span>
                  </div>
                  <div>
                    <strong>
                      <FormattedMessage
                        id="order.totalQuota"
                        defaultMessage="Total Quota"
                      />
                    </strong>
                    <span>{data.subscribedSubscriptionId?.quota?.total}</span>
                  </div>
                  <div>
                    <strong>
                      <FormattedMessage
                        id="order.bookedQuota"
                        defaultMessage="Booked"
                      />
                    </strong>
                    <span>{data.subscribedSubscriptionId?.quota?.consume}</span>
                  </div>
                  <div style={{ flex: "20% 0" }}>
                    <strong>
                      <FormattedMessage
                        id="order.leftQuota"
                        defaultMessage="Remaining"
                      />
                    </strong>
                    <span>{data.subscribedSubscriptionId?.quota?.left}</span>
                  </div>
                </>
              )}
              {/* For subscription order */}
              <CheckAccess activityId={ActivitiesEnum.orders_refund}>
                {data &&
                  !data?.subscriptionId &&
                  getOrderCompletedStatus(data) && (
                    <div className="d-block refund-resend-btn">
                      {data?.appointmentId?.collectionAndDelivery
                        ?.collectionAddress ? (
                        data?.appointmentId?.canRescheduleCAD &&
                        !data?.isReceivedCarSmsSent &&
                        data?.cndStatus !== "pickedFromCustomer" && (
                          <input
                            type="button"
                            disabled={data.isRefunded}
                            onClick={() => {
                              if (!data.isRefunded) setUserVerified(true);
                            }}
                            className="btn btn-primary refund-btn"
                            value={intl.formatMessage({
                              id: "order.refundAction",
                              defaultMessage: "Refund Action",
                            })}
                          />
                        )
                      ) : (
                        <input
                          type="button"
                          disabled={data.isRefunded}
                          onClick={() => {
                            if (!data.isRefunded) setUserVerified(true);
                          }}
                          className="btn btn-primary refund-btn"
                          value={intl.formatMessage({
                            id: "order.refundAction",
                            defaultMessage: "Refund Action",
                          })}
                        />
                      )}
                      {data.isRefunded && toShowResendInvoiceButton(data) && (
                        <input
                          type="button"
                          className="btn btn-primary resend-invoice"
                          value={intl.formatMessage({
                            id: "global.resend",
                            defaultMessage: "Resend Invoice",
                          })}
                          onClick={handleResendInvoice}
                        />
                      )}
                    </div>
                  )}
              </CheckAccess>

              {data?.booking && (
                <React.Fragment>
                  <div>
                    <strong>
                      <FormattedMessage
                        id="order.bookingStartTime"
                        defaultMessage="Booking Start"
                      />
                      :{" "}
                    </strong>{" "}
                    <span>{moment(data?.booking.start).format("lll")}</span>
                  </div>
                  <div>
                    <strong>
                      <FormattedMessage
                        id="order.bookingEndTime"
                        defaultMessage="Booking End"
                      />
                      :{" "}
                    </strong>{" "}
                    <span>{moment(data?.booking.end).format("lll")}</span>
                  </div>
                  {appState?.state?.token?.role === "sadmin" &&
                    data?.customerOrderOtp && (
                      <div>
                        <strong>
                          <FormattedMessage
                            id="order.customerOrderOtp"
                            defaultMessage="Order Otp"
                          />
                          :{" "}
                        </strong>{" "}
                        <span>{data?.customerOrderOtp}</span>
                      </div>
                    )}
                  {appState.state.token?.role === "sadmin" && (
                    <CheckAccess
                      activityId={ActivitiesEnum.orders__edit_booking}
                    >
                      <div>
                        <strong>
                          <FormattedMessage
                            id="global.editBooking"
                            defaultMessage="Edit Booking"
                          />
                          :{" "}
                        </strong>{" "}
                        <span>
                          <a
                            style={{ cursor: "pointer" }}
                            className="btn-primary"
                            onClick={() => {
                              setEditingBooking(true);
                            }}
                          >
                            <i className="fa fa-pencil" />{" "}
                          </a>
                        </span>
                      </div>
                    </CheckAccess>
                  )}
                </React.Fragment>
              )}
              {showCompleteOrderBtn() && (
                <div>
                  <input
                    className="btn btn-primary"
                    value={intl.formatMessage({
                      id: "order.completeOrder",
                      defaultMessage: "Complete Order",
                    })}
                    onClick={() => showCompleteOrderModal()}
                    type="button"
                  />
                </div>
              )}
            </div>

            <br />

            {data?.isDeleted && data?.cancellationReason && (
              <div className="dashboard-row label-row">
                <div className="col-md-12 col-xs-12 col-lg-12">
                  <strong>
                    <span>
                      {intl.formatMessage({
                        id: "order.cancellationReaason",
                        defaultMessage: "Cancellation Reason",
                      })}{" "}
                    </span>
                  </strong>
                  <div className="col-md-12 col-xs-12" style={{ top: 6 }}>
                    {data?.cancellationReason?.["value_" + lang]}
                  </div>
                </div>
              </div>
            )}

            <CheckAccess activityId={ActivitiesEnum.display_products}>
              {data?.variantItems?.length ? (
                <div>
                  {!data?.isRefunded && (
                    <CheckAccess
                      activityId={ActivitiesEnum.order_mark_as_delivered}
                    >
                      <div className="order-completion-action-wrapper">
                        <strong>
                          <FormattedMessage
                            id="order.action"
                            defaultMessage="Action:"
                          />
                        </strong>
                        <div>
                          {data?.variantCompletedStatus === "completed" ? (
                            <span className="orderCompleted">
                              <FormattedMessage
                                id="order.delivered"
                                defaultMessage="Delivered"
                              />
                            </span>
                          ) : (
                            <span
                              className="orderPending"
                              onClick={() => {
                                sendOtpForOrderCompletion(data, "variant");
                                setUpsellServiceAction("");
                              }}
                            >
                              <FormattedMessage
                                id="order.markAsDelivered"
                                defaultMessage="Mark As Delivered"
                              />
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="completed-by">
                        <strong>
                          <FormattedMessage
                            id="order.deliveredBy"
                            defaultMessage="Delivered By"
                          />
                          :
                        </strong>
                        <span>
                          {data["variantDeliveredBy"]
                            ? `${data["variantDeliveredBy"]?.fullName} (${data["variantDeliveredBy"]?.role})`
                            : "N/A"}
                        </span>
                      </div>
                    </CheckAccess>
                  )}

                  <div className="dashboard-row label-row">
                    <div className="col-md-12 col-xs-12 mb-0">
                      <strong>
                        <span>
                          {intl.formatMessage({
                            id: "order.variantItems",
                            defaultMessage: "Variant Items:",
                          })}
                        </span>
                      </strong>
                    </div>
                    <div className="col-md-12 col-xs-12">
                      <Well bsSize="small">
                        <Items
                          itemDetails={data?.variantItems}
                          keyName="variantId"
                        />
                      </Well>
                    </div>
                  </div>
                </div>
              ) : null}
            </CheckAccess>

            <CheckAccess activityId={ActivitiesEnum.display_offers}>
              {data?.offerItems?.length ? (
                <div>
                  {/* <CheckAccess
                      activityId={ActivitiesEnum.order_mark_as_delivered}
                    > */}
                  <strong>
                    <FormattedMessage
                      id="order.action"
                      defaultMessage="Action:"
                    />
                  </strong>
                  {data?.orderType === "upsell" && data?.offerItems?.length ? (
                    <>
                      <CheckAccess
                        activityId={ActivitiesEnum.upsell_service_assign}
                      >
                        <div className="branch-service-advisor">
                          <div className="service-advisor-btn-wrapper">
                            <input
                              type="button"
                              onClick={() => setIsShowServiceAdvisor(true)}
                              value={intl.formatMessage({
                                id: "order.assignServiceAdvisor",
                                defaultMessage: "Assign Service Advisor",
                              })}
                              className={`${
                                data?.serviceAdvisor
                                  ? "disabled-service-advisor-btn"
                                  : ""
                              }`}
                              disabled={data?.serviceAdvisor ? true : false}
                            />
                          </div>
                          {isShowServiceAdvisor || data?.serviceAdvisor ? (
                            <div className="service-advisor-form">
                              <ServiceAdvisorListing
                                {...{
                                  intl,
                                  handleAssignServiceAdvisor,
                                  serviceAdvisor,
                                  selectedSA: data?.serviceAdvisor,
                                  disabled:
                                    data?.upsellServiceStatus === "completed" ||
                                    data?.upsellServiceStatus === "pending"
                                      ? true
                                      : false,
                                }}
                              />
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </CheckAccess>
                    </>
                  ) : null}
                  {data?.orderType === "upsell" ? (
                    <>
                      {data?.serviceAdvisor ? (
                        <div className="offer-start-service-wrapper">
                          <div>
                            <span
                              className={` ${
                                data?.upsellServiceStatus === "completed"
                                  ? "upsellServiceCompleted"
                                  : "upsellStartService"
                              } upsellServiceBtn`}
                              onClick={() => {
                                if (data?.upsellServiceStatus === "completed")
                                  return;
                                setCompletionKey("upsellService");
                                setUpsellServiceAction(
                                  data?.upsellServiceStatus === "notStarted"
                                    ? "starting"
                                    : "ending"
                                );
                                setShowConfirmationModal(true);
                              }}
                              type="button"
                            >
                              {data?.upsellServiceStatus === "completed" ? (
                                <FormattedMessage
                                  id="order.serviceCompleted"
                                  defaultMessage="Service Completed"
                                />
                              ) : data?.upsellServiceStatus === "pending" ? (
                                <FormattedMessage
                                  id="order.endService"
                                  defaultMessage="End Service"
                                />
                              ) : (
                                <FormattedMessage
                                  id="order.startService"
                                  defaultMessage="Start Service"
                                />
                              )}
                            </span>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    !data.subscriptionId &&
                    !data?.isRefunded && (
                      <>
                        <div className="order-completion-action-wrapper">
                          <div>
                            {data?.offerCompletedStatus === "completed" ? (
                              <span className="orderCompleted">
                                <FormattedMessage
                                  id="order.completed"
                                  defaultMessage="Completed"
                                />
                              </span>
                            ) : data?.appointmentId?.collectionAndDelivery
                                ?.collectionAddress ? (
                              <div className="cad-btn-wrapper">
                                {data?.isReceivedCarSmsSent ? (
                                  <div className="offer-start-service-wrapper">
                                    <span
                                      className={` ${
                                        data?.offerStatus === "completed"
                                          ? "upsellServiceCompleted"
                                          : "upsellStartService"
                                      } upsellServiceBtn`}
                                      onClick={() => {
                                        if (data?.offerStatus === "completed")
                                          return;
                                        setCompletionKey("offer");
                                        setIsCndOrder(true);
                                        setUpsellServiceAction(
                                          data?.offerStatus === "notStarted"
                                            ? "starting"
                                            : "ending"
                                        );
                                        setShowConfirmationModal(true);
                                      }}
                                      type="button"
                                    >
                                      {data?.offerStatus === "completed" ? (
                                        <FormattedMessage
                                          id="order.serviceCompleted"
                                          defaultMessage="Service Completed"
                                        />
                                      ) : data?.offerStatus === "pending" ? (
                                        <FormattedMessage
                                          id="order.endService"
                                          defaultMessage="End Service & Dispatch Car"
                                        />
                                      ) : (
                                        <FormattedMessage
                                          id="order.startService"
                                          defaultMessage="Start Service"
                                        />
                                      )}
                                    </span>
                                  </div>
                                ) : null}

                                {data?.cndStatus === "notStarted" &&
                                data?.appointmentId?.collectionAndDelivery
                                  ?.collectionAddress ? (
                                  <div>
                                    <input
                                      type="button"
                                      disabled={
                                        data?.cndStatus !== "notStarted"
                                      }
                                      className={`btn btn-primary cadCarReceivedBtn`}
                                      onClick={() => {
                                        if (data?.cndStatus !== "notStarted")
                                          return;
                                        handleChangeCndOrderStatus(
                                          "pickedFromCustomer"
                                        );
                                      }}
                                      value={intl.formatMessage({
                                        id: "order.pickedUpFromCustomer",
                                        defaultMessage:
                                          "Picked up from Customer",
                                      })}
                                    />
                                  </div>
                                ) : null}
                                {data?.cndStatus === "pickedFromCustomer" &&
                                !data?.isReceivedCarSmsSent &&
                                data?.appointmentId?.collectionAndDelivery
                                  ?.collectionAddress ? (
                                  <div>
                                    <input
                                      type="button"
                                      disabled={data?.isReceivedCarSmsSent}
                                      className={`btn btn-primary cadCarReceivedBtn`}
                                      onClick={() => {
                                        if (data?.isReceivedCarSmsSent) return;
                                        handleSendNotificationToUser(false);
                                      }}
                                      value={intl.formatMessage({
                                        id: "order.cadCarReceived",
                                        defaultMessage: "Received at Center",
                                      })}
                                    />
                                  </div>
                                ) : null}
                                {data?.cndStatus === "pickedFromCenter" &&
                                data?.isReceivedCarSmsSent &&
                                data?.appointmentId?.collectionAndDelivery
                                  ?.collectionAddress ? (
                                  <div>
                                    <input
                                      type="button"
                                      disabled={
                                        data?.cndStatus !== "pickedFromCenter"
                                      }
                                      className={`btn btn-primary cadCarReceivedBtn`}
                                      onClick={() => {
                                        handleSendNotificationToUser(true);
                                      }}
                                      value={intl.formatMessage({
                                        id: "order.deliveredToCustomer",
                                        defaultMessage: "Delivered to Customer",
                                      })}
                                    />
                                  </div>
                                ) : null}
                              </div>
                            ) : (
                              <div className="offer-start-service-wrapper">
                                <div>
                                  <span
                                    className={` ${
                                      data?.offerStatus === "completed"
                                        ? "upsellServiceCompleted"
                                        : "upsellStartService"
                                    } upsellServiceBtn`}
                                    onClick={() => {
                                      if (data?.offerStatus === "completed")
                                        return;
                                      setCompletionKey("offer");
                                      setUpsellServiceAction(
                                        data?.offerStatus === "notStarted"
                                          ? "starting"
                                          : "ending"
                                      );
                                      setShowConfirmationModal(true);
                                    }}
                                    type="button"
                                  >
                                    {data?.offerStatus === "completed" ? (
                                      <FormattedMessage
                                        id="order.serviceCompleted"
                                        defaultMessage="Service Completed"
                                      />
                                    ) : data?.offerStatus === "pending" ? (
                                      <FormattedMessage
                                        id="order.endService"
                                        defaultMessage="End Service"
                                      />
                                    ) : (
                                      <FormattedMessage
                                        id="order.startService"
                                        defaultMessage="Start Service"
                                      />
                                    )}
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="completed-by">
                          <strong>
                            <FormattedMessage
                              id="order.completedBy"
                              defaultMessage="Completed By"
                            />
                            :
                          </strong>
                          <span>
                            {" "}
                            {data["offerCompletedBy"]
                              ? `${data["offerCompletedBy"]?.fullName} (${data["offerCompletedBy"]?.role})`
                              : "N/A"}
                          </span>
                        </div>
                      </>
                    )
                  )}
                  {/* </CheckAccess> */}
                  <div className="dashboard-row label-row">
                    <div className="col-md-12 col-xs-12 mb-0">
                      <strong>
                        <span>
                          {intl.formatMessage({
                            id: "order.offerItems",
                            defaultMessage: "Offer Items:",
                          })}
                        </span>
                      </strong>
                    </div>
                    <div className="col-md-12 col-xs-12">
                      <Well bsSize="small">
                        <Items
                          itemDetails={data?.offerItems}
                          keyName="serviceId"
                        />
                      </Well>
                    </div>
                  </div>
                </div>
              ) : null}
            </CheckAccess>

            <CheckAccess activityId={ActivitiesEnum.display_offers}>
              {data?.subscriptionItems?.length ? (
                <div>
                  {!data?.isRefunded && (
                    <CheckAccess activityId={ActivitiesEnum.display_offers}>
                      <>
                        <div className="order-completion-action-wrapper">
                          <strong>
                            <FormattedMessage
                              id="order.action"
                              defaultMessage="Action:"
                            />
                          </strong>
                          <div>
                            {data?.subscriptionCompletedStatus ===
                            "completed" ? (
                              <span className="orderCompleted">
                                <FormattedMessage
                                  id="order.completed"
                                  defaultMessage="Completed"
                                />
                              </span>
                            ) : data.subscribedSubscriptionId?.quota?.left >
                              0 ? (
                              <span className="orderSubscription">
                                <FormattedMessage
                                  id="order.quotaLeft"
                                  defaultMessage="Pending"
                                />{" "}
                                <span>{` (${data.subscribedSubscriptionId?.quota?.left} Quota Left)`}</span>
                              </span>
                            ) : (
                              <span
                                className="orderPending"
                                onClick={() => {
                                  setCompletionKey("subscription");
                                  setShowConfirmationModal(true);
                                }}
                              >
                                <FormattedMessage
                                  id="order.markAsCompleted"
                                  defaultMessage="Mark As Completed"
                                />
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="completed-by">
                          <strong>
                            <FormattedMessage
                              id="order.completedBy"
                              defaultMessage="Completed By"
                            />
                            :
                          </strong>
                          <span>
                            {" "}
                            {data["subscriptionCompletedBy"]
                              ? `${data["subscriptionCompletedBy"]?.fullName} (${data["subscriptionCompletedBy"]?.role})`
                              : "N/A"}
                          </span>
                        </div>
                      </>
                    </CheckAccess>
                  )}

                  <div className="dashboard-row label-row">
                    <div className="col-md-12 col-xs-12 mb-0">
                      <strong>
                        <span>
                          {intl.formatMessage({
                            id: "order.subscriptionItems",
                            defaultMessage: "Subscription Items:",
                          })}
                        </span>
                      </strong>
                    </div>
                    <div className="col-md-12 col-xs-12">
                      <Well bsSize="small">
                        <Items
                          itemDetails={data?.subscriptionItems}
                          keyName="subscriptionId"
                        />
                      </Well>
                    </div>
                  </div>
                </div>
              ) : null}
            </CheckAccess>

            {data?.carId && (
              <div className="dashboard-row row">
                <div className="col-md-12 col-xs-12">
                  <strong>
                    <span>
                      {intl.formatMessage({
                        id: "order.carDetails",
                        defaultMessage: "Car Details",
                      })}
                      :{" "}
                    </span>
                  </strong>
                </div>
                <div className="col-md-4 col-xs-12">
                  <CarCard
                    cursorFlag={false}
                    key={"car-" + data?._id}
                    // style={style}
                    car={data?.carId}
                  />
                </div>
              </div>
            )}
            {data?.orderAdjustmentBit && (
              <div className="dashboard-row row">
                <div className="col-md-12 col-xs-12">
                  <strong>
                    <span>
                      {intl.formatMessage({
                        id: "order.orderAdjustment",
                        defaultMessage: "Order Adjustments",
                      })}
                      :{" "}
                    </span>
                  </strong>
                </div>

                <div className="col-md-12 col-xs-12">
                  <Well bsSize="small">
                    <AdjustmentDetails itemDetails={data?.orderAdjustment} />
                  </Well>
                </div>
              </div>
            )}
            {/* )} */}
            <CompleteOrder
              closeModal={closeModal}
              doShowOrderCompleteModal={!loading && doShowOrderCompleteModal}
              doShowOtpModal={!loading && doShowOtpModal}
              showOdoMeterModal={!loading && showOdoMeterModal}
              orderIdText={orderIdText}
              odometer={odometer}
              verifyOrderOtpText={verifyOrderOtpText}
              //   onChangeTextValue={(key, text) =>
              //     this.setState({ [key]: text })
              //   }
              completeOrder={() => !loading && completeOrder()}
              submitOrderId={() => !loading && submitOrderId()}
              verifyOrderOtp={() => !loading && verifyOrderOtp()}
              intl={intl}
            />
          </div>
        </div>

        <div className="dashboard-row -nobottom">
          <Button variant="link" onClick={() => navigate("/orders")}>
            {intl.formatMessage({
              id: "global.back",
              defaultMessage: "Back",
            })}
          </Button>
        </div>
      </div>
    </Layout>
  );
};

export default withRouter(injectIntl(OrderDetails));
