import React, { Component } from "react";
import _ from "lodash";

export default class Thumb extends Component {
  state = {
    loading: false,
    thumb: undefined,
  };

  componentWillReceiveProps(nextProps) {
    if (
      !nextProps.file ||
      (typeof nextProps.file !== "string" &&
        nextProps.file.name === _.get(this, "props.file.name"))
    ) {
      return;
    }

    this.setState({ loading: true }, () => {
      if (typeof nextProps.file === "string") {
        const key = "xzic2b7Fei1GJlKuQsJcBtIo12qfnJ5W";
        this.setState({
          loading: false,
          thumb: `/api/util/image?key=${nextProps.file}&accesskey=${key}`,
        });
      } else if (nextProps.file instanceof Blob) {
        const blob = nextProps.file;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        this.setState({ loading: false, thumb: this.fileUrl });
      } else {
        let reader = new FileReader();

        reader.onloadend = () => {
          this.setState({ loading: false, thumb: reader.result });
        };

        reader.readAsDataURL(nextProps.file);
      }
    });
  }

  render() {
    const { file, showFocusBorder } = this.props;
    const { loading, thumb } = this.state;

    if (!file) {
      return null;
    }

    if (loading) {
      return <p>loading...</p>;
    }

    return (
      <img
        src={
          file === "/design/images/carhub_placeholder.png"
            ? "/design/images/carhub_placeholder.png"
            : thumb
        }
        alt={file.name}
        className="thumbnail thumbnailBg"
        style={{
          border: showFocusBorder ? "3px solid #29c0dc" : undefined,
          maxWidth: "400px",
          maxHeight: "400px",
          minWidth: "200px",
          minHeight: "200px",
        }}
      />
    );
  }
}
