import React, { Component } from "react";
import { FormattedMessage, injectIntl, FormattedNumber } from "react-intl";
import Layout from "../shared/Layout";
import Services from "../../services";
import _ from "lodash";
import Auth from "../shared/Auth";
import { Link } from "react-router-dom";
import withRouter from "../../util/withRouter";
import appState from "../../state/AppStateContainer";
import ImageProvider from "../shared/ImageProvider";
import HeaderTitle from "../shared/HeaderTitle";
import CheckAccess from "../shared/CheckAccess";
import { ActivitiesEnum } from "../../staticData/Activities";
import { getLocalDateTime } from "../../util";
import Table from "../shared/Table";

export class KaizenList extends Component {
  state = {
    data: [],
    hasMore: true,
    pageIndex: -1,
    pageSize: 10,
    searchFilter: "",
    approvalFilter: "all",
    statusFilter: "all",
    rejectingId: "",
    aplhabeticalOrder: true,
    count: 0,
    searchValueInput: "",
  };

  async componentDidMount() {
    try {
      this.setState({ loading: true });
      
      this.subscription = appState.emitter.addListener(
        "onLanguageChange",
        () => {
          this.formik.validateForm();
        }
      );

      const data = await Services.getKaizens({ progressBar: true });
      this.setState({ data });
    } catch (e) {
      this.props.navigate("/error", {
        error: _.get(e, "response.data.error", "error.unexpectedError"),
      });
    } finally {
      this.setState({ loading: false });
    }
  }

  componentWillUnmount() {
    this.subscription.remove();
  }

  render() {
    return (
      <Auth requireAuth={true} roles={["sadmin", "wsadmin", "spadmin"]}>
        <Layout
          hidden={this.props.match.params.isFormOpen}
          loading={this.state.loading}
        >
          <div className="table-header clearfix">
            <HeaderTitle id="kazien.title" defaultMessage="Kaizen" />
            <div className="main-container">
              <CheckAccess activityId={ActivitiesEnum.kaizen_add_update}>
                <div className="row">
                  <div className="col-xs-3 pull-right">
                    <div className="panel-group-buttons pull-right">
                      <Link className="btn btn-special" to="/kaizen">
                        <span className="fa fa-plus" />{" "}
                        <FormattedMessage
                          id="kaizen.new"
                          defaultMessage="Create Kaizen"
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              </CheckAccess>
            </div>
            <div>
              <CheckAccess activityId={ActivitiesEnum.kaizen_table}>
                <div className="row">
                  <div className="col-md-12">
                    <FormattedMessage
                      id="global.totalCount"
                      defaultMessage="Total count"
                    />{" "}
                    <strong>
                      <FormattedNumber
                        style="decimal"
                        maximumFractionDigits={0}
                        minimumFractionDigits={0}
                        value={this.state.data?.length}
                      />{" "}
                    </strong>
                  </div>
                </div>
                <div className="table-responsive">
                  <Table
                    caption={
                      (!this.state.data || this.state.data.length == 0) && (
                        <caption
                          style={{
                            captionSide: "bottom",
                            textAlign: "center",
                            margin: "20px",
                            color: "darkgray",
                          }}
                        >
                          <FormattedMessage
                            id="global.noData"
                            defaultMessage="No data available"
                          />
                        </caption>
                      )
                    }
                    headerRow={
                      <tr>
                        <th>
                          <FormattedMessage
                            id="autoHubServies.image"
                            defaultMessage="Image"
                          />
                        </th>
                        <th>
                          <FormattedMessage
                            id="autoHubServies.englishName"
                            defaultMessage="English Name"
                          />
                        </th>
                        <th>
                          <FormattedMessage
                            id="autoHubServies.arabicName"
                            defaultMessage="Arabic Name"
                          />
                        </th>
                        <th>
                          <FormattedMessage
                            id="global.createdAt"
                            defaultMessage="Created At"
                          />
                        </th>
                        <th>
                          <FormattedMessage
                            id="autoHubServies.isActive"
                            defaultMessage="IsActive"
                          />
                        </th>
                        <th style={{ width: "20px" }} />
                      </tr>
                    }
                  >
                    {this.state.data.map((c) => {
                      var showEdit = false;

                      if (
                        (appState.state.token?.role === "sadmin" &&
                          c.approvalState === "approved") ||
                        (appState.state.token?.id === c.approvalRequestedBy &&
                          c.approvalState !== "approved")
                      )
                        showEdit = true;
                      return (
                        <tr
                          key={c._id}
                          onClick={() =>
                            this.props.navigate(`/kaizen/${c?._id}`)
                          }
                          className="cases-trow link-row"
                        >
                          <ImageProvider imageSrc={c.image} />
                          <td>{c.name?.value_en}</td>
                          <td>{c.name?.value_ar}</td>
                          <td>
                            {c.createdAt
                              ? getLocalDateTime(c.createdAt)
                              : undefined}
                          </td>
                          <td>{c.isActive ? "Yes" : "No"}</td>
                        </tr>
                      );
                    })}
                  </Table>
                </div>
              </CheckAccess>
            </div>
          </div>
        </Layout>
      </Auth>
    );
  }
}

export default withRouter(injectIntl(KaizenList));
