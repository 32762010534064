const gMapAPIKey = "AIzaSyCyPOyS4Y7RdJy1HFEJnnXBq58N4n0swk4";
const gGeocodingAPIKey = "AIzaSyBOp64FPdaw6xZGWqMJ56blLryC8GkQ9zw";

export default {
  gDynamicLinksAPIKey: "",
  gGeocodingAPIKey: gMapAPIKey,
  gMapAPIKey: gMapAPIKey,
  googleMapUrl: `https://maps.googleapis.com/maps/api/js?key=${gMapAPIKey}&v=3.exp&libraries=geometry,drawing,places`,
  serverChangePass: "43770",
};
