import React, { useState, useEffect, useRef } from "react";
import { FormattedMessage, injectIntl, FormattedNumber } from "react-intl";
import Layout from "../shared/Layout";
import _ from "lodash";
import Auth from "../shared/Auth";
import withRouter from "../../util/withRouter";
import moment from "moment";
import HeaderTitle from "../shared/HeaderTitle";
import Table from "../shared/Table";
import careemPromoServices from "../../services/careemPromoServices";
import classNames from "classnames";

const CareemPromoList = ({ match, location, intl, navigate }) => {
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [pageIndex, setPageIndex] = useState(-1);
  const [loading, setLoading] = useState(false);
  const [statusFilter, setStatusFilter] = useState("all");
  const [disableFlag, setDisableFlag] = useState(false);
  const [searchFilter, setSearchFilter] = useState("");

  const searchInputRef = useRef(null);

  useEffect(() => {
    if (location.pathname !== match.path) {
      if (_.get(location, "state.isSubmitted") === true) {
        setPageIndex(-1);
        setData([]);
        setHasMore(true);
        setDisableFlag(true);
        setSearchFilter(searchInputRef.current?.value);
      }
    }
  }, [location.pathname, match.params.isFormOpen, location.state]);

  const resetData = (e) => {
    setPageIndex(-1);
    setData([]);
    setHasMore(true);
    setDisableFlag(false);
    setSearchFilter(searchInputRef.current?.value);

    if (e) e.preventDefault();
  };

  const resetPage = (e) => {
    setPageIndex(-1);
    setData([]);
    setHasMore(true);
    setSearchFilter("");
    setStatusFilter("all");
    setDisableFlag(false);
    searchInputRef.current.value = "";
    e.preventDefault();
  };

  const loadMore = async () => {
    setLoading(true);
    setPageIndex((prevState) => prevState + 1);

    setHasMore(false);
    const _pageIndex = pageIndex + 1;

    const options = {
      progressBar: true,
      alphabeticalOrder: true,
    };

    const pageData = await careemPromoServices.getCareemPromos(
      searchFilter,
      statusFilter,
      _pageIndex,
      10, // pageSize
      options
    );
    let summary = { count };

    if (_pageIndex === 0) {
      summary = await careemPromoServices.getCareemPromos(
        searchFilter,
        statusFilter,
        undefined,
        undefined,
        options,
        summary
      );
    }

    setData((prevData) => [...prevData, ...pageData]);
    setHasMore(pageData.length > 0);
    setDisableFlag(false);
    setLoading(false);
    if (_pageIndex === 0) {
      setCount(summary.count);
    }
  };

  return (
    <Auth requireAuth={true} roles={["sadmin", "wsadmin", "spadmin"]}>
      <Layout hidden={match.params.isFormOpen} loading={loading}>
        <div className="table-header clearfix">
          <HeaderTitle
            id="careemPromo.careemPromo"
            defaultMessage="Careem Promo"
          />
          <div className="main-container">
            <div className="table-header clearfix">
              <form className="row" onSubmit={resetData}>
                <div className="col-md-2 col-sm-4 col-xs-6">
                  <div className="has-feedback">
                    <input
                      type="text"
                      ref={searchInputRef}
                      className="form-control"
                      //   value={searchFilter}
                      placeholder={intl.formatMessage({
                        id: "global.search",
                        defaultMessage: "Search",
                      })}
                    />
                    <i className="glyphicon glyphicon-search form-control-feedback" />
                  </div>
                </div>
                <div className="col force--inline">
                  <div className="tooltip-master">
                    <button
                      className="btn btn-primary"
                      onClick={resetData}
                      disabled={disableFlag}
                    >
                      <i className="material-icons">search</i>
                    </button>
                    <span className="tooltiptext">
                      <FormattedMessage
                        id="global.filter"
                        defaultMessage="Filter"
                      />
                    </span>
                  </div>
                </div>
                <div className="col force--inline">
                  <div className="tooltip-master">
                    <button
                      className="btn btn-primary"
                      onClick={resetPage}
                      disabled={disableFlag}
                    >
                      <i className="material-icons">settings_backup_restore</i>
                    </button>
                    <span className="tooltiptext">
                      <FormattedMessage
                        id="global.resetFilter"
                        defaultMessage="Reset Filter"
                      />
                    </span>
                  </div>
                </div>
              </form>
            </div>

            <div className="row">
              <div className="col-md-12">
                <FormattedMessage
                  id="global.totalCount"
                  defaultMessage="Total Count"
                />
                <strong>
                  {" "}
                  <FormattedNumber
                    style="decimal"
                    maximumFractionDigits={0}
                    minimumFractionDigits={0}
                    value={count || 0}
                  />
                </strong>
              </div>
            </div>
          </div>
          <div className="table-responsive">
            <Table
              loadMore={loadMore}
              hasMore={hasMore}
              caption={
                (!data || data?.length === 0) && (
                  <caption
                    style={{
                      captionSide: "bottom",
                      textAlign: "center",
                      margin: "20px",
                      color: "darkgray",
                    }}
                  >
                    <FormattedMessage
                      id="global.noData"
                      defaultMessage="No data available"
                    />
                  </caption>
                )
              }
              headerRow={
                <tr>
                  <th>
                    <FormattedMessage
                      id="careemPromo.careemPromoCode"
                      defaultMessage="Careem Promo Code"
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="careemPromo.status"
                      defaultMessage="Status"
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="careemPromo.updatedAt"
                      defaultMessage="Updated At"
                    />
                  </th>
                  <th style={{ width: "20px" }} />
                </tr>
              }
            >
              {data?.map((c, i) => {
                return (
                  <tr
                    key={c._id + i}
                    onClick={() => navigate(`/careem-promo/${c._id}`)}
                    className="cases-trow link-row"
                  >
                    <td>{c?.careemPromoCode}</td>
                    <td
                      className={classNames({
                        "col-status-green": c.isConsumed,
                        "col-status-pending": !c.isConsumed,
                      })}
                    >
                      <a href="javascript:;">
                        {c?.isConsumed ? "Sent" : "Unsent"}
                      </a>
                    </td>
                    <td>{moment.utc(c?.updatedAt).format("LL")}</td>
                  </tr>
                );
              })}
            </Table>
          </div>
        </div>
      </Layout>
    </Auth>
  );
};

export default withRouter(injectIntl(CareemPromoList));
