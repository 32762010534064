import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Formik, Form } from "formik";
import { Yup } from "../../util";
import { Modal, Button } from "react-bootstrap";
import appState from "../../state/AppStateContainer";
import _ from "lodash";

class PasswordVerificationModal extends Component {
  constructor() {
    super();
  }
  state = {
    showPassword: false,
  };
  componentDidUpdate(prevProps) {
    if (prevProps !== this.props && !this.props.add) {
      this.formik.resetForm();
    }
  }

  render() {
    const { intl, loading } = this.props;

    return (
      <Modal
        show={this.props.show}
        onHide={() => {
          this.props.onCancel && this.props.onCancel();
        }}
        backdrop="static"
      >
        <Modal.Header closeButton={true}>
          <h4>
            <FormattedMessage
              id="login.verification"
              defaultMessage="Verification"
            />
          </h4>
        </Modal.Header>
        <Modal.Body>
          <Formik
            ref={(f) => (this.formik = f)}
            innerRef={(f) => (this.formik = f)}
            validationSchema={Yup.object().shape(
              _({
                password: Yup.string()
                  .label(
                    intl.formatMessage({
                      id: "global.password",
                      defaultMessage: "Password",
                    })
                  )
                  .trim()
                  .required(),
              })
                .omitBy(_.isUndefined)
                .value()
            )}
            onSubmit={(values) => {
              this.props.onSubmit && this.props.onSubmit(values);
            }}
            initialValues={{
              password: this.props.password || "",
              confirmPassword: this.props.confirmPassword || "",
            }}
            render={({ values, handleChange, touched, errors }) => {
              return (
                <Form>
                  {this.props.add && (
                    <div className="admin-change-password-wrapper">
                      <FormattedMessage
                        id="global.password"
                        defaultMessage="Password"
                      />
                      <input
                        name="password"
                        type={this.state.showPassword ? "text" : "password"}
                        autoComplete="off"
                        value={values.password}
                        onChange={handleChange}
                        className="form-control"
                      />
                      <span
                        className="input-group-btn admin-emoji-floater admin-change-password"
                        style={{ paddingRight: "129px" }}
                      >
                        <a
                          onClick={() => {
                            this.setState({
                              showPassword:
                                this.state.showPassword == false ? true : false,
                            });
                          }}
                          className="btn btn-default"
                        >
                          <i className="fa fa-eye" />
                        </a>
                      </span>
                      {touched["password"] && errors["password"] && (
                        <p className="text-warning">{errors["password"]}</p>
                      )}
                    </div>
                  )}
                  <br />
                </Form>
              );
            }}
          />
        </Modal.Body>
        {!loading && (
          <Modal.Footer>
            <Button
              bsStyle="btn btn-primary"
              style={{ marginBottom: 0 }}
              onClick={() => {
                this.formik.submitForm();
              }}
            >
              <FormattedMessage id="global.verify" defaultMessage="Verify" />
            </Button>
            <Button
              bsStyle="link"
              onClick={() => {
                this.props.onCancel && this.props.onCancel();
              }}
            >
              <FormattedMessage id="global.cancel" defaultMessage="Cancel" />
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    );
  }
}

export default injectIntl(PasswordVerificationModal);
