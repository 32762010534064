import React, { useState, useEffect } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import withRouter from "../../util/withRouter";
import Auth from "../shared/Auth";
import Loading from "../shared/Loading";
import Layout from "../shared/Layout";
import HeaderTitle from "../shared/HeaderTitle";
import services from "../../services";
import {
  handleChange,
  validateArabicLanguage,
  validateEnglishLanguage,
} from "./Helpers";
import { Button } from "react-bootstrap";

const BookingAppointmentFor = ({
  intl,
  noLayout,
  match,
  serviceData,
  serviceId,
  carOwnedBy,
  setBookingAppointmentForData,
  setCurrentDocStatus,
  prevSelectedService,
  setPrevSeletedService,
}) => {
  const [loading, setLoading] = useState(false);
  const _service = {
    service: serviceId ? serviceId : carOwnedBy[0]?.service,
  };
  const [docValues, setDocValues] = useState([
    {
      name: {
        value_en: "",
        value_ar: "",
      },
      ..._service,
      carOwnBy: "",
    },
  ]);
  const [isFetching, setIsFetching] = useState(false);
  const [errorIndex, setErrorIndex] = useState([]);

  useEffect(() => {
    if (serviceId || prevSelectedService) {
      getBookingAppointmentFor();
    }
  }, [isFetching]);

  const getBookingAppointmentFor = async (from = undefined) => {
    try {
      setLoading(true);

      const res = await services.getServiceBookingAppointmentFor(
        serviceId || carOwnedBy[0]["service"] || prevSelectedService
      );
      if (!res?.length) {
        setLoading(false);
        return;
      }

      setDocValues(res);
      setBookingAppointmentForData(res);
      if (from) setCurrentDocStatus("requiredDocuments");

      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const movetoNextForm = async () => {
    let _errorIndex = [];
    const _docValues = [...docValues];
    _docValues &&
      _docValues.forEach((item, i) => {
        if (item.name.value_en === "" || item.name.value_ar === "") {
          _errorIndex = [..._errorIndex, i];
        }
      });
    setErrorIndex(_errorIndex);
    if (_errorIndex?.length) return;
    setLoading(true);

    const res = await services.saveBookingAppointmentFor(
      _docValues,
      serviceId || prevSelectedService
    );

    if (
      res === "success.createdSuccessfully" ||
      res === "success.updatedSuccessfully"
    ) {
      setLoading(false);
      setIsFetching(true);
      getBookingAppointmentFor("savedFunc");
    }
  };

  const handleAddMoreFields = () => {
    const docServiceValue = {
      service: serviceId ? serviceId : carOwnedBy[0]?.service,
    };
    setDocValues([
      ...docValues,
      {
        name: {
          value_en: "",
          value_ar: "",
        },
        ...docServiceValue,
        carOwnBy: "",
      },
    ]);
  };

  const handleRemoveField = (index) => {
    const _docValues = [...docValues];
    _docValues.splice(index, 1);
    setDocValues(_docValues);

    // const _index = errorIndex.indexOf(index);

    // if (_index !== -1) {
    //   errorIndex.splice(index, 1);
    // }
  };

  const selectServiceAgainstCarOwnBy = (field, index) => {
    const { value } = field;
    const _docValues = [...docValues];
    carOwnedBy &&
      carOwnedBy.filter((obj) => {
        if (obj._id === value) {
          _docValues[index]["service"] = obj.service;
        }
      });
    setDocValues(_docValues);
  };

  return (
    <Auth requireAuth={true} roles={["sadmin"]}>
      <Layout noLayout={noLayout} loading={loading}>
        <HeaderTitle
          id="requiredDoc.listing"
          defaultMessage="Required Documents For Appointment Services"
        />
        <div className="dashboard-table-container card-layout">
          <div className="panel-primary">
            <header className="panel-title">
              {match.params.id && (
                <FormattedMessage
                  id="requiredDoc.updateBookingAppointmentFor"
                  defaultMessage="Update Booking Appointment For"
                />
              )}
              {!match.params.id && (
                <FormattedMessage
                  id="requiredDoc.addBookingAppointmentFor"
                  defaultMessage="Add Booking Appointment For"
                />
              )}
            </header>

            <div>
              {docValues &&
                docValues.map((val, index) => {
                  return (
                    <div
                      className="service-status-wrapper dashboard-row"
                      key={index}
                    >
                      <div className="service-status-fields-wrapper">
                        <div className="row">
                          <div className="col-md-2 col-sm-2 col-xs-12">
                            <label>
                              <FormattedMessage
                                id="requiredDoc.englishName"
                                defaultMessage="English Name"
                              />
                              <span className="text-warning">*</span>
                            </label>
                          </div>
                          <div className="col-md-8 col-sm-8 col-xs-12">
                            <input
                              name="value_en"
                              type="text"
                              placeholder="Enter English Name"
                              value={val["name"]?.value_en}
                              onChange={(e) =>
                                handleChange({
                                  field: e.target,
                                  index,
                                  docValues,
                                  setDocValues,
                                })
                              }
                            />
                            <p className="text-warning">
                              {errorIndex.includes(index) &&
                              docValues[index]["name"]?.value_en.length <= 0 ? (
                                <FormattedMessage
                                  id="requiredDoc.englishField"
                                  defaultMessage="English Value is required"
                                />
                              ) : validateEnglishLanguage(
                                  docValues[index]["name"]?.value_en
                                ) ? null : (
                                <FormattedMessage
                                  id="requiredDoc.arabicCharsNotAllowed"
                                  defaultMessage="Arabic Characters are not allowed"
                                />
                              )}
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-2 col-sm-2 col-xs-12">
                            <label>
                              <FormattedMessage
                                id="requiredDoc.arabicName"
                                defaultMessage="Arabic Name"
                              />
                              <span className="text-warning">*</span>
                            </label>
                          </div>
                          <div className="col-md-8 col-sm-8 col-xs-12">
                            <input
                              className="ml10"
                              name="value_ar"
                              type="text"
                              placeholder="Enter Arabic Name"
                              value={val["name"]?.value_ar}
                              onChange={(e) =>
                                handleChange({
                                  field: e.target,
                                  index,
                                  docValues,
                                  setDocValues,
                                })
                              }
                            />
                            <p className="text-warning">
                              {errorIndex.includes(index) &&
                              docValues[index]["name"]?.value_ar.length <= 0 ? (
                                <FormattedMessage
                                  id="requiredDoc.arabicField"
                                  defaultMessage="Arabic Value is required"
                                />
                              ) : validateArabicLanguage(
                                  docValues[index]["name"]?.value_ar
                                ) ? null : (
                                <FormattedMessage
                                  id="requiredDoc.englishCharsNotAllowed"
                                  defaultMessage="English Characters are not allowed"
                                />
                              )}
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-2 col-sm-2 col-xs-12">
                            <label>
                              <FormattedMessage
                                id="requiredDoc.carOwnBy"
                                defaultMessage="Car Own By"
                              />
                              <span className="text-warning">*</span>
                            </label>
                          </div>
                          <div className="col-md-8 col-sm-8 col-xs-12">
                            <select
                              id="selectOption"
                              name="carOwnBy"
                              type="select"
                              value={docValues[index]["carOwnBy"]}
                              onChange={(e) => {
                                handleChange({
                                  field: e.target,
                                  index,
                                  docValues,
                                  setDocValues,
                                });
                                selectServiceAgainstCarOwnBy(e.target, index);
                              }}
                            >
                              <option value="">-- Select --</option>
                              {carOwnedBy &&
                                carOwnedBy.map((val, index) => {
                                  if (serviceId) {
                                    if (val?.service !== serviceId) return;
                                  }
                                  return (
                                    <option value={val._id} key={index}>
                                      {val.name.value_en}
                                    </option>
                                  );
                                })}
                            </select>
                            <p className="text-warning">
                              {errorIndex.includes(index) &&
                              docValues[index].carOwnBy.length <= 0 ? (
                                <FormattedMessage
                                  id="requiredDoc.carOwnByField"
                                  defaultMessage="Car Owned By is required"
                                />
                              ) : null}
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-2 col-sm-2 col-xs-12">
                            <label>
                              <FormattedMessage
                                id="requiredDoc.service"
                                defaultMessage="Service"
                              />
                              <span className="text-warning">*</span>
                            </label>
                          </div>
                          <div className="col-md-8 col-sm-8 col-xs-12">
                            <select
                              id="selectOption"
                              name="service"
                              type="select"
                              value={docValues[index]["service"]}
                              defaultValue={docValues[index]["service"]}
                              onChange={(e) => {
                                return;
                              }}
                              disabled={true}
                            >
                              <option value="">-- Select --</option>
                              {serviceData &&
                                serviceData.map((service, index) => (
                                  <option value={service._id} key={index}>
                                    {service.name.value_en}
                                  </option>
                                ))}
                            </select>

                            <p className="text-warning">
                              {errorIndex.includes(index) &&
                              docValues[index].service.length <= 0 ? (
                                <FormattedMessage
                                  id="requiredDoc.serviceField"
                                  defaultMessage="Service is required"
                                />
                              ) : null}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="btn-box">
                        {docValues.length - 1 === index && (
                          <Button
                            variant="secondary"
                            onClick={handleAddMoreFields}
                          >
                            {intl.formatMessage({
                              id: "global.add",
                              defaultMessage: "Add",
                            })}
                          </Button>
                        )}
                        {docValues.length !== 1 && (
                          <Button
                            variant="secondary"
                            onClick={() => handleRemoveField(index)}
                          >
                            {intl.formatMessage({
                              id: "global.remove",
                              defaultMessage: "Remove",
                            })}
                          </Button>
                        )}
                      </div>
                    </div>
                  );
                })}
              <div className="dashboard-row -nobottom">
                <Button
                  variant="primary"
                  onClick={movetoNextForm}
                  style={{ margin: "0 10px" }}
                >
                  {intl.formatMessage({
                    id: "global.next",
                    defaultMessage: "Next",
                  })}
                </Button>
                <Button
                  variant="link"
                  onClick={() => {
                    setCurrentDocStatus("carOwnBy");
                    setPrevSeletedService(carOwnedBy[0]?.service);
                  }}
                >
                  {intl.formatMessage({
                    id: "global.back",
                    defaultMessage: "Back",
                  })}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </Auth>
  );
};

export default React.memo(withRouter(injectIntl(BookingAppointmentFor)));
