import React from "react";
import classNames from "classnames";
import Header from "./Header";
import appState from "../../state/AppStateContainer";
import Loading from "../shared/Loading";

const Layout = (props) => {
  const dashboardMainClasses = classNames("dashboard-main", "right", {
    active: appState.state.sidebarActive,
  });

  const style = {};
  if (props.hidden) style.display = "none";

  return (
    <>
      <Loading state={props.loading || !appState.state.loaded} />

      {!props.noLayout ? (
        <section
          id="rightSection"
          className={dashboardMainClasses}
          style={style}
          onKeyDown={props.onKeyDown}
        >
          <Header registerSp={props.registerSp} />
          <div style={{ height: "100%" }}>
            <div
              id="dashboardContent"
              className="dashboard-content animated fadeIn"
              style={{ height: "100%", padding: "0px !important" }}
            >
              {props.children}
            </div>
          </div>
        </section>
      ) : (
        <div style={style} onKeyDown={props.onKeyDown}>
          {props.children}
        </div>
      )}
    </>
  );
};

export default React.memo(Layout);
