import React from "react";
import { Modal } from "react-bootstrap";

const CustomModal = ({
  show,
  handleClose,
  title,
  children,
  footer,
  containerStyle,
  footerStyle = {},
  dialogClassName = "modal-container",
  closeButton = true,
  header = true,
  ...rest
}) => {
  return (
    <Modal show={show} onHide={handleClose} dialogClassName={dialogClassName} {...rest} centered>
      {header && (
        <Modal.Header closeButton={closeButton}>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
      )}
      <Modal.Body style={containerStyle}>{children}</Modal.Body>
      <Modal.Footer style={footerStyle}>{footer}</Modal.Footer>
    </Modal>
  );
};

export default CustomModal;
