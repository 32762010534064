import BaseService from "./baseService";
import axios from "axios";

class CareemPromoServices extends BaseService {
  constructor() {
    super();
  }

  async addCareemPromo(values) {
    // const formData = new FormData();
    // for (const key in values) {
    //   if (values.hasOwnProperty(key)) {
    //     formData.append(key, values[key]);
    //   }
    // }

    const response = await axios.post("/api/careem-promo", values);
    return response.data;
  }

  async getCareemPromos(
    searchFilter,
    statusFilter,
    pageIndex,
    pageSize,
    options = {},
    summary,
  ) {
    const response = await axios.get("/api/careem-promos", {
      params: {
        searchFilter,
        statusFilter: statusFilter ? statusFilter : "all",
        pageIndex,
        pageSize,
        summary,
        // alphabeticalOrder: options.alphabeticalOrder,
      },
      progressBar: options.progressBar,
    });
    return response.data;
  }

  async getCareemPromo(id) {
    // const options = {};
    // if (id) options.params = { id };
    const response = await axios.get(`/api/careem-promo/${id}`);
    return response.data;
  }
}

export default new CareemPromoServices();
