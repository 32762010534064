import React from "react";
import moment from "moment";
import { FormattedMessage } from "react-intl";

const Notification = ({ messages = [], showNoResults = true }) => {
  return (
    <>
      {messages.length ? (
        <>
          {messages.map((msg, i) => {
            const commentDate = moment(msg.createdTime).format(
              "MMM D YYYY, h:mma"
            );
            return (
              <>
                <div
                  className={`d-inline-block admin-message mb-3 ${
                    msg.seen ? "border-gray" : "border-blue"
                  }`}
                  key={i}
                >
                  <div
                    closeButton={false}
                    className={` notifications ${
                      msg.seen ? "read-notification" : "unread-notification"
                    }`}
                  >
                    <strong className="me-auto">
                      {" "}
                      <FormattedMessage
                        id="track.autohubHeading"
                        defaultMessage=" Autohub"
                      />
                    </strong>
                    <small>{commentDate}</small>
                  </div>
                  <div className="msg-body">
                    <p className="mb-0">{msg.body}</p>
                  </div>
                </div>
              </>
            );
          })}
        </>
      ) : (
        <>
          {showNoResults && (
            <div className="no-comments">
              <h4 className="no-data">
                <FormattedMessage
                  id="track.noComments"
                  defaultMessage=" No Comments"
                />
              </h4>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default React.memo(Notification);
