const ALL_TIMESLOTS = Object.freeze({
  _9am: "9:00am",
  _10am: "10:00am",
  _11am: "11:00am",
  _12pm: "12:00pm",
  _1pm: "1:00pm",
  _2pm: "2:00pm",
  _3pm: "3:00pm",
  _4pm: "4:00pm",
  _5pm: "5:00pm",
  _6pm: "6:00pm",
  _7pm: "7:00pm",
  _8pm: "8:00pm",
  _9pm: "9:00pm",
  _10pm: "10:00pm",
  _11pm: "11:00pm",
  _12am: "12:00am",
  _1am: "1:00am",
  _2am: "2:00am",
  _3am: "3:00am",
  _4am: "4:00am",
  _5am: "5:00am",
  _6am: "6:00am",
  _7am: "7:00am",
  _8am: "8:00am",
});

const HOURLY_SLOTS = Object.freeze({
  _12am: "12:00am",
  _1am: "1:00am",
  _2am: "2:00am",
  _3am: "3:00am",
  _4am: "4:00am",
  _5am: "5:00am",
  _6am: "6:00am",
  _7am: "7:00am",
  _8am: "8:00am",
  _9am: "9:00am",
  _10am: "10:00am",
  _11am: "11:00am",
  _12pm: "12:00pm",
  _1pm: "1:00pm",
  _2pm: "2:00pm",
  _3pm: "3:00pm",
  _4pm: "4:00pm",
  _5pm: "5:00pm",
  _6pm: "6:00pm",
  _7pm: "7:00pm",
  _8pm: "8:00pm",
  _9pm: "9:00pm",
  _10pm: "10:00pm",
  _11pm: "11:00pm",
});

const NINE_HOURS_SLOTS = Object.freeze({
  _9am: "9:00am",
  _10am: "10:00am",
  _11am: "11:00am",
  _12pm: "12:00pm",
  _1pm: "1:00pm",
  _2pm: "2:00pm",
  _3pm: "3:00pm",
  _4pm: "4:00pm",
  _5pm: "5:00pm",
});

const CLOSED_DAY_TIMESLOTS = Object.freeze({
  _9am: "9:00am",
  "_9:30am": "9:30am",
  _10am: "10:00am",
  "_10:30am": "10:30am",
  _11am: "11:00am",
  "_11:30am": "11:30am",
  _12pm: "12:00pm",
  "_12:30pm": "12:30pm",
  _1pm: "1:00pm",
  "_1:30pm": "1:30pm",
  _2pm: "2:00pm",
  "_2:30pm": "2:30pm",
  _3pm: "3:00pm",
  "_3:30pm": "3:30pm",
  _4pm: "4:00pm",
  "_4:30pm": "4:30pm",
  _5pm: "5:00pm",
  "_5:30pm": "5:30pm",
  _6pm: "6:00pm",
  "_6:30pm": "6:30pm",
  _7pm: "7:00pm",
  "_7:30pm": "7:30pm",
  _8pm: "8:00pm",
  "_8:30pm": "8:30pm",
  _9pm: "9:00pm",
  "_9:30pm": "9:30pm",
  _10pm: "10:00pm",
  "_10:30pm": "10:30pm",
  _11pm: "11:00pm",
  "_11:30pm": "11:30pm",
  _12am: "12:00am",
  "_12:30am": "12:30am",
  _1am: "1:00am",
  "_1:30am": "1:30am",
  _2am: "2:00am",
  "_2:30am": "2:30am",
  _3am: "3:00am",
  "_3:30am": "3:30am",
  _4am: "4:00am",
  "_4:30am": "4:30am",
  _5am: "5:00am",
  "_5:30am": "5:30am",
  _6am: "6:00am",
  "_6:30am": "6:30am",
  _7am: "7:00am",
  "_7:30am": "7:30am",
  _8am: "8:00am",
  "_8:30am": "8:30am",
});

const WEEK = Object.freeze({
  monday: {
    en: "Monday",
    ar: "الاثنين",
  },
  tuesday: {
    en: "Tuesday",
    ar: "يوم الثلاثاء",
  },
  wednesday: {
    en: "Wednesday",
    ar: "الأربعاء",
  },
  thursday: {
    en: "Thursday",
    ar: "يوم الخميس",
  },
  friday: {
    en: "Friday",
    ar: "جمعة",
  },
  saturday: {
    en: "Saturday",
    ar: "السبت",
  },
  sunday: {
    en: "Sunday",
    ar: "الأحد",
  },
});

const COMBINED_DAYS = Object.freeze({
  // "weekDays": {
  //     "en":"Weekdays",
  //     "ar":"أيام الأسبوع",
  // },
  // "weekEnds": {
  //     "en":"Weekends",
  //     "ar":"عطلات نهاية الأسبوع",
  // },
  // "wholeWeek": {
  //     "en":"Whole week",
  //     "ar":"كل الأسبوع",
  // },

  ...WEEK,
});

const CITIES = Object.freeze([
  { id: "Riyadh", name: "Riyadh" },
  { id: "Jeddah", name: "Jeddah" },
  { id: "Makkah", name: "Makkah" },
  { id: "Madinah", name: "Madinah" },
  { id: "Dammam", name: "Dammam" },
  { id: "Taif", name: "Taif" },
  { id: "Al Hufūf", name: "Al Hufūf" },
  { id: "Buraydah", name: "Buraydah" },
  { id: "Al Ḩillah", name: "Al Ḩillah" },
  { id: "Tabūk", name: "Tabūk" },
  { id: "Khamīs Mushayţ", name: "Khamīs Mushayţ" },
  { id: "Ḩā’il", name: "Ḩā’il" },
]);

const CITY_NAMES = Object.freeze({
  Medina: "Madinah",
  Mecca: "Makkah",
  "At Taif": "Taif",
});

const CAR_MODEL_YEARS = Object.freeze([
  "Before 2016",
  "2016",
  "2017",
  "2018",
  "2019",
  "2020",
  "2021",
  "2022",
  "2023",
  "2024",
]);

const SOURCES = Object.freeze([
  { id: "", name: "Select Source" },
  { id: "Website", name: "Website" },
  { id: "Social Media", name: "Social Media" },
  { id: "From A Friend", name: "From A Friend" },
  { id: "CarHub", name: "CarHub" },
  { id: "Others", name: "Others" },
]);

const SOURCES_AR = Object.freeze([
  { id: "", name: "اختر مصدر" },
  { id: "Website", name: "موقع إلكتروني" },
  { id: "Social Media", name: "وسائل التواصل الاجتماعي" },
  { id: "From A Friend", name: "من صديق" },
  { id: "CarHub", name: "محور السيارة" },
  { id: "Others", name: "آحرون" },
]);

const CND_ORDER_STATUS = Object.freeze({
  notStarted: "Not Started",
  pickedFromCustomer: "Picked From Customer",
  receivedAtCenter: "Received At Center",
  pickedFromCenter: "Picked From Center",
  deliveredToCustomer: "Delivered To Customer",
});

export default {
  ALL_TIMESLOTS,
  NINE_HOURS_SLOTS,
  CLOSED_DAY_TIMESLOTS,
  WEEK,
  COMBINED_DAYS,
  CITIES,
  CITY_NAMES,
  HOURLY_SLOTS,
  CAR_MODEL_YEARS,
  SOURCES,
  SOURCES_AR,
  CND_ORDER_STATUS
};
