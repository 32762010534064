import React, { useEffect, useRef, useState } from "react";
import Layout from "../../shared/Layout";
import HeaderTitle from "../../shared/HeaderTitle";
import { FormattedMessage, injectIntl } from "react-intl";
import { Formik, Form } from "formik";
import FieldRow from "../../shared/FieldRow";
import Auth from "../../shared/Auth";
import { NotificationManager } from "react-notifications";
import _ from "lodash";
import CheckAccess from "../../shared/CheckAccess";
import { ActivitiesEnum } from "../../../staticData/Activities";
import { withAuthContext } from "../../../state/AuthContext";
import withRouter from "../../../util/withRouter";
import services from "../../../services";
import appState from "../../../state/AppStateContainer";
import moment from "moment";
import ReasonsModal from "../../shared/ReasonsModal";
import ServiceAdvisorListing from "../../order/ServiceAdvisorListing";
import { Button } from "react-bootstrap";

const TrackFeedbackForm = (props) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState("");
  const [showReasonModal, setShowReasonModal] = useState(false);
  const [reasons, setReasons] = useState([]);
  const [showServiceAdvisor, setShowServiceAdvisor] = useState(false);
  const [serviceAdvisor, setServiceAdvisor] = useState([]);

  useEffect(() => {
    getFastCollectData();
  }, []);

  const getFastCollectData = async () => {
    setLoading(true);
    try {
      let response = await services.getFastCollectFeedback(
        props?.match?.params?.id
      );
      response = response[0];

      setData(response || "");
      if (
        response?.isCustomerSatisfied === false ||
        response?.isCustomerSatisfied === true ||
        response?.reasons?.length > 0
      ) {
        await getAllServiceAdvisorAgainstBranch(response?.branch[0]?._id);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const getAllServiceAdvisorAgainstBranch = async (branchId) => {
    const response = await services.getAllServiceAdvisor(branchId);
    setServiceAdvisor(response);
  };

  const handleCloseReasonsModal = () => {
    setShowReasonModal(false);
  };

  const handleAssignServiceAdvisor = async (values) => {
    setLoading(true);
    try {
      const response = await services.assignServiceAdvisorTrack({
        id: data?._id,
        ...values,
        isCustomerSatisfied: data?.isCustomerSatisfied,
      });

      NotificationManager.success(
        props.intl.formatMessage({
          id: "global.operationSuccessful",
          defaultMessage: "Operation Successful",
        }),
        props.intl.formatMessage({
          id: "global.success",
          defaultMessage: "Success",
        })
      );
    } catch (e) {
      NotificationManager.error(
        props.intl.formatMessage({
          id: _.get(e, "response.data.error", "error.unexpectedError"),
          defaultMessage: "An unexpected error has occurred",
        }),
        props.intl.formatMessage({
          id: "global.error",
          defaultMessage: "Error",
        })
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout noLayout={props.noLayout} loading={loading}>
      <HeaderTitle
        id="track.trackFeedback"
        defaultMessage="Track Car Repair Feedback"
      />

      <div className="dashboard-table-container card-layout">
        <Auth requireAuth={true} roles={["sadmin", "wsadmin", "spadmin"]}>
          <Formik
            enableReinitialize
            initialValues={{
              customerName: data
                ? `${data?.userDetails[0]?.firstName} ${data?.userDetails[0]?.lastName}`
                : "",
              branchName: data
                ? data?.branch[0]?.name["value_" + appState?.state?.language]
                : "",
              make: data?.make ? data?.make : "",
              model: data?.model ? data?.model : "",
              carPlateNumber: data?.carPlateNumber ? data?.carPlateNumber : "",
              phoneNumber: data?.phoneNumber ? data?.phoneNumber : "",
              isCustomerSatisfied: data?.isCustomerSatisfied
                ? data?.isCustomerSatisfied
                : false,
              reason:
                data?.reasons?.length > 0 ? data?.reasons[0]["notes"] : "",
              reasonCreationTime:
                data?.reasons?.length > 0
                  ? moment(
                      data?.reasons[data?.reasons?.length - 1]["createdTime"]
                    )?.format("DD/MM/YYYY HH:mm")
                  : "",
            }}
          >
            {(formikProps) => (
              <div className="panel-primary">
                <header className="panel-title">
                  <FormattedMessage
                    id="track.feedbackDetails"
                    defaultMessage="Feedback Details"
                  />
                </header>

                <Form>
                  <div className="dashboard-row">
                    <FieldRow
                      type="text"
                      name="customerName"
                      labelId="track.customerName"
                      defaultLabel="Customer Name"
                      placeholderId="track.customerName"
                      defaultPlaceholder="Customer Name"
                      formikProps={formikProps}
                      disabled={true}
                    />
                    <FieldRow
                      type="text"
                      name="phoneNumber"
                      labelId="track.customerPhoneNumber"
                      defaultLabel="Phone Number"
                      placeholderId="track.customerPhoneNumber"
                      defaultPlaceholder="Phone Number"
                      formikProps={formikProps}
                      disabled={true}
                    />
                    <FieldRow
                      type="text"
                      name="branchName"
                      labelId="track.branchName"
                      defaultLabel="Branch name"
                      placeholderId="track.branchName"
                      defaultPlaceholder="Branch name"
                      formikProps={formikProps}
                      disabled={true}
                    />
                    <FieldRow
                      type="text"
                      name="make"
                      labelId="track.make"
                      defaultLabel="Make"
                      placeholderId="track.make"
                      defaultPlaceholder="Make"
                      formikProps={formikProps}
                      disabled={true}
                    />
                    <FieldRow
                      type="text"
                      name="model"
                      labelId="track.model"
                      defaultLabel="Model"
                      placeholderId="track.model"
                      defaultPlaceholder="Model"
                      formikProps={formikProps}
                      disabled={true}
                    />
                    <FieldRow
                      type="text"
                      name="carPlateNumber"
                      labelId="track.carPlateNumber"
                      defaultLabel="Car Plate number"
                      placeholderId="track.carPlateNumber"
                      defaultPlaceholder="Car Plate number"
                      formikProps={formikProps}
                      disabled={true}
                    />

                    {data?.reasons?.length ? (
                      <>
                        <FieldRow
                          type="textarea"
                          name="reason"
                          labelId="track.reason"
                          defaultLabel="Reason"
                          placeholderId="track.reason"
                          defaultPlaceholder="Reason"
                          formikProps={formikProps}
                          disabled={true}
                        />
                        {data?.reasons?.length ? (
                          <div className="row">
                            <div className="col-md-2 col-sm-2 col-xs-12"></div>
                            <div className="input-group col-md-8 col-sm-8 col-xs-12">
                              <p
                                className="reason-history"
                                onClick={() => {
                                  setShowReasonModal(true);
                                  setReasons(data?.reasons);
                                }}
                              >
                                <FormattedMessage
                                  id="track.checkFeedbackReasons"
                                  defaultMessage="Check Reasons History"
                                />
                              </p>
                            </div>
                          </div>
                        ) : null}

                        <FieldRow
                          type="text"
                          name="reasonCreationTime"
                          labelId="track.reasonCreationTime"
                          defaultLabel="Created At"
                          placeholderId="track.reasonCreationTime"
                          defaultPlaceholder="Created At"
                          formikProps={formikProps}
                          disabled={true}
                        />
                      </>
                    ) : null}
                  </div>
                </Form>

                {data?.isCustomerSatisfied === false ||
                data?.isCustomerSatisfied === true ||
                data?.reasons?.length ? (
                  <div className="dashboard-row branch-service-advisor">
                    <CheckAccess
                      activityId={
                        ActivitiesEnum.view_assign_service_advisor_button
                      }
                    >
                      <div className="service-advisor-btn-wrapper">
                        <Button
                          variant="danger"
                          onClick={() => setShowServiceAdvisor(true)}
                          disabled={data?.serviceAdvisorId}
                        >
                          {props.intl.formatMessage({
                            id: "order.assignServiceAdvisor",
                            defaultMessage: "Assign Service Advisor",
                          })}
                        </Button>
                      </div>
                      {(showServiceAdvisor || data?.serviceAdvisorId) && (
                        <div className="service-advisor-form">
                          <ServiceAdvisorListing
                            {...{
                              intl: props.intl,
                              handleAssignServiceAdvisor,
                              serviceAdvisor,
                              selectedSA: data?.serviceAdvisorId,
                              disabled: false,
                              props,
                            }}
                          />
                        </div>
                      )}
                    </CheckAccess>
                  </div>
                ) : null}

                <div className="dashboard-row -nobottom">
                  <Button
                    variant="link"
                    onClick={() => props.navigate("/track-feedbacks")}
                  >
                    {props.intl.formatMessage({
                      id: "global.cancel",
                      defaultMessage: "Cancel",
                    })}
                  </Button>
                </div>
              </div>
            )}
          </Formik>
        </Auth>
      </div>
      <ReasonsModal
        {...{
          show: showReasonModal,
          onClose: handleCloseReasonsModal,
          reasons,
        }}
      />
    </Layout>
  );
};
export default withAuthContext(withRouter(injectIntl(TrackFeedbackForm)));
