import React, { Component } from "react";
import { FormattedMessage, FormattedNumber, injectIntl } from "react-intl";
import classNames from "classnames";
import Layout from "../shared/Layout";
import services from "../../services";
import _ from "lodash";
import Auth from "../shared/Auth";
import { Link } from "react-router-dom";
import withRouter from "../../util/withRouter";
import ActionMenu from "../shared/ActionMenu";
import { getLocalDateTime } from "../../util";
import appState from "../../state/AppStateContainer";
import moment from "moment";
import HeaderTitle from "../shared/HeaderTitle";
import { ActivitiesEnum } from "../../staticData/Activities";
import CheckAccess from "../shared/CheckAccess";
import Table from "../shared/Table";

export class PromoList extends Component {
  state = {
    data: [],
    promos: [],
    hasMore: true,
    pageIndex: -1,
    pageSize: 10,
    searchFilter: "",
    statusFilter: "all",
    updatingRowId: "",
    count: 0,
  };

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      if (!this.props.match.params.isFormOpen) {
        if (_.get(this, "props.location.state.isSubmitted") === true) {
          this.setState({
            pageIndex: -1,
            data: [],
            hasMore: true,
            searchFilter: this.searchInput.value,
            statusFilter: this.statusSelect.value,
            disableFlag: true,
            updatingRowId: "",
          });
        } else {
          this.setState({ updatingRowId: "" });
        }
      }
    }
  }

  async loadMore() {
    this.setState(
      (currentState) => {
        return {
          pageIndex: currentState.pageIndex + 1,
          hasMore: false,
          loading: true,
        };
      },
      async () => {
        const options = {};
        const pageIndex = 0;
        options.progressBar = true;
        const pageData = await services.getPromos(
          this.state.searchFilter,
          this.state.statusFilter,
          this.state.pageIndex,
          this.state.pageSize,
          options
        );
        const summary =
          pageIndex === 0
            ? await services.getPromos(
                this.state.searchFilter,
                this.state.statusFilter,
                undefined,
                undefined,
                { summary: true }
              )
            : { count: this.state.count };
        this.setState((currentState) => {
          return {
            data: currentState.data.concat(pageData),
            hasMore: pageData.length > 0,
            disableFlag: false,
            loading: false,
            count: summary.count,
          };
        });
      }
    );
  }

  resetData(e) {
    this.setState({
      pageIndex: -1,
      data: [],
      hasMore: true,
      searchFilter: this.searchInput.value,
      statusFilter: this.statusSelect.value,
      disableFlag: true,
    });
    if (e) e.preventDefault();
  }

  resetPage(e) {
    this.setState({
      pageIndex: -1,
      data: [],
      hasMore: true,
      searchFilter: "",
      statusFilter: "all",
      disableFlag: true,
    });
    this.searchInput.value = "";
    this.statusSelect.value = "all";
    e.preventDefault();
  }

  render() {
    const { intl } = this.props;
    const { loading, count } = this.state;

    return (
      <Auth requireAuth={true} roles={["sadmin"]}>
        <Layout hidden={this.props.match.params.isFormOpen} loading={loading}>
          <HeaderTitle id="promo.promos" defaultMessage="Promotion Codes" />
          <div className="table-header clearfix">
            <form className="row" onSubmit={this.resetData.bind(this)}>
              <div className="col-xs-3 pull-right">
                <div className="panel-group-buttons pull-right">
                  <CheckAccess
                    activityId={
                      ActivitiesEnum.promo_management__add_new_promo_code
                    }
                  >
                    <Link className="btn btn-special " to="/promo">
                      <span className="fa fa-plus" />{" "}
                      <FormattedMessage
                        id="promo.newPromo"
                        defaultMessage="Create Promo"
                      />
                    </Link>
                  </CheckAccess>
                </div>
              </div>
              <CheckAccess
                activityId={ActivitiesEnum.promo_management__filter_and_search}
              >
                <div className="col-md-2 col-sm-4 col-xs-6">
                  <div className="has-feedback">
                    <input
                      type="text"
                      ref={(c) => {
                        this.searchInput = c;
                      }}
                      className="form-control"
                      placeholder={intl.formatMessage({
                        id: "global.search",
                        defaultMessage: "Search",
                      })}
                    />
                    <i className="glyphicon glyphicon-search form-control-feedback" />
                  </div>
                </div>
                <div className="col-md-2 col-sm-4 col-xs-6">
                  <select
                    ref={(c) => {
                      this.statusSelect = c;
                    }}
                    onChange={this.resetData.bind(this)}
                    className="form-control"
                  >
                    <option value="all">
                      {intl.formatMessage({
                        id: "operator.status",
                        defaultMessage: "Status",
                      })}
                    </option>
                    <option value="active">
                      {intl.formatMessage({
                        id: "operator.active",
                        defaultMessage: "Active",
                      })}
                    </option>
                    <option value="suspended">
                      {intl.formatMessage({
                        id: "operator.suspended",
                        defaultMessage: "Suspended",
                      })}
                    </option>
                  </select>
                </div>
                <div className="col force--inline">
                  <div className="tooltip-master">
                    <button
                      href="javascript:;"
                      className="btn btn-primary"
                      onClick={this.resetData.bind(this)}
                      disabled={this.state.disableFlag}
                    >
                      <i className="material-icons">search</i>
                    </button>
                    <span className="tooltiptext">
                      <FormattedMessage
                        id="global.filter"
                        defaultMessage="Filter"
                      />
                    </span>
                  </div>
                </div>
                <div className="col force--inline">
                  <div className="tooltip-master">
                    <button
                      href="javascript:;"
                      className="btn btn-primary"
                      onClick={this.resetPage.bind(this)}
                      disabled={this.state.disableFlag}
                    >
                      <i className="material-icons">settings_backup_restore</i>
                    </button>
                    <span className="tooltiptext">
                      <FormattedMessage
                        id="global.resetFilter"
                        defaultMessage="Reset Filter"
                      />
                    </span>
                  </div>
                </div>
              </CheckAccess>
            </form>
            <div className="row">
              <div className="col-md-12">
                <FormattedMessage
                  id="global.totalCount"
                  defaultMessage="Total Count"
                />

                <strong>
                  {" "}
                  <FormattedNumber
                    style="decimal"
                    maximumFractionDigits={0}
                    minimumFractionDigits={0}
                    value={count}
                  />{" "}
                </strong>
              </div>
            </div>
          </div>
          <div>
            <Table
              loadMore={this.loadMore.bind(this)}
              hasMore={this.state.hasMore}
              caption={
                (!this.state.data || this.state.data.length == 0) && (
                  <caption
                    style={{
                      captionSide: "bottom",
                      textAlign: "center",
                      margin: "20px",
                      color: "darkgray",
                    }}
                  >
                    <FormattedMessage
                      id="global.noData"
                      defaultMessage="No data available"
                    />
                  </caption>
                )
              }
              headerRow={
                <tr onClick={() => {}}>
                  <th>
                    <FormattedMessage
                      id="promo.promoCode"
                      defaultMessage="Promo code"
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="promo.discountInPercentage"
                      defaultMessage="Discount In Percentage"
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="promo.discountInFixedAmount"
                      defaultMessage="Discount In Fixed Amount"
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="promo.createdAt"
                      defaultMessage="Created At"
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="promo.startDate"
                      defaultMessage="Start Date"
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="promo.endDate"
                      defaultMessage="End date"
                    />
                  </th>
                  {/* <th>
                    <FormattedMessage
                      id="promo.frequencyPerUser"
                      defaultMessage="Frequency Per User"
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="promo.frequencyPerPromo"
                      defaultMessage="Frequency Per Promo"
                    />
                  </th> */}
                  <th>
                    <FormattedMessage
                      id="promo.promoConsumed"
                      defaultMessage="Promo Code Consumed"
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="promo.status"
                      defaultMessage="Status"
                    />
                  </th>
                  <th style={{ width: "20px" }} />
                </tr>
              }
            >
              {this.state.data.map((w) => (
                <tr
                  key={w._id}
                  onClick={() => {
                    this.setState(
                      {
                        updatingRowId: w._id,
                      },
                      () => {
                        this.props.navigate(`/promo/${w._id}`);
                      }
                    );
                  }}
                  className="cases-trow link-row"
                >
                  <td>{w.promoCode}</td>
                  <td>
                    <FormattedNumber
                      style="decimal"
                      maximumFractionDigits={2}
                      minimumFractionDigits={2}
                      value={w.percentageOffer || 0}
                    />
                  </td>
                  <td>
                    <FormattedNumber
                      style="decimal"
                      maximumFractionDigits={2}
                      minimumFractionDigits={2}
                      value={w.fixedAmountOffer || 0}
                    />
                  </td>
                  <td>
                    {w.createdAt ? getLocalDateTime(w.createdAt) : undefined}
                  </td>
                  <td>{moment(w.start_date).format("MMM Do YY")}</td>
                  <td>{moment(w.end_date).format("MMM Do YY")}</td>
                  {/* <td>{w.frequencyPerUser}</td>
                  <td>{w.frequencyPerPromo}</td> */}
                  <td>{w.usage || 0}</td>
                  <td
                    className={classNames({
                      "col-status-green": w.isActive,
                      "col-status-red": !w.isActive,
                    })}
                  >
                    <a href="javascript:;">
                      {w.isActive && (
                        <FormattedMessage
                          id="operator.active"
                          defaultMessage="Active"
                        />
                      )}
                      {!w.isActive && (
                        <FormattedMessage
                          id="operator.suspended"
                          defaultMessage="Suspended"
                        />
                      )}
                    </a>
                  </td>
                  <td style={{ position: "relative" }}>
                    <ActionMenu
                      visible={this.state.visible}
                      menuStyle={{
                        backgroundColor: "#E9E9E9",
                      }}
                    >
                      <CheckAccess
                        activityId={
                          ActivitiesEnum.promo_management__update_promotion_code_edit
                        }
                      >
                        <Link
                          style={{ margin: "10px", cursor: "pointer" }}
                          to={`/promo/${w._id}`}
                        >
                          <span className="fa fa-pencil" />{" "}
                          <FormattedMessage
                            id="global.edit"
                            defaultMessage="Edit"
                          />
                        </Link>
                      </CheckAccess>
                      <a
                        style={{ margin: "10px", cursor: "pointer" }}
                        onClick={() => {
                          this.handleLoginAs(w.adminId);
                        }}
                      />
                      {appState.state.token?.role === "sadmin" && (
                        <CheckAccess
                          activityId={ActivitiesEnum.user_activity__all}
                        >
                          <Link
                            style={{
                              margin: "10px",
                              cursor: "pointer",
                            }}
                            to={`/userActivities/${w._id}`}
                          >
                            <span className="fa fa-history" />{" "}
                            <FormattedMessage
                              id="global.viewHistory"
                              defaultMessage="View History"
                            />
                          </Link>
                        </CheckAccess>
                      )}
                    </ActionMenu>
                  </td>
                </tr>
              ))}
            </Table>
          </div>
        </Layout>
      </Auth>
    );
  }
}

export default withRouter(injectIntl(PromoList));
