import BaseService from "./baseService";
import axios from "axios";

class QuotationService extends BaseService {
  async getQuotations(
    searchFilter,
    statusFilter,
    pageIndex,
    pageSize,
    options = {},
    summary
  ) {
    const result = await axios.get("/api/quotations", {
      params: {
        searchFilter,
        statusFilter,
        pageIndex,
        pageSize,
        options,
        summary
      },
      progressBar: options?.progressBar,
    });
    return result.data?.response;
  }

  async getQuotation(id, options = {}) {
    const result = await axios.get("/api/quotation", {
      params: { id },
      progressBar: options.progressBar,
    });
    return result.data?.response;
  }

  async addQuotation(data) {
    const formData = new FormData();
    for (let key in data) {
      formData.append(key, data[key]);
    }
    const result = await axios.post("/api/quotation", formData);
    return result.data?.response;
  }

  async updateQuotation(data) {
    const formData = new FormData();
    for (let key in data) {
      formData.append(key, data[key]);
    }
    const result = await axios.put("/api/quotation", formData);
    return result.data?.response;
  }

  async getRequestedQuotes(
    searchFilter,
    statusFilter,
    pageIndex,
    pageSize,
    fromDate,
    toDate,
    phoneNumber,
    options = {},
    summary,
  ) {
    const result = await axios.get("/api/requested-quotes", {
      params: {
        searchFilter,
        statusFilter,
        pageIndex,
        pageSize,
        fromDate,
        toDate,
        phoneNumber,
        summary,
        isFromCms: true
      },
      progressBar: options.progressBar,
    });
    return result.data?.response;
  }

  async getRequestQuoteById(id) {
    const result = await axios.get("/api/requested-quote/" + id);
    return result.data?.response;
  }

  async updateQuotationRequest(data) {
    const formData = new FormData();
    for (const key in data) {
      formData.append(key, data[key]);
    }
    const result = await axios.put("/api/requested-quote", formData);
    return result.data?.response;
  }
}

export default new QuotationService();
