import InfiniteScroll from "react-infinite-scroller";
import services from "../../services";

export default class InfiniteScrollOverride extends InfiniteScroll {
  /**
   * We are overriding the getParentElement function to use a custom element as the scrollable element
   *
   * @param {any} el the scroller domNode
   * @returns {any} the parentNode to base the scroll calulations on
   *
   * @memberOf InfiniteScrollOverride
   */
  getParentElement(el) {
    return (

      services.mobileAndTabletcheck() ? document.querySelector(".body-wrapper") : this.props.parentEl ||
        document.querySelector(this.props.parentSelector || "#rightSection")
    );
  }

  render() {
    return super.render();
  }
}
