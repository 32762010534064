import React, { Component, createRef } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Form, Formik } from "formik";
import { Yup } from "../../util";
import Layout from "../shared/Layout";
import Blog from "../../services/blogService";
import { NotificationManager } from "react-notifications";
import _ from "lodash";
import Auth from "../shared/Auth";
import FieldRow from "../shared/FieldRow";
import withRouter from "../../util/withRouter";
import appState from "../../state/AppStateContainer";
import HeaderTitle from "../shared/HeaderTitle";
import { withAuthContext } from "../../state/AuthContext";
import { Button } from "react-bootstrap";

export class BlogTagsForm extends Component {
  constructor() {
    super();
    this.formik = createRef();
  }

  state = {
    data: {},
    loading: true,
  };

  async componentDidMount() {
    try {
      this.subscription = appState.emitter.addListener(
        "onLanguageChange",
        () => {
          this.formik.validateForm();
        }
      );
      
      if (this.props.match.params.id) {
        const tags = await Blog.getBlogTags({
          _id: this.props.match.params.id,
        });
        this.setState({ data: tags.response });
      }
    } catch (e) {
      this.props.navigate("/error", {
        error: _.get(e, "response.data.error", "error.unexpectedError"),
      });
    } finally {
      this.setState({ loading: false });
    }
  }

  componentWillUnmount() {
    this.subscription.remove();
  }

  async handleSubmit(values, { setSubmitting }) {
    setSubmitting(true);
    this.setState({ loading: true });
    try {
      await Blog.submitBlogTag(values, this.props.match.params.id);
      this.props.navigate("/blog-tags");

      NotificationManager.success(
        this.props.intl.formatMessage({
          id: "global.operationSuccessful",
          defaultMessage: "Operation Successful",
        }),
        this.props.intl.formatMessage({
          id: "global.success",
          defaultMessage: "Success",
        })
      );
    } catch (e) {
      NotificationManager.error(
        this.props.intl.formatMessage({
          id: _.get(e, "response.data.error", "error.unexpectedError"),
          defaultMessage: "An unexpected error has occurred",
        }),
        this.props.intl.formatMessage({
          id: "global.error",
          defaultMessage: "Error",
        })
      );
    } finally {
      setSubmitting(false);
      this.setState({ loading: false });
    }
  }

  _renderBody(formikProps) {
    const { isSubmitting } = formikProps;

    return (
      <Layout loading={this.state.loading}>
        <HeaderTitle id="blogs.blogTags" defaultMessage="Blog Tags" />
        <div className="dashboard-table-container card-layout">
          <div className="panel-primary">
            <header className="panel-title">
              {!this.props.match.params.id ? (
                <FormattedMessage
                  id="blogs.addBlogTag"
                  defaultMessage="Add Blog Tag"
                />
              ) : (
                <FormattedMessage
                  id="blogs.updateBlogTag"
                  defaultMessage="Update Blog Tag"
                />
              )}
            </header>
            <Form>
              <div className="dashboard-row">
                <FieldRow
                  type="text"
                  name="tag_en"
                  labelId="blogs.blogTagEn"
                  defaultLabel="Blog Tag (English)"
                  placeholderId="blogs.blogTagEn"
                  defaultPlaceholder="Blog Tag (English)"
                  formikProps={formikProps}
                  showRequiredFlag={true}
                />
                <FieldRow
                  type="text"
                  name="tag_ar"
                  labelId="blogs.blogTagAr"
                  defaultLabel="Blog Tag (Arabic)"
                  placeholderId="blogs.blogTagAr"
                  defaultPlaceholder="Blog Tag (Arabic)"
                  formikProps={formikProps}
                  showRequiredFlag={true}
                />
                <br />
                <FieldRow
                  type="checkbox"
                  name="isActive"
                  labelId="blogs.isActive"
                  defaultLabel="Active"
                  placeholderId="blogs.isActive"
                  formikProps={formikProps}
                />
              </div>
              <div className="dashboard-row -nobottom text-right">
                {this.props.authContext.authorized && (
                  <input
                    type="submit"
                    className="btn btn-primary"
                    value={this.props.intl.formatMessage({
                      id: "global.submit",
                      defaultMessage: "Submit",
                    })}
                    disabled={isSubmitting}
                  />
                )}
                <Button
                  variant="link"
                  onClick={() => this.props.navigate("/blog-tags")}
                  disabled={isSubmitting}
                >
                  {this.props.intl.formatMessage({
                    id: "global.cancel",
                    defaultMessage: "Cancel",
                  })}
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Layout>
    );
  }

  render() {
    const { intl } = this.props;
    let data = this.state.data;
    if (data.length) data = data[0];

    return (
      <Auth requireAuth={true} roles={["sadmin"]}>
        <Formik
          innerRef={(f) => {
            this.formik = f;
          }}
          enableReinitialize
          initialValues={{
            tag_en: data.name?.en,
            tag_ar: data.name?.ar,
            isActive: data.isActive || false,
          }}
          validationSchema={Yup.object().shape({
            tag_en: Yup.string()
              .hasNoArabic(
                intl.formatMessage({
                  id: "error.arabicCharactersAreNotAllowedInThisField",
                  defaultMessage:
                    "Arabic characters are not allowed in this field",
                })
              )
              .label(
                intl.formatMessage({
                  id: "blogs.blogTag",
                  defaultMessage: "Blog tag",
                })
              )
              .trim()
              .required(),
            tag_ar: Yup.string()
              .hasNoEnglish(
                intl.formatMessage({
                  id: "error.englishCharactersAreNotAllowedInThisField",
                  defaultMessage:
                    "English characters are not allowed in this field",
                })
              )
              .label(
                intl.formatMessage({
                  id: "blogs.blogTag",
                  defaultMessage: "Blog tag",
                })
              )
              .trim()
              .required(),
          })}
          onSubmit={this.handleSubmit.bind(this)}
        >
          {this._renderBody.bind(this)}
        </Formik>
      </Auth>
    );
  }
}

export default withAuthContext(withRouter(injectIntl(BlogTagsForm)));
