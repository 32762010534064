import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Modal } from "react-bootstrap";
import _ from "lodash";
import constants from "../../staticData/constants";
import moment from "moment";

const AddExpressProgress = ({
  show,
  onCancel,
  data,
  setCarProgressAction,
  handleCarProgress,
}) => {
  const [isActiveState, setIsActive] = useState(false);

  return (
    <Modal show={show} onHide={() => onCancel("view")}>
      <Modal.Header closeButton={true}>
        <h4>
          <FormattedMessage
            id="fastCollect.viewRepairHistory"
            defaultMessage="View car repair history"
          />
        </h4>
      </Modal.Header>
      <Modal.Body>
        {data?.messages?.length ? (
          <div className="fast-collect">
            {data?.messages?.map(
              (
                { notes, images, video, createdTime, seen, _id, isActive },
                i
              ) => {
                return (
                  <div className="content-holder" key={i}>
                    <div className="meta-data">
                      <span>
                        {moment(createdTime).format("MMM DD YYYY, h:mm:ss a")}
                      </span>
                      <i className={seen ? "fa fa-eye" : "fa fa-eye-slash"} />
                    </div>
                    <span>{notes}</span>
                    {(images || video) && (
                      <>
                        <div className="media images-wrapper-repair-progress">
                          {images &&
                            images.map((image) => (
                              <img
                                src={`/api/util/image?key=${image?.value}&accesskey=${constants.MEDIA_KEY}`}
                                onClick={() => {
                                  this.setState({
                                    lighboxSrc: image,
                                  });
                                }}
                              />
                            ))}
                        </div>
                        <div className="media">
                          {video && (
                            <video controls>
                              <source
                                src={`/api/util/image?key=${video}&accesskey=${constants.MEDIA_KEY}`}
                                type="video/mp4"
                              />
                            </video>
                          )}
                        </div>
                      </>
                    )}
                    <div className="active-wrapper">
                      <label>
                        Active
                        <input
                          type="checkbox"
                          defaultChecked={isActive}
                          onChange={(e) => {
                            setCarProgressAction({
                              messageId: _id,
                              progressId: data?._id,
                              isActive: e?.target?.checked,
                            });
                            setIsActive(e?.target?.checked);
                          }}
                        />
                      </label>
                      <br />
                      <div className="save-btn-wrapper">
                        <button
                          className="btn btn-primary"
                          onClick={() => {
                            setCarProgressAction({
                              messageId: _id,
                              progressId: data?._id,
                              isActive: isActiveState,
                            });
                            handleCarProgress();
                          }}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                );
              }
            )}
          </div>
        ) : null}
      </Modal.Body>
    </Modal>
  );
};

export default AddExpressProgress;
