import React, { useState, useEffect, useRef } from "react";
import { FormattedMessage, FormattedNumber, injectIntl } from "react-intl";
import Layout from "../shared/Layout";
import services from "../../services";
import _ from "lodash";
import Auth from "../shared/Auth";
import withRouter from "../../util/withRouter";
import Table from "../shared/Table";
import appState from "../../state/AppStateContainer";
import HeaderTitle from "../shared/HeaderTitle";
import CheckAccess from "../shared/CheckAccess";
import { ActivitiesEnum } from "../../staticData/Activities";
import moment from "moment";
import { SingleDatePicker } from "react-dates";
import ExportToExcel from "../shared/ExportToExcel";

const EstimationRequestList = (props) => {
  const { intl } = props;

  const [count, setCount] = useState(0);
  const [data, setData] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [pageIndex, setPageIndex] = useState(-1);
  const [pageSize, setPageSize] = useState(10);
  const [searchFilter, setSearchFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("all");
  const [loading, setLoading] = useState(false);
  const [disableFlag, setDisableFlag] = useState(false);
  const [approvalFilter, setApprovalFilter] = useState("all");
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [focusedFrom, setFocusedFrom] = useState(false);
  const [focusedTo, setFocusedTo] = useState(false);
  const [exportExcelLoading, setExportExcelLoading] = useState(false);

  const searchInputRef = useRef();
  const prevPropsRef = useRef();
  const statusRef = useRef();

  useEffect(() => {
    prevPropsRef.current = props;
  }, []);

  const prevProps = prevPropsRef.current;

  useEffect(() => {
    if (props.location.pathname !== prevProps?.location?.pathname) {
      if (!props.match.params.isFormOpen) {
        if (_.get(props.location, "state.isSubmitted") === true) {
          setPageIndex(-1);
          setData([]);
          setHasMore(true);
          setSearchFilter(searchInputRef.current.value);
          setStatusFilter(statusRef.current.value);
          setDisableFlag(true);
        }
      }
    }
  }, [props.location.pathname]);

  useEffect(() => {
    const subscription = appState.emitter.addListener(
      "onLanguageChange",
      () => {
        setFrom(
          from ? moment(from).locale(appState.state.language) : undefined
        );
        setTo(to ? moment(to).locale(appState.state.language) : undefined);
      }
    );

    return () => {
      subscription.remove();
    };
  }, [props.location.state, from, to]);

  const onExport = async (startDate, endDate) => {
    if (!startDate) return;
    if (!endDate) {
      endDate = startDate;
    }
    setLoading(true);
    try {
      setExportExcelLoading(true);
      await services.downloadEstimationReport(
        searchFilter,
        statusFilter,
        moment(startDate)
          .utcOffset(60 * 3)
          .startOf("day")
          .toString(),
        moment(endDate)
          .utcOffset(60 * 3)
          .endOf("day")
          .toString()
      );
    } catch (err) {}
    setLoading(false);
  };

  const loadMore = async () => {
    setLoading(true);
    setPageIndex((prevState) => prevState + 1);

    setHasMore(false);
    const _pageIndex = pageIndex + 1;

    const options = {
      progressBar: true,
      alphabeticalOrder: true,
      fromDate: from ? moment(from).startOf("day").toString() : null,
      toDate: to ? moment(to).endOf("day").toString() : null,
    };

    const pageData = await services.getRequestEstimation(
      searchFilter,
      statusFilter,
      _pageIndex,
      pageSize,
      from ? moment(from).startOf("day").toString() : null,
      to ? moment(to).endOf("day").toString() : null,
      options
    );
    let summary = { count };

    if (_pageIndex === 0) {
      summary = await services.getRequestEstimation(
        searchFilter,
        statusFilter,
        undefined,
        undefined,
        from ? moment(from).startOf("day").toString() : null,
        to ? moment(to).endOf("day").toString() : null,
        options,
        summary
      );
    }

    setData((prevData) => [...prevData, ...pageData]);
    setHasMore(pageData.length > 0);
    setDisableFlag(false);
    setLoading(false);
    if (_pageIndex === 0) {
      setCount(summary);
    }
  };

  const resetData = (e) => {
    setPageIndex(-1);
    setData([]);
    setHasMore(true);
    setSearchFilter(searchInputRef.current.value);
    setStatusFilter(statusRef.current.value);
    setDisableFlag(true);
    if (e) e.preventDefault();
  };

  const resetPage = (e) => {
    setPageIndex(-1);
    setData([]);
    setHasMore(true);
    setSearchFilter("");
    setApprovalFilter("all");
    setStatusFilter("all");
    setDisableFlag(true);
    setFrom("");
    setTo("");

    searchInputRef.current.value = "";
    statusRef.current.value = "all";
    e.preventDefault();
  };

  return (
    <Auth requireAuth={true} roles={["sadmin"]}>
      <Layout hidden={props.match.params.isFormOpen} loading={loading}>
        <HeaderTitle
          id="estimations.estimationRequest"
          defaultMessage="Estimation Requests"
        />
        <div className="table-header clearfix">
          <form className="row" onSubmit={resetData}>
            <div className="col-xs-3 pull-right"></div>
            <div className="col-md-2 col-sm-4 col-xs-6">
              <div className="has-feedback">
                <input
                  type="text"
                  ref={searchInputRef}
                  className="form-control"
                  placeholder={intl.formatMessage({
                    id: "global.search",
                    defaultMessage: "Search",
                  })}
                />
                <i className="glyphicon glyphicon-search form-control-feedback" />
              </div>
            </div>
            <div className="col-md-2 col-sm-4 col-xs-6">
              <select
                ref={statusRef}
                onChange={resetData}
                className="form-control"
              >
                <option value="all">
                  {intl.formatMessage({
                    id: "global.status",
                    defaultMessage: "Status",
                  })}
                </option>
                <option value="Estimation Requested">
                  {intl.formatMessage({
                    id: "global.requested",
                    defaultMessage: "Estimation Requested",
                  })}
                </option>
                <option value="Estimation In Progress">
                  {intl.formatMessage({
                    id: "estimations.inProgress",
                    defaultMessage: "Estimation In progress",
                  })}
                </option>
                <option value="Estimation Completed">
                  {intl.formatMessage({
                    id: "estimations.completed",
                    defaultMessage: "Estimation Completed",
                  })}
                </option>
              </select>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-12 col-xs-6">
              <SingleDatePicker
                id="fromDate"
                placeholder={intl.formatMessage({
                  id: "global.from",
                  defaultMessage: "From",
                })}
                block={true}
                small={true}
                isOutsideRange={() => false}
                numberOfMonths={1}
                initialDate={from}
                isRTL={appState.state.language === "ar" ? true : false}
                date={from}
                onDateChange={(date) => {
                  setFrom(date);
                  resetData();
                }}
                focused={focusedFrom}
                onFocusChange={({ focused }) => setFocusedFrom(focused)}
              />
            </div>
            <div className="col-lg-2 col-md-4 col-sm-12 col-xs-6">
              <SingleDatePicker
                id="toDate"
                block={true}
                placeholder={intl.formatMessage({
                  id: "global.to",
                  defaultMessage: "To",
                })}
                small={true}
                isOutsideRange={() => false}
                numberOfMonths={1}
                initialDate={to}
                isRTL={appState.state.language === "ar" ? true : false}
                date={to}
                onDateChange={(date) => {
                  setTo(date);
                  resetData();
                }}
                focused={focusedTo}
                onFocusChange={({ focused }) => setFocusedTo(focused)}
              />
            </div>
            <div className="col force--inline">
              <div className="tooltip-master">
                <button
                  href="javascript:;"
                  className="btn btn-primary"
                  onClick={resetData}
                  disabled={disableFlag}
                >
                  <i className="material-icons">search</i>
                </button>
                <span className="tooltiptext">
                  <FormattedMessage
                    id="global.filter"
                    defaultMessage="Filter"
                  />
                </span>
              </div>
            </div>
            <div className="col force--inline">
              <div className="tooltip-master">
                <button
                  href="javascript:;"
                  className="btn btn-primary"
                  onClick={resetPage}
                  disabled={disableFlag}
                >
                  <i className="material-icons">settings_backup_restore</i>
                </button>
                <span className="tooltiptext">
                  <FormattedMessage
                    id="global.resetFilter"
                    defaultMessage="Reset Filter"
                  />
                </span>
              </div>
            </div>
            <div className="col-xs-3 pull-right">
              <div className="panel-group-buttons pull-right">
                <CheckAccess
                  activityId={ActivitiesEnum.estimation_request_export_to_excel}
                >
                  <ExportToExcel onExport={onExport} />
                </CheckAccess>
              </div>
            </div>
          </form>
          <div className="row">
            <div className="col-md-12">
              <FormattedMessage
                id="global.totalCount"
                defaultMessage="Total Count"
              />
              <strong>
                {" "}
                <FormattedNumber
                  style="decimal"
                  maximumFractionDigits={0}
                  minimumFractionDigits={0}
                  value={count || 0}
                />
              </strong>
            </div>
          </div>
        </div>

        <CheckAccess activityId={ActivitiesEnum.estimation_request_all}>
          <div className="dashboard-table-container card-layout">
            <div className="table-responsive">
              <Table
                loadMore={loadMore}
                className="abc"
                hasMore={hasMore}
                caption={
                  (!data || data == 0) && (
                    <caption
                      style={{
                        captionSide: "bottom",
                        textAlign: "center",
                        margin: "20px",
                        color: "darkgray",
                      }}
                    >
                      <FormattedMessage
                        id="global.noData"
                        defaultMessage="No data available"
                      />
                    </caption>
                  )
                }
                headerRow={
                  <tr>
                    <th>
                      <FormattedMessage
                        id="estimations.firstName"
                        defaultMessage="First Name"
                      />
                    </th>
                    <th>
                      <FormattedMessage
                        id="estimations.lastName"
                        defaultMessage="Last Name"
                      />
                    </th>
                    <th>
                      <FormattedMessage
                        defaultMessage="Phone Number"
                        id="estimations.phoneNumber"
                      />
                    </th>
                    <th>
                      <FormattedMessage
                        defaultMessage="Licence Plate"
                        id="estimations.licencePlate"
                      />
                    </th>
                    <th>
                      <FormattedMessage
                        id="estimations.status"
                        defaultMessage="Status"
                      />
                    </th>
                    <th>
                      <FormattedMessage
                        id="estimations.createdAt"
                        defaultMessage="Created At"
                      />
                    </th>
                    <th style={{ width: "20px" }} />
                  </tr>
                }
              >
                {data &&
                  data.map((estimation) => (
                    <tr
                      key={estimation._id}
                      onClick={() =>
                        props.navigate(`/estimation-request/${estimation._id}`)
                      }
                      className="cases-trow link-row"
                      style={{
                        backgroundColor: estimation.isUpdated && "#29c0dc4d",
                      }}
                    >
                      <td>{estimation.firstName}</td>
                      <td>{estimation.lastName}</td>
                      <td className="phoneNumber-alignment">
                        {estimation.phoneNumber}
                      </td>
                      <td>{estimation.licencePlate}</td>
                      <td>{estimation.status}</td>
                      <td>
                        {moment(estimation.createdAt)
                          .utc()
                          .format("YYYY-MM-DD")}
                      </td>
                      {/* 
                    <td
                      className={classNames({
                        "col-status-green": car.isActive,
                        "col-status-red": !car.isActive
                      })}
                    >
                      <a href="javascript:;">
                        {car.isActive && (
                          <FormattedMessage
                            id="operator.active"
                            defaultMessage="Active"
                          />
                        )}
                        {!car.isActive && (
                          <FormattedMessage
                            id="operator.suspended"
                            defaultMessage="Suspended"
                          />
                        )}
                      </a>
                    </td> */}
                      {/* <td style={{ position: "relative" }}>
                      <ActionMenu
                        visible={this.state.visible}
                        menuStyle={{
                          backgroundColor: "#E9E9E9"
                        }}
                      >
                        <CheckAccess
                          activityId={ActivitiesEnum.cars__update_car_edit}
                        >
                          <Link
                            style={{ margin: "10px", cursor: "pointer" }}
                            to={`/car/${car._id}`}
                          >
                            <span className="fa fa-pencil" />{" "}
                            <FormattedMessage
                              id="global.edit"
                              defaultMessage="Edit"
                            />
                          </Link>
                        </CheckAccess>
                        {appState.state.token?.role === "sadmin" && (
                          <CheckAccess
                            activityId={ActivitiesEnum.user_activity__all}
                          >
                            <Link
                              style={{
                                margin: "10px",
                                cursor: "pointer"
                              }}
                              to={`/userActivities/${car._id}`}
                            >
                              <span className="fa fa-history" />{" "}
                              <FormattedMessage
                                id="global.viewHistory"
                                defaultMessage="View History"
                              />
                            </Link>
                          </CheckAccess>
                        )}
                        <a
                          style={{ margin: "10px", cursor: "pointer" }}
                          onClick={() => {}}
                        />
                      </ActionMenu>
                    </td> */}
                    </tr>
                  ))}
              </Table>
            </div>
          </div>
        </CheckAccess>
      </Layout>
    </Auth>
  );
};

export default withRouter(injectIntl(EstimationRequestList));
