import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import FieldRow from "../../../shared/FieldRow";
import _ from "lodash";
import { notificationsData, messageParameters } from "../utils/utils";

export class EmailContent extends Component {
  formatHtmlContentOnVariableChange(values, setFieldValue, field, variable) {
    const currentContent = values[field];
    const htmlContent = `${currentContent}`;

    const regex = /(<li>[^<]*<\/li>\s*<\/(?:ul|ol)>|<\/[^>]+>)\s*$/i;
    // const regex = /(<li>[^<]*<\/li>\s*<\/(?:ul|ol)>|<\/[^>]+>)\s*$/ig;;

    const modifiedContent = htmlContent.replace(regex, (match, closingTag) => {
      return `%${variable}${closingTag}`;
    });

    setFieldValue(field, modifiedContent);
  }

  render() {
    const { formikProps, setFieldValue, values, formatMessage, type, emails } =
      this.props;
    const {
      name,
      subjectEn,
      fieldEmailEng,
      fieldEmailAr,
      emailsTo,
      emailsCC,
    } = notificationsData[type];

    return (
      <div className="col-md-12 single-notification">
        <div className="notify">
          <FormattedMessage id={"notify." + type} defaultMessage={name} />
        </div>
        <div className="col-md-12">
          {/* Email Content */}

          <div className="">
            <div className="content-type">
              {/* <FormattedMessage
                id="notify.emailContent"
                defaultMessage="Email Content"
              /> */}
            </div>
            <div>
              <FieldRow
                type="select"
                labelId="admin.selectVariable"
                defaultLabel="Select Variable"
                formikProps={formikProps}
                onChange={(e) => {
                  this.formatHtmlContentOnVariableChange(
                    values,
                    setFieldValue,
                    fieldEmailEng,
                    e.target.value
                  );
                }}
              >
                {messageParameters[type].email.map(({ key, value }, i) => {
                  return (
                    <option value={"{" + value + "}"} key={i}>
                      {formatMessage({
                        id: "notify." + value,
                        defaultMessage: key,
                      })}
                    </option>
                  );
                })}
              </FieldRow>
              <FieldRow
                type="text"
                name={subjectEn}
                labelId="translation.englishEmailSubject"
                defaultLabel="Email Subject in English"
                placeholderId="translation.enterEnglishEmailSubject"
                defaultPlaceholder="Enter Email Subject in English"
                showRequiredFlag={true}
                formikProps={formikProps}
              />
              <FieldRow
                type="html"
                name={fieldEmailEng}
                labelId="translation.englishEmail"
                defaultLabel="Email in English"
                placeholderId="translation.enterEnglishEmail"
                defaultPlaceholder="Enter Email in English"
                showRequiredFlag={true}
                formikProps={formikProps}
                textEditor={true}
              />
            </div>
            <div>
              <FieldRow
                type="select"
                labelId="admin.selectVariable"
                defaultLabel="Select Variable"
                formikProps={formikProps}
                onChange={(e) => {
                  this.formatHtmlContentOnVariableChange(
                    values,
                    setFieldValue,
                    fieldEmailAr,
                    e.target.value
                  );
                }}
              >
                {messageParameters[type].email.map(({ key, value }, i) => {
                  return (
                    <option value={"{" + value + "}"} key={i}>
                      {formatMessage({
                        id: "notify." + value,
                        defaultMessage: key,
                      })}
                    </option>
                  );
                })}
              </FieldRow>
             
              <FieldRow
                type="html"
                name={fieldEmailAr}
                labelId="translation.arabicEmail"
                defaultLabel="Email in Arabic"
                placeholderId="translation.enterArabicEmail"
                defaultPlaceholder="Enter Email in Arabic"
                showRequiredFlag={true}
                textEditor={true}
                formikProps={formikProps}
              />
              {type !== "forgotPasswordEmail" &&
                type !== "setPasswordScreenForAdmin" && (
                  <>
                    <FieldRow
                      emailsPlaceholderText={formatMessage({
                        id: "scheduleEmail.addEmails",
                        defaultMessage: "Add Email",
                      })}
                      type="tag"
                      name={emailsTo}
                      data={values[emailsTo]}
                      // initialEmails={this.state.value.emails}
                      labelId="scheduleEmail.to"
                      defaultLabel="To"
                      placeholderId="scheduleEmail.emails"
                      defaultPlaceholder="Enter Emails"
                      formikProps={formikProps}
                      showRequiredFlag={true}
                    />
                    <FieldRow
                      emailsPlaceholderText={formatMessage({
                        id: "scheduleEmail.addEmails",
                        defaultMessage: "Add Email",
                      })}
                      type="tag"
                      name={emailsCC}
                      data={values[emailsCC]}
                      // initialEmails={this.state.value.emails}
                      labelId="scheduleEmail.cc"
                      defaultLabel="CC"
                      placeholderId="scheduleEmail.emails"
                      defaultPlaceholder="Enter Emails"
                      formikProps={formikProps}
                      showRequiredFlag={true}
                    />
                  </>
                )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EmailContent;
