import React, { useState, useEffect, useRef } from "react";
import { FormattedMessage, injectIntl, FormattedNumber } from "react-intl";
import Layout from "../shared/Layout";
import _ from "lodash";
import Auth from "../shared/Auth";
import { Link } from "react-router-dom";
import withRouter from "../../util/withRouter";
import appState from "../../state/AppStateContainer";
import moment from "moment";
import HeaderTitle from "../shared/HeaderTitle";
import CheckAccess from "../shared/CheckAccess";
import { ActivitiesEnum } from "../../staticData/Activities";
import Table from "../shared/Table";
import variantService from "../../services/variantsServices";
import classNames from "classnames";

const Variants = ({ navigate, match, location, intl }) => {
  const [data, setData] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [pageIndex, setPageIndex] = useState(-1);
  const [pageSize, setPageSize] = useState(10);
  const [approvalFilter, setApprovalFilter] = useState("all");
  const [statusFilter, setStatusFilter] = useState("all");
  const [count, setCount] = useState(0);
  const [disableFlag, setDisableFlag] = useState(false);
  const [searchFilter, setSearchFilter] = useState("");

  const searchInputRef = useRef(null);
  const statusSelectRef = useRef(null);

  useEffect(() => {
    if (location.pathname !== match.path) {
      if (!match.params.isFormOpen) {
        if (_.get(location, "state.isSubmitted") === true) {
          setPageIndex(-1);
          setData([]);
          setHasMore(true);
          setDisableFlag(true);
          setSearchFilter(searchInputRef.current?.value);
          setStatusFilter(statusSelectRef.current?.value);
        }
      }
    }
  }, [location.pathname, match.params.isFormOpen, location.state]);

  useEffect(() => {
    getData(true);
  }, []);

  const loadMore = async () => {
    setLoading(true);
    getData();
  };

  const getData = async (summary) => {
    const options = { progressBar: true, alphabeticalOrder: true };

    if (summary) {
      const counts = await variantService.getVariants(
        searchFilter,
        statusFilter,
        "hsda",
        pageIndex,
        pageSize,
        options,
        summary
      );
      setCount(counts?.count || 0);
    } else {
      const pageData = await variantService.getVariants(
        searchFilter,
        statusFilter,
        "hsda",
        pageIndex,
        pageSize,
        options
      );
      setData(pageData);
      setDisableFlag(false);
      setLoading(false);
      setHasMore(false);
    }
  };

  const resetData = (e) => {
    setPageIndex(-1);
    setData([]);
    setHasMore(true);
    setDisableFlag(false);
    setSearchFilter(searchInputRef.current?.value);
    setStatusFilter(statusSelectRef.current?.value);

    if (e) e.preventDefault();
  };

  const resetPage = (e) => {
    setPageIndex(-1);
    setData([]);
    setHasMore(true);
    setSearchFilter("");
    setApprovalFilter("all");
    setStatusFilter("all");
    setDisableFlag(false);
    searchInputRef.current.value = "";
    statusSelectRef.current.value = "all";
    e.preventDefault();
  };

  return (
    <Auth requireAuth={true} roles={["sadmin", "wsadmin", "spadmin"]}>
      <Layout hidden={match.params.isFormOpen} loading={loading}>
        <div className="table-header clearfix">
          <HeaderTitle id="variants.variants" defaultMessage="Variants" />
          <div className="main-container">
            <div className="table-header clearfix">
              <form className="row" onSubmit={resetData}>
                <div className="col-md-2 col-sm-4 col-xs-6">
                  <div className="has-feedback">
                    <input
                      type="text"
                      ref={searchInputRef}
                      className="form-control"
                      //   value={searchFilter}
                      placeholder={intl.formatMessage({
                        id: "global.search",
                        defaultMessage: "Search",
                      })}
                    />
                    <i className="glyphicon glyphicon-search form-control-feedback" />
                  </div>
                </div>
                <div className="col-md-2 col-sm-4 col-xs-6">
                  <select
                    ref={statusSelectRef}
                    onChange={resetData}
                    className="form-control"
                  >
                    <option value="all">
                      {intl.formatMessage({
                        id: "shops.status",
                        defaultMessage: "Status",
                      })}
                    </option>
                    <option value="Yes">
                      {intl.formatMessage({
                        id: "shops.active",
                        defaultMessage: "Active",
                      })}
                    </option>
                    <option value="No">
                      {intl.formatMessage({
                        id: "shops.suspended",
                        defaultMessage: "Suspended",
                      })}
                    </option>
                  </select>
                </div>
                <div className="col force--inline">
                  <div className="tooltip-master">
                    <button
                      className="btn btn-primary"
                      onClick={resetData}
                      disabled={disableFlag}
                    >
                      <i className="material-icons">search</i>
                    </button>
                    <span className="tooltiptext">
                      <FormattedMessage
                        id="global.filter"
                        defaultMessage="Filter"
                      />
                    </span>
                  </div>
                </div>
                <div className="col force--inline">
                  <div className="tooltip-master">
                    <button
                      className="btn btn-primary"
                      onClick={resetPage}
                      disabled={disableFlag}
                    >
                      <i className="material-icons">settings_backup_restore</i>
                    </button>
                    <span className="tooltiptext">
                      <FormattedMessage
                        id="global.resetFilter"
                        defaultMessage="Reset Filter"
                      />
                    </span>
                  </div>
                </div>
              </form>
            </div>
            <div className="row">
              <div className="col-xs-3 pull-right">
                <div className="panel-group-buttons pull-right">
                  <CheckAccess activityId={ActivitiesEnum.variant__add_variant}>
                    <Link className="btn btn-special " to="/variant">
                      <span className="fa fa-plus" />{" "}
                      <FormattedMessage
                        id="variants.new_variant"
                        defaultMessage="Create Variant"
                      />
                    </Link>
                  </CheckAccess>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <FormattedMessage
                  id="global.totalCount"
                  defaultMessage="Total Count"
                />

                <strong>
                  {" "}
                  <FormattedNumber
                    style="decimal"
                    maximumFractionDigits={0}
                    minimumFractionDigits={0}
                    value={count || 0}
                  />{" "}
                </strong>
              </div>
            </div>
          </div>
          <div className="table-responsive">
            <Table
              loadMore={loadMore}
              hasMore={hasMore}
              caption={
                (!data || data?.length === 0) && (
                  <caption
                    style={{
                      captionSide: "bottom",
                      textAlign: "center",
                      margin: "20px",
                      color: "darkgray",
                    }}
                  >
                    <FormattedMessage
                      id="global.noData"
                      defaultMessage="No data available"
                    />
                  </caption>
                )
              }
              headerRow={
                <tr onClick={() => {}}>
                  <th>
                    <FormattedMessage
                      id="variants.englishName"
                      defaultMessage="English Name"
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="variants.arabicName"
                      defaultMessage="Arabic Name"
                    />
                  </th>

                  <th>
                    <FormattedMessage
                      id="variants.product"
                      defaultMessage="Product"
                    />
                  </th>

                  <th>
                    <FormattedMessage
                      id="variants.isActive"
                      defaultMessage="Active"
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="variants.createdAt"
                      defaultMessage="Created At"
                    />
                  </th>

                  <th style={{ width: "20px" }} />
                </tr>
              }
            >
              {data?.map((c) => {
                var showEdit = false;

                if (
                  (appState.state.token?.role === "sadmin" &&
                    c.approvalState === "approved") ||
                  (appState.state.token?.id === c.approvalRequestedBy &&
                    c.approvalState !== "approved")
                )
                  showEdit = true;

                return (
                  <tr
                    key={c._id}
                    onClick={() => navigate(`/variant/${c._id}`)}
                    className="cases-trow link-row"
                  >
                    <td>{c.name?.value_en}</td>
                    <td>{c.name?.value_ar}</td>

                    <td>
                      {appState.state.language === "en"
                        ? c.product[0].name[0].value_en
                        : c.product[0].name[0].value_ar}
                    </td>

                    <td
                      className={classNames({
                        "col-status-green": c.isActive,
                        "col-status-red": !c.isActive,
                      })}
                    >
                      <a href="javascript:;">
                        {c.isActive && (
                          <FormattedMessage
                            id="operator.active"
                            defaultMessage="Active"
                          />
                        )}
                        {!c.isActive && (
                          <FormattedMessage
                            id="operator.suspended"
                            defaultMessage="Suspended"
                          />
                        )}
                      </a>
                    </td>
                    <td>{moment.utc(c.createdAt).format("MMM Do YY")}</td>
                  </tr>
                );
              })}
            </Table>
          </div>
        </div>
      </Layout>
    </Auth>
  );
};

export default withRouter(injectIntl(Variants));
