import React from "react";
import { FormattedMessage } from "react-intl";
import ActionMenu from "../components/shared/ActionMenu";
import ImageProvider from "../components/shared/ImageProvider";
import { getLocalDateTime } from "../util";
import { isLtr, offerTypes } from "../util/utils";
import { FaGripVertical } from "react-icons/fa";
import moment from "moment";
import appState from "../state/AppStateContainer";
import CheckAccess from "../components/shared/CheckAccess";
import { ActivitiesEnum } from "../staticData/Activities";

const TableBody = (props) => {
  const { deleteHandler, c, history, navigate, updateStatus, from } = props;
  const lang = appState.state.language;

  return (
    <div>
      <div className="grip-drag-icon">
        <FaGripVertical color="lightgray" />
      </div>
      {from === "banners" && (
        <div onClick={() => navigate(`/banner/${c._id}`)}>
          <div
            key={c._id}
            className="custom-row-table"
            style={{ borderBottom: "1px solid lightgray" }}
          >
            <ImageProvider
              imageSrc={isLtr() ? c?.homeBanner_en : c?.homeBanner_ar}
            />

            <div>{c.title.en}</div>
            <div>{c.title.ar}</div>

            <div>
              {c.expiryDate
                ? moment(c.expiryDate).format("MMM DD, YYYY")
                : "Never"}
            </div>
            <div>{c.isActive ? "Yes" : "No"}</div>
            <div style={{ position: "relative" }} className="actions-wrapper">
              <ActionMenu
                menuStyle={{
                  backgroundColor: "#E9E9E9",
                }}
              >
                <a
                  onClick={() => updateStatus(c._id, !c.isActive)}
                  style={{ margin: "10px", cursor: "pointer" }}
                >
                  <span className="fa fa-pencil-square-o" />{" "}
                  <FormattedMessage
                    id={c.isActive ? "global.deActivate" : "global.activate"}
                    defaultMessage="Status"
                  />
                </a>
                <a
                  onClick={() => deleteHandler(c._id)}
                  style={{ margin: "10px", cursor: "pointer" }}
                >
                  <span className="fa fa-trash" />
                  <FormattedMessage
                    id="global.delete"
                    defaultMessage="Delete"
                  />
                </a>
              </ActionMenu>
            </div>
          </div>
        </div>
      )}

      {from === "service" && (
        <div
          key={c.id}
          onClick={() => navigate(`/service/${c.id}`)}
          className="cases-trow link-row"
        >
          <div key={c.id} className="custom-row-table">
            <div>{c.englishName}</div>
            <div>{c.arabicName}</div>
            <div>
              {
                offerTypes.find(({ value }) => value === c.offerType)?.[
                  "label_" + lang
                ]
              }
            </div>
            <div>{c.isComingSoon ? "Yes" : "No"}</div>
            <div>{c.createdAt ? getLocalDateTime(c.createdAt) : undefined}</div>
            <div>{c.isActive ? "Yes" : "No"}</div>
            <CheckAccess activityId={ActivitiesEnum.services_clone_service}>
              <div style={{ position: "relative" }} className="actions-wrapper">
                <ActionMenu>
                  <a onClick={() => updateStatus(c.id)}>
                    <span className="fa fa-clone" />{" "}
                    <FormattedMessage
                      id="global.clone"
                      defaultMessage="Clone"
                    />
                  </a>
                </ActionMenu>
              </div>
            </CheckAccess>
          </div>
        </div>
      )}

      {from === "offers" && (
        <div
          key={c._id}
          onClick={() => navigate(`/offer/${c._id}`)}
          className="custom-row-table"
          style={{ borderBottom: "1px solid lightgray" }}
        >
          <div>{c.name["value_" + lang]}</div>
          <div>{c.campaignCode}</div>
          <div>{moment(c.startDate).format("DD-MM-YYYY")}</div>
          <div>{moment(c.endDate).format("DD-MM-YYYY")}</div>
          <div>{c.isActive ? "Yes" : "No"}</div>
          <div>{c.isDeleted ? "Yes" : "No"}</div>
        </div>
      )}

      {from === "subscriptions" && (
        <div
          key={c._id}
          onClick={() => navigate(`/subscription/${c._id}`)}
          className="custom-row-table"
          style={{ borderBottom: "1px solid lightgray" }}
        >
          <div>{c.name["value_" + lang]}</div>
          <div>{c.price}</div>
          <div>{c.quota}</div>
          <div>{c.isActive ? "Yes" : "No"}</div>
          <div>{moment(c.createdAt).format("DD-MM-YYYY")}</div>
        </div>
      )}

      {from === "blogs" && (
        <div
          key={c._id}
          onClick={() => {
            navigate(`/blog/${c._id}`);
          }}
          className="custom-row-table"
          style={{ borderBottom: "1px solid lightgray" }}
        >
          <div>{c.title.en}</div>
          <div>{c.title.ar}</div>
          <div>{c.ctaText.en}</div>
          <div>{c.ctaText.ar}</div>
          <div>{c.ctaLink}</div>
          <div>{c.isActive ? "Yes" : "No"}</div>
          <div style={{ position: "relative" }} className="actions-wrapper">
            <ActionMenu
              menuStyle={{
                backgroundColor: "#E9E9E9",
              }}
            >
              <a
                onClick={() => updateStatus(c._id, !c.isActive)}
                style={{ margin: "10px", cursor: "pointer" }}
              >
                <span className="fa fa-pencil-square-o" />{" "}
                <FormattedMessage
                  id={c.isActive ? "global.deActivate" : "global.activate"}
                  defaultMessage="Status"
                />
              </a>
              <a
                onClick={() => deleteHandler(c._id)}
                style={{ margin: "10px", cursor: "pointer" }}
              >
                <span className="fa fa-trash" />{" "}
                <FormattedMessage id="global.delete" defaultMessage="Delete" />
              </a>
            </ActionMenu>
          </div>
        </div>
      )}

      {from === "cities" && (
        <div
          key={c._id.city}
          className="custom-row-table"
          style={{ borderBottom: "1px solid lightgray" }}
        >
          <div>{c._id.city}</div>
          <div>{c._id.cityNameAr}</div>
          <div>{c.data.length}</div>
        </div>
      )}
    </div>
  );
};

export default TableBody;
