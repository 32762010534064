import React, { Component } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import Auth from "../shared/Auth";
import _ from "lodash";
import HeaderTitle from "../shared/HeaderTitle";
import moment from "moment";
// import "react-sliding-pane/dist/react-sliding-pane.css";
import AppointmentDashboard from "../appointment/AppointmentDashboard";

// if you want to show the loader when React loads data again

export class Dashboard extends Component {
  constructor() {
    super();
  }
  state = {
    loading: true,
    orderStats: [],
    downloadType: "customer",
    type: "order",
    from: moment()
      .utcOffset(60 * 3)
      .subtract(30, "day")
      .startOf("day")
      .toString(),
    to: moment()
      .utcOffset(60 * 3)
      .endOf("day")
      .toString(),
    topPerfomer: [],
    orderStats: [],
    popularCities: [],
    downloads: [],
    showCustomDateFilter: true,
    data: {
      loaded: true,
      adjustmentsStats: [],
      orderWeeklyStats: [],
      categoryStats: [],
      spStats: [],
      activeIndex: 0,
      pieActiveIndex: 0,
      wsStats: [],
      serviceStats: [],
      topPerfomer: [],
      orderStats: [],
      popularCities: [],
      customerStats: [],
    },
    activities: [],
  };

  render() {
    return (
      <Auth>
        <HeaderTitle id="menu.dashboard" defaultMessage="Dashboard" />
        <AppointmentDashboard />
      </Auth>
    );
  }
}

export default injectIntl(Dashboard);
