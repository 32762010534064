import BaseService from "./baseService";
import axios from "axios";

class HeadlineService extends BaseService {
  constructor() {
    super();
  }

  async getHeadline(id) {
    const response = await axios.get(
      "/api/headline/all" + (id ? "?id=" + id : "/")
    );
    return response.data.response;
  }

  async addHeadline(request) {
    const formData = new FormData();
    for (let key in request) {
      formData.append(
        key,
        typeof request[key] == "object" && key != "imageEn" && key != "imageAr"
          ? JSON.stringify(request[key])
          : request[key]
      );
    }
    const response = await axios.post("/api/headline", formData);
    return response.data.response;
  }

  async updateHeadline(request, id) {
    const formData = new FormData();
    for (let key in request) {
      formData.append(
        key,
        typeof request[key] == "object" && key != "imageEn" && key != "imageAr"
          ? JSON.stringify(request[key])
          : request[key]
      );
    }
    const response = await axios.put("/api/headline/" + id, formData);
    return response.data.response;
  }

  async deleteHeadline(id) {
    const response = await axios.delete("/api/headline/" + id);
    return response.data.response;
  }

  async updateHeadlineStatus(id, status) {
    const response = await axios.patch("/api/headline/" + id, {
      isActive: status,
    });
    return response.data.response;
  }
}
export default new HeadlineService();
