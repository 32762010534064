import React, { Component, createRef } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Formik, Form } from "formik";
import { Yup } from "../../util";
import { Modal, Button } from "react-bootstrap";
import appState from "../../state/AppStateContainer";
import _ from "lodash";

class TranslationModal extends Component {
  constructor(props) {
    super(props);
    this.formikRef = createRef();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props && !this.props.add) {
      this.formikRef.resetForm();
    }
  }

  render() {
    const {
      intl,
      loading,
      show,
      onSubmit,
      stringId,
      englishValue,
      arabicValue,
      add,
      onCancel
    } = this.props;

    return (
      <Modal show={show} onHide={onCancel} centered backdrop="static">
        <Modal.Header closeButton={!loading}>
          <h4>
            <FormattedMessage
              id="translation.translate"
              defaultMessage="Translate"
            />
          </h4>
        </Modal.Header>
        <Modal.Body>
          <Formik
            innerRef={(f) => (this.formikRef = f)}
            validationSchema={Yup.object().shape(
              _({
                stringId: Yup.string()
                  .label(
                    intl.formatMessage({
                      id: "global.id",
                      defaultMessage: "Id",
                    })
                  )
                  .trim()
                  .required(),
                englishValue: Yup.string()
                  .required("Field is required")
                  .label(
                    intl.formatMessage({
                      id: "translation.english",
                      defaultMessage: "English",
                    })
                  ),
                arabicValue: Yup.string()
                  .required("Field is required")
                  .label(
                    intl.formatMessage({
                      id: "translation.arabic",
                      defaultMessage: "Arabic",
                    })
                  ),
              })
                .omitBy(_.isUndefined)
                .value()
            )}
            onSubmit={onSubmit}
            initialValues={{
              stringId: stringId || "",
              englishValue:
                englishValue || appState.state.editingTranslationDefault || "",
              arabicValue: arabicValue || "",
            }}
          >
            {({ values, handleChange, touched, errors }) => {
              return (
                <Form>
                  {add && (
                    <div>
                      <FormattedMessage
                        id="translation.id"
                        defaultMessage="String Id"
                      />
                      <input
                        name="stringId"
                        value={values.stringId}
                        onChange={handleChange}
                        className="form-control"
                      />
                      {touched["stringId"] && errors["stringId"] && (
                        <p className="text-warning">{errors["stringId"]}</p>
                      )}
                    </div>
                  )}

                  <br />

                  <FormattedMessage
                    id="translation.english"
                    defaultMessage="English"
                  />

                  <input
                    name="englishValue"
                    value={values.englishValue}
                    onChange={handleChange}
                    className="form-control"
                  />
                  {touched["englishValue"] && errors["englishValue"] && (
                    <p className="text-warning">{errors["englishValue"]}</p>
                  )}
                  <br />
                  <FormattedMessage
                    id="translation.arabic"
                    defaultMessage="Arabic"
                  />
                  <br />
                  <input
                    name="arabicValue"
                    value={values.arabicValue}
                    onChange={handleChange}
                    className="form-control"
                  />
                  {touched["arabicValue"] && errors["arabicValue"] && (
                    <p className="text-warning">{errors["arabicValue"]}</p>
                  )}
                  <br />
                </Form>
              );
            }}
          </Formik>
        </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => {
                this.formikRef.submitForm();
              }}
              disabled={loading}
            >
              <FormattedMessage id="global.save" defaultMessage="Save" />
            </Button>
            <Button
              variant="link"
              onClick={onCancel}
              disabled={loading}
            >
              <FormattedMessage id="global.cancel" defaultMessage="Cancel" />
            </Button>
          </Modal.Footer>
      </Modal>
    );
  }
}

export default injectIntl(TranslationModal);
