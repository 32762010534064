import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import FieldRow from "../../../shared/FieldRow";
import _ from "lodash";
import { notificationsData, messageParameters } from "../utils/utils";

export class NotificationType extends Component {
  render() {
    const {
      formikProps,
      type,
      renderSmsOnly,
      renderEmailOnly,
      renderArabicContent = true,
    } = this.props;
    const { values, setFieldValue } = formikProps;
    const { formatMessage } = this.props.intl;
    const {
      name,
      fieldSmsEng,
      fieldSmsAr,
      activeSms,
      fieldEmailEng,
      fieldEmailAr,
    } = notificationsData[type];
    return (
      <div className="col-md-12 single-notification">
        <div className="notify">
          <FormattedMessage id={"notify." + type} defaultMessage={name} />
        </div>
        <div className="col-md-12">
          {/* SMS Content */}
          {!renderEmailOnly && (
            <div className="col-md-6">
              <div className="content-type">
                <FormattedMessage
                  id="notify.smsContent"
                  defaultMessage="SMS Content"
                />
              </div>
              <div>
                <FieldRow
                  type="select"
                  labelId="admin.selectVariable"
                  defaultLabel="Select Variable"
                  formikProps={formikProps}
                  onChange={(e) => {
                    setFieldValue(
                      fieldSmsEng,
                      values[fieldSmsEng] + `%${e.target.value}`
                    );
                  }}
                >
                  {messageParameters[type].sms.map(({ key, value }, i) => {
                    return (
                      <option value={"{" + value + "}"} key={i}>
                        {formatMessage({
                          id: "notify." + value,
                          defaultMessage: key,
                        })}
                      </option>
                    );
                  })}
                </FieldRow>
                <FieldRow
                  type="textarea"
                  name={fieldSmsEng}
                  labelId="translation.englishSMS"
                  defaultLabel="SMS in English"
                  placeholderId="translation.enterEnglishSMS"
                  defaultPlaceholder="Enter SMS in English"
                  showRequiredFlag={true}
                  formikProps={formikProps}
                />
              </div>
              {renderArabicContent && (
                <div>
                  <FieldRow
                    type="select"
                    labelId="admin.selectVariable"
                    defaultLabel="Select Variable"
                    formikProps={formikProps}
                    onChange={(e) => {
                      setFieldValue(
                        fieldSmsAr,
                        values[fieldSmsAr] + `%${e.target.value}`
                      );
                    }}
                  >
                    {messageParameters[type].sms.map(({ key, value }, i) => {
                      return (
                        <option value={"{" + value + "}"} key={i}>
                          {formatMessage({
                            id: "notify." + value,
                            defaultMessage: key,
                          })}
                        </option>
                      );
                    })}
                  </FieldRow>
                  <FieldRow
                    type="textarea"
                    name={fieldSmsAr}
                    labelId="translation.arabicSMS"
                    defaultLabel="SMS in Arabic"
                    placeholderId="translation.enterArabicSMS"
                    defaultPlaceholder="Enter SMS in Arabic"
                    showRequiredFlag={true}
                    formikProps={formikProps}
                  />
                  {activeSms && (
                    <FieldRow
                      name={activeSms}
                      type="checkbox"
                      formikProps={formikProps}
                      labelId="content.active"
                      defaultLabel="Active"
                      placeholderId="content.active"
                      defaultPl
                    />
                  )}
                </div>
              )}
            </div>
          )}

          {/* Email Content */}
          {!renderSmsOnly ? (
            <div className="col-md-6">
              <div className="content-type">
                <FormattedMessage
                  id="notify.emailContent"
                  defaultMessage="Email Content"
                />
              </div>
              <div>
                <FieldRow
                  type="select"
                  labelId="admin.selectVariable"
                  defaultLabel="Select Variable"
                  formikProps={formikProps}
                  onChange={(e) => {
                    setFieldValue(
                      fieldEmailEng,
                      values[fieldEmailEng] + `%${e.target.value}`
                    );
                  }}
                >
                  {messageParameters[type].email.map(({ key, value }, i) => {
                    return (
                      <option value={"{" + value + "}"} key={i}>
                        {formatMessage({
                          id: "notify." + value,
                          defaultMessage: key,
                        })}
                      </option>
                    );
                  })}
                </FieldRow>
                <FieldRow
                  type="textarea"
                  name={fieldEmailEng}
                  labelId="translation.englishEmail"
                  defaultLabel="Email in English"
                  placeholderId="translation.enterEnglishEmail"
                  defaultPlaceholder="Enter Email in English"
                  showRequiredFlag={true}
                  formikProps={formikProps}
                />
              </div>
              {renderArabicContent && (
                <div>
                  <FieldRow
                    type="select"
                    labelId="admin.selectVariable"
                    defaultLabel="Select Variable"
                    formikProps={formikProps}
                    onChange={(e) => {
                      setFieldValue(
                        fieldEmailAr,
                        values[fieldEmailAr] + `%${e.target.value}`
                      );
                    }}
                  >
                    {messageParameters[type].email.map(({ key, value }, i) => {
                      return (
                        <option value={"{" + value + "}"} key={i}>
                          {formatMessage({
                            id: "notify." + value,
                            defaultMessage: key,
                          })}
                        </option>
                      );
                    })}
                  </FieldRow>
                  <FieldRow
                    type="textarea"
                    name={fieldEmailAr}
                    labelId="translation.arabicEmail"
                    defaultLabel="Email in Arabic"
                    placeholderId="translation.enterArabicEmail"
                    defaultPlaceholder="Enter Email in Arabic"
                    showRequiredFlag={true}
                    formikProps={formikProps}
                  />
                </div>
              )}
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default injectIntl(NotificationType);
