import BaseService from "../../../services/baseService";

class Payloads extends BaseService {
    constructor() {
        super();
    }

    // Getting ready shop timings to send it on backend
    compileShopTiming(values, week) {
        const shopTimings = [];
        week.forEach(({ en }) => {
            shopTimings.push({
                day: en.toLowerCase(),
                timings: {
                    openingTime: values["openingTime" + en],
                    closingTime: values["closingTime" + en],
                },
                isClosed: values["is" + en + "Close"],
            });
        });

        return shopTimings;
    }

    // Get ready the events data to send it on backend
    compileOccasions(eventsData, eventsDataCopy, id) {
        let occasions = eventsData;
        occasions = occasions.map(({ id, name, startDate, endDate, openTime, closeTime, isHoliday, isActive }, i) => {
            if (i > 0) {
                // Creating data structure same as the shcema on backend will recieve
                let data = {
                    event: id,
                    title: name,
                    dateRange: { from: startDate, to: endDate },
                    shopTimings: {
                        openingTime: openTime,
                        closingTime: closeTime,
                    },
                    isHoliday: isHoliday,
                    isActive: isActive
                }

                if (id) {
                    data._id = eventsDataCopy.find(e => e.id === id)?.id
                }

                return data;
            }
        });
        occasions.shift();

        if (id) {
            occasions = occasions.filter((o) => o.isActive || !o.isActive && o._id);
        }

        return occasions;
    }

    // Getting ready all the services with its timeslots and details
    // This code will merge the event days timeslots in working days timeslots and create exact structure like the schema on backend 
    async compileOfferedServices(ocassions, normalSlots, eventsSlots) {
        const normal = [...normalSlots]; // Final normal days slots
        const special = [...eventsSlots]; // Final events slots

        // Logic
        let selectedServices = [];
        if (ocassions) {
            selectedServices = normal.map((normalSlot) => {
                const requiredSpecialSlot = special.findIndex(({ service }) => service === normalSlot.service)
                if (!special[requiredSpecialSlot]) return normalSlot;
                special[requiredSpecialSlot].events.forEach(({ slots, event }) => {
                    const ocassionIndex = ocassions?.findIndex((o) => o.event === event)
                    slots.forEach((spslot) => {
                        const timeSlotForday = normalSlot.slots.find(({ day }) => spslot.day === day);
                        if (!timeSlotForday) {
                            normalSlot.slots.push({
                                day: spslot.day,
                                timeSlots: spslot.timeSlots.map(({ time, numberOfSlots }) => ({
                                    time,
                                    numberOfSlots: 0,
                                    specialSlots: [{
                                        ocassion: ocassionIndex,
                                        numberOfSlots
                                    }]
                                }))
                            })
                        }
                        else {
                            spslot.timeSlots.forEach((t) => {
                                const particularSlot = timeSlotForday.timeSlots.find(({ time }) => time === t.time);
                                if (particularSlot) {
                                    if (particularSlot.specialSlots) {
                                        particularSlot.specialSlots.push({
                                            ocassion: ocassionIndex,
                                            numberOfSlots: t.numberOfSlots
                                        })
                                    }
                                    else {
                                        particularSlot.specialSlots = [{
                                            ocassion: ocassionIndex,
                                            numberOfSlots: t.numberOfSlots
                                        }]
                                    }
                                }
                                else {
                                    timeSlotForday.timeSlots.push({
                                        time: t.time,
                                        numberOfSlots: 0,
                                        specialSlots: [{
                                            ocassion: ocassionIndex,
                                            numberOfSlots: t.numberOfSlots
                                        }]
                                    })
                                }
                            })
                        }
                    })
                })
                return normalSlot;
            });

            return selectedServices;
        }
    }
}

export default new Payloads();