import React, { Component, createRef } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Form, Formik } from "formik";
import { Yup } from "../../util";
import Layout from "../shared/Layout";
import services from "../../services";
import { NotificationManager } from "react-notifications";
import _ from "lodash";
import Auth from "../shared/Auth";
import FieldRow from "../shared/FieldRow";
import withRouter from "../../util/withRouter";
import appState from "../../state/AppStateContainer";
import HeaderTitle from "../shared/HeaderTitle";
import { withAuthContext } from "../../state/AuthContext";
import CheckAccess from "../shared/CheckAccess";
import { ActivitiesEnum } from "../../staticData/Activities";
import Select from "react-select";
import { Button } from "react-bootstrap";
export class CategoryForm extends Component {
  constructor() {
    super();
    this.formik = createRef();
    this.getCategorySuggestions = this.getCategorySuggestions.bind(this);
  }

  state = {
    suggestion: [],
    servicesType: [],
    data: {},
    loading: true,
    serviceOptions: [],
  };

  async getCategorySuggestions(field, value) {
    return await services.getSugestions(0, 5, field, value, "category");
  }

  async fetchServices() {
    this.setState({ loading: true });
    try {
      const servicesType = await services.getServicesName({
        fromCategoryScreen: true,
      });
      this.setState({ servicesType });
    } catch (e) {
      this.props.navigate("/error", {
        error: _.get(e, "response.data.error", "error.unexpectedError"),
      });
    } finally {
      this.setState({ loading: false });
    }
  }

  async componentDidMount() {
    try {
      this.subscription = appState.emitter.addListener(
        "onLanguageChange",
        () => {
          this.formik.validateForm();
        }
      );

      await this.fetchServices();

      if (this.props.match.params.id) {
        const data = await services.getCategory(this.props.match.params.id, {
          progressBar: true,
        });
        this.setState({ data });
      }
    } catch (e) {
      this.props.navigate("/error", {
        error: _.get(e, "response.data.error", "error.unexpectedError"),
      });
    } finally {
      this.setState({ loading: false });
    }
  }

  async componentWillUnmount() {
    this.subscription.remove();
  }

  handleChange = (selectedServiceType, formikProps) => {
    formikProps.setFieldValue("serviceType", selectedServiceType);
    formikProps.setTouched({ serviceType: true });
  };

  async handleSubmit(values, { setSubmitting }) {
    try {
      setSubmitting(true);
      this.setState({ loading: true });

      let payload = {
        ...values,
      };
      payload.serviceType = payload?.serviceType?.map((service) =>
        service?.value ? service?.value : service
      );
      payload.serviceType = JSON.stringify(payload.serviceType);
      payload["isServiceCategory"] = false;

      let response;
      if (!this.props.match.params.id) {
        response = await services.addCategory(payload);
      } else {
        const request = { id: this.props.match.params.id, ...payload };
        response = await services.updateCategory(request);
      }

      NotificationManager.success(
        this.props.intl.formatMessage({
          id: "global.operationSuccessful",
          defaultMessage: "Operation Successful",
        }),
        this.props.intl.formatMessage({
          id: "global.success",
          defaultMessage: "Success",
        })
      );

      this.props.navigate("/product-categories");
    } catch (e) {
      NotificationManager.error(
        this.props.intl.formatMessage({
          id: e?.response?.data?.response || "error.unexpectedError",
          defaultMessage: "An unexpected error has occurred",
        }),
        this.props.intl.formatMessage({
          id: "global.error",
          defaultMessage: "Error",
        })
      );
    } finally {
      setSubmitting(false);
      this.setState({ loading: false });
    }
  }

  _renderBody(formikProps) {
    const { isSubmitting, values } = formikProps;
    return (
      <Layout noLayout={this.props.noLayout} loading={this.state.loading}>
        <HeaderTitle
          id="category.productCategories"
          defaultMessage="Product Categories"
        />
        <div className="dashboard-table-container card-layout">
          <div className="panel-primary">
            <header className="panel-title">
              {this.props.match.params.id && (
                <FormattedMessage
                  id="category.update"
                  defaultMessage="Update Category"
                />
              )}
              {!this.props.match.params.id && (
                <FormattedMessage
                  id="category.add"
                  defaultMessage="Add Category"
                />
              )}
            </header>
            <Form>
              <div className="dashboard-row">
                <FieldRow
                  type="text"
                  name="englishName"
                  labelId="category.englishName"
                  defaultLabel="Category Name (English)"
                  placeholderId="category.enterEnglishName"
                  defaultPlaceholder="Enter english name"
                  formikProps={formikProps}
                  showRequiredFlag={true}
                />
                <FieldRow
                  type="text"
                  name="arabicName"
                  labelId="category.arabicName"
                  defaultLabel="Category Name (Arabic)"
                  placeholderId="category.enterArabicName"
                  defaultPlaceholder="Enter arabic name"
                  showRequiredFlag={true}
                  formikProps={formikProps}
                />
                <FieldRow
                  type="textarea"
                  name="englishDescription"
                  labelId="category.englishDescription"
                  defaultLabel="English Description"
                  placeholderId="category.enterEnglishDescription"
                  defaultPlaceholder="Enter english description"
                  showRequiredFlag={true}
                  formikProps={formikProps}
                />
                <FieldRow
                  type="textarea"
                  name="arabicDescription"
                  labelId="category.arabicDescription"
                  defaultLabel="Arabic Description"
                  placeholderId="category.enterArabicDescription"
                  defaultPlaceholder="Enter arabic description"
                  showRequiredFlag={true}
                  formikProps={formikProps}
                />
                <div>
                  <label className="form-label caps block">
                    <FormattedMessage
                      id="category.serviceType"
                      defaultMessage="Service Type *"
                    />
                  </label>
                </div>
                <Select
                  isDisabled={
                    this.state.data.createdBy === "inspirage" ? true : false
                  }
                  isClearable={true}
                  isMulti={true}
                  options={this.state?.servicesType?.map((s) => ({
                    value: s.id.toString(),
                    label:
                      appState.state.language === "en"
                        ? s.englishName
                        : s.arabicName,
                  }))}
                  placeholder={this.props.intl.formatMessage({
                    id: "category.selectServiceType",
                    defaultMessage: "Select Service Type",
                  })}
                  onChange={(selectedServiceType) =>
                    this.handleChange(selectedServiceType, formikProps)
                  }
                  value={values?.serviceType?.map((item) => {
                    const service = this.state?.servicesType?.find(
                      (serviceType) =>
                        serviceType?.id === (item?.value ? item?.value : item)
                    );
                    return {
                      value: item?.value ? item?.value : item,
                      label:
                        appState.state.language === "en"
                          ? service?.englishName
                          : service?.arabicName,
                    };
                  })}
                />
                <br />
                <FieldRow
                  name="logo"
                  type="file"
                  image={true}
                  cameraIcon={true}
                  showThumbnail={true}
                  labelId="category.logo"
                  defaultLabel="Logo"
                  placeholderId="category.selectLogo"
                  defaultPlaceholder="Select Logo"
                  showRequiredFlag={false}
                  formikProps={formikProps}
                />
                {appState.state.token?.role === "sadmin" && (
                  <FieldRow
                    name="isActive"
                    type="checkbox"
                    labelId="category.isActive"
                    defaultLabel="Active"
                    formikProps={formikProps}
                  />
                )}
              </div>
              <div className="dashboard-row -nobottom">
                <CheckAccess
                  activityId={
                    ActivitiesEnum.categories__update_category_edit_reject
                  }
                >
                  {this.props.authContext.authorized && (
                    <input
                      type="submit"
                      className="btn btn-primary"
                      value={this.props.intl.formatMessage({
                        id: "global.submit",
                        defaultMessage: "Submit",
                      })}
                      disabled={isSubmitting}
                    />
                  )}
                </CheckAccess>
                <Button
                  variant="link"
                  onClick={() => this.props.navigate("/product-categories")}
                  disabled={isSubmitting}
                >
                  {this.props.intl.formatMessage({
                    id: "global.cancel",
                    defaultMessage: "Cancel",
                  })}
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Layout>
    );
  }

  render() {
    const { intl } = this.props;
    return (
      <Auth requireAuth={true} roles={["sadmin"]}>
        <Formik
          innerRef={(f) => {
            this.formik = f;
          }}
          enableReinitialize
          initialValues={{
            englishName: this.state.data.englishName || "",
            arabicName: this.state.data.arabicName || "",
            englishDescription: this.state.data.englishDescription || "",
            arabicDescription: this.state.data.arabicDescription || "",
            serviceType: this.state?.data?.serviceType?.map((item) => {
              const service = this.state?.servicesType?.find(
                (serviceType) =>
                  serviceType?.id === (item?.value ? item?.value : item)
              );
              return {
                value: item?.value ? item?.value : item,
                label:
                  appState.state.language === "en"
                    ? service?.englishName
                    : service?.arabicName,
              };
            }),
            logo: this.state.data.logo || "",
            isActive: this.props.match.params.id
              ? this.state.data.isActive
              : true,
          }}
          validationSchema={Yup.object().shape({
            englishName: Yup.string()
              .hasNoArabic(
                intl.formatMessage({
                  id: "error.arabicCharactersAreNotAllowedInThisField",
                  defaultMessage:
                    "Arabic characters are not allowed in this field",
                })
              )
              .label(
                intl.formatMessage({
                  id: "category.englishName",
                  defaultMessage: "English name",
                })
              )
              .trim()
              .required(),
            englishDescription: Yup.string()
              .hasNoArabic(
                intl.formatMessage({
                  id: "error.arabicCharactersAreNotAllowedInThisField",
                  defaultMessage:
                    "Arabic characters are not allowed in this field",
                })
              )
              .label(
                intl.formatMessage({
                  id: "category.englishDescription",
                  defaultMessage: "English description",
                })
              )
              .trim()
              .required(),
            arabicName: Yup.string()
              .label(
                intl.formatMessage({
                  id: "category.arabicName",
                  defaultMessage: "Arabic Name",
                })
              )
              .hasNoEnglish(
                intl.formatMessage({
                  id: "error.englishCharactersAreNotAllowedInThisField",
                  defaultMessage:
                    "English characters are not allowed in this field",
                })
              )
              .trim()
              .required(),
            arabicDescription: Yup.string()
              .label(
                intl.formatMessage({
                  id: "category.arabicDescription",
                  defaultMessage: "Arabic Description",
                })
              )
              .hasNoEnglish(
                intl.formatMessage({
                  id: "error.englishCharactersAreNotAllowedInThisField",
                  defaultMessage:
                    "English characters are not allowed in this field",
                })
              )
              .trim()
              .required(),

            logo: Yup.mixed()
              .label(
                intl.formatMessage({
                  id: "category.logo",
                  defaultMessage: "Logo",
                })
              )
              .required(),
          })}
          onSubmit={this.handleSubmit.bind(this)}
        >
          {this._renderBody.bind(this)}
        </Formik>
      </Auth>
    );
  }
}

export default withAuthContext(withRouter(injectIntl(CategoryForm)));
