import React, { Component, createRef } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Form, Formik } from "formik";
import { Yup } from "../../util";
import Layout from "../shared/Layout";
import Services from "../../services";
import { NotificationManager } from "react-notifications";
import _ from "lodash";
import Auth from "../shared/Auth";
import FieldRow from "../shared/FieldRow";
import withRouter from "../../util/withRouter";
import appState from "../../state/AppStateContainer";
import HeaderTitle from "../shared/HeaderTitle";
import { withAuthContext } from "../../state/AuthContext";
import CheckAccess from "../shared/CheckAccess";
import { ActivitiesEnum } from "../../staticData/Activities";
import { Button } from "react-bootstrap";
export class ServiceCategoryForm extends Component {
  constructor() {
    super();
    this.formik = createRef();
  }

  state = {
    suggestion: [],
    data: {},
    loading: true,
    serviceOptions: [],
  };

  async componentDidMount() {
    try {
      this.subscription = appState.emitter.addListener(
        "onLanguageChange",
        () => {
          this.formik.validateForm();
        }
      );

      if (this.props.match.params.id) {
        const categories = await Services.getCategory(
          this.props.match.params.id,
          {
            progressBar: true,
          }
        );
        this.setState({ data: categories });
      }
    } catch (e) {
      this.props.navigate("/error", {
        error: _.get(e, "response.data.error", "error.unexpectedError"),
      });
    } finally {
      this.setState({ loading: false });
    }
  }

  componentWillUnmount() {
    this.subscription.remove();
  }

  async handleSubmit(values, { setSubmitting }) {
    this.setState({ loading: true });
    try {
      let response;
      values["isServiceCategory"] = true;
      if (!this.props.match.params.id) {
        response = await Services.addCategory(values);
      } else {
        const request = { id: this.props.match.params.id, ...values };
        response = await Services.updateCategory(request);
      }

      NotificationManager.success(
        this.props.intl.formatMessage({
          id: "global.operationSuccessful",
          defaultMessage: "Operation Successful",
        }),
        this.props.intl.formatMessage({
          id: "global.success",
          defaultMessage: "Success",
        })
      );

      this.props.navigate("/service-categories", {
        isSubmitted: true,
      });
    } catch (e) {
      NotificationManager.error(
        this.props.intl.formatMessage({
          id: e?.response?.data?.response || "error.unexpectedError",
          defaultMessage: "An unexpected error has occurred",
        }),
        this.props.intl.formatMessage({
          id: "global.error",
          defaultMessage: "Error",
        })
      );
    } finally {
      setSubmitting(false);
      this.setState({ loading: false });
    }
  }

  _renderBody(formikProps) {
    const { isSubmitting } = formikProps;
    return (
      <Layout noLayout={this.props.noLayout} loading={this.state.loading}>
        <HeaderTitle
          id="category.serviceCategories"
          defaultMessage="Service Categories"
        />
        <div className="dashboard-table-container card-layout">
          <div className="panel-primary">
            <header className="panel-title">
              {this.props.match.params.id && (
                <FormattedMessage
                  id="category.update"
                  defaultMessage="Update Category"
                />
              )}
              {!this.props.match.params.id && (
                <FormattedMessage
                  id="category.add"
                  defaultMessage="Add Category"
                />
              )}
            </header>

            <div className="">
              <Form>
                <div className="dashboard-row">
                  <FieldRow
                    type="text"
                    name="englishName"
                    labelId="category.englishName"
                    defaultLabel="Category Name (English)"
                    placeholderId="category.enterEnglishName"
                    defaultPlaceholder="Enter english name"
                    showRequiredFlag={true}
                    formikProps={formikProps}
                  />
                  <FieldRow
                    type="text"
                    name="arabicName"
                    labelId="category.arabicName"
                    defaultLabel="Category Name (Arabic)"
                    placeholderId="category.enterArabicName"
                    defaultPlaceholder="Enter arabic name"
                    showRequiredFlag={true}
                    formikProps={formikProps}
                  />
                  <FieldRow
                    type="textarea"
                    name="englishDescription"
                    labelId="category.englishDescription"
                    defaultLabel="English Description"
                    placeholderId="category.enterEnglishDescription"
                    defaultPlaceholder="Enter english description"
                    showRequiredFlag={true}
                    formikProps={formikProps}
                  />
                  <FieldRow
                    type="textarea"
                    name="arabicDescription"
                    labelId="category.arabicDescription"
                    defaultLabel="Arabic Description"
                    placeholderId="category.enterArabicDescription"
                    defaultPlaceholder="Enter arabic description"
                    showRequiredFlag={true}
                    formikProps={formikProps}
                  />

                  <FieldRow
                    name="logo"
                    type="file"
                    image={true}
                    cameraIcon={true}
                    showThumbnail={true}
                    labelId="category.logo"
                    defaultLabel="Logo"
                    placeholderId="category.selectLogo"
                    defaultPlaceholder="Select Logo"
                    // showRequiredFlag={false}
                    formikProps={formikProps}
                    crop={false}
                    aspectRatio={40 / 40}
                    imageSize="W:40 x H:40"
                  />

                  {appState.state.token?.role === "sadmin" && (
                    <FieldRow
                      name="isActive"
                      type="checkbox"
                      labelId="category.isActive&Web"
                      defaultLabel="Active / Display on services page for WEB"
                      formikProps={formikProps}
                    />
                  )}
                </div>
                <div className="dashboard-row -nobottom">
                  <CheckAccess
                    activityId={
                      ActivitiesEnum[
                        this.props.match.params.id
                          ? "service_categories__update_category_edit_reject"
                          : "service_categories__new"
                      ]
                    }
                  >
                    {this.props.authContext.authorized && (
                      <input
                        type="submit"
                        className="btn btn-primary"
                        value={this.props.intl.formatMessage({
                          id: "global.submit",
                          defaultMessage: "Submit",
                        })}
                        disabled={isSubmitting}
                      />
                    )}
                  </CheckAccess>
                  <Button
                    variant="link"
                    onClick={() => this.props.navigate("/service-categories")}
                    disabled={isSubmitting}
                  >
                    {this.props.intl.formatMessage({
                      id: "global.cancel",
                      defaultMessage: "Cancel",
                    })}
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </Layout>
    );
  }

  render() {
    const { intl } = this.props;

    return (
      <Auth requireAuth={true} roles={["sadmin"]}>
        <Formik
          innerRef={(f) => {
            this.formik = f;
          }}
          enableReinitialize
          initialValues={{
            englishName: this.state.data.englishName || "",
            arabicName: this.state.data.arabicName || "",
            englishDescription: this.state.data.englishDescription || "",
            arabicDescription: this.state.data.arabicDescription || "",
            logo: this.state.data.logo || "",
            isActive: this.props.match.params.id
              ? this.state.data.isActive
              : true,
          }}
          validationSchema={Yup.object().shape({
            englishName: Yup.string()
              .hasNoArabic(
                intl.formatMessage({
                  id: "error.arabicCharactersAreNotAllowedInThisField",
                  defaultMessage:
                    "Arabic characters are not allowed in this field",
                })
              )
              .label(
                intl.formatMessage({
                  id: "category.englishName",
                  defaultMessage: "English name",
                })
              )
              .trim()
              .required(),
            englishDescription: Yup.string()
              .hasNoArabic(
                intl.formatMessage({
                  id: "error.arabicCharactersAreNotAllowedInThisField",
                  defaultMessage:
                    "Arabic characters are not allowed in this field",
                })
              )
              .label(
                intl.formatMessage({
                  id: "category.englishDescription",
                  defaultMessage: "English description",
                })
              )
              .trim()
              .required(),
            arabicName: Yup.string()
              .label(
                intl.formatMessage({
                  id: "category.arabicName",
                  defaultMessage: "Arabic Name",
                })
              )
              .hasNoEnglish(
                intl.formatMessage({
                  id: "error.englishCharactersAreNotAllowedInThisField",
                  defaultMessage:
                    "English characters are not allowed in this field",
                })
              )
              .trim()
              .required(),
            arabicDescription: Yup.string()
              .label(
                intl.formatMessage({
                  id: "category.arabicDescription",
                  defaultMessage: "Arabic Description",
                })
              )
              .hasNoEnglish(
                intl.formatMessage({
                  id: "error.englishCharactersAreNotAllowedInThisField",
                  defaultMessage:
                    "English characters are not allowed in this field",
                })
              )
              .trim()
              .required(),
            logo: Yup.mixed().label(
              intl.formatMessage({
                id: "category.logo",
                defaultMessage: "Logo",
              })
            ),
          })}
          onSubmit={this.handleSubmit.bind(this)}
        >
          {this._renderBody.bind(this)}
        </Formik>
      </Auth>
    );
  }
}

export default withAuthContext(withRouter(injectIntl(ServiceCategoryForm)));
