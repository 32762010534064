import React, { Component } from "react";
import appState from "../../state/AppStateContainer";

export default class HeaderTitle extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    appState.setHeaderTitle(this.props.defaultMessage, this.props.id);
  }

  render() {
    return <div />;
  }
}
