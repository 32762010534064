import React, { useState, useEffect, useRef } from "react";
import { FormattedMessage, FormattedNumber, injectIntl } from "react-intl";
import Layout from "../shared/Layout";
import SubscriptionService from "../../services/subscriptionService";
import _ from "lodash";
import withRouter from "../../util/withRouter";
import Table from "../shared/Table";
import HeaderTitle from "../shared/HeaderTitle";
import moment from "moment";
import appState from "../../state/AppStateContainer";

const SubscribedOffersList = (props) => {
  const { intl, navigate } = props;

  const [count, setCount] = useState(0);
  const [data, setData] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [pageIndex, setPageIndex] = useState(-1);
  const [searchFilter, setSearchFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("all");
  const [loading, setLoading] = useState(false);
  const [disableFlag, setDisableFlag] = useState(false);
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");

  const pageSize = 10;
  const searchInputRef = useRef();
  const prevPropsRef = useRef();
  // const statusRef = useRef();

  useEffect(() => {
    prevPropsRef.current = props;
  }, []);

  const prevProps = prevPropsRef.current;

  useEffect(() => {
    if (props.location.pathname !== prevProps?.location?.pathname) {
      if (!props.match.params.isFormOpen) {
        if (_.get(props.location, "state.isSubmitted") === true) {
          setPageIndex(-1);
          setData([]);
          setHasMore(true);
          setSearchFilter(searchInputRef.current.value);
          // setStatusFilter(statusRef.current.value);
          setDisableFlag(true);
        }
      }
    }
  }, [props.location.pathname]);

  const loadMore = async () => {
    setLoading(true);
    setPageIndex((prevState) => prevState + 1);

    setHasMore(false);
    const _pageIndex = pageIndex + 1;

    const options = {
      progressBar: true,
      alphabeticalOrder: true,
      fromDate: from ? moment(from).startOf("day").toString() : null,
      toDate: to ? moment(to).endOf("day").toString() : null,
    };

    const pageData = await SubscriptionService.getSubscribedOffers(
      searchFilter,
      statusFilter,
      _pageIndex,
      pageSize,
      from ? moment(from).startOf("day").toString() : null,
      to ? moment(to).endOf("day").toString() : null,
      undefined,
      options
    );
    let summary = { count };

    if (_pageIndex === 0) {
      summary = await SubscriptionService.getSubscribedOffers(
        searchFilter,
        statusFilter,
        undefined,
        undefined,
        from ? moment(from).startOf("day").toString() : null,
        to ? moment(to).endOf("day").toString() : null,
        undefined,
        options,
        summary
      );
    }

    setData((prevData) => [...prevData, ...pageData]);
    setHasMore(pageData.length > 0);
    setDisableFlag(false);
    setLoading(false);
    if (_pageIndex === 0) {
      setCount(summary.count);
    }
  };

  const resetData = (e) => {
    setPageIndex(-1);
    setData([]);
    setHasMore(true);
    setSearchFilter(searchInputRef.current.value);
    // setStatusFilter(statusRef.current.value);
    setDisableFlag(true);
    if (e) e.preventDefault();
  };

  const resetPage = (e) => {
    setPageIndex(-1);
    setData([]);
    setHasMore(true);
    setSearchFilter("");
    setStatusFilter("all");
    setDisableFlag(true);
    setFrom("");
    setTo("");

    searchInputRef.current.value = "";
    // statusRef.current.value = "all";
    e.preventDefault();
  };

  const lang = appState.state.language;

  return (
    <Layout hidden={props.match.params.isFormOpen} loading={loading}>
      <HeaderTitle
        id="subscription.subscribedOffer"
        defaultMessage="Subscribed Packages"
      />

      <div className="table-header clearfix">
        <form className="row" onSubmit={resetData}>
          <div className="col-md-2 col-sm-4 col-xs-6">
            <div className="has-feedback">
              <input
                type="text"
                ref={searchInputRef}
                className="form-control"
                placeholder={intl.formatMessage({
                  id: "global.search",
                  defaultMessage: "Search",
                })}
              />
              <i className="glyphicon glyphicon-search form-control-feedback" />
            </div>
          </div>
          <div className="col force--inline">
            <div className="tooltip-master">
              <button
                href="javascript:;"
                className="btn btn-primary"
                onClick={resetData}
                disabled={disableFlag}
              >
                <i className="material-icons">search</i>
              </button>
              <span className="tooltiptext">
                <FormattedMessage id="global.filter" defaultMessage="Filter" />
              </span>
            </div>
          </div>
          <div className="col force--inline">
            <div className="tooltip-master">
              <button
                href="javascript:;"
                className="btn btn-primary"
                onClick={resetPage}
                disabled={disableFlag}
              >
                <i className="material-icons">settings_backup_restore</i>
              </button>
              <span className="tooltiptext">
                <FormattedMessage
                  id="global.resetFilter"
                  defaultMessage="Reset Filter"
                />
              </span>
            </div>
          </div>
        </form>
      </div>

      <div className="row">
        <div className="col-md-12">
          <FormattedMessage
            id="global.totalCount"
            defaultMessage="Total Count"
          />

          <strong>
            {" "}
            <FormattedNumber
              style="decimal"
              maximumFractionDigits={0}
              minimumFractionDigits={0}
              value={count}
            />{" "}
          </strong>
        </div>
      </div>

      <div className="dashboard-table-container card-layout">
        <div className="table-responsive">
          <Table
            loadMore={loadMore}
            hasMore={hasMore}
            caption={
              (!data || data == 0) && (
                <caption
                  style={{
                    captionSide: "bottom",
                    textAlign: "center",
                    margin: "20px",
                    color: "darkgray",
                  }}
                >
                  <FormattedMessage
                    id="global.noData"
                    defaultMessage="No data available"
                  />
                </caption>
              )
            }
            headerRow={
              <tr>
                <th>
                  <FormattedMessage
                    id="subscribed.fullName"
                    defaultMessage="Customer Name"
                  />
                </th>
                <th>
                  <FormattedMessage
                    defaultMessage="Phone Number"
                    id="subscribed.phoneNumber"
                  />
                </th>
                <th>
                  <FormattedMessage
                    defaultMessage="License Plate"
                    id="subscribed.licencePlate"
                  />
                </th>
                <th>
                  <FormattedMessage
                    id="subscribed.subscriptionName"
                    defaultMessage="Subscription Name"
                  />
                </th>
                <th>
                  <FormattedMessage
                    id="subscribed.packageId"
                    defaultMessage="Subscription Id"
                  />
                </th>
                <th>
                  <FormattedMessage
                    id="subscribed.branchName"
                    defaultMessage="Branch Name"
                  />
                </th>
                <th>
                  <FormattedMessage
                    id="subscribed.createdAt"
                    defaultMessage="Purchase Date"
                  />
                </th>
                <th>
                  <FormattedMessage
                    id="subscribed.endDate"
                    defaultMessage="Expiry Date"
                  />
                </th>
              </tr>
            }
          >
            {data?.map((s) => (
              <tr
                key={s._id}
                onClick={() => navigate(`/subscribed-offers/${s._id}`)}
                className={`cases-trow link-row ${
                  s.isPaid ? "paid-order" : "unpaid-order"
                }-record`}
              >
                <td>{s.title + " " + s.firstName + " " + s.lastName}</td>
                <td>{s.phoneNumber}</td>
                <td>{s.licencePlate}</td>
                <td>{s.subscriptionData?.name?.["value_" + lang]}</td>
                <td>{s.hashId}</td>
                <td>{s.branchData?.name?.["value_" + lang]}</td>
                <td>{moment(s.createdAt).utc().format("YYYY-MM-DD")}</td>
                <td>{moment(s.endDate).utc().format("YYYY-MM-DD")}</td>
              </tr>
            ))}
          </Table>
        </div>
      </div>
    </Layout>
  );
};

export default withRouter(injectIntl(SubscribedOffersList));