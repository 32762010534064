import React, { useState, useRef, useEffect } from "react";
import { Form, Field, Formik } from "formik";
import * as Yup from "yup";
import { NotificationManager } from "react-notifications";
import { FormattedMessage, injectIntl } from "react-intl";
import services from "../../services";
import appState from "../../state/AppStateContainer";
import _ from "lodash";
import withRouter from "../../util/withRouter";

const ResetPassword = (props) => {
  const { intl, navigate, location } = props;
  const lang = appState.state.language;
  const isCreateAdminPassword = location.pathname.includes("/set-password");

  const formikRef = useRef();

  const [showPassword, setShowPassword] = useState({
    newPassword: false,
    confirmPassword: false,
  });

  useEffect(() => {
    if (appState.state.token?.accessToken) navigate("/");
  }, [appState.state.token?.accessToken]);

  useEffect(() => {
    formikRef.current.validateForm();
  }, [lang]);

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      if (!props?.match?.params?.access_token) return;
      const payload = {
        ...values,
        code: props?.match?.params?.access_token,
      };
      let result;
      if (isCreateAdminPassword) {
        result = await services.setAdminPassword(payload);
      } else {
        result = await services.resetPasswordByAdmin(payload);
      }
      NotificationManager.success(
        intl.formatMessage({
          id: "global.operationSuccessful",
          defaultMessage: "Operation Successful",
        }),
        intl.formatMessage({
          id: "global.success",
          defaultMessage: "Success",
        })
      );
      if (result?.statusCode === 200) navigate("/");
    } catch (e) {
      NotificationManager.error(
        props.intl.formatMessage({
          id: e?.response?.data?.response || "error.unexpectedError",
          defaultMessage: "An unexpected error has occurred",
        }),
        props.intl.formatMessage({
          id: "global.error",
          defaultMessage: "Error",
        })
      );
    } finally {
      setSubmitting(false);
    }
  };

  function equalTo(ref, msg) {
    return this.test({
      name: "equalTo",
      exclusive: false,
      message: msg || "${path} must be the same as ${reference}",
      params: {
        reference: ref.path,
      },
      test: function (value) {
        return value === this.resolve(ref);
      },
    });
  }

  Yup.addMethod(Yup.string, "equalTo", equalTo);

  const validationSchema = () => {
    return Yup.object().shape({
      newPassword: Yup.string()
        .label(
          intl.formatMessage({
            id: "profile.newPassword",
            defaultMessage: "New password",
          })
        )
        .trim()
        .matches(
          /^(?=.*[0-9])(?=.*[!@#$%^&*/._(){}:";,=?-])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*/._(){}:";,=?-]{8,}$/,
          intl.formatMessage({
            id: "profile.passwordAlphanumeric",
            defaultMessage:
              "Password must be at least 8 characters long and include at least one letter, one number, and one special character.",
          })
        )
        .required(),
      confirmPassword: Yup.string()
        .label(
          intl.formatMessage({
            id: "profile.confirmPassword",
            defaultMessage: "Confirm password",
          })
        )
        .equalTo(
          Yup.ref("newPassword"),
          intl.formatMessage({
            id: "profile.confirmPasswordShouldMatchNewPassword",
            defaultMessage: "Confirm password should match new password",
          })
        )
        .matches(
          /^(?=.*[0-9])(?=.*[!@#$%^&*/._(){}:";,=?-])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*/._(){}:";,=?-]{8,}$/,
          intl.formatMessage({
            id: "profile.passwordAlphanumeric",
            defaultMessage:
              "Password must be at least 8 characters long and include at least one letter, one number, and one special character.",
          })
        )
        .required(),
    });
  };

  return (
    <>
      <div
        className={`${
          appState.state.language === "en"
            ? "en-bg-sky-white"
            : "ar-bg-sky-white"
        }`}
      >
        <div className="lang-wrapper">
          <a
            className=""
            style={{ cursor: "pointer" }}
            onClick={() => {
              appState.changeLanguage();
            }}
          >
            <span className="fa fa-language fa-2x" />
          </a>
        </div>

        <div className="login-row-wrapper">
          <div className="service-advisor-login-wrapper">
            <div className="login-left-box">
              <h3>
                {isCreateAdminPassword ? (
                  <FormattedMessage
                    id="setPassword.setPassword"
                    defaultMessage="Set Your New Password"
                  />
                ) : (
                  <FormattedMessage
                    id="resetPassword.changePassword"
                    defaultMessage="Change Your Password"
                  />
                )}
              </h3>
            </div>
            <div className="right-form-box">
              <Formik
                initialValues={{
                  newPassword: "",
                  confirmPassword: "",
                }}
                validationSchema={validationSchema}
                innerRef={formikRef}
                onSubmit={handleSubmit}
              >
                {({ touched, errors, isSubmitting }) => (
                  <Form>
                    <div>
                      <div className="autohub-logo-wrapper">
                        <img
                          src={`/design/images/${
                            lang === "en" ? "ah-logo.webp" : "ah-logo-ar.webp"
                          }`}
                          alt="The Auto Hub CMS"
                        />
                      </div>
                      <div className="emoji-wrapper full-row">
                        <Field
                          type={
                            showPassword["newPassword"] ? "text" : "password"
                          }
                          className={`service-advisor-login-field ${
                            appState.state.language === "ar"
                              ? "arabic-field"
                              : ""
                          }`}
                          name="newPassword"
                          placeholder={intl.formatMessage({
                            id: "login.newPassword",
                            defaultMessage: "New Password",
                          })}
                        />
                        <span
                          className="input-group-btn emoji-floater"
                          style={{
                            paddingRight: "14px",
                            paddingLeft: "14px",
                          }}
                        >
                          <a
                            onClick={() =>
                              setShowPassword({
                                ...showPassword,
                                newPassword: !showPassword.newPassword,
                              })
                            }
                            className="btn btn-default"
                          >
                            <i
                              className={`fa ${
                                showPassword.newPassword
                                  ? "fa-eye"
                                  : "fa-eye-slash"
                              }`}
                            />
                          </a>
                        </span>
                        {touched.newPassword && errors.newPassword && (
                          <p className="text-warning">{errors.newPassword}</p>
                        )}
                      </div>
                      <div className="emoji-wrapper full-row">
                        <Field
                          type={
                            showPassword["confirmPassword"]
                              ? "text"
                              : "password"
                          }
                          className={`service-advisor-login-field ${
                            appState.state.language === "ar"
                              ? "arabic-field"
                              : ""
                          }`}
                          name="confirmPassword"
                          placeholder={intl.formatMessage({
                            id: "login.confirmPassword",
                            defaultMessage: "Confirm Password",
                          })}
                        />

                        <span
                          className="input-group-btn emoji-floater"
                          style={{
                            paddingRight: "14px",
                            paddingLeft: "14px",
                          }}
                        >
                          <a
                            onClick={() =>
                              setShowPassword({
                                ...showPassword,
                                confirmPassword: !showPassword.confirmPassword,
                              })
                            }
                            className="btn btn-default"
                          >
                            <i
                              className={`fa ${
                                showPassword.confirmPassword
                                  ? "fa-eye"
                                  : "fa-eye-slash"
                              }`}
                            />
                          </a>
                        </span>
                        {touched.confirmPassword && errors.confirmPassword && (
                          <p className="text-warning">
                            {errors.confirmPassword}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="full-row login-btn-wrapper">
                      <input
                        type="submit"
                        disabled={
                          !props?.match?.params?.access_token || isSubmitting
                        }
                        value={intl.formatMessage({
                          id: isCreateAdminPassword
                            ? "global.setPasswordBtn"
                            : "global.changePassword",
                          defaultMessage: isCreateAdminPassword
                            ? "Set Password"
                            : "Change Password",
                        })}
                        className="btn btn-primary btn-login"
                      />
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(injectIntl(ResetPassword));