import React, { Component } from "react";
import { FormattedMessage, injectIntl, FormattedNumber } from "react-intl";
import Layout from "../shared/Layout";
import Services from "../../services";
import _ from "lodash";
import Auth from "../shared/Auth";
import { Link } from "react-router-dom";
import withRouter from "../../util/withRouter";
import Table from "../shared/Table";
import appState from "../../state/AppStateContainer";
import HeaderTitle from "../shared/HeaderTitle";
import CheckAccess from "../shared/CheckAccess";
import { ActivitiesEnum } from "../../staticData/Activities";
import moment from "moment";
import { isLtr } from "../../util/utils";
import { SingleDatePicker } from "react-dates";
import Select from "react-select";
import ExportToExcel from "../shared/ExportToExcel";
import expressCar from "./expressCar.png";
import BranchesService from "../../services/branchesService";

export class AppointmentList extends Component {
  state = {
    data: [],
    hasMore: true,
    pageIndex: -1,
    pageSize: 10,
    searchFilter: "",
    statusFilter: "all",
    branchesFilter: "all",
    servicesFilter: "all",
    updatingRowId: "",
    searchValueInput: "",
    branches: [],
    services: [],
    count: 0,
  };

  async componentDidMount() {
    this.setState({ loading: true });
    try {
      const branches = await BranchesService.getActiveBranches();
      const data = await Services.getBranchesAndServices();
      this.setState({ branches, services: data?.services });
    } catch (e) {
      this.props.navigate("/error", {
        error: _.get(e, "response.data.error", "error.unexpectedError"),
      });
    } finally {
      this.setState({ loading: false });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      if (!this.props.match.params.isFormOpen) {
        if (_.get(this, "props.location.state.isSubmitted") === true) {
          this.setState({
            pageIndex: -1,
            data: [],
            hasMore: true,
            searchFilter: this.searchInput?.value,
            statusFilter: this.statusSelect.value,
            expireFilter: this.expireStatusSelect.value,
            subscriptionAppointment: this.subscriptionAppointmentSelect.value,
            collectionAndDeliveryAppointment:
              this.collectionAndDeliveryAppointmentSelect.value,
            expressAppointment: this.expressAppointmentSelect.value,
            expressAppointmentApproval: this.expressApproval.value,
            disableFlag: true,
          });
        } else {
          this.setState({ updatingRowId: "" });
        }
      }
    }
  }

  onExport = async (startDate, endDate, isAppointmentDateFilter = false) => {
    if (!startDate) return;
    if (!endDate) {
      endDate = startDate;
    }
    this.setState({ loading: true });
    try {
      this.setState({ exportExcelLoading: true });
      await Services.downloadAppointmentList(
        this.state.searchFilter,
        this.state.statusFilter,
        moment(startDate)
          .utcOffset(60 * 3)
          .startOf("day")
          .toString(),
        moment(endDate)
          .utcOffset(60 * 3)
          .endOf("day")
          .toString(),
        isAppointmentDateFilter
      );
    } catch (err) {}
    this.setState({ loading: false });
  };

  async loadMore() {
    this.setState(
      (currentState) => {
        return {
          pageIndex: currentState.pageIndex + 1,
          hasMore: false,
          loading: true,
        };
      },
      async () => {
        const options = {
          progressBar: true,
          alphabeticalOrder: true,
          fromDate: this.state.from
            ? moment(this.state.from).startOf("day").toString()
            : null,
          toDate: this.state.to
            ? moment(this.state.to).endOf("day").toString()
            : null,
        };
        const pageIndex = 0;

        const pageData = await Services.getscheduleAppointment(
          this.state.searchFilter,
          this.state.statusFilter,
          this.state.expireFilter,
          this.state.subscriptionAppointment,
          this.state.collectionAndDeliveryAppointment,
          this.state.expressAppointment,
          this.state.expressAppointmentApproval,
          this.state.branchesFilter?.value
            ? this.state.branchesFilter?.value
            : this.state.branchesFilter,
          this.state.servicesFilter?.value
            ? this.state.servicesFilter?.value
            : this.state.servicesFilter,
          this.state.pageIndex,
          this.state.pageSize,
          options
        );

        const summary =
          pageIndex === 0
            ? await Services.getscheduleAppointment(
                this.state.searchFilter,
                this.state.statusFilter,
                this.state.expireFilter,
                this.state.subscriptionAppointment,
                this.state.collectionAndDeliveryAppointment,
                this.state.expressAppointment,
                this.state.expressAppointmentApproval,
                this.state.branchesFilter?.value
                  ? this.state.branchesFilter?.value
                  : this.state.branchesFilter,
                this.state.servicesFilter?.value
                  ? this.state.servicesFilter?.value
                  : this.state.servicesFilter,
                undefined,
                undefined,
                { ...options, summary: 1 }
              )
            : { count: this.state.count };

        this.setState((currentState) => {
          return {
            data: currentState.data.concat(pageData),
            hasMore: pageData.length > 0,
            disableFlag: false,
            count: summary.count,
            loading: false,
          };
        });
      }
    );
  }

  resetData(e) {
    this.setState({
      pageIndex: -1,
      data: [],
      hasMore: true,
      searchFilter: this.searchInput?.value,
      statusFilter: this.statusSelect.value,
      expireFilter: this.expireStatusSelect.value,
      collectionAndDeliveryAppointment:
        this.collectionAndDeliveryAppointmentSelect.value,
      expressAppointment: this.expressAppointmentSelect.value,
      expressAppointmentApproval: this.expressApproval.value,
      branchesFilter: this.state.branchesFilter,
      servicesFilter: this.state.servicesFilter,
      disableFlag: false,
    });
    if (e) e.preventDefault();
  }

  resetPage(e) {
    this.setState({
      pageIndex: -1,
      data: [],
      hasMore: true,
      searchFilter: "",
      statusFilter: "all",
      branchesFilter: "all",
      servicesFilter: "all",
      disableFlag: false,
      searchValueInput: null,
      to: undefined,
      from: undefined,
      subscriptionAppointment: "all",
      collectionAndDeliveryAppointment: "all",
      expressAppointment: "all",
      expressAppointmentApproval: "all",
      expireFilter: "all",
    });

    this.searchInput.value = "";
    this.statusSelect.value = "all";
    this.expireStatusSelect.value = "all";
    this.subscriptionAppointmentSelect.value = "all";
    this.collectionAndDeliveryAppointmentSelect.value = "all";
    this.expressAppointmentSelect.value = "all";
    this.expressApproval.value = "all";
    if (e) e.preventDefault();
  }

  render() {
    const { intl } = this.props;
    return (
      <>
        <Auth requireAuth={true} roles={["sadmin"]}>
          <Layout
            hidden={this.props.match.params.isFormOpen}
            loading={this.state.loading}
          >
            <HeaderTitle
              id="appointment.scheduleAppointments"
              defaultMessage="Scheduled Appointments"
            />
            <div className="main-container">
              <div className="table-header clearfix">
                <form className="row" onSubmit={this.resetData.bind(this)}>
                  <div className="col-md-2 col-sm-4 col-xs-6">
                    <div className="has-feedback">
                      <input
                        type="text"
                        ref={(c) => {
                          this.searchInput = c;
                        }}
                        className="form-control"
                        value={this.searchValueInput}
                        placeholder={intl.formatMessage({
                          id: "global.search",
                          defaultMessage: "Search",
                        })}
                      />
                      <i className="glyphicon glyphicon-search form-control-feedback" />
                    </div>
                  </div>
                  <div className="col-lg-2 col-md-4 col-sm-12 col-xs-6">
                    <SingleDatePicker
                      id="fromDate"
                      placeholder={intl.formatMessage({
                        id: "global.from",
                        defaultMessage: "From",
                      })}
                      block={true}
                      small={true}
                      isOutsideRange={() => false}
                      numberOfMonths={1}
                      initialDate={this.state.from}
                      isRTL={appState.state.language === "ar" ? true : false}
                      date={this.state.from}
                      onDateChange={(date) => {
                        this.setState(
                          { from: date },
                          this.resetData.bind(this)
                        );
                      }}
                      focused={this.state.focusedFrom}
                      onFocusChange={({ focused }) =>
                        this.setState({ focusedFrom: focused })
                      }
                    />
                  </div>
                  <div className="col-lg-2 col-md-4 col-sm-12 col-xs-6">
                    <SingleDatePicker
                      id="toDate"
                      block={true}
                      placeholder={intl.formatMessage({
                        id: "global.to",
                        defaultMessage: "To",
                      })}
                      small={true}
                      isOutsideRange={() => false}
                      numberOfMonths={1}
                      initialDate={this.state.to}
                      isRTL={appState.state.language === "ar" ? true : false}
                      date={this.state.to}
                      onDateChange={(date) => {
                        this.setState({ to: date }, this.resetData.bind(this));
                      }}
                      focused={this.state.focusedTo}
                      onFocusChange={({ focused }) =>
                        this.setState({ focusedTo: focused })
                      }
                    />
                  </div>

                  <div className="col-md-2 col-sm-4 col-xs-6">
                    <select
                      ref={(c) => {
                        this.expireStatusSelect = c;
                      }}
                      onChange={this.resetData.bind(this)}
                      className="form-control"
                    >
                      <option value="all">
                        {intl.formatMessage({
                          id: "global.expireStatus",
                          defaultMessage: "Expire Status",
                        })}
                      </option>
                      <option value={true}>
                        {intl.formatMessage({
                          id: "global.yes",
                          defaultMessage: "Yes",
                        })}
                      </option>
                      <option value={false}>
                        {intl.formatMessage({
                          id: "global.no",
                          defaultMessage: "No",
                        })}
                      </option>
                    </select>
                  </div>

                  <div className="col-md-2 col-sm-4 col-xs-6">
                    <select
                      ref={(c) => {
                        this.expressAppointmentSelect = c;
                      }}
                      onChange={this.resetData.bind(this)}
                      className="form-control"
                    >
                      <option value="all">
                        {intl.formatMessage({
                          id: "global.expressAppointment",
                          defaultMessage: "Express Appointment",
                        })}
                      </option>
                      <option value={"yes"}>
                        {intl.formatMessage({
                          id: "global.yes",
                          defaultMessage: "Yes",
                        })}
                      </option>
                      <option value={"no"}>
                        {intl.formatMessage({
                          id: "global.no",
                          defaultMessage: "No",
                        })}
                      </option>
                    </select>
                  </div>
                  <div className="col-md-2 col-sm-4 col-xs-6">
                    <select
                      ref={(c) => {
                        this.expressApproval = c;
                      }}
                      onChange={this.resetData.bind(this)}
                      className="form-control"
                    >
                      <option value="all">
                        {intl.formatMessage({
                          id: "global.expressAppointmentApproval",
                          defaultMessage: "Express Approval",
                        })}
                      </option>
                      <option value={"yes"}>
                        {intl.formatMessage({
                          id: "global.yes",
                          defaultMessage: "Yes",
                        })}
                      </option>
                      <option value={"no"}>
                        {intl.formatMessage({
                          id: "global.no",
                          defaultMessage: "No",
                        })}
                      </option>
                    </select>
                  </div>

                  <div className="col-md-2 col-sm-4 col-xs-6">
                    <select
                      ref={(c) => {
                        this.statusSelect = c;
                      }}
                      onChange={this.resetData.bind(this)}
                      className="form-control"
                    >
                      <option value="all">
                        {intl.formatMessage({
                          id: "global.status",
                          defaultMessage: "Cancellation Status",
                        })}
                      </option>
                      <option value={true}>
                        {intl.formatMessage({
                          id: "global.yes",
                          defaultMessage: "Yes",
                        })}
                      </option>
                      <option value={false}>
                        {intl.formatMessage({
                          id: "global.no",
                          defaultMessage: "No",
                        })}
                      </option>
                    </select>
                  </div>
                  <div className="col-md-2 col-sm-4 col-xs-6">
                    <select
                      ref={(c) => {
                        this.subscriptionAppointmentSelect = c;
                      }}
                      onChange={this.resetData.bind(this)}
                      className="form-control"
                    >
                      <option value="all">
                        {intl.formatMessage({
                          id: "global.status",
                          defaultMessage: "Subscription Appointment",
                        })}
                      </option>
                      <option value={true}>
                        {intl.formatMessage({
                          id: "global.yes",
                          defaultMessage: "Yes",
                        })}
                      </option>
                      <option value={false}>
                        {intl.formatMessage({
                          id: "global.no",
                          defaultMessage: "No",
                        })}
                      </option>
                    </select>
                  </div>
                  <div className="col-md-2 col-sm-4 col-xs-6">
                    <select
                      ref={(c) => {
                        this.collectionAndDeliveryAppointmentSelect = c;
                      }}
                      onChange={this.resetData.bind(this)}
                      className="form-control"
                    >
                      <option value="all">
                        {intl.formatMessage({
                          id: "global.status",
                          defaultMessage: "Collection And Delivery",
                        })}
                      </option>
                      <option value={true}>
                        {intl.formatMessage({
                          id: "global.yes",
                          defaultMessage: "Yes",
                        })}
                      </option>
                      <option value={false}>
                        {intl.formatMessage({
                          id: "global.no",
                          defaultMessage: "No",
                        })}
                      </option>
                    </select>
                  </div>
                  <div className="col-md-2 col-sm-4 col-xs-6">
                    <Select
                      isDisabled={this.state.disableFlag}
                      options={this.state.branches?.map((c) => {
                        return {
                          value: c?._id,
                          label:
                            appState.state.language === "en"
                              ? c?.name?.value_en
                              : c?.name?.value_ar || c?.name?.value_en,
                        };
                      })}
                      placeholder={intl.formatMessage({
                        id: "global.branches",
                        defaultMessage: "Branches",
                      })}
                      value={this.state.branchesFilter}
                      onChange={(v) => {
                        this.setState(
                          { branchesFilter: v },
                          this.resetData.bind(this)
                        );
                      }}
                    />
                  </div>
                  <div className="col-md-2 col-sm-4 col-xs-6">
                    <Select
                      isDisabled={this.state.disableFlag}
                      options={this.state.services?.map((c) => {
                        return {
                          value: c?._id,
                          label:
                            appState.state.language === "en"
                              ? c?.name?.value_en
                              : c?.name?.value_ar || c?.name?.value_en,
                        };
                      })}
                      placeholder={intl.formatMessage({
                        id: "global.services",
                        defaultMessage: "Services",
                      })}
                      value={this.state.servicesFilter}
                      onChange={(v) => {
                        this.setState(
                          { servicesFilter: v },
                          this.resetData.bind(this)
                        );
                      }}
                    />
                  </div>
                  <div className="col force--inline">
                    <div className="tooltip-master">
                      <button
                        // href="javascript:;"
                        className="btn btn-primary"
                        onClick={this.resetData.bind(this)}
                        disabled={this.state.disableFlag}
                      >
                        <i className="material-icons">search</i>
                      </button>
                      <span className="tooltiptext">
                        <FormattedMessage
                          id="global.filter"
                          defaultMessage="Filter"
                        />
                      </span>
                    </div>
                  </div>
                  <div className="col force--inline">
                    <div className="tooltip-master">
                      <button
                        // href="javascript:;"
                        className="btn btn-primary"
                        onClick={this.resetPage.bind(this)}
                        disabled={this.state.disableFlag}
                      >
                        <i className="material-icons">
                          settings_backup_restore
                        </i>
                      </button>
                      <span className="tooltiptext">
                        <FormattedMessage
                          id="global.resetFilter"
                          defaultMessage="Reset Filter"
                        />
                      </span>
                    </div>
                  </div>
                  <div className="col-xs-3 pull-right">
                    <div className="panel-group-buttons pull-right">
                      <CheckAccess
                        activityId={
                          ActivitiesEnum.schedule_appointment_export_to_excel
                        }
                      >
                        <ExportToExcel
                          onExport={this.onExport}
                          toShowCheckbox={true}
                        />
                      </CheckAccess>
                    </div>
                  </div>
                </form>

                <CheckAccess
                  activityId={ActivitiesEnum.schedule_appointment_add}
                >
                  <div className="row">
                    <div className="col-xs-3 pull-right">
                      <div className="panel-group-buttons pull-right">
                        <Link className="btn btn-special " to="/appointment">
                          <span className="fa fa-plus" />{" "}
                          <FormattedMessage
                            id="appointment.scheduleNew"
                            defaultMessage="Schedule"
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                </CheckAccess>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <FormattedMessage
                    id="global.totalCount"
                    defaultMessage="Total count"
                  />{" "}
                  <strong>
                    <FormattedNumber
                      style="decimal"
                      maximumFractionDigits={0}
                      minimumFractionDigits={0}
                      value={this.state.count}
                    />{" "}
                  </strong>
                </div>
              </div>

              <CheckAccess
                activityId={ActivitiesEnum.schedule_appointment_all}
                accessOnChange={this.state.searchFilter}
              >
                <div className="dashboard-table-container card-layout">
                  <div className="table-responsive">
                    <Table
                      loadMore={this.loadMore.bind(this)}
                      hasMore={this.state.hasMore}
                      caption={
                        (!this.state.data || this.state.data.length == 0) && (
                          <caption
                            style={{
                              captionSide: "bottom",
                              textAlign: "center",
                              margin: "20px",
                              color: "darkgray",
                            }}
                          >
                            <FormattedMessage
                              id="global.noData"
                              defaultMessage="No data available"
                            />
                          </caption>
                        )
                      }
                      headerRow={
                        <tr>
                          <th>{""}</th>
                          <th>
                            <FormattedMessage
                              id="appointment.name"
                              defaultMessage="Name"
                            />
                          </th>
                          <th>
                            <FormattedMessage
                              defaultMessage="Phone Number"
                              id="appointment.phoneNumber"
                            />
                          </th>
                          <CheckAccess
                            activityId={ActivitiesEnum.schedule_appointment_all}
                          >
                            <th>
                              <FormattedMessage
                                id="appointment.city"
                                defaultMessage="City"
                              />
                            </th>

                            <th>
                              <FormattedMessage
                                id="appointment.branchName"
                                defaultMessage="Branch Name"
                              />
                            </th>
                            <th>
                              <FormattedMessage
                                id="appointment.serviceName"
                                defaultMessage="Service Name"
                              />
                            </th>

                            <th>
                              <FormattedMessage
                                id="appointment.date"
                                defaultMessage="Schedule Date"
                              />
                            </th>

                            <th>
                              <FormattedMessage
                                id="appointment.isExpired"
                                defaultMessage="Expired"
                              />
                            </th>

                            <th>
                              <FormattedMessage
                                id="appointment.isCanceled"
                                defaultMessage="Canceled"
                              />
                            </th>

                            <th>
                              <FormattedMessage
                                id="appointment.cancelReason"
                                defaultMessage="Reason for Cancelation"
                              />
                            </th>
                            <th>
                              <FormattedMessage
                                id="appointment.expressApproval"
                                defaultMessage="Express Approved"
                              />
                            </th>
                            <th>
                              <FormattedMessage
                                id="appointment.isServed"
                                defaultMessage="Is Served"
                              />
                            </th>
                            <th>
                              <FormattedMessage
                                id="appointment.packageId"
                                defaultMessage="Subscription Id (If Any)"
                              />
                            </th>
                            <th>
                              <FormattedMessage
                                id="appointment.CreatedBy-Platform"
                                defaultMessage="CreatedBy / Platform"
                              />
                            </th>
                          </CheckAccess>
                          <th style={{ width: "20px" }} />
                        </tr>
                      }
                    >
                      {this.state?.data &&
                        this.state?.data.map((car) => (
                          <tr
                            key={car?._id}
                            onClick={() => {
                              if (!car?.isCanceled && !car?.isExpired) {
                                this.setState(
                                  {
                                    updatingRowId: car?._id,
                                  },
                                  () => {
                                    this.props.navigate(
                                      `/appointment/${car._id}`
                                    );
                                  }
                                );
                              }
                            }}
                            className={`cases-trow ${
                              car?.isCanceled || car?.isExpired
                                ? "disabled"
                                : "link-row"
                            } ${
                              car.isPaidService && !car.isPaid
                                ? "unpaid-order"
                                : "paid-order"
                            }-record`}
                            style={{
                              backgroundColor:
                                car.isExpressUpdated && "#29c0dc4d",
                            }}
                          >
                            <td>
                              {car?.expressDocuments?.length > 0 ? (
                                <img
                                  src={expressCar}
                                  alt="express appointment"
                                  width="50px"
                                />
                              ) : (
                                ""
                              )}
                            </td>
                            <td>
                              {car?.name
                                ?.replace("null", "")
                                ?.replace("undefined", "")}
                            </td>

                            <td className="direction">{car?.phoneNumber}</td>
                            <CheckAccess
                              activityId={
                                ActivitiesEnum.schedule_appointment_all
                              }
                            >
                              <td>{car?.city}</td>
                              <td>
                                {
                                  car?.branch?.name?.[
                                    isLtr() ? "value_en" : "value_ar"
                                  ]
                                }
                              </td>
                              <td>
                                {
                                  car?._services[0]?.name?.[
                                    isLtr() ? "value_en" : "value_ar"
                                  ]
                                }
                              </td>
                              <td>{moment(car?.date).format("DD MMM YYYY")}</td>
                              <td>{car?.isExpired ? "Yes" : "No"}</td>
                              <td>{car?.isCanceled ? "Yes" : "No"}</td>
                              <td>{car?.reasonForCancelation || ""}</td>
                              <td>
                                {car?.isExpressAppointmentConfirmed
                                  ? "Yes"
                                  : "No"}
                              </td>
                              <td>{car?.qms?.isServed ? "Yes" : "No"}</td>
                              <td>
                                {car?.userSubscription[0]?.hashId || "N/A"}
                              </td>
                              <td>
                                {car?.user?.isSubAdmin || car?.isCreatedFromCms
                                  ? `${car?.user?.fullName || ""} on CMS`
                                  : `${(
                                      (car?.user?.firstName || "") +
                                      " " +
                                      (car?.user?.lastName || "")
                                    ).trim()} on WEB`}
                              </td>
                            </CheckAccess>
                          </tr>
                        ))}
                    </Table>
                  </div>
                </div>
              </CheckAccess>
            </div>
          </Layout>
        </Auth>
      </>
    );
  }
}

export default withRouter(injectIntl(AppointmentList));
