import React, { Component } from "react";
import _ from "lodash";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";


export default class Tag extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    handleChange = (emails) => {
        const { name, formikprops } = this.props;

        this.setState({ emails });
        const touchedConfig = {};
        touchedConfig[name] = true;
        formikprops.setTouched(touchedConfig);
        formikprops.setFieldValue(name, emails);
    }

    render() {
        const { name, emailsPlaceholderText, formikprops } = this.props;

        return (
            <React.Fragment>
                <TagsInput
                    inputProps={{
                        className: 'react-tagsinput-input',
                        placeholder: emailsPlaceholderText
                    }}
                    value={formikprops.values[name] || []}
                    onChange={this.handleChange}
                />
            </React.Fragment>
        );
    }
}
