import React, { useState, useRef, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import { Button, Modal } from "react-bootstrap";
import { FormattedMessage, injectIntl } from "react-intl";
import withRouter from "../../util/withRouter";
import * as Yup from "yup";
import { automateOTPFields } from "../../util/utils";
import appState from "../../state/AppStateContainer";

const OtpModal = ({
  intl,
  setShow,
  show,
  handleVerifyUserSubmit,
  showError,
  setShowError,
  loading = false,
}) => {
  const otpFirstDigit = useRef();
  const otpSecondDigit = useRef();
  const otpThirdDigit = useRef();
  const otpFourthDigit = useRef();

  const getVerifyUserValidationSchema = () => {
    return Yup.object().shape({
      code1: Yup.string().required(
        intl.formatMessage({
          id: "error.required",
          defaultMessage: "Required",
        })
      ),
      code2: Yup.string().required(
        intl.formatMessage({
          id: "error.required",
          defaultMessage: "Required",
        })
      ),
      code3: Yup.string().required(
        intl.formatMessage({
          id: "error.required",
          defaultMessage: "Required",
        })
      ),
      code4: Yup.string().required(
        intl.formatMessage({
          id: "error.required",
          defaultMessage: "Required",
        })
      ),
    });
  };

  return (
    <>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        className="otp-modal"
        backdrop="static"
      >
        <Modal.Header closeButton={!loading}>
          <h6 className="text-center otp-heading">
            <FormattedMessage
              id="loginModal.optVerificationTitle"
              defaultMessage="OTP Verification"
            />
          </h6>
        </Modal.Header>
        <Modal.Body>
          <Formik
            enableReinitialize
            initialValues={{ code1: "", code2: "", code3: "", code4: "" }}
            validationSchema={getVerifyUserValidationSchema}
            onSubmit={handleVerifyUserSubmit}
          >
            {({ values }) => {
              return (
                <Form id="verifyUser">
                  <div className="otp-input-box">
                    <div className="text-center">
                      <FormattedMessage
                        id="loginModal.enterOtpSent"
                        defaultMessage="Enter the OTP you received"
                      />
                    </div>

                    <div className="row OTP">
                      <div className="col-sm-4">
                        <div className="box">
                          <Field
                            type="number"
                            id="code1"
                            name="code1"
                            placeholder="X"
                            className="form-control inptFields one1"
                            maxLength={1}
                            innerRef={otpFirstDigit}
                            pattern="[0-9]*"
                            onInput={(e) =>
                              automateOTPFields(e, otpSecondDigit, setShowError)
                            }
                          />
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="box">
                          <Field
                            type="number"
                            id="code2"
                            name="code2"
                            placeholder="X"
                            className="form-control inptFields two2"
                            maxLength={1}
                            innerRef={otpSecondDigit}
                            pattern="[0-9]*"
                            onInput={(e) =>
                              automateOTPFields(e, otpThirdDigit, setShowError)
                            }
                            onKeyDown={(e) =>
                              automateOTPFields(e, otpFirstDigit)
                            }
                          />
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="box">
                          <Field
                            type="number"
                            id="code3"
                            name="code3"
                            placeholder="X"
                            className="form-control inptFields three3"
                            maxLength={1}
                            innerRef={otpThirdDigit}
                            pattern="[0-9]*"
                            onInput={(e) =>
                              automateOTPFields(e, otpFourthDigit, setShowError)
                            }
                            onKeyDown={(e) =>
                              automateOTPFields(e, otpSecondDigit)
                            }
                          />
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="box">
                          <Field
                            type="number"
                            id="code4"
                            name="code4"
                            placeholder="X"
                            maxLength={1}
                            innerRef={otpFourthDigit}
                            className="form-control inptFields four4"
                            pattern="[0-9]*"
                            onInput={(e) =>
                              automateOTPFields(e, null, setShowError)
                            }
                            onKeyDown={(e) =>
                              automateOTPFields(e, otpThirdDigit)
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="otp-invalid-error-wrapper">
                      {showError && (
                        <FormattedMessage
                          id="loginModal.InvalidOtp"
                          defaultMessage="Invalid OTP"
                        />
                      )}
                    </div>

                    <div className="mt-5">
                      {!loading ? (
                        <>
                          <Button
                            type="button"
                            onClick={() => setShow(false)}
                          >
                            <FormattedMessage
                              id="otpModal.cancel"
                              defaultMessage="Cancel"
                            />
                          </Button>
                          <input
                            type="submit"
                            className="ah-btn btn primary-btn verify-btn-otp active-verify-btn"
                            value={
                              appState.state.language === "en"
                                ? "Verify"
                                : "يؤكد"
                            }
                            disabled={
                              values.code1 === "" ||
                              values.code2 === "" ||
                              values.code3 === "" ||
                              values.code4 === ""
                            }
                          />
                        </>
                      ) : (
                        <FormattedMessage
                          id="global.processing"
                          defaultMessage="Processing..."
                        />
                      )}
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};

export default React.memo(withRouter(injectIntl(OtpModal)));
