import BaseService from "./baseService";
import axios from "axios";

class SubscriptionService extends BaseService {
  constructor() {
    super();
  }

  async addSubscription(data) {
    const formData = new FormData();
    for (let key in data) {
      if (key === "branches" || key === "serviceType" || key === "cars") {
        formData.append(key, JSON.stringify(data[key]));
      } else {
        formData.append(key, data[key]);
      }
    }
    const response = await axios.post("/api/subscription", formData);
    return response.data;
  }

  async getSubscriptions({ searchFilter, summary }) {
    const response = await axios.get("/api/subscriptions", {
      params: {
        searchFilter,
        summary,
      },
    });
    return response.data.response;
  }

  async getSubscriptionById(id, options = {}) {
    const response = await axios.get("/api/subscription", {
      params: { id },
      progressBar: options.progressBar,
    });
    return response.data.response;
  }

  async updateSubscriptionsSorting(payload) {
    const response = await axios.put("/api/subscription/sort", {
      data: payload,
    });
    return response.data;
  }

  async updateSubscription(data) {
    const formData = new FormData();
    for (let key in data) {
      if (key === "branches" || key === "serviceType" || key === "cars") {
        formData.append(key, JSON.stringify(data[key]));
      } else {
        formData.append(key, data[key]);
      }
    }
    const response = await axios.put("/api/subscription", formData);
    return response.data;
  }

  async getSubscribedOffers(
    searchFilter,
    statusFilter,
    pageIndex,
    pageSize,
    fromDate,
    toDate,
    phoneNumber,
    options = {},
    summary
  ) {
    const result = await axios.get("/api/subscribed-offers", {
      params: {
        searchFilter,
        statusFilter,
        pageIndex,
        pageSize,
        fromDate,
        toDate,
        phoneNumber,
        summary,
      },
      progressBar: options.progressBar,
    });
    return result?.data?.response;
  }

  async getSubscribedOfferById(id) {
    const result = await axios.get("/api/subscribed-offer/" + id);
    return result?.data?.response;
  }
}
export default new SubscriptionService();
