import BaseService from "./baseService";
import axios from "axios";

class LocationServices extends BaseService {
  constructor() {
    super();
  }

  async getCities() {
    const result = await axios.get(`/api/get-located-branches`);
    return result.data?.response;
  }

  async updateCitiesSorting(payload) {
    const result = await axios.put("/api/cities-sorting", {
      data: payload,
    });
    return result.data?.response;
  }
}

export default new LocationServices();
