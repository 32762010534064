import React, { useState, useEffect } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import Layout from "../shared/Layout";
import _ from "lodash";
import Auth from "../shared/Auth";
import { Link } from "react-router-dom";
import withRouter from "../../util/withRouter";
import HeaderTitle from "../shared/HeaderTitle";
import CheckAccess from "../shared/CheckAccess";
import { ActivitiesEnum } from "../../staticData/Activities";
import SortableComponent from "../../shared/DragAndDropTable";
import { arrayMove } from "react-sortable-hoc";
import Blog from "../../services/blogService";

const BlogsList = (props) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try {
      setLoading(true);
      const { response } = await Blog.getBlogs({});
      setData(response);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const sortBlogs = async (oldIndex, newIndex) => {
    try {
      setLoading(true);
      const updatedData = arrayMove(data, oldIndex, newIndex);
      updatedData.map((item, i) => {
        item.order = i;
      });
      setData(updatedData);
      await Blog.sortBlogs(updatedData);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const updateStatus = async (id, state) => {
    try {
      setLoading(true);
      await Blog.updateStatus(id, state);
      await loadData();
    } catch (e) {
      console.error(e);
    }
  };

  const deleteHandler = async (id) => {
    try {
      setLoading(true);
      await Blog.deleteBlog(id);
      await loadData();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Auth requireAuth={true} roles={["sadmin"]}>
      <Layout hidden={props.match.params.isFormOpen} loading={loading}>
        <HeaderTitle id="blogs.blogs" defaultMessage="Blogs" />
        <div className="main-container">
          <div className="table-header clearfix">
            <div className="row">
              <div className="col-xs-3 pull-right">
                <div className="panel-group-buttons pull-right">
                  <CheckAccess activityId={ActivitiesEnum.knowledge_hub_blog}>
                    <Link className="btn btn-special " to="/blog">
                      <span className="fa fa-plus" />{" "}
                      <FormattedMessage
                        id="blogs.newBlog"
                        defaultMessage="Create Blog"
                      />
                    </Link>
                  </CheckAccess>
                </div>
              </div>

              <div className="col-md-12">
                <FormattedMessage
                  id="global.totalCount"
                  defaultMessage="Total count"
                />{" "}
                <strong>{data.length}</strong>
              </div>
            </div>
          </div>

          <div className="dashboard-table-container card-layout">
            <div className="table-responsive">
              <div className="custom-table">
                <div className="custom-table-header">
                  <div>
                    <FormattedMessage
                      id="blogs.titleEn"
                      defaultMessage="Title English"
                    />
                  </div>
                  <div>
                    <FormattedMessage
                      id="blogs.titleAr"
                      defaultMessage="Title Arabic"
                    />
                  </div>
                  <div>
                    <FormattedMessage
                      id="blogs.ctaTextEn"
                      defaultMessage="CTA Text English"
                    />
                  </div>
                  <div>
                    <FormattedMessage
                      id="blogs.ctaTextAr"
                      defaultMessage="CTA Text Arabic"
                    />
                  </div>
                  <div>
                    <FormattedMessage
                      id="blogs.ctaLink"
                      defaultMessage="CTA Link"
                    />
                  </div>
                  <div>
                    <FormattedMessage
                      id="blogs.isActive"
                      defaultMessage="Active"
                    />
                  </div>
                </div>
                {!data || !data?.length ? (
                  <div
                    style={{
                      captionSide: "bottom",
                      textAlign: "center",
                      margin: "20px",
                      color: "darkgray",
                    }}
                  >
                    <FormattedMessage
                      id="global.noData"
                      defaultMessage="No data available"
                    />
                  </div>
                ) : (
                  <div className="main-container">
                    <SortableComponent
                      from="blogs"
                      data={data}
                      _setData={setData}
                      props={props}
                      updateSorting={sortBlogs}
                      updateStatus={updateStatus}
                      deleteHandler={deleteHandler}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </Auth>
  );
};

export default withRouter(injectIntl(BlogsList));
