import React from "react";
import appState from "../../../state/AppStateContainer";
import { Modal } from "react-bootstrap";
import { getFormattedCarNumberPlate } from "../../../util/utils";
import { FormattedMessage } from "react-intl";

const EstimationCancelReasonModal = ({ show, close, selectedEstimation }) => {
  const language = appState.state.language;
  const { cancelRequestReason, otherBrand, otherModel, licencePlate, carData } =
    selectedEstimation;
  return (
    <Modal show={show} onHide={close} centered size="md">
      <Modal.Header closeButton>
        <Modal.Title>
          <FormattedMessage
            id="track.estimationHeading"
            defaultMessage="Estimation Request"
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="image-guidance-modal-body reason-modal-body">
        <div className="reason-modal-header">
          <h4>
            <FormattedMessage
              id="track.estimationClosed"
              defaultMessage="Estimation Closed"
            />
          </h4>
          <h6>
            <span>
              {otherBrand || carData[0].brand["value_" + language]}{" "}
              {otherModel || carData[0].product["value_" + language]}{" "}
            </span>
            <span>{getFormattedCarNumberPlate(licencePlate)}</span>
          </h6>
          <p style={{ margin: 0 }}>{cancelRequestReason}</p>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default React.memo(EstimationCancelReasonModal);
