import BaseService from "./baseService";
import axios from "axios";

class HomeBannerService extends BaseService {
  constructor() {
    super();

    this.imageFileKeys = [
      "homeBanner_en",
      "homeBanner_ar",
      "homeBanner_mobile_en",
      "homeBanner_mobile_ar",
    ];
  }

  async getHomeBanners(id) {
    const response = await axios.get(
      "/api/slider/all" + (id ? "?id=" + id : "/")
    );
    return response.data.response;
  }

  async addHomeBanner(request) {
    const formData = new FormData();
    for (let key in request) {
      formData.append(
        key,
        typeof request[key] === "object" && !this.imageFileKeys.includes(key)
          ? JSON.stringify(request[key])
          : request[key]
      );
    }
    const response = await axios.post("/api/slider", formData);
    return response.data.response;
  }

  async updateHomeBanner(request, id) {
    const formData = new FormData();
    for (let key in request) {
      formData.append(
        key,
        typeof request[key] === "object" && !this.imageFileKeys.includes(key)
          ? JSON.stringify(request[key])
          : request[key]
      );
    }
    const response = await axios.put("/api/slider/" + id, formData);
    return response.data.response;
  }

  async deleteHomeBanner(id) {
    const response = await axios.delete("/api/slider/" + id);
    return response.data.response;
  }

  async updateHomeBannerStatus(id, status) {
    const response = await axios.patch(
      "/api/slider/update-active-status/" + id,
      {
        isActive: status,
      }
    );
    return response.data.response;
  }
  
  async updateHomeBannerSorting(payload) {
    const response = await axios.put("/api/sliders-sorting", { data: payload });
    return response.data.response;
  }
}

export default new HomeBannerService();
