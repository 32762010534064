import React, { useCallback, useRef, useState } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Formik, Form } from "formik";
import { Modal } from "react-bootstrap";
import FieldRow from "./FieldRow";
import { NotificationManager } from "react-notifications";
import Services from "../../services";
import _ from "lodash";
import { Yup } from "../../util";
import { withAuthContext } from "../../state/AuthContext";
import withRouter from "../../util/withRouter";
import ConfirmationModal from "./ConfirmationModal";

const AddRecommendation = ({
  showRecommendationModal,
  setShowRecommendationModal,
  intl,
  phoneNumber,
  getProgress,
  firstCarDetails,
  progress,
  selectedTab,
}) => {
  const formikRef = useRef();
  const [showModal, setShowModal] = useState({
    confirmationModal: false,
    canFormSubmit: false,
  });
  const id = progress[0];

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    if (!showModal?.canFormSubmit) {
      setShowModal({ confirmationModal: true });
      return;
    }

    setSubmitting(true);
    try {
      const price = values?.price;
      const total =
        Number(values?.labour || 0) +
        Number(values?.parts || 0) +
        Number(values?.paint || 0) +
        Number(values?.oil || 0) +
        Number(values?.boughtOut || 0) +
        Number(values?.sublet || 0);

      if (Number(total) !== price) {
        return setErrors({
          ...formikRef.current.errors,
          amountExceeded: intl.formatMessage({
            id: "offer.amountExceededMessage",
            defaultMessage:
              "Sum of Labour, Parts, Bought Out, Paint, Oil and Sublet cannot greater and less than the Price",
          }),
        });
      }

      await Services.addRecommendation({
        ...values,
        appointmentId:
          selectedTab === "cndAppointments" ? id && id[0]?.appointmentId : "",
        carData: JSON.stringify(firstCarDetails),
      });

      setShowRecommendationModal({
        add: false,
      });
      getProgress();
      setShowModal({ canFormSubmit: false, confirmationModal: false });
      NotificationManager.success(
        intl.formatMessage({
          id: "global.operationSuccessful",
          defaultMessage: "Operation Successful",
        }),
        intl.formatMessage({
          id: "global.success",
          defaultMessage: "Success",
        })
      );
    } catch (e) {
      setShowModal({ canFormSubmit: false, confirmationModal: false });
      NotificationManager.error(
        intl.formatMessage({
          id: _.get(e, "response.data.message", "error.unexpectedError"),
          defaultMessage: e?.response?.data?.message,
        }),
        intl.formatMessage({
          id: "global.error",
          defaultMessage: "Error",
        })
      );
    } finally {
      setSubmitting(false);
    }
  };

  const handleConfirmationModal = () => {
    setShowModal({ confirmationModal: false, canFormSubmit: true });
    if (formikRef && formikRef.current) {
      formikRef.current.submitForm();
    }
  };

  const handleCancelConfirmationModal = useCallback(() => {
    setShowModal({ canFormSubmit: false, confirmationModal: false });
    if (formikRef && formikRef.current) {
      formikRef.current.setSubmitting(false);
    }
  }, [showModal]);

  return (
    <>
      <Modal
        show={showRecommendationModal?.add}
        onHide={() => setShowRecommendationModal(false)}
        backdrop="static"
        size="xl"
      >
        <Modal.Header closeButton>
          <h4>
            <FormattedMessage
              id="fastCollect.addRecommendation"
              defaultMessage="Add Recommendation"
            />
          </h4>
        </Modal.Header>
        <Formik
          innerRef={formikRef}
          initialValues={{
            titleEn: "",
            titleAr: "",
            descriptionEn: "",
            descriptionAr: "",
            imageOne: "",
            imageTwo: "",
            imageThree: "",
            imageFour: "",
            video: "",
            price: "",
            labour: 0,
            parts: 0,
            boughtOut: 0,
            paint: 0,
            oil: 0,
            sublet: 0,
            phoneNumber: phoneNumber,
          }}
          validationSchema={Yup.object().shape({
            titleEn: Yup.string()
              .hasNoArabic(
                intl.formatMessage({
                  id: "error.arabicCharactersAreNotAllowedInThisField",
                  defaultMessage:
                    "Arabic characters are not allowed in this field",
                })
              )
              .label(
                intl.formatMessage({
                  id: "fastCollect.recommendationTitleEn",
                  defaultMessage: "Title English",
                })
              )
              .trim()
              .required(),
            titleAr: Yup.string()
              .hasNoEnglish(
                intl.formatMessage({
                  id: "error.englishCharactersAreNotAllowedInThisField",
                  defaultMessage:
                    "English characters are not allowed in this field",
                })
              )
              .label(
                intl.formatMessage({
                  id: "fastCollect.recommendationTitleAr",
                  defaultMessage: "Title Arabic",
                })
              )
              .trim()
              .required(),
            descriptionEn: Yup.string()
              .hasNoArabic(
                intl.formatMessage({
                  id: "error.arabicCharactersAreNotAllowedInThisField",
                  defaultMessage:
                    "Arabic characters are not allowed in this field",
                })
              )
              .label(
                intl.formatMessage({
                  id: "fastCollect.recommendationDescriptionEn",
                  defaultMessage: "Description English",
                })
              )
              .trim()
              .required(),
            descriptionAr: Yup.string()
              .hasNoEnglish(
                intl.formatMessage({
                  id: "error.englishCharactersAreNotAllowedInThisField",
                  defaultMessage:
                    "English characters are not allowed in this field",
                })
              )
              .label(
                intl.formatMessage({
                  id: "fastCollect.recommendationDescriptionAr",
                  defaultMessage: "Description Arabic",
                })
              )
              .trim()
              .required(),
            price: Yup.number()
              .label(
                intl.formatMessage({
                  id: "location.price",
                  defaultMessage: "Price",
                })
              )
              .required(),
            labour: Yup.number()
              .label(
                intl.formatMessage({
                  id: "service.labour",
                  defaultMessage: "Labour",
                })
              )
              .required(),
            parts: Yup.number()
              .label(
                intl.formatMessage({
                  id: "service.parts",
                  defaultMessage: "Parts",
                })
              )
              .required(),
            boughtOut: Yup.number()
              .label(
                intl.formatMessage({
                  id: "service.boughtOut",
                  defaultMessage: "Bought Out",
                })
              )
              .required(),
            paint: Yup.number()
              .label(
                intl.formatMessage({
                  id: "service.paint",
                  defaultMessage: "Paint",
                })
              )
              .required(),
            oil: Yup.number()
              .label(
                intl.formatMessage({
                  id: "service.oil",
                  defaultMessage: "Oil",
                })
              )
              .required(),
            sublet: Yup.number()
              .label(
                intl.formatMessage({
                  id: "service.sublet",
                  defaultMessage: "Sublet",
                })
              )
              .required(),
            imageOne: Yup.string()
              .label(
                intl.formatMessage({
                  id: "track.thumbnail",
                  defaultMessage: "Thumbnail Image is",
                })
              )
              .required(),
            imageTwo: Yup.string()
              .label(
                intl.formatMessage({
                  id: "track.banner",
                  defaultMessage: "Banner Image is",
                })
              )
              .required(),
          })}
          onSubmit={handleSubmit}
        >
          {(formikProps) => {
            return (
              <div className="panel-primary">
                <Form>
                  <Modal.Body>
                    <FieldRow
                      type="text"
                      name="titleEn"
                      showRequiredFlag={true}
                      defaultLabel="English Title"
                      labelId="fastCollect.englishTitle"
                      placeholderId="fastCollect.englishTitle"
                      defaultPlaceholder="English Title"
                      formikProps={formikProps}
                      colSize={3}
                    />
                    <FieldRow
                      type="text"
                      name="titleAr"
                      showRequiredFlag={true}
                      defaultLabel="Arabic Title"
                      labelId="fastCollect.arabicTitle"
                      placeholderId="fastCollect.arabicTitle"
                      defaultPlaceholder="Arabic Title"
                      formikProps={formikProps}
                      colSize={3}
                    />
                    <FieldRow
                      type="textarea"
                      name="descriptionEn"
                      showRequiredFlag={true}
                      defaultLabel="English Description"
                      labelId="fastCollect.englishDescription"
                      placeholderId="fastCollect.englishDescription"
                      defaultPlaceholder="English Description"
                      formikProps={formikProps}
                      colSize={3}
                    />
                    <FieldRow
                      type="textarea"
                      name="descriptionAr"
                      showRequiredFlag={true}
                      defaultLabel="Arabic Description"
                      labelId="fastCollect.arabicDescription"
                      placeholderId="fastCollect.arabicDescription"
                      defaultPlaceholder="Arabic Description"
                      formikProps={formikProps}
                      colSize={3}
                    />
                    <FieldRow
                      type="number"
                      name="price"
                      labelId="fastCollect.price"
                      defaultLabel="Price"
                      placeholderId="fastCollect.price"
                      defaultPlaceholder="Price"
                      showRequiredFlag={true}
                      formikProps={formikProps}
                      colSize={3}
                    />
                    <FieldRow
                      type="number"
                      name="labour"
                      labelId="fastCollect.labour"
                      defaultLabel="Labour"
                      placeholderId="fastCollect.labour"
                      defaultPlaceholder="Labour"
                      formikProps={formikProps}
                      colSize={3}
                    />
                    <FieldRow
                      type="number"
                      name="parts"
                      labelId="fastCollect.parts"
                      defaultLabel="Parts"
                      placeholderId="fastCollect.parts"
                      defaultPlaceholder="Parts"
                      formikProps={formikProps}
                      colSize={3}
                    />
                    <FieldRow
                      type="number"
                      name="boughtOut"
                      labelId="fastCollect.boughtOut"
                      defaultLabel="Bought Out"
                      placeholderId="fastCollect.boughtOut"
                      defaultPlaceholder="Bought Out"
                      formikProps={formikProps}
                      colSize={3}
                    />
                    <FieldRow
                      type="number"
                      name="paint"
                      labelId="fastCollect.paint"
                      defaultLabel="Paint"
                      placeholderId="fastCollect.paint"
                      defaultPlaceholder="Paint"
                      formikProps={formikProps}
                      colSize={3}
                    />
                    <FieldRow
                      type="number"
                      name="oil"
                      labelId="fastCollect.oil"
                      defaultLabel="Oil"
                      placeholderId="fastCollect.oil"
                      defaultPlaceholder="Oil"
                      formikProps={formikProps}
                      colSize={3}
                    />
                    <FieldRow
                      type="number"
                      name="sublet"
                      labelId="fastCollect.sublet"
                      defaultLabel="Sublet"
                      placeholderId="fastCollect.sublet"
                      defaultPlaceholder="Sublet"
                      formikProps={formikProps}
                      colSize={3}
                    />

                    {formikProps?.errors?.amountExceeded && (
                      <p className="text-warning">
                        {formikProps.errors.amountExceeded}
                      </p>
                    )}

                    <FieldRow
                      type="file"
                      name="imageOne"
                      labelId="fastCollect.fastCollectImage"
                      defaultLabel="Thumbnail Image"
                      image={true}
                      showThumbnail={true}
                      showRequiredFlag={false}
                      cameraIcon={true}
                      formikProps={formikProps}
                      aspectRatio={320 / 320}
                      imageSize="W:320 x H:320"
                      colSize={3}
                    />
                    <FieldRow
                      type="file"
                      name="imageTwo"
                      labelId="fastCollect.fastCollectImage"
                      defaultLabel="Banner Image One"
                      image={true}
                      showThumbnail={true}
                      showRequiredFlag={false}
                      forCarousel={true}
                      cameraIcon={true}
                      formikProps={formikProps}
                      aspectRatio={1130 / 450}
                      imageSize="W:1130 x H:450"
                      forServices={true}
                      colSize={3}
                    />
                    <FieldRow
                      type="file"
                      name="imageThree"
                      labelId="fastCollect.fastCollectImage"
                      defaultLabel="Banner Image Two"
                      image={true}
                      showThumbnail={true}
                      showRequiredFlag={false}
                      forCarousel={true}
                      cameraIcon={true}
                      formikProps={formikProps}
                      aspectRatio={1130 / 450}
                      imageSize="W:1130 x H:450"
                      forServices={true}
                      colSize={3}
                    />
                    <FieldRow
                      type="file"
                      name="imageFour"
                      labelId="fastCollect.fastCollectImage"
                      defaultLabel="Banner Image Three"
                      image={true}
                      showThumbnail={true}
                      showRequiredFlag={false}
                      forCarousel={true}
                      cameraIcon={true}
                      formikProps={formikProps}
                      aspectRatio={1130 / 450}
                      imageSize="W:1130 x H:450"
                      forServices={true}
                      colSize={3}
                    />
                    <FieldRow
                      type="video"
                      name="video"
                      labelId="fastCollect.fastCollectVideo"
                      defaultLabel="Repair Video"
                      showRequiredFlag={false}
                      showThumbnail={true}
                      formikProps={formikProps}
                      allowFiles="video/mp4,video/webm,video/avi,video/quicktime"
                      colSize={3}
                    />
                  </Modal.Body>
                  <Modal.Footer>
                    <input
                      type="submit"
                      className="btn btn-primary"
                      value={intl.formatMessage({
                        id: "global.submit",
                        defaultMessage: "Submit",
                      })}
                      disabled={formikProps.isSubmitting}
                    />
                  </Modal.Footer>
                </Form>
              </div>
            );
          }}
        </Formik>
      </Modal>

      <ConfirmationModal
        {...{
          show: showModal?.confirmationModal,
          id: "gloabl.confirmationText",
          defaultMessage: "Are you sure you want to continue",
          onCancel: handleCancelConfirmationModal,
          onSubmit: handleConfirmationModal,
        }}
      />
    </>
  );
};

export default withAuthContext(
  withRouter(injectIntl(React.memo(AddRecommendation)))
);
