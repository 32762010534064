import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";

import services from "../../services";
import _ from "lodash";

import withRouter  from "../../util/withRouter";
import appState from "../../state/AppStateContainer";
import { FormattedNumber } from "react-intl";
import classNames from "classnames";
import ActionMenu from "../shared/ActionMenu";
import { NotificationManager } from "react-notifications";
import Loading from "../shared/Loading";

export class AdjustmentDetails extends Component {
  state = {
    data: [],
    searchFilter: "",
    approvalFilter: "approved",
    statusFilter: "active",
    categoryFilter: "all",
    listFilter: "added",
    categories: [],
    selected: [],
    isMounted: false,
    loading: false
  };

  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    try {
    } catch (e) {
      this.props.navigate("/error", {
        error: _.get(e, "response.data.error", "error.unexpectedError")
      });
    }
  }

  async handleForceCharge(id) {
    try {
      this.setState({ loading: true });
      await services.adjustmentForceCharge(id);
      NotificationManager.success(
        this.props.intl.formatMessage({
          id: "global.amountForcefullyCharged",
          defaultMessage: "Amount forcefully charged from customer wallet"
        }),
        this.props.intl.formatMessage({
          id: "global.success",
          defaultMessage: "Success"
        })
      );
      this.setState({ loading: false });
    } catch (e) {
      NotificationManager.error(
        this.props.intl.formatMessage({
          id: _.get(e, "response.data.error", "error.unexpectedError"),
          defaultMessage: "An unexpected error has occurred"
        }),
        this.props.intl.formatMessage({
          id: "global.error",
          defaultMessage: "Error"
        })
      );
      this.setState({ loading: false });
    }
  }

  render() {
    const items = this.props.itemDetails;
    console.log("itemss", items);
    return (
      <table className="table dashboard-table table-hover ">
        {this.state.loading && <Loading />}
        <thead>
          <tr onClick={() => { }}>
            <th>
              <FormattedMessage id="Amount" defaultMessage="Amount" />
            </th>
            <th>
              <FormattedMessage id="adjustment.paid" defaultMessage="Paid" />
            </th>

            <th>
              <FormattedMessage
                id="adjustment.comment"
                defaultMessage="Comment"
              />
            </th>
            <th>
              <FormattedMessage
                id="adjustment.status"
                defaultMessage="Status"
              />
            </th>
            <th>
              <FormattedMessage
                id="adjustment.customerStatus"
                defaultMessage="Customer Status"
              />
            </th>
            <th style={{ width: "20px" }} />
          </tr>
        </thead>
        {items.map(w => (
          <tr
            key={w.id}
            // onClick={() => navigate(`/shop/${w.id}`)}
            className="cases-trow link-row"
          >
            <td>
              <FormattedNumber
                style="decimal"
                maximumFractionDigits={2}
                minimumFractionDigits={2}
                value={w.amount}
              />
            </td>
            <td
              className={classNames({
                "col-status-green": w.paid,
                "col-status-red": w.paid === false
              })}
            >
              <a href="javascript:;">
                {w.paid && (
                  <FormattedMessage id="shop.yes" defaultMessage="Yes" />
                )}
                {w.paid === false && (
                  <FormattedMessage id="shop.No" defaultMessage="No" />
                )}
              </a>
            </td>

            <td className="tooltip-master">
              {w.comment}
              <span className="tooltiptext">{w.comment}</span>
            </td>
            <td
              className={classNames({
                "col-status-green": w.status === "adjustment",
                "col-status-red": w.status === "refund"
              })}
            >
              <a href="javascript:;">
                {w.status === "adjustment" && (
                  <FormattedMessage
                    id="shop.adjustment"
                    defaultMessage="Adjustment"
                  />
                )}
                {w.status === "refund" && (
                  <FormattedMessage id="shop.refund" defaultMessage="Refund" />
                )}
              </a>
            </td>
            <td
              className={classNames({
                "col-status-green": w.customerStatus === "accept",
                "col-status-red": w.customerStatus === "reject"
              })}
            >
              <a href="javascript:;">
                {w.customerStatus === "accept" && (
                  <FormattedMessage id="shop.accept" defaultMessage="Accept" />
                )}
                {w.customerStatus === "reject" && (
                  <FormattedMessage id="shop.reject" defaultMessage="Reject" />
                )}
              </a>
            </td>
            <td>
              {_.get(appState, "state.token.role") === "sadmin" &&
                w.customerStatus !== "accept" &&
                w.status !== "refund" &&
                !w.paid && (
                  <a
                    style={{ margin: "10px", cursor: "pointer" }}
                    onClick={async () => {
                      this.handleForceCharge(w._id);
                    }}
                  >
                    <span className="fa fa-sign-in" />{" "}
                    <FormattedMessage
                      id="global.forceCharge"
                      defaultMessage="Force Charge"
                    />
                  </a>
                )}
            </td>
            <td style={{ position: "relative" }}>
              <ActionMenu
                visible={this.state.visible}
                RightClickDisabled={true}
                menuStyle={{
                  backgroundColor: "#E9E9E9"
                }}
              >
                {w.customerStatus !== "accept" &&
                  w.status !== "refund" &&
                  !w.paid && (
                    <a
                      style={{ margin: "10px", cursor: "pointer" }}
                      onClick={async () => {
                        this.handleForceCharge(w._id);
                      }}
                    >
                      <span className="fa fa-sign-in" />{" "}
                      <FormattedMessage
                        id="global.forceCharge"
                        defaultMessage="Force Charge"
                      />
                    </a>
                  )}
                {/* {w.customerStatus !== "accept" &&
                  w.status !== "refund" &&
                  !w.paid && <div />} */}
              </ActionMenu>
            </td>
          </tr>
        ))}
      </table>
    );
  }
}

export default withRouter(injectIntl(AdjustmentDetails));
