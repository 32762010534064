import React, { useState, useEffect, useRef } from "react";
import { FormattedMessage, FormattedNumber, injectIntl } from "react-intl";
import Layout from "../shared/Layout";
import services from "../../services";
import Auth from "../shared/Auth";
import withRouter from "../../util/withRouter";
import HeaderTitle from "../shared/HeaderTitle";
import { getLocalDateTime } from "../../util";
import moment from "moment";
import appState from "../../state/AppStateContainer";
import { SingleDatePicker } from "react-dates";
import Table from "../shared/Table";

const ActivityList = ({ intl, match }) => {
  const searchInputRef = useRef();
  const areaSelectRef = useRef();

  const [data, setData] = useState([]);
  const [filtersData, setFiltersData] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [pageIndex, setPageIndex] = useState(-1);
  const [pageSize, setPageSize] = useState(10);
  const [searchFilter, setSearchFilter] = useState("");
  const [areaFilter, setAreaFilter] = useState("all");
  const [actionFilter, setActionFilter] = useState("all");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [focusedFrom, setFocusedFrom] = useState(null);
  const [focusedTo, setFocusedTo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [disableFlag, setDisableFlag] = useState(false);
  const [count, setCount] = useState(0);

  useEffect(() => {
    const onLanguageChange = () => {
      setFromDate(
        fromDate ? moment(fromDate).locale(appState.state.language) : undefined
      );
      setToDate(
        toDate ? moment(toDate).locale(appState.state.language) : undefined
      );
    };

    const subscription = appState.emitter.addListener(
      "onLanguageChange",
      onLanguageChange
    );

    return () => {
      subscription.remove();
    };
  }, [fromDate, toDate]);

  useEffect(() => {
    setupPage();
  }, []);

  const setupPage = async () => {
    try {
      const filtersData = await services.getActivityFilters();
      setFiltersData(filtersData);
    } catch (e) {
      console.error(e);
    }
  };

  const loadMore = async () => {
    setLoading(true);
    setPageIndex((prevState) => prevState + 1);

    setHasMore(false);
    const _pageIndex = pageIndex + 1;

    const options = {
      progressBar: true,
      alphabeticalOrder: true,
      fromDate: fromDate ? moment(fromDate).startOf("day").toString() : null,
      toDate: toDate ? moment(toDate).endOf("day").toString() : null,
    };

    const pageData = await services.getActivities(
      searchFilter,
      areaFilter,
      actionFilter,
      match.params.id,
      _pageIndex,
      pageSize,
      options
    );
    let summary = { count };

    if (_pageIndex === 0) {
      summary = await services.getActivities(
        searchFilter,
        areaFilter,
        actionFilter,
        match.params.id,
        undefined,
        undefined,
        options,
        summary
      );
    }

    setData((prevData) => [...prevData, ...pageData]);
    setHasMore(pageData.length > 0);
    setDisableFlag(false);
    setLoading(false);
    if (_pageIndex === 0) {
      setCount(summary);
    }
  };

  const resetData = (e) => {
    setPageIndex(-1);
    setData([]);
    setHasMore(true);
    setSearchFilter(searchInputRef.current.value);
    setAreaFilter(areaSelectRef.current.value);
    setDisableFlag(true);
    if (e) e.preventDefault();
  };

  const resetPage = (e) => {
    setPageIndex(-1);
    setData([]);
    setHasMore(true);
    setSearchFilter("");
    setAreaFilter("all");
    setActionFilter("all");
    setDisableFlag(true);
    setFromDate(null);
    setToDate(null);
    searchInputRef.current.value = "";
    areaSelectRef.current.value = "all";
    if (e) e.preventDefault();
  };

  //   const selectedArea = filtersData.filter((f) => f._id === areaFilter)[0];
  //   let actions = [];
  //   if (selectedArea) {
  //     actions = selectedArea.actions;
  //   }

  return (
    <Auth requireAuth={true} roles={["sadmin"]}>
      <Layout loading={loading}>
        <HeaderTitle
          id="activity.userActivities"
          defaultMessage="User Activities"
        />

        <div className="table-header clearfix">
          <form className="row" onSubmit={resetData}>
            <div>
              <div className="col-md-2 col-sm-4 col-xs-6">
                <div className="has-feedback">
                  <input
                    type="text"
                    ref={searchInputRef}
                    className="form-control"
                    placeholder={intl.formatMessage({
                      id: "global.search",
                      defaultMessage: "Search",
                    })}
                  />
                  <i className="glyphicon glyphicon-search form-control-feedback" />
                </div>
              </div>

              <div className="col-md-2 col-sm-4 col-xs-6">
                <select
                  ref={areaSelectRef}
                  onChange={resetData}
                  className="form-control"
                >
                  <option value="all">
                    {intl.formatMessage({
                      id: "activity.area",
                      defaultMessage: "Area",
                    })}
                  </option>
                  {filtersData.map((f) => (
                    <option value={f._id}>{f._id}</option>
                  ))}
                </select>
              </div>

              {/* <div className="col-md-2 col-sm-4 col-xs-6">
                  <select
                    ref={(c) => {
                      actionSelect = c;
                    }}
                    onChange={resetData.bind(this)}
                    className="form-control"
                  >
                    <option value="all">
                      {intl.formatMessage({
                        id: "activity.action",
                        defaultMessage: "Action",
                      })}
                    </option>
                    {actions.map((a) => (
                      <option value={a}>{a}</option>
                    ))}
                  </select>
                </div> */}

              <div className="col-lg-2 col-md-4 col-sm-12 col-xs-6">
                <SingleDatePicker
                  id="fromDate"
                  placeholder={intl.formatMessage({
                    id: "global.from",
                    defaultMessage: "From",
                  })}
                  block={true}
                  small={true}
                  isOutsideRange={() => false}
                  numberOfMonths={1}
                  initialDate={fromDate}
                  isRTL={appState.state.language === "ar" ? true : false}
                  date={fromDate}
                  onDateChange={(date) => {
                    setFromDate(date);
                    resetData();
                  }}
                  focused={focusedFrom}
                  onFocusChange={({ focused }) => setFocusedFrom(focused)}
                />
              </div>

              <div className="col-lg-2 col-md-4 col-sm-12 col-xs-6">
                <SingleDatePicker
                  id="toDate"
                  block={true}
                  placeholder={intl.formatMessage({
                    id: "global.to",
                    defaultMessage: "To",
                  })}
                  small={true}
                  isOutsideRange={() => false}
                  numberOfMonths={1}
                  initialDate={toDate}
                  isRTL={appState.state.language === "ar" ? true : false}
                  date={toDate}
                  onDateChange={(date) => {
                    setToDate(date);
                    resetData();
                  }}
                  focused={focusedTo}
                  onFocusChange={({ focused }) => setFocusedTo(focused)}
                />
              </div>

              <div className="col force--inline">
                <div className="tooltip-master">
                  <button
                    href="#disable"
                    className="btn btn-primary"
                    onClick={resetData.bind(this)}
                    disabled={disableFlag}
                  >
                    <i className="material-icons">search</i>
                  </button>
                  <span className="tooltiptext">
                    <FormattedMessage
                      id="global.filter"
                      defaultMessage="Filter"
                    />
                  </span>
                </div>
              </div>
              <div className="col force--inline">
                <div className="tooltip-master">
                  <button
                    href="#disable"
                    className="btn btn-primary"
                    onClick={resetPage.bind(this)}
                    disabled={disableFlag}
                  >
                    <i className="material-icons">settings_backup_restore</i>
                  </button>
                  <span className="tooltiptext">
                    <FormattedMessage
                      id="global.resetFilter"
                      defaultMessage="Reset Filter"
                    />
                  </span>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="row">
          <div className="col-md-12">
            <FormattedMessage
              id="global.totalCount"
              defaultMessage="Total Count"
            />
            <strong>
              {" "}
              <FormattedNumber
                style="decimal"
                maximumFractionDigits={0}
                minimumFractionDigits={0}
                value={count || 0}
              />{" "}
            </strong>
          </div>
        </div>
        <Table
          loadMore={loadMore}
          hasMore={hasMore}
          caption={
            (!data || data.length === 0) && (
              <caption
                style={{
                  captionSide: "bottom",
                  textAlign: "center",
                  margin: "20px",
                  color: "darkgray",
                }}
              >
                <FormattedMessage
                  id="global.noData"
                  defaultMessage="No data available"
                />
              </caption>
            )
          }
          headerRow={
            <tr>
              <th>
                <FormattedMessage
                  id="activity.timeStamp"
                  defaultMessage="Timestamp"
                />
              </th>
              <th>
                <FormattedMessage id="activity.area" defaultMessage="Area" />
              </th>
              <th>
                <FormattedMessage
                  id="activity.action"
                  defaultMessage="Action"
                />
              </th>
              <th>
                <FormattedMessage
                  id="activity.itemId"
                  defaultMessage="Item ID"
                />
              </th>
              <th>
                <FormattedMessage
                  id="global.englishName"
                  defaultMessage="English Name"
                />
              </th>
              <th>
                <FormattedMessage
                  id="global.arabicName"
                  defaultMessage="Arabic Name"
                />
              </th>
              <th>
                <FormattedMessage id="global.userId" defaultMessage="UserID" />
              </th>
              <th>
                <FormattedMessage
                  id="global.username"
                  defaultMessage="Username"
                />
              </th>
            </tr>
          }
        >
          {data?.length > 0 &&
            data.map((c) => {
              return (
                <tr key={c.id} className="cases-trow link-row">
                  <td>
                    {c.createdAt ? getLocalDateTime(c.createdAt) : undefined}
                  </td>
                  <td>{c.area}</td>
                  <td>{c.action}</td>
                  <td>{c.itemId}</td>
                  <td>{c.name.value_en}</td>
                  <td>{c.name.value_ar}</td>
                  <td>{c.userId}</td>
                  <td>{c.username}</td>
                </tr>
              );
            })}
        </Table>
      </Layout>
    </Auth>
  );
};

export default withRouter(injectIntl(ActivityList));
