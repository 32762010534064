import React, { Component } from "react";
import { FormattedMessage, injectIntl, FormattedNumber } from "react-intl";
import classNames from "classnames";
import Layout from "../shared/Layout";
import services from "../../services";
import _ from "lodash";
import Auth from "../shared/Auth";
import { Link } from "react-router-dom";
import withRouter from "../../util/withRouter";
import appState from "../../state/AppStateContainer";
import ImageProvider from "../shared/ImageProvider";
import Autosuggest from "react-autosuggest";
import HeaderTitle from "../shared/HeaderTitle";
import { ActivitiesEnum } from "../../staticData/Activities";
import CheckAccess from "../shared/CheckAccess";
import { SingleDatePicker } from "react-dates";
import moment from "moment";
import { getLocalDateTime } from "../../util";
import Table from "../shared/Table";
export class ProductList extends Component {
  state = {
    data: [],
    hasMore: true,
    pageIndex: -1,
    pageSize: 10,
    statusFilter: "all",
    categoryFilter: "all",
    categories: [],
    aplhabeticalOrder: true,
    value: "",
    suggestions: [],
    count: 0,
    loading: true,
  };

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      if (!this.props.match.params.isFormOpen) {
        if (_.get(this, "props.location.state.isSubmitted") === true) {
          this.setState({
            pageIndex: -1,
            data: [],
            hasMore: true,
            statusFilter: this.statusSelect.value,
            categoryFilter: this.categorySelect.value,
            disableFlag: true,
          });
        } else {
          this.setState({ updatingRowId: "" });
        }
      }
    }
  }

  async componentDidMount() {
    try {
      this.subscription = appState.emitter.addListener(
        "onLanguageChange",
        () => {
          this.setState({
            from: this.state.from
              ? moment(this.state.from).locale(appState.state.language)
              : undefined,
            to: this.state.to
              ? moment(this.state.to).locale(appState.state.language)
              : undefined,
          });
        }
      );

      const categories = await services.getCategoriesForProducts();
      this.setState({ categories });
    } catch (e) {
      this.props.navigate("/error", {
        error: _.get(e, "response.data.error", "error.unexpectedError"),
      });
    }
  }

  componentWillUnmount() {
    this.subscription.remove();
  }

  async loadMore() {
    let options = {
      progressBar: true,
      alphabeticalOrder: true,
    };

    this.setState(
      (currentState) => {
        return {
          pageIndex: currentState.pageIndex + 1,
          hasMore: false,
          loading: true,
        };
      },

      async () => {
        const pageIndex = this.state.pageIndex;

        const pageData = await services.getProducts(
          this.state.value,
          this.state.statusFilter,
          this.state.categoryFilter,
          this.state.pageIndex,
          this.state.pageSize,
          {
            ...options,
            fromDate: this.state.from
              ? moment(this.state.from).startOf("day").toString()
              : null,
            toDate: this.state.to
              ? moment(this.state.to).endOf("day").toString()
              : null,
          }
        );
        const summary =
          pageIndex === 0
            ? await services.getProducts(
                this.state.value,
                this.state.statusFilter,
                this.state.categoryFilter,
                undefined,
                undefined,
                {
                  ...options,
                  summary: 1,
                  fromDate: this.state.from
                    ? moment(this.state.from).startOf("day").toString()
                    : null,
                  toDate: this.state.to
                    ? moment(this.state.to).endOf("day").toString()
                    : null,
                }
              )
            : { count: this.state.count };
        this.setState((currentState) => {
          return {
            data: currentState.data.concat(pageData),
            hasMore: pageData.length > 0,
            disableFlag: false,
            count: summary.count,
            loading: false,
          };
        });
      }
    );
  }

  resetData(e) {
    this.setState({
      pageIndex: -1,
      data: [],
      hasMore: true,
      statusFilter: this.statusSelect.value,
      categoryFilter: this.categorySelect.value,
      disableFlag: true,
    });
    if (e) e.preventDefault();
  }

  resetPage(e) {
    this.setState({
      data: [],
      hasMore: true,
      pageIndex: -1,
      value: "",
      statusFilter: "all",
      categoryFilter: "all",
      disableFlag: true,
      to: undefined,
      from: undefined,
    });
    this.statusSelect.value = "all";
    this.categorySelect.value = "all";
    e.preventDefault();
  }

  async getSuggestions(value) {
    return await services.getSugestions(
      0,
      5,
      "combinedName",
      value,
      "productCombined"
    );
  }

  render() {
    const { intl } = this.props;

    const inputProps = {
      className: "form-control",
      value: this.state.value,
      onChange: (e, { newValue }) => {
        this.setState({ value: newValue });
      },
      placeholder: intl.formatMessage({
        id: "global.search",
        defaultMessage: "Search",
      }),
    };

    return (
      <Auth requireAuth={true} roles={["sadmin"]}>
        <Layout
          hidden={this.props.match.params.isFormOpen}
          noLayout={this.props.noLayout}
          loading={this.state.loading}
        >
          <HeaderTitle id="product.products" defaultMessage="Products" />
          <div className="table-header clearfix">
            <form className="row" onSubmit={this.resetData.bind(this)}>
              <div>
                <div className="col-xs-3 pull-right">
                  <div className="panel-group-buttons pull-right">
                    <>
                      <CheckAccess
                        activityId={ActivitiesEnum.products__new_product}
                      >
                        <Link className="btn btn-special " to="/product">
                          <span className="fa fa-plus" />{" "}
                          <FormattedMessage
                            id="product.newProduct"
                            defaultMessage="Create Product"
                          />
                        </Link>
                      </CheckAccess>
                    </>
                  </div>
                </div>
                <CheckAccess
                  activityId={ActivitiesEnum.products__filter_and_search}
                >
                  <div className="col-lg-2 col-md-4 col-sm-12 col-xs-6">
                    <div className="has-feedback">
                      <Autosuggest
                        type="text"
                        inputProps={inputProps}
                        suggestions={this.state.suggestions}
                        onSuggestionsFetchRequested={async (value) => {
                          const newSuggestions = await this.getSuggestions(
                            value.value
                          );
                          this.setState({ suggestions: newSuggestions });
                        }}
                        onSuggestionsClearRequested={() =>
                          this.setState({ suggestions: [] })
                        }
                        getSuggestionValue={(suggestion) => {
                          return suggestion.options;
                        }}
                        renderSuggestion={(suggestion) => (
                          <div>{suggestion.options}</div>
                        )}
                        onSuggestionSelected={() => this.resetData()}
                      />
                      <i className="glyphicon glyphicon-search form-control-feedback" />
                    </div>
                  </div>
                  <div className="col-lg-2 col-md-4 col-sm-12 col-xs-6">
                    <select
                      ref={(c) => {
                        this.statusSelect = c;
                      }}
                      onChange={this.resetData.bind(this)}
                      className="form-control"
                    >
                      <option value="all">
                        {intl.formatMessage({
                          id: "product.status",
                          defaultMessage: "Status",
                        })}
                      </option>
                      <option value="active">
                        {intl.formatMessage({
                          id: "product.active",
                          defaultMessage: "Active",
                        })}
                      </option>
                      <option value="suspended">
                        {intl.formatMessage({
                          id: "product.suspended",
                          defaultMessage: "Suspended",
                        })}
                      </option>
                    </select>
                  </div>
                  <div className="col-lg-2 col-md-4 col-sm-12 col-xs-6">
                    <select
                      ref={(c) => {
                        this.categorySelect = c;
                      }}
                      onChange={this.resetData.bind(this)}
                      className="form-control"
                    >
                      <option ke fy="all" value="all">
                        {intl.formatMessage({
                          id: "product.category",
                          defaultMessage: "Category",
                        })}
                      </option>
                      {this.state.categories.map((cat) => {
                        return (
                          <option key={"opt-" + cat._id} value={cat._id}>
                            {appState.state.language === "ar"
                              ? cat.name.value_ar || cat.name.value_en
                              : cat.name.value_en}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div className="clearfix" />
                  <div className="col-lg-2 col-md-4 col-sm-12 col-xs-6">
                    <SingleDatePicker
                      id="fromDate"
                      placeholder={intl.formatMessage({
                        id: "global.from",
                        defaultMessage: "From",
                      })}
                      block={true}
                      small={true}
                      isOutsideRange={() => false}
                      numberOfMonths={1}
                      initialDate={this.state.from}
                      isRTL={appState.state.language === "ar" ? true : false}
                      date={this.state.from}
                      onDateChange={(date) => {
                        this.setState(
                          { from: date },
                          this.resetData.bind(this)
                        );
                      }}
                      focused={this.state.focusedFrom}
                      onFocusChange={({ focused }) =>
                        this.setState({ focusedFrom: focused })
                      }
                    />
                  </div>
                  <div className="col-lg-2 col-md-4 col-sm-12 col-xs-6">
                    <SingleDatePicker
                      id="toDate"
                      block={true}
                      placeholder={intl.formatMessage({
                        id: "global.to",
                        defaultMessage: "To",
                      })}
                      small={true}
                      isOutsideRange={() => false}
                      numberOfMonths={1}
                      initialDate={this.state.to}
                      isRTL={appState.state.language === "ar" ? true : false}
                      date={this.state.to}
                      onDateChange={(date) => {
                        this.setState({ to: date }, this.resetData.bind(this));
                      }}
                      focused={this.state.focusedTo}
                      onFocusChange={({ focused }) =>
                        this.setState({ focusedTo: focused })
                      }
                    />
                  </div>

                  <div className="col force--inline">
                    <div className="tooltip-master">
                      <button
                        href="javascript:;"
                        className="btn btn-primary"
                        onClick={this.resetData.bind(this)}
                        disabled={this.state.disableFlag}
                      >
                        <i className="material-icons">search</i>
                      </button>
                      <span className="tooltiptext">
                        <FormattedMessage
                          id="global.filter"
                          defaultMessage="Filter"
                        />
                      </span>
                    </div>{" "}
                  </div>

                  <div className="col force--inline">
                    <div className="tooltip-master">
                      <button
                        href="javascript:;"
                        className="btn btn-primary"
                        onClick={this.resetPage.bind(this)}
                        disabled={this.state.disableFlag}
                      >
                        <i className="material-icons">
                          settings_backup_restore
                        </i>
                      </button>
                      <span className="tooltiptext">
                        <FormattedMessage
                          id="global.resetFilter"
                          defaultMessage="Reset Filter"
                        />
                      </span>
                    </div>
                  </div>
                </CheckAccess>
              </div>
            </form>
            <div className="col-xs-6 pull-right">
              <div className="panel-group-buttons pull-right" />
            </div>
            <div className="row">
              <div className="col-md-12">
                <FormattedMessage
                  id="global.totalCount"
                  defaultMessage="Total Count"
                />

                <strong>
                  {" "}
                  <FormattedNumber
                    style="decimal"
                    maximumFractionDigits={0}
                    minimumFractionDigits={0}
                    value={this.state.count}
                  />{" "}
                </strong>
              </div>
            </div>
          </div>
          <Table
            loadMore={this.loadMore.bind(this)}
            hasMore={this.state.hasMore}
            caption={
              !this.state.data ||
              (this.state.data.length == 0 && (
                <caption
                  className="noDataCaption"
                  style={{
                    captionSide: "bottom",
                    textAlign: "center",
                    margin: "20px",
                    color: "darkgray",
                  }}
                >
                  <FormattedMessage
                    id="global.noData"
                    defaultMessage="No data available"
                  />
                </caption>
              ))
            }
            headerRow={
              <tr onClick={() => {}}>
                <th>
                  <FormattedMessage id="product.image" defaultMessage="Image" />
                </th>
                <th>
                  <FormattedMessage id="product.name" defaultMessage="Name" />
                </th>
                <th>
                  <FormattedMessage
                    id="product.category"
                    defaultMessage="Category"
                  />
                </th>
                <th>
                  <FormattedMessage
                    id="global.status"
                    defaultMessage="Status"
                  />
                </th>
                <th>
                  <FormattedMessage
                    id="global.createdAt"
                    defaultMessage="Created At"
                  />
                </th>
                <th style={{ width: "20px" }} />
              </tr>
            }
          >
            {this.state.data.map((c) => {
              if (_.isNull(c)) return;
              return (
                <tr
                  key={c.id}
                  onClick={() => {
                    this.props.navigate(`/product/${c.id}`);
                  }}
                  className="cases-trow link-row"
                >
                  <ImageProvider imageSrc={c.productThumbnailEn} />
                  <td>
                    {appState.state.language === "ar"
                      ? c.arabicName || c.englishName
                      : c.englishName}
                  </td>
                  <td>
                    {appState.state.language === "ar"
                      ? c.categoryArabicName || c.categoryEnglishName
                      : c.categoryEnglishName}
                  </td>
                  <td
                    className={classNames({
                      "col-status-green": c.isActive,
                      "col-status-red": !c.isActive,
                    })}
                  >
                    <a href="javascript:;">
                      {c.isActive && (
                        <FormattedMessage
                          id="global.active"
                          defaultMessage="Active"
                        />
                      )}
                      {!c.isActive && (
                        <FormattedMessage
                          id="global.suspended"
                          defaultMessage="Suspended"
                        />
                      )}
                    </a>
                  </td>
                  <td>
                    {c.createdAt ? getLocalDateTime(c.createdAt) : undefined}
                  </td>
                </tr>
              );
            })}
          </Table>
        </Layout>
      </Auth>
    );
  }
}

export default withRouter(injectIntl(ProductList));
