import React, { Component, createRef } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Form, Formik } from "formik";
import { Yup } from "../../util";
import Layout from "../shared/Layout";
import Blog from "../../services/blogService";
import { NotificationManager } from "react-notifications";
import _ from "lodash";
import Auth from "../shared/Auth";
import FieldRow from "../shared/FieldRow";
import withRouter from "../../util/withRouter";
import appState from "../../state/AppStateContainer";
import HeaderTitle from "../shared/HeaderTitle";
import { withAuthContext } from "../../state/AuthContext";
import Select from "react-select";
import { Button } from "react-bootstrap";

export class BlogForm extends Component {
  constructor() {
    super();
    this.formik = createRef();
  }

  state = {
    data: {},
    tags: [],
    selectedTags: [],
    loading: true,
  };

  async componentDidMount() {
    try {
      this.subscription = appState.emitter.addListener(
        "onLanguageChange",
        () => {
          this.formik.validateForm();
        }
      );

      const tags = await Blog.getBlogTags({ isActive: true });
      if (this.props.match.params.id) {
        const blogs = await Blog.getBlogs({ _id: this.props.match.params.id });
        const selectedTags = this.createTagsOption(
          tags.response.filter(({ _id }) =>
            blogs.response[0].tags.includes(_id)
          )
        );
        this.setState({
          data: blogs.response,
          selectedTags,
          loading: true,
        });
      }

      const tagOptions = this.createTagsOption(tags.response);
      this.setState({ tags: tagOptions });
    } catch (e) {
      this.props.navigate("/error", {
        error: _.get(e, "response.data.error", "error.unexpectedError"),
      });
    } finally {
      this.setState({ loading: false });
    }
  }

  componentWillUnmount() {
    this.subscription.remove();
  }

  createTagsOption(tags) {
    return tags.map(({ _id, value, name, label }) => ({
      value: value || _id,
      label: label || name[appState.state.language],
    }));
  }

  handleTagsChange(values, setFieldValue) {
    setFieldValue("tags", this.createTagsOption(values));
    this.setState({ selectedTags: this.createTagsOption(values) });
  }

  async handleSubmit(values, { setSubmitting }) {
    try {
      if (!this.formik.isSubmitting) return;
      setSubmitting(true);

      const payload = {
        ...values,
        tags: this.state.selectedTags.map(({ value }) => value),
      };

      await Blog.addBlog(payload, this.props.match.params.id);
      this.props.navigate("/blogs");
      NotificationManager.success(
        this.props.intl.formatMessage({
          id: "global.operationSuccessful",
          defaultMessage: "Operation Successful",
        }),
        this.props.intl.formatMessage({
          id: "global.success",
          defaultMessage: "Success",
        })
      );
    } catch (e) {
      NotificationManager.error(
        this.props.intl.formatMessage({
          id: _.get(e, "response.data.error", "error.unexpectedError"),
          defaultMessage: "An unexpected error has occurred",
        }),
        this.props.intl.formatMessage({
          id: "global.error",
          defaultMessage: "Error",
        })
      );
    } finally {
      setSubmitting(false);
    }
  }

  _renderBody(formikProps) {
    const { isSubmitting, setFieldValue, errors } = formikProps;

    return (
      <Layout noLayout={this.props.noLayout} loading={this.state.loading}>
        <HeaderTitle id="blogs.blogs" defaultMessage="Blogs" />
        <div className="dashboard-table-container card-layout">
          <div className="panel-primary">
            <header className="panel-title">
              {!this.props.match.params.id ? (
                <FormattedMessage
                  id="blogs.addBlog"
                  defaultMessage="Add Blog"
                />
              ) : (
                <FormattedMessage
                  id="blogs.updateBlog"
                  defaultMessage="Update Blog"
                />
              )}
            </header>
            <Form>
              <div className="dashboard-row">
                <FieldRow
                  type="text"
                  name="title_en"
                  labelId="blogs.blogTitleEn"
                  defaultLabel="Blog Title (English)"
                  placeholderId="blogs.blogTitleEn"
                  defaultPlaceholder="Blog Title (English)"
                  formikProps={formikProps}
                  showRequiredFlag={true}
                />
                <FieldRow
                  type="text"
                  name="title_ar"
                  labelId="blogs.blogTitleAr"
                  defaultLabel="Blog Title (Arabic)"
                  placeholderId="blogs.blogTitleAr"
                  defaultPlaceholder="Blog Title (Arabic)"
                  formikProps={formikProps}
                  showRequiredFlag={true}
                />
                <br />
                <FieldRow
                  type="file"
                  name="thumbnail_en"
                  labelId="blogs.thumbnailEn"
                  defaultLabel="Thumbnail (English)"
                  aspectRatio={397 / 312}
                  imageSize="W:397 x H:312"
                  formikProps={formikProps}
                  image={true}
                  showThumbnail={true}
                  cameraIcon={true}
                  disabled={false}
                  showRequiredFlag={true}
                />
                <FieldRow
                  type="file"
                  name="thumbnail_ar"
                  labelId="blogs.thumbnailAr"
                  defaultLabel="Thumbnail (Arabic)"
                  aspectRatio={397 / 312}
                  imageSize="W:397 x H:312"
                  formikProps={formikProps}
                  image={true}
                  showThumbnail={true}
                  cameraIcon={true}
                  disabled={false}
                  showRequiredFlag={true}
                />
                <FieldRow
                  type="file"
                  name="banner_en"
                  labelId="blogs.bannerEn"
                  defaultLabel="Banner (English)"
                  aspectRatio={1130 / 450}
                  imageSize="W:1130 x H:450"
                  formikProps={formikProps}
                  image={true}
                  showThumbnail={true}
                  cameraIcon={true}
                  disabled={false}
                  showRequiredFlag={true}
                />
                <FieldRow
                  type="file"
                  name="banner_ar"
                  labelId="blogs.bannerAr"
                  defaultLabel="Banner (Arabic)"
                  aspectRatio={1130 / 450}
                  imageSize="W:1130 x H:450"
                  formikProps={formikProps}
                  image={true}
                  showThumbnail={true}
                  cameraIcon={true}
                  disabled={false}
                  showRequiredFlag={true}
                />
                <FieldRow
                  type="html"
                  name="content_en"
                  labelId="blogs.blogDescriptionEn"
                  defaultLabel="Blog Content (English)"
                  formikProps={formikProps}
                  showRequiredFlag={true}
                  textEditor={true}
                />
                <FieldRow
                  type="html"
                  name="content_ar"
                  labelId="blogs.blogDescriptionAr"
                  defaultLabel="Blog Content (Arabic)"
                  formikProps={formikProps}
                  showRequiredFlag={true}
                  textEditor={true}
                />
                <br />
                <FieldRow
                  type="file"
                  name="contentImg_en"
                  labelId="blogs.contentImageEn"
                  defaultLabel="Content Image (English)"
                  aspectRatio={600 / 450}
                  formikProps={formikProps}
                  image={true}
                  showThumbnail={true}
                  cameraIcon={true}
                  disabled={false}
                  showRequiredFlag={true}
                />
                <FieldRow
                  type="file"
                  name="contentImg_ar"
                  labelId="blogs.contentImageAr"
                  defaultLabel="Content Image (Arabic)"
                  aspectRatio={600 / 450}
                  formikProps={formikProps}
                  image={true}
                  showThumbnail={true}
                  cameraIcon={true}
                  disabled={false}
                  showRequiredFlag={true}
                />
                <FieldRow
                  name="ctaText_en"
                  labelId="blogs.ctaText_en"
                  defaultLabel="CTA Button Text (English)"
                  placeholderId="blogs.ctaText_en"
                  defaultPlaceholder="CTA Button Text (English)"
                  formikProps={formikProps}
                />
                <FieldRow
                  name="ctaText_ar"
                  labelId="blogs.ctaText_ar"
                  defaultLabel="CTA Button Text (Arabic)"
                  placeholderId="blogs.ctaText_ar"
                  defaultPlaceholder="CTA Button Text (Arabic)"
                  formikProps={formikProps}
                />
                <FieldRow
                  name="ctaLink"
                  labelId="blogs.ctaLink"
                  defaultLabel="CTA Button Link (e.g: /offers)"
                  placeholderId="blogs.ctaLink"
                  defaultPlaceholder="CTA Button Link"
                  formikProps={formikProps}
                />

                <div className="row">
                  <div className="col-md-2 col-xs-12">
                    <label className="form-label caps block">
                      <FormattedMessage
                        id="blogs.assignTags"
                        defaultMessage="Assign tags"
                      />
                    </label>
                  </div>
                  <div className="input-group col-md-8 col-sm-8 col-xs-12">
                    <Select
                      name="tags"
                      options={this.state.tags}
                      placeholder={this.props.intl.formatMessage({
                        id: "blogs.selectTags",
                        defaultMessage: "All Blogs / Select tag(s)",
                      })}
                      value={this.state.selectedTags}
                      onChange={(values) =>
                        this.handleTagsChange(values, setFieldValue)
                      }
                      closeMenuOnSelect={false}
                      isDisabled={false}
                      isMulti={true}
                    />
                    {errors.tags && (
                      <span className="text-warning">{errors.tags}</span>
                    )}
                  </div>
                </div>
                <FieldRow
                  type="checkbox"
                  name="isActive"
                  labelId="blogs.isActive"
                  defaultLabel="Active"
                  placeholderId="blogs.isActive"
                  formikProps={formikProps}
                />
              </div>
              <div className="dashboard-row -nobottom text-right">
                {this.props.authContext.authorized && (
                  <input
                    type="submit"
                    className="btn btn-primary"
                    value={this.props.intl.formatMessage({
                      id: "global.submit",
                      defaultMessage: "Submit",
                    })}
                    disabled={isSubmitting}
                  />
                )}
                <Button
                  variant="link"
                  onClick={() => this.props.navigate("/blogs")}
                >
                  {this.props.intl.formatMessage({
                    id: "global.cancel",
                    defaultMessage: "Cancel",
                  })}
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Layout>
    );
  }

  render() {
    const { intl } = this.props;
    let data = this.state.data;
    if (data.length) data = data[0];
    data.tags = this.state.selectedTags.map(({ value }) => value);

    return (
      <Auth requireAuth={true} roles={["sadmin"]}>
        <Formik
          innerRef={(f) => {
            this.formik = f;
          }}
          enableReinitialize
          initialValues={{
            title_en: data.title?.en,
            title_ar: data.title?.ar,
            thumbnail_en: data.thumbnail?.en,
            thumbnail_ar: data.thumbnail?.ar,
            banner_en: data.banner?.en,
            banner_ar: data.banner?.ar,
            content_en: data.content?.en || "",
            content_ar: data.content?.ar || "",
            contentImg_en: data.contentImg?.en || "",
            contentImg_ar: data.contentImg?.ar || "",
            ctaText_en: data.ctaText?.en || "",
            ctaText_ar: data.ctaText?.ar || "",
            ctaLink: data.ctaLink || "",
            tags: data.tags || [],
            isActive: data.isActive || false,
          }}
          validationSchema={Yup.object().shape(
            {
              title_en: Yup.string()
                .hasNoArabic(
                  intl.formatMessage({
                    id: "error.arabicCharactersAreNotAllowedInThisField",
                    defaultMessage:
                      "Arabic characters are not allowed in this field",
                  })
                )
                .label(
                  intl.formatMessage({
                    id: "blogs.blogTitle",
                    defaultMessage: "Blog title",
                  })
                )
                .trim()
                .required(),
              title_ar: Yup.string()
                .hasNoEnglish(
                  intl.formatMessage({
                    id: "error.englishCharactersAreNotAllowedInThisField",
                    defaultMessage:
                      "English characters are not allowed in this field",
                  })
                )
                .label(
                  intl.formatMessage({
                    id: "blogs.blogTitle",
                    defaultMessage: "Blog title",
                  })
                )
                .trim()
                .required(),
              thumbnail_en: Yup.string()
                .label(
                  intl.formatMessage({
                    id: "blogs.thumbnail_en",
                    defaultMessage: "Thumbnail",
                  })
                )
                .required(),
              thumbnail_ar: Yup.string()
                .label(
                  intl.formatMessage({
                    id: "blogs.thumbnail_ar",
                    defaultMessage: "Thumbnail",
                  })
                )
                .required(),
              banner_en: Yup.string()
                .label(
                  intl.formatMessage({
                    id: "blogs.banner_en",
                    defaultMessage: "Banner",
                  })
                )
                .required(),
              banner_ar: Yup.string()
                .label(
                  intl.formatMessage({
                    id: "blogs.banner_ar",
                    defaultMessage: "Banner",
                  })
                )
                .required(),
              content_en: Yup.string()
                .label(
                  intl.formatMessage({
                    id: "blogs.blogDescription",
                    defaultMessage: "Blog description",
                  })
                )
                .trim()
                .required(),
              content_ar: Yup.string()
                .label(
                  intl.formatMessage({
                    id: "blogs.blogDescription",
                    defaultMessage: "Blog description",
                  })
                )
                .trim()
                .required(),
              ctaText_en: Yup.string()
                .label(
                  intl.formatMessage({
                    id: "blogs.ctaText_en",
                    defaultMessage: "CTA text",
                  })
                )
                .trim()
                .when(["ctaText_ar", "ctaLink"], {
                  is: (ctaText_ar, ctaLink) => !!ctaText_ar || !!ctaLink,
                  then: (schema) =>
                    schema.required(
                      intl.formatMessage({
                        id: "blogs.ctaText_en_required",
                        defaultMessage: "CTA text in English is required",
                      })
                    ),
                  otherwise: (schema) => schema.notRequired(),
                }),
              ctaText_ar: Yup.string()
                .label(
                  intl.formatMessage({
                    id: "blogs.ctaText_ar",
                    defaultMessage: "CTA text",
                  })
                )
                .trim()
                .when(["ctaText_en", "ctaLink"], {
                  is: (ctaText_en, ctaLink) => !!ctaText_en || !!ctaLink,
                  then: (schema) =>
                    schema.required(
                      intl.formatMessage({
                        id: "blogs.ctaText_ar_required",
                        defaultMessage: "CTA text in Arabic is required",
                      })
                    ),
                  otherwise: (schema) => schema.notRequired(),
                }),
              ctaLink: Yup.string()
                .label(
                  intl.formatMessage({
                    id: "blogs.ctaLink",
                    defaultMessage: "CTA link",
                  })
                )
                .when(["ctaText_en", "ctaText_ar"], {
                  is: (ctaText_en, ctaText_ar) => !!ctaText_en || !!ctaText_ar,
                  then: (schema) =>
                    schema.required(
                      intl.formatMessage({
                        id: "blogs.ctaLink_required",
                        defaultMessage: "CTA link is required",
                      })
                    ),

                  otherwise: (schema) => schema.notRequired(),
                })
                .trim(),
              tags: Yup.array(),
            },
            [
              ["ctaText_en", "ctaText_ar"],
              ["ctaText_en", "ctaLink"],
              ["ctaText_ar", "ctaLink"],
            ]
          )}
          onSubmit={this.handleSubmit.bind(this)}
        >
          {this._renderBody.bind(this)}
        </Formik>
      </Auth>
    );
  }
}

export default withAuthContext(withRouter(injectIntl(BlogForm)));
