import React, { Component, createRef } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Form, Formik } from "formik";
import { Yup } from "../../util";
import Layout from "../shared/Layout";
import services from "../../services";
import { NotificationManager } from "react-notifications";
import _ from "lodash";
import Loading from "../shared/Loading";
import Auth from "../shared/Auth";
import FieldRow from "../shared/FieldRow";
import withRouter from "../../util/withRouter";
import appState from "../../state/AppStateContainer";
import CheckAccess from "../shared/CheckAccess";
import { ActivitiesEnum } from "../../staticData/Activities";
import HeaderTitle from "../shared/HeaderTitle";
import { withAuthContext } from "../../state/AuthContext";
import { Button } from "react-bootstrap";
export class KaizenForm extends Component {
  constructor() {
    super();
    this.formik = createRef();
  }

  state = {
    suggestion: [],
    data: {},
    statusFilter: "all",
    approvalFilter: "all",
    loading: true,
  };

  async componentDidMount() {
    try {
      this.subscription = appState.emitter.addListener(
        "onLanguageChange",
        () => {
          this.formik.validateForm();
        }
      );

      if (this.props.match.params.id) {
        const data = await services.getKaizenById(this.props.match.params.id, {
          progressBar: true,
        });
        this.setState({ data });
      }
    } catch (e) {
      this.props.navigate("/error", {
        error: _.get(e, "response.data.error", "error.unexpectedError"),
      });
    } finally {
      this.setState({ loading: false });
    }
  }

  componentWillUnmount() {
    this.subscription.remove();
  }

  async handleSubmit(values, { setSubmitting }) {
    this.setState({ loading: true });
    try {
      const payload = {
        ...values,
      };
      let response;
      if (!this.props.match.params.id) {
        response = await services.addKaizen(payload);
      } else {
        const request = { id: this.props.match.params.id, ...payload };
        response = await services.updateKaizen(request);
      }

      NotificationManager.success(
        this.props.intl.formatMessage({
          id: "global.operationSuccessful",
          defaultMessage: "Operation Successful",
        }),
        this.props.intl.formatMessage({
          id: "global.success",
          defaultMessage: "Success",
        })
      );

      this.props.navigate("/kaizens", {
        isSubmitted: true,
        isFormOpen: true,
      });
    } catch (e) {
      NotificationManager.error(
        this.props.intl.formatMessage({
          id: _.get(e, "response.data.error", "error.unexpectedError"),
          defaultMessage: "An unexpected error has occurred",
        }),
        this.props.intl.formatMessage({
          id: "global.error",
          defaultMessage: "Error",
        })
      );
    } finally {
      setSubmitting(false);
      this.setState({ loading: false });
    }
  }

  _renderBody(formikProps) {
    const { isSubmitting, dirty } = formikProps;

    return (
      <Layout noLayout={this.props.noLayout} loading={this.state.loading}>
        <HeaderTitle id="kaizen.title" defaultMessage="Kaizen" />
        <div className="dashboard-table-container card-layout">
          <div className="panel-primary">
            <header className="panel-title">
              {this.props.match.params.id ? (
                <FormattedMessage
                  id="offer.updateKaizen"
                  defaultMessage="Update Kaizen"
                />
              ) : (
                <FormattedMessage
                  id="offer.addKaizen"
                  defaultMessage="Add Kaizen"
                />
              )}
            </header>
            <Form>
              <div className="dashboard-row">
                <FieldRow
                  type="text"
                  name="englishName"
                  labelId="kaizen.englishName"
                  defaultLabel="Service Name (English)"
                  placeholderId="kaizen.enterEnglishName"
                  defaultPlaceholder="Enter english name"
                  formikProps={formikProps}
                  showRequiredFlag={true}
                />
                <FieldRow
                  name="arabicName"
                  type={"text"}
                  labelId="kaizen.arabicName"
                  defaultLabel="Service Name (Arabic)"
                  placeholderId="kaizen.enterArabicName"
                  defaultPlaceholder="Enter arabic name"
                  showRequiredFlag={true}
                  formikProps={formikProps}
                />
                <FieldRow
                  type="html"
                  name="englishDescription"
                  labelId="kaizen.descriptionEnglish"
                  defaultLabel="Kaizen Description English"
                  showRequiredFlag={true}
                  formikProps={formikProps}
                  textEditor={true}
                />
                <FieldRow
                  type="html"
                  name="arabicDescription"
                  labelId="kaizen.descriptionArabic"
                  defaultLabel="Kaizen Description Arabic"
                  showRequiredFlag={true}
                  formikProps={formikProps}
                  textEditor={true}
                />
                <FieldRow
                  name="image"
                  type="file"
                  image={true}
                  aspectRatio={325.8 / 325}
                  imageSize="W:325.8 x H:325"
                  cameraIcon={true}
                  showThumbnail={true}
                  labelId="kaizen.image"
                  defaultLabel="Image"
                  placeholderId="kaizen.selectImage"
                  defaultPlaceholder="Select Image"
                  showRequiredFlag={true}
                  formikProps={formikProps}
                />
                <FieldRow
                  name="isActive"
                  type="checkbox"
                  labelId="kaizen.active"
                  defaultLabel="Is Active"
                  formikProps={formikProps}
                  showRequiredFlag={false}
                />
              </div>
              <div className="dashboard-row -nobottom">
                <CheckAccess activityId={ActivitiesEnum.kaizen_add_update}>
                  {this.props.authContext.authorized && (
                    <input
                      type="submit"
                      className="btn btn-primary"
                      value={this.props.intl.formatMessage({
                        id: "global.submit",
                        defaultMessage: "Submit",
                      })}
                      disabled={isSubmitting || !dirty}
                    />
                  )}
                </CheckAccess>
                <Button
                  variant="link"
                  onClick={() => this.props.navigate("/kaizens")}
                  disabled={isSubmitting}
                >
                  {this.props.intl.formatMessage({
                    id: "global.cancel",
                    defaultMessage: "Cancel",
                  })}
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Layout>
    );
  }

  render() {
    const { intl } = this.props;
    console.log(this.state.data);
    return (
      <Auth requireAuth={true} roles={["sadmin", "wsadmin", "spadmin"]}>
        <Formik
          innerRef={(f) => {
            this.formik = f;
          }}
          enableReinitialize
          initialValues={{
            englishName: this.state.data.name?.value_en || "",
            arabicName: this.state.data.name?.value_ar || "",
            englishDescription: this.state.data.description?.value_en || "",
            arabicDescription: this.state.data.description?.value_ar || "",
            image: this.state.data.image || "",
            isActive: this.state.data?.isActive || false,
          }}
          validationSchema={Yup.object().shape({
            englishName: Yup.string()
              .hasNoArabic(
                intl.formatMessage({
                  id: "error.arabicCharactersAreNotAllowedInThisField",
                  defaultMessage:
                    "Arabic characters are not allowed in this field",
                })
              )
              .label(
                intl.formatMessage({
                  id: "offer.englishName",
                  defaultMessage: "English name",
                })
              )
              .trim()
              .required(),
            englishDescription: Yup.string()
              .hasNoArabic(
                intl.formatMessage({
                  id: "error.arabicCharactersAreNotAllowedInThisField",
                  defaultMessage:
                    "Arabic characters are not allowed in this field",
                })
              )
              .label(
                intl.formatMessage({
                  id: "service.englishDescription",
                  defaultMessage: "English description",
                })
              )
              .trim()
              .required(),
            arabicName: Yup.string()
              .label(
                intl.formatMessage({
                  id: "service.arabicName",
                  defaultMessage: "Arabic Name",
                })
              )
              .trim()
              .required()
              .hasNoEnglish(
                intl.formatMessage({
                  id: "error.englishCharactersAreNotAllowedInThisField",
                  defaultMessage:
                    "English characters are not allowed in this field",
                })
              ),
            arabicDescription: Yup.string()
              .label(
                intl.formatMessage({
                  id: "service.arabicDescription",
                  defaultMessage: "Arabic Description",
                })
              )
              .trim()
              .required(),
            image: Yup.mixed()
              .label(
                intl.formatMessage({
                  id: "service.image",
                  defaultMessage: "Image",
                })
              )
              .required(),
          })}
          onSubmit={this.handleSubmit.bind(this)}
        >
          {this._renderBody.bind(this)}
        </Formik>
      </Auth>
    );
  }
}

export default withAuthContext(withRouter(injectIntl(KaizenForm)));
