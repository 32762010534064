import BaseService from "./baseService";
import axios from "axios";

class VariantService extends BaseService {
  constructor() {
    super();
  }

  async addVariant(values) {
    const formData = new FormData();
    for (const key in values) {
      if (values.hasOwnProperty(key)) {
        if (key === "inventory") {
          formData.append(key, JSON.stringify(values[key]));
        } else {
          formData.append(key, values[key]);
        }
      }
    }

    const response = await axios.post("/api/variant", formData);
    return response.data;
  }

  async updateVariant(values) {
    const formData = new FormData();
    for (const key in values) {
      if (key === "inventory") {
        formData.append(key, JSON.stringify(values[key]));
      } else {
        formData.append(key, values[key]);
      }
    }

    const response = await axios.put("/api/variant", formData);
    return response.data;
  }

  async getVariants(
    searchFilter,
    statusFilter,
    categoryFilter,
    pageIndex,
    pageSize,
    options = {},
    summary,
  ) {
    const response = await axios.get("/api/variants", {
      params: {
        searchFilter,
        statusFilter: statusFilter ? statusFilter : "all",
        categoryFilter,
        pageIndex,
        pageSize,
        summary
        // alphabeticalOrder: options.alphabeticalOrder,
      },
      progressBar: options.progressBar,
    });
    return response.data;
  }

  async getVariant(id) {
    const options = {};
    if (id) options.params = { id };
    const response = await axios.get("/api/variant", {
      params: { id },
    });
    return response.data;
  }
  async getBranchVariantHistory(variantId, branchId) {
    const response = await axios.get("/api/inventory/history", {
      params: { variantId, branchId },
    });
    return response.data;
  }
  async updateInventoryStatus(variantId, branchId, status) {
    const response = await axios.put("/api/variant/inventory/status", {
      variantId,
      branchId,
      status,
    });
    return response.data;
  }
}

export default new VariantService();
