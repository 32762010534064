import React, { Component, createRef } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Form, Formik, Field } from "formik";
import { Yup } from "../../util";
import Layout from "../shared/Layout";
import services from "../../services";
import { NotificationManager } from "react-notifications";
import { get, orderBy, isEmpty } from "lodash";
import Auth from "../shared/Auth";
import FieldRow from "../shared/FieldRow";
import withRouter from "../../util/withRouter";
import appState from "../../state/AppStateContainer";
import CheckAccess from "../shared/CheckAccess";
import { ActivitiesEnum } from "../../staticData/Activities";
import HeaderTitle from "../shared/HeaderTitle";
import { withAuthContext } from "../../state/AuthContext";
import classNames from "classnames";
import BranchesService from "../../services/branchesService";
import { Modal, Button, Accordion } from "react-bootstrap";
import enums from "../../util/enums";
import {
  getNormalSlots,
  combinedDays,
  week,
  cities,
  getFormatedDate,
} from "../../util/utils";
import moment from "moment";
import PrimaryDetails from "./sections/PrimaryDetails";
import BranchTimings from "./sections/BranchTimings";
import Initializeform from "./functions/initializeForm";
import Payloads from "./functions/payloads";
import InvoiceDetails from "./sections/InvoiceDetails";

export class BranchesForm extends Component {
  constructor() {
    super();
    this.checkboxRef = createRef();
    this.daysRef = createRef();
    this.eventsForm = createRef();
    this.formik = createRef();
    this.displayShopTime = Initializeform.customizeHourlySlots();
    this.citiesArray = cities.map((city) => {
      return {
        id: city.id,
        name: city.name,
      };
    });
    this.watchFormValues = "";
    this.defaultSelect = { id: "workingDays", name: "Working Days" };
    this.lang = "en";
  }

  state = {
    data: {},
    servicesList: [],
    statusFilter: "all",
    approvalFilter: "all",
    loading: true,
    validateServices: false,
    validateSlots: false,
    trackSelectedServices: [],
    timeSlotsModal: false,
    serviceData: {},
    timeSlotsData: [],
    timeSlotCount: 0,
    keepAllTimeSlots: [],
    basicDetailError: false,
    InvoiceDetailsError: false,
    errInspirageId: false,
    errBranchCode: false,
    servicesToOfferExpanded: false,
    servicesExpandedError: false,
    eventDays: [],
    eventsData: [{ id: "workingDays", name: "Working Days" }],
    eventsDataCopy: [],
    slotsFor: "workingDays",
    keepAllEventsTimeSlots: [],
    holidayFormShow: false,
    eventsErrorMsg: "",
    hasMarkedAllDays: false,
    offers: [],
    associatedOffersSelected: [],
  };

  async componentDidMount() {
    try {
      this.subscription = appState.emitter.addListener("onLanguageChange", () =>
        this.formik.validateForm()
      );

      const offersForService = await BranchesService.getOffersAgainstService();

      // Getting all created services
      this.setState({
        loading: true,
        offers: offersForService,
        servicesList: orderBy(
          await services.getServicesList(),
          ["_id"],
          ["asc"]
        ),
      });

      // During update: Initialize update branch form
      if (this.props.match.params.id) await this.setUpForm();
    } catch (e) {
      this.props.navigate("/error", {
        error: get(e, "response.data.error", "error.unexpectedError"),
      });
    } finally {
      this.setState({ loading: false });
    }
  }

  componentDidUpdate() {
    appState.state.language === "en" ? (this.lang = "en") : (this.lang = "ar");
  }

  async componentWillUnmount() {
    this.subscription.remove();
  }

  async setUpForm() {
    // Getting saved brannch data
    const { branch, serviceIds } = await Initializeform.getBranch(
      this.props.match.params.id
    );

    // Geting slots and setting in states
    const { workingDaysSlots, eventDaysSlots } = await Initializeform.getSlots(
      this.props.match.params.id,
      serviceIds
    );

    // Getting saved events from particular branch and setting in state
    const { occasions, events } = await Initializeform.deCompileOccasions(
      this.props.match.params.id
    );

    // Initialize services
    const { servicesData, selectedIds } =
      Initializeform.deCompileServices(branch);

    // Handling services checkboxes to mark check for selected services
    Array.from(this.checkboxRef.current.children).forEach((category) => {
      Array.from(category.children).forEach((label) => {
        if (label.nodeName.toLowerCase() === "label") {
          if (selectedIds.includes(label.children[0].value)) {
            label.children[0].checked = true;
          }
        }
      });
    });

    // Set data
    const initialState = {
      keepAllTimeSlots: workingDaysSlots,
      associatedOffersSelected: branch.associatedOffers,
      keepAllEventsTimeSlots: eventDaysSlots,
      eventsData: occasions,
      eventsDataCopy: occasions,
      eventDays: events,
      trackSelectedServices: servicesData,
    };
    if (events.length) initialState.holidayFormShow = true;
    this.setState({ ...initialState, data: branch });
  }

  // Showing selected services bullets on checkboxes change
  showSelectedServices(e, service) {
    if (e.target.checked) {
      this.setState({
        validateServices: false,

        // Appending new selected service
        trackSelectedServices: [
          ...this.state.trackSelectedServices,
          { id: service.id, en: service.nameEn, ar: service.nameAr },
        ],

        // Initializing normal days timslots
        keepAllTimeSlots: [
          ...this.state.keepAllTimeSlots,
          { service: service.id, slots: [] },
        ],

        // Initializing events timeslots
        keepAllEventsTimeSlots: [
          ...this.state.keepAllEventsTimeSlots,
          { service: service.id, events: [{ event: "", slots: [] }] },
        ],
      });
    } else {
      // Deleting services on de-selection
      this.setState({
        validateServices: false,
        validateSlots: false,
        trackSelectedServices: this.state.trackSelectedServices.filter(
          (s) => s.id !== service.id
        ),
        associatedOffersSelected: this.state.associatedOffersSelected.filter(
          (s) => s.serviceType !== service.id
        ),

        // Deleting normal days timeslots of particular service on de-selection
        keepAllTimeSlots: this.state.keepAllTimeSlots.filter(
          (sl) => sl.service !== service.id
        ),

        // Deleting events timeslots of particular service on de-selection
        keepAllEventsTimeSlots: this.state.keepAllEventsTimeSlots.filter(
          (sl) => sl.service !== service.id
        ),
      });
    }
  }

  // Timeslots modal
  async handleModalShow(s) {
    this.setState({
      timeSlotsModal: true,
      serviceData: { name: { en: s.en, ar: s.ar }, id: s.id },
      slotsFor: "workingDays",
    });

    // Initializing modal
    this.arrangeSlotsforDay({ target: { value: "" } }, s.id);
  }

  // Function to show slots of each day (Normal days)
  async arrangeSlotsforDay(e, serviceId) {
    // For normal days
    if (this.state.slotsFor == "workingDays") {
      const firedValue = e.target.value; // Day name (onchange)
      const selectedDay = enums.COMBINED_DAYS[firedValue]?.en;
      const allSlots = getNormalSlots().allSlots;
      const formOpenTime = this.watchFormValues["openingTime" + selectedDay]; // open time
      const formCloseTime = this.watchFormValues["closingTime" + selectedDay]; // close time
      let slotsToRender;
      // let slotsData;

      if (formOpenTime && formCloseTime) {
        const openTime = allSlots.indexOf(formOpenTime);
        const closeTime = allSlots.indexOf(formCloseTime);
        let shopTimings;

        if (openTime < closeTime)
          shopTimings = allSlots.slice(openTime, closeTime);
        else {
          //for after 8:00am slots
          shopTimings = allSlots.slice(openTime, allSlots.length);
          const tempArr = allSlots.slice(0, closeTime);
          shopTimings = shopTimings.concat(tempArr);
        }

        // if (this.props.match.params.id) {
        //   // Get saved slots during update (Endpoint)
        //   const response = await BranchesService.getSlotsByDay(
        //     this.props.match.params.id,
        //     serviceId,
        //     firedValue
        //   );
        //   if (response?.length > 0 && response[0]?.laneCapacity.length > 0) {
        //     slotsData = response[0].laneCapacity[0].slots.filter(({ time }) =>
        //       shopTimings.includes(time)
        //     );
        //   }
        // }

        this.setState({
          slotsForDay: {
            en: selectedDay,
            ar: enums.COMBINED_DAYS[firedValue]?.ar,
          },
        });

        // Check if slots are saved by user on state locally
        let savedSlots = this.state.keepAllTimeSlots
          .find((s) => s.service == serviceId)
          ?.slots.find((sl) => sl.day == firedValue);
        savedSlots = savedSlots?.timeSlots.filter(({ time }) =>
          shopTimings.includes(time)
        );
        savedSlots =
          savedSlots &&
          shopTimings.map((st) => {
            const _slot = savedSlots.find((ss) => ss.time === st);
            if (_slot) return _slot;
            return {
              time: st,
              numberOfSlots: 0,
            };
          });

        if (savedSlots) {
          slotsToRender = this.slotsPopulator(savedSlots);
        }
        // else if (slotsData) {
        //   slotsToRender = this.slotsPopulator(slotsData);
        // }
        else {
          // Else show default slots as per shop timings
          slotsToRender = shopTimings.map((time) => {
            return {
              time: time,
              numberOfSlots: 0,
            };
          });
        }

        // To show some extra details on modal
        this.setState({
          timeSlotsData: {
            service: serviceId,
            days: firedValue,
            slots: slotsToRender,
          },
        });

        // Add/Delete slot button controls
        this.manageSlotsControls(shopTimings);
      } else {
        this.removeExtraDetailsOfModal();
      }
    } else if (e.target.value) {
      this.arrangeSlotsforEventDays(e, serviceId);
    } else {
      this.removeExtraDetailsOfModal();
    }
  }

  // Empty extra details from modal
  removeExtraDetailsOfModal() {
    this.setState({ timeSlotsData: [], timeSlotCount: "", slotsForDay: "" });
  }

  // On change of occasion and working days dropdown
  settingSlotsForChange(e, serviceId) {
    let selectedEvent = e.target.value;
    this.daysRef.current.value = "";
    this.setState({
      slotsFor: selectedEvent,
      slotsForDay: "",
      timeSlotsData: [],
    });
    this.arrangeSlotsforDay({ target: { value: "" } }, serviceId);

    this.gettingDaysFromRange(selectedEvent);
  }

  // Get days between selected event start and end date
  gettingDaysFromRange(selectedEvent) {
    let event = this.state.eventsData.find((e) => e.id == selectedEvent);
    if (event.id != "workingDays") {
      let startDate = moment(event.startDate);
      let endDate = moment(event.endDate);
      let dateList = this.getDaysBetweenDates(startDate, endDate);

      const dayNames = dateList.map((date) => {
        let day = moment(date, "MM/DD/YYYY").locale("en");
        return day.format("dddd").toLowerCase();
      });

      let days = [...new Set(dayNames)];

      if (dayNames.length > 7) {
        const misplaced = days.splice(days.indexOf("monday"));
        days = [...misplaced, ...days];
      }

      this.setState({ daysRangeToShow: days });
    }
  }

  // All dates between selected start and end date
  getDaysBetweenDates(startDate, endDate) {
    let now = startDate.clone(),
      dates = [];
    while (now.isSameOrBefore(endDate)) {
      dates.push(now.format("MM/DD/YYYY"));
      now.add(1, "days");
    }
    return dates;
  }

  // Function to show slots of each day (Event days)
  async arrangeSlotsforEventDays(e, serviceId) {
    const firedValue = e.target.value;
    const selectedDay = enums.COMBINED_DAYS[firedValue]?.en;
    const allSlots = getNormalSlots().allSlots;
    const thisEvent = this.state.eventsData.find(
      (event) => event.id == this.state.slotsFor
    );
    const openAt = thisEvent.openTime;
    const closeAt = thisEvent.closeTime;
    let slotsToRender;
    // let slotsData = [];

    const openTime = allSlots.indexOf(openAt);
    const closeTime = allSlots.indexOf(closeAt);
    let shopTimings;

    if (openTime < closeTime) shopTimings = allSlots.slice(openTime, closeTime);
    else {
      //for after 8:00am slots
      shopTimings = allSlots.slice(openTime, allSlots.length);
      const tempArr = allSlots.slice(0, closeTime);
      shopTimings = shopTimings.concat(tempArr);
    }

    // Get saved slots during update (Endpoint)
    // if (this.props.match.params.id) {
    //   const response = await BranchesService.getSlotsByDay(
    //     this.props.match.params.id,
    //     serviceId,
    //     firedValue
    //   );
    //   if (response?.length > 0 && response[0]?.laneCapacity.length > 0) {
    //     let normalSlots = response[0].laneCapacity[0].slots.filter(({ time }) => shopTimings.includes(time));
    //     normalSlots.forEach(({ specialSlots }, i) => {
    //       let specialSlot = specialSlots.filter((sp) => sp.ocassionId == this.state.slotsFor);
    //       slotsData.push({ time: normalSlots[i].time, numberOfSlots: specialSlot[0]?.numberOfSlots || 0 });
    //     });
    //   }
    // }

    this.setState({
      slotsForDay: {
        en: selectedDay,
        ar: enums.COMBINED_DAYS[firedValue]?.ar,
      },
    });

    // Check if slots are saved by user on state locally
    let savedSlots;
    savedSlots = this.state.keepAllEventsTimeSlots.find(
      (s) => s.service == serviceId
    )?.events;
    savedSlots = savedSlots?.find((e) => e.event == this.state.slotsFor)?.slots;
    savedSlots = savedSlots?.find((s) => s.day == firedValue);
    savedSlots = savedSlots?.timeSlots?.filter(({ time }) =>
      shopTimings.includes(time)
    );
    savedSlots =
      savedSlots &&
      shopTimings.map((st, i) => {
        const _slot = savedSlots.find((ss) => ss.time === st);
        if (_slot) return _slot;
        return {
          time: st,
          numberOfSlots: 0,
        };
      });

    if (savedSlots) {
      slotsToRender = this.slotsPopulator(savedSlots);
    }
    // else if (slotsData.length) {
    //   slotsToRender = this.slotsPopulator(slotsData);
    // }
    else {
      // Else show default slots as per shop timings
      slotsToRender = shopTimings.map((time) => {
        return {
          time: time,
          numberOfSlots: 0,
        };
      });
    }

    // To show some extra details on modal
    this.setState({
      timeSlotsData: {
        service: serviceId,
        days: firedValue,
        slots: slotsToRender,
      },
    });

    // Add/Delete button to control slots
    this.manageSlotsControls(shopTimings);
  }

  // Slots poulator on modal
  slotsPopulator(data) {
    let slotsToRender = data.map(({ time, numberOfSlots }) => {
      return {
        time: time,
        numberOfSlots: Number(numberOfSlots),
      };
    });

    return slotsToRender;
  }

  // Add/Delete button control
  manageSlotsControls(times) {
    this.setState({ timeSlotCount: times.length });
  }

  // Days name to show in dropdown (Filtering out off days and holidays)
  manageDaysDisplay(options) {
    let daysToMap =
      this.state.slotsFor == "workingDays"
        ? combinedDays
        : this.state.daysRangeToShow;
    let days = daysToMap.map((day, i) => {
      const values = this.watchFormValues;
      const titleCaseDay = day.charAt(0).toUpperCase() + day.slice(1);
      const closeDay = values["is" + titleCaseDay + "Close"];
      const option = options ? (
        <option value={day} key={i}>
          {enums.COMBINED_DAYS[day][this.lang === "en" ? "en" : "ar"]}
        </option>
      ) : (
        enums.COMBINED_DAYS[day][
          this.lang === "en" ? "en" : "ar"
        ]?.toLowerCase()
      );

      if (closeDay != undefined && !closeDay) {
        return option;
      }
    });

    return days;
  }

  // To show event date range on modal
  displayDateRange() {
    let event = this.state.eventsData.find(
      (ev) => ev.id == this.state.slotsFor
    );
    if (event && event.id != "workingDays") {
      let startDate = getFormatedDate(event.startDate, "MMMM Do, YYYY");
      let endDate = getFormatedDate(event.endDate, "MMMM Do, YYYY");
      return `${startDate} - ${endDate}`;
    }
  }

  // To show number of open slots on modal
  displayNumOfOpenedSlots() {
    return (
      " " +
      this.state.timeSlotsData.slots?.reduce(
        (p, c) => Number(p) + Number(c.numberOfSlots),
        0
      )
    );
  }

  // Close modal
  handleModalClose() {
    this.setState({ timeSlotsModal: false, hasMarkedAllDays: false });
  }

  // Add timeslot button funtionality
  // createNewTimeSlot() {
  //   const allSlots = getNormalSlots().allSlots;
  //   const lastSlot = this.state.timeSlotsData.slots.slice(-1)[0].time;
  //   this.addTimeSlot(allSlots[allSlots.indexOf(lastSlot) + 1]);

  //   let slotsBody = document.getElementById("slots-body");
  //   slotsBody.scrollTop = slotsBody.scrollHeight;
  // }

  // addTimeSlot(newTime) {
  //   const { service, slots } = this.state.timeSlotsData;
  //   const firstSlot = slots[0].time.toLowerCase();

  //   if (newTime != firstSlot) {
  //     const updatedSlots = {
  //       service: service,
  //       slots: [...slots, { time: newTime, numberOfSlots: 0 }],
  //     };
  //     this.setState({ timeSlotsData: updatedSlots });
  //   }
  // }

  // Delete timeslot button funtionality
  // deleteLastTimeSlot() {
  //   const { service, slots } = this.state.timeSlotsData;
  //   if (slots.length > 1) {
  //     this.setState({
  //       timeSlotsData: {
  //         service: service,
  //         slots: slots.filter((s, i) => i !== slots.length - 1),
  //       },
  //     });
  //   }

  //   let slotsBody = document.getElementById("slots-body");
  //   slotsBody.scrollTop = slotsBody.scrollHeight;
  // }

  // Save time slots button (for normal days)
  saveTimeSlots(e, serviceId) {
    if (this.state.slotsFor == "workingDays") {
      // this.state.hasMarkedAllDays
      // Scrap modal form data
      const formData = Object.fromEntries(new FormData(e.target).entries());
      let selectedDay;
      let createArrayOfObjects = [];

      Object.keys(formData).forEach((key) => {
        if (key != "event") {
          if (key != "days") {
            createArrayOfObjects.push({
              time: key,
              numberOfSlots: formData[key],
            });
          } else {
            selectedDay = formData[key];
          }
        }
      });

      // Finding selected service and saving data locally on state
      const allTimeSlots = this.state.keepAllTimeSlots;
      const findServiceToUpdate = allTimeSlots.findIndex(
        (i) => i.service == serviceId
      );
      let currentSlots = allTimeSlots[findServiceToUpdate].slots;
      const findSlotsToUpdate = currentSlots.findIndex(
        (s) => s.day == selectedDay
      );

      if (!this.state.hasMarkedAllDays)
        if (findSlotsToUpdate > -1) {
          currentSlots.splice(findSlotsToUpdate, 1);
        }

      if (this.state.hasMarkedAllDays) {
        // Remove existing slots from currentSlots and new slots
        currentSlots.splice(0, currentSlots.length);
        this.manageDaysDisplay().map((day) => {
          if (day !== undefined) {
            currentSlots.push({
              day,
              timeSlots: createArrayOfObjects,
            });
          }
        });
      } else {
        currentSlots.push({
          day: selectedDay,
          timeSlots: createArrayOfObjects,
        });
      }

      allTimeSlots.splice(findServiceToUpdate, 1);
      this.setState({
        keepAllTimeSlots: [
          ...allTimeSlots,
          { service: serviceId, slots: currentSlots },
        ],
        timeSlotsData: {
          service: serviceId,
          slots: currentSlots.find((s) => s.day == selectedDay).timeSlots,
        },
      });

      // Show save success message
      const savedSlotMsg =
        this.lang === "en"
          ? "Timeslots saved for " +
            (this.state.hasMarkedAllDays
              ? "All days"
              : enums.COMBINED_DAYS[selectedDay].en + ".")
          : "تم حفظ المواعيد الزمنية لـ " +
            (this.state.hasMarkedAllDays
              ? "All days"
              : enums.COMBINED_DAYS[selectedDay].ar + ".");
      alert(savedSlotMsg);

      this.setState({ validateSlots: false });
    } else {
      this.saveTimeSlotsForEvents(e, serviceId);
    }

    e.preventDefault();
  }

  // Save time slots button (for event days)
  saveTimeSlotsForEvents(e, serviceId) {
    // Scrap form data
    const formData = Object.fromEntries(new FormData(e.target).entries());
    let selectedDay;
    let evenId;
    let createArrayOfObjects = [];

    Object.keys(formData).forEach((key) => {
      if (key != "event") {
        if (key != "days") {
          createArrayOfObjects.push({
            time: key,
            numberOfSlots: +formData[key],
          });
        } else {
          selectedDay = formData[key];
        }
      } else {
        evenId = formData[key];
      }
    });

    const allTimeSlots = this.state.keepAllEventsTimeSlots;
    const serviceIndex = allTimeSlots.findIndex((i) => i.service == serviceId);
    const eventIndex = allTimeSlots[serviceIndex].events.findIndex(
      (e) => e.event == evenId
    );
    let currentSlots = [];
    let currentEvents = allTimeSlots[serviceIndex].events;

    if (eventIndex > -1) {
      currentSlots = allTimeSlots[serviceIndex].events[eventIndex].slots;
      const slotsIndex = currentSlots.findIndex((s) => s.day == selectedDay);
      if (slotsIndex > -1) {
        currentSlots.splice(slotsIndex, 1);
      }
      currentEvents.splice(eventIndex, 1);
    }
    if (this.state.hasMarkedAllDays) {
      // Remove existing slots from currentSlots and new slots
      currentSlots.splice(0, currentSlots.length);
      this.manageDaysDisplay().map((day) => {
        if (day !== undefined) {
          currentSlots.push({
            day,
            timeSlots: createArrayOfObjects,
          });
        }
      });
    } else {
      currentSlots.push({ day: selectedDay, timeSlots: createArrayOfObjects });
    }
    currentEvents.push({ event: evenId, slots: currentSlots });
    const eventsToPush = currentEvents.filter((e) => e.event !== "");

    allTimeSlots.splice(serviceIndex, 1);

    this.setState({
      keepAllEventsTimeSlots: [
        ...allTimeSlots,
        {
          service: serviceId,
          events: eventsToPush,
        },
      ],
      timeSlotsData: {
        service: serviceId,
        slots: currentSlots.find((s) => s.day == selectedDay).timeSlots,
      },
    });

    // Show success message
    const eventName = this.state.eventsData.find(
      ({ id }) => id == this.state.slotsFor
    ).name;
    const savedSlotMsg =
      this.lang === "en"
        ? "Timeslots saved for  " +
          eventName +
          (this.state.hasMarkedAllDays
            ? " All days"
            : enums.COMBINED_DAYS[selectedDay].en + ".") +
          "'s."
        : "تم حفظ المواعيد الزمنية لـ " +
          (this.state.hasMarkedAllDays
            ? " All days"
            : enums.COMBINED_DAYS[selectedDay].ar + ".");
    alert(savedSlotMsg);
  }

  // Panel 3 (Event and Ocassions)
  // Add event (Just appending row here having empty data)
  addEvent() {
    const holidays = this.state.eventDays;
    this.setState({
      eventDays: [
        ...holidays,
        {
          id: Date.now(),
          name: "",
          startDate: "",
          endDate: "",
          openTime: "",
          closeTime: "6:00pm",
          isHoliday: false,
          isActive: true,
        },
      ],
      holidayFormShow: true,
    });
  }

  // Save events
  saveEvents() {
    let events = [];
    let errorOccur = false;
    let validationsChecked = false;

    // Scraping form data
    const formData = Object.fromEntries(new FormData(this.eventsForm.current));
    Object.keys(formData).forEach((key, i) => {
      let title = key.split("|")[0];
      let id = key.split("|")[1];

      if (title == "name") {
        let name = formData["name|" + id];
        let startDate = formData["startDate|" + id];
        let endDate = formData["endDate|" + id];
        let openTime = formData["openTime|" + id];
        let closeTime = formData["closeTime|" + id];
        let isHoliday = formData["isHoliday|" + id] ? true : false;

        // Checking if all required fields are filled
        if (name && startDate && endDate) {
          const currentEvent = {
            id,
            name,
            startDate,
            endDate,
            openTime,
            closeTime,
            isHoliday,
            isActive: true,
          };

          // Push only when all the validations are passed through the functuon enclose in if() statement
          if (this.validateEventDates(currentEvent, events)) {
            events.push(currentEvent);
          } else {
            errorOccur = true;
          }
        } else {
          // Else show error
          this.setState({
            eventsErrorMsg: {
              en: "You've missed filling all the fields. Please double check!",
              ar: "لقد فاتك ملء جميع الحقول. من فضلك افحصه مجددا",
            },
          });

          errorOccur = true;
        }
      }

      if (Object.keys(formData).length - 1 == i) {
        validationsChecked = true;
      }
    });

    // Checking if already existing slots are deleted
    if (this.props.match.params.id && this.state.eventsDataCopy.length) {
      const deletedEvents = this.state.eventDays.filter(
        (day) => typeof day.id !== "number" && day.isActive === false
      );
      events = events.concat(deletedEvents);
    }

    events.unshift(this.defaultSelect);
    this.setState({ eventsData: events });

    if (!errorOccur && validationsChecked) {
      let showSaveMsg =
        this.lang === "en" ? "Events saved!" : "تم حفظ الأحداث!";
      alert(showSaveMsg);
    }
  }

  // Check if all the event have unique dates
  validateEventDates(currentEvent, events) {
    let isValid = false;
    this.setState({ eventsErrorMsg: "" });

    isValid = this.isEventDatesValid(
      currentEvent.startDate,
      currentEvent.endDate
    );

    if (!isValid) {
      this.setState({
        eventsErrorMsg: {
          en: `${currentEvent.name} end date should be same or future date than its starting date`,
          ar: `${currentEvent.name} يجب أن يكون تاريخ الانتهاء هو نفس تاريخ بدايته أو في المستقبل`,
        },
      });
      return;
    }

    this.setState({ eventsErrorMsg: "" });
    isValid = true;

    if (events.length) {
      for (const evn of events) {
        const isEventStartDateAndCurrentEndDateAreSame = this.isDatesAreSame(
          evn.startDate,
          currentEvent.endDate
        );

        const isEventEndDateAndCurrentStartDateAreSame = this.isDatesAreSame(
          evn.endDate,
          currentEvent.startDate
        );

        const isStartDateAreSame = this.isDatesAreSame(
          evn.startDate,
          currentEvent.startDate
        );

        const isEndDateAreSame = this.isDatesAreSame(
          evn.endDate,
          currentEvent.endDate
        );

        const isStartDateBetween = moment(currentEvent.startDate).isBetween(
          evn.startDate,
          evn.endDate,
          "days",
          "[]"
        );

        const isEndDateBetween = moment(currentEvent.endDate).isBetween(
          evn.startDate,
          evn.endDate,
          "days",
          "[]"
        );

        const isEventStartDateBetweenCurrentRange = moment(
          evn.startDate
        ).isBetween(currentEvent.startDate, currentEvent.endDate, "days", "[]");

        const isEventEndDateBetweenCurrentRange = moment(evn.endDate).isBetween(
          currentEvent.startDate,
          currentEvent.endDate,
          "days",
          "[]"
        );
        if (
          isStartDateBetween ||
          isEndDateBetween ||
          isStartDateAreSame ||
          isEndDateAreSame ||
          isEventStartDateAndCurrentEndDateAreSame ||
          isEventEndDateAndCurrentStartDateAreSame ||
          isEventStartDateBetweenCurrentRange ||
          isEventEndDateBetweenCurrentRange
        ) {
          isValid = false;

          this.setState({
            eventsErrorMsg: {
              en: `${currentEvent.name} dates already exists in another event (${evn.name})`,
              ar: `${currentEvent.name} التواريخ موجودة بالفعل في حدث آخر (${evn.name})`,
            },
          });

          break;
        }
      }
    }
    return isValid;
  }

  // Sub-function for event dates validation
  isEventDatesValid(startDate, endDate) {
    if (this.isDatesAreSame(startDate, endDate)) {
      return true;
    }
    var start = moment(startDate);
    var end = moment(endDate);
    return start.isBefore(end);
  }

  // Sub-function for event dates validation
  isDatesAreSame(startDate, endDate) {
    return moment(startDate).isSame(endDate);
  }

  // Delete event row and turn isActive false for deleted event
  deleteEvent(id) {
    const events = this.state.eventDays;
    const activeStatus = [];
    events.forEach((h) => {
      if (h.id == id) {
        h.isActive = false;
      }
      activeStatus.push(h.isActive);
    });

    let updatedEvents = this.state.eventsData;
    updatedEvents.map((e) => {
      if (e.id == id) {
        e.isActive = false;
      }
    });

    let eventsTimeSlots = this.state.keepAllEventsTimeSlots;
    eventsTimeSlots.forEach((s) => {
      s.events = s.events.filter((e) => e.event != id);
    });

    this.setState({
      eventDays: events,
      holidayFormShow: activeStatus.indexOf(true) > -1 ? true : false,
      eventsData: updatedEvents,
      keepAllEventsTimeSlots: eventsTimeSlots,
    });
  }

  // Disabling open and close time in events on declaring that event as holiday
  handleOpenAndCloseTime(e) {
    const openTime = document.getElementById(
      "openTime" + "|" + e.target.name.split("|")[1]
    );
    const closeTime = document.getElementById(
      "closeTime" + "|" + e.target.name.split("|")[1]
    );

    if (e.target.checked) {
      openTime.disabled = true;
      closeTime.disabled = true;
    } else {
      openTime.disabled = false;
      closeTime.disabled = false;
    }
  }

  handleMarkAllDays(e) {
    this.setState({ hasMarkedAllDays: e.target.checked });
  }

  // Validate slots and services
  async validateServicesAndSlots() {
    this.setState({ submitted: true });

    let keys = await this.formik.validateForm();
    if (!isEmpty(keys)) {
      const selector = Object.entries(keys)[0][0];
      if (selector) {
        let element = document.getElementsByName(selector)[0];
        if (element) {
          element.scrollIntoView({
            behavior: "auto",
            block: "center",
            inline: "center",
          });
        }
      }
    }

    // Check if not any service is selected
    if (!this.state.trackSelectedServices.length) {
      this.setState({
        // servicesToOfferExpanded: true,
        validateServices: true,
        servicesExpandedError: true,
      });
    } else {
      this.setState({
        validateServices: false,
        servicesExpandedError: false,
      });
    }

    // Check if all services doesn't have timeslots in normal days
    this.state.keepAllTimeSlots.forEach((offering) => {
      if (!offering.slots.length)
        this.setState({
          validateSlots: true,
          servicesExpandedError: true,
        });
    });
  }

  // Form submission
  async handleSubmit(values, { setSubmitting }) {
    values.shopTimings = Payloads.compileShopTiming(values, week);
    values.ocassions = Payloads.compileOccasions(
      this.state.eventsData,
      this.state.eventsDataCopy,
      this.props.match.params.id
    );
    values.offeredServices = await Payloads.compileOfferedServices(
      values.ocassions,
      this.state.keepAllTimeSlots,
      this.state.keepAllEventsTimeSlots
    );

    try {
      let response;
      if (values.offeredServices.length && !this.state.validateSlots) {
        setSubmitting(true);
        this.setState({ loading: true });

        if (!this.props.match.params.id) {
          values.associatedOffers = this.state.associatedOffersSelected;
          response = await BranchesService.addBranch(values);
        } else {
          values.associatedOffers = this.state.associatedOffersSelected;

          if (values?.isRecommended === false && values?.isAvailable === true) {
            values.isAvailable = false;
          }

          response = await BranchesService.updateBranch(
            values,
            this.props.match.params.id
          );
        }

        NotificationManager.success(
          this.props.intl.formatMessage({
            id: "global.operationSuccessful",
            defaultMessage: "Operation Successful",
          }),
          this.props.intl.formatMessage({
            id: "global.success",
            defaultMessage: "Success",
          })
        );

        this.props.navigate("/branches");
      }
    } catch (e) {
      const error = { e }.e.response.data.errors[0];
      if (error) {
        const state = {
          basicDetailsExpanded: true,
          basicDetailError: true,
          InvoiceDetailsError: true,
          InvoiceDetailsExpanded: true,
        };

        if (error === "error.inspirageIdAlreadyExists")
          state.errInspirageId = true;
        else if (error === "error.branchCodeAlreadyExists")
          state.errBranchCode = true;

        return this.setState(state);
      }

      NotificationManager.error(
        this.props.intl.formatMessage({
          id: get(e, "response.data.error", "error.unexpectedError"),
          defaultMessage: "An unexpected error has occurred",
        }),
        this.props.intl.formatMessage({
          id: "global.error",
          defaultMessage: "Error",
        })
      );
    } finally {
      setSubmitting(false);
      this.setState({ loading: false });
    }
  }

  async handleSaveOffersAgainstService(isChecked, offer, serviceId) {
    const { associatedOffersSelected } = this.state;
    if (isChecked) {
      this.setState({
        associatedOffersSelected: [
          ...associatedOffersSelected,
          {
            offer: offer.offerId,
            service: serviceId,
          },
        ],
      });
    } else {
      this.setState({
        associatedOffersSelected: this.state.associatedOffersSelected.filter(
          (associatedOffer) =>
            associatedOffer.offer !== offer._id ||
            associatedOffer.service !== serviceId
        ),
      });
    }
  }

  handleCheckedValue(offer, serviceId) {
    const { associatedOffersSelected } = this.state;
    const associatedOffer = associatedOffersSelected.find(
      (associatedOffer) =>
        associatedOffer.service === serviceId &&
        associatedOffer.offer === offer.offerId
    );
    return !!associatedOffer; // Return true if an associated offer is found, otherwise false
  }

  _renderBody(formikProps) {
    const { isSubmitting, values } = formikProps;
    this.watchFormValues = values;

    return (
      <Layout noLayout={this.props.noLayout} loading={this.state.loading}>
        <HeaderTitle id="branches.branches" defaultMessage="Branches" />

        <div className="dashboard-table-container card-layout">
          <div className="panel-primary">
            <header className="panel-title">
              {this.props.match.params.id && (
                <FormattedMessage
                  id="branches.updateBranch"
                  defaultMessage="Update Branch"
                />
              )}
              {!this.props.match.params.id && (
                <FormattedMessage
                  id="branches.addBranch"
                  defaultMessage="Add Branch"
                />
              )}
            </header>

            <Form>
              <div className="dashboard-row">
                <Accordion>
                  <PrimaryDetails
                    formikProps={formikProps}
                    errInspirageId={this.state.errInspirageId}
                    errBranchCode={this.state.errBranchCode}
                    basicDetailError={this.state.basicDetailError}
                  />

                  <BranchTimings formikProps={formikProps} />

                  <div className="holidays shop-days-panels">
                    <Accordion.Item eventKey="2">
                      <Accordion.Header
                        style={{ backgroundColor: "#555" }}
                        onClick={() =>
                          this.setState({
                            holidaysExpanded: !this.state.holidaysExpanded,
                          })
                        }
                      >
                        <FormattedMessage
                          id="branch.holidays"
                          defaultMessage="Events and Occasions"
                        />
                        <span
                          className={classNames("glyphicon", "pull-right", {
                            "glyphicon-plus": !this.state.holidaysExpanded,
                            "glyphicon-minus": this.state.holidaysExpanded,
                          })}
                          aria-hidden="true"
                        />
                      </Accordion.Header>

                      <Accordion.Body>
                        <div className="add-holiday-btn">
                          <a
                            onClick={() => {
                              this.addEvent();
                            }}
                          >
                            <FormattedMessage
                              id="branch.addHoliday"
                              defaultMessage="ADD EVENT"
                            />
                          </a>
                        </div>

                        <form ref={this.eventsForm}>
                          <table
                            className="table"
                            style={{
                              display: this.state.holidayFormShow
                                ? "table"
                                : "none",
                            }}
                          >
                            <thead className="thead-dark">
                              <tr>
                                <th scope="col">
                                  <FormattedMessage
                                    id="branch.occasion"
                                    defaultMessage="OCCASION"
                                  />
                                </th>
                                <th scope="col">
                                  <FormattedMessage
                                    id="branch.startDate"
                                    defaultMessage="START DATE"
                                  />
                                </th>
                                <th scope="col">
                                  <FormattedMessage
                                    id="branch.endDate"
                                    defaultMessage="END DATE"
                                  />
                                </th>
                                <th scope="col">
                                  <FormattedMessage
                                    id="branch.openingTime"
                                    defaultMessage="OPENING TIME"
                                  />
                                </th>
                                <th scope="col">
                                  <FormattedMessage
                                    id="branch.closingTime"
                                    defaultMessage="CLOSING TIME"
                                  />
                                </th>
                                <th scope="col">
                                  <FormattedMessage
                                    id="branch.isHoliday"
                                    defaultMessage="HOLIDAY"
                                  />
                                </th>
                                <th scope="col">
                                  <FormattedMessage
                                    id="branch.deleteHoliday"
                                    defaultMessage="DELETE"
                                  />
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.eventDays?.map(
                                ({
                                  id,
                                  name,
                                  startDate,
                                  endDate,
                                  openTime,
                                  closeTime,
                                  isHoliday,
                                  isActive,
                                }) => {
                                  if (isActive) {
                                    return (
                                      <tr key={id}>
                                        <td scope="row" className="occasion">
                                          <input
                                            type="text"
                                            name={"name" + "|" + id}
                                            className="form-control"
                                            placeholder={
                                              this.lang === "en"
                                                ? "Occasion"
                                                : "مناسبات"
                                            }
                                            defaultValue={name}
                                            required={true}
                                          />
                                        </td>
                                        <td className="date-pick">
                                          <input
                                            type="date"
                                            name={"startDate" + "|" + id}
                                            min={moment(new Date())
                                              .locale("en")
                                              .format("YYYY-MM-DD")}
                                            className="form-control"
                                            defaultValue={startDate}
                                            required={true}
                                          />
                                        </td>
                                        <td className="date-pick">
                                          <input
                                            type="date"
                                            name={"endDate" + "|" + id}
                                            min={moment(new Date())
                                              .locale("en")
                                              .format("YYYY-MM-DD")}
                                            className="form-control"
                                            defaultValue={endDate}
                                            required={true}
                                          />
                                        </td>
                                        <td className="special-times">
                                          <FieldRow
                                            type="select"
                                            name={"openTime" + "|" + id}
                                            data={this.displayShopTime}
                                            showRequiredFlag={true}
                                            formikProps={formikProps}
                                            defaultValue={openTime}
                                            disabled={isHoliday ? true : false}
                                          />
                                        </td>
                                        <td className="special-times">
                                          <FieldRow
                                            type="select"
                                            name={"closeTime" + "|" + id}
                                            data={this.displayShopTime}
                                            showRequiredFlag={true}
                                            formikProps={formikProps}
                                            defaultValue={closeTime}
                                            disabled={isHoliday ? true : false}
                                          />
                                        </td>
                                        <td style={{ paddingLeft: "18px" }}>
                                          <input
                                            type="checkbox"
                                            name={"isHoliday" + "|" + id}
                                            style={{
                                              transform: "scale(2.5)",
                                            }}
                                            onChange={(e) =>
                                              this.handleOpenAndCloseTime(e)
                                            }
                                            defaultChecked={
                                              isHoliday ? true : false
                                            }
                                          />
                                        </td>
                                        <td style={{ paddingLeft: "8px" }}>
                                          <i
                                            className="fa fa-times"
                                            onClick={() => this.deleteEvent(id)}
                                          ></i>
                                        </td>
                                      </tr>
                                    );
                                  }
                                }
                              )}
                            </tbody>
                          </table>

                          {this.state.holidayFormShow && (
                            <div className="save-holiday-btn">
                              <button
                                type="button"
                                onClick={() => {
                                  this.saveEvents();
                                }}
                              >
                                <FormattedMessage
                                  id="branch.saveEvents"
                                  defaultMessage="SAVE EVENTS"
                                />
                              </button>
                            </div>
                          )}
                          {this.state.eventsErrorMsg[this.lang] && (
                            <span className="text-warning">
                              {this.state.eventsErrorMsg[this.lang]}
                            </span>
                          )}
                        </form>
                      </Accordion.Body>
                    </Accordion.Item>
                  </div>

                  <Accordion.Item eventKey="3">
                    <Accordion.Header
                      style={{
                        backgroundColor: this.state.servicesExpandedError
                          ? "red"
                          : "#555",
                      }}
                      onClick={() =>
                        this.setState({
                          servicesToOfferExpanded:
                            !this.state.servicesToOfferExpanded,
                        })
                      }
                    >
                      <FormattedMessage
                        id="branch.servicesToOffer"
                        defaultMessage="Services To Offer"
                      />
                      <span
                        className={classNames("glyphicon", "pull-right", {
                          "glyphicon-plus": !this.state.servicesToOfferExpanded,
                          "glyphicon-minus": this.state.servicesToOfferExpanded,
                        })}
                        aria-hidden="true"
                      />
                    </Accordion.Header>

                    <Accordion.Body>
                      <div className="offeredServices">
                        <div ref={this.checkboxRef}>
                          {this.state.servicesList?.map((category, index) => {
                            return (
                              <div key={index}>
                                <p>
                                  <FormattedMessage
                                    id={"branch" + category._id}
                                    defaultMessage={category._id.toUpperCase()}
                                  />
                                </p>
                                {category.data.map((service) => {
                                  return (
                                    <label
                                      key={service.id}
                                      htmlFor={service.id}
                                    >
                                      <input
                                        type="checkbox"
                                        name="offeredServices"
                                        id={service.id}
                                        value={service.id}
                                        onChange={(e) => {
                                          this.showSelectedServices(e, service);
                                        }}
                                      />
                                      <p>
                                        {this.lang === "en"
                                          ? service.nameEn
                                          : service.nameAr}
                                      </p>
                                    </label>
                                  );
                                })}
                              </div>
                            );
                          })}
                        </div>

                        {this.state.validateServices && (
                          <div className="text-warning">
                            <FormattedMessage
                              id="branch.atleastOneService"
                              defaultMessage="Please select at least one service"
                            />
                          </div>
                        )}

                        {this.state.trackSelectedServices.length ? (
                          <h4>
                            <FormattedMessage
                              id="branch.setServiceTimeSlots"
                              defaultMessage="Click on a service to set its time slots"
                            />
                          </h4>
                        ) : null}
                        <div className="selected-services branch-services">
                          {orderBy(
                            this.state.trackSelectedServices,
                            ["en"],
                            ["asc"]
                          )?.map((s, i) => {
                            return (
                              <div key={i}>
                                <p onClick={() => this.handleModalShow(s)}>
                                  {s[this.lang]}
                                </p>
                                {this.state.offers?.length ? (
                                  this.state.offers.map((offer, index) => {
                                    return (
                                      <label key={index}>
                                        <div className="selected-offers">
                                          <Field
                                            type="checkbox"
                                            name="associatedOffers"
                                            checked={this.handleCheckedValue(
                                              offer,
                                              s.id
                                            )}
                                            onChange={(e) =>
                                              this.handleSaveOffersAgainstService(
                                                e.target.checked,
                                                offer,
                                                s.id
                                              )
                                            }
                                          />
                                          <span>
                                            {this.lang === "en"
                                              ? offer.name.value_en
                                              : offer.name.value_ar}
                                          </span>
                                        </div>
                                      </label>
                                    );
                                  })
                                ) : (
                                  <FormattedMessage
                                    id="branch.noOfferAvailable"
                                    defaultMessage="No Offer(s) Available"
                                  />
                                )}
                              </div>
                            );
                          })}
                        </div>

                        {this.state.validateSlots && (
                          <div className="text-warning">
                            <FormattedMessage
                              id="branch.selectSlotsForService"
                              defaultMessage="Looks like you have missed saving timeslots for all selected services. 
                              Please set timeslots for each service to continue."
                            />
                          </div>
                        )}
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>

                  <InvoiceDetails
                    formikProps={formikProps}
                    InvoiceDetailsError={this.state.InvoiceDetailsError}
                  />
                </Accordion>

                <br />
                {this?.state?.data?.qrCode && (
                  <div>
                    <img src={this?.state?.data?.qrCode} height="100px" />
                    <br />
                  </div>
                )}

                <div className="isActiveCheck">
                  <div className="col-md-2 col-xs-12">
                    <label className={classNames("form-label caps block")}>
                      <FormattedMessage
                        id="branch.isActive"
                        defaultMessage="Active"
                      />
                    </label>
                  </div>
                  <FieldRow
                    name="active"
                    type="checkbox"
                    formikProps={formikProps}
                    checked={true}
                  />
                </div>

                <div className="branches-checkbox-wrapper">
                  {/* <div className="isActiveCheck">
                      <div className="">
                        <label className={classNames("form-label caps block")}>
                          <FormattedMessage
                            id="branch.activateEshop"
                            defaultMessage="Activate for E-shop"
                          />
                        </label>
                      </div>
                      <FieldRow
                        name="activateForEshop"
                        type="checkbox"
                        formikProps={formikProps}
                        checked={true}
                      />
                    </div> */}
                  <div className="isActiveCheck">
                    <div className="">
                      <label className={classNames("form-label caps block")}>
                        <FormattedMessage
                          id="branch.activateAddOn"
                          defaultMessage="Activate for Add-on flow"
                        />
                      </label>
                    </div>
                    <FieldRow
                      name="activateForAddOnFlow"
                      type="checkbox"
                      formikProps={formikProps}
                      checked={true}
                    />
                  </div>
                  <div className="isActiveCheck">
                    <div className="">
                      <label className={classNames("form-label caps block")}>
                        <FormattedMessage
                          id="branch.activateUpsell"
                          defaultMessage="Activate for Upsell flow"
                        />
                      </label>
                    </div>
                    <FieldRow
                      name="activateForUpsellFlow"
                      type="checkbox"
                      formikProps={formikProps}
                      checked={true}
                    />
                  </div>
                  <div className="isActiveCheck">
                    <div className="">
                      <label className={classNames("form-label caps block")}>
                        <FormattedMessage
                          id="branch.activateExpress"
                          defaultMessage="Activate for Express"
                        />
                      </label>
                    </div>
                    <FieldRow
                      name="activateForExpress"
                      type="checkbox"
                      formikProps={formikProps}
                      checked={true}
                    />
                  </div>
                </div>
                <div className="isActiveCheck">
                  <div className="col-md-2 col-xs-12">
                    <label className={classNames("form-label caps block")}>
                      <FormattedMessage
                        id="branch.fastCollect"
                        defaultMessage="Fast Collect"
                      />
                    </label>
                  </div>
                  <FieldRow
                    name="fastCollect"
                    type="checkbox"
                    formikProps={formikProps}
                    checked={false}
                  />
                </div>
                <div className="isActiveCheck">
                  <div className="col-md-2 col-xs-12">
                    <label className={classNames("form-label caps block")}>
                      <FormattedMessage
                        id="branch.isRecommended"
                        defaultMessage="Is Recommended"
                      />
                    </label>
                  </div>
                  <FieldRow
                    name="isRecommended"
                    type="checkbox"
                    formikProps={formikProps}
                    checked={false}
                  />
                </div>

                {values?.isRecommended ? (
                  <div className="isActiveCheck">
                    <div className="col-md-2 col-xs-12">
                      <label className={classNames("form-label caps block")}>
                        <FormattedMessage
                          id="branch.isAvailable"
                          defaultMessage="Is Available"
                        />
                      </label>
                    </div>
                    <FieldRow
                      name="isAvailable"
                      type="checkbox"
                      formikProps={formikProps}
                      checked={false}
                    />
                  </div>
                ) : null}
              </div>
              <div className="dashboard-row -nobottom">
                <CheckAccess
                  activityId={
                    ActivitiesEnum[
                      this.props.match.params.id
                        ? "branch__update_branch"
                        : "branch__add_branch"
                    ]
                  }
                >
                  {this.props.authContext.authorized && (
                    <input
                      type="submit"
                      className="btn btn-primary"
                      value={this.props.intl.formatMessage({
                        id: "global.submit",
                        defaultMessage: "Submit",
                      })}
                      onClick={() => this.validateServicesAndSlots()}
                      disabled={isSubmitting}
                    />
                  )}
                </CheckAccess>
                <Button
                  variant="link"
                  onClick={() => this.props.navigate("/branches")}
                  disabled={isSubmitting}
                >
                  {this.props.intl.formatMessage({
                    id: "global.cancel",
                    defaultMessage: "Cancel",
                  })}
                </Button>
              </div>
            </Form>
          </div>
        </div>

        <Modal
          className="set-timeslots"
          show={this.state.timeSlotsModal}
          onHide={() => this.handleModalClose()}
          backdrop="static"
          size="lg"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <FormattedMessage
                id="branch.setTimeSlotsFor"
                defaultMessage="Set time slots for"
              />
              <b>
                {" "}
                {this.lang === "en"
                  ? this.state.serviceData.name?.en
                  : this.state.serviceData.name?.ar}
              </b>
            </Modal.Title>
          </Modal.Header>
          <form
            onSubmit={(e) => this.saveTimeSlots(e, this.state.serviceData?.id)}
          >
            <Modal.Body id="slots-body">
              <div className="slots-predata">
                <div className="slot-days">
                  <label>
                    <FormattedMessage
                      id="branch.slotsFor"
                      defaultMessage="Timeslots For"
                    />
                    <select
                      name="event"
                      onChange={(e) =>
                        this.settingSlotsForChange(e, this.state.serviceData.id)
                      }
                    >
                      {this.state.eventsData.map(
                        ({ id, name, isHoliday, isActive }, i) => {
                          if (i > 0 && !isHoliday && isActive) {
                            return (
                              <option value={id} key={i}>
                                {name}
                              </option>
                            );
                          } else if (i == 0) {
                            return (
                              <option value={id} key={i}>
                                {name}
                              </option>
                            );
                          }
                        }
                      )}
                    </select>
                  </label>
                </div>
                <div className="slot-days">
                  <label>
                    <FormattedMessage id="branch.on" defaultMessage="On" />
                    <select
                      name="days"
                      ref={this.daysRef}
                      defaultValue={this.state.timeSlotsData.days}
                      onChange={(e) =>
                        this.arrangeSlotsforDay(e, this.state.serviceData.id)
                      }
                      required
                    >
                      <option value="">
                        {this.lang === "en"
                          ? "- Select Day -"
                          : "- اختر اليوم -"}
                      </option>
                      {this.manageDaysDisplay("options")}
                    </select>
                  </label>
                </div>
              </div>
              <span className="date-range">{this.displayDateRange()}</span>
              <hr />
              <p>
                {this.state.slotsForDay
                  ? this.lang === "en"
                    ? "No. of opened slots for " +
                      this.state.slotsForDay.en +
                      ": "
                    : " عدد الفتحات المفتوحة لـ" +
                      this.state.slotsForDay.ar +
                      ": "
                  : null}
                <span>
                  {this.state.slotsForDay ? this.displayNumOfOpenedSlots() : ""}
                </span>
              </p>
              {this.state.timeSlotsData.slots?.map(
                ({ time, numberOfSlots }, i) => {
                  return (
                    <label key={i + this.state.slotsForDay.en}>
                      <span>{time}</span>
                      <input
                        type="number"
                        name={time}
                        placeholder={
                          this.lang === "en" ? "No. of slots" : "عدد الفتحات"
                        }
                        defaultValue={numberOfSlots}
                        min="0"
                      />
                    </label>
                  );
                }
              )}
            </Modal.Body>
            <Modal.Footer>
              {/* <div className="controls">
                <div
                  style={{
                    display:
                      this.state.timeSlotCount > 0 &&
                      this.state.timeSlotsData.slots?.length <
                        this.state.timeSlotCount
                        ? "block"
                        : "none",
                  }}
                  onClick={() => this.createNewTimeSlot()}
                >
                  <FormattedMessage
                    id="branches.addSlot"
                    defaultMessage="Add slot"
                  />{" "}
                  <i className="fa fa-plus"></i>
                </div>

                <div
                  style={{
                    display:
                      this.state.timeSlotsData.slots?.length > 1
                        ? "block"
                        : "none",
                  }}
                  onClick={() => this.deleteLastTimeSlot()}
                >
                  <FormattedMessage
                    id="branches.deleteSlot"
                    defaultMessage="Delete slot"
                  />{" "}
                  <i className="fa fa-minus"></i>
                </div>
              </div> */}
              {/* <div className="mark-all-day">
                <div className="">
                  <label className={classNames("form-label caps block")}>
                    <FormattedMessage
                      id="branch.isActive"
                      defaultMessage="Is Active"
                    />
                  </label>
                </div>
                <FieldRow
                  name="hasAllSlots"
                  type="checkbox"
                  formikProps={formikProps}
                  checked={true}
                />
              </div> */}
              <div className="mark-all-day">
                <input
                  type="checkbox"
                  id="markAllDays"
                  onChange={this.handleMarkAllDays.bind(this)}
                />
                <label htmlFor="markAllDays">
                  <FormattedMessage
                    id="branches.markSameForAllDays"
                    defaultMessage=" Mark same for all open days"
                  />
                </label>
              </div>

              <div>
                {this.state.slotsForDay ? (
                  <Button type="submit" className="saveBtn">
                    {this.lang === "en"
                      ? "Save timeslots for " +
                        (this.state.hasMarkedAllDays
                          ? "All Days"
                          : this.state.slotsForDay.en)
                      : "احفظ الفترات الزمنية ل " +
                        (this.state.hasMarkedAllDays
                          ? "All Days"
                          : this.state.slotsForDay.ar)}
                  </Button>
                ) : null}
                <Button variant="link" onClick={() => this.handleModalClose()}>
                  <FormattedMessage
                    id="branches.close"
                    defaultMessage="Close"
                  />
                </Button>
              </div>
            </Modal.Footer>
          </form>
        </Modal>
      </Layout>
    );
  }

  render() {
    const { intl } = this.props;
    return (
      <Auth requireAuth={true} roles={["sadmin", "wsadmin", "spadmin"]}>
        <Formik
          innerRef={(f) => {
            this.formik = f;
          }}
          enableReinitialize
          initialValues={{
            name: this.state.data.name?.value_en || this.state.data.name || "",
            nameAr: this.state.data.name?.value_ar || "",
            address: this.state.data.address || "",
            addressArabic: this.state.data.addressArabic || "",
            addressLink: this.state.data.addressLink || "",
            phoneNumber: this.state.data.phoneNumber || "",
            inspirageId: this.state.data.inspirageId || "",
            branchCode: this.state.data.branchCode || "",
            offeredServices: false,
            associatedOffers: false,
            openingTimeMonday:
              this.state.data.shopTimings?.find((item) => item.day === "monday")
                .timings.openingTime || "9:00am",
            closingTimeMonday:
              this.state.data.shopTimings?.find((item) => item.day === "monday")
                .timings.closingTime || "6:00pm",
            isMondayClose:
              this.state.data.shopTimings?.find((item) => item.day === "monday")
                .isClosed || false,

            openingTimeTuesday:
              this.state.data.shopTimings?.find(
                (item) => item.day === "tuesday"
              ).timings.openingTime || "9:00am",
            closingTimeTuesday:
              this.state.data.shopTimings?.find(
                (item) => item.day === "tuesday"
              ).timings.closingTime || "6:00pm",
            isTuesdayClose:
              this.state.data.shopTimings?.find(
                (item) => item.day === "tuesday"
              ).isClosed || false,

            openingTimeWednesday:
              this.state.data.shopTimings?.find(
                (item) => item.day === "wednesday"
              ).timings.openingTime || "9:00am",
            closingTimeWednesday:
              this.state.data.shopTimings?.find(
                (item) => item.day === "wednesday"
              ).timings.closingTime || "6:00pm",
            isWednesdayClose:
              this.state.data.shopTimings?.find(
                (item) => item.day === "wednesday"
              ).isClosed || false,

            openingTimeThursday:
              this.state.data.shopTimings?.find(
                (item) => item.day === "thursday"
              ).timings.openingTime || "9:00am",
            closingTimeThursday:
              this.state.data.shopTimings?.find(
                (item) => item.day === "thursday"
              ).timings.closingTime || "6:00pm",
            isThursdayClose:
              this.state.data.shopTimings?.find(
                (item) => item.day === "thursday"
              ).isClosed || false,

            openingTimeFriday:
              this.state.data.shopTimings?.find((item) => item.day === "friday")
                .timings.openingTime || "9:00am",
            closingTimeFriday:
              this.state.data.shopTimings?.find((item) => item.day === "friday")
                .timings.closingTime || "6:00pm",
            isFridayClose:
              this.state.data.shopTimings?.find((item) => item.day === "friday")
                .isClosed || false,

            openingTimeSaturday:
              this.state.data.shopTimings?.find(
                (item) => item.day === "saturday"
              ).timings.openingTime || "9:00am",
            closingTimeSaturday:
              this.state.data.shopTimings?.find(
                (item) => item.day === "saturday"
              ).timings.closingTime || "6:00pm",
            isSaturdayClose:
              this.state.data.shopTimings?.find(
                (item) => item.day === "saturday"
              ).isClosed || false,

            openingTimeSunday:
              this.state.data.shopTimings?.find((item) => item.day === "sunday")
                .timings.openingTime || "9:00am",
            closingTimeSunday:
              this.state.data.shopTimings?.find((item) => item.day === "sunday")
                .timings.closingTime || "6:00pm",
            isSundayClose:
              this.state.data.shopTimings?.find((item) => item.day === "sunday")
                .isClosed || false,

            openingTimeWeekdays: "9:00am",
            closingTimeWeekdays: "8:00am",

            openingTimeWeekends: "9:00am",
            closingTimeWeekends: "8:00am",

            "openingTimeWhole week": "9:00am",
            "closingTimeWhole week": "8:00am",

            active: this.state.data.active == "Yes" ? true : false || false,
            // activateForEshop: this.state.data.activateForEshop || false,
            activateForAddOnFlow: this.state.data.activateForAddOnFlow || false,
            activateForUpsellFlow:
              this.state.data.activateForUpsellFlow || false,
            activateForExpress: this.state.data.activateForExpress || false,
            fastCollect: this.state.data.fastCollect || false,
            city: this.state.data.city || "Riyadh",
            location: this.state.data.location?.coordinates || [
              39.140933, 21.5546891,
            ],
            isRecommended: this.state.data?.isRecommended || false,
            isAvailable: this.state.data?.isAvailable || false,
            cityNameAr: this.state.data.cityNameAr || "",
            centerNameEnglish:
              this.state.data?.invoiceDetails?.centerNameEnglish || "",
            centerNameArabic:
              this.state.data?.invoiceDetails?.centerNameArabic || "",
            crNo: this.state.data?.invoiceDetails?.crNo || "",
            contactNo: this.state.data?.invoiceDetails?.contactNo || "",
            vatId: this.state.data?.invoiceDetails?.vatId || "",
          }}
          validate={(values) => {
            this.setState({ errInspirageId: false, errBranchCode: false });

            let errors = {};
            if (this.state.submitted) {
              this.setState({ submitted: false });

              if (
                !Yup.string().required().isValidSync(values.name) ||
                !Yup.string().required().isValidSync(values.nameAr) ||
                !Yup.string().required().isValidSync(values.address) ||
                !Yup.string().required().isValidSync(values.addressArabic) ||
                !Yup.string().isValidSync(values.addressLink) ||
                !Yup.string().required().isValidSync(values.phoneNumber) ||
                !Yup.string().required().isValidSync(values.inspirageId) ||
                !Yup.string().required().isValidSync(values.branchCode) ||
                !Yup.string().required().isValidSync(values.cityNameAr)
                // !Yup.string().required().isValidSync(values.city)
              ) {
                this.setState({
                  // basicDetailsExpanded: true,
                  basicDetailError: true,
                  InvoiceDetailsError: true,
                });
              } else {
                this.setState({
                  basicDetailError: false,
                  InvoiceDetailsError: false,
                });
              }
              return errors;
            }
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string()
              .hasNoArabic(
                intl.formatMessage({
                  id: "error.arabicCharactersAreNotAllowedInThisField",
                  defaultMessage:
                    "Arabic characters are not allowed in this field",
                })
              )
              .label(
                intl.formatMessage({
                  id: "branch.branchName",
                  defaultMessage: "Branch name (English)",
                })
              )
              .trim()
              .required(),
            nameAr: Yup.string()
              .hasNoEnglish(
                intl.formatMessage({
                  id: "error.englishCharactersAreNotAllowedInThisField",
                  defaultMessage:
                    "English characters are not allowed in this field",
                })
              )
              .label(
                intl.formatMessage({
                  id: "branch.nameArabic",
                  defaultMessage: "Branch name (Arabic)",
                })
              )
              .trim()
              .required(),
            address: Yup.string()
              .label(
                intl.formatMessage({
                  id: "branch.englishAddress",
                  defaultMessage: "Address (English)",
                })
              )
              .trim()
              .required(),
            addressArabic: Yup.string()
              .label(
                intl.formatMessage({
                  id: "branch.arabicName",
                  defaultMessage: "Address (Arabic)",
                })
              )
              .trim()
              .required()
              .hasNoEnglish(
                intl.formatMessage({
                  id: "error.englishCharactersAreNotAllowedInThisField",
                  defaultMessage:
                    "English characters are not allowed in this field",
                })
              ),
            addressLink: Yup.string(),
            city: Yup.string()
              .label(
                intl.formatMessage({
                  id: "branch.city",
                  defaultMessage: "City Name",
                })
              )
              .required(),
            cityNameAr: Yup.string()
              .label(
                intl.formatMessage({
                  id: "branch.cityArabicName",
                  defaultMessage: "City Name (Arabic)",
                })
              )
              .trim()
              .required()
              .hasNoEnglish(
                intl.formatMessage({
                  id: "error.englishCharactersAreNotAllowedInThisField",
                  defaultMessage:
                    "English characters are not allowed in this field",
                })
              ),
            phoneNumber: Yup.string()
              .label(
                intl.formatMessage({
                  id: "branch.phoneNumber",
                  defaultMessage: "Phone Number",
                })
              )
              .matches(
                /^[0-9+]*$/,
                <FormattedMessage
                  id="appointmentCreate.onlyNumber"
                  defaultMessage="Phone no must be only numbers"
                />
              )
              .test(
                "len",
                intl.formatMessage({
                  id: "branches.wrongPhoneFormat",
                  defaultMessage:
                    "Wrong format, It should start with + and 12 or 13 digit mobile number. Example +966551234567",
                }),
                (val) =>
                  val?.length === 12 || val?.length === 13 || val?.length === 14
              )
              .trim()
              .required(),
            inspirageId: Yup.string()
              .label(
                intl.formatMessage({
                  id: "branch.inspirageId",
                  defaultMessage: "Inspirage ID",
                })
              )
              .trim()
              .required(),
            branchCode: Yup.string()
              .label(
                intl.formatMessage({
                  id: "branch.branchCode",
                  defaultMessage: "Branch Code",
                })
              )
              .trim()
              .required(),
            centerNameEnglish: Yup.string()
              .label(
                intl.formatMessage({
                  id: "branch.centerNameEnglish",
                  defaultMessage: "Center Name English",
                })
              )
              .trim()
              .required(),
            centerNameArabic: Yup.string()
              .label(
                intl.formatMessage({
                  id: "branch.centerNameArabic",
                  defaultMessage: "Center Name Arabic",
                })
              )
              .trim()
              .required(),
            contactNo: Yup.string()
              .label(
                intl.formatMessage({
                  id: "branch.contactNo",
                  defaultMessage: "Contact Number",
                })
              )
              .trim()
              .required(),
            crNo: Yup.string()
              .label(
                intl.formatMessage({
                  id: "branch.crNo",
                  defaultMessage: "CR Number",
                })
              )
              .trim()
              .required(),
            vatId: Yup.string()
              .label(
                intl.formatMessage({
                  id: "branch.vatId",
                  defaultMessage: "VAT ID",
                })
              )
              .trim()
              .required(),
          })}
          onSubmit={this.handleSubmit.bind(this)}
        >
          {this._renderBody.bind(this)}
        </Formik>
      </Auth>
    );
  }
}

export default withAuthContext(withRouter(injectIntl(BranchesForm)));
