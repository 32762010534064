import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Form, Formik } from "formik";
import { Yup } from "../../util";
import Layout from "../shared/Layout";
import services from "../../services";
import { NotificationManager } from "react-notifications";
import _ from "lodash";
import Auth from "../shared/Auth";
import FieldRow from "../shared/FieldRow";
import withRouter from "../../util/withRouter";
import appState from "../../state/AppStateContainer";
import HeaderTitle from "../shared/HeaderTitle";
import { withAuthContext } from "../../state/AuthContext";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import BranchesService from "../../services/branchesService";
import VariantsServices from "../../services/variantsServices";
import { isJsonParsable } from "../../util/utils";
import HistoryModal from "./HistoryModal";
import CheckAccess from "../shared/CheckAccess";
import { ActivitiesEnum } from "../../staticData/Activities";
import { Button } from "react-bootstrap";

const VariantForm = ({ intl, match, history, authContext, navigate }) => {
  let formikRef = useRef(null);

  const [branches, setBranches] = useState([]);
  const [products, setProducts] = useState([]);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [rows, setRows] = useState([]);
  const [inventory, setInventory] = useState([]);
  const [branchInventoryHistory, setBranchInventoryHistory] = useState([]);

  const columnDefs = (
    formikProps,
    setIsEditing,
    setLoading,
    setBranchInventoryHistory,
    handleEditButtonClick
  ) => {
    const columns = [
      {
        field: "branchName",
        headerName: "Branch Name",
        rowDrag: true,
        width: 250,
        resizable: true,
      },
      {
        field: "oldPrice",
        headerName: "Old Price",
        width: 100,
        resizable: true,
      },
      {
        field: "price",
        headerName: "price",
        width: 100,
        resizable: true,
      },
      {
        field: "totalQuantity",
        headerName: "Total Quantity",
        width: 140,
        resizable: true,
      },
      {
        field: "remainingQuantity",
        headerName: "Remaining Quantity",
        width: 150,
        resizable: true,
      },
      {
        field: "isActive",
        headerName: "Active",
        width: 100,
        editable: true,
        cellRenderer: ({ value, data, api, columnApi }) => {
          return (
            <CheckboxRenderer
              {...{
                value,
                data,
                api,
                columnApi,
              }}
            />
          );
        },
      },
      {
        field: "editButton",
        headerName: "Edit",
        width: 100,
        editable: false,
        cellRenderer: (params) => (
          <ButtonRenderer
            onClick={() => {
              setIsEditing(true);
              handleEditButtonClick(params, formikProps);
            }}
            iconClass="fa-edit"
          />
        ),
      },
      {
        field: "viewButton",
        headerName: "View History",
        width: 100,
        editable: false,
        cellRenderer: (params) => (
          <ButtonRenderer
            onClick={async () => {
              setLoading(true);
              const history = await VariantsServices.getBranchVariantHistory(
                params.data.variantId,
                params.data.branchId
              );
              setBranchInventoryHistory(history);
              setLoading(false);
            }}
            iconClass="fa-eye"
          />
        ),
      },
    ];
    return columns;
  };

  useEffect(() => {
    const handleLanguageChange = () => {
      formikRef.current.validateForm();
    };
    const subscription = appState.emitter.addListener(
      "onLanguageChange",
      handleLanguageChange
    );

    return () => {
      subscription.remove();
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const branches = await BranchesService.getActiveBranches();
        setBranches(branches);

        const products = await services.getProducts(
          undefined,
          "all",
          undefined,
          undefined,
          undefined,
          {},
          true
        );
        setProducts(products);

        if (match.params.id) {
          const variantData = await VariantsServices.getVariant(
            match.params.id,
            { progressBar: true }
          );
          setData(variantData?.variant);

          let inventoryPayload = [];
          if (variantData?.inventory) {
            variantData.inventory.map((data) => {
              inventoryPayload.push({
                branchName:
                  appState.state.language === "en"
                    ? data?.name?.value_en
                    : data?.name?.value_ar,
                totalQuantity: data?.totalQuantity,
                remainingQuantity: data?.remainingQuantity,
                price: data?.price,
                oldPrice: data?.oldPrice,
                isActive: data?.isActive,
                branchId: data?.branchId,
                variantId: data?.variantId,
              });
            });
            setRows(inventoryPayload);
          }
        }
      } catch (e) {
        navigate("/error", {
          error: _.get(e, "response.data.error", "error.unexpectedError"),
        });
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [match.params.id, history]);

  useEffect(() => {
    if (branches?.length && rows?.length) {
      let result = branches?.filter((o1) => {
        return !rows?.some((o2) => {
          return o1?._id === o2?.branchId;
        });
      });
      setBranches(result);
    }
  }, [match.params.id, rows, data]);

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      setSubmitting(true);
      setLoading(true);

      let response;
      let payload = {
        descriptionAr: values?.descriptionAr,
        descriptionEn: values?.descriptionEn,
        image: values?.image,
        isActive: values?.isActive,
        nameAr: values?.nameAr,
        nameEn: values?.nameEn,
        product: values?.product,
        inventory,
        advanceBookingDays: values?.advanceBookingDays,
      };
      if (!match.params.id) {
        response = await VariantsServices.addVariant(payload);
      } else {
        const request = { id: match.params.id, ...payload };
        response = await VariantsServices.updateVariant(request);
      }

      NotificationManager.success(
        intl.formatMessage({
          id: "global.operationSuccessful",
          defaultMessage: "Operation Successful",
        }),
        intl.formatMessage({
          id: "global.success",
          defaultMessage: "Success",
        })
      );
      setInventory([]);

      navigate("/variants", {
        isSubmitted: true,
      });
    } catch (e) {
      NotificationManager.error(
        intl.formatMessage({
          id: _.get(e, "response.data.error", "error.unexpectedError"),
          defaultMessage: "An unexpected error has occurred",
        }),
        intl.formatMessage({
          id: "global.error",
          defaultMessage: "Error",
        })
      );
    } finally {
      setSubmitting(false);
      setLoading(false);
    }
  };
  const handleEditButtonClick = (params, formikProps) => {
    formikProps.setFieldValue("branch", params.data);
    formikProps.setFieldValue("price", params.data?.price);
    formikProps.setFieldValue("oldPrice", params.data?.oldPrice);
  };

  const handleAddVariantToBranch = async (formikProps, values) => {
    if (!values.quantity || !values.price || !values.branch) return;
    let branch = isJsonParsable(formikProps?.values?.branch);

    // filter the selected branch from branch array
    const remainingBranches = branches.filter(
      (b) => b?._id !== (branch?._id || branch?.branchId)
    );
    setBranches(remainingBranches);

    // Find the index of the item to update
    const existingBranchIndex = rows.findIndex(
      (row) => row?.branchId === (branch?._id || branch?.branchId)
    );

    if (existingBranchIndex !== -1) {
      const updatedRows = [...rows];

      updatedRows[existingBranchIndex] = {
        ...updatedRows[existingBranchIndex],
        totalQuantity:
          Number(updatedRows[existingBranchIndex].totalQuantity) +
          Number(formikProps?.values?.quantity),
        remainingQuantity:
          Number(updatedRows[existingBranchIndex].remainingQuantity) +
          Number(formikProps?.values?.quantity),
        price: Number(updatedRows[existingBranchIndex].price),
        oldPrice: Number(updatedRows[existingBranchIndex].oldPrice),
      };

      setRows(updatedRows);
    } else {
      const payload = {
        branchName:
          appState.state.language === "en"
            ? branch?.name?.value_en
            : branch?.name?.value_ar,
        totalQuantity: formikProps?.values?.quantity,
        remainingQuantity: formikProps?.values?.quantity,
        price: formikProps?.values?.price,
        oldPrice: formikProps?.values?.oldPrice,
        isActive: true,
        branchId: branch?.branchId || branch?._id,
      };
      setRows((data) => [...data, payload]);
    }
    setInventory((data) => [
      ...data,
      {
        branchName:
          appState.state.language === "en"
            ? branch?.name?.value_en
            : branch?.name?.value_ar,
        totalQuantity: formikProps?.values?.quantity,
        remainingQuantity: formikProps?.values?.quantity,
        price: formikProps?.values?.price,
        oldPrice: formikProps?.values?.oldPrice,
        isActive: true,
        branchId: branch?._id || branch?.branchId,
      },
    ]);
    resetProductFields(formikProps);
  };

  const resetProductFields = (formikProps) => {
    formikProps.setFieldValue("oldPrice", "");
    formikProps.setFieldValue("price", "");
    formikProps.setFieldValue("quantity", "");
    formikProps.setFieldValue("branch", "");
    setIsEditing(false);
  };

  const toolbarOptions = [
    ["bold", "italic", "underline", "strike"], // toggled buttons
    ["blockquote", "code-block"],

    [{ header: 1 }, { header: 2 }], // custom button values
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
    [{ direction: "rtl" }], // text direction

    [{ size: ["small", false, "large", "huge"] }], // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ font: [] }],
    [{ align: [] }],

    ["clean"], // remove formatting button
  ];

  const canPerformActivity = (activityId) => {
    if (!appState.state.token || !activityId) return false;

    if (!appState.state.token?.isSubAdmin) return true;

    return appState.state.token?.activities.indexOf(activityId) >= 0;
  };

  const sanitizeHtml = (html) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    tempDiv.querySelectorAll("br").forEach((br) => br.remove());
    const isEmpty = tempDiv.textContent.trim() === "";
    return isEmpty ? "" : tempDiv.innerHTML;
  };

  const CheckboxRenderer = ({ value, data, api, columnApi }) => {
    const [checked, setChecked] = useState(value);

    const handleChange = async () => {
      const newValue = !checked;
      setChecked(newValue);

      try {
        api.showLoadingOverlay();
        await VariantsServices.updateInventoryStatus(
          data.variantId,
          data.branchId,
          newValue
        );
        NotificationManager.success(
          intl.formatMessage({
            id: "global.operationSuccessful",
            defaultMessage: "Inventory Status Updated Successfully",
          }),
          intl.formatMessage({
            id: "global.success",
            defaultMessage: "Success",
          })
        );
        data.isActive = newValue;
        api.refreshCells({ rowNodes: [data] });
      } finally {
        api.hideOverlay();
      }
    };

    return (
      <input
        type="checkbox"
        checked={checked}
        disabled={!canPerformActivity(ActivitiesEnum.variant__update_variant)}
        onChange={handleChange}
      />
    );
  };

  const ButtonRenderer = ({
    onClick,
    iconClass,
    buttonClass = "variant-edit-btn",
  }) => (
    <button className={buttonClass} type="button" onClick={onClick}>
      <i className={`fa ${iconClass}`} />
    </button>
  );

  const renderBody = (formikProps) => {
    const { isSubmitting, values } = formikProps;
    return (
      <Layout loading={loading}>
        <HeaderTitle id="variants.variants" defaultMessage="Variants" />
        <div className="dashboard-table-container card-layout">
          <div className="panel-primary">
            <header className="panel-title">
              {match.params.id ? (
                <FormattedMessage
                  id="variant.update"
                  defaultMessage="Update Variant"
                />
              ) : (
                <FormattedMessage
                  id="variant.add"
                  defaultMessage="Add Variant"
                />
              )}
            </header>

            <div className="">
              <Form>
                <div className="dashboard-row">
                  <FieldRow
                    name="nameEn"
                    disabled={false}
                    type="text"
                    labelId="variant.nameEn"
                    defaultLabel="Variant Name English"
                    placeholderId="variant.variantNameEnglish"
                    defaultPlaceholder="Enter Variant Name English"
                    showRequiredFlag={true}
                    formikProps={formikProps}
                  />
                  <FieldRow
                    name="nameAr"
                    disabled={false}
                    type="text"
                    labelId="variant.nameAr"
                    defaultLabel="Variant Name Arabic"
                    placeholderId="variant.variantNameArabic"
                    defaultPlaceholder="Enter Variant Name Arabic"
                    showRequiredFlag={true}
                    formikProps={formikProps}
                  />{" "}
                  <FieldRow
                    type="html"
                    name="descriptionEn"
                    labelId="variant.descriptionEn"
                    defaultLabel="Variant English Description"
                    showRequiredFlag={true}
                    formikProps={formikProps}
                    textEditor={true}
                  />
                  <FieldRow
                    type="html"
                    name="descriptionAr"
                    labelId="variant.descriptionAr"
                    defaultLabel="Variant Arabic Description"
                    showRequiredFlag={true}
                    formikProps={formikProps}
                    textEditor={true}
                  />
                  <FieldRow
                    formikProps={formikProps}
                    type="select"
                    name="product"
                    labelId="variant.products"
                    defaultLabel="Product"
                    placeholderId="variant.products"
                    defaultPlaceholder="Product"
                    showRequiredFlag={true}
                    disabled={false}
                  >
                    <option value="">
                      {intl.formatMessage({
                        id: "variant.selectProduct",
                        defaultMessage: "Select Product",
                      })}
                    </option>
                    {products?.map((product, i) => (
                      <option value={product.id} key={i}>
                        {appState.state.language === "en"
                          ? `${product?.englishName} - ${product?.categoryEnglishName}`
                          : `${product?.arabicName} - ${product?.categoryArabicName}`}
                      </option>
                    ))}
                  </FieldRow>
                  <FieldRow
                    name="image"
                    disabled={false}
                    type="file"
                    image={true}
                    cameraIcon={true}
                    showThumbnail={true}
                    labelId="variant.image"
                    defaultLabel="Image"
                    placeholderId="variant.selectImage"
                    defaultPlaceholder="Select Image"
                    showRequiredFlag={true}
                    formikProps={formikProps}
                  />
                  <FieldRow
                    type="number"
                    name="advanceBookingDays"
                    labelId="variant.advanceBookingDays"
                    defaultLabel="Advance booking days"
                    placeholderId="variant.advanceBookingDays"
                    defaultPlaceholder="Advance booking days"
                    formikProps={formikProps}
                    min={0}
                    max={20}
                  />
                  {appState.state.token?.role === "sadmin" && (
                    <FieldRow
                      name="isActive"
                      type="checkbox"
                      labelId="category.isActive"
                      defaultLabel="Active"
                      formikProps={formikProps}
                    />
                  )}
                </div>
                <header className="panel-title">
                  <FormattedMessage
                    id="variant.addProduct"
                    defaultMessage="Add Product"
                  />
                </header>
                <div className="dashboard-row">
                  <FieldRow
                    formikProps={formikProps}
                    type="select"
                    name="branch"
                    labelId="variant.branches"
                    defaultLabel="Branches"
                    placeholderId="variant.branches"
                    defaultPlaceholder="Branches"
                    showRequiredFlag={true}
                    disabled={isEditing}
                    onChange={(e) => {
                      formikProps.setFieldValue("branch", e.target.value);
                    }}
                  >
                    <option
                      value={
                        values.branch?.branchName
                          ? JSON.stringify(values.branch)
                          : ""
                      }
                    >
                      {values.branch?.branchName
                        ? values.branch?.branchName
                        : intl.formatMessage({
                            id: "variant.selectProduct",
                            defaultMessage: "Select Branch",
                          })}
                    </option>
                    {branches?.map((branch, i) => (
                      <option value={JSON.stringify(branch)} key={i}>
                        {appState.state.language === "en"
                          ? branch?.name?.value_en
                          : branch.name?.value_ar}
                      </option>
                    ))}
                  </FieldRow>
                  <FieldRow
                    name="oldPrice"
                    disabled={isEditing}
                    type="number"
                    labelId="variant.productOldPrice"
                    defaultLabel="Old Price"
                    placeholderId="variant.productOldPrice"
                    defaultPlaceholder="Enter Product Old Price"
                    formikProps={formikProps}
                    min={0}
                  />
                  <FieldRow
                    name="price"
                    disabled={isEditing}
                    type="number"
                    labelId="variant.productPrice"
                    defaultLabel="Price"
                    placeholderId="variant.productPrice"
                    defaultPlaceholder="Enter Product Price"
                    formikProps={formikProps}
                    showRequiredFlag={true}
                    min={0}
                  />
                  <FieldRow
                    name="quantity"
                    disabled={false}
                    type="number"
                    labelId="variant.quantity"
                    defaultLabel="Quantity"
                    placeholderId="variant.quantity"
                    defaultPlaceholder="Enter Quantity"
                    formikProps={formikProps}
                    showRequiredFlag={true}
                  />
                  <Button
                    type="button"
                    onClick={() =>
                      handleAddVariantToBranch(formikProps, values)
                    }
                  >
                    {intl.formatMessage({
                      id: "global.add",
                      defaultMessage: "Add",
                    })}
                  </Button>
                  <Button
                    type="button"
                    variant="secondary"
                    onClick={() => resetProductFields(formikProps)}
                    style={{ margin: "0 10px" }}
                  >
                    {intl.formatMessage({
                      id: "global.reset",
                      defaultMessage: "Reset",
                    })}
                  </Button>
                </div>
                <div class="dashboard-table-container ">
                  <table class="table dashboard-table table-hover ">
                    <div
                      className="ag-theme-balham"
                      style={{
                        height: "500px",
                      }}
                    >
                      <AgGridReact
                        rowSelection="single"
                        columnDefs={columnDefs(
                          formikProps,
                          setIsEditing,
                          setLoading,
                          setBranchInventoryHistory,
                          handleEditButtonClick
                        )}
                        frameworkComponents={{
                          CheckboxRenderer,
                          ButtonRenderer,
                        }}
                        yy
                        rowData={rows}
                      ></AgGridReact>
                    </div>
                  </table>
                </div>

                <div className="dashboard-row -nobottom">
                  {authContext.authorized && (
                    <CheckAccess
                      activityId={ActivitiesEnum.variant__update_variant}
                    >
                      <input
                        type="submit"
                        className="btn btn-primary"
                        value={intl.formatMessage({
                          id: "global.submit",
                          defaultMessage: "Submit",
                        })}
                        disabled={isSubmitting}
                      />
                    </CheckAccess>
                  )}
                  <Button
                    variant="link"
                    onClick={() => navigate("/variants")}
                    disabled={isSubmitting}
                  >
                    {intl.formatMessage({
                      id: "global.cancel",
                      defaultMessage: "Cancel",
                    })}
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>

        <HistoryModal
          onCancel={() => setBranchInventoryHistory([])}
          show={branchInventoryHistory?.length}
          data={branchInventoryHistory}
        />
      </Layout>
    );
  };

  return (
    <Auth requireAuth={true} roles={["sadmin"]}>
      <Formik
        innerRef={formikRef}
        initialValues={{
          nameEn: data.name?.value_en || "",
          nameAr: data.name?.value_ar || "",
          descriptionEn: data.description?.value_en || "",
          descriptionAr: data.description?.value_ar || "",
          product: data.product || "",
          image: data.image || "",
          isActive: data?.isActive || false,
          branch: "",
          advanceBookingDays: data?.advanceBookingDays || 0,
        }}
        validationSchema={Yup.object().shape({
          nameEn: Yup.string()
            .hasNoArabic(
              intl.formatMessage({
                id: "error.arabicCharactersAreNotAllowedInThisField",
                defaultMessage:
                  "Arabic characters are not allowed in this field",
              })
            )
            .label(
              intl.formatMessage({
                id: "category.englishName",
                defaultMessage: "English name",
              })
            )
            .trim()
            .required(),
          nameAr: Yup.string()
            .hasNoEnglish(
              intl.formatMessage({
                id: "error.englishCharactersAreNotAllowedInThisField",
                defaultMessage:
                  "English characters are not allowed in this field",
              })
            )
            .label(
              intl.formatMessage({
                id: "variant.arabicName",
                defaultMessage: "Arabic Name",
              })
            )
            .trim()
            .required(),
          descriptionEn: Yup.string()
            .label(
              intl.formatMessage({
                id: "variant.englishDescription",
                defaultMessage: "English Description",
              })
            )
            .test(
              "is-empty",
              "Description is required",
              (value) => sanitizeHtml(value).trim() !== ""
            )
            .required(),
          descriptionAr: Yup.string()
            .label(
              intl.formatMessage({
                id: "variant.arabicDescription",
                defaultMessage: "Arabic Description",
              })
            )
            .test(
              "is-empty",
              "Description is required",
              (value) => sanitizeHtml(value).trim() !== ""
            )
            .required(),
          image: Yup.string().required(),
          product: Yup.string().required(),
          advanceBookingDays: Yup.number()
            .label(
              intl.formatMessage({
                id: "variant.advanceBookingDays",
                defaultMessage: "Advance Booking Days",
              })
            )
            .min(0)
            .required(),
        })}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {(formikProps) => renderBody(formikProps)}
      </Formik>
    </Auth>
  );
};

export default withAuthContext(withRouter(injectIntl(VariantForm)));
