import React, { Component } from "react";
import { Route, Link, Routes, useMatch } from "react-router-dom";
import withRouter from "../../util/withRouter";
import classNames from "classnames";
import { FormattedMessage, injectIntl, useIntl } from "react-intl";
import appState from "../../state/AppStateContainer";
import _ from "lodash";
import services from "../../services";
import { ModulesEnum, ActivitiesEnum } from "../../staticData/Activities";
import CheckAccess from "./CheckAccess";

const SidebarLink = ({
  iconName,
  labelId,
  defaultLabel,
  to,
  moduleId,
  activityId,
}) => {
  if (
    (!moduleId && !activityId) ||
    services.hasAccessToModule(moduleId) ||
    services.canPerformActivity(activityId)
  ) {
    const match = useMatch(to);

    return (
      <li
        className={classNames("list-group-item sidebar-link", {
          "active-link": match,
        })}
      >
        <span style={{ pointerEvents: "none" }} className={"fa " + iconName} />
        <Link to={to} className="list-group-link">
          <FormattedMessage id={labelId} defaultMessage={defaultLabel} />
        </Link>
      </li>
    );
  } else {
    return null;
  }
};

export class Sidebar extends Component {
  state = {
    reportsExpanded: false,
    walletPackagesExpanded: false,
  };

  logoUrl = {
    en: "ah-logo.webp",
    ar: "ah-logo-ar.webp",
  };

  checkGroupedMenuAccess(nodes) {
    const hasAccess = nodes.some((n) =>
      services.hasAccessToModule(ModulesEnum[n]) ? true : false
    );
    return hasAccess;
  }

  render() {
    const sidebarClasses = classNames("sidebar", "left", {
      active: appState.state.sidebarActive,
    });

    return (
      <aside className={sidebarClasses}>
        <a
          className={`navbar-brand ${appState.state.language}`}
          onClick={() => this.props.navigate("/dashboard")}
        >
          <div className="text-center">
            <img
              src={"/design/images/" + this.logoUrl[appState.state.language]}
              alt="The Auto Hub CMS"
            />
          </div>
        </a>
        <div className="sidebar-group" id="MainMenu">
          <>
            <ul className="list-group">
              <SidebarLink
                deshboard-navi
                defaultLabel="Appointments Dashboard"
                iconName="fa fa-tachometer"
                labelId="menu.dashboard"
                moduleId={ModulesEnum.dashboard}
                to="/dashboard"
              />
              <SidebarLink
                defaultLabel="Autohub Web Translations"
                iconName="fa-language"
                labelId="menu.autohub-translation"
                to="/autohub-translation"
                moduleId={ModulesEnum.autohub_web_translation}
              />
              <CheckAccess moduleId={ModulesEnum.change_password}>
                <>
                  <li
                    className="list-group-item sidebar-link collapsed"
                    data-toggle="collapse"
                    data-target="#changePasswordSubmenu"
                    onClick={() => {
                      this.setState({
                        changePasswordExpanded:
                          !this.state.changePasswordExpanded,
                      });
                    }}
                  >
                    <span className="fa fa-key" />
                    <a className="list-group-link">
                      <FormattedMessage
                        id="sidebar.changePassword"
                        defaultMessage="App Credentials"
                      />
                    </a>
                    <span
                      className={classNames("fa dropdown_arrow", {
                        "fa-angle-down": !this.state.changePasswordExpanded,
                        "fa-angle-up": this.state.changePasswordExpanded,
                      })}
                    />
                  </li>
                  <ul className="sub-menu collapse" id="changePasswordSubmenu">
                    {appState.state.token?.role === "sadmin" && (
                      <SidebarLink
                        defaultLabel="Web"
                        iconName="fa-key"
                        labelId="sidebar.changeWebPassword"
                        to="/change-web-password"
                        activityId={ActivitiesEnum.change_password__web}
                      />
                    )}
                    <SidebarLink
                      defaultLabel="CMS"
                      iconName="fa-key"
                      labelId="sidebar.changeCMSPassword"
                      to="/change-cms-password"
                      activityId={ActivitiesEnum.change_password__cms}
                    />
                  </ul>
                </>
              </CheckAccess>

              <SidebarLink
                defaultLabel="Branches"
                iconName="fa fa-building-o"
                labelId="menu.branches"
                to="/branches"
                moduleId={ModulesEnum.branches}
              />

              <SidebarLink
                iconName="fa-dollar"
                labelId="promo.careemPromo"
                defaultLabel="Careem Promo Codes"
                to="/careem-promos"
                moduleId={ModulesEnum.careem_promo}
              />
              {appState.state.token?.role === "sadmin" && (
                <SidebarLink
                  defaultLabel="Cars"
                  iconName="fa-car"
                  labelId="sidebar.cars"
                  to="/cars"
                  moduleId={ModulesEnum.cars}
                />
              )}
              <SidebarLink
                defaultLabel="Cities"
                iconName="fa fa-institution"
                labelId="menu.cities"
                to="/cities"
                moduleId={ModulesEnum.cities}
              />
              <CheckAccess moduleId={ModulesEnum.contact_us}>
                <li
                  className="list-group-item sidebar-link collapsed"
                  data-toggle="collapse"
                  data-target="#ContactUsMenu"
                  onClick={() => {
                    this.setState({
                      contactUs: !this.state.contactUs,
                    });
                  }}
                >
                  <span className="fa fa-envelope" />
                  <span className="list-group-link">
                    <FormattedMessage
                      id="sidebar.contactUs"
                      defaultMessage="Contact Queries"
                    />
                  </span>
                  <span
                    className={classNames("fa dropdown_arrow", {
                      "fa-angle-down": !this.state.contactUs,
                      "fa-angle-up": this.state.contactUs,
                    })}
                  />
                </li>
                <ul className="sub-menu collapse" id="ContactUsMenu">
                  {/* <SidebarLink
                      defaultLabel="All"
                      iconName="fa-phone"
                      labelId="sidebar.contactUs"
                      to="/contacts"
                      activityId={ActivitiesEnum.contact_us_all}
                    /> */}
                  <SidebarLink
                    defaultLabel="Customer Care"
                    iconName="fa-headphones"
                    labelId="menu.customerCare"
                    to="/customer-care"
                    activityId={ActivitiesEnum.contact_us_customer_care}
                  />
                  <SidebarLink
                    defaultLabel="Marketing"
                    iconName="fa-bullhorn"
                    labelId="menu.marketing"
                    to="/marketing"
                    activityId={ActivitiesEnum.contact_us_marketing}
                  />
                  <SidebarLink
                    defaultLabel="Career"
                    iconName="fa-handshake-o"
                    labelId="menu.career"
                    to="/career"
                    activityId={ActivitiesEnum.contact_us_career}
                  />
                  <SidebarLink
                    defaultLabel="VIP and Loyalty Signup"
                    iconName="fa-user-plus"
                    labelId="menu.loyaltySignup"
                    to="/service-signup"
                    activityId={ActivitiesEnum.contact_us_vip_and_loyalty}
                  />
                  <SidebarLink
                    defaultLabel="Delete Account"
                    iconName="fa-user"
                    labelId="menu.deleteAccount"
                    to="/delete-account"
                    activityId={ActivitiesEnum.contact_us_delete_account}
                  />
                  <SidebarLink
                    defaultLabel="Checked-in Vehicles"
                    iconName="fa-car"
                    labelId="menu.checkedinVehicles"
                    to="/checkedin-vehicles"
                    activityId={ActivitiesEnum.contact_us_checked_in_vehicles}
                  />
                </ul>
              </CheckAccess>

              <CheckAccess moduleId={ModulesEnum.content}>
                <>
                  <li
                    className="list-group-item sidebar-link collapsed"
                    data-toggle="collapse"
                    data-target="#ContentSubmenu"
                    onClick={() => {
                      this.setState({
                        contentExpanded: !this.state.contentExpanded,
                      });
                    }}
                  >
                    <span className="fa fa-sticky-note" />
                    <a className="list-group-link">
                      <FormattedMessage
                        id="sidebar.content"
                        defaultMessage="Content"
                      />
                    </a>
                    <span
                      className={classNames("fa dropdown_arrow", {
                        "fa-angle-down": !this.state.contentExpanded,
                        "fa-angle-up": this.state.contentExpanded,
                      })}
                    />
                  </li>
                  <ul className="sub-menu collapse" id="ContentSubmenu">
                    <SidebarLink
                      defaultLabel="Pages & Media"
                      iconName="fa fa-sticky-note"
                      labelId="menu.media"
                      to="/pages-and-media"
                      activityId={ActivitiesEnum.content_media}
                    />
                    <CheckAccess activityId={ActivitiesEnum.content_home}>
                      <li
                        className="list-group-item sidebar-link collapsed"
                        data-toggle="collapse"
                        data-target="#homeBannerSubmenu"
                        onClick={() => {
                          this.setState({
                            bannerExpanded: !this.state.bannerExpanded,
                          });
                        }}
                      >
                        <span className="fa fa-sticky-note" />
                        <span className="list-group-link">
                          <FormattedMessage
                            id="menu.home"
                            defaultMessage="Home"
                          />
                        </span>
                        <span
                          className={classNames("fa dropdown_arrow", {
                            "fa-angle-down": !this.state.bannerExpanded,
                            "fa-angle-up": this.state.bannerExpanded,
                          })}
                        />
                      </li>
                      <ul className="sub-menu collapse" id="homeBannerSubmenu">
                        <SidebarLink
                          defaultLabel="Headlines"
                          iconName="fa fa-newspaper-o"
                          labelId="menu.headlines"
                          to="/headlines"
                          activityId={ActivitiesEnum.content_home}
                        />
                        <SidebarLink
                          defaultLabel="Banners"
                          iconName="fa fa-picture-o"
                          labelId="menu.banners"
                          to="/banners"
                          activityId={ActivitiesEnum.content_home}
                        />
                      </ul>
                    </CheckAccess>

                    <SidebarLink
                      defaultLabel="Notifications"
                      iconName="fa fa-sticky-note"
                      labelId="menu.Notifications"
                      to="/notifications"
                      activityId={
                        ActivitiesEnum.content_customer_sms_notification
                      }
                    />
                    <SidebarLink
                      defaultLabel="Email Notifications"
                      iconName="fa fa-envelope-o"
                      labelId="menu.emailNotifications"
                      to="/notifications-email"
                      activityId={
                        ActivitiesEnum.content_customer_email_notification
                      }
                    />
                  </ul>
                </>
              </CheckAccess>

              <SidebarLink
                defaultLabel="Customers"
                iconName="fa fa-users"
                labelId="menu.user"
                to="/users"
                moduleId={ModulesEnum.customers}
              />

              <SidebarLink
                defaultLabel="Estimation Requests"
                iconName="fa fa-clock-o"
                labelId="menu.estimationRequest"
                to="/estimation-requests"
                moduleId={ModulesEnum.estimation_request}
              />

              <SidebarLink
                defaultLabel="Kaizen"
                iconName="fa fa-trophy"
                labelId="menu.kaizen"
                to="/kaizens"
                activityId={ActivitiesEnum.content_kaizen}
              />

              <CheckAccess moduleId={ModulesEnum.knowledge_hub}>
                <>
                  <li
                    className="list-group-item sidebar-link collapsed"
                    data-toggle="collapse"
                    data-target="#BlogSubmenu"
                    onClick={() => {
                      this.setState({
                        blogExpanded: !this.state.blogExpanded,
                      });
                    }}
                  >
                    <span className="fa fa-book" />
                    <span className="list-group-link">
                      <FormattedMessage
                        id="sidebar.knowledgeHub"
                        defaultMessage="Knowledge Hub"
                      />
                    </span>
                    <span
                      className={classNames("fa dropdown_arrow", {
                        "fa-angle-down": !this.state.blogExpanded,
                        "fa-angle-up": this.state.blogExpanded,
                      })}
                    />
                  </li>
                  <ul className="sub-menu collapse" id="BlogSubmenu">
                    <SidebarLink
                      defaultLabel="Tags"
                      iconName="fa fa-tags"
                      labelId="menu.blogTags"
                      to="/blog-tags"
                      activityId={ActivitiesEnum.knowledge_hub_tag}
                    />
                    <SidebarLink
                      defaultLabel="Blogs"
                      iconName="fa fa-newspaper-o"
                      labelId="menu.blogs"
                      to="/blogs"
                      activityId={ActivitiesEnum.knowledge_hub_blog}
                    />
                  </ul>
                </>
              </CheckAccess>

              {/* <SidebarLink
                defaultLabel="Offers"
                iconName="fa fa-tag"
                labelId="menu.offers"
                to="/offers"
                moduleId={ModulesEnum.offers}
              /> */}

              {this.checkGroupedMenuAccess([
                "orders_lisitng",
                "orders_report_lisitng",
              ]) && (
                <>
                  <li
                    className="list-group-item sidebar-link collapsed"
                    data-toggle="collapse"
                    data-target="#OrderSubmenu"
                    onClick={() => {
                      this.setState({
                        orderExpanded: !this.state.orderExpanded,
                      });
                    }}
                  >
                    <span className="fa fa-shopping-cart" />
                    <a className="list-group-link">
                      <FormattedMessage
                        id="sidebar.order"
                        defaultMessage="Order Details"
                      />
                    </a>
                    <span
                      className={classNames("fa dropdown_arrow", {
                        "fa-angle-down": !this.state.orderExpanded,
                        "fa-angle-up": this.state.orderExpanded,
                      })}
                    />
                  </li>

                  <ul className="sub-menu collapse" id="OrderSubmenu">
                    <SidebarLink
                      defaultLabel="Orders"
                      iconName="fa fa-shopping-cart"
                      labelId="menu.orders"
                      to="/orders"
                      moduleId={ModulesEnum.orders_lisitng}
                    />
                    <SidebarLink
                      defaultLabel="Order Reports"
                      iconName="fa fa-shopping-cart"
                      labelId="menu.orderReport"
                      to="/order-reports"
                      moduleId={ModulesEnum.orders_report_lisitng}
                    />
                  </ul>
                </>
              )}

              <CheckAccess moduleId={ModulesEnum.permissions}>
                <>
                  <li
                    className="list-group-item sidebar-link collapsed"
                    data-toggle="collapse"
                    data-target="#PermissionsSubmenu"
                    onClick={() => {
                      this.setState({
                        permissionsExpanded: !this.state.permissionsExpanded,
                      });
                    }}
                  >
                    <span className="fa fa-shield" />
                    <a className="list-group-link">
                      <FormattedMessage
                        id="sidebar.permissions"
                        defaultMessage="Permissions"
                      />
                    </a>
                    <span
                      className={classNames("fa dropdown_arrow", {
                        "fa-angle-down": !this.state.permissionsExpanded,
                        "fa-angle-up": this.state.permissionsExpanded,
                      })}
                    />
                  </li>
                  <ul className="sub-menu collapse" id="PermissionsSubmenu">
                    <SidebarLink
                      defaultLabel="Roles"
                      iconName="fa-users"
                      labelId="global.roles"
                      to="/roles"
                      activityId={ActivitiesEnum.permissions__roles}
                    />

                    <SidebarLink
                      defaultLabel="Admins"
                      iconName="fa-user-circle-o"
                      labelId="global.admins"
                      to="/admins"
                      activityId={ActivitiesEnum.permissions__admins}
                    />
                  </ul>
                </>
              </CheckAccess>

              <SidebarLink
                defaultLabel="Promo Codes"
                iconName="fa-dollar"
                labelId="promo.promoMangement"
                to="/promos"
                moduleId={ModulesEnum.promo_management}
              />

              {this.checkGroupedMenuAccess([
                "create_quotation",
                "requested_quotations",
              ]) && (
                <>
                  <li
                    className="list-group-item sidebar-link collapsed"
                    data-toggle="collapse"
                    data-target="#QuotationsSubmenu"
                    onClick={() => {
                      this.setState({
                        quotationsExpanded: !this.state.quotationsExpanded,
                      });
                    }}
                  >
                    <span className="fa fa-money" />
                    <a className="list-group-link">
                      <FormattedMessage
                        id="sidebar.quotations"
                        defaultMessage="Quotations"
                      />
                    </a>
                    <span
                      className={classNames("fa dropdown_arrow", {
                        "fa-angle-down": !this.state.quotationsExpanded,
                        "fa-angle-up": this.state.quotationsExpanded,
                      })}
                    />
                  </li>
                  <ul className="sub-menu collapse" id="QuotationsSubmenu">
                    <SidebarLink
                      defaultLabel="Quotation Services"
                      iconName="fa fa-money"
                      labelId="menu.quotations"
                      to="/quotations"
                      moduleId={ModulesEnum.create_quotation}
                    />
                    <SidebarLink
                      labelId="menu.requestedQuotes"
                      defaultLabel="Quotation Requests"
                      iconName="fa fa-money"
                      to="/quotation-requests"
                      moduleId={ModulesEnum.requested_quotations}
                    />
                  </ul>
                </>
              )}

              <SidebarLink
                defaultLabel="Required Documents"
                iconName="fa fa-sticky-note"
                labelId="menu.requiredDocuments"
                to="/required-documents"
                moduleId={ModulesEnum.required_documents}
              />

              <SidebarLink
                defaultLabel="Scheduled Appointments"
                iconName="fa fa-calendar-check-o"
                labelId="menu.appointment"
                to="/appointments"
                moduleId={ModulesEnum.schedule_appointment}
              />
              <SidebarLink
                defaultLabel="Schedule Emails"
                iconName="fa-envelope"
                labelId="menu.scheduleEmail"
                to="/schedule-emails"
                moduleId={ModulesEnum.schedule_email}
              />

              {this.checkGroupedMenuAccess([
                "service_categories",
                "services",
              ]) && (
                <>
                  <li
                    className="list-group-item sidebar-link collapsed"
                    data-toggle="collapse"
                    data-target="#ServicesSubmenu"
                    onClick={() => {
                      this.setState({
                        quotationsExpanded: !this.state.quotationsExpanded,
                      });
                    }}
                  >
                    <span className="fa fa-wrench" />
                    <a className="list-group-link">
                      <FormattedMessage
                        id="sidebar.services"
                        defaultMessage="Services / Offers"
                      />
                    </a>
                    <span
                      className={classNames("fa dropdown_arrow", {
                        "fa-angle-down": !this.state.quotationsExpanded,
                        "fa-angle-up": this.state.quotationsExpanded,
                      })}
                    />
                  </li>
                  <ul className="sub-menu collapse" id="ServicesSubmenu">
                    <SidebarLink
                      defaultLabel="Categories"
                      iconName="fa fa-wrench"
                      labelId="menu.serviceCategories"
                      to="/service-categories"
                      moduleId={ModulesEnum.service_categories}
                    />
                    <SidebarLink
                      defaultLabel="Services"
                      iconName="fa fa-wrench"
                      labelId="menu.services"
                      to="/services"
                      moduleId={ModulesEnum.services}
                    />
                  </ul>
                </>
              )}

              <SidebarLink
                defaultLabel="Settings"
                iconName="fa fa-cogs"
                labelId="global.moreSettings"
                to="/settings"
                moduleId={ModulesEnum.settings}
              />

              {this.checkGroupedMenuAccess([
                "categories",
                "products",
                "variants",
              ]) && (
                <>
                  <li
                    className="list-group-item sidebar-link collapsed"
                    data-toggle="collapse"
                    data-target="#ShopSubmenu"
                    onClick={() => {
                      this.setState({
                        shopExpanded: !this.state.shopExpanded,
                      });
                    }}
                  >
                    <span className="fa fa-shopping-bag" />
                    <a className="list-group-link">
                      <FormattedMessage
                        id="sidebar.shop"
                        defaultMessage="Shop"
                      />
                    </a>
                    <span
                      className={classNames("fa dropdown_arrow", {
                        "fa-angle-down": !this.state.shopExpanded,
                        "fa-angle-up": this.state.shopExpanded,
                      })}
                    />
                  </li>

                  <ul className="sub-menu collapse" id="ShopSubmenu">
                    <SidebarLink
                      defaultLabel="Categories"
                      iconName="fa-shopping-bag"
                      labelId="menu.productCategories"
                      moduleId={ModulesEnum.categories}
                      to="/product-categories"
                    />
                    <SidebarLink
                      defaultLabel="Products"
                      iconName="fa-shopping-bag"
                      labelId="menu.products"
                      to="/products"
                      moduleId={ModulesEnum.products}
                    />
                    <SidebarLink
                      defaultLabel="Variants"
                      iconName="fa-shopping-bag"
                      labelId="menu.variants"
                      to="/variants"
                      moduleId={ModulesEnum.variants}
                    />
                  </ul>
                </>
              )}

              {this.checkGroupedMenuAccess([
                "subscription_packages",
                "subscribed_offers",
              ]) && (
                <>
                  <li
                    className="list-group-item sidebar-link collapsed"
                    data-toggle="collapse"
                    data-target="#SubscriptionSubmenu"
                    onClick={() => {
                      this.setState({
                        subscriptionExpanded: !this.state.subscriptionExpanded,
                      });
                    }}
                  >
                    <span className="fa fa-tags" />
                    <a className="list-group-link">
                      <FormattedMessage
                        id="sidebar.subscriptions"
                        defaultMessage="Subscriptions"
                      />
                    </a>
                    <span
                      className={classNames("fa dropdown_arrow", {
                        "fa-angle-down": !this.state.subscriptionExpanded,
                        "fa-angle-up": this.state.subscriptionExpanded,
                      })}
                    />
                  </li>
                  <ul className="sub-menu collapse" id="SubscriptionSubmenu">
                    <SidebarLink
                      defaultLabel="Subscription Packages"
                      iconName="fa fa-tags"
                      labelId="menu.subscriptions"
                      to="/subscriptions"
                      moduleId={ModulesEnum.subscription_packages}
                    />
                    <SidebarLink
                      defaultLabel="Subscribed Packages"
                      iconName="fa fa-tags"
                      labelId="menu.subscribedOffers"
                      to="/subscribed-offers"
                      moduleId={ModulesEnum.subscribed_offers}
                    />
                  </ul>
                </>
              )}

              {this.checkGroupedMenuAccess([
                "track",
                "track_repair_feedback",
                "upsell_recommendation",
              ]) && (
                <>
                  <li
                    className="list-group-item sidebar-link collapsed"
                    data-toggle="collapse"
                    data-target="#TrackSubmenu"
                    onClick={() => {
                      this.setState({
                        trackExpanded: !this.state.trackExpanded,
                      });
                    }}
                  >
                    <span className="fa fa-tags" />
                    <a className="list-group-link">
                      <FormattedMessage
                        id="sidebar.trackAndUpsell"
                        defaultMessage="Track & Upsell"
                      />
                    </a>
                    <span
                      className={classNames("fa dropdown_arrow", {
                        "fa-angle-down": !this.state.trackExpanded,
                        "fa-angle-up": this.state.trackExpanded,
                      })}
                    />
                  </li>
                  <ul className="sub-menu collapse" id="TrackSubmenu">
                    <SidebarLink
                      defaultLabel="Track Car Repair Progress"
                      iconName="fa fa-tasks"
                      labelId="menu.trackPage"
                      to="/track"
                      moduleId={ModulesEnum.track}
                    />
                    <SidebarLink
                      defaultLabel="Track Car Repair Feedback"
                      iconName="fa fa-commenting"
                      labelId="menu.trackFeedback"
                      to="/track-feedbacks"
                      moduleId={ModulesEnum.track_repair_feedback}
                    />
                    <SidebarLink
                      defaultLabel="Upsell Recommendations"
                      iconName="fa fa-thumbs-up"
                      labelId="menu.upsellRecommendations"
                      to="/upsell-recommendations"
                      moduleId={ModulesEnum.upsell_recommendation}
                    />
                  </ul>
                </>
              )}

              <SidebarLink
                defaultLabel="User Activities"
                iconName="fa fa-tags"
                labelId="menu.userActivity"
                to="/user-activities"
                activityId={ModulesEnum.user_activities}

              />
            </ul>
          </>
        </div>
      </aside>
    );
  }
}

export default withRouter(Sidebar);
