import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Formik, Form } from "formik";
import { Yup } from "../../util";
import { Modal, Button } from "react-bootstrap";

export default class CancelRequestModal extends Component {
  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={() => this.props.onCancel()}
        centered
      >
        <Modal.Header closeButton={true}>
          <h4>
            <FormattedMessage
              id="global.cancelRequest"
              defaultMessage="Cancel Request"
            />
          </h4>
        </Modal.Header>
        <Modal.Body>
          <FormattedMessage
            id="global.pleaseAddYourReason"
            defaultMessage="Please Add your reason about cancel estimation request."
          />
          <Formik
            innerRef={(f) => (this.formik = f)}
            validationSchema={Yup.object().shape({
              cancelRequestReason: Yup.string().required(),
            })}
            onSubmit={(values) => {
              this.props.onSubmit &&
                this.props.onSubmit(values.cancelRequestReason);
            }}
            initialValues={{
              cancelRequestReason: this.props.cancelRequestReason || "",
            }}
            render={({
              values,
              handleChange,
              touched,
              errors,
              setFieldValue,
            }) => {
              return (
                <Form>
                  <textarea
                    name="cancelRequestReason"
                    value={values.cancelRequestReason}
                    cols="30"
                    rows="10"
                    onChange={handleChange}
                    className="form-control"
                    disabled={this.props.disabled}
                  />
                  {touched["cancelRequestReason"] &&
                    errors["cancelRequestReason"] && (
                      <p className="text-warning">
                        {errors["cancelRequestReason"]}
                      </p>
                    )}
                </Form>
              );
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="link" onClick={() => this.props.onCancel()}>
            <FormattedMessage id="global.cancel" defaultMessage="Cancel" />
          </Button>
          <Button
            onClick={() => this.formik.submitForm()}
            disabled={this.props.disabled}
          >
            <FormattedMessage id="global.submit" defaultMessage="Submit" />
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
