import React from "react";
import { Step, StepTitle, Stepper } from "react-progress-stepper";
import appState from "../../../state/AppStateContainer";
import { FormattedMessage } from "react-intl";
import {
  quotationStatus,
  getFormattedCarNumberPlate,
} from "../../../util/utils";
import services from "../../../services";

const QuotationProgress = ({ quotations, loader, setLoading }) => {
  const language = appState.state.language;

  const createMasterStep = (masterStatus) => {
    const status = quotationStatus[masterStatus];

    const step1Props = { customContent: undefined };
    if (status === 0) {
      step1Props.customContent = () => <div className="current-step" />;
    }

    const step2Props = { customContent: undefined };
    if (status === 1) {
      step2Props.customContent = () => <div className="current-step" />;
    }

    return (
      <>
        <Stepper step={status} numbered={false}>
          <Step {...step1Props}>
            <StepTitle>
              <FormattedMessage
                id="track.quotationRequested"
                defaultMessage="Quotation Requested"
              />
            </StepTitle>
          </Step>
          <Step {...step2Props}>
            <StepTitle>
              <FormattedMessage
                id="track.quotationProgress"
                defaultMessage="Quotation In Progress"
              />
            </StepTitle>
          </Step>
          <Step customContent={() => <i className="fa fa-trophy" />}>
            <StepTitle>
              <FormattedMessage
                id="track.quotationCompleted"
                defaultMessage="Quotation Completed"
              />
            </StepTitle>
          </Step>
        </Stepper>
      </>
    );
  };

  const downloadPDF = async (file) => {
    setLoading(true);
    await services.downloadFile(file);
    setLoading(false);
  };

  return (
    <div className="progress-tracker">
      {quotations.length ? (
        quotations.map((est, i) => {
          let parentClass = "row request-estimate-body estimate-head ";
          parentClass += est?.isApproved ? " estimation-approved" : "";
          parentClass += est?.isExpired ? " estimation-expired" : "";
          parentClass += est?.isAppointmentCreated ? " estimation-booked" : "";

          const car = est.carData[0];

          return (
            <div className={parentClass} key={i}>
              <div className="col-md-3 col-lg-3 col-xl-2 status-for">
                <h5>
                  {est.otherBrand || car.brand["value_" + language]}{" "}
                  {est.otherModel || car.product["value_" + language]}
                  <br />
                  {getFormattedCarNumberPlate(est.licencePlate)}
                </h5>
                {window.innerWidth <= 991 && est?.isApproved && (
                  <div className="estimation-data">
                    <span className="moreDetails">
                      <FormattedMessage
                        id="track.cost"
                        defaultMessage={"Cost"}
                      />{" "}
                      :{" "}
                      <span className="fw-bold">
                        {est?.cost}{" "}
                        <FormattedMessage
                          id="track.SAR"
                          defaultMessage={"SAR"}
                        />
                      </span>
                    </span>
                    <a className="pdf-link">
                      <button onClick={() => downloadPDF(est?.costPdf)}>
                        <FormattedMessage
                          id="track.downloadEstimation"
                          defaultMessage={"Download Estimation"}
                        />
                      </button>
                    </a>

                    <div className="estimation-btn">
                        <FormattedMessage
                          id={
                            est?.isAppointmentCreated
                              ? "track.booked"
                              : "track.notBooked"
                          }
                          defaultMessage={
                            est?.isAppointmentCreated ? "Booked" : "Not Booked"
                          }
                        />
                    </div>
                  </div>
                )}
                {est?.isExpired && (
                  <p className="expired-label">
                    <FormattedMessage
                      id="track.quotationExpired"
                      defaultMessage="Quotation Expired"
                    />
                  </p>
                )}
              </div>
              <div className="col-md-8 col-lg-7 col-xl-8">
                {createMasterStep(est?.status)}
              </div>
              <div className="col-md-1 col-lg-2 col-xl-2 p-0">
                {window.innerWidth > 991 && est?.isApproved && (
                  <div className="estimation-data desktop">
                    <span className="moreDetails">
                      <FormattedMessage
                        id="track.cost"
                        defaultMessage="Cost"
                      />
                      :{" "}
                      <span className="fw-bold">
                        {est?.cost}{" "}
                        <FormattedMessage
                          id="track.SAR"
                          defaultMessage="SAR"
                        />
                      </span>
                    </span>
                    <a
                      className="pdf-link"
                      onClick={() => downloadPDF(est?.costPdf)}
                    >
                      <button>
                        <FormattedMessage
                          id="track.downloadQuotation"
                          defaultMessage="Download Quotation"
                        />
                      </button>
                    </a>

                    <div className="estimation-btn">
                      {!est?.isAppointmentCreated ? (
                        <span>
                          <FormattedMessage
                            id="track.notBooked"
                            defaultMessage="Not Booked"
                          />
                        </span>
                      ) : (
                        <span>
                          <FormattedMessage
                            id="track.booked"
                            defaultMessage="Booked"
                          />
                        </span>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        })
      ) : !quotations.length && !loader ? (
        <h5 className="no-data">
          <FormattedMessage
            id="track.noQuotations"
            defaultMessage="No Quotations"
          />
        </h5>
      ) : null}
    </div>
  );
};

export default QuotationProgress;
