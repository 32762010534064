import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import _ from "lodash";
import FieldRow from "../../shared/FieldRow";
import appState from "../../../state/AppStateContainer";
import classNames from "classnames";
import { Collapse, Accordion } from "react-bootstrap";
import { week } from "../../../util/utils";
import initializeForm from "../functions/initializeForm";

class ShopTimings extends Component {
  constructor() {
    super();
    this.displayShopTime = initializeForm.customizeHourlySlots();
  }

  state = {
    shopTimingsExpanded: false,
  };

  render() {
    const formikProps = this.props.formikProps;
    const { values } = formikProps;

    return (
      <div className="shop-days-panels">
        <Accordion.Item eventKey="1">
          <Accordion.Header
            style={{ backgroundColor: "#555" }}
            onClick={() =>
              this.setState({
                shopTimingsExpanded: !this.state.shopTimingsExpanded,
              })
            }
          >
            <FormattedMessage
              id="branch.shopTimings"
              defaultMessage="Shop Timings"
            />
            <span
              className={classNames("glyphicon", "pull-right", {
                "glyphicon-plus": !this.state.shopTimingsExpanded,
                "glyphicon-minus": this.state.shopTimingsExpanded,
              })}
              aria-hidden="true"
            />
          </Accordion.Header>
          {/* <Collapse in={this.state.shopTimingsExpanded}> */}
          <Accordion.Body>
            <table className="table">
              <thead className="thead-dark">
                <tr>
                  <th scope="col">
                    <FormattedMessage id="branch.Days" defaultMessage="DAYS" />
                  </th>
                  <th scope="col">
                    <FormattedMessage
                      id="branch.openingTime"
                      defaultMessage="OPENING TIME"
                    />
                  </th>
                  <th scope="col">
                    <FormattedMessage
                      id="branch.closingTime"
                      defaultMessage="CLOSING TIME"
                    />
                  </th>
                  <th scope="col">
                    <FormattedMessage
                      id="branch.isClosed"
                      defaultMessage="CLOSED"
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {week.map(({ en, ar }, i) => {
                  return (
                    <tr key={i}>
                      <th scope="row">
                        {appState.state.language === "en"
                          ? en.toUpperCase()
                          : ar}
                      </th>
                      <td>
                        <FieldRow
                          colSize={3}
                          type="select"
                          name={"openingTime" + en}
                          data={this.displayShopTime}
                          showRequiredFlag={true}
                          disabled={values["is" + en + "Close"]}
                          formikProps={formikProps}
                        />
                      </td>
                      <td>
                        <FieldRow
                          colSize={3}
                          type="select"
                          name={"closingTime" + en}
                          data={this.displayShopTime}
                          showRequiredFlag={true}
                          disabled={values["is" + en + "Close"]}
                          formikProps={formikProps}
                        />
                      </td>
                      <td style={{ paddingLeft: 0 }}>
                        <FieldRow
                          colSize={3}
                          type="checkbox"
                          name={"is" + en + "Close"}
                          fieldStyle={{
                            width: "82px",
                            height: "35px",
                            margin: "4px 0 8px",
                          }}
                          formikProps={formikProps}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Accordion.Body>
          {/* </Collapse> */}
        </Accordion.Item>
      </div>
    );
  }
}

export default ShopTimings;
