import React, { Component, createRef } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Form, Formik } from "formik";
import { Yup } from "../../util";
import Layout from "../shared/Layout";
import services from "../../services";
import { NotificationManager } from "react-notifications";
import Loading from "../shared/Loading";
import Auth from "../shared/Auth";
import FieldRow from "../shared/FieldRow";
import withRouter from "../../util/withRouter";
import appState from "../../state/AppStateContainer";
import HeaderTitle from "../shared/HeaderTitle";
import { withAuthContext } from "../../state/AuthContext";
import PagesDropdown from "../shared/PagesDropdown";
import _ from "lodash";

export class Home extends Component {
  constructor() {
    super();
    this.formik = createRef();
  }

  state = {
    data: {},
    loading: true,
    pagesData: [],
  };

  async componentDidMount() {
    try {
      this.subscription = appState.emitter.addListener(
        "onLanguageChange",
        () => {
          this.formik.validateForm();
        }
      );

      this.setState({ loading: true });
      const options = { progressBar: true };
      const [pages, data] = await Promise.all([
        services.getPages(),
        services.getMediaData(options),
      ]);
      this.setState({ pagesData: pages, data });
    } catch (e) {
      this.props.navigate("/error", {
        error: _.get(e, "response.data.error", "error.unexpectedError"),
      });
    } finally {
      this.setState({ loading: false });
    }
  }

  componentWillUnmount() {
    this.subscription.remove();
  }

  async handleSubmit(values, { setSubmitting }) {
    setSubmitting(true);
    try {
      await services.addMedia(values);
      NotificationManager.success(
        this.props.intl.formatMessage({
          id: "global.operationSuccessful",
          defaultMessage: "Operation Successful",
        }),
        this.props.intl.formatMessage({
          id: "global.success",
          defaultMessage: "Success",
        })
      );
    } catch (e) {
      NotificationManager.error(
        this.props.intl.formatMessage({
          id: _.get(e, "response.data.error", "error.unexpectedError"),
          defaultMessage: "An unexpected error has occurred",
        }),
        this.props.intl.formatMessage({
          id: "global.error",
          defaultMessage: "Error",
        })
      );
    } finally {
      setSubmitting(false);
    }
  }

  render() {
    const { intl } = this.props;
    const { data } = this.state;
    return (
      <Auth requireAuth={true} roles={["sadmin", "wsadmin", "spadmin"]}>
        <Formik
          innerRef={(f) => {
            this.formik = f;
          }}
          enableReinitialize
          initialValues={{
            aljLogo_en: data.aljLogo_en,
            aljLogo_ar: data.aljLogo_ar,
            autohubShop: data.autohubShop,
            autohubDocThumb: data.autohubDocThumb,
            autohubDoc: data.autohubDoc,
            kaizenBanner: data.kaizenBanner,
            blogsBanner: data.blogsBanner,
            careBanner: data.careBanner,
            marketingBanner: data.marketingBanner,
            careerBanner: data.careerBanner,
            vipServiceBanner: data.vipServiceBanner,
            deleteAccountBanner: data.deleteAccountBanner,
            vehicleCheckinBanner: data.vehicleCheckinBanner,
          }}
          validationSchema={Yup.object().shape({
            aljLogo_en: Yup.mixed()
              .label(
                intl.formatMessage({
                  id: "media.aljLogo_en",
                  defaultMessage: "ALJ logo en",
                })
              )
              .required(),
            aljLogo_ar: Yup.mixed()
              .label(
                intl.formatMessage({
                  id: "media.aljLogo_ar",
                  defaultMessage: "ALJ logo ar",
                })
              )
              .required(),
            autohubShop: Yup.mixed()
              .label(
                intl.formatMessage({
                  id: "media.autohubShop",
                  defaultMessage: "Autohub shop",
                })
              )
              .required(),
            autohubDoc: Yup.mixed()
              .label(
                intl.formatMessage({
                  id: "media.autohubDoc",
                  defaultMessage: "Autohub video",
                })
              )
              .required(),
            kaizenBanner: Yup.mixed()
              .label(
                intl.formatMessage({
                  id: "media.kaizenBanner",
                  defaultMessage: "Kaizen banner",
                })
              )
              .required(),
            blogsBanner: Yup.mixed()
              .label(
                intl.formatMessage({
                  id: "media.blogsBanner",
                  defaultMessage: "Blogs banner",
                })
              )
              .required(),
            careBanner: Yup.mixed()
              .label(
                intl.formatMessage({
                  id: "media.careBanner",
                  defaultMessage: "Care banner",
                })
              )
              .required(),
            marketingBanner: Yup.mixed()
              .label(
                intl.formatMessage({
                  id: "media.marketingBanner",
                  defaultMessage: "Marketing banner",
                })
              )
              .required(),
            careerBanner: Yup.mixed()
              .label(
                intl.formatMessage({
                  id: "media.careerBanner",
                  defaultMessage: "Career banner",
                })
              )
              .required(),
            vipServiceBanner: Yup.mixed()
              .label(
                intl.formatMessage({
                  id: "media.vipServiceBanner",
                  defaultMessage: "VIP Service Signup banner",
                })
              )
              .required(),
            deleteAccountBanner: Yup.mixed()
              .label(
                intl.formatMessage({
                  id: "media.deleteAccountBanner",
                  defaultMessage: "Delete Account banner",
                })
              )
              .required(),
            vehicleCheckinBanner: Yup.mixed()
              .label(
                intl.formatMessage({
                  id: "media.vehicleCheckinBanner",
                  defaultMessage: "Vehicle Checkin banner",
                })
              )
              .required(),
          })}
          onSubmit={this.handleSubmit.bind(this)}
        >
          {(formikProps) => {
            const { isSubmitting } = formikProps;
            const { pagesData } = this.state;

            return (
              <Layout
                noLayout={this.props.noLayout}
                loading={this.state.loading}
              >
                <HeaderTitle
                  id="content.media"
                  defaultMessage="Pages & Media"
                />
                <div className="dashboard-table-container card-layout">
                  <div className="panel-primary">
                    <div className="dashboard-row -nobottom no-flex">
                      <div>
                        <div className="titleFieldForm">
                          <h5>
                            <FormattedMessage
                              id="media.pagesAccess"
                              defaultMessage="Pages Access Control for Web"
                            />
                          </h5>
                        </div>
                        <div className="pages-access-wrap">
                          {pagesData.map(({ name, isActive }, i) => {
                            return (
                              <PagesDropdown
                                key={name + i}
                                page={name}
                                isActive={isActive}
                                loading={(state) =>
                                  this.setState({ loading: state })
                                }
                              />
                            );
                          })}
                        </div>
                      </div>
                    </div>

                    <Form>
                      <div className="dashboard-row">
                        <div>
                          <div className="titleFieldForm">
                            <h5>
                              <FormattedMessage
                                id="media.home"
                                defaultMessage="Home (media)"
                              />
                            </h5>
                          </div>
                          <FieldRow
                            type="file"
                            name="aljLogo_en"
                            labelId="media.aljLogoEn"
                            defaultLabel="ALJ logo (En)"
                            formikProps={formikProps}
                            image={true}
                            showThumbnail={true}
                            showRequiredFlag={false}
                            forCarousel={true}
                            cameraIcon={true}
                            disabled={false}
                          />
                          <FieldRow
                            type="file"
                            name="aljLogo_ar"
                            labelId="media.aljLogoAr"
                            defaultLabel="ALJ logo (Ar)"
                            formikProps={formikProps}
                            image={true}
                            showThumbnail={true}
                            showRequiredFlag={false}
                            forCarousel={true}
                            cameraIcon={true}
                            disabled={false}
                          />
                          <FieldRow
                            type="file"
                            name="autohubShop"
                            labelId="media.autohubShop"
                            defaultLabel="Autohub shop"
                            formikProps={formikProps}
                            image={true}
                            showThumbnail={true}
                            showRequiredFlag={false}
                            forCarousel={true}
                            cameraIcon={true}
                            disabled={false}
                          />
                          <FieldRow
                            type="file"
                            name="autohubDocThumb"
                            labelId="media.autohubDocThumb"
                            defaultLabel="Autohub video thumbnail"
                            formikProps={formikProps}
                            image={true}
                            showThumbnail={true}
                            showRequiredFlag={false}
                            forCarousel={true}
                            cameraIcon={true}
                            disabled={false}
                          />
                          <FieldRow
                            type="video"
                            name="autohubDoc"
                            labelId="media.autohubDoc"
                            defaultLabel="Autohub video"
                            formikProps={formikProps}
                            showRequiredFlag={false}
                            showThumbnail={true}
                            disabled={false}
                          />
                        </div>
                        <div>
                          <div className="titleFieldForm">
                            <h5>
                              <FormattedMessage
                                id="media.contactUs"
                                defaultMessage="Contact Us (media)"
                              />
                            </h5>
                          </div>
                          <FieldRow
                            type="file"
                            name="careBanner"
                            labelId="contact.careBanner"
                            defaultLabel="Customer Care (Banner)"
                            aspectRatio={1920 / 1080}
                            imageSize="W:1920 x H:1080"
                            formikProps={formikProps}
                            image={true}
                            showThumbnail={true}
                            showRequiredFlag={false}
                            forCarousel={true}
                            cameraIcon={true}
                            disabled={false}
                          />
                          <FieldRow
                            type="file"
                            name="marketingBanner"
                            labelId="contactus.marketingBanner"
                            defaultLabel="Partner with Autohub (Banner)"
                            aspectRatio={1920 / 1080}
                            imageSize="W:1920 x H:1080"
                            formikProps={formikProps}
                            image={true}
                            showThumbnail={true}
                            showRequiredFlag={false}
                            forCarousel={true}
                            cameraIcon={true}
                            disabled={false}
                          />
                          <FieldRow
                            type="file"
                            name="careerBanner"
                            labelId="contactus.careerBanner"
                            defaultLabel="Join Autohub Team (Banner)"
                            aspectRatio={1920 / 1080}
                            imageSize="W:1920 x H:1080"
                            formikProps={formikProps}
                            image={true}
                            showThumbnail={true}
                            showRequiredFlag={false}
                            forCarousel={true}
                            cameraIcon={true}
                            disabled={false}
                          />
                          <FieldRow
                            type="file"
                            name="vipServiceBanner"
                            labelId="contactus.vipServiceBanner"
                            defaultLabel="VIP Service Signup (Banner)"
                            aspectRatio={1920 / 1080}
                            imageSize="W:1920 x H:1080"
                            formikProps={formikProps}
                            image={true}
                            showThumbnail={true}
                            showRequiredFlag={false}
                            forCarousel={true}
                            cameraIcon={true}
                            disabled={false}
                          />
                          <FieldRow
                            type="file"
                            name="deleteAccountBanner"
                            labelId="contactus.deleteAccountBanner"
                            defaultLabel="Delete Your Account (Banner)"
                            aspectRatio={1920 / 1080}
                            imageSize="W:1920 x H:1080"
                            formikProps={formikProps}
                            image={true}
                            showThumbnail={true}
                            showRequiredFlag={false}
                            forCarousel={true}
                            cameraIcon={true}
                            disabled={false}
                          />
                          <FieldRow
                            type="file"
                            name="vehicleCheckinBanner"
                            labelId="contactus.vehicleCheckinBanner"
                            defaultLabel="Vehicle Checkin (Banner)"
                            aspectRatio={1920 / 1080}
                            imageSize="W:1920 x H:1080"
                            formikProps={formikProps}
                            image={true}
                            showThumbnail={true}
                            showRequiredFlag={false}
                            forCarousel={true}
                            cameraIcon={true}
                            disabled={false}
                          />
                        </div>
                        <div>
                          <div className="titleFieldForm">
                            <h5>
                              <FormattedMessage
                                id="media.kaizen"
                                defaultMessage="Kaizen (media)"
                              />
                            </h5>
                          </div>
                          <FieldRow
                            type="file"
                            name="kaizenBanner"
                            labelId="media.kaizenBanner"
                            defaultLabel="Kaizen Banner"
                            formikProps={formikProps}
                            image={true}
                            showThumbnail={true}
                            showRequiredFlag={false}
                            forCarousel={true}
                            cameraIcon={true}
                            disabled={false}
                          />
                        </div>
                        <div>
                          <div className="titleFieldForm">
                            <h5>
                              <FormattedMessage
                                id="media.blogs"
                                defaultMessage="KnowledgeHub (media)"
                              />
                            </h5>
                          </div>
                          <FieldRow
                            type="file"
                            name="blogsBanner"
                            labelId="media.blogsBanner"
                            defaultLabel="Blogs Banner"
                            aspectRatio={1440 / 300}
                            imageSize="W:1440 x H:300"
                            formikProps={formikProps}
                            image={true}
                            showThumbnail={true}
                            showRequiredFlag={false}
                            forCarousel={true}
                            cameraIcon={true}
                            disabled={false}
                          />
                        </div>
                      </div>

                      <div className="dashboard-row -nobottom">
                        {this.props.authContext.authorized && (
                          <input
                            type="submit"
                            className="btn btn-primary"
                            value={this.props.intl.formatMessage({
                              id: "global.submit",
                              defaultMessage: "Save",
                            })}
                            disabled={isSubmitting}
                          />
                        )}
                      </div>
                    </Form>
                  </div>
                </div>
              </Layout>
            );
          }}
        </Formik>
      </Auth>
    );
  }
}

export default withAuthContext(withRouter(injectIntl(Home)));
