import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import _ from "lodash";
import FieldRow from "../../shared/FieldRow";
import classNames from "classnames";
import { Accordion } from "react-bootstrap";
import Creatable from "react-select/creatable";
import { cities } from "../../../util/utils";
import Map from "../../shared/Map";

class BasicDetails extends Component {
  constructor() {
    super();
  }

  state = {
    basicDetailError: false,
    basicDetailsExpanded: false,
    errInspirageId: false,
    errBranchCode: false,
    errPhoneNum: false,
    countryCode: "SA",
    address: "",
  };

  componentWillReceiveProps({
    basicDetailError,
    errInspirageId,
    errBranchCode,
  }) {
    if (!_.isEqual(this.state.basicDetailError, basicDetailError)) {
      // this.setState({ basicDetailsExpanded: basicDetailError });
      this.setState({ basicDetailError });
    }

    if (!_.isEqual(this.state.errInspirageId, errInspirageId)) {
      this.setState({ errInspirageId });
      if (!basicDetailError) {
        this.setState({ basicDetailsExpanded: true });
      }
    }

    if (!_.isEqual(this.state.errBranchCode, errBranchCode)) {
      this.setState({ errBranchCode });
      if (!basicDetailError) {
        this.setState({ basicDetailsExpanded: true });
      }
    }
  }

  render() {
    const formikProps = this.props.formikProps;
    const { values, setFieldValue, setTouched, errors, touched } = formikProps;

    return (
      <Accordion.Item eventKey="0">
        <Accordion.Header
          style={{
            backgroundColor: this.state.basicDetailError ? "red" : "#555",
          }}
          onClick={() =>
            this.setState({
              basicDetailsExpanded: !this.state.basicDetailsExpanded,
            })
          }
        >
          <FormattedMessage
            id="branch.basicDetails"
            defaultMessage="Basic Details"
          />
          <span
            className={classNames("glyphicon", "pull-right", {
              "glyphicon-plus": !this.state.basicDetailsExpanded,
              "glyphicon-minus": this.state.basicDetailsExpanded,
            })}
            aria-hidden="true"
          />
        </Accordion.Header>
        <Accordion.Body>
          <FieldRow
            id="name"
            type="text"
            name="name"
            labelId="branch.name"
            placeholderId="branch.enterName"
            defaultLabel="Branch Name (English)"
            defaultPlaceholder="Enter branch name (English)"
            formikProps={formikProps}
            showRequiredFlag={true}
          />

          <FieldRow
            id="nameAr"
            type="text"
            name="nameAr"
            labelId="branch.nameArabic"
            placeholderId="branch.enterNameArabic"
            defaultLabel="Branch Name (Arabic)"
            defaultPlaceholder="Enter branch name (Arabic)"
            formikProps={formikProps}
            showRequiredFlag={true}
          />

          <div className="row">
            <div className="col-md-2 col-xs-12">
              <label className="form-label caps block">
                <FormattedMessage
                  id="shop.location"
                  defaultMessage="Location"
                />
              </label>
            </div>
            <div className="input-group col-md-8 col-xs-12">
              <Map
                initialLocation={{
                  lng: values.location[0],
                  lat: values.location[1]
                }}
                setFieldValue={setFieldValue}
              />
            </div>
          </div>

          <FieldRow
            id="address"
            type="textarea"
            name="address"
            labelId="branch.englishAddress"
            defaultLabel="Address (English)"
            placeholderId="branch.enterEnglishAddress"
            defaultPlaceholder="Enter address (English)"
            showRequiredFlag={true}
            formikProps={formikProps}
          />

          <FieldRow
            type="textarea"
            name="addressArabic"
            id="addressArabic"
            labelId="branch.arabicAddress"
            defaultLabel="Address (Arabic)"
            placeholderId="branch.enterArabicAddress"
            defaultPlaceholder="Enter address (Arabic)"
            showRequiredFlag={true}
            formikProps={formikProps}
          />

          <FieldRow
            type="text"
            name="addressLink"
            id="addressLink"
            labelId="branch.addressLink"
            defaultLabel="Address Link"
            placeholderId="branch.enterAddressLink"
            defaultPlaceholder="Enter address Link"
            showRequiredFlag={false}
            formikProps={formikProps}
          />

          <FieldRow
            type="text"
            name="phoneNumber"
            id="phoneNumber"
            labelId="branch.phoneNumber"
            defaultLabel="Phone Number"
            placeholderId="branch.enterPhoneNumber"
            defaultPlaceholder="Enter phone number"
            showRequiredFlag={true}
            formikProps={formikProps}
          />
          {this.state.errPhoneNum ? (
            <p className="text-warning">
              <FormattedMessage
                id="branch.errPhoneNum"
                defaultMessage="Phone number is already in use by another branch"
              />
            </p>
          ) : null}

          <FieldRow
            type="number"
            name="inspirageId"
            id="inspirageId"
            labelId="branch.inspirageId"
            defaultLabel="Inspirage ID"
            placeholderId="branch.enterInspirageId"
            defaultPlaceholder="Enter inspirage ID"
            showRequiredFlag={true}
            formikProps={formikProps}
          />
          {this.state.errInspirageId ? (
            <p className="text-warning">
              <FormattedMessage
                id="branch.errInspirageId"
                defaultMessage="Inspirage ID is already in use by other branch"
              />
            </p>
          ) : null}

          <FieldRow
            type="text"
            name="branchCode"
            id="branchCode"
            labelId="branch.branchCode"
            defaultLabel="Branch Code"
            placeholderId="branch.enterBranchCode"
            defaultPlaceholder="Enter Branch Code"
            showRequiredFlag={true}
            formikProps={formikProps}
          />
          {this.state.errBranchCode ? (
            <p className="text-warning">
              <FormattedMessage
                id="branch.errBranchCode"
                defaultMessage="Branch Code is already in use by other branch"
              />
            </p>
          ) : null}

          <div className="row">
            <div className="col-md-2 col-xs-12">
              <label
                className={classNames("form-label caps block", {
                  "require-flag": true,
                })}
              >
                <FormattedMessage id="car.City" defaultMessage="City" />
              </label>
            </div>
            <Creatable
              name="city"
              value={{
                value: values.city,
                label: values.city,
              }}
              className="input-group col-md-8 col-xs-12 custom-col-xs-12"
              options={cities?.map((c) => {
                return {
                  value: c.id,
                  label: c.name,
                };
              })}
              onChange={(e) => {
                if (_.isEmpty(e) || _.isNull(e)) {
                  return;
                }
                setFieldValue("city", e.label);
                setTouched({ city: true });
              }}
            />
            {errors["city"] && touched["city"] && (
              <div className="row">
                <div className="col-md-2 col-xs-12"></div>
                <p className="text-warning col-md-8 col-xs-12">
                  {errors["city"]}
                </p>
              </div>
            )}
          </div>

          <FieldRow
            id="cityNameAr"
            type="text"
            name="cityNameAr"
            labelId="branch.cityNameAr"
            placeholderId="branch.enterNameArabic"
            defaultLabel="City Name (Arabic)"
            defaultPlaceholder="Enter City name (Arabic)"
            formikProps={formikProps}
            showRequiredFlag={true}
          />
        </Accordion.Body>
      </Accordion.Item>
    );
  }
}

export default BasicDetails;
