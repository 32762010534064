import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import Layout from "../shared/Layout";

export class InternetError extends Component {
  render() {
    return (
      <Layout>
        <div className="dashboard-table-container card-layout">
          <h2 className="card-title">
            <FormattedMessage
              id="error.internetError"
              defaultMessage="Connection Error"
            />
          </h2>
          <p>
            <FormattedMessage
              id="error.internetErrorMsg"
              defaultMessage="Unable to connect to the server. Please check your internet connection and try again."
            />
          </p>
        </div>
      </Layout>
    );
  }
}

export default injectIntl(InternetError);
