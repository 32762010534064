import BaseService from "./baseService";
import axios from "axios";

class EstimationRequestServices extends BaseService {
  constructor() {
    super();
  }

  async updateEstimationRequest(data) {
    const formData = new FormData();
    for (let key in data) {
      formData.append(key, data[key]);
    }
    const result = await axios.put("/api/estimation-request/", formData);
    return result.data.response;
  }

  async getUserEstimations(phone) {
    const result = await axios.get("/api/user-estimation-request/" + phone);
    return result && result.data?.response;
  }

  async sendDataToQapter(data) {
    const result = await axios.put("/api/qapter", data);
    return result.data.response;
  }
}

export default new EstimationRequestServices();
