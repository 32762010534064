import React, { useState, useEffect } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import withRouter from "../../util/withRouter";
import Auth from "../shared/Auth";
import Layout from "../shared/Layout";
import HeaderTitle from "../shared/HeaderTitle";
import services from "../../services";
import { validateArabicLanguage, validateEnglishLanguage } from "./Helpers";
import { Button } from "react-bootstrap";

const CarOwnByDoc = ({
  intl,
  noLayout,
  match,
  serviceData,
  serviceId,
  setCurrentDocStatus,
  setCarOwnedBy,
  navigate,
  prevSelectedService,
}) => {
  const [loading, setLoading] = useState(false);
  const _service = {
    service: "",
  };
  const [docValues, setDocValues] = useState([
    {
      name: {
        value_en: "",
        value_ar: "",
      },
      ..._service,
    },
  ]);

  const [errorIndex, setErrorIndex] = useState([]);

  useEffect(() => {
    if (serviceId || prevSelectedService) {
      getCarOwnByData();
    }
  }, []);

  const getCarOwnByData = async (from = undefined) => {
    try {
      setLoading(true);
      const res = await services.getServiceCarOwnBy(
        serviceId || docValues[0]["service"] || prevSelectedService
      );
      if (!res?.response?.length) {
        setLoading(false);
        return;
      }

      setDocValues(res?.response);
      setCarOwnedBy(res?.response);
      if (from) setCurrentDocStatus("bookingAppointmentFor");

      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const movetoNextForm = async () => {
    try {
      let _errorIndex = [];

      const _docValues = [...docValues];

      docValues &&
        docValues.forEach((item, i) => {
          if (item.name.value_en === "" || item.name.value_ar === "") {
            _errorIndex = [..._errorIndex, i];
          }
        });
      setErrorIndex(_errorIndex);
      if (_errorIndex?.length) return;
      setLoading(true);
      const res = await services.saveCarOwnBy(
        _docValues,
        serviceId || prevSelectedService
      );

      if (
        res?.message === "success.createdSuccessfully" ||
        res?.message === "success.updatedSuccessfully" ||
        res?.message === "success.recordUpdatedSuccessfully"
      ) {
        setLoading(false);
        getCarOwnByData("savedFunc");
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const handleAddMoreFields = () => {
    if (docValues[0]?.service === "") {
      return setErrorIndex([0]);
    }
    const docServiceValue = {
      service: serviceId ? serviceId : docValues[0]?.service,
    };
    setDocValues([
      ...docValues,
      {
        name: {
          value_en: "",
          value_ar: "",
        },
        ...docServiceValue,
      },
    ]);
  };

  const handleRemoveField = (index) => {
    const _docValues = [...docValues];
    _docValues.splice(index, 1);
    setDocValues(_docValues);

    // const _index = errorIndex.indexOf(index);

    // if (_index !== -1) {
    //   errorIndex.splice(index, 1);
    // }
  };

  const handleChange = ({ field, index, setDocValues, docValues }) => {
    const { name: keyName, value } = field;
    let _docValues = [...docValues];

    if (keyName === "carOwnBy" || keyName === "service") {
      _docValues[index][keyName] = value;
    } else {
      _docValues[index]["name"][keyName] = value;
    }
    if (_docValues?.length > 1) {
      const service = _docValues[0]["service"];
      for (let i = 0; i < _docValues.length; i++) {
        _docValues[i]["service"] = service;
      }
    }
    setDocValues(_docValues);
  };

  return (
    <Auth requireAuth={true} roles={["sadmin"]}>
      <Layout noLayout={noLayout} loading={loading}>
        <HeaderTitle
          id="requiredDoc.listing"
          defaultMessage="Required Documents For Appointment Services"
        />
        <div className="dashboard-table-container card-layout">
          <div className="panel-primary">
            <header className="panel-title">
              {match.params.id && (
                <FormattedMessage
                  id="requiredDoc.updateCarOwnBy"
                  defaultMessage="Update Car Owned By"
                />
              )}
              {!match.params.id && (
                <FormattedMessage
                  id="requiredDoc.addCarOwnBy"
                  defaultMessage="Add Car Owned By"
                />
              )}
            </header>

            <div>
              {/* <Form> */}
              {docValues &&
                docValues.map((val, index) => {
                  return (
                    <div
                      className="service-status-wrapper dashboard-row"
                      key={index}
                    >
                      <div className="service-status-fields-wrapper">
                        <div className="row">
                          <div className="col-md-2 col-sm-2 col-xs-12">
                            <label>
                              <FormattedMessage
                                id="requiredDoc.englishName"
                                defaultMessage="English Name"
                              />
                              <span className="text-warning">*</span>
                            </label>
                          </div>
                          <div className="col-md-8 col-sm-8 col-xs-12">
                            <input
                              name="value_en"
                              type="text"
                              placeholder="Enter English Name"
                              value={val["name"]?.value_en}
                              onChange={(e) =>
                                handleChange({
                                  field: e.target,
                                  index,
                                  docValues,
                                  setDocValues,
                                })
                              }
                            />
                            <p className="text-warning">
                              {errorIndex.includes(index) &&
                              docValues[index]["name"]?.value_en.length <= 0 ? (
                                <FormattedMessage
                                  id="requiredDoc.englishField"
                                  defaultMessage="English Value is required"
                                />
                              ) : validateEnglishLanguage(
                                  docValues[index]["name"]?.value_en
                                ) ? null : (
                                <FormattedMessage
                                  id="requiredDoc.arabicCharsNotAllowed"
                                  defaultMessage="Arabic Characters are not allowed"
                                />
                              )}
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-2 col-sm-2 col-xs-12">
                            <label>
                              <FormattedMessage
                                id="requiredDoc.arabicName"
                                defaultMessage="Arabic Name"
                              />
                              <span className="text-warning">*</span>
                            </label>
                          </div>
                          <div className="col-md-8 col-sm-8 col-xs-12">
                            <input
                              className="ml10"
                              name="value_ar"
                              type="text"
                              placeholder="Enter Arabic Name"
                              value={val["name"]?.value_ar}
                              onChange={(e) =>
                                handleChange({
                                  field: e.target,
                                  index,
                                  docValues,
                                  setDocValues,
                                })
                              }
                            />
                            <p className="text-warning">
                              {errorIndex.includes(index) &&
                              docValues[index]["name"]?.value_ar.length <= 0 ? (
                                <FormattedMessage
                                  id="requiredDoc.arabicField"
                                  defaultMessage="Arabic Value is required"
                                />
                              ) : validateArabicLanguage(
                                  docValues[index]["name"]?.value_ar
                                ) ? null : (
                                <FormattedMessage
                                  id="requiredDoc.englishCharsNotAllowed"
                                  defaultMessage="English Characters are not allowed"
                                />
                              )}
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-2 col-sm-2 col-xs-12">
                            <label>
                              <FormattedMessage
                                id="requiredDoc.service"
                                defaultMessage="Service"
                              />
                              <span className="text-warning">*</span>
                            </label>
                          </div>
                          <div className="col-md-8 col-sm-8 col-xs-12">
                            <select
                              id="selectOption"
                              name="service"
                              type="select"
                              value={docValues[index]["service"]}
                              onChange={(e) => {
                                // if (serviceId) return;
                                handleChange({
                                  field: e.target,
                                  index,
                                  docValues,
                                  setDocValues,
                                });
                              }}
                              disabled={match.params.id}
                            >
                              <option value="">-- Select --</option>
                              {serviceData?.length > 0 &&
                                serviceData?.map((service, index) => (
                                  <option value={service._id} key={index}>
                                    {service.name.value_en}
                                  </option>
                                ))}
                            </select>
                            <p className="text-warning">
                              {errorIndex.includes(index) &&
                              docValues[index].service.length <= 0 ? (
                                <FormattedMessage
                                  id="requiredDoc.serviceField"
                                  defaultMessage="Service is required"
                                />
                              ) : null}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="btn-box">
                        {docValues?.length - 1 === index && (
                          <Button
                            variant="secondary"
                            onClick={handleAddMoreFields}
                          >
                            {intl.formatMessage({
                              id: "global.add",
                              defaultMessage: "Add",
                            })}
                          </Button>
                        )}
                        {docValues?.length !== 1 && (
                          <Button
                            variant="secondary"
                            onClick={() => handleRemoveField(index)}
                          >
                            {intl.formatMessage({
                              id: "global.remove",
                              defaultMessage: "Remove",
                            })}
                          </Button>
                        )}
                      </div>
                    </div>
                  );
                })}
              {/* </Form> */}
              <div className="dashboard-row -nobottom">
                <Button
                  type="primary"
                  onClick={movetoNextForm}
                  style={{ margin: "0 10px" }}
                >
                  {intl.formatMessage({
                    id: "global.next",
                    defaultMessage: "Next",
                  })}
                </Button>
                <Button
                  variant="link"
                  onClick={() => {
                    navigate("/required-documents");
                    // setNewDoc(false);
                    // setShowDocs(false);
                  }}
                >
                  {intl.formatMessage({
                    id: "global.cancel",
                    defaultMessage: "Cancel",
                  })}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </Auth>
  );
};

export default React.memo(withRouter(injectIntl(CarOwnByDoc)));
