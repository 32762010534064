import React, { useState, useEffect, useRef } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Form, Formik } from "formik";
import { Yup } from "../../util";
import Layout from "../shared/Layout";
import Services from "../../services";
import { NotificationManager } from "react-notifications";
import _ from "lodash";
import Auth from "../shared/Auth";
import FieldRow from "../shared/FieldRow";
import withRouter from "../../util/withRouter";
import HeaderTitle from "../shared/HeaderTitle";
import { isLtr } from "../../util/utils";

const ChangeWebPassword = (props) => {
  const { intl, noLayout } = props;

  const formik = useRef();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    formik.current.validateForm();
  }, [isLtr()]);

  const handleSubmit = async (values, { setSubmitting }) => {
    setLoading(true);
    try {
      await Services.changeWebPassword({ ...values });

      NotificationManager.success(
        intl.formatMessage({
          id: "global.operationSuccessful",
          defaultMessage: "Operation Successful",
        }),
        intl.formatMessage({
          id: "global.success",
          defaultMessage: "Success",
        })
      );

      formik.current.resetForm();
    } catch (e) {
      NotificationManager.error(
        intl.formatMessage({
          id: e?.response?.data?.response || "error.unexpectedError",
          defaultMessage: "An unexpected error has occurred",
        }),
        intl.formatMessage({
          id: "global.error",
          defaultMessage: "Error",
        })
      );
    } finally {
      setSubmitting(false);
      setLoading(false);
    }
  };

  function equalTo(ref, msg) {
    return this.test({
      name: "equalTo",
      exclusive: false,
      message: msg || "${path} must be the same as ${reference}",
      params: {
        reference: ref.path,
      },
      test: function (value) {
        return value === this.resolve(ref);
      },
    });
  }

  Yup.addMethod(Yup.string, "equalTo", equalTo);

  return (
    <Auth requireAuth={true} roles={["sadmin", "wsadmin", "spadmin"]}>
      <Formik
        innerRef={formik}
        initialValues={{
          existingPassword: "",
          newPassword: "",
          confirmPassword: "",
        }}
        validationSchema={Yup.object().shape({
          existingPassword: Yup.string()
            .label(
              intl.formatMessage({
                id: "profile.existingPassword",
                defaultMessage: "Existing password",
              })
            )
            .matches(
              /^(?=.*[0-9])(?=.*[!@#$%^&*/._(){}:";,=?-])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*/._(){}:";,=?-]{8,}$/,
              intl.formatMessage({
                id: "profile.passwordAlphanumeric",
                defaultMessage:
                  "Password must be at least 8 characters long and include at least one letter, one number, and one special character.",
              })
            )
            .required(),
          newPassword: Yup.string()
            .label(
              intl.formatMessage({
                id: "profile.newPassword",
                defaultMessage: "New password",
              })
            )
            .trim()
            .matches(
              /^(?=.*[0-9])(?=.*[!@#$%^&*/._(){}:";,=?-])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*/._(){}:";,=?-]{8,}$/,
              intl.formatMessage({
                id: "profile.passwordAlphanumeric",
                defaultMessage:
                  "Password must be at least 8 characters long and include at least one letter, one number, and one special character.",
              })
            )
            .required(),
          confirmPassword: Yup.string()
            .label(
              intl.formatMessage({
                id: "profile.confirmPassword",
                defaultMessage: "Confirm password",
              })
            )
            .equalTo(
              Yup.ref("newPassword"),
              intl.formatMessage({
                id: "profile.confirmPasswordShouldMatchNewPassword",
                defaultMessage: "Confirm password should match new password",
              })
            )
            .required()
            .matches(
              /^(?=.*[0-9])(?=.*[!@#$%^&*/._(){}:";,=?-])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*/._(){}:";,=?-]{8,}$/,
              intl.formatMessage({
                id: "profile.passwordAlphanumeric",
                defaultMessage:
                  "Password must be at least 8 characters long and include at least one letter, one number, and one special character.",
              })
            ),
        })}
        onSubmit={handleSubmit}
      >
        {(formikProps) => {
          const { isSubmitting } = formikProps;
          return (
            <Layout noLayout={noLayout} loading={loading}>
              <HeaderTitle
                id="menu.changePassword"
                defaultMessage="Change Password"
              />
              <div className="dashboard-table-container card-layout">
                <div className="row">
                  <div className="col-md-12">
                    <div className="panel-primary">
                      <header className="panel-title">
                        <FormattedMessage
                          id="menu.changeWebPassword"
                          defaultMessage="Change Web Password"
                        />
                      </header>

                      <Form>
                        <div className="dashboard-row">
                          <div className="emoji-wrapper">
                            <FieldRow
                              type="password"
                              name="existingPassword"
                              labelId="profile.existingPassword"
                              defaultLabel="Existing Password"
                              placeholderId="profile.enterExistingPassword"
                              defaultPlaceholder="Enter existing password"
                              showRequiredFlag={true}
                              formikProps={formikProps}
                              showPasswordEyeIcon={true}
                            />
                          </div>
                          <div className="emoji-wrapper">
                            <FieldRow
                              type="password"
                              name="newPassword"
                              labelId="profile.newPassword"
                              defaultLabel="New Password"
                              placeholderId="profile.enterNewPassword"
                              defaultPlaceholder="Enter new password"
                              showRequiredFlag={true}
                              formikProps={formikProps}
                              showPasswordEyeIcon={true}
                            />
                          </div>
                          <div className="emoji-wrapper">
                            <FieldRow
                              type="password"
                              name="confirmPassword"
                              labelId="profile.confirmNewPassword"
                              defaultLabel="Confirm New Password"
                              placeholderId="profile.enterConfirmPassword"
                              defaultPlaceholder="Confirm new password"
                              showRequiredFlag={true}
                              formikProps={formikProps}
                              showPasswordEyeIcon={true}
                            />
                          </div>
                        </div>
                        <div className="dashboard-row -nobottom">
                          <input
                            type="submit"
                            className="btn btn-primary"
                            value={intl.formatMessage({
                              id: "global.submit",
                              defaultMessage: "Submit",
                            })}
                            disabled={isSubmitting}
                          />
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </Layout>
          );
        }}
      </Formik>
    </Auth>
  );
};

export default withRouter(injectIntl(ChangeWebPassword));
