import React, { useState, useEffect } from "react";
import ExportExcelButton from "./ExportExcelButton";
import CustomModal from "./CustomModal";
import { FormattedMessage } from "react-intl";
import { DateRangePicker } from "react-dates";
import moment from "moment";
import appState from "../../state/AppStateContainer";
import { Button } from "react-bootstrap";

const ExportToExcel = ({ resetFilter, onExport, toShowCheckbox = false }) => {
  const [showModal, setShowModal] = useState(false);
  const [startDate, setStartDate] = useState(undefined);
  const [endDate, setEndDate] = useState(undefined);
  const [focusedInput, setFocusedInput] = useState(undefined);
  const [isAppointmentDateFilter, setAppointmentDateFilter] = useState(false);

  useEffect(() => {
    const subscription = appState.emitter.addListener(
      "onLanguageChange",
      () => {
        if (startDate)
          setStartDate(moment(startDate).locale(appState.state.language));
        if (endDate) {
          setEndDate(moment(endDate).locale(appState.state.language));
        }
      }
    );
    return () => subscription.remove();
  }, [startDate, endDate]);

  const handleModal = (visible) => {
    setShowModal(visible);
  };

  const onDatesChange = ({ startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const isOutsideRange = (day) => {
    if (isAppointmentDateFilter) {
      // const endDateRange = moment(startDate).add(29, "days").endOf("day");

      if (focusedInput == "endDate") {
        return day.isAfter(moment().endOf("day"));
      }
      // if (focusedInput == "startDate") {
      //   // setEndDate(undefined);
      //   return day.isAfter(moment(new Date()).add(1, "day").startOf("day"));
      // }
    }

    return false;
  };

  return (
    <div>
      <ExportExcelButton
        // disabledButton={disabledButton}
        onClickButton={() => {
          handleModal(true);
        }}
      />
      <CustomModal
        show={showModal}
        handleClose={() => {
          handleModal(false);
        }}
        title={
          <FormattedMessage
            id="global.exportExcelData"
            defaultMessage="Export Data"
          />
        }
        footer={
          <>
            <div className="panel-group-buttons pull-right">
              <a
                className="btn btn-special"
                disabled={!startDate ? true : false}
                onClick={() => {
                  if (!startDate) return;
                  handleModal(false);
                  onExport(startDate, endDate, isAppointmentDateFilter);
                }}
                href="#disable"
              >
                <span className="fa fa-file-excel-o" />{" "}
                <FormattedMessage
                  id="global.exportButton"
                  defaultMessage="Export"
                />
              </a>
            </div>
          </>
        }
      >
        <div>
          <p className="export-excel-group">
            <FormattedMessage
              id="global.exportExcelNote"
              defaultMessage="Note: Only 30 days of data will be exported"
            />
          </p>
        </div>
        {toShowCheckbox && (
          <div>
            <div className="appointmentFilter">
              <label htmlFor="appointmentFilter">
                <FormattedMessage
                  id="global.appointmentCreatedFilter"
                  defaultMessage="Appointment created filter"
                />
              </label>
              <input
                type="checkbox"
                id="appointmentFilter"
                checked={isAppointmentDateFilter}
                onChange={(e) => setAppointmentDateFilter(e?.target?.checked)}
              />
            </div>
          </div>
        )}
        <div className="row container">
          <DateRangePicker
            startDate={startDate}
            startDateId="your_unique_start_date_id"
            endDate={endDate}
            endDateId="your_unique_end_date_id"
            onDatesChange={onDatesChange}
            isRTL={appState.state.language === "ar" ? true : false}
            readOnly={true}
            focusedInput={focusedInput}
            onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
            isOutsideRange={isOutsideRange}
          />{" "}
          <div className="tooltip-master">
            <Button
              onClick={() => {
                setStartDate(undefined);
                setEndDate(undefined);
              }}
            >
              <i
                className="material-icons"
                style={{ transform: "translateY(2px)" }}
              >
                settings_backup_restore
              </i>
            </Button>
            <span className="tooltiptext">
              <FormattedMessage
                id="global.resetFilter"
                defaultMessage="Reset Filter"
              />
            </span>
          </div>
        </div>
      </CustomModal>
    </div>
  );
};

export default ExportToExcel;
