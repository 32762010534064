import React, { Component } from "react";
import _ from "lodash";
import "react-dates/initialize";
import { SingleDatePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import appState from "../../state/AppStateContainer";

export default class DatePickerSingle extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleChange = (e) => {
    const { name, formikprops } = this.props;

    const touchedConfig = {};
    touchedConfig[name] = true;
    formikprops.setTouched(touchedConfig);
    formikprops.setFieldValue(name, e);
  }

  render() {
    const {
      startFromPlaceholderText,
      name,
      formikprops,
      initialStartFrom,
      initialExpirydate,
    } = this.props;

    return (
      <React.Fragment>
        <SingleDatePicker
          id="fromDate"
          placeholder={startFromPlaceholderText}
          // block={true}
          // small={true}
          isOutsideRange={(day) => day.isBefore(new Date().setHours(0,0,0,0))}
          numberOfMonths={1}
          initialDate={initialStartFrom || initialExpirydate}
          isRTL={appState.state.language === "ar" ? true : false}
          date={this.state.startFrom || initialStartFrom || initialExpirydate}
          onDateChange={(date) => {
            this.setState({ startFrom: date });
            const touchedConfig = {};
            touchedConfig[name] = true;
            formikprops.setTouched(touchedConfig);
            formikprops.setFieldValue(name, date);
          }}
          focused={this.state.focusedFrom}
          onFocusChange={({ focused }) =>
            this.setState({ focusedFrom: focused })
          }
        />
      </React.Fragment>
    );
  }
}
