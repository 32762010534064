import React, { useState, useRef, useEffect } from "react";
import { FormattedMessage, injectIntl, FormattedNumber } from "react-intl";
import Layout from "../shared/Layout";
import services from "../../services/subscriptionService";
import _ from "lodash";
import Auth from "../shared/Auth";
import withRouter from "../../util/withRouter";
import HeaderTitle from "../shared/HeaderTitle";
import CheckAccess from "../shared/CheckAccess";
import { ActivitiesEnum } from "../../staticData/Activities";
import SortableComponent from "../../shared/DragAndDropTable";
import { arrayMove } from "react-sortable-hoc";
import { Link } from "react-router-dom";

const SubscriptionList = (props) => {
  const { intl } = props;

  const [data, setData] = useState([]);
  const [disableFlag, setDisableFlag] = useState(true);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const searchInputRef = useRef();

  useEffect(() => {
    loadMore();
  }, []);

  const loadMore = async (searchFilter = "") => {
    try {
      setLoading(true);
      const options = {
        progressBar: true,
        alphabeticalOrder: true,
        searchFilter,
      };

      const pageData = await services.getSubscriptions(options);
      setCount(pageData?.length || 0);
      setData(pageData);
      setDisableFlag(false);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const resetData = (e) => {
    setData([]);
    setDisableFlag(true);
    if (e) e.preventDefault();
    loadMore(searchInputRef.current.value);
  };

  const resetPage = (e) => {
    setData([]);

    setDisableFlag(true);

    searchInputRef.current.value = "";
    e.preventDefault();
    loadMore();
  };

  const updateSubscriptionsSorting = async (oldIndex, newIndex) => {
    try {
      const updatedData = arrayMove(data, oldIndex, newIndex);
      updatedData.map((item, i) => {
        item.order = i;
      });
      setData(updatedData);
      setLoading(true);
      await services.updateSubscriptionsSorting(updatedData);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Auth requireAuth={true} roles={["sadmin"]}>
      <Layout hidden={props.match.params.isFormOpen} loading={loading}>
        <HeaderTitle
          id="subscription.subscription"
          defaultMessage="Subscription Packages"
        />
        <div className="main-container">
          <CheckAccess activityId={ActivitiesEnum.subscription_filter_search}>
            <div className="table-header clearfix ">
              <form className="row" onSubmit={resetData}>
                <div className="col-xs-3 pull-right"></div>
                <div className="col-md-2 col-sm-4 col-xs-6">
                  <div className="has-feedback">
                    <input
                      type="text"
                      ref={searchInputRef}
                      className="form-control"
                      // value={this.searchValueInput}
                      placeholder={intl.formatMessage({
                        id: "global.search",
                        defaultMessage: "Search",
                      })}
                    />
                    <i className="glyphicon glyphicon-search form-control-feedback" />
                  </div>
                </div>
                <div className="col force--inline">
                  <div className="tooltip-master">
                    <button
                      // href="javascript:;"
                      className="btn btn-primary"
                      onClick={resetData}
                      disabled={disableFlag}
                    >
                      <i className="material-icons">search</i>
                    </button>
                    <span className="tooltiptext">
                      <FormattedMessage
                        id="global.filter"
                        defaultMessage="Filter"
                      />
                    </span>
                  </div>
                </div>
                <div className="col force--inline">
                  <div className="tooltip-master">
                    <button
                      // href="javascript:;"
                      className="btn btn-primary"
                      onClick={resetPage}
                      disabled={disableFlag}
                    >
                      <i className="material-icons">settings_backup_restore</i>
                    </button>
                    <span className="tooltiptext">
                      <FormattedMessage
                        id="global.resetFilter"
                        defaultMessage="Reset Filter"
                      />
                    </span>
                  </div>
                </div>
              </form>
            </div>
          </CheckAccess>

          <div className="row ">
            <div className="col-md-12">
              <FormattedMessage
                id="global.totalCount"
                defaultMessage="Total Count"
              />{" "}
              <strong>
                <FormattedNumber
                  style="decimal"
                  maximumFractionDigits={0}
                  minimumFractionDigits={0}
                  value={count || 0}
                />
              </strong>
            </div>
          </div>

          <CheckAccess activityId={ActivitiesEnum.subscription_new_package}>
            <div className="row ">
              <div className="col-xs-3 pull-right">
                <div className="panel-group-buttons pull-right">
                  <Link className="btn btn-special " to="/subscription">
                    <span className="fa fa-plus" />{" "}
                    <FormattedMessage
                      id="subscription.create"
                      defaultMessage="Create Subscription"
                    />
                  </Link>
                </div>
              </div>
            </div>
          </CheckAccess>

          <div className="dashboard-table-container card-layout">
            <div className="table-responsive">
              <div className="custom-table">
                <div className="custom-table-header">
                  <div>
                    <FormattedMessage
                      id="subscription.subscriptionName"
                      defaultMessage="Subscription Name"
                    />
                  </div>
                  <div>
                    <FormattedMessage
                      id="subscription.price"
                      defaultMessage="Subscription Price"
                    />
                  </div>
                  <div>
                    <FormattedMessage
                      id="subscription.quota"
                      defaultMessage="Subscription Quota"
                    />
                  </div>
                  <div>
                    <FormattedMessage
                      id="subscription.isActive"
                      defaultMessage="Active"
                    />
                  </div>
                  <div>
                    <FormattedMessage
                      id="subscription.createdAt"
                      defaultMessage="Created At"
                    />
                  </div>
                </div>
                {!data && data?.length <= 0 ? (
                  <div
                    style={{
                      captionSide: "bottom",
                      textAlign: "center",
                      margin: "20px",
                      color: "darkgray",
                    }}
                  >
                    <FormattedMessage
                      id="global.noData"
                      defaultMessage="No data available"
                    />
                  </div>
                ) : (
                  <div className="main-container">
                    <SortableComponent
                      data={data}
                      _setData={setData}
                      props={props}
                      updateSorting={updateSubscriptionsSorting}
                      from="subscriptions"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </Auth>
  );
};

export default withRouter(injectIntl(SubscriptionList));
