import React, { Component } from "react";
import { Button } from "react-bootstrap";
import _ from "lodash";
import { NotificationManager } from "react-notifications";
import { FormattedMessage } from "react-intl";
import CropModal from "./CropModal";
import imageCompression from "browser-image-compression";

export default class FileInput extends Component {
  state = {
    isCropping: false,
    isImage: true,
  };
  render() {
    const {
      placeholder,
      name,
      fileicon = false,
      cameraicon = false,
      formikprops,
      disabled,
      image = false,
      legaldocumentation,
      inputClassName,
      spregistrationlogo,
      crop = true,
      aspectratio = 1,
      allowfiles,
      selfregistration,
      ispdf = false,
      forcarousel,
      forservices,
      disabledfile = false,
    } = this.props;
    return (
      <React.Fragment>
        <input
          accept={allowfiles}
          type="file"
          ref={(f) => (this.innerInput = f)}
          style={{
            width: "0.1px",
            height: "0.1px",
            opacity: 0,
            overflow: "hidden",
            position: "absolute",
            zIndex: -1,
          }}
          id={name}
          disabled={disabled}
          className={`${inputClassName} form-control`}
          placeholder={placeholder}
          name={name}
          onClick={(event) => {
            event.target.value = null;
          }}
          onChange={async (e) => {
            let fileData = e.currentTarget.files[0];

            if (!legaldocumentation && image && fileData) {
              if (
                fileData.type !== "image/bmp" &&
                fileData.type !== "image/jpg" &&
                fileData.type !== "image/jpeg" &&
                fileData.type !== "image/png" &&
                fileData.type !== "image/webp" &&
                fileData.type !== "image/svg+xml"
              ) {
                this.setState({
                  isImage: false,
                });
                if (selfregistration) {
                  formikprops.setFieldError(
                    name,
                    <FormattedMessage
                      id="auth.onlyImageIsAllowed"
                      defaultMessage="Only Image upload is allowed"
                    />
                  );
                  return;
                } else {
                  NotificationManager.error(
                    <FormattedMessage
                      id="auth.onlyImageIsAllowed"
                      defaultMessage="Only Image upload is allowed"
                    />
                  );
                  return;
                }
              }

              const imageSize = fileData.size / 1024 / 1024;
              if (imageSize > 100)
                return NotificationManager.error(
                  <FormattedMessage
                    id="auth.thisFileIsNotAllowed"
                    defaultMessage="File size is too large"
                  />
                );
                
              if (imageSize > 1) {
                const options = {
                  maxSizeMB: imageSize / 2,
                  maxWidthOrHeight: 2000,
                  useWebWorker: true,
                };
                fileData = await imageCompression(fileData, options);
              }
              if (crop) {
                const reader = new FileReader();
                reader.addEventListener("load", () =>
                  this.setState({
                    cropImageSrc: reader.result,
                    cropFilename: fileData.name,
                    cropFileType: fileData.type,
                    isCropping: true,
                  })
                );
                reader.readAsDataURL(fileData);
                return;
              }
            }
            if (legaldocumentation) {
              const fileData = e.currentTarget.files[0];

              if (fileData && fileData.size / 1048576.0 > 10) {
                NotificationManager.error(
                  <FormattedMessage
                    id="auth.thisFileIsNotAllowed"
                    defaultMessage="File size is too large"
                  />
                );
                return;
              }
              if (ispdf && fileData && fileData.type !== "application/pdf") {
                NotificationManager.error(
                  <FormattedMessage
                    id="auth.onlyPdfAllowed"
                    defaultMessage="Only Pdf allowed"
                  />
                );
                return;
              }
              if (
                !ispdf &&
                fileData &&
                fileData.type !== "text/plain" &&
                fileData.type !==
                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document" &&
                fileData.type !== "application/msword" &&
                fileData.type !== "application/pdf" &&
                fileData.type !== "image/jpg" &&
                fileData.type !== "image/jpeg" &&
                fileData.type !== "image/png"
              ) {
                NotificationManager.error(
                  <FormattedMessage
                    id="auth.thisFileIsNotAllowed"
                    defaultMessage="This File is not allowed"
                  />
                );
                return;
              }
            }
            const touchedConfig = {};
            touchedConfig[name] = true;
            formikprops.setTouched(touchedConfig);
            formikprops.setFieldValue(name, e.currentTarget.files[0]);
            this.setState({
              isCropping: crop && !legaldocumentation ? true : false,
            });
          }}
        />
        <span className="img-btn-wrapper">
          {spregistrationlogo && !fileicon && !cameraicon && !disabled && (
            <label
              ref={(r) => (this._lblCamera = r)}
              style={{ margin: "0 10px 0 0" }}
              htmlFor={name}
              disabled={disabled}
              className="btn btn-success fileChooseBtn"
            >
              <span className="fa fa-camera" />
            </label>
          )}
          {!spregistrationlogo && !fileicon && !cameraicon && !disabled && (
            <Button
              className="fileChooseBtn"
              style={{ marginRight: 10 }}
              bsStyle="success"
            >
              <label style={{ margin: 0 }} htmlFor={name} disabled={disabled}>
                {fileicon && <span className="fa fa-file-image-o" />}

                {/* {(fileicon === false &&
                  _.get(this.innerInput, "files[0].name")) ||
                  formikprops.values[name].slice(
                    37,
                    formikprops.values[name].length
                  ) || (
                    <FormattedMessage
                      id="global.chooseFile"
                      defaultMessage="Choose File"
                    />
                  )} */}
                {(fileicon === false &&
                  (formikprops.values[name].name ||
                    formikprops.values[name])) || (
                  <FormattedMessage
                    id="global.chooseFile"
                    defaultMessage="Choose File"
                  />
                )}
              </label>
            </Button>
          )}
          {!spregistrationlogo && fileicon && disabled && (
            <label
              ref={(r) => (this._lblCamera = r)}
              style={{ margin: "0 10px 0 0" }}
              htmlFor={name}
              disabled={disabled}
              className="btn btn-success fileChooseBtn"
            >
              {fileicon && <span className="fa fa-file-image-o" />}
            </label>
          )}
          {!spregistrationlogo && fileicon && !disabled && (
            <label
              ref={(r) => (this._lblCamera = r)}
              style={{ margin: "0 10px 0 0" }}
              htmlFor={name}
              disabled={disabledfile}
              className="btn btn-success fileChooseBtn"
            >
              {fileicon && <span className="fa fa-file-image-o" />}
            </label>
          )}
          {!spregistrationlogo && cameraicon && !disabled && (
            <label
              ref={(r) => (this._lblCamera = r)}
              style={{ margin: "0 10px 0 0" }}
              htmlFor={name}
              disabled={disabled}
              className="btn btn-success fileChooseBtn"
            >
              {cameraicon && <span className="fa fa-camera" />}
            </label>
          )}
          {formikprops.values[name] &&
            !disabled &&
            !ispdf &&
            formikprops.values[name] !==
              "/design/images/carhub_placeholder.png" && (
              <>
                {formikprops.values[name] instanceof File && (
                  <a
                    href={URL.createObjectURL(formikprops.values[name])}
                    target="_blank"
                    className="btn btn-success"
                    style={{ margin: "0 10px 0 0" }}
                  >
                    <span className="fa fa-eye" />
                  </a>
                )}
                <label
                  onClick={() => {
                    this.innerInput.value = "";
                    const touchedConfig = {};
                    touchedConfig[name] = true;
                    formikprops.setTouched(touchedConfig);
                    formikprops.setFieldValue(name, "");
                  }}
                  ref={(r) => (this._lblCamera = r)}
                  htmlFor={name}
                  disabled={disabled}
                  className="btn btn-danger fileRemoveBtn"
                  style={{ margin: "0 10px 0 0" }}
                >
                  <span className="fa fa-trash" />
                </label>
              </>
            )}
          {this.state.isCropping && (
            <CropModal
              imageSrc={this.state.cropImageSrc}
              aspectratio={aspectratio}
              filename={this.state.cropFilename}
              fileType={this.state.cropFileType}
              forcearousel={forcarousel}
              forservices={forservices}
              onCancel={() =>
                this.setState({
                  isCropping: false,
                  cropImageSrc: null,
                  cropFilename: null,
                })
              }
              onSubmit={(blob) => {
                const touchedConfig = {};
                touchedConfig[name] = true;
                formikprops.setTouched(touchedConfig);
                formikprops.setFieldValue(name, blob);
                this.setState({
                  isCropping: false,
                  cropImageSrc: null,
                  cropFilename: null,
                });
              }}
            />
          )}
        </span>
      </React.Fragment>
    );
  }
}
