import React, { Component } from "react";
import Layout from "../shared/Layout";
import { FormattedMessage } from "react-intl";
import _ from "lodash";
class AccessDenied extends Component {
  render() {
    return (
      <Layout>
        <div className="dashboard-table-container card-layout">
          <h2 className="card-title">
            <FormattedMessage
              id="error.accessDenied"
              defaultMessage="Access Denied"
            />
          </h2>
          <p>
            <FormattedMessage
              id="error.youDonotHaveAccessToThisPage"
              defaultMessage="You don't have access to this page"
            />
          </p>
        </div>
      </Layout>
    );
  }
}

export default AccessDenied;
