import React, { useRef } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import withRouter from "../../util/withRouter";
import { Formik, Form } from "formik";
import { Yup } from "../../util";
import { Modal, Button } from "react-bootstrap";
import _ from "lodash";

const AppointmentCancelReasonModal = ({
  onSubmit,
  onCancel,
  show,
  intl,
  loading,
}) => {
  let formikRef = useRef();

  return (
    <>
      <Modal show={show} onHide={() => onCancel()} backdrop="static">
        <Modal.Header closeButton={true}>
          <h4>
            <FormattedMessage id="login.reason" defaultMessage="Reason" />
          </h4>
        </Modal.Header>
        <Modal.Body>
          <Formik
            ref={(f) => (formikRef = f)}
            innerRef={(f) => (formikRef = f)}
            validationSchema={Yup.object().shape(
              _({
                reason: Yup.string()
                  .label(
                    intl.formatMessage({
                      id: "global.reason",
                      defaultMessage: "Reason",
                    })
                  )
                  .required(),
              })
                .omitBy(_.isUndefined)
                .value()
            )}
            onSubmit={(values) => onSubmit(values)}
            initialValues={{
              reason: "",
            }}
            render={({ values, handleChange, touched, errors }) => {
              return (
                <Form>
                  <div className="admin-change-password-wrapper">
                    <FormattedMessage
                      id="global.reasonDescription"
                      defaultMessage="Please provide us the reason"
                    />
                    <textarea
                      name="reason"
                      rows="5"
                      autoComplete="off"
                      value={values.reason}
                      onChange={handleChange}
                      className="form-control"
                    ></textarea>

                    {touched["reason"] && errors["reason"] && (
                      <p className="text-warning">{errors["reason"]}</p>
                    )}
                  </div>

                  <br />
                </Form>
              );
            }}
          />
        </Modal.Body>
        {!loading && (
          <Modal.Footer>
            <Button
              bsStyle="btn btn-primary"
              style={{ marginBottom: 0 }}
              onClick={() => formikRef.submitForm()}
            >
              <FormattedMessage id="global.submit" defaultMessage="submit" />
            </Button>
            <Button bsStyle="link" onClick={() => onCancel()}>
              <FormattedMessage id="global.cancel" defaultMessage="Cancel" />
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
};

export default React.memo(withRouter(injectIntl(AppointmentCancelReasonModal)));
