import React from "react";

const AuthContext = React.createContext({ authorized: false });

export const withAuthContext = Component => {
  return props => (
    <AuthContext.Consumer>
      {value => <Component {...props} authContext={value} />}
    </AuthContext.Consumer>
  );
};

export default AuthContext;
