import axios from "axios";
import BaseService from "./baseService";

class PaymentService extends BaseService {
  // Tamara payment method
  async verifyTamaraPayment(orderId) {
    const result = await axios.get(`/api/tamara-verify/${orderId}`);
    return result && result.data?.response;
  }
}

export default new PaymentService();
