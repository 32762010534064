import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Form, Formik } from "formik";
import { Yup } from "../../util";
import Layout from "../shared/Layout";
import _ from "lodash";
import Auth from "../shared/Auth";
import FieldRow from "../shared/FieldRow";
import withRouter from "../../util/withRouter";
import appState from "../../state/AppStateContainer";
import HeaderTitle from "../shared/HeaderTitle";
import { withAuthContext } from "../../state/AuthContext";
import careemPromoServices from "../../services/careemPromoServices";
import "react-quill/dist/quill.snow.css";
import { Button } from "react-bootstrap";

const CareemPromoForm = ({ intl, match, history, navigate }) => {
  const formikRef = useRef(null);

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const handleLanguageChange = () => {
      formikRef.current.validateForm();
    };

    const fetchData = async () => {
      try {
        const options = { progressBar: true };
        if (match.params.id) {
          const careemPromoData = await careemPromoServices.getCareemPromo(
            match.params.id,
            options
          );
          setData(careemPromoData?.response);
          // let careemPromoPayload = [];
          // if (careemPromoData) {
          //   careemPromoData?.map((data) => {
          //     careemPromoPayload.push({
          //       careemPromoCode: data?.careemPromoCode,
          //       assignTo: data?.assignTo,
          //     });
          //   });
          // }
          // setRows(careemPromoData);
          setLoading(false);
        }
      } catch (e) {
        navigate("/error", {
          error: _.get(e, "response.data.error", "error.unexpectedError"),
        });
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [match.params.id, history]);

  // const handleSubmit = async (values, { setSubmitting }) => {
  //   try {
  //     // if (preventSubmit) return;
  //     if (formikRef?.current?.isSubmitting) return;
  //     setSubmitting(true);
  //     let response;
  //     const payload = {
  //       careemPromoCode: values.careemPromoCode,
  //       assignTo: values.assignTo,
  //     };
  //     if (!match.params.id) {
  //       response = await careemPromoServices.addCareemPromo(payload);
  //     } else {
  //       // updateCareemPromo
  //       // const request = { id: match.params.id, ...payload };
  //       // response = await careemPromoServices.updateCareemPromo(request);
  //     }

  //     NotificationManager.success(
  //       intl.formatMessage({
  //         id: "global.operationSuccessful",
  //         defaultMessage: "Operation Successful",
  //       }),
  //       intl.formatMessage({
  //         id: "global.success",
  //         defaultMessage: "Success",
  //       })
  //     );
  //     setInventory([]);

  //     navigate("/careem-promos", {
  //       isSubmitted: true,
  //     });
  //   } catch (e) {
  //     NotificationManager.error(
  //       intl.formatMessage({
  //         id: _.get(e, "response.data.error", "error.unexpectedError"),
  //         defaultMessage: "An unexpected error has occurred",
  //       }),
  //       intl.formatMessage({
  //         id: "global.error",
  //         defaultMessage: "Error",
  //       })
  //     );
  //   } finally {
  //     setSubmitting(false);
  //   }
  // };

  const renderBody = (formikProps) => {
    return (
      <Layout loading={loading}>
        <HeaderTitle
          id="careemPromo.careemPromo"
          defaultMessage="Careem Promo"
        />
        <div className="dashboard-table-container card-layout">
          <div className="panel-primary">
            <header className="panel-title">
              {match.params.id ? (
                <FormattedMessage
                  id="careemPromo.update"
                  defaultMessage="Update Careem Promo"
                />
              ) : (
                <FormattedMessage
                  id="careemPromo.add"
                  defaultMessage="Add Careem Promo"
                />
              )}
            </header>

            <div>
              <Form>
                <div className="dashboard-row">
                  <FieldRow
                    name="careemPromoCode"
                    type="text"
                    labelId="careemPromoCode.careemPromoCode"
                    defaultLabel="Careem Promo Code"
                    placeholderId="careemPromoCode.careemPromoCode"
                    defaultPlaceholder="Enter Careem Promo Code"
                    showRequiredFlag={true}
                    formikProps={formikProps}
                    disabled={true}
                  />
                  <FieldRow
                    name="status"
                    type="text"
                    labelId="careemPromoCode.status"
                    defaultLabel="Status"
                    placeholderId="careemPromoCode.status"
                    defaultPlaceholder="Status"
                    showRequiredFlag={true}
                    formikProps={formikProps}
                    disabled={true}
                  />
                </div>
                <div className="dashboard-row -nobottom">
                  {/* {authContext.authorized && (
                    <CheckAccess
                      activityId={
                        ActivitiesEnum.careem_promo__update_careem_promo_code_edit
                      }
                    >
                      <input
                        disabled={true}
                        type="submit"
                        className="btn btn-primary"
                        value={intl.formatMessage({
                          id: "global.submit",
                          defaultMessage: "Submit",
                        })}
                      />
                    </CheckAccess>
                  )} */}
                  <Button
                    variant="link"
                    onClick={() => navigate("/careem-promos")}
                  >
                    {intl.formatMessage({
                      id: "global.cancel",
                      defaultMessage: "Cancel",
                    })}
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </Layout>
    );
  };

  return (
    <Auth requireAuth={true} roles={["sadmin"]}>
      <Formik
        innerRef={formikRef}
        initialValues={{
          careemPromoCode: data.careemPromoCode || "",
          status: data.isConsumed ? "Sent" : "Unsent",
        }}
        validationSchema={Yup.object().shape({
          careemPromoCode: Yup.string().required(
            intl.formatMessage({
              id: "global.valueRequired",
              defaultMessage: "Required",
            })
          ),
          status: Yup.string().required(
            intl.formatMessage({
              id: "global.valueRequired",
              defaultMessage: "Required",
            })
          ),
        })}
        // onSubmit={handleSubmit}
        enableReinitialize
      >
        {(formikProps) => renderBody(formikProps)}
      </Formik>
    </Auth>
  );
};

export default withAuthContext(withRouter(injectIntl(CareemPromoForm)));
