import React, { useRef } from "react";
import { FormattedMessage } from "react-intl";
import { Formik, Form } from "formik";
import { Yup } from "../../util";
import { Button, Modal } from "react-bootstrap";
import FieldRow from "../shared/FieldRow";

const CloneServiceModal = ({ show, onCancel, onSubmit, loading }) => {
  const formRef = useRef();

  return (
    <Modal show={show} onHide={onCancel} centered>
      <Modal.Header closeButton={true}>
        <h4>
          <FormattedMessage
            id="service.cloneService"
            defaultMessage="Clone Service"
          />
        </h4>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={{
            name_en: "",
            name_ar: "",
          }}
          validationSchema={Yup.object().shape({
            name_en: Yup.string()
              .hasNoArabic(
                <FormattedMessage
                  id="error.arabicCharactersAreNotAllowedInThisField"
                  defaultMessage="Arabic characters are not allowed in this field"
                />
              )
              .required("Required"),
            name_ar: Yup.string()
              .hasNoEnglish(
                <FormattedMessage
                  id="error.englishCharactersAreNotAllowedInThisField"
                  defaultMessage="English characters are not allowed in this field"
                />
              )
              .required("Required"),
          })}
          onSubmit={onSubmit}
          innerRef={formRef}
        >
          {(formikProps) => {
            return (
              <Form>
                <FieldRow
                  type="text"
                  name="name_en"
                  labelId="service.englishName"
                  defaultLabel="Service Name (English)"
                  placeholderId="service.enterEnglishName"
                  defaultPlaceholder="Enter english name"
                  showRequiredFlag={true}
                  formikProps={formikProps}
                />
                <FieldRow
                  type="text"
                  name="name_ar"
                  labelId="service.arabicName"
                  defaultLabel="Service Name (Arabic)"
                  placeholderId="service.enterArabicName"
                  defaultPlaceholder="Enter arabic name"
                  showRequiredFlag={true}
                  formikProps={formikProps}
                />
              </Form>
            );
          }}
        </Formik>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="link"
          onClick={onCancel}
          disabled={loading}
        >
          <FormattedMessage id="global.cancel" defaultMessage="Cancel" />
        </Button>
        <Button
          type="submit"
          onClick={() => formRef.current.submitForm()}
          disabled={loading}
        >
          <FormattedMessage id="global.submit" defaultMessage="Submit" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CloneServiceModal;
