export let mixed = {
  default: "${path} is invalid",
  required: "${path} is required"
};

export let string = {
  required: "${path} is required"
};

export default {
  mixed,
  string
};
