import React, { useState, useEffect } from "react";
import { injectIntl } from "react-intl";
import withRouter from "../../util/withRouter";
import services from "../../services";
import BookingAppointmentFor from "./bookingAppointmentFor";
import CarOwnByDoc from "./carOwnByDoc";
import RequiredDocuments from "./requiredDocuments";
import "./style.css";
const RequiredDocumentForm = ({ match, location }) => {
  const [carOwnedBy, setCarOwnedBy] = useState([]);
  const [serviceData, setServiceData] = useState([]);
  const [serviceId, setServiceId] = useState(match.params.id || "");
  const [bookingAppointmentForData, setBookingAppointmentForData] = useState(
    []
  );

  const [currentDocStatus, setCurrentDocStatus] = useState("carOwnBy");
  const [prevSelectedService, setPrevSeletedService] = useState("");

  useEffect(() => {
    getCarOwnByData();
  }, []);

  const getCarOwnByData = async () => {
    try {
      let response;
      if (!serviceId) {
        response = await services.getCarOwnBy();
        response = response?.map((obj) => obj.service);
      }

      getServices(
        serviceId
          ? { servicesID: [serviceId], action: "updating" }
          : { servicesID: response }
      );
    } catch (err) {
      console.log(err);
    }
  };
  
  const getServices = async ({ servicesID, action = undefined }) => {
    try {
      const res = await services.getServicesV3(
        servicesID,
        action,
      );
      setServiceData(res);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      {currentDocStatus === "carOwnBy" && (
        <CarOwnByDoc
          {...{
            serviceData,
            serviceId,
            setCurrentDocStatus,
            setCarOwnedBy,
            prevSelectedService,
          }}
        />
      )}
      {currentDocStatus === "bookingAppointmentFor" && (
        <BookingAppointmentFor
          {...{
            serviceId,
            setCurrentDocStatus,
            serviceData,
            carOwnedBy,
            setBookingAppointmentForData,
            setPrevSeletedService,
            prevSelectedService,
          }}
        />
      )}
      {currentDocStatus === "requiredDocuments" && (
        <RequiredDocuments
          {...{
            serviceId,
            carOwnedBy,
            setCurrentDocStatus,
            bookingAppointmentForData,
            setPrevSeletedService,
            prevSelectedService,
          }}
        />
      )}
    </>
  );
};
export default React.memo(withRouter(injectIntl(RequiredDocumentForm)));
