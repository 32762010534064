import React, { useState, useEffect } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import withRouter from "../../util/withRouter";
import Auth from "../shared/Auth";
import Layout from "../shared/Layout";
import HeaderTitle from "../shared/HeaderTitle";
import services from "../../services";
import {
  handleChange,
  validateArabicLanguage,
  validateEnglishLanguage,
} from "./Helpers";
import { Button } from "react-bootstrap";

const RequiredDocuments = ({
  intl,
  noLayout,
  match,
  navigate,
  bookingAppointmentForData,
  carOwnedBy,
  serviceId,
  setCurrentDocStatus,
  setPrevSeletedService,
}) => {
  const [loading, setLoading] = useState(false);
  const _service = {
    service: serviceId ? serviceId : bookingAppointmentForData[0]?.service,
  };
  const [docValues, setDocValues] = useState([
    {
      name: {
        value_en: "",
        value_ar: "",
      },
      carOwnBy: "",
      bookingAppointmentFor: "",
      ..._service,
      subMenu: [],
    },
  ]);
  const [errorIndex, setErrorIndex] = useState([]);

  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    if (serviceId) {
      getCarOwnByData();
    }
  }, [isFetching]);

  const getCarOwnByData = async () => {
    try {
      setLoading(true);
      const res = await services.getServiceRequiredDocuments(serviceId);
      if (!res?.length) {
        setLoading(false);
        return;
      }

      setDocValues(res);

      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const movetoNextForm = async () => {
    try {
      let _errorIndex = [];
      const _docValues = [...docValues];
      _docValues &&
        _docValues.forEach((item, i) => {
          if (item.name.value_en === "" || item.name.value_ar === "") {
            _errorIndex = [..._errorIndex, i];
          }
          let _subMenu = [];

          _subMenu =
            item?.subMenu?.length &&
            item.subMenu.filter(
              (menu) =>
                menu.hasOwnProperty("name") &&
                menu?.name?.value_en?.length &&
                menu?.name?.value_ar?.length
            );
          if (!_subMenu?.length) {
            return (item["subMenu"] = []);
          }
          item["subMenu"] = _subMenu;
        });
      setErrorIndex(_errorIndex);
      if (_errorIndex?.length) return;

      const res = await services.saveRequiredDocuments(_docValues, serviceId);

      if (
        res === "success.createdSuccessfully" ||
        res === "success.updatedSuccessfully"
      ) {
        setIsFetching(true);
        setCurrentDocStatus("carOwnBy");
        navigate("/required-documents");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleAddMoreFields = () => {
    const docServiceValue = {
      service: serviceId ? serviceId : bookingAppointmentForData[0]?.service,
    };

    setDocValues([
      ...docValues,
      {
        name: {
          value_en: "",
          value_ar: "",
        },
        carOwnBy: "",
        bookingAppointmentFor: "",
        ...docServiceValue,
        subMenu: [],
      },
    ]);
  };
  const handleAddSubFields = (index) => {
    const _docValues = [...docValues];

    _docValues[index]["subMenu"] = [
      ..._docValues[index]["subMenu"],
      {
        name: {
          value_en: "",
          value_ar: "",
        },
        isDeleted: false,
      },
    ];
    setDocValues(_docValues);
  };

  const handleRemoveSubFields = ({ parentIndex, childIndex }) => {
    const _docValues = [...docValues];
    _docValues[parentIndex]["subMenu"].splice(childIndex, 1);
    setDocValues(_docValues);
  };

  const handleRemoveField = (index) => {
    const _docValues = [...docValues];
    _docValues.splice(index, 1);
    // _docValues.splice(index, 1);
    setDocValues(_docValues);

    // const _index = errorIndex.indexOf(index);

    // if (_index !== -1) {
    //   errorIndex.splice(index, 1);
    // }
  };

  const handleSubFieldChange = ({ field, parentIndex, childIndex }) => {
    const { name: keyName, value } = field;
    const _docValues = [...docValues];

    _docValues[parentIndex]["subMenu"][childIndex]["name"][keyName] = value;

    setDocValues(_docValues);
  };

  return (
    <Auth requireAuth={true} roles={["sadmin"]}>
      <Layout noLayout={noLayout} loading={loading}>
        <HeaderTitle
          id="requiredDoc.listing"
          defaultMessage="Required Documents For Appointment Services"
        />
        <div className="dashboard-table-container card-layout">
          <div className="panel-primary">
            <header className="panel-title">
              {match.params.id && (
                <FormattedMessage
                  id="requiredDoc.updateRequiredDocuments"
                  defaultMessage="Update Required Documents"
                />
              )}
              {!match.params.id && (
                <FormattedMessage
                  id="requiredDoc.addRequiredDocuments"
                  defaultMessage="Add Required Documents"
                />
              )}
            </header>

            <div>
              {docValues &&
                docValues.map((val, index) => {
                  return (
                    <div
                      className="service-status-wrapper dashboard-row"
                      key={index}
                    >
                      <div className="service-status-fields-wrapper">
                        <div className="row">
                          <div className="col-md-2 col-sm-2 col-xs-12">
                            <label>
                              <FormattedMessage
                                id="requiredDoc.englishName"
                                defaultMessage="English Name"
                              />
                              <span className="text-warning">*</span>
                            </label>
                          </div>
                          <div className="col-md-8 col-sm-8 col-xs-12">
                            <input
                              name="value_en"
                              type="text"
                              placeholder="Enter English Name"
                              value={val["name"]?.value_en}
                              onChange={(e) =>
                                handleChange({
                                  field: e.target,
                                  index,
                                  docValues,
                                  setDocValues,
                                })
                              }
                            />
                            <p className="text-warning">
                              {errorIndex.includes(index) &&
                              docValues[index]["name"]?.value_en.length <= 0 ? (
                                <FormattedMessage
                                  id="requiredDoc.englishField"
                                  defaultMessage="English Value is required"
                                />
                              ) : validateEnglishLanguage(
                                  docValues[index]["name"]?.value_en
                                ) ? null : (
                                <FormattedMessage
                                  id="requiredDoc.arabicCharsNotAllowed"
                                  defaultMessage="Arabic Characters are not allowed"
                                />
                              )}
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-2 col-sm-2 col-xs-12">
                            <label>
                              <FormattedMessage
                                id="requiredDoc.arabicName"
                                defaultMessage="Arabic Name"
                              />
                              <span className="text-warning">*</span>
                            </label>
                          </div>
                          <div className="col-md-8 col-sm-8 col-xs-12">
                            <input
                              className="ml10"
                              name="value_ar"
                              type="text"
                              placeholder="Enter Arabic Name"
                              value={val["name"]?.value_ar}
                              onChange={(e) =>
                                handleChange({
                                  field: e.target,
                                  index,
                                  docValues,
                                  setDocValues,
                                })
                              }
                            />
                            <p className="text-warning">
                              {errorIndex.includes(index) &&
                              docValues[index]["name"]?.value_ar.length <= 0 ? (
                                <FormattedMessage
                                  id="requiredDoc.arabicField"
                                  defaultMessage="Arabic Value is required"
                                />
                              ) : validateArabicLanguage(
                                  docValues[index]["name"]?.value_ar
                                ) ? null : (
                                <FormattedMessage
                                  id="requiredDoc.englishCharsNotAllowed"
                                  defaultMessage="English Characters are not allowed"
                                />
                              )}
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-2 col-sm-2 col-xs-12">
                            <label>
                              <FormattedMessage
                                id="requiredDoc.carOwnBy"
                                defaultMessage="Car Own By"
                              />
                              <span className="text-warning">*</span>
                            </label>
                          </div>
                          <div className="col-md-8 col-sm-8 col-xs-12">
                            <select
                              id="selectOption"
                              type="select"
                              name="carOwnBy"
                              value={docValues[index]["carOwnBy"]}
                              onChange={(e) => {
                                handleChange({
                                  field: e.target,
                                  index,
                                  docValues,
                                  setDocValues,
                                });
                              }}
                            >
                              <option value="">-- Select --</option>
                              {carOwnedBy &&
                                carOwnedBy.map((val, index) => {
                                  // if (serviceId) {
                                  //   if (val?.service !== serviceId) return;
                                  // }
                                  return (
                                    <option value={val._id} key={index}>
                                      {val.name.value_en}
                                    </option>
                                  );
                                })}
                            </select>
                            <p className="text-warning">
                              {errorIndex.includes(index) &&
                              docValues[index].carOwnBy.length <= 0 ? (
                                <FormattedMessage
                                  id="requiredDoc.carOWnByField"
                                  defaultMessage="Car Owned by is required"
                                />
                              ) : null}
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-2 col-sm-2 col-xs-12">
                            <label>
                              <FormattedMessage
                                id="requiredDoc.bookingAppointmentFor"
                                defaultMessage="Booking Appointment For"
                              />
                              <span className="text-warning">*</span>
                            </label>
                          </div>
                          <div className="col-md-8 col-sm-8 col-xs-12">
                            <select
                              id="selectOption"
                              type="select"
                              name="bookingAppointmentFor"
                              value={docValues[index]["bookingAppointmentFor"]}
                              //   defaultValue={
                              //     docValues[index]["bookingAppointmentFor"]
                              //   }
                              onChange={(e) => {
                                handleChange({
                                  field: e.target,
                                  index,
                                  docValues,
                                  setDocValues,
                                });
                              }}
                            >
                              <option value="">-- Select --</option>
                              {bookingAppointmentForData &&
                                bookingAppointmentForData.map((obj, index) => {
                                  // if (serviceId) {
                                  //   if (obj?.service !== serviceId) return;
                                  // }
                                  return (
                                    <option value={obj._id} key={index}>
                                      {obj.name.value_en}
                                    </option>
                                  );
                                })}
                            </select>

                            <p className="text-warning">
                              {errorIndex.includes(index) &&
                              docValues[index].bookingAppointmentFor.length <=
                                0 ? (
                                <FormattedMessage
                                  id="requiredDoc.bookingAppointmentField"
                                  defaultMessage="Booking Appointment for is required"
                                />
                              ) : null}
                            </p>
                          </div>
                        </div>
                        <div className="sub-options-wrapper">
                          <div className="sub-options-head">
                            <label>
                              <FormattedMessage
                                id="requiredDoc.subMenu"
                                defaultMessage="Sub Options"
                              />
                            </label>
                          </div>
                          {val?.subMenu &&
                            val?.subMenu.map((val, i) => {
                              if (val?.isDeleted) return;
                              return (
                                <div className="dashboard-row" key={i}>
                                  <div className="row">
                                    <div className="col-md-2 col-sm-2 col-xs-12">
                                      <label>
                                        <FormattedMessage
                                          id="requiredDoc.englishName"
                                          defaultMessage="English Name"
                                        />
                                        <span className="text-warning">*</span>
                                      </label>
                                    </div>
                                    <div className="col-md-8 col-sm-8 col-xs-12">
                                      <input
                                        name="value_en"
                                        type="text"
                                        placeholder="Enter English Name"
                                        value={val["name"]?.value_en}
                                        onChange={(e) =>
                                          handleSubFieldChange({
                                            field: e.target,
                                            childIndex: i,
                                            parentIndex: index,
                                          })
                                        }
                                      />
                                      <p className="text-warning">
                                        {errorIndex.includes(i) &&
                                        val["name"]?.value_en.length <= 0 ? (
                                          <FormattedMessage
                                            id="requiredDoc.englishField"
                                            defaultMessage="English Value is required"
                                          />
                                        ) : validateEnglishLanguage(
                                            val["name"]?.value_en
                                          ) ? null : (
                                          <FormattedMessage
                                            id="requiredDoc.arabicCharsNotAllowed"
                                            defaultMessage="Arabic Characters are not allowed"
                                          />
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-2 col-sm-2 col-xs-12">
                                      <label>
                                        <FormattedMessage
                                          id="requiredDoc.arabicName"
                                          defaultMessage="Arabic Name"
                                        />
                                        <span className="text-warning">*</span>
                                      </label>
                                    </div>
                                    <div className="col-md-8 col-sm-8 col-xs-12">
                                      <input
                                        className="ml10"
                                        name="value_ar"
                                        type="text"
                                        placeholder="Enter Arabic Name"
                                        value={val["name"]?.value_ar}
                                        onChange={(e) =>
                                          handleSubFieldChange({
                                            field: e.target,
                                            childIndex: i,
                                            parentIndex: index,
                                          })
                                        }
                                      />
                                      <p className="text-warning">
                                        {errorIndex.includes(i) &&
                                        val["name"]?.value_ar.length <= 0 ? (
                                          <FormattedMessage
                                            id="requiredDoc.arabicField"
                                            defaultMessage="Arabic Value is required"
                                          />
                                        ) : validateArabicLanguage(
                                            val["name"]?.value_ar
                                          ) ? null : (
                                          <FormattedMessage
                                            id="requiredDoc.englishCharsNotAllowed"
                                            defaultMessage="English Characters are not allowed"
                                          />
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="btn-box">
                                    <Button
                                      variant="secondary"
                                      className="sub-options-remove-btn"
                                      onClick={() =>
                                        handleRemoveSubFields({
                                          parentIndex: index,
                                          childIndex: i,
                                        })
                                      }
                                    >
                                      {intl.formatMessage({
                                        id: "global.remove",
                                        defaultMessage: "Remove",
                                      })}
                                    </Button>
                                  </div>
                                </div>
                              );
                            })}
                          <br />
                          <div className="btn-box">
                            <Button
                              variant="secondary"
                              onClick={() => handleAddSubFields(index)}
                            >
                              {intl.formatMessage({
                                id: "global.addSubField",
                                defaultMessage: "Add Sub Fields",
                              })}
                            </Button>
                          </div>
                        </div>
                      </div>
                      <div className="btn-box">
                        {docValues.length - 1 === index && (
                          <Button
                            variant="secondary"
                            onClick={handleAddMoreFields}
                          >
                            {intl.formatMessage({
                              id: "global.add",
                              defaultMessage: "Add",
                            })}
                          </Button>
                        )}
                        {docValues.length !== 1 && (
                          <Button
                            variant="secondary"
                            onClick={() => handleRemoveField(index)}
                          >
                            {intl.formatMessage({
                              id: "global.remove",
                              defaultMessage: "Remove",
                            })}
                          </Button>
                        )}
                      </div>
                    </div>
                  );
                })}
              <div className="dashboard-row -nobottom move-to-next-btn">
                <Button onClick={movetoNextForm} style={{ margin: "0 10px" }}>
                  {intl.formatMessage({
                    id: "global.save",
                    defaultMessage: "Save",
                  })}
                </Button>
                <Button
                  variant="link"
                  onClick={() => {
                    setPrevSeletedService(docValues[0]?.service);
                    setCurrentDocStatus("bookingAppointmentFor");
                  }}
                >
                  {intl.formatMessage({
                    id: "global.back",
                    defaultMessage: "Back",
                  })}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </Auth>
  );
};

export default React.memo(withRouter(injectIntl(RequiredDocuments)));
