import React, { useState, useEffect, useRef } from "react";
import { FormattedMessage, FormattedNumber, injectIntl } from "react-intl";
import classNames from "classnames";
import Layout from "../../shared/Layout";
import services from "../../../services";
import _ from "lodash";
import { Link } from "react-router-dom";
import withRouter from "../../../util/withRouter";
import ActionMenu from "../../shared/ActionMenu";
import Table from "../../shared/Table";
import appState from "../../../state/AppStateContainer";
import HeaderTitle from "../../shared/HeaderTitle";
import moment from "moment";

const UpsellRecommendations = (props) => {
  const { intl } = props;

  const [data, setData] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [pageIndex, setPageIndex] = useState(-1);
  const [searchFilter, setSearchFilter] = useState("");
  const [disableFlag, setDisableFlag] = useState(false);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);

  const searchInputRef = useRef(null);
  const pageSize = 10;

  const loadMore = async () => {
    setLoading(true);
    setPageIndex((prevState) => prevState + 1);

    setHasMore(false);
    const _pageIndex = pageIndex + 1;

    const options = {
      progressBar: true,
      alphabeticalOrder: true,
    };

    const pageData = await services.getUpsellRecommendations(
      searchFilter,
      _pageIndex,
      pageSize,
      options
    );
    let summary = { count };

    if (_pageIndex === 0) {
      summary = await services.getUpsellRecommendations(
        searchFilter,
        undefined,
        undefined,
        options,
        summary
      );
    }

    setData((prevData) => [...prevData, ...pageData]);
    setHasMore(pageData.length > 0);
    setDisableFlag(false);
    setLoading(false);
    if (_pageIndex === 0) {
      setCount(summary?.count);
    }
  };

  const resetData = (e) => {
    setPageIndex(-1);
    setData([]);
    setHasMore(true);
    setDisableFlag(true);
    setSearchFilter(searchInputRef.current.value);
    if (e) e.preventDefault();
  };

  const resetPage = (e) => {
    setPageIndex(-1);
    setData([]);
    setHasMore(true);
    setSearchFilter("");
    setDisableFlag(true);
    searchInputRef.current.value = "";
    e.preventDefault();
  };

  return (
    <Layout hidden={props.match.params.isFormOpen} loading={loading}>
      <HeaderTitle
        id="track.upsellRecommendations"
        defaultMessage="Upsell Recommendations"
      />
      <div className="main-container">
        <div className="table-header clearfix">
          <form className="row" onSubmit={resetData}>
            <div className="col-md-2 col-sm-4 col-xs-6">
              <div className="has-feedback">
                <input
                  type="text"
                  ref={searchInputRef}
                  className="form-control"
                  placeholder={intl.formatMessage({
                    id: "global.search",
                    defaultMessage: "Search",
                  })}
                />
                <i className="glyphicon glyphicon-search form-control-feedback" />
              </div>
            </div>

            <div className="col force--inline">
              <div className="tooltip-master">
                <button
                  // href="javascript:;"
                  className="btn btn-primary"
                  onClick={resetData}
                  disabled={disableFlag}
                >
                  <i className="material-icons">search</i>
                </button>
                <span className="tooltiptext">
                  <FormattedMessage
                    id="global.filter"
                    defaultMessage="Filter"
                  />
                </span>
              </div>
            </div>
            <div className="col force--inline">
              <div className="tooltip-master">
                <button
                  // href="javascript:;"
                  className="btn btn-primary"
                  onClick={resetPage}
                  disabled={disableFlag}
                >
                  <i className="material-icons">settings_backup_restore</i>
                </button>
                <span className="tooltiptext">
                  <FormattedMessage
                    id="global.resetFilter"
                    defaultMessage="Reset Filter"
                  />
                </span>
              </div>
            </div>
          </form>
          <div className="row">
            <div className="col-md-12">
              <FormattedMessage
                id="global.totalCount"
                defaultMessage="Total Count"
              />{" "}
              <strong>
                <FormattedNumber
                  style="decimal"
                  maximumFractionDigits={0}
                  minimumFractionDigits={0}
                  value={count}
                />
              </strong>
            </div>
          </div>
        </div>

        <div className="dashboard-table-container card-layout">
          <div className="table-responsive">
            <Table
              loadMore={loadMore}
              hasMore={hasMore}
              caption={
                (!data || data.length == 0) && (
                  <caption
                    style={{
                      captionSide: "bottom",
                      textAlign: "center",
                      margin: "20px",
                      color: "darkgray",
                    }}
                  >
                    <FormattedMessage
                      id="global.noData"
                      defaultMessage="No data available"
                    />
                  </caption>
                )
              }
              headerRow={
                <tr>
                  <th>
                    <FormattedMessage
                      id="track.customerNumber"
                      defaultMessage="Phone Number"
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="track.name"
                      defaultMessage="Offer Name"
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="fastCollect.contactedStatus"
                      defaultMessage="Customer Contacted"
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="global.status"
                      defaultMessage="Status"
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="track.createdAt"
                      defaultMessage="Created At"
                    />
                  </th>
                  <th style={{ width: "20px" }} />
                </tr>
              }
            >
              {data &&
                data.map((track, i) => (
                  <tr
                    key={i}
                    onClick={() =>
                      props.navigate(`/upsell-recommendation/${track._id}`)
                    }
                    className="cases-trow link-row"
                  >
                    <td>{track.phoneNumber}</td>
                    <td>{track?.name["value_" + appState.state.language]}</td>
                    <td
                      className={classNames({
                        "col-status-green": track.isCustomerContacted,
                        "col-status-red": !track.isCustomerContacted,
                      })}
                    >
                      <a>
                        {track.isCustomerContacted ? (
                          <FormattedMessage
                            id="fastCollect.contactedYes"
                            defaultMessage="Yes"
                          />
                        ) : (
                          <FormattedMessage
                            id="fastCollect.contactedNo"
                            defaultMessage="No"
                          />
                        )}
                      </a>
                    </td>
                    <td
                      className={classNames({
                        "col-status-green": track.isActive,
                        "col-status-red": !track.isActive,
                      })}
                    >
                      <a>
                        {track.isActive ? (
                          <FormattedMessage
                            id="global.active"
                            defaultMessage="Yes"
                          />
                        ) : (
                          <FormattedMessage
                            id="global.suspended"
                            defaultMessage="Suspended"
                          />
                        )}
                      </a>
                    </td>
                    <td>{moment(track?.createdAt).format("MM/DD/YYYY")}</td>
                    <td style={{ position: "relative" }}>
                      <ActionMenu
                        // visible={visible}
                        menuStyle={{
                          backgroundColor: "#E9E9E9",
                        }}
                      >
                        <Link
                          style={{ margin: "10px", cursor: "pointer" }}
                          to={`/upsell-recommendation/${track._id}`}
                        >
                          <span className="fa fa-pencil" />{" "}
                          <FormattedMessage
                            id="global.edit"
                            defaultMessage="Edit"
                          />
                        </Link>
                        <a
                          style={{ margin: "10px", cursor: "pointer" }}
                          onClick={() => {}}
                        />
                      </ActionMenu>
                    </td>
                  </tr>
                ))}
            </Table>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default withRouter(injectIntl(UpsellRecommendations));
