import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Modal, Button } from "react-bootstrap";
import appState from "../../state/AppStateContainer";
import _ from "lodash";
import { AgGridReact } from "ag-grid-react";
import moment from "moment";

const HistoryModal = ({ show, onCancel, data }) => {
  const columns = [
    {
      field:
        appState.state.language === "en"
          ? "branchId.name.value_en"
          : "branchId.name.value_ar",
      headerName: "Branch Name",
      rowDrag: true,
      width: 200,
      resizable: true,
    },
    {
      field: "price",
      headerName: "price",
      width: 100,
      resizable: true,
    },
    {
      field: "totalQuantity",
      headerName: "Total Quantity",
      width: 130,
      resizable: true,
    },
    {
      field: "remainingQuantity",
      headerName: "Remaining Quantity",
      width: 150,
      resizable: true,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      width: 150,
      resizable: true,
      cellRenderer: (data) => {
        return moment(data.createdAt).format("MM-Do-YYYY ");
      },
    },
  ];

  return (
    <Modal
      show={show}
      onHide={() => {
        onCancel && onCancel();
      }}
      dialogClassName="history-modal"
    >
      <Modal.Header closeButton={true}>
        <h4>
          <FormattedMessage
            id="variant.history"
            defaultMessage="Branch Variant History"
          />
        </h4>
      </Modal.Header>
      <Modal.Body>
        {" "}
        <div class="">
          <table class="table dashboard-table table-hover ">
            <div
              className="ag-theme-balham"
              style={{
                height: "265px",
              }}
            >
              <AgGridReact
                rowSelection="single"
                columnDefs={columns}
                rowData={data}
              ></AgGridReact>
            </div>
          </table>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          bsStyle="link"
          onClick={() => {
            onCancel && onCancel();
          }}
        >
          <FormattedMessage id="global.cancel" defaultMessage="Cancel" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default React.memo(HistoryModal);
