import React from "react";
import { Modal, Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

const ConfirmationModal = ({
  show,
  onCancel,
  onSubmit,
  id,
  defaultMessage,
  loading = false,
}) => {
  return (
    <>
      <Modal show={show} onHide={() => onCancel()} backdrop="static">
        <Modal.Header closeButton={!loading}>
          <h4>
            <FormattedMessage
              id="gloabl.confirmation"
              defaultMessage="Confirmation"
            />
          </h4>
        </Modal.Header>
        <Modal.Body>
          <FormattedMessage id={id} defaultMessage={defaultMessage} />
        </Modal.Body>
        <Modal.Footer>
          {!loading ? (
            <>
              <Button
                bsStyle="btn btn-primary"
                style={{ marginBottom: 0 }}
                onClick={() => onSubmit()}
              >
                <FormattedMessage id="global.yes" defaultMessage="Yes" />
              </Button>
              <Button bsStyle="link" onClick={() => onCancel()}>
                <FormattedMessage id="global.no" defaultMessage="No" />
              </Button>
            </>
          ) : (
            <FormattedMessage
              id="global.processing"
              defaultMessage="Processing..."
            />
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ConfirmationModal;
