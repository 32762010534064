export let mixed = {
  default: "القيمة غير صحيحة",
  required: "هذا مجال مطلوب"
};

export let string = {
  length: "يجب أن تكون القيمة ${length} أحرف بالضبط",
  min: "يجب أن تكون القيمة ${min} أحرف على الأقل",
  max: "يجب أن تكون القيمة ${max} أحرف كحد أقصى",
  required: "هذا مجال مطلوب",
  email: "يجب أن تكون القيمة بريدًا إلكترونيًا صالحًا",
  url: "يجب أن تكون القيمة عنوان URL صالحًا",
  trim: "يجب ألا تحتوي القيمة على مسافة بيضاء سابقة أو لاحقة"
};

export let number = {
  min: "يجب أن تكون القيمة أكبر من أو تساوي ${min}",
  max: "يجب أن تكون القيمة أقل من أو تساوي ${max}",
  lessThan: "يجب أن تكون القيمة أقل من ${less}",
  moreThan: "يجب أن تكون القيمة أكبر من ${more}",

  positive: "يجب أن تكون القيمة رقمًا موجبًا",
  negative: "يجب أن تكون القيمة رقمًا سالبًا",
  integer: "يجب أن تكون القيمة عددًا صحيحًا"
};

export default {
  mixed,
  string,
  number
};
