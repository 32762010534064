export const handleChange = ({ field, index, setDocValues, docValues }) => {
  const { name: keyName, value } = field;
  const _docValues = [...docValues];

  if (
    keyName === "carOwnBy" ||
    keyName === "service" ||
    keyName === "quoteService" ||
    keyName === "bookingAppointmentFor"
  ) {
    _docValues[index][keyName] = value;
  } else {
    _docValues[index]["name"][keyName] = value;
  }

  setDocValues(_docValues);
};
export const validateArabicLanguage = (ar) => {
  const result = /[a-z]/gi.exec(ar);
  return !ar || !result || result.length === 0;
};
export const validateEnglishLanguage = (en) => {
  const result = /[\u0621-\u064A]/.exec(en);
  return !en || !result || result.length === 0;
};
