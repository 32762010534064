import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import appState from "../../state/AppStateContainer";
import _ from "lodash";

export default class TranslationLabel extends Component {
  state = {
    highlight: false
  };

  getTranslationId() {
    return this._reactInternals.return.pendingProps.id;
  }

  getDefaultMessage() {
    return this._reactInternals.return.pendingProps.defaultMessage;
  }

  handleClick(e) {
    const id = this.getTranslationId();
    if (e.button === 0 && e.shiftKey) {
      appState.setState({
        editingTranslationId: id,
        editingTranslationDefault: this.getDefaultMessage()
      });

      e.stopPropagation();
      e.preventDefault();
      return false;
    }
  }

  handleMouseMove(e) {
    this.setState({ highlight: e.shiftKey });
  }

  handleMouseLeave(e) {
    this.setState({ highlight: false });
  }

  render() {
    let eventHandlers = {};
    if (_.get(appState.state.token, "role") === "sadmin") {
      eventHandlers = {
        onMouseDown: this.handleClick.bind(this),
        onClick: this.handleClick.bind(this),
        onMouseMove: this.handleMouseMove.bind(this),
        onMouseLeave: this.handleMouseLeave.bind(this),
        onContextMenu: e => e.preventDefault()
      };
    }
    let style = { ...this.props.style };
    if (this.state.highlight) style.backgroundColor = "yellow";
    return (
      <span style={style} {...eventHandlers}>
        {this.props.children}
      </span>
    );
  }
}

