import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useField, useFormikContext } from "formik";

const QuillEditor = ({ name }) => {
  const { setFieldValue, setTouched } = useFormikContext();
  const [field, meta] = useField(name);

  const handleChange = (content, delta, source, editor) => {
    setFieldValue(name, content);
  };

  const handleBlur = () => {
    setTouched({ [name]: true });
  };

  const toolbarOptions = [
    ["bold", "italic", "underline", "strike"], // toggled buttons
    ["blockquote", "code-block"],

    [{ header: 1 }, { header: 2 }], // custom button values
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
    [{ direction: "rtl" }], // text direction

    [{ size: ["small", false, "large", "huge"] }], // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ font: [] }],
    [{ align: [] }],

    ["clean"], // remove formatting button
  ];

  return (
    <div>
      <ReactQuill
        className="quill-arabic"
        value={field.value}
        onChange={handleChange}
        onBlur={handleBlur}
        modules={{ toolbar: toolbarOptions }}
      />
    </div>
  );
};

export default QuillEditor;
