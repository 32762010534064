import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import NotificationType from "./notificationType";
import SmsEmailContentModal from "../../shared/SmsEmailContentModal";
import _ from "lodash";
export class Notifications extends Component {
  state = {
    showContentModal: null,
    modalMode: "sms",
    notificationName: undefined,
    modalData: [],
  };

  setModal(display, mode, type) {
    this.setState({
      showContentModal: display,
      modalMode: mode,
      notificationName: type,
      modalData: this.state.modalData,
    });

    if (this.props.smsData && !this.state.modalData.length) {
      this.setState({ modalData: this.props.smsData });
    }
  }

  async updateContent(values) {
    let modalData = this.state.modalData;

    let notification = this.state.notificationName;

    let doExist = modalData.findIndex(
      (message) => message[notification + "Content"]
    );
    if (doExist > -1) {
      modalData.splice(doExist, 1);
    }

    let content = { [notification + "Content"]: values };
    this.setState({ modalData: [...modalData, content] });

    this.props.smsContent && this.props.smsContent(this.state.modalData);

    this.setState({ showContentModal: null });
  }

  render() {
    const { formikProps, formatMessage, values } = this.props;

    return (
      <>
        <table className="table dashboard-table table-hover notify-table">
          <thead>
            <tr>
              <th style={{ maxWidth: "80px" }}>
                <FormattedMessage
                  id="admin.notifications"
                  defaultMessage="Notifications"
                />
              </th>
              <th style={{ maxWidth: "50px" }}>
                <FormattedMessage id="admin.allow" defaultMessage="Allow" />
              </th>
              <th style={{ maxWidth: "80px", paddingLeft: "5px" }}>
                <FormattedMessage
                  id="admin.frequency"
                  defaultMessage="Frequency"
                />
              </th>
              <th style={{ maxWidth: "50px" }}>
                <FormattedMessage
                  id="admin.smsContent"
                  defaultMessage="SMS content"
                />
              </th>
            </tr>
          </thead>

          <NotificationType
            type="scheduleNotification"
            formikProps={formikProps}
            formatMessage={formatMessage}
            handleModal={(display, mode, type) =>
              this.setModal(display, mode, type)
            }
            values={values}
          />
          <NotificationType
            type="rescheduleNotification"
            formikProps={formikProps}
            formatMessage={formatMessage}
            handleModal={(display, mode, type) =>
              this.setModal(display, mode, type)
            }
            values={values}
          />
          <NotificationType
            type="cancelNotification"
            formikProps={formikProps}
            formatMessage={formatMessage}
            handleModal={(display, mode, type) =>
              this.setModal(display, mode, type)
            }
            values={values}
          />
          <NotificationType
            type="createEstimationNotification"
            formikProps={formikProps}
            formatMessage={formatMessage}
            handleModal={(display, mode, type) =>
              this.setModal(display, mode, type)
            }
            values={values}
          />
            {/* <NotificationType
            type="updateEstimationNotification"
            formikProps={formikProps}
            formatMessage={formatMessage}
            handleModal={(display, mode, type) =>
              this.setModal(display, mode, type)
            }
            values={values}
          /> */}
        </table>

        <SmsEmailContentModal
          data={this.state.modalData}
          mode={this.state.modalMode}
          notificationName={this.state.notificationName}
          onSubmit={(values) => this.updateContent(values)}
          show={this.state.showContentModal}
          onCancel={() => this.setState({ showContentModal: null })}
        />
      </>
    );
  }
}

export default Notifications;
