import React, { useEffect, useRef, useState } from "react";
import { Map, GoogleApiWrapper, Marker, InfoWindow } from "google-maps-react";
import AppConfig from "../../config/AppConfig";
import { Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

const GoogleMap = (props) => {
  const { initialLocation, setFieldValue } = props;
  const googleMapRef = useRef();

  const [searchQuery, setSearchQuery] = useState("");
  // const [markerPosition, setMarkerPosition] = useState({});
  const [country, setCountry] = useState("SA");

  useEffect(() => {
    const autocomplete = new window.google.maps.places.Autocomplete(
      googleMapRef.current,
      {
        types: ["geocode"],
        componentRestrictions: { country: "SA" },
      }
    );

    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      if (!place.geometry) return console.error("Place not found");

      getGoecodeResults(place);
    });
  }, []);

  const handleMapClick = (mapProps, map, clickEvent) =>
    getLocationData({ location: clickEvent.latLng });

  const handleSearchInputChange = (event) => setSearchQuery(event.target.value);

  const handleSearchSubmit = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      e.stopPropagation();

      getLocationData({ address: searchQuery });
    }
  };

  const getLocationData = (params) => {
    const geocoder = new props.google.maps.Geocoder();
    geocoder.geocode(
      { ...params, language: "en", region: "us" },
      (results, status) => {
        if (status === "OK" && results[0]) getGoecodeResults(results[0]);
        else console.error("Geocoder failed due to: " + status);
      }
    );
  };

  const getGoecodeResults = (place) => {
    const position = {
      lat: place.geometry.location.lat().toString(),
      lng: place.geometry.location.lng().toString(),
    };

    // Update the marker position and address values
    const { country, city } = getCountryAndCity(place.address_components);
    setCountry(country);
    setFieldValue("address", place.formatted_address);
    setFieldValue("city", city);
    setFieldValue("location", [position.lng, position.lat]);
    // setMarkerPosition(position);
  };

  const getCountryAndCity = (locations) => {
    const origin = {};
    locations.forEach((area) => {
      if (area.types.includes("country")) origin.country = area.short_name;
      else if (area.types.includes("locality")) origin.city = area.long_name;
    });

    return origin;
  };

  return (
    <Card className="map-card">
      <div className="map-container">
        <div className="input-wrap">
          <input
            type="text"
            ref={googleMapRef}
            className="input-left-btn"
            placeholder="Search Location"
            value={searchQuery}
            onChange={handleSearchInputChange}
            onKeyDown={handleSearchSubmit}
          />
        </div>
        <Map
          google={props.google}
          center={initialLocation}
          initialCenter={initialLocation}
          zoom={8}
          onClick={handleMapClick}
        >
          <Marker position={initialLocation} />

          <InfoWindow
            position={initialLocation}
            visible={country !== "SA" && initialLocation}
          >
            <span>Autohub doesn't operate in this area</span>
          </InfoWindow>
        </Map>
      </div>

      <a
        href={`https://www.google.com/maps/search/?api=1&zoom=8&query=${initialLocation.lat},${initialLocation.lng}`}
        target="_blank"
      >
        <FormattedMessage
          id="branch.viewLocation"
          defaultMessage="View Location"
        />
      </a>
    </Card>
  );
};

export default GoogleApiWrapper({
  apiKey: AppConfig.gMapAPIKey,
})(React.memo(GoogleMap));
