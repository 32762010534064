import React from "react";
// import QrReader from "react-qr-reader";

const Scanner = ({onScanQrCode}) => {
  const [result, setResult] = React.useState(null);

  const onScan = (data) => {
    if (data) {
      setResult(data);
      onScanQrCode(data);
      console.log(data);
    }
  };

  const handleError = (err) => console.error(err);

  return (
    <div>
      {/* <QrReader
        delay={100}
        onError={handleError}
        onScan={onScan}
        style={{ width: "100%" }}
      /> */}
    </div>
  );
};

export default Scanner;
