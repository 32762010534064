import React, { useRef } from "react";
import { FormattedMessage } from "react-intl";
import { Formik, Form } from "formik";
import { Yup } from "../../util";
import { Modal, Button } from "react-bootstrap";
import FieldRow from "./FieldRow";
import Loading from "./Loading";

const ReasonModal = ({
  showNextStep,
  setShowNextStep,
  show,
  onSubmit,
  onCancel,
  loading,
}) => {
  const formikRef = useRef(null);

  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          setShowNextStep(false);
          onCancel && onCancel();
        }}
      >
        {loading && <Loading />}
        <Modal.Header closeButton={true}>
          <h4>
            <FormattedMessage
              id={showNextStep ? "global.confirmation" : "global.reasonComment"}
              defaultMessage={showNextStep ? "Confirmation" : "Refund Reason"}
            />
          </h4>
        </Modal.Header>

        <Formik
          innerRef={formikRef}
          validationSchema={Yup.object().shape({
            reason: Yup.string().required(),
          })}
          onSubmit={(values) => {
            if (showNextStep) {
              onSubmit && onSubmit(values);
              return;
            }
            setShowNextStep(true);
          }}
          initialValues={{
            reason: "",
          }}
          render={(formikProps) => {
            return (
              <Form>
                <Modal.Body>
                  {!showNextStep ? (
                    <div className="col-xs-12">
                      <FieldRow
                        name="reason"
                        type="textarea"
                        labelId="order.refundReason"
                        defaultLabel="Refund Reason"
                        placeholderId="order.enterRefundReason"
                        defaultPlaceholder="Enter Refund Reason"
                        showRequiredFlag={true}
                        formikProps={formikProps}
                      />
                    </div>
                  ) : (
                    <FormattedMessage
                      id="global.refundConfirmationPara"
                      defaultMessage="have you tried to Convince the Customer?"
                    />
                  )}
                </Modal.Body>
                <Modal.Footer>
                  <Button bsStyle="btn btn-primary" type="submit">
                    <FormattedMessage
                      id={showNextStep ? "global.yes" : "global.submit"}
                      defaultMessage={showNextStep ? "Yes" : "Submit"}
                    />
                  </Button>
                  <Button
                    bsStyle="link"
                    onClick={() => {
                      setShowNextStep(false);
                      onCancel && onCancel();
                    }}
                  >
                    <FormattedMessage
                      id={showNextStep ? "global.no" : "global.cancel"}
                      defaultMessage={showNextStep ? "No" : "Cancel"}
                    />
                  </Button>
                </Modal.Footer>
              </Form>
            );
          }}
        />
      </Modal>
    </>
  );
};

export default React.memo(ReasonModal);
