import React, { useState, useEffect, useRef } from "react";
import { FormattedMessage, FormattedNumber, injectIntl } from "react-intl";
import Layout from "../shared/Layout";
import QuotationService from "../../services/quotationService";
import _ from "lodash";
import { Link } from "react-router-dom";
import withRouter from "../../util/withRouter";
import Table from "../shared/Table";
import appState from "../../state/AppStateContainer";
import HeaderTitle from "../shared/HeaderTitle";
import { getLocalDateTime } from "../../util";
import ImageProvider from "../shared/ImageProvider";

const QuotationList = (props) => {
  const [data, setData] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [pageIndex, setPageIndex] = useState(-1);
  const [pageSize, setPageSize] = useState(10);
  const [searchFilter, setSearchFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("all");
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);

  const prevPropsRef = useRef();

  useEffect(() => {
    prevPropsRef.current = props;
  }, []);

  const prevProps = prevPropsRef.current;
  const lang = appState.state.language;

  useEffect(() => {
    if (props.location.pathname !== prevProps?.location?.pathname) {
      if (!props.match.params.isFormOpen) {
        if (_.get(props.location, "state.isSubmitted") === true) {
          setPageIndex(-1);
          setData([]);
          setHasMore(true);
        }
      }
    }
  }, [props.location.pathname]);
  const loadMore = async () => {
    setLoading(true);
    setPageIndex((prevState) => prevState + 1);

    setHasMore(false);
    const _pageIndex = pageIndex + 1;

    const options = {
      progressBar: true,
      alphabeticalOrder: true,
    };

    const pageData = await QuotationService.getQuotations(
      searchFilter,
      statusFilter,
      _pageIndex,
      pageSize,
      options
    );
    let summary = { count };

    if (_pageIndex === 0) {
      summary = await QuotationService.getQuotations(
        searchFilter,
        statusFilter,
        undefined,
        undefined,
        options,
        summary
      );
    }

    setData((prevData) => [...prevData, ...pageData]);
    setHasMore(pageData.length > 0);
    setLoading(false);
    if (_pageIndex === 0) {
      setCount(summary.count);
    }
  };

  return (
    <Layout hidden={props.match.params.isFormOpen} loading={loading}>
      <HeaderTitle id="quotation.title" defaultMessage="Quotations" />
      <div className="main-container">
        <div className="row">
          <div className="col-xs-3 pull-right">
            <div className="panel-group-buttons pull-right">
              <Link className="btn btn-special" to="/quotation">
                <span className="fa fa-plus" />{" "}
                <FormattedMessage
                  id="quotation.new"
                  defaultMessage="Create Quotation"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="row">
          <div className="col-md-12">
            <FormattedMessage
              id="global.totalCount"
              defaultMessage="Total count"
            />{" "}
            <strong>
              <FormattedNumber
                style="decimal"
                maximumFractionDigits={0}
                minimumFractionDigits={0}
                value={count}
              />{" "}
            </strong>
          </div>
        </div>
        <div className="table-responsive">
          <Table
            loadMore={loadMore}
            hasMore={hasMore}
            caption={
              (!data || data.length == 0) && (
                <caption
                  style={{
                    captionSide: "bottom",
                    textAlign: "center",
                    margin: "20px",
                    color: "darkgray",
                  }}
                >
                  <FormattedMessage
                    id="global.noData"
                    defaultMessage="No data available"
                  />
                </caption>
              )
            }
            headerRow={
              <tr>
                <th>
                  <FormattedMessage
                    id="autoHubServies.image"
                    defaultMessage="Image"
                  />
                </th>
                <th>
                  <FormattedMessage
                    id="autoHubServies.englishName"
                    defaultMessage="English Name"
                  />
                </th>
                <th>
                  <FormattedMessage
                    id="autoHubServies.arabicName"
                    defaultMessage="Arabic Name"
                  />
                </th>
                <th>
                  <FormattedMessage
                    id="global.createdAt"
                    defaultMessage="Created At"
                  />
                </th>
                <th>
                  <FormattedMessage
                    id="autoHubServies.isActive"
                    defaultMessage="IsActive"
                  />
                </th>
              </tr>
            }
          >
            {data.map((c) => {
              var showEdit = false;

              if (
                (appState.state.token?.role === "sadmin" &&
                  c.approvalState === "approved") ||
                (appState.state.token?.id === c.approvalRequestedBy &&
                  c.approvalState !== "approved")
              )
                showEdit = true;
              return (
                <tr
                  key={c._id}
                  onClick={() => props.navigate(`/quotation/${c?._id}`)}
                  className="cases-trow link-row"
                >
                  <ImageProvider imageSrc={c["image_" + lang]} />
                  <td>{c.name?.value_en}</td>
                  <td>{c.name?.value_ar}</td>
                  <td>
                    {c.createdAt ? getLocalDateTime(c.createdAt) : undefined}
                  </td>
                  <td>{c.isActive ? "Yes" : "No"}</td>
                </tr>
              );
            })}
          </Table>
        </div>
      </div>
    </Layout>
  );
};

export default withRouter(injectIntl(QuotationList));
