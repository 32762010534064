import React from "react";
import appState from "../../state/AppStateContainer";
import CheckAccess from "./CheckAccess";
import _ from "lodash";
import { FormattedMessage } from "react-intl";
import ReactLoading from "react-loading";

function ExportExcelButton({ onClickButton, disabledButton, loading }) {
  return (
    <div>
      {/* {_.get(appState, "state.token.role") == "sadmin" &&
        !_.get(appState, "state.token.isSubAdmin") && ( */}
          <a
            className="btn btn-special"
            disabled={disabledButton}
            onClick={!disabledButton ? onClickButton : undefined}
          >
            {loading ? (
              <div
                style={{
                  margin: "auto",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <ReactLoading
                  color="#e99326"
                  type="spin"
                  height="20px"
                  width="20px"
                />
              </div>
            ) : (
              <>
                <span className="fa fa-file-excel-o" />{" "}
                <FormattedMessage
                  id="global.exportToExcel"
                  defaultMessage="Export to Excel"
                />
              </>
            )}
          </a>
        {/* )} */}
      {/* </CheckAccess> */}
    </div>
  );
}

export default ExportExcelButton;
