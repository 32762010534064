import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Formik, Form } from "formik";
import { Yup } from "../../util";
import { Modal, Button } from "react-bootstrap";
import { ImAttachment } from "react-icons/im";
import FieldRow from "./FieldRow";
const key = "xzic2b7Fei1GJlKuQsJcBtIo12qfnJ5W";

export default class CommentModal extends Component {
  displayImage(file) {
    const blob = file;
    window.URL.revokeObjectURL(this.fileUrl);
    this.fileUrl = window.URL.createObjectURL(blob);
    return this.fileUrl;
  }
  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={() => {
          this.props.onCancel && this.props.onCancel();
        }}
        centered
      >
        <Modal.Header closeButton={true}>
          <h4>
            <FormattedMessage
              id="global.addComment"
              defaultMessage="Add Comment"
            />
          </h4>
        </Modal.Header>
        <Modal.Body>
          <FormattedMessage
            id="global.pleaseAddYourComment"
            defaultMessage="Please Add your comment about estimation"
          />
          <Formik
            ref={(f) => (this.formik = f)}
            innerRef={(f) => (this.formik = f)}
            validationSchema={Yup.object().shape({
              comment: Yup.string().required(),
            })}
            onSubmit={(values) => {
              this.props.onSubmit &&
                this.props.onSubmit({
                  comment: values.comment,
                  image: values?.image,
                });
            }}
            initialValues={{
              comment: this.props.comment || "",
              image: "",
            }}
            render={({
              values,
              handleChange,
              touched,
              errors,
              setFieldValue,
              setTouched,
            }) => {
              return (
                <Form>
                  <textarea
                    name="comment"
                    value={values.comment}
                    cols="30"
                    rows="10"
                    onChange={handleChange}
                    className="form-control"
                    disabled={this.props.disabled}
                  ></textarea>
                  {values?.image && (
                    <div className="comment-image">
                      <img
                        // src={`/api/util/image?key=${values?.image}&accesskey=${key}`}
                        src={this.displayImage(values?.image)}
                      />
                    </div>
                  )}

                  {touched["comment"] && errors["comment"] && (
                    <p className="text-warning">{errors["comment"]}</p>
                  )}
                  {this?.props?.source?.toLowerCase() === "carhub" && (
                    <div className="attachment-icon">
                      <FieldRow
                        type="file"
                        name="image"
                        image={true}
                        aspectRatio={325.8 / 325}
                        imageSize="W:325.8 x H:325"
                        cameraIcon={true}
                        formikProps={{
                          touched,
                          errors,
                          setFieldValue,
                          handleChange,
                          values,
                          setTouched,
                        }}
                        disabled={false}
                      />
                    </div>
                  )}
                </Form>
              );
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="link"
            onClick={() => {
              this.props.onCancel && this.props.onCancel();
            }}
          >
            <FormattedMessage id="global.cancel" defaultMessage="Cancel" />
          </Button>
          <Button
            onClick={() => {
              this.formik.submitForm();
            }}
            disabled={this.props.disabled}
          >
            <FormattedMessage id="global.submit" defaultMessage="Submit" />
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
