import moment from "moment";
import React from "react";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

const ReasonsModal = ({ show, onClose, reasons }) => {
  return (
    <Modal
      style={{
        top: "50px",
      }}
      show={show}
      onHide={onClose}
      backdrop="static"
    >
      <Modal.Header closeButton={true}>
        <h4>
          <FormattedMessage id="track.reasons" defaultMessage="Reasons" />
        </h4>
      </Modal.Header>
      <Modal.Body>
        {reasons?.length > 0 &&
          reasons?.map((reason) => {
            return (
              <div className="reasons-modal-wrapper">
                <label>
                  {moment(reason?.createdTime).format("DD/MM/YYYY HH:mm")}
                </label>
                <p>{reason?.notes}</p>
                <hr />
              </div>
            );
          })}
      </Modal.Body>
    </Modal>
  );
};
export default ReasonsModal;
