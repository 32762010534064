import React, { useEffect, useState } from "react";
import { FormattedMessage, FormattedNumber, injectIntl } from "react-intl";
import withRouter from "../../util/withRouter";
import Auth from "../shared/Auth";
import HeaderTitle from "../shared/HeaderTitle";
import Layout from "../shared/Layout";
import LocationService from "../../services/locationService";
import SortableComponent from "../../shared/DragAndDropTable";
import Loading from "../shared/Loading";
import { arrayMove } from "react-sortable-hoc";
import { orderBy } from "lodash";

const CityList = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getCities();
  }, []);

  const getCities = async () => {
    try {
      setLoading(true);
      const data = await LocationService.getCities();
      const sortedCities = orderBy(data, ["_id.position"], ["asc"]);
      setData(sortedCities);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const updateCitiesSorting = async (oldIndex, newIndex) => {
    try {
      setLoading(true);
      const updatedData = arrayMove(data, oldIndex, newIndex);
      updatedData.map((item, i) => {
        item._id.position = i;
      });
      setData(updatedData);
      await LocationService.updateCitiesSorting(updatedData);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Auth requireAuth={true} roles={["sadmin"]}>
      <Layout loading={loading}>
        <HeaderTitle id="city.city" defaultMessage="Cities" />
        <div className="main-container">
          <div className="row">
            <div className="col-md-12">
              <FormattedMessage
                id="global.totalCount"
                defaultMessage="Total Count"
              />{" "}
              <strong>
                <FormattedNumber
                  style="decimal"
                  maximumFractionDigits={0}
                  minimumFractionDigits={0}
                  value={data?.length || 0}
                />
              </strong>
            </div>
          </div>
          <div
            style={{ marginTop: "10px" }}
            className="dashboard-table-container card-layout mt-5"
          >
            <div className="table-responsive">
              <div className="custom-table">
                <div className="custom-table-header">
                  <div>
                    <FormattedMessage
                      id="city.cityName"
                      defaultMessage="City English Name"
                    />
                  </div>
                  <div>
                    <FormattedMessage
                      id="city.cityNameAr"
                      defaultMessage="City Arabic Name"
                    />
                  </div>
                  <div>
                    <FormattedMessage
                      id="city.numOfBranches"
                      defaultMessage="Number of Branches"
                    />
                  </div>
                </div>
                {!data && data?.length <= 0 ? (
                  <div
                    style={{
                      captionSide: "bottom",
                      textAlign: "center",
                      margin: "20px",
                      color: "darkgray",
                    }}
                  >
                    <FormattedMessage
                      id="global.noData"
                      defaultMessage="No data available"
                    />
                  </div>
                ) : (
                  <div className="main-container">
                    <SortableComponent
                      data={data}
                      _setData={setData}
                      props={props}
                      updateSorting={updateCitiesSorting}
                      from="cities"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </Auth>
  );
};

export default withRouter(injectIntl(CityList));
