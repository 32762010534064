import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import _ from "lodash";
import withRouter from "../../util/withRouter";
import appState from "../../state/AppStateContainer";
import { Image } from "react-bootstrap";
import { FormattedNumber } from "react-intl";

function ServiceCard({
  service,
  quantity,
  listPrice,
  promoDiscount,
  price,
  onClick,
  keyName,
}) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        flexDirection: "row",
        padding: "10px",
        marginBottom: "5px",
      }}
      className="card"
    >
      <div style={{ flex: "0.1 30px", padding: "2px" }}>
        {_.isEmpty(
          service.image_en ||
            service.banner_en ||
            service.images ||
            service.image
        ) && (
          <Image
            src="/design/images/carhub_placeholder.png"
            responsive
            thumbnail
            style={{ backgroundColor: "transparent" }}
          />
        )}
        {service.image_en ||
        service.banner_en ||
        service.images?.length ||
        service.image ? (
          <Image
            src={`/api/util/image?key=${
              service?.isRecommended
                ? service?.images[0]?.value
                : service.image_en || service.banner_en || service?.image
            }`}
            responsive
            thumbnail
            style={{ backgroundColor: "transparent" }}
          />
        ) : null}
      </div>
      <div
        style={{
          flex: 0.9,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "strech",
          marginLeft: "10px",
        }}
      >
        <strong>
          {appState.state.language === "ar"
            ? service.name?.value_ar || service.name?.value_en
            : service.name?.value_en}
        </strong>
        {/* <span style={{ color: "silver", fontSize: ".8em" }}>
          {appState.state.language === "ar"
            ? service.categoryArabicName || service.categoryEnglishName
            : service.categoryEnglishName}
        </span> */}
        <div
          className="abc"
          style={{
            fontSize: ".8em",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>
            <strong>
              {appState.state.language === "ar"
                ? service?.product?.name.value_ar
                : service?.product?.name.value_en}
            </strong>
          </div>
          {/* <div>
            <strong>
              <FormattedMessage
                id="service.name"
                defaultMessage="Promo Discount"
              />
              :{" "}
            </strong>{" "}
            {promoDiscount}
          </div> */}
          <div>
            <strong>
              <FormattedMessage
                id="service.unitPrice"
                defaultMessage="Unit Price"
              />
              :{" "}
            </strong>{" "}
            <FormattedNumber
              style="decimal"
              maximumFractionDigits={2}
              minimumFractionDigits={2}
              value={parseFloat(price / quantity).toFixed(2)}
            />
          </div>

          <div>
            <strong>
              <FormattedMessage
                id="service.quantity"
                defaultMessage="Quantity"
              />
              :{" "}
            </strong>{" "}
            {quantity.toFixed(2)}
          </div>
          <div>
            <strong>
              <FormattedMessage
                id="service.subTotal"
                defaultMessage="Sub Total"
              />
              :{" "}
            </strong>{" "}
            {`${parseFloat(price / quantity).toFixed(2)} x ${quantity.toFixed(
              2
            )} = ${parseFloat(price).toFixed(2)}`}
          </div>
          {/* {appState.state.token?.role !== "sadmin" && (
            <div>
              <strong>
                <FormattedMessage id="service.price" defaultMessage="Price" />:{" "}
              </strong>{" "}
              <FormattedNumber
                style="decimal"
                maximumFractionDigits={2}
                minimumFractionDigits={2}
                value={service.price ? service.price : "N/A"}
              />
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
}

export class ServiceSelect extends Component {
  state = {
    data: [],
    searchFilter: "",
    approvalFilter: "approved",
    statusFilter: "active",
    categoryFilter: "all",
    listFilter: "added",
    categories: [],
    selected: [],
    isMounted: false,
  };

  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    try {
    } catch (e) {
      this.props.navigate("/error", {
        error: _.get(e, "response.data.error", "error.unexpectedError"),
      });
    }
  }

  render() {
    const items = this.props.itemDetails;
    const keyName = this.props.keyName;

    return (
      <div style={this.props.style}>
        {!this.props.disabled && (
          <div
            style={{ marginBottom: "0px" }}
            className="table-header clearfix"
          >
            {/* </form> */}
          </div>
        )}
        <div
          className="addon-services"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "strech",
            flexWrap: "wrap",
          }}
        >
          {!this.props.disabled && (
            <div
              style={{
                maxHeight: "300px",
                overflowY: "auto",
                padding: "10px",
                flex: 1,
              }}
            >
              {items?.map((c, i) => {
                return (
                  <ServiceCard
                    key={i}
                    // key={"card-" + c.id}
                    service={c[keyName] || {}}
                    quantity={c.quantity}
                    promoDiscount={c.promoDiscount}
                    listPrice={c.listPrice}
                    price={c.priceBeforePromoDiscount}
                    keyName={keyName}
                  />
                );
              })}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(injectIntl(ServiceSelect));
