import * as yup from "yup";
import moment from "moment";

yup.addMethod(yup.mixed, "sameAs", function(ref, message) {
  return this.test("sameAs", message, function(value) {
    const other = this.resolve(ref);
    return !other || !value || value === other;
  });
});

yup.addMethod(yup.string, "hasNoArabic", function(message) {
  return this.test("hasNoArabic", message, function(value) {
    const result = /[\u0621-\u064A]/.exec(value);
    return !value || !result || result.length === 0;
  });
});

yup.addMethod(yup.string, "hasNoEnglish", function(message) {
  return this.test("hasNoEnglish", message, function(value) {
    const result = /[a-z]/gi.exec(value);
    return !value || !result || result.length === 0;
  });
});

export function getLocalDateTime(givenDate) {
  const utcDate = givenDate.toString();
  // var gmtDateTime = moment.utc(utcDate, "YYYY-MM-DD HH");
  const gmtDateTime = moment.utc(utcDate);
  const local = gmtDateTime.local().format("YYYY-MMM-DD h:mm A");
  return moment(local, "YYYY-MMM-DD h:mm A").format("lll");
}

export function getDates(startDate, stopDate) {
  var start = new Date(startDate);
  var end = new Date(stopDate);
  let holidays_ = [];
  //Logic for getting rest of the dates between two dates("FromDate" to "EndDate")
  while (start <= end) {
    holidays_.push(moment(start).format('YYYY-MMM-DD'));
    var newDate = start.setDate(start.getDate() + 1);
    start = new Date(newDate);
  }
  return holidays_;
}

export function distance(lat1, lon1, lat2, lon2, unit) {
  if (lat1 == lat2 && lon1 == lon2) {
    return 0;
  } else {
    var radlat1 = (Math.PI * lat1) / 180;
    var radlat2 = (Math.PI * lat2) / 180;
    var theta = lon1 - lon2;
    var radtheta = (Math.PI * theta) / 180;
    var dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit == "K") {
      dist = dist * 1.609344;
    }
    if (unit == "N") {
      dist = dist * 0.8684;
    }
    return dist;
  }
}

export const Yup = yup;
