import React, { Component } from "react";
import Layout from "../shared/Layout";
import { FormattedMessage } from "react-intl";
import _ from "lodash";
class AccessDenied extends Component {
  render() {
    return (
      <Layout>
        <div className="dashboard-table-container card-layout">
          <h2 className="card-title">
            <FormattedMessage
              id="error.pageNotFound"
              defaultMessage="Not Found"
            />
          </h2>
          <p>
            <FormattedMessage
              id="error.pageNotFoundMsg"
              defaultMessage="The requested page was not found, please double check the URL."
            />
          </p>
        </div>
      </Layout>
    );
  }
}

export default AccessDenied;
