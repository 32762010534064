import React, { useState, useEffect, useRef } from "react";
import { FormattedMessage, injectIntl, FormattedNumber } from "react-intl";
import Layout from "../shared/Layout";
import _ from "lodash";
import Auth from "../shared/Auth";
import withRouter from "../../util/withRouter";
import appState from "../../state/AppStateContainer";
import moment from "moment";
import HeaderTitle from "../shared/HeaderTitle";
import CheckAccess from "../shared/CheckAccess";
import { ActivitiesEnum } from "../../staticData/Activities";
import Table from "../shared/Table";
import services from "../../services";
import classNames from "classnames";
import BranchesService from "../../services/branchesService";
import { SingleDatePicker } from "react-dates";
import ExportToExcel from "../shared/ExportToExcel";
import { generateInvoice } from "../../util/orderUtil";

const OrderReport = ({ history, match, location, intl }) => {
  const [data, setData] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [pageIndex, setPageIndex] = useState(-1);
  const [pageSize, setPageSize] = useState(10);
  const [approvalFilter, setApprovalFilter] = useState("all");
  const [statusFilter, setStatusFilter] = useState("all");
  const [count, setCount] = useState(0);
  const [disableFlag, setDisableFlag] = useState(false);
  const [searchFilter, setSearchFilter] = useState("");
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [branches, setBranches] = useState([]);
  const [branchFilter, setBranchFilter] = useState("all");
  const [orderTypeFilter, setOrderTypeFilter] = useState("all");
  const [appointmentFilter, setAppointmentFilter] = useState("all");
  const [eShopFilter, setEshopFilter] = useState("all");
  const [upsellFilter, setUpsellFilter] = useState("all");
  const [refundFilter, setRefundFilter] = useState("all");
  const [paymentBrandFilter, setPaymentBrandFilter] = useState("all");
  const [focusedFrom, setFocusedFrom] = useState("");
  const [focusedTo, setFocusedTo] = useState("");
  const [loading, setLoading] = useState(false);
  const [branchIds, setBranchIds] = useState("");

  const searchInputRef = useRef(null);
  const statusSelectRef = useRef(null);
  const orderTypeRef = useRef(null);
  const branchesRef = useRef(null);
  const refundStatusRef = useRef(null);
  // const paymentBrandRef = useRef(null);
  const appointmenSelectRef = useRef(null);
  const eShopSelectRef = useRef(null);
  const upsellSelectRef = useRef(null);

  useEffect(() => {
    if (location.pathname !== match.path) {
      if (!match.params.isFormOpen) {
        if (_.get(location, "state.isSubmitted") === true) {
          setPageIndex(-1);
          setData([]);
          setHasMore(true);
          setDisableFlag(true);
          setSearchFilter(searchInputRef.current?.value);
          setStatusFilter(statusSelectRef.current?.value);
          setBranchFilter(branchesRef.current?.value);
          setOrderTypeFilter(orderTypeRef.current?.value);
          setAppointmentFilter(appointmenSelectRef.current?.value);
          setEshopFilter(eShopSelectRef.current?.value);
          setUpsellFilter(upsellSelectRef.current?.value);
          setRefundFilter(refundStatusRef.current.value);
          // setPaymentBrandFilter(paymentBrandRef.current.value);
        }
      }
    }
  }, [location.pathname, match.params.isFormOpen, location.state]);

  useEffect(() => {
    fetchBranches();
  }, []);

  const fetchBranches = async () => {
    setLoading(true);
    try {
      const branches = await BranchesService.getActiveBranches();
      const _branchIds = branches && branches?.map((branch) => branch._id);
      setBranchIds(JSON.stringify(_branchIds));
      setBranches(branches);
    } catch (e) {
      console.error(e);
    }
  };

  const loadMore = async () => {
    setLoading(true);
    setPageIndex((prevState) => prevState + 1);

    setHasMore(false);
    const _pageIndex = pageIndex + 1;

    const options = {
      progressBar: true,
      alphabeticalOrder: true,
      fromDate: from ? moment(from).startOf("day").toString() : null,
      toDate: to ? moment(to).endOf("day").toString() : null,
    };

    const pageData = await services.getOrdersReport(
      searchFilter,
      statusFilter,
      branchFilter,
      orderTypeFilter,
      appointmentFilter,
      eShopFilter,
      branchIds,
      upsellFilter,
      refundFilter,
      paymentBrandFilter,
      pageIndex + 1,
      pageSize,
      {
        ...options,
      }
    );
    let summary = { count };

    if (_pageIndex === 0) {
      summary = await services.getOrdersReport(
        searchFilter,
        statusFilter,
        branchFilter,
        orderTypeFilter,
        appointmentFilter,
        eShopFilter,
        branchIds,
        upsellFilter,
        refundFilter,
        paymentBrandFilter,
        undefined,
        undefined,
        {
          ...options,
          fromDate: from ? moment(from).startOf("day").toString() : null,
          toDate: to ? moment(to).endOf("day").toString() : null,
        },
        summary
      );
    }

    setData((prevData) => [...prevData, ...pageData]);
    setHasMore(pageData.length > 0);
    setDisableFlag(false);
    setLoading(false);

    if (_pageIndex === 0) {
      setCount(summary.count);
    }
  };

  const resetData = (e) => {
    setPageIndex(-1);
    setData([]);
    setHasMore(true);
    setDisableFlag(false);
    setSearchFilter(searchInputRef.current?.value);
    setStatusFilter(statusSelectRef.current?.value);
    setBranchFilter(branchesRef.current?.value);
    setOrderTypeFilter(orderTypeRef.current?.value);
    setAppointmentFilter(appointmenSelectRef.current?.value);
    setEshopFilter(eShopSelectRef.current?.value);
    setUpsellFilter(upsellSelectRef.current?.value);
    setRefundFilter(refundStatusRef.current.value);
    // setPaymentBrandFilter(paymentBrandRef.current.value);

    if (e) e.preventDefault();
  };

  useEffect(() => {
    const onLanguageChange = () => {
      setFrom(from ? moment(from).locale(appState.state.language) : undefined);
      setTo(to ? moment(to).locale(appState.state.language) : undefined);
    };
    const subscription = appState.emitter.addListener(
      "onLanguageChange",
      onLanguageChange
    );

    return () => {
      subscription.remove();
    };
  }, [from, to]);

  const resetPage = (e) => {
    setPageIndex(-1);
    setData([]);
    setHasMore(true);
    setSearchFilter("");
    setApprovalFilter("all");
    setStatusFilter("all");
    setDisableFlag(false);
    setTo(undefined);
    setFrom(undefined);
    setBranchFilter("all");
    searchInputRef.current.value = "";
    statusSelectRef.current.value = "all";
    branchesRef.current.value = "all";
    orderTypeRef.current.value = "all";
    appointmenSelectRef.current.value = "all";
    eShopSelectRef.current.value = "all";
    upsellSelectRef.current.value = "all";
    refundStatusRef.current.value = "all";
    // paymentBrandRef.current.value = "all";
    e.preventDefault();
  };

  const onExport = async (startDate, endDate) => {
    if (!startDate) return;
    if (!endDate) {
      endDate = startDate;
    }
    setLoading(true);
    try {
      // setExportExcelLoading(true);
      await services.downloadOrderReport(
        searchFilter,
        statusFilter,
        moment(startDate)
          .utcOffset(60 * 3)
          .startOf("day")
          .toString(),
        moment(endDate)
          .utcOffset(60 * 3)
          .endOf("day")
          .toString()
      );
    } catch (err) {}
    setLoading(false);
  };

  const orderDeterminationCalculations = (order, operation, key1, key2) => {
    //  return order?.orderDeterminationVariants[key]
    const { orderDeterminationVariants, orderDeterminationOffers } = order;

    const operations = {
      "+": (a, b) => a[key1] + b[key1],
      "-": (a, b) => a[key1] - b[key1],
      total: (a, b) => {
        let beforePromoPrice = a[key1] - b[key1];
        let afterPromoPrice = a[key2] - b[key2];
        return Math.abs(beforePromoPrice + afterPromoPrice);
      },
    };

    return (
      operations[operation]?.(
        orderDeterminationVariants,
        orderDeterminationOffers
      ) || 0
    );
  };

  const handleInvoiceDownload = async (id, deposit, type, refund) => {
    setLoading(true);
    await generateInvoice({ id, deposit, type, refund });
    setLoading(false);
  };

  return (
    <Auth requireAuth={true} roles={["sadmin", "wsadmin", "spadmin"]}>
      <Layout hidden={match.params.isFormOpen} loading={loading}>
        <div className="table-header clearfix">
          <HeaderTitle id="order.orderReport" defaultMessage="Order Reports" />
          <div>
            <CheckAccess
              activityId={ActivitiesEnum.orders_report_filter_and_search}
            >
              <div className="table-header clearfix">
                <form className="row" onSubmit={resetData}>
                  <div className="col-md-2 col-sm-4 col-xs-6">
                    <div className="has-feedback">
                      <input
                        type="text"
                        ref={searchInputRef}
                        className="form-control"
                        //   value={searchFilter}
                        placeholder={intl.formatMessage({
                          id: "global.search",
                          defaultMessage: "Search",
                        })}
                      />
                      <i className="glyphicon glyphicon-search form-control-feedback" />
                    </div>
                  </div>
                  <div className="col-lg-2 col-md-4 col-sm-12 col-xs-6">
                    <SingleDatePicker
                      id="fromDate"
                      placeholder={intl.formatMessage({
                        id: "global.from",
                        defaultMessage: "From",
                      })}
                      block={true}
                      small={true}
                      isOutsideRange={() => false}
                      numberOfMonths={1}
                      initialDate={from}
                      isRTL={appState.state.language === "ar" ? true : false}
                      date={from}
                      onDateChange={(date) => setFrom(date)}
                      focused={focusedFrom}
                      onFocusChange={({ focused }) => setFocusedFrom(focused)}
                    />
                  </div>

                  <div className="col-lg-2 col-md-4 col-sm-12 col-xs-6">
                    <SingleDatePicker
                      id="toDate"
                      block={true}
                      placeholder={intl.formatMessage({
                        id: "global.to",
                        defaultMessage: "To",
                      })}
                      small={true}
                      isOutsideRange={() => false}
                      numberOfMonths={1}
                      initialDate={to}
                      isRTL={appState.state.language === "ar" ? true : false}
                      date={to}
                      onDateChange={(date) => {
                        setTo(date);
                      }}
                      focused={focusedTo}
                      onFocusChange={({ focused }) => setFocusedTo(focused)}
                    />
                  </div>
                  <div className="col-md-2 col-sm-4 col-xs-6">
                    <select
                      ref={statusSelectRef}
                      onChange={resetData}
                      className="form-control"
                    >
                      <option value="all">
                        {intl.formatMessage({
                          id: "order.status",
                          defaultMessage: "Status",
                        })}
                      </option>
                      <option value="Yes">
                        {intl.formatMessage({
                          id: "order.active",
                          defaultMessage: "Active",
                        })}
                      </option>
                      <option value="No">
                        {intl.formatMessage({
                          id: "order.suspended",
                          defaultMessage: "Suspended",
                        })}
                      </option>
                    </select>
                  </div>
                  <div className="col-md-2 col-sm-4 col-xs-6">
                    <select
                      ref={orderTypeRef}
                      onChange={resetData}
                      className="form-control"
                    >
                      <option value="all">
                        {intl.formatMessage({
                          id: "order.all",
                          defaultMessage: "Order Type",
                        })}
                      </option>
                      <option value="offer">
                        {intl.formatMessage({
                          id: "order.offer",
                          defaultMessage: "Offer",
                        })}
                      </option>
                      <option value="variant">
                        {intl.formatMessage({
                          id: "order.variant",
                          defaultMessage: "Variant",
                        })}
                      </option>
                      <option value="subscription">
                        {intl.formatMessage({
                          id: "order.subscription",
                          defaultMessage: "Subscription",
                        })}
                      </option>
                      {/* <option value="both">
                        {intl.formatMessage({
                          id: "order.both",
                          defaultMessage: "Both",
                        })}
                      </option> */}
                    </select>
                  </div>
                  <div className="col-md-2 col-sm-4 col-xs-6">
                    <select
                      ref={appointmenSelectRef}
                      onChange={resetData}
                      className="form-control"
                    >
                      <option value="all">
                        {intl.formatMessage({
                          id: "order.appointment",
                          defaultMessage: "Appointment",
                        })}
                      </option>
                      <option value="true">
                        {intl.formatMessage({
                          id: "order.yes",
                          defaultMessage: "Yes",
                        })}
                      </option>
                      <option value="false">
                        {intl.formatMessage({
                          id: "order.no",
                          defaultMessage: "No",
                        })}
                      </option>
                    </select>
                  </div>
                  <div className="col-md-2 col-sm-4 col-xs-6">
                    <select
                      ref={eShopSelectRef}
                      onChange={resetData}
                      className="form-control"
                    >
                      <option value="all">
                        {intl.formatMessage({
                          id: "order.eShop",
                          defaultMessage: "E-Shop",
                        })}
                      </option>
                      <option value="Yes">
                        {intl.formatMessage({
                          id: "order.yes",
                          defaultMessage: "Yes",
                        })}
                      </option>
                      <option value="No">
                        {intl.formatMessage({
                          id: "order.no",
                          defaultMessage: "No",
                        })}
                      </option>
                    </select>
                  </div>
                  <div className="col-md-2 col-sm-4 col-xs-6">
                    <select
                      ref={upsellSelectRef}
                      onChange={resetData}
                      className="form-control"
                    >
                      <option value="all">
                        {intl.formatMessage({
                          id: "order.upsell",
                          defaultMessage: "Upsell",
                        })}
                      </option>
                      <option value="Yes">
                        {intl.formatMessage({
                          id: "order.yes",
                          defaultMessage: "Yes",
                        })}
                      </option>
                      <option value="No">
                        {intl.formatMessage({
                          id: "order.no",
                          defaultMessage: "No",
                        })}
                      </option>
                    </select>
                  </div>
                  <div className="col-md-2 col-sm-4 col-xs-6">
                    <select
                      ref={refundStatusRef}
                      onChange={resetData}
                      className="form-control"
                    >
                      <option value="all">
                        {intl.formatMessage({
                          id: "order.all",
                          defaultMessage: "Refund Status",
                        })}
                      </option>
                      <option value={true}>
                        {intl.formatMessage({
                          id: "order.refunded",
                          defaultMessage: "Refunded",
                        })}
                      </option>
                      <option value={false}>
                        {intl.formatMessage({
                          id: "order.nonRefunded",
                          defaultMessage: "Non-Refunded",
                        })}
                      </option>
                    </select>
                  </div>
                  {/* <div className="col-md-2 col-sm-4 col-xs-6">
                    <select
                      ref={paymentBrandRef}
                      onChange={resetData}
                      className="form-control"
                    >
                      <option value="all">
                        {intl.formatMessage({
                          id: "order.appointmentOrEShop",
                          defaultMessage: "Appointment Or E-Shop",
                        })}
                      </option>
                      <option value="e-shop">
                        {intl.formatMessage({
                          id: "order.eShop",
                          defaultMessage: "E-Shop",
                        })}
                      </option>
                    </select>
                  </div> */}
                  <div className="col-md-2 col-sm-4 col-xs-6">
                    <select
                      ref={branchesRef}
                      onChange={resetData}
                      className="form-control"
                    >
                      <option value="all">
                        {intl.formatMessage({
                          id: "order.branches",
                          defaultMessage: "Branches",
                        })}
                      </option>
                      {branches?.map((branch) => (
                        <option value={branch?._id}>
                          {appState.state.language === "en"
                            ? branch?.name?.value_en
                            : branch?.name?.value_ar}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="col force--inline">
                    <div className="tooltip-master">
                      <button
                        className="btn btn-primary"
                        onClick={resetData}
                        disabled={disableFlag}
                      >
                        <i className="material-icons">search</i>
                      </button>
                      <span className="tooltiptext">
                        <FormattedMessage
                          id="global.filter"
                          defaultMessage="Filter"
                        />
                      </span>
                    </div>
                  </div>
                  <div className="col force--inline">
                    <div className="tooltip-master">
                      <button
                        className="btn btn-primary"
                        onClick={resetPage}
                        disabled={disableFlag}
                      >
                        <i className="material-icons">
                          settings_backup_restore
                        </i>
                      </button>
                      <span className="tooltiptext">
                        <FormattedMessage
                          id="global.resetFilter"
                          defaultMessage="Reset Filter"
                        />
                      </span>
                    </div>
                  </div>
                </form>
              </div>
            </CheckAccess>
            <CheckAccess
              activityId={ActivitiesEnum.orders_report_export_to_excel}
            >
              <div className="col-xs-3 pull-right">
                <div className="panel-group-buttons pull-right">
                  <ExportToExcel onExport={onExport} />
                </div>
              </div>
            </CheckAccess>

            {/* <div className="row">
              <div className="col-xs-3 pull-right">
                <div className="panel-group-buttons pull-right">
                  <CheckAccess activityId={ActivitiesEnum.variant__add_variant}>
                    <Link className="btn btn-special " to="/variant">
                      <span className="fa fa-plus" />{" "}
                      <FormattedMessage
                        id="variants.new_variant"
                        defaultMessage="New Variant"
                      />
                    </Link>
                  </CheckAccess>
                </div>
              </div>
            </div> */}

            <div className="row">
              <div className="col-md-12">
                <FormattedMessage
                  id="global.totalCount"
                  defaultMessage="Total Count"
                />

                <strong>
                  {" "}
                  <FormattedNumber
                    style="decimal"
                    maximumFractionDigits={0}
                    minimumFractionDigits={0}
                    value={count}
                  />{" "}
                </strong>
              </div>
            </div>
          </div>
          <CheckAccess activityId={ActivitiesEnum.orders_report_table}>
            <div className="table-responsive">
              {branchIds?.length ? (
                <Table
                  loadMore={loadMore}
                  hasMore={hasMore}
                  caption={
                    (!data || data?.length === 0) && (
                      <caption
                        style={{
                          captionSide: "bottom",
                          textAlign: "center",
                          margin: "20px",
                          color: "darkgray",
                        }}
                      >
                        <FormattedMessage
                          id="global.noData"
                          defaultMessage="No data available"
                        />
                      </caption>
                    )
                  }
                  headerRow={
                    <tr>
                      <th>
                        <FormattedMessage
                          id="order.placementTime"
                          defaultMessage="Order Placement time"
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          id="order.branch"
                          defaultMessage="Branch Name"
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          id="order.appointmentRef"
                          defaultMessage="Appointment Reference (If Any)"
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          id="order.orderId"
                          defaultMessage="Order Id"
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          id="order.paymentBranch"
                          defaultMessage="Payment Brand"
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          id="order.vatPercentage"
                          defaultMessage="VAT %"
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          id="order.customer"
                          defaultMessage="Customer Name"
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          id="order.customerNo"
                          defaultMessage="Customer Number"
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          id="order.type"
                          defaultMessage="Order Type"
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          id="order.plateId"
                          defaultMessage="Plate Id"
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          id="order.vatAmount"
                          defaultMessage="VAT Amount"
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          id="order.priceBeforePromo"
                          defaultMessage="Before Promo Price"
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          id="order.promoDiscount"
                          defaultMessage="Promo Discount"
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          id="order.priceAfterPromo"
                          defaultMessage="After Promo Price"
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          id="order.retailPrice"
                          defaultMessage="Retail Price"
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          id="order.netRevenue"
                          defaultMessage="Net Revenue"
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          id="order.items"
                          defaultMessage="No Of Items"
                        />
                      </th>

                      {/* <th>
                    <FormattedMessage
                      id="order.category"
                      defaultMessage="Category Name"
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="order.product"
                      defaultMessage="Product Name"
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="order.variant"
                      defaultMessage="Variant Name"
                    />
                  </th> */}
                      <th>
                        <FormattedMessage
                          id="order.amount"
                          defaultMessage="Total Amount (SAR)"
                        />
                      </th>

                      <th>
                        <FormattedMessage
                          id="order.cancelStatus"
                          defaultMessage="Cancel Status"
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          id="order.downloadDepositReceipt"
                          defaultMessage="Deposit Receipt"
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          id="order.finalInvoice"
                          defaultMessage="Final Invoice (Service)"
                        />
                      </th>
                      <th style={{ width: "20px" }} />
                    </tr>
                  }
                >
                  {data?.map((c) => {
                    var showEdit = false;

                    if (
                      (appState.state.token?.role === "sadmin" &&
                        c.approvalState === "approved") ||
                      (appState.state.token?.id === c.approvalRequestedBy &&
                        c.approvalState !== "approved")
                    )
                      showEdit = true;

                    return (
                      <tr key={c._id} className="cases-trow link-row">
                        <td>{moment(c?.createdAt).format("DD-MM-YYYYY")}</td>
                        <td>
                          {appState.state.language === "en"
                            ? c.branch?.name?.value_en
                            : c.branch?.name?.value_ar}
                        </td>
                        <td>{c?.appointmentHash || "N/A"}</td>
                        <td>{c?.orderIdHash}</td>
                        <td>{c?.paymentBrand}</td>
                        <td>15.00</td>
                        <td>
                          {c?.username === "null null"
                            ? "N/A"
                            : c?.username || ""}
                        </td>
                        <td>{c?.phoneNumber}</td>
                        <td>{c?.orderType}</td>
                        <td>{c?.carNumberPlate}</td>
                        <td>
                          {orderDeterminationCalculations(
                            c,
                            "+",
                            "autohub_vat_amount"
                          )}
                        </td>
                        <td>
                          {orderDeterminationCalculations(
                            c,
                            "+",
                            "priceBeforePromoDiscount"
                          )}
                        </td>
                        <td>
                          {orderDeterminationCalculations(
                            c,
                            "total",
                            "priceBeforePromoDiscount",
                            "priceAfterPromoDiscount"
                          )}
                        </td>
                        <td>
                          {orderDeterminationCalculations(
                            c,
                            "+",
                            "priceAfterPromoDiscount"
                          )}
                        </td>
                        <td>
                          {orderDeterminationCalculations(
                            c,
                            "+",
                            "retail_price"
                          )}
                        </td>
                        <td>00</td>
                        <td>
                          {c?.variantItems?.length + c?.offerItems?.length || 0}
                        </td>
                        <td>{c?.totalPrice || 0}</td>

                        <td
                          className={classNames({
                            "col-status-green": c.isActive,
                            "col-status-red": !c.isActive,
                          })}
                        >
                          <a href="javascript:;">
                            {c.isActive && (
                              <FormattedMessage
                                id="operator.true"
                                defaultMessage="True"
                              />
                            )}
                            {!c.isActive && (
                              <FormattedMessage
                                id="operator.false"
                                defaultMessage="False"
                              />
                            )}
                          </a>
                        </td>
                        <td className="download-icon">
                          {c?.offerItems?.length ? (
                            <i
                              class="fa fa-download"
                              onClick={() =>
                                handleInvoiceDownload(c?._id, true, "service")
                              }
                            ></i>
                          ) : (
                            "-"
                          )}
                        </td>
                        <td className="download-icon">
                          {c?.offerCompletedBy ? (
                            <i
                              class="fa fa-download"
                              onClick={() =>
                                handleInvoiceDownload(c?._id, false, "service")
                              }
                            ></i>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </Table>
              ) : (
                <></>
              )}
            </div>
          </CheckAccess>
        </div>
      </Layout>
    </Auth>
  );
};

export default withRouter(injectIntl(OrderReport));
