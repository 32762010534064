import React, { Component } from "react";
import Layout from "../shared/Layout";
import { FormattedMessage } from "react-intl";
import appState from "../../state/AppStateContainer";
export default class PermissionsChanged extends Component {
  async componentDidMount() {
    await appState.logout("permissions.invalidated");
  }

  render() {
    return (
      <Layout>
        <div className="dashboard-table-container card-layout">
          <div>
            <h2 className="card-title">
              <FormattedMessage
                id="error.permissionChanged"
                defaultMessage="Permissions Changed"
              />
            </h2>
            <p>
              <FormattedMessage
                id="error.permissionChangedMsg"
                defaultMessage="Some permissions for your role might have been changed by the authorities. Please log in again for the new permissions to take effect."
              />
            </p>
          </div>
        </div>
      </Layout>
    );
  }
}
