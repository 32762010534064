import React from "react";
import { useState } from "react";
import services from "../../services";
import { FormattedMessage, injectIntl } from "react-intl";
import { withAuthContext } from "../../state/AuthContext";
import withRouter from "../../util/withRouter";
import { NotificationManager } from "react-notifications";

function PagesDropdown({ page, isActive, intl, loading }) {
  const [option, setOption] = useState(isActive);

  const handleChange = async (event) => {
    loading(true);
    setOption(event.target.value);
    await services.addAndUpdatepage(page, event.target.value);
    loading(false);
    
    NotificationManager.success(
      <FormattedMessage
      id="global.operationSuccessful"
      defaultMessage="Operation Successful"
      />,
      <FormattedMessage id="global.success" defaultMessage="Success" />
    );
  };

  return (
    <label>
      <FormattedMessage
        id={"pages.PG" + page}
        defaultMessage={page}
      />
      <select
        value={option || isActive}
        onChange={handleChange}
      >
        <option value={true}>
          {intl.formatMessage({
            id: "pages.publishOption",
            defaultMessage: "Publish",
          })}
        </option>
        <option value={false}>
          {intl.formatMessage({
            id: "pages.unpublishOption",
            defaultMessage: "Unpublish",
          })}
        </option>
      </select>
    </label>
  );
}

export default withAuthContext(withRouter(injectIntl(PagesDropdown)));
