import React, { useState, useEffect, useRef } from "react";
import { FormattedMessage, FormattedNumber, injectIntl } from "react-intl";
import Layout from "../shared/Layout";
import QuotationService from "../../services/quotationService";
import _ from "lodash";
import Auth from "../shared/Auth";
import { Route } from "react-router-dom";
import withRouter from "../../util/withRouter";
import Loading from "../shared/Loading";
import Table from "../shared/Table";
import HeaderTitle from "../shared/HeaderTitle";
import CheckAccess from "../shared/CheckAccess";
import { ActivitiesEnum } from "../../staticData/Activities";
import QuoteRequestForm from "./quoteRequestForm";
import moment from "moment";
import appState from "../../state/AppStateContainer";
import { SingleDatePicker } from "react-dates";
import ExportToExcel from "../shared/ExportToExcel";
import services from "../../services";

const QuoteServiceList = (props) => {
  const { intl, navigate } = props;

  const [count, setCount] = useState(0);
  const [data, setData] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [pageIndex, setPageIndex] = useState(-1);
  const [pageSize, setPageSize] = useState(10);
  const [searchFilter, setSearchFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("all");
  const [loading, setLoading] = useState(false);
  const [disableFlag, setDisableFlag] = useState(false);
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [focusedFrom, setFocusedFrom] = useState(false);
  const [focusedTo, setFocusedTo] = useState(false);

  const searchInputRef = useRef();
  const prevPropsRef = useRef();
  const statusRef = useRef();

  useEffect(() => {
    prevPropsRef.current = props;
  }, []);

  const prevProps = prevPropsRef.current;

  useEffect(() => {
    if (props.location.pathname !== prevProps?.location?.pathname) {
      if (!props.match.params.isFormOpen) {
        if (_.get(props.location, "state.isSubmitted") === true) {
          setPageIndex(-1);
          setData([]);
          setHasMore(true);
          setSearchFilter(searchInputRef.current.value);
          setStatusFilter(statusRef.current.value);
          setDisableFlag(true);
        }
      }
    }
  }, [props.location.pathname]);

  const loadMore = async () => {
    setLoading(true);
    setPageIndex((prevState) => prevState + 1);

    setHasMore(false);
    const _pageIndex = pageIndex + 1;

    const options = {
      progressBar: true,
      alphabeticalOrder: true,
      fromDate: from ? moment(from).startOf("day").toString() : null,
      toDate: to ? moment(to).endOf("day").toString() : null,
    };

    const pageData = await QuotationService.getRequestedQuotes(
      searchFilter,
      statusFilter,
      _pageIndex,
      pageSize,
      from ? moment(from).startOf("day").toString() : null,
      to ? moment(to).endOf("day").toString() : null,
      undefined,
      options
    );
    let summary = { count };

    if (_pageIndex === 0) {
      summary = await QuotationService.getRequestedQuotes(
        searchFilter,
        statusFilter,
        undefined,
        undefined,
        from ? moment(from).startOf("day").toString() : null,
        to ? moment(to).endOf("day").toString() : null,
        undefined,
        options,
        summary
      );
    }

    setData((prevData) => [...prevData, ...pageData]);
    setHasMore(pageData.length > 0);
    setDisableFlag(false);
    setLoading(false);
    if (_pageIndex === 0) {
      setCount(summary.count);
    }
  };

  const resetData = (e) => {
    setPageIndex(-1);
    setData([]);
    setHasMore(true);
    setSearchFilter(searchInputRef.current.value);
    setStatusFilter(statusRef.current.value);
    setDisableFlag(true);
    if (e) e.preventDefault();
  };

  const resetPage = (e) => {
    setPageIndex(-1);
    setData([]);
    setHasMore(true);
    setSearchFilter("");
    setStatusFilter("all");
    setDisableFlag(true);
    setFrom("");
    setTo("");

    searchInputRef.current.value = "";
    statusRef.current.value = "all";
    e.preventDefault();
  };

  // const onExport = async (startDate, endDate) => {
  //   if (!startDate) return;
  //   if (!endDate) {
  //     endDate = startDate;
  //   }
  //   setLoading(true);
  //   try {
  //     await services.downloadEstimationReport(
  //       searchFilter,
  //       statusFilter,
  //       moment(startDate)
  //         .utcOffset(60 * 3)
  //         .startOf("day")
  //         .toString(),
  //       moment(endDate)
  //         .utcOffset(60 * 3)
  //         .endOf("day")
  //         .toString()
  //     );
  //   } catch (err) {}
  //   setLoading(false);
  // };

  const lang = appState.state.language;

  return (
    <Layout hidden={props.match.params.isFormOpen} loading={loading}>
      <HeaderTitle
        id="menu.quoteRequestsTitle"
        defaultMessage="Quotation Requests"
      />

      <div className="table-header clearfix">
        <form className="row" onSubmit={resetData}>
          <div className="col-md-2 col-sm-4 col-xs-6">
            <div className="has-feedback">
              <input
                type="text"
                ref={searchInputRef}
                className="form-control"
                placeholder={intl.formatMessage({
                  id: "global.search",
                  defaultMessage: "Search",
                })}
              />
              <i className="glyphicon glyphicon-search form-control-feedback" />
            </div>
          </div>

          <div className="col-md-2 col-sm-4 col-xs-6">
            <select
              ref={statusRef}
              onChange={resetData}
              className="form-control"
            >
              <option value="all">
                {intl.formatMessage({
                  id: "global.status",
                  defaultMessage: "Status",
                })}
              </option>
              <option value="In Progress">
                {intl.formatMessage({
                  id: "quote.inProgress",
                  defaultMessage: "In Progress",
                })}
              </option>
              <option value="Completed">
                {intl.formatMessage({
                  id: "quote.completed",
                  defaultMessage: "Completed",
                })}
              </option>
            </select>
          </div>
          <div className="col-md-2 col-sm-4 col-xs-6">
            <SingleDatePicker
              id="fromDate"
              placeholder={intl.formatMessage({
                id: "global.from",
                defaultMessage: "From",
              })}
              block={true}
              small={true}
              isOutsideRange={() => false}
              numberOfMonths={1}
              initialDate={from}
              isRTL={appState.state.language === "ar" ? true : false}
              date={from}
              onDateChange={(date) => {
                setFrom(date);
                resetData();
              }}
              focused={focusedFrom}
              onFocusChange={({ focused }) => setFocusedFrom(focused)}
            />
          </div>
          <div className="col-md-2 col-sm-4 col-xs-6">
            <SingleDatePicker
              id="toDate"
              block={true}
              placeholder={intl.formatMessage({
                id: "global.to",
                defaultMessage: "To",
              })}
              small={true}
              isOutsideRange={() => false}
              numberOfMonths={1}
              initialDate={to}
              isRTL={appState.state.language === "ar" ? true : false}
              date={to}
              onDateChange={(date) => {
                setTo(date);
                resetData();
              }}
              focused={focusedTo}
              onFocusChange={({ focused }) => setFocusedTo(focused)}
            />
          </div>

          <div className="col force--inline">
            <div className="tooltip-master">
              <button
                href="javascript:;"
                className="btn btn-primary"
                onClick={resetData}
                disabled={disableFlag}
              >
                <i className="material-icons">search</i>
              </button>
              <span className="tooltiptext">
                <FormattedMessage id="global.filter" defaultMessage="Filter" />
              </span>
            </div>
          </div>
          <div className="col force--inline">
            <div className="tooltip-master">
              <button
                href="javascript:;"
                className="btn btn-primary"
                onClick={resetPage}
                disabled={disableFlag}
              >
                <i className="material-icons">settings_backup_restore</i>
              </button>
              <span className="tooltiptext">
                <FormattedMessage
                  id="global.resetFilter"
                  defaultMessage="Reset Filter"
                />
              </span>
            </div>
          </div>
        </form>
      </div>

      <div style={{ margin: "20px 0" }}>
        <div>
          <FormattedMessage
            id="global.totalCount"
            defaultMessage="Total Count"
          />

          <strong>
            {" "}
            <FormattedNumber
              style="decimal"
              maximumFractionDigits={0}
              minimumFractionDigits={0}
              value={count}
            />{" "}
          </strong>
        </div>
      </div>
      <div className="dashboard-table-container card-layout">
        <div className="table-responsive">
          <Table
            loadMore={loadMore}
            hasMore={hasMore}
            caption={
              (!data || data == 0) && (
                <caption
                  style={{
                    captionSide: "bottom",
                    textAlign: "center",
                    margin: "20px",
                    color: "darkgray",
                  }}
                >
                  <FormattedMessage
                    id="global.noData"
                    defaultMessage="No data available"
                  />
                </caption>
              )
            }
            headerRow={
              <tr>
                <th>
                  <FormattedMessage
                    id="quote.fullName"
                    defaultMessage="Full Name"
                  />
                </th>
                <th>
                  <FormattedMessage
                    defaultMessage="Phone Number"
                    id="quote.phoneNumber"
                  />
                </th>
                <th>
                  <FormattedMessage
                    defaultMessage="Licence Plate"
                    id="quote.licencePlate"
                  />
                </th>
                <th>
                  <FormattedMessage
                    id="quote.serviceName"
                    defaultMessage="Service Name"
                  />
                </th>
                <th>
                  <FormattedMessage
                    id="quote.quoteIdHash"
                    defaultMessage="Quote Id Hash"
                  />
                </th>
                <th>
                  <FormattedMessage id="quote.status" defaultMessage="Status" />
                </th>
                <th>
                  <FormattedMessage
                    id="quote.createdAt"
                    defaultMessage="Created At"
                  />
                </th>
              </tr>
            }
          >
            {data?.map((quote) => (
              <tr
                key={quote._id}
                onClick={() => navigate(`/quotation-request/${quote._id}`)}
                className="cases-trow link-row"
              >
                <td>{quote.firstName + " " + quote.lastName}</td>
                <td className="phoneNumber-alignment">{quote.phoneNumber}</td>
                <td>{quote.licencePlate}</td>
                <td>{quote.serviceName["value_" + lang]}</td>
                <td>{quote.quoteIdHash}</td>
                <td>{quote.status}</td>
                <td>{moment(quote.createdAt).utc().format("YYYY-MM-DD")}</td>
              </tr>
            ))}
          </Table>
        </div>
      </div>
    </Layout>
  );
};

export default withRouter(injectIntl(QuoteServiceList));
