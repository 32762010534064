import React from "react";
import { FormattedMessage } from "react-intl";
import { Modal, Button } from "react-bootstrap";
import _ from "lodash";
import moment from "moment";
import appState from "../../state/AppStateContainer";

const AdminAlerts = ({ show, onCancel, data }) => {
  const getLocalDateTime = (givenDate) => {
    return moment.utc(givenDate).local().format("ddd, MMM D, YYYY h:mm A");
  };

  return (
    <>
      <Modal
        show={show}
        onHide={() => onCancel()}
        dialogClassName="history-modal"
      >
        <Modal.Header closeButton={true}>
          <h4>
            <FormattedMessage
              id="alert.notifications"
              defaultMessage="Notifications"
            />
          </h4>
        </Modal.Header>
        <Modal.Body className="alerts-modal-body">
          {data?.alerts ? (
            data?.alerts?.map((alert) => {
              return (
                <>
                  <div className="alert-details-wrapper">
                    <p>
                      {alert?.message["value_" + appState?.state?.language]}
                    </p>
                    <span>{getLocalDateTime(alert?.createdAt)}</span>
                  </div>
                </>
              );
            })
          ) : (
            <div className="no-record-text">
              <FormattedMessage
                id="alert.noRecordFound"
                defaultMessage="No Records"
              />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle="link" onClick={onCancel}>
            <FormattedMessage id="global.cancel" defaultMessage="Cancel" />
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default React.memo(AdminAlerts);
