import _ from "lodash"
const handleErrors = (error) => {
  if (error === "error.OrderNotFound") {
    return {
      id: error,
      defaultMessage: {
        value_en: "Order Not Found",
        value_ar: "الطلب غير موجود",
      },
    };
  } else if (error === "error.qrCodeExpired") {
    return {
      id: error,
      defaultMessage: {
        value_en: "Qr Code expired",
        value_ar: "الطلب غير موجود",
      },
    };
  } else if (error === "error.orderAlreadyCompleted") {
    return {
      id: error,
      defaultMessage: {
        value_en: "Order already completed",
        value_ar: "الطلب قد اكتمل بالفعل",
      },
    };
  } else if (error === "error.paymentMethodNotExist") {
    return {
      id: error,
      defaultMessage: {
        value_en: "Payment method does not exist",
        value_ar: "طريقة الدفع غير موجودة",
      },
    };
  } 
  else if (error === "error.orderChainAndOperatorChainIsDifferent") {
    return {
      id: error,
      defaultMessage: {
        value_en: "Invalid Order",
        value_ar: "طلب غير صحيح",
      },
    };
  } 
  else if (error === "error.orderAlreadyCompleted") {
    return {
      id: error,
      defaultMessage: {
        value_en: "Order Already Completed",
        value_ar: "الطلب قد اكتمل بالفعل"
      },
    };
  } 
  else if (error === "error.orderNotPaid") {
    return {
      id: error,
      defaultMessage: {
        value_en: "Order Not Paid",
        value_ar:"طلب غير مدفوع",
      },
    };
  }
  else if (error === "error.unexpectedError"){
    return {
      id: "error.unexpectedError",
      defaultMessage: "Unexpected Error Occured",
    };
  }
  else if (error === "campaign.alreadyActiveCampaignExist"){
    return {
      id: "campaign.alreadyActiveCampaignExist",
      defaultMessage: "There's Already active campaign",
    };
  }
  else if (!_.isEmpty(error)) {
    return {
      id: error,
      defaultMessage: error,
    };
  }
  else {
    return {
      id: "error.unexpectedError",
      defaultMessage: "Unexpected Error Occured",
    };
  }
};

const handlePhoneNumberPosition = (phone,language)=>{
  if (phone && language === "ar"){
    phone = phone?.split("+")
    if(phone?.length>1){
      return phone[1]+"+"
    }
  }
  return phone
}

export { handleErrors, handlePhoneNumberPosition };
