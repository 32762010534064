import React, { Component, createRef } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Form, Formik } from "formik";
import { Yup } from "../../util";
import Layout from "../shared/Layout";
import services from "../../services";
import { NotificationManager } from "react-notifications";
import _ from "lodash";
import Auth from "../shared/Auth";
import FieldRow from "../shared/FieldRow";
import withRouter from "../../util/withRouter";
import appState from "../../state/AppStateContainer";
import moment from "moment";
import { withAuthContext } from "../../state/AuthContext";
import Select from "react-select";
import CheckAccess from "../shared/CheckAccess";
import { ActivitiesEnum } from "../../staticData/Activities";
import { Button } from "react-bootstrap";
import HeaderTitle from "../shared/HeaderTitle";

export class PromoForm extends Component {
  constructor() {
    super();
    this.formik = createRef();
  }

  state = {
    value: {},
    data: {},
    offers: [],
    branches: [],
    products: [],
    loading: true,
  };

  async componentDidMount() {
    try {
      this.subscription = appState.emitter.addListener(
        "onLanguageChange",
        () => {
          this.formik.validateForm();
          this.setState({
            value: {
              startDate: moment(this.state.value?.startDate).locale(
                appState.state.language
              ),
              endDate: moment(this.state.value?.endDate).locale(
                appState.state.language
              ),
            },
          });
        }
      );

      const offersData = await services.getOffersForPromo();
      const branchesData = await services.getBranchesForPromo();
      const productsData = await services.getProducts("", "all", "all");

      let data = this.props.match.params.id
        ? await services.getPromo(this.props.match.params.id, {
            progressBar: true,
          })
        : {};

      data.offers = data?.offers?.map((x) => {
        return {
          value: x._id,
          label:
            appState.state.language === "en"
              ? x.name?.value_en
              : x.name?.value_ar || x.name?.value_en,
          type: "offers",
        };
      });

      data.branches = data?.branches?.map((x) => {
        return {
          value: x._id,
          label:
            appState.state.language === "en"
              ? x.name?.value_en
              : x.name?.value_ar || x.name?.value_en,
          type: "branches",
        };
      });
      data.associatedProducts = data?.associatedProducts?.map((x) => {
        return {
          value: x._id,
          label:
            appState.state.language === "en"
              ? x.name?.value_en
              : x.name?.value_ar || x.name?.value_en,
          type: "products",
        };
      });

      this.setState({
        data: data,
        offers: [
          {
            label: "Offers",
            options: offersData.map((x) => {
              return {
                value: x._id,
                label:
                  appState.state.language === "en"
                    ? x.name?.value_en
                    : x.name?.value_ar || x.name?.value_en,
                type: "offers",
              };
            }),
          },
        ],
        branches: [
          {
            label: "branches",
            options: branchesData.map((x) => {
              return {
                value: x._id,
                label:
                  appState.state.language === "en"
                    ? x.name?.value_en
                    : x.name?.value_ar || x.name?.value_en,
                type: "branches",
              };
            }),
          },
        ],
        products: [
          {
            label: "products",
            options: productsData.map((x) => {
              return {
                value: x.id,
                label:
                  appState.state.language === "en"
                    ? x.englishName
                    : x.arabicName || x.englishName,
                type: "products",
              };
            }),
          },
        ],
      });

      if (this.props.match.params.id) {
        const start_date = moment(this.state?.data?.datePicker?.startDate);
        const end_date = moment(this.state?.data?.datePicker?.endDate);
        this.setState({
          value: {
            startDate: start_date,
            endDate: end_date,
          },
        });
      }
    } catch (e) {
      this.props.navigate("/error", {
        error: _.get(e, "response.data.error", "error.unexpectedError"),
      });
    } finally {
      this.setState({ loading: false });
    }
  }

  componentWillUnmount() {
    this.subscription.remove();
  }

  async handleSubmit(values, { setSubmitting }) {
    setSubmitting(true);
    this.setState({ loading: true });
    try {
      if (!values.percentageOffer && !values.fixedAmountOffer) {
        NotificationManager.error(
          this.props.intl.formatMessage({
            id: "promo.atLeastOneIsRequired",
            defaultMessage:
              "Please select offer in percentage or offer in fixed amount to add the Promo Code",
          }),
          this.props.intl.formatMessage({
            id: "global.error",
            defaultMessage: "Error",
          })
        );
        return;
      }

      let response;

      let offers = values.offers
        .filter((x) => x.type === "offers")
        .map((x) => x.value);

      let branches = values.branches
        .filter((x) => x.type === "branches")
        .map((x) => x.value);

      let associatedProducts = values.associatedProducts
        .filter((x) => x.type === "products")
        .map((x) => x.value);

      if (offers[0] === "All Offers") {
        offers = this.state.offers[0]?.options?.map(({ value }) => value);
      }
      if (branches[0] === "All Branches") {
        branches = await services.getAllBranches();
      }
      if (associatedProducts[0] === "All Products") {
        associatedProducts = await services.getAllProducts();
      }
      values = { ...values, offers, branches, associatedProducts };

      if (!this.props.match.params.id) {
        response = await services.addPromo(values);
        // response = await services.addMultiplePromo(values); for multipe promo creation
      } else {
        const request = { id: this.props.match.params.id, ...values };
        response = await services.updatePromo(request);
      }

      NotificationManager.success(
        this.props.intl.formatMessage({
          id: _.get(response, "message", "global.operationSuccessful"),
          defaultMessage: "Operation Successful",
        }),
        this.props.intl.formatMessage({
          id: "global.success",
          defaultMessage: "Success",
        })
      );

      this.props.navigate("/promos");
    } catch (e) {
      NotificationManager.error(
        this.props.intl.formatMessage({
          id: _.get(e, "response.data.error", "error.unexpectedError"),
          defaultMessage: "An unexpected error has occurred",
        }),
        this.props.intl.formatMessage({
          id: "global.error",
          defaultMessage: "Error",
        })
      );
    } finally {
      setSubmitting(false);
      this.setState({ loading: false });
    }
  }

  handleChange(value, { setFieldValue }) {
    setFieldValue("percentageOffer", value);
    if (!value) {
      setFieldValue("maximumOfferLimit", "");
    }
  }

  render() {
    const { intl } = this.props;

    return (
      <Auth requireAuth={true} roles={["sadmin"]}>
        <Formik
          innerRef={(f) => {
            this.formik = f;
          }}
          enableReinitialize
          initialValues={{
            promoCode: this.state.data.promoCode || "",
            promoDesc: this.state.data.promoDesc || "",
            datePicker: this.state.data.datePicker || "",
            percentageOffer: this.state.data.percentageOffer || undefined,
            fixedAmountOffer: this.state.data.fixedAmountOffer || undefined,
            frequencyPerUser: this.state.data.frequencyPerUser || undefined,
            frequencyPerPromo: this.state.data.frequencyPerPromo || undefined,
            maximumOfferLimit: this.state.data.maximumOfferLimit || undefined,
            minimumOrderLimit: this.state.data.minimumOrderLimit || undefined,
            offers: this.state.data.offers || [],
            branches: this.state.data.branches || [],
            isActiveForEshop: this.state.data.isActiveForEshop,
            isActiveForAddOn: this.state.data.isActiveForAddOn,
            isActiveForUpsell: this.state.data.isActiveForUpsell,
            isActive: this.props.match.params.id
              ? this.state.data.isActive
              : true,
            associatedProducts: this.state.data.associatedProducts || [],
          }}
          validate={(values) => {
            let errors = {};
            if (
              values.percentageOffer &&
              (!values.maximumOfferLimit || values.maximumOfferLimit === "")
            ) {
              errors.maximumOfferLimit = intl.formatMessage({
                id: "error.maximumOfferLimitisRequired",
                defaultMessage: "Maximum offer limit is required",
              });
            }

            if (
              !values.isActiveForEshop &&
              !values.isActiveForAddOn &&
              !values.isActiveForUpsell
            ) {
              errors.isActiveForEshop = intl.formatMessage({
                id: "error.isActiveForRequired",
                defaultMessage: "At least one needs to be checked",
              });

              errors.isActiveForAddOn = intl.formatMessage({
                id: "error.isActiveForRequired",
                defaultMessage: "At least one needs to be checked",
              });

              errors.isActiveForUpsell = intl.formatMessage({
                id: "error.isActiveForRequired",
                defaultMessage: "At least one needs to be checked",
              });
            }
            return errors;
          }}
          validationSchema={Yup.object().shape({
            promoCode: Yup.string()
              .label(
                intl.formatMessage({
                  id: "promo.promoCode",
                  defaultMessage: "Promo code",
                })
              )
              .trim()
              .required(),
            promoDesc: Yup.string()
              .label(
                intl.formatMessage({
                  id: "promo.promoDesc",
                  defaultMessage: "Promo description",
                })
              )
              .trim()
              .required(),
            promoDesc: Yup.string()
              .label(
                intl.formatMessage({
                  id: "promo.promoDesc",
                  defaultMessage: "Promo Description",
                })
              )
              .trim()
              .required(),
            percentageOffer: Yup.number()
              .typeError(
                intl.formatMessage({
                  id: "promo.youMustSpecifyANumber",
                  defaultMessage: "You must specify a number",
                })
              )
              .label(
                intl.formatMessage({
                  id: "promo.percentageOffer",
                  defaultMessage: "Offer percentage",
                })
              )
              .min(1)
              .max(100),
            fixedAmountOffer: Yup.number()
              .typeError(
                intl.formatMessage({
                  id: "promo.youMustSpecifyANumber",
                  defaultMessage: "You must specify a number",
                })
              )
              .label(
                intl.formatMessage({
                  id: "promo.fixedAmountOffer",
                  defaultMessage: "Fixed amount offer",
                })
              )
              .min(1),
            frequencyPerUser: Yup.number()
              .typeError(
                intl.formatMessage({
                  id: "promo.youMustSpecifyANumber",
                  defaultMessage: "You must specify a number",
                })
              )
              .label(
                intl.formatMessage({
                  id: "promo.frequencyPerUser",
                  defaultMessage: "Frequency per user",
                })
              )
              .min(1)
              .required(),
            maximumOfferLimit: Yup.number()
              .typeError(
                intl.formatMessage({
                  id: "promo.youMustSpecifyANumber",
                  defaultMessage: "You must specify a number",
                })
              )
              .label(
                intl.formatMessage({
                  id: "promo.maximumOfferLimit",
                  defaultMessage: "Maximum offer limit",
                })
              )
              .min(1),
            minimumOrderLimit: Yup.number()
              .typeError(
                intl.formatMessage({
                  id: "promo.youMustSpecifyANumber",
                  defaultMessage: "You must specify a number",
                })
              )
              .label(
                intl.formatMessage({
                  id: "promo.minimumOrderLimit",
                  defaultMessage: "Minimum order limit",
                })
              )
              .min(1)
              .required(),
            frequencyPerPromo: Yup.number()
              .typeError(
                intl.formatMessage({
                  id: "promo.youMustSpecifyANumber",
                  defaultMessage: "You must specify a number",
                })
              )
              .label(
                intl.formatMessage({
                  id: "promo.frequencyPerPromo",
                  defaultMessage: "Frequency per promo",
                })
              )
              .min(1)
              .required(),
            datePicker: Yup.mixed()
              .label(
                intl.formatMessage({
                  id: "promo.datePicker",
                  defaultMessage: "Date",
                })
              )
              .required(),
          })}
          onSubmit={this.handleSubmit.bind(this)}
        >
          {(formikProps) => {
            const { setFieldValue, values, setTouched, isSubmitting } =
              formikProps;

            return (
              <Layout loading={this.state.loading}>
                <HeaderTitle
                  id="promo.promos"
                  defaultMessage="Promotion Codes"
                />
                <div className="dashboard-table-container card-layout">
                  <div className="panel-primary">
                    <header className="panel-title">
                      {this.props.match.params.id && (
                        <FormattedMessage
                          id="promo.update"
                          defaultMessage="Update Promotion Code"
                        />
                      )}
                      {!this.props.match.params.id && (
                        <FormattedMessage
                          id="promo.add"
                          defaultMessage="Add Promo code"
                        />
                      )}
                    </header>

                    <div className="">
                      <Form>
                        <div className="dashboard-row">
                          <FieldRow
                            name="promoCode"
                            labelId="promo.promoCode"
                            defaultLabel="Promo Code"
                            placeholderId="promo.enterPromo"
                            defaultPlaceholder="Enter Promo Code"
                            showRequiredFlag={true}
                            formikProps={formikProps}
                          />
                          <FieldRow
                            name="promoDesc"
                            labelId="promo.promoDesc"
                            defaultLabel="Promo Description"
                            placeholderId="promo.enterPromoDescription"
                            defaultPlaceholder="Enter Promo Description"
                            showRequiredFlag={true}
                            formikProps={formikProps}
                          />
                          <FieldRow
                            startDatePlaceholderText={this.props.intl.formatMessage(
                              {
                                id: "promo.startDate",
                                defaultMessage: "Start Date",
                              }
                            )}
                            endDatePlaceholderText={this.props.intl.formatMessage(
                              {
                                id: "promo.endDate",
                                defaultMessage: "End Date",
                              }
                            )}
                            type="date"
                            name="datePicker"
                            data={this.state.data.datePicker}
                            initialStartDate={this.state.value.startDate}
                            initialEndDate={this.state.value.endDate}
                            labelId="promo.date"
                            defaultLabel="Date"
                            placeholderId="promo.date"
                            formikProps={formikProps}
                            showRequiredFlag={true}
                          />

                          <div className="row" style={{ marginTop: "20px" }}>
                            <div className="col-md-2 col-xs-12">
                              <label className="form-label caps block">
                                <FormattedMessage
                                  id="promo.offers"
                                  defaultMessage={"Offers Selection"}
                                />
                              </label>
                            </div>
                            <div className="col-md-8 col-xs-12 no-bottom ">
                              <Select
                                closeMenuOnSelect={false}
                                isDisabled={false}
                                isMulti={true}
                                options={[
                                  { value: "All Offers", label: "All Offers" }, // "All Offers" option at the top
                                  ...this.state.offers, // Rest of the options
                                ]}
                                placeholder={this.props.intl.formatMessage({
                                  id: "promo.allOffers",
                                  defaultMessage: "Select Offer(s)",
                                })}
                                value={values.offers}
                                onChange={(v) => {
                                  if (
                                    v.some(
                                      (item) => item.value === "All Offers"
                                    )
                                  ) {
                                    // If "All Offers" is selected, set offers to only "all"
                                    setFieldValue("offers", [
                                      {
                                        value: "All Offers",
                                        label: "All Offers",
                                        type: "offers",
                                      },
                                    ]);
                                  } else {
                                    // If other options are selected, remove "all" option and update offers
                                    setFieldValue(
                                      "offers",
                                      v.filter(
                                        (item) => item.value !== "All Offers"
                                      )
                                    );
                                  }
                                  setTouched({ offers: true });
                                }}
                              />
                            </div>
                          </div>

                          <div className="row" style={{ marginTop: "20px" }}>
                            <div className="col-md-2 col-xs-12">
                              <label className="form-label caps block">
                                <FormattedMessage
                                  id="promo.branches"
                                  defaultMessage={"Branches Selection"}
                                />
                              </label>
                            </div>

                            <div className="col-md-8 col-xs-12 no-bottom ">
                              <Select
                                closeMenuOnSelect={false}
                                isDisabled={false}
                                isMulti={true}
                                options={[
                                  {
                                    value: "All Branches",
                                    label: "All Branches",
                                  }, // "All Branches" option at the top
                                  ...this.state.branches, // Rest of the options
                                ]}
                                placeholder={this.props.intl.formatMessage({
                                  id: "promo.allBranches",
                                  defaultMessage: "Select Branch(es)",
                                })}
                                value={values.branches}
                                onChange={(v) => {
                                  if (
                                    v.some(
                                      (item) => item.value === "All Branches"
                                    )
                                  ) {
                                    // If "All Branches" is selected, set branches to only "All Branches"
                                    setFieldValue("branches", [
                                      {
                                        value: "All Branches",
                                        label: "All Branches",
                                        type: "branches",
                                      },
                                    ]);
                                  } else {
                                    // If other options are selected, remove "all" option and update branches
                                    setFieldValue(
                                      "branches",
                                      v.filter(
                                        (item) => item.value !== "All Branches"
                                      )
                                    );
                                  }
                                  setTouched({ branches: true });
                                }}
                              />
                            </div>
                          </div>

                          <div className="row" style={{ marginTop: "20px" }}>
                            <div className="col-md-2 col-xs-12">
                              <label className="form-label caps block">
                                <FormattedMessage
                                  id="promo.products"
                                  defaultMessage="Products Selection"
                                />
                              </label>
                            </div>

                            <div className="col-md-8 col-xs-12 no-bottom ">
                              <Select
                                closeMenuOnSelect={false}
                                isDisabled={false}
                                isMulti={true}
                                options={[
                                  {
                                    value: "All Products",
                                    label: "All Products",
                                  }, // "All Products" option at the top
                                  ...this.state.products, // Rest of the options
                                ]}
                                placeholder={this.props.intl.formatMessage({
                                  id: "promo.allProducts",
                                  defaultMessage: "Select Product(s)",
                                })}
                                value={values.associatedProducts}
                                onChange={(v) => {
                                  if (
                                    v.some(
                                      (item) => item.value === "All Products"
                                    )
                                  ) {
                                    // If "All Products" is selected, set products to only "All Products"
                                    setFieldValue("associatedProducts", [
                                      {
                                        value: "All Products",
                                        label: "All Products",
                                        type: "products",
                                      },
                                    ]);
                                  } else {
                                    // If other options are selected, remove "all" option and update products
                                    setFieldValue(
                                      "associatedProducts",
                                      v.filter(
                                        (item) => item.value !== "All Products"
                                      )
                                    );
                                  }
                                  setTouched({ products: true });
                                }}
                              />
                            </div>
                          </div>

                          <FieldRow
                            type="number"
                            name="percentageOffer"
                            labelId="promo.percentageDiscount"
                            defaultLabel="Offer In Percentage"
                            placeholderId="promo.discount"
                            defaultPlaceholder="Enter Offer in Percentage"
                            formikProps={formikProps}
                            onChange={(e) =>
                              this.handleChange(e.target.value, formikProps)
                            }
                            disabled={values.fixedAmountOffer}
                          />
                          <FieldRow
                            type="number"
                            name="maximumOfferLimit"
                            labelId="promo.maximumOfferLimit"
                            defaultLabel="Maximum Offer Limit"
                            placeholderId="promo.enterMaximumOfferLimit"
                            defaultPlaceholder="Enter Maximum Offer Limit"
                            formikProps={formikProps}
                            showRequiredFlag={values.percentageOffer}
                            disabled={!values.percentageOffer}
                          />
                          <FieldRow
                            type="number"
                            name="fixedAmountOffer"
                            labelId="promo.fixedAmountOffer"
                            defaultLabel="Fixed Amount Offer"
                            placeholderId="promo.discount"
                            defaultPlaceholder="Enter Fixed Amount Offer"
                            formikProps={formikProps}
                            disabled={values.percentageOffer}
                          />

                          <FieldRow
                            type="number"
                            name="frequencyPerUser"
                            labelId="promo.frequencyPerUser"
                            defaultLabel="Frequency (Per User)"
                            placeholderId="promo.enterFrequencyPerUser"
                            defaultPlaceholder="Enter frequency Per User"
                            formikProps={formikProps}
                            showRequiredFlag={true}
                          />
                          <FieldRow
                            type="number"
                            name="frequencyPerPromo"
                            labelId="promo.frequencyPerPromo"
                            defaultLabel="Frequency (Per Promo)"
                            placeholderId="promo.enterFrequencyPerPromo"
                            defaultPlaceholder="Enter frequency Per Promo"
                            formikProps={formikProps}
                            showRequiredFlag={true}
                          />

                          <FieldRow
                            type="number"
                            name="minimumOrderLimit"
                            labelId="promo.minimumOrderLimit"
                            defaultLabel="minimum Order Limit"
                            placeholderId="promo.enterminimumOrderLimit"
                            defaultPlaceholder="Enter minimum Order Limit"
                            formikProps={formikProps}
                            showRequiredFlag={true}
                          />
                          <FieldRow
                            name="isActive"
                            type="checkbox"
                            labelId="operator.isActive"
                            defaultLabel="Active"
                            formikProps={formikProps}
                          />

                          <FieldRow
                            name="isActiveForEshop"
                            type="checkbox"
                            labelId="operator.isActiveForEshop"
                            defaultLabel="Active For Eshop"
                            formikProps={formikProps}
                          />

                          <FieldRow
                            name="isActiveForAddOn"
                            type="checkbox"
                            labelId="operator.isActiveForAddOn"
                            defaultLabel="Active For AddOn"
                            formikProps={formikProps}
                          />

                          <FieldRow
                            name="isActiveForUpsell"
                            type="checkbox"
                            labelId="operator.isActiveForUpsell"
                            defaultLabel="Active For Upsell"
                            formikProps={formikProps}
                          />
                        </div>
                        <div className="dashboard-row -nobottom">
                          <CheckAccess
                            activityId={
                              ActivitiesEnum.promo_management__update_promotion_code_edit
                            }
                          >
                            {this.props.authContext.authorized && (
                              <input
                                type="submit"
                                className="btn btn-primary"
                                value={this.props.intl.formatMessage({
                                  id: "global.submit",
                                  defaultMessage: "Submit",
                                })}
                                disabled={isSubmitting}
                              />
                            )}
                          </CheckAccess>
                          <Button
                            variant="link"
                            onClick={() => this.props.navigate("/promos")}
                            disabled={isSubmitting}
                          >
                            {this.props.intl.formatMessage({
                              id: "global.cancel",
                              defaultMessage: "Cancel",
                            })}
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              </Layout>
            );
          }}
        </Formik>
      </Auth>
    );
  }
}

export default withAuthContext(withRouter(injectIntl(PromoForm)));
