import React, { useRef, useEffect } from "react";
import { Form, Field, Formik } from "formik";
import * as Yup from "yup";
import { NotificationManager } from "react-notifications";
import { FormattedMessage, injectIntl } from "react-intl";
import services from "../../services";
import appState from "../../state/AppStateContainer";
import _ from "lodash";
import withRouter from "../../util/withRouter";

const ForgotPassword = (props) => {
  const { intl, navigate } = props;
  const lang = appState.state.language;

  const formikRef = useRef();

  useEffect(() => {
    formikRef.current.validateForm();
  }, [lang]);

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const response = await services.ForgotPassword(values.username);
      NotificationManager.success(
        intl.formatMessage({
          id: "global.resetPasswordLinkSuccessfullySent",
          defaultMessage: "Reset Password Link Successfully Sent",
        }),
        intl.formatMessage({
          id: "global.success",
          defaultMessage: "Success",
        })
      );
      if (response?.statusCode === 200) {
        navigate("/forgot-password/email-sent", {
          state: {
            result: "email.reset",
            message: "email.resetPassword",
          },
        });
      }
    } catch (e) {
      NotificationManager.error(
        props.intl.formatMessage({
          id: e?.response?.data?.response || "error.unexpectedError",
          defaultMessage: "An unexpected error has occurred",
        }),
        props.intl.formatMessage({
          id: "global.error",
          defaultMessage: "Error",
        })
      );
    } finally {
      setSubmitting(false);
    }
  };

  const validationSchema = () => {
    return Yup.object().shape({
      username: Yup.string()
        .email(
          intl.formatMessage({
            id: "global.emailFormat",
            defaultMessage: "Not a vail email address",
          })
        )
        .required(
          intl.formatMessage({
            id: "global.required",
            defaultMessage: "Required",
          })
        ),
    });
  };

  return (
    <>
      <div
        className={`${
          appState.state.language === "en"
            ? "en-bg-sky-white"
            : "ar-bg-sky-white"
        }`}
      >
        <div className="lang-wrapper">
          <a
            style={{ cursor: "pointer" }}
            onClick={() => {
              appState.changeLanguage();
            }}
          >
            <span className="fa fa-language fa-2x" />
          </a>
        </div>

        <div className="login-row-wrapper">
          <div className="service-advisor-login-wrapper">
            <div className="login-left-box">
              <h3>
                <FormattedMessage
                  id="forgot.ForgotPassword"
                  defaultMessage="Forgot Password"
                />
              </h3>
            </div>
            <div className="right-form-box">
              <Formik
                initialValues={{ username: "" }}
                validationSchema={validationSchema}
                innerRef={formikRef}
                onSubmit={handleSubmit}
              >
                {({ touched, errors, isSubmitting }) => (
                  <Form>
                    <div className="full-row">
                      <div className="autohub-logo-wrapper">
                        <img
                          src={`/design/images/${
                            lang === "en" ? "ah-logo.webp" : "ah-logo-ar.webp"
                          }`}
                          alt="The Auto Hub CMS"
                        />
                      </div>
                      <Field
                        type="text"
                        className={`service-advisor-login-field ${
                          appState.state.language === "ar" ? "arabic-field" : ""
                        }`}
                        name="username"
                        placeholder={intl.formatMessage({
                          id: "login.enterUsernameOrEmail",
                          defaultMessage: "Enter your email",
                        })}
                        autoFocus=""
                      />
                      {touched.username && errors.username && (
                        <p className="text-warning">{errors.username}</p>
                      )}
                    </div>

                    <div className="full-row login-btn-wrapper">
                      <input
                        type="submit"
                        className="btn btn-primary btn-login"
                        value={intl.formatMessage({
                          id: "login.sendEmail",
                          defaultMessage: "Send Email",
                        })}
                        disabled={isSubmitting}
                      />
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(injectIntl(ForgotPassword));
