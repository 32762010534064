import React, { Component } from "react";
import { Link, Route } from "react-router-dom";
import withRouter from "../../../util/withRouter";
import { FormattedMessage, injectIntl, FormattedNumber } from "react-intl";
import { withAuthContext } from "../../../state/AuthContext";
import _ from "lodash";
import Auth from "../../shared/Auth";
import Layout from "../../shared/Layout";
import HeaderTitle from "../../shared/HeaderTitle";
import appState from "../../../state/AppStateContainer";
import ImageProvider from "../../shared/ImageProvider";
import CheckAccess from "../../shared/CheckAccess";
import { ActivitiesEnum } from "../../../staticData/Activities";
import { getLocalDateTime } from "../../../util";
import Table from "../../shared/Table";
import ActionMenu from "../../shared/ActionMenu";
import HeadlineService from "../../../services/headlineService";
import { NotificationManager } from "react-notifications";
export class HeadlineList extends Component {
  state = {
    data: [],
    activeData: [],
    searchFilter: "",
    approvalFilter: "all",
    statusFilter: "all",
    rejectingId: "",
    visible: false,
    loading: false,
  };

  resetData(e) {
    this.setState({
      data: [],
      disableFlag: true,
    });
    if (e) e.preventDefault();
  }

  async componentDidMount() {
    this.getHeadlineData();
  }

  async getHeadlineData() {
    this.setState({ loading: true });
    const getHeadlines = await HeadlineService.getHeadline();
    this.setState({
      data: getHeadlines,
      activeData: getHeadlines.filter(({ isActive }) => isActive),
      disableFlag: false,
      loading: false,
    });
  }

  async updateHeadlinesStatus(id, status) {
    try {
      const message = {
        en: "Do you want to update status?",
        ar: "هل تريد تحديث الحالة؟",
      };
      if (!window.confirm(message[appState.state.language])) return;
      const response = await HeadlineService.updateHeadlineStatus(id, status);
      if (response.status) await this.getHeadlineData();
    } catch (e) {
      NotificationManager.error(
        this.props.intl.formatMessage({
          id: _.get(e, "response.data.message", "error.unexpectedError"),
          defaultMessage: e?.response?.data?.message,
        }),
        this.props.intl.formatMessage({
          id: "global.error",
          defaultMessage: "Error",
        })
      );
    }
  }

  async deleteHandler(id) {
    const message = {
      en: "Do you want to delete headline?",
      ar: "هل تريد حذف العنوان؟",
    };
    if (!window.confirm(message[appState.state.language])) return;
    const response = await HeadlineService.deleteHeadline(id);
    if (response.message === "Deleted") await this.getHeadlineData();
  }

  resetPage(e) {
    this.setState({
      data: [],
      searchFilter: "",
      approvalFilter: "all",
      statusFilter: "all",
      disableFlag: true,
      to: undefined,
      from: undefined,
    });
    this.getHeadlineData();
    e.preventDefault();
  }

  render() {
    return (
      <Auth requireAuth={true} roles={["sadmin", "wsadmin", "spadmin"]}>
        <Layout
          hidden={this.props.match.params.isFormOpen}
          loading={this.state.loading}
        >
          <div className="table-header clearfix">
            <HeaderTitle
              id="headline.headlinesTitle"
              defaultMessage="Headlines"
            />
            <div className="main-container">
              <div className="table-header clearfix">
                <button
                  className="btn btn-primary"
                  onClick={this.resetPage.bind(this)}
                  disabled={this.state.disableFlag}
                >
                  <i className="material-icons">settings_backup_restore</i>
                </button>
              </div>
              {this.state.activeData.length < 5 ? (
                <div className="row">
                  <div className="col-xs-3 pull-right">
                    <div className="panel-group-buttons pull-right">
                      <CheckAccess activityId={ActivitiesEnum.content_home}>
                        <Link className="btn btn-special" to="/headline">
                          <span className="fa fa-plus" />{" "}
                          <FormattedMessage
                            id="home.headline"
                            defaultMessage="Create Headline"
                          />
                        </Link>
                      </CheckAccess>
                    </div>
                  </div>
                </div>
              ) : null}

              <div className="row">
                <div className="col-md-12">
                  <FormattedMessage
                    id="global.totalCount"
                    defaultMessage="Total Count"
                  />

                  <strong>
                    {" "}
                    <FormattedNumber
                      style="decimal"
                      maximumFractionDigits={0}
                      minimumFractionDigits={0}
                      value={this.state.data.length}
                    />{" "}
                  </strong>
                </div>
              </div>
            </div>
            <div>
              <div className="table-responsive">
                <Table
                  caption={
                    (!this.state.data || this.state.data.length == 0) && (
                      <caption
                        style={{
                          captionSide: "bottom",
                          textAlign: "center",
                          margin: "20px",
                          color: "darkgray",
                        }}
                      >
                        <FormattedMessage
                          id="global.noData"
                          defaultMessage="No data available"
                        />
                      </caption>
                    )
                  }
                  headerRow={
                    <tr>
                      <th>
                        <FormattedMessage
                          id="headline.englishImage"
                          defaultMessage="Image (English)"
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          id="headline.arabicImage"
                          defaultMessage="Image (Arabic)"
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          id="headline.englishTitle"
                          defaultMessage="English Title"
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          id="headline.arabicTitle"
                          defaultMessage="Arabic Title"
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          id="global.createdAt"
                          defaultMessage="Created At"
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          id="headline.isActive"
                          defaultMessage="IsActive"
                        />
                      </th>
                      <th style={{ width: "20px" }} />
                    </tr>
                  }
                  loadMore={() => {}}
                >
                  {this.state.data.map((c) => {
                    var showEdit = false;

                    if (
                      (appState.state.token?.role === "sadmin" &&
                        c.approvalState === "approved") ||
                      (appState.state.token?.id === c.approvalRequestedBy &&
                        c.approvalState !== "approved")
                    )
                      showEdit = true;
                    return (
                      <tr
                        key={c._id}
                        onClick={() =>
                          this.props.navigate(`/headline/${c._id}`)
                        }
                        className="cases-trow link-row"
                      >
                        <ImageProvider imageSrc={c?.image.en} />
                        <ImageProvider imageSrc={c?.image.ar} />
                        <td>{c.title.en}</td>
                        <td>{c.title.ar}</td>
                        <td>
                          {c.createdAt
                            ? getLocalDateTime(c.createdAt)
                            : undefined}
                        </td>
                        <td>{c.isActive ? "Yes" : "No"}</td>
                        <td style={{ position: "relative" }}>
                          <ActionMenu
                            visible={this.state.visible}
                            menuStyle={{
                              backgroundColor: "#E9E9E9",
                            }}
                          >
                            <a
                              style={{ margin: "10px", cursor: "pointer" }}
                              onClick={() =>
                                this.updateHeadlinesStatus(c._id, !c.isActive)
                              }
                            >
                              <span className="fa fa-pencil-square-o" />{" "}
                              <FormattedMessage
                                id={
                                  c.isActive
                                    ? "global.deActivate"
                                    : "global.activate"
                                }
                                defaultMessage="Status"
                              />
                            </a>
                            <a
                              style={{ margin: "10px", cursor: "pointer" }}
                              onClick={() => this.deleteHandler(c._id)}
                            >
                              <span className="fa fa-trash" />{" "}
                              <FormattedMessage
                                id="global.delete"
                                defaultMessage="Delete"
                              />
                            </a>
                          </ActionMenu>
                        </td>
                      </tr>
                    );
                  })}
                </Table>
              </div>
            </div>
          </div>
        </Layout>
      </Auth>
    );
  }
}

export default withAuthContext(withRouter(injectIntl(HeadlineList)));
