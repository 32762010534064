const Error = {
  SERVICE_UNAVAILABLE: {
    key: "error.serviceUnavailable",
    value: "Service Unavailable",
  },
  INVALID_PARAMETER: {
    key: "error.invalidParameters",
    value: "Invalid or missing Input Parameters",
  },
  FAILED: {
    key: "error.failedToSend",
    value: "Failed to re send the invoice",
  },
  TOO_MANY_REQUEST: {
    key: "error.tooManyRequest",
    value: "Too many request from this IP. Please try again later.",
  },
  OTP_EXPIRED: {
    key: "error.otpExpired",
    value: "OTP expired. Please request a new one",
  },
  INVALID_OTP: {
    key: "error.invalidOtp",
    value: "Invalid OTP",
  },
  WEAK_PASSWORD: {
    key: "error.weakPassword",
    value:
      "Password must be at least 8 characters long and include at least one letter, one number, and one special character",
  },
  INCORRECT_PASSWORD: {
    key: "error.incorrectPassword",
    value: "Existing password is incorrect",
  },
  INVALID_CREDENTIALS: {
    key: "error.invalidCredentials",
    value: "Invalid Credentials",
  },
  NO_USER_FOUND: {
    key: "error.userNotFound",
    value: "User not Found",
  },
  INVOICE_NOT_SEND: {
    key: "error.invoiceNotSent",
    value: "Invoice not sent successfully",
  },
  INVOICE_SENT: {
    key: "success.invoiceSent",
    value: "Invoice sent successfully",
  },
  LINK_EXPIRED: {
    key: "error.linkExpired",
    value: "Link has been expired",
  },
  INVALID_PASSWORD_CODE: {
    key: "error.invalidPasswordCode",
    value: "Invalid Code",
  },
  ORDER_NOT_FOUND: {
    key: "error.noOrderFound",
    value: "Order not found",
  },
  409: {
    key: "error.duplicateRecord",
    value: "Duplicate values are not allowed",
  },
};

export default Error
