import BaseService from "./baseService";
import axios from "axios";

class ScheduleEmailService extends BaseService {
  constructor() {
    super();
  }

  async getScheduleEmail(
    searchFilter,
    reportTypeFilter,
    tenureFilter,
    pageIndex,
    pageSize,
    alphabeticalOrder,
    options = {}
  ) {
    const response = await axios.get("/api/schedule-email", {
      params: {
        searchFilter,
        reportTypeFilter,
        tenureFilter,
        pageIndex,
        pageSize,
        alphabeticalOrder,
        summary: options.summary,
        fromDate: options.fromDate,
        toDate: options.toDate,
        categories: options.categories,
      },
      progressBar: options.progressBar,
    });
    return response.data.response;
  }

  async getScheduleEmailById(id, options = {}) {
    const response = await axios.get(`/api/schedule-email/${id}`, {
      progressBar: options.progressBar,
    });
    return response.data.response;
  }

  async addScheduleEmail(data) {
    const response = await axios.post("/api/schedule-email", data);
    return response.data.response;
  }

  async updateScheduleEmail(values) {
    const response = await axios.put("/api/schedule-email", values);
    return response.data.response;
  }

  async deleteScheduleEmail(id) {
    const response = await axios.delete("/api/schedule-email", {
      params: { id },
    });
    return response.data.response;
  }
}

export default new ScheduleEmailService();
