import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import _ from "lodash";
import "react-dates/initialize";
import {
  DateRangePickerWrapper,
  DateRangePicker,
  SingleDatePicker,
  DayPickerRangeController,
} from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import moment from "moment";
import appState from "../../state/AppStateContainer";

export default class DatePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // startDate: moment()
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    const {
      placeholder,
      name,
      formikprops,
      disabled,
      getsuggestions,
      startDate,
      endDate,
      initialstartdate,
      initialenddate,
    } = this.props;

    const touchedConfig = {};
    touchedConfig[name] = true;
    formikprops.setTouched(touchedConfig);
    formikprops.setFieldValue(name, e);
  }

  render() {
    const {
      startdateplaceholdertext,
      enddateplaceholdertext,
      name,
      formikprops,
      initialstartdate,
      initialenddate,
    } = this.props;

    moment.locale("en");

    return (
      <React.Fragment>
        <DateRangePicker
          enddateplaceholdertext={enddateplaceholdertext}
          startdateplaceholdertext={startdateplaceholdertext}
          initialstartdate={initialstartdate}
          initialenddate={initialenddate}
          isRTL={appState.state.language === "ar" ? true : false}
          startDate={this.state.startDate || initialstartdate} // momentPropTypes.momentObj or null,
          endDate={this.state.endDate || initialenddate} // momentPropTypes.momentObj or null,
          onDatesChange={({ startDate, endDate }) => {
            this.setState({ startDate, endDate });
            const touchedConfig = {};
            touchedConfig[name] = true;
            formikprops.setTouched(touchedConfig);
            formikprops.setFieldValue(name, { startDate, endDate });
          }}
          focusedInput={this.state.focusedInput}
          onFocusChange={(focusedInput) => this.setState({ focusedInput })}
          minimumNights={0}
        />
      </React.Fragment>
    );
  }
}
