import services from "../services";
import PaymentService from "../services/PaymentService";
import appState from "../state/AppStateContainer";

export const downloadPdf = async (pdf, deposit, refund) => {
  const blob = new Blob([pdf], { type: "application/pdf" });

  // Create a download link for the file
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = refund
    ? `Credit-Memo.pdf`
    : deposit
    ? `Deposit-Receipt.pdf`
    : `Final-Invoice.pdf`;
  document.body.appendChild(link);
  link.click();

  // Clean up the object URL after the download is complete
  URL.revokeObjectURL(url);
};

export const generateInvoice = async ({ id, deposit, type, refund, order }) => {
  //   const { offerCompletedBy, variantDeliveredBy, isRefunded } = order;
  try {
    let pdf;
    if (refund) {
      // if (offerCompletedBy || variantDeliveredBy || isRefunded) return;
      pdf = await services.refundOrder(id, type);
    } else {
      if (deposit) pdf = await services.downloadDepositInvoice(id, type);
      else pdf = await services.downloadFinalInvoice(id, type);
    }
    await downloadPdf(pdf, deposit, refund);
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
};

export const generateZatcaInvoice = async (data) => {
  try {
    const pdf = await services.downloadZatcaInvoice(data);
    // await downloadPdf(pdf, false, false);
    return pdf;
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const getOrderCompletedStatus = (data) => {
  if (!Object.keys(data).length) return false;

  const {
    orderType,
    variantCompletedStatus,
    variantItems,
    offerItems,
    offerStatus,
    upsellServiceStatus,
    offerStartedBy,
  } = data;

  const hasVariants = variantItems?.length > 0;
  const hasOffers = offerItems?.length > 0;

  switch (orderType) {
    case "eshop":
      return hasVariants && variantCompletedStatus?.toLowerCase() === "pending";

    case "addon":
      return (
        // hasVariants &&
        variantCompletedStatus !== "completed" &&
        // hasOffers &&
        offerStatus === "notStarted"
      );

    case "upsell":
      return (
        (hasVariants && variantCompletedStatus === "pending") ||
        (hasOffers && upsellServiceStatus !== "completed")
      );

    case "offer":
      return hasOffers && offerStatus === "notStarted";

    default:
      return false;
  }
};

export const getOrderRefundItemKey = (order) => {
  if (order?.offerItems?.length > 0) return "offer";
  else if (order?.variantItems?.length > 0) return "variant";
  else return "subscription";
};

export const toShowResendInvoiceButton = (order) => {
  if (
    order?.offerItems?.length > 0 &&
    order?.zatcaInvoiceDetails?.zatcaOfferRefundUuid
  )
    return false;
  else if (
    order?.variantItems?.length > 0 &&
    order?.zatcaInvoiceDetails?.zatcaVariantRefundUuid
  )
    return false;
  else if (
    order?.offerItems?.length > 0 &&
    order?.zatcaInvoiceDetails?.zatcaOfferRefundUuid &&
    order?.variantItems?.length > 0 &&
    order?.zatcaInvoiceDetails?.zatcaVariantRefundUuid
  )
    return false;
  else return true;
};

export const getOrderPayload = ({
  module,
  references,
  pathname,
  serviceItem,
}) => {
  let orderPayload = {
    orderType: module,
    paymentBrand: "tamara",
    // promoCode: draftVals?.coupon,
    branchId: references?.branchId || "63c10f6aa920ea32701f0b7a",
    language: appState?.state?.language,
    currentPageUrl: pathname,
    appointmentVoucherId: "",
    appointmentId: references._id,
    offers: serviceItem,
    variants: [],
    firstName: references?.firstName,
    lastName: references?.lastName,
    mobilePhone: references?.phoneNumber,
    email: references?.email,
    fromCms: true,
  };

  return orderPayload;
};

export const createOrder = async ({
  orderPayload,
  NotificationManager,
  intl,
}) => {
  try {
    let order = await services.createOrder(orderPayload);

    if (order) {
      if (order.tamaraCheckoutUrl) {
        localStorage.setItem(
          "tamaraDetails",
          JSON.stringify({
            orderId: order?._doc?._id,
            appointmentId: orderPayload?.appointmentId,
          })
        );
        window.location.href = order.tamaraCheckoutUrl;
        return;
      }
    }
  } catch (e) {
    console.log(e);
    NotificationManager.error(
      intl.formatMessage({
        id: "error.orderFailed",
        defaultMessage: "Order Failed",
      }),
      intl.formatMessage({
        id: "global.error",
        defaultMessage: "Error",
      })
    );
  }
};
export const checkTamaraPaymentStatus = async ({
  orderId,
  NotificationManager,
  intl,
}) => {
  try {
    let result = await PaymentService.verifyTamaraPayment(orderId);

    if (result?.message === "order.tamaraPaymentSuccessful") {
      NotificationManager.success(
        intl.formatMessage({
          id: "order.transactionSuccessful",
          defaultMessage: "Transaction Successful",
        }),
        intl.formatMessage({
          id: "global.success",
          defaultMessage: "Success",
        })
      );
      NotificationManager.success(
        intl.formatMessage({
          id: "order.orderSuccessful",
          defaultMessage: "Order Successfully placed!",
        }),
        intl.formatMessage({
          id: "global.success",
          defaultMessage: "Success",
        })
      );
      return result.appointmentId || true;
    }
  } catch (e) {
    console.log(e);
    NotificationManager.error(
      intl.formatMessage({
        id: "order.transactionFailed",
        defaultMessage: "Transaction Failed",
      }),
      intl.formatMessage({
        id: "global.error",
        defaultMessage: "Error",
      })
    );
    return false;
  }
};
