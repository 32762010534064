import BaseService from "./baseService";
import axios from "axios";
class BlogService extends BaseService {
  async addBlog(data, id) {
    const formData = new FormData();
    for (const key in data) {
      formData.append(key, data[key]);
    }

    let response;
    if (!id) response = await axios.post("/api/blog", formData);
    else response = await axios.put("/api/blog/" + id, formData);
    return response.data;
  }

  async updateStatus(id, state) {
    const response = await axios.put("/api/blog/status/" + id, {
      isActive: state,
    });
    return response.data;
  }

  async getBlogs(filters) {
    const response = await axios.get("/api/blogs", { params: filters });
    return response.data;
  }

  async sortBlogs(data) {
    const response = await axios.patch("/api/blog/sort", data);
    return response.data;
  }

  async deleteBlog(id) {
    const response = await axios.delete("/api/blog/" + id);
    return response.data;
  }

  async submitBlogTag(data, id) {
    let response;
    if (!id) response = await axios.post("/api/tag", data);
    else response = await axios.put("/api/tag/" + id, data);
    return response.data;
  }

  async getBlogTags(filters) {
    const response = await axios.get("/api/tags", { params: filters });
    return response.data;
  }

  async deleteBlogTag(id) {
    const response = await axios.delete("/api/tag/" + id);
    return response.data;
  }
}

export default new BlogService();
